import { TableRow } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { useToggle } from 'react-use'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import currencyText from 'Lib/currencyText'
import OrderLineQuantity from '../OrderLineQuantity'
import TableLine from './TableLine'
import StyledCell from './StyledCell'

const BundleLine = ({
  pos,
  permissions,
  sku,
  name,
  netPrice,
  quantity,
  margin,
  price,
  discount,
  stock,
  puid,
  actions,
  bundledLines,
  type,
}) => {
  const [expanded, toggle] = useToggle(true)

  return (
    <>
      <TableRow>
        <StyledCell>
          <div>
            {pos}
            <IconButton onClick={toggle}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </div>
        </StyledCell>
        <StyledCell>{sku}</StyledCell>
        <StyledCell>{name}</StyledCell>
        <StyledCell style={{ textAlign: 'right' }}>
          <OrderLineQuantity
            stock={stock}
            quantity={quantity}
            puid={puid}
            sku={sku}
          />
        </StyledCell>

        <StyledCell align="right">
          {type === 'external' && currencyText(netPrice)}
        </StyledCell>

        <StyledCell align="right">
          {type === 'external' && currencyText(netPrice)}
        </StyledCell>
        <StyledCell align="right">
          {currencyText(netPrice * quantity)}
        </StyledCell>
        <StyledCell align="right">{`${margin}%`}</StyledCell>

        <StyledCell align="right">
          {margin > 0 && currencyText(price * (margin / 100))}
        </StyledCell>

        <StyledCell align="right">
          {discount && `-${currencyText(discount)}`}
        </StyledCell>

        <StyledCell>{actions}</StyledCell>
      </TableRow>
      {bundledLines.map((line, index) => (
        <TableLine
          style={{ display: expanded ? 'table-row' : 'none' }}
          {...line}
          permissions={permissions}
          lineType={line['@type']}
          pos={`${pos}.${index + 1}`}
          bundled
        />
      ))}
    </>
  )
}

BundleLine.propTypes = {
  pos: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  netPrice: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  margin: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  stock: PropTypes.number.isRequired,
  puid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  actions: PropTypes.node.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  bundledLines: PropTypes.arrayOf(
    PropTypes.shape({
      pos: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      netPrice: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
      margin: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      stock: PropTypes.bool.isRequired,
      puid: PropTypes.string.isRequired,
      actions: PropTypes.node.isRequired,
      bundled: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  classes: PropTypes.shape({
    button: PropTypes.string,
  }).isRequired,
}

export default BundleLine
