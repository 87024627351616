import { Inputs } from 'Components/Common/Orders/OrderEdit'
import { useOrder } from 'OrderContext'
import React from 'react'
import { TextField } from 'react-admin'
import BooleanField from 'Components/Common/ViewFields/BooleanField'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import DateField from 'Components/Common/ViewFields/DateField'

const OrderGeneralEdit = () => {
  const orderProps = useOrder()

  return (
    <Inputs {...orderProps}>
      <TextField source="billingAddress.company" />
      <DateField source="createdAt" format="DD.MM.YYYY" />

      <CurrencyField source="total" />
      <BooleanField
        options={{ labelStyle: { color: '#000000' } }}
        source="tradingModel"
      />
    </Inputs>
  )
}

export default OrderGeneralEdit
