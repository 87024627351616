import Button from 'Components/Common/Buttons/Button'
import { useOrder } from 'OrderContext'
import React, { useContext } from 'react'
import {
  useLoading,
  useMutation,
  useNotify,
  useUpdateLoading,
} from 'react-admin'
import { useHistory } from 'react-router'
import { ResellerContext } from 'ResellerContext'

const DuplicateAsOfferButton = () => {
  const { record } = useOrder()
  const { flags } = useContext(ResellerContext)
  const loading = useLoading()
  const { startLoading, stopLoading } = useUpdateLoading()
  const showNotification = useNotify()
  const history = useHistory()

  const [mutate] = useMutation()

  if (!flags.createOrder) {
    return null
  }

  const handleClick = async () => {
    try {
      startLoading()
      const res = await new Promise((resolve, reject) => {
        mutate(
          {
            type: 'duplicateAsOffer',
            resource: 'orders',
            payload: { id: record.id },
          },
          {
            onSuccess: data => {
              resolve(data)
            },
            onFailure: error => {
              reject(error)
            },
          },
        )
      })
      showNotification('notifications.duplicateAsOffer.success')
      history.push(`/offers/${res.data.id}`)
    } catch (error) {
      showNotification('notifications.duplicateAsOffer.fail', 'warning')
    }
    stopLoading()
  }

  return (
    <Button
      onClick={handleClick}
      disabled={loading}
      label="actions.duplicateAsOffer"
      variant="outlined"
      resource="orders"
      fullWidth
    />
  )
}

export default DuplicateAsOfferButton
