import Button from '@material-ui/core/Button'
import React from 'react'
import { useTranslate } from 'react-admin'
import GenericRestClientButton from '../../Common/ActionButtons/GenericRestClientButton'

const ITScopeCartButton = ({ data, variant, label, fullWidth }) => {
  const t = useTranslate()
  if (data && data.itscopeCartId) {
    return (
      <Button
        color="primary"
        component="a"
        target="_blank"
        fullWidth={fullWidth}
        href={`https://www.itscope.com/red/app#deals/portal/${data.itscopeCartId}`}
        style={{ overflow: 'inherit' }}
      >
        {t('actions.jumpToItScopeCart')}
      </Button>
    )
  }
  return (
    <GenericRestClientButton
      type="itScopeCreateCart"
      label={label}
      variant={variant}
      fullWidth={fullWidth}
      action="createITScopeCart"
      resource="orders"
      payload={{ id: data.id }}
    />
  )
}

export default ITScopeCartButton
