import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import renderTextField from 'Components/Common/InputFields/renderComponents/renderTextField'
import React from 'react'
import { useTranslate, useNotify } from 'react-admin'
import { Field, Form } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import authApiPlatformClient from '../../../authApiPlatformClient'

const useStyles = makeStyles({
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  button: {
    width: '100%',
  },
})

const PasswordRequestForm = () => {
  const history = useHistory()
  const translate = useTranslate()
  const classes = useStyles()
  const showNotification = useNotify()

  const handleSubmit = async values => {
    try {
      await authApiPlatformClient.authRequestReset(values)
    } finally {
      showNotification('notifications.passwordRequest')
      history.push('/login')
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                name="email"
                component={renderTextField}
                label={translate('ra.auth.username')}
                disabled={submitting}
                fullWidth
              />
            </div>
          </div>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={submitting}
              className={classes.button}
            >
              {submitting && <CircularProgress size={25} thickness={2} />}
              {translate('ra.auth.reset')}
            </Button>
          </CardActions>
        </form>
      )}
    />
  )
}

export default PasswordRequestForm
