import { makeStyles } from '@material-ui/styles'
import Chip from '@material-ui/core/Chip'
import get from 'lodash.get'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslate } from 'ra-core'

const useStyles = makeStyles({
  truncateChip: {
    minWidth: 100,
    textAlign: 'center ',
  },
})

const ChipArrayField = ({
  source,
  record = {},
  accessor,
  truncate,
  elStyle,
}) => {
  const classes = useStyles()
  const translate = useTranslate()

  const value = get(record, source)

  if (!value) {
    return null
  }

  return (
    <>
      {value
        .slice(0, truncate || value.length)
        .map(v =>
          accessor ? (
            <Chip style={elStyle} label={v[accessor]} key={v[accessor]} />
          ) : (
            <Chip style={elStyle} label={v} key={v} />
          ),
        )}
      {truncate && value.length > truncate && (
        <Chip
          label={`+ ${value.length - truncate} ${translate(
            'texts.additional',
          ).toLowerCase()}`}
          variant="outlined"
          className={classes.truncateChip}
        />
      )}
    </>
  )
}

ChipArrayField.propTypes = {
  // addLabel: PropTypes.bool,
  elStyle: PropTypes.object,
  // label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  accessor: PropTypes.string,
  truncate: PropTypes.number,
  // hideFalseValues: PropTypes.bool,
}

ChipArrayField.defaultProps = {
  elStyle: {},
  record: {},
  accessor: undefined,
  truncate: undefined,
}

export default ChipArrayField
