import { makeStyles } from '@material-ui/core'
import red from '@material-ui/core/colors/red'
import Button from 'Components/Common/Buttons/Button'
import React from 'react'
import { useFormState } from 'react-final-form'
import { useOrder } from 'OrderContext'
import { useLoading, useTranslate } from 'react-admin'

const useButtonStyles = makeStyles(theme => ({
  dangerButton: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const CommonActionsOrderSubmitButton = ({
  valid,
  enabled,
  transition,
  orderType,
  danger,
  icon: Icon,
  permitted,
  transitionEnabled,
  onClick,
  context,
}) => {
  const classes = useButtonStyles()
  const loading = useLoading()
  const translate = useTranslate()

  const { values } = useFormState()
  const { record } = useOrder()

  const hasDialog =
    context &&
    context.dialogCondition &&
    context.dialogCondition(record, values)

  const type = hasDialog ? 'button' : 'submit'

  return (
    <Button
      type={type}
      variant="contained"
      color="primary"
      fullWidth
      className={danger && classes.dangerButton}
      disabled={
        !valid || !enabled || loading || !transitionEnabled || !permitted
      }
      label={translate(`${orderType}.transitions.${transition}`)}
      onClick={onClick}
    >
      <Icon className={classes.rightIcon} />
    </Button>
  )
}

CommonActionsOrderSubmitButton.defaultProps = {
  onClick: () => {},
}

export default CommonActionsOrderSubmitButton
