import React, { useEffect } from 'react'
import { TextInput } from 'react-admin'
import { useField } from 'react-final-form'

const CompanyShortInput = props => {
  const {
    input: { value: companyValue },
  } = useField('billingAddress.company')

  const {
    input: { onChange },
    meta: { touched },
  } = useField('billingAddress.companyShort')

  useEffect(() => {
    if (!touched) {
      onChange(companyValue.slice(0, 50))
    }
  }, [companyValue])

  return <TextInput {...props} source="billingAddress.companyShort" />
}

export default CompanyShortInput
