import { Field } from 'react-final-form'
import React from 'react'
import { Labeled } from 'react-admin'
import PropTypes from 'prop-types'

const renderArrayField = ({ input, label, joinString }) => (
  <Labeled label={label}>
    <div>
      {Object.entries(input.value || {}).map(([key, value]) => (
        <div>
          <span>{`${key}: ${(value || []).join(joinString)}`}</span>
        </div>
      ))}
    </div>
  </Labeled>
)

renderArrayField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.node.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  joinString: PropTypes.string.isRequired,
}

const KeyValueJoinedArrayInputField = ({ source, ...rest }) => (
  <Field name={source} component={renderArrayField} {...rest} />
)

KeyValueJoinedArrayInputField.propTypes = {
  source: PropTypes.string.isRequired,
  addLabel: PropTypes.bool,
  joinString: PropTypes.string,
}

KeyValueJoinedArrayInputField.defaultProps = {
  addLabel: false,
  joinString: ', ',
}

export default KeyValueJoinedArrayInputField
