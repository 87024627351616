import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import DownloadAttachmentAction from './ActionButtons/DownloadAttachmentAction'

const DownloadAttachmentField = ({ source, record = {}, elStyle }) => {
  const value = get(record, source)
  if (!value) {
    return null
  }
  return (
    <span style={elStyle}>
      <DownloadAttachmentAction id={value.id} name={value.type} />
    </span>
  )
}

DownloadAttachmentField.propTypes = {
  addLabel: PropTypes.bool,
  elStyle: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

DownloadAttachmentField.defaultProps = {
  addLabel: true,
}

export default DownloadAttachmentField
