import React, { useContext } from 'react'
import { Dialog, Slide } from '@material-ui/core'
import { useLoading } from 'react-admin'
import { ResellerContext } from 'ResellerContext'
import AfterCreationWizard from 'Components/Aluxo/ActionDialogs/BulkCreateDeviceVariantsDialog/AfterCreationWizard'
import { useHistory } from 'react-router'
import BeforeCreationWizard from 'Components/Aluxo/ActionDialogs/BulkCreateDeviceVariantsDialog/BeforeCreationWizard/BeforeCreationWizard'
import { ProcessProvider, useProcessContext } from './ProcessContext'

const Transition = props => <Slide direction="up" {...props} />

const AluxoActionDialogsBulkCreateDeviceVariantsDialog = () => {
  const { flags } = useContext(ResellerContext)

  const loading = useLoading()

  const history = useHistory()

  const closeDialog = () => {
    history.push('/devices')
  }

  const { processStatus, states } = useProcessContext()

  if (!flags.massCreateDeviceVariants) {
    return null
  }

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      aria-labelledby="simple-dialog-title"
      open
      onClose={() => {
        if (!loading) {
          history.push('/devices')
        }
      }}
      TransitionComponent={Transition}
    >
      {processStatus === states.EDITING ? (
        <AfterCreationWizard closeDialog={closeDialog} />
      ) : (
        <BeforeCreationWizard closeDialog={closeDialog} />
      )}
    </Dialog>
  )
}

const AluxoActionDialogsBulkCreateDeviceVariantsDialogContainer = () => (
  <ProcessProvider>
    <AluxoActionDialogsBulkCreateDeviceVariantsDialog />
  </ProcessProvider>
)

export default AluxoActionDialogsBulkCreateDeviceVariantsDialogContainer
