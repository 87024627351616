import React from 'react'
import { Button } from '@material-ui/core'
import { useNotify } from 'react-admin'
import { useForm } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'
import { useResellerContext } from 'ResellerContext'
import { sanitizeResellerProductBeforeAddingToLines } from 'Components/Also/EDI/ResellableProductList'
import { useToggle } from 'react-use'
import AlsoOfferLineAddDialog from 'Components/Also/Offers/OfferLines/AlsoOfferLineAddDialog'
import { last } from 'lodash'

export default function AlsoOfferAddRelatedProductButton({
  relatedToProduct,
  idx,
}) {
  const [open, toggle] = useToggle(false)
  const notify = useNotify()
  const { customizingValues } = useResellerContext()
  const { batch } = useForm()

  const {
    fields: { insertAt },
  } = useFieldArray('offerLines')

  const handleClickOpen = () => {
    toggle(true)
  }

  const handleClose = () => {
    toggle(false)
  }

  const handleAdd = records => {
    const recordArray = [].concat(records || [])
    batch(() => {
      recordArray.forEach((record, index) =>
        insertAt(idx + index + 1, {
          ...sanitizeResellerProductBeforeAddingToLines(record),
          margin: customizingValues.alsoOfferSettings.defaultMargin,
          parentPosition: idx,
        }),
      )
    })
    notify('notifications.addOfferLine.success')
    handleClose()
  }

  const relatedId = last(relatedToProduct.split('/'))

  return (
    <>
      <Button variant="text" color="primary" onClick={handleClickOpen}>
        Zubehör hinzufügen
      </Button>
      <AlsoOfferLineAddDialog
        handleAdd={handleAdd}
        open={open}
        relatedToProducts={relatedId}
        handleClose={handleClose}
      />
    </>
  )
}
