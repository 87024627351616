import { Box } from '@material-ui/core'
import currencyText from 'Lib/currencyText'
import React from 'react'
import InStockDisplay from '../InStockDisplay/InStockDisplay'

export default ({ a, totalQuantity }) => (
  <Box display="flex" width="100%">
    <InStockDisplay stock={a.stock} stockNeeded={totalQuantity} />
    <Box display="flex" justifyContent="space-between" width="100%">
      <div>{a.supplierName}</div>
      <div>{currencyText(a.price)}</div>
    </Box>
  </Box>
)
