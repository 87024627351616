import { Paper } from '@material-ui/core'
import { Input } from 'Components/Common/InputFields/AutoSuggests/Lib/renderFunctions'
import Downshift from 'downshift'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useField } from 'react-final-form'
import SelectList from 'Components/Common/InputFields/AutoSuggests/Lib/SelectList'
import FetchData from './FetchData'
import getSuggestions from './getSuggestions'

const itemToString = item => item.label

const ITScopeAutoSuggest = ({ label, source, onItemSelect, autoFocus }) => {
  const {
    input: { value, onChange },
  } = useField(source)

  const selectedItem = useMemo(() => ({ value }), [value])

  return (
    <Downshift
      onChange={selection => {
        if (selection) {
          onChange(selection.value)
          onItemSelect(selection)
        }
      }}
      itemToString={itemToString}
      selectedItem={selectedItem}
    >
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        isOpen,
        inputValue,
        selectedItem,
        highlightedIndex,
      }) => (
        <div>
          <Input
            label={label}
            autoFocus={autoFocus}
            fullWidth
            InputProps={getInputProps()}
          />

          <Paper
            style={{
              display: isOpen ? 'block' : 'none',
              visibility: isOpen ? 'visible' : 'hidden',
            }}
            square
            {...getMenuProps()}
          >
            {isOpen ? (
              <FetchData itScopeSearchValue={inputValue}>
                {({ itScopeData }) => {
                  const suggestions = getSuggestions({
                    itScopeData,
                    inputValue,
                  })

                  return (
                    <SelectList
                      rows={suggestions}
                      getItemProps={getItemProps}
                      highlightedIndex={highlightedIndex}
                      selectedItem={selectedItem}
                      itemToString={itemToString}
                    />
                  )
                }}
              </FetchData>
            ) : null}
          </Paper>
        </div>
      )}
    </Downshift>
  )
}

ITScopeAutoSuggest.propTypes = {
  source: PropTypes.string,
  onItemSelect: PropTypes.func,
  withITScope: PropTypes.bool,
}

ITScopeAutoSuggest.defaultProps = {
  source: null,
  onItemSelect: () => {},
  withITScope: false,
}

export default ITScopeAutoSuggest
