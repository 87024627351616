import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { Save } from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTranslate } from 'react-admin'

const SaveButton = ({
  handleSubmit,
  saving,
  label = 'ra.action.save',
  raised = true,
  type,
  submitOnEnter,
  redirect,
}) => {
  const translate = useTranslate()

  const handleClick = e => {
    if (saving) {
      // prevent double submission
      e.preventDefault()
    } else {
      // always submit form explicitly regardless of button type
      if (e) {
        e.preventDefault()
      }
      handleSubmit()
    }
  }

  return (
    <Button
      type={type}
      variant={raised && 'contained'}
      onClick={handleClick}
      primary={!saving}
      style={{
        margin: '10px 24px',
        position: 'relative',
      }}
    >
      {saving && saving.redirect === redirect ? (
        <CircularProgress size={25} thickness={2} />
      ) : (
        <Save />
      )}
      {label && translate(label, { _: label })}
    </Button>
  )
}

SaveButton.propTypes = {
  label: PropTypes.string,
  raised: PropTypes.bool,
  saving: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  submitOnEnter: PropTypes.bool,
  handleSubmit: PropTypes.func,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

SaveButton.defaultProps = {
  handleSubmit: () => {},
}

export default SaveButton
