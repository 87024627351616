import { FormInput } from 'react-admin'
import React, { useState } from 'react'

import Button from 'Components/Common/Buttons/Button'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import PropTypes from 'prop-types'
import getDialogFormInputs from 'Components/Aluxo/OrderLines/Lines/getDialogFormInputs'
import PromoBundleAutoSuggest from 'Components/Common/InputFields/AutoSuggests/PromoBundleAutoSuggest/PromoBundleAutoSuggest'
import Box from '@material-ui/core/Box'
import DeviceSelect from 'Components/Aluxo/OrderLines/Lines/PromoBundleLine/DeviceSelect'
import ProductSelect from 'Components/Aluxo/OrderLines/Lines/PromoBundleLine/ProductSelect'

export default function AddPromoBundleDialogForm({
  handleSubmit,
  reference,
  handleClose,
  record,
  loading,
  form,
}) {
  const inputs = getDialogFormInputs({
    handleSubmit,
    reference,
    handleClose,
    record,
    loading,
    form,
  })

  const [availableDevices, setAvailableDevices] = useState([])
  const [products, setProducts] = useState([])

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <FormInput
          input={<PromoBundleAutoSuggest />}
          basePath={reference}
          record={record}
          resource={reference}
          variant="filled"
          margin="dense"
          autoFocus
          label="resources.promo_bundle_order_lines.fields.search"
          source="bundle"
          onItemSelect={item => {
            setAvailableDevices(item.requiredDevices)
            form.change(
              'products',
              item.allowedProducts.map(p => p.id),
            )
            setProducts(item.allowedProducts)
          }}
        />

        <Box mt={1} mb={0.5}>
          <FormInput
            input={<DeviceSelect availableDevices={availableDevices} />}
            basePath={reference}
            record={record}
            resource={reference}
            variant="filled"
            margin="dense"
            label="resources.promo_bundle_order_lines.fields.device"
            source="device"
          />
        </Box>
        <Box mt={1} mb={0.5}>
          <ProductSelect products={products} />
        </Box>

        {inputs
          .filter(i => i)
          .map(({ Component, key, props }) => (
            <FormInput
              key={key}
              input={<Component {...props} />}
              basePath={reference}
              record={record}
              resource={reference}
              variant="filled"
              margin="dense"
              {...props}
            />
          ))}
      </DialogContent>
      <DialogActions>
        <Button
          label="Abbrechen"
          onClick={handleClose}
          color="secondary"
          disabled={loading}
        />
        <Button
          label="ra.action.save"
          type="submit"
          color="primary"
          loading={loading}
          disabled={loading}
        />
      </DialogActions>
    </form>
  )
}

AddPromoBundleDialogForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  reference: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    change: PropTypes.func.isRequired,
    getFieldState: PropTypes.func.isRequired,
  }).isRequired,
}
