import DevicesTable from 'Components/Aluxo/ExternalProtectionPlans/DevicesTable'
import DateInput from 'Components/Common/InputFields/DateInput'
import React from 'react'
import {
  Create,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin'
import styles from './styles'

export const dateSettingsRent = {
  autoOk: true,
}

const defaultValues = {
  orderCreate: false,
  duration: 36,
  theft: false,
  type: 'rent',
  date: new Date(),
  insuranceDate: new Date(),
}

const validateRequired = required()

const BaseExternalProtectionPlanCreate = props => (
  <Create {...props}>
    <SimpleForm defaultValue={defaultValues}>
      <TextInput
        options={{
          validate: [required],
          style: { width: '100%' },
          listStyle: { width: '100%' },
          textFieldStyle: { width: '100%' },
        }}
        style={styles.firstColumn}
        source="customer"
        validate={validateRequired}
      />

      <SelectInput
        source="type"
        options={{ fullWidth: true }}
        validate={validateRequired}
        style={styles.firstColumn}
        label="resources.external_protection_plans.fields.purchase"
        choices={[
          { id: 'sale', name: 'Kaufen' },
          { id: 'rent', name: 'Mieten' },
        ]}
      />

      <DateInput
        options={dateSettingsRent}
        source="date"
        style={styles.firstColumn}
        validate={validateRequired}
        label="resources.external_protection_plans.fields.deliveryDate"
      />

      <DateInput
        options={dateSettingsRent}
        source="insuranceDate"
        style={styles.firstColumn}
        validate={validateRequired}
        label="resources.external_protection_plans.fields.insuranceDate"
      />

      <SelectInput
        source="duration"
        validate={validateRequired}
        label="resources.external_protection_plans.fields.duration"
        choices={[
          { id: 24, name: '24 Monate' },
          { id: 36, name: '36 Monate' },
        ]}
      />

      <SelectInput
        source="theft"
        label="resources.external_protection_plans.fields.theftProtection"
        choices={[
          { id: true, name: 'Ja' },
          { id: false, name: 'Nein' },
        ]}
      />

      <DevicesTable name="devices" spread />
    </SimpleForm>
  </Create>
)

export default BaseExternalProtectionPlanCreate
