import PropTypes from 'prop-types'
import React from 'react'
import { get } from 'lodash'
import { Link } from 'react-router-dom'

const CustomerLink = ({ source, record, linkText }) => {
  const customerID = get(record, source)
  const labelString = get(record, linkText)

  if (!customerID) {
    return <span>{labelString}</span>
  }

  return <Link to={`/customers/${customerID}`}>{labelString}</Link>
}

CustomerLink.propTypes = {
  addLabel: PropTypes.bool,
  source: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
}

CustomerLink.defaultProps = {
  addLabel: true,
}

export default CustomerLink
