import {
  Box,
  Card,
  CardContent,
  Tooltip,
  LinearProgress,
} from '@material-ui/core'
import { AddToPhotos } from '@material-ui/icons'
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined'
import Button from 'Components/Common/Buttons/Button'
import currencyText from 'Lib/currencyText'
import { get } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import {
  Datagrid,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  List,
  TextField,
  useGetList,
  useListContext,
  useLoading,
  useUnselectAll,
} from 'react-admin'
import { useField } from 'react-final-form'
import { useResellerContext } from 'ResellerContext'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

export const sanitizeResellerProductBeforeAddingToLines = ({
  '@id': iri,
  id,
  name,
  manufacturer,
  sku,
  ean,
  price,
}) => ({
  resellableProduct: iri,
  id,
  name,
  manufacturer,
  sku,
  ean,
  price,
  margin: 10,
  parentPosition: null,
})

const AddButton = ({ record, handleAdd }) => {
  const handleClick = () => {
    handleAdd([record])
  }

  return <Button label="Hinzufügen" onClick={handleClick} />
}

const RateField = ({ source, record }) => {
  const {
    input: { value: period },
  } = useField('period')

  const price = get(record, source)
  const rate = price / period

  return <span>{currencyText(rate)}</span>
}

const FilterSidebar = () => {
  const { data = {} } = useGetList(
    'common_product_attribute_groups',
    false,
    false,
    false,
  )

  const filterSegments = useMemo(
    () =>
      Object.values(data).map(group => (
        <FilterList label={group.name} icon={<LocalOfferIcon />}>
          {group.values.map(value => (
            <FilterListItem
              label={value.value}
              value={{ [`attribute.${group.name}`]: value.value }}
            />
          ))}
        </FilterList>
      )),
    [data],
  )
  return (
    <Box ml={1}>
      <Card>
        <CardContent>
          <FilterLiveSearch source="search" />
          {filterSegments}
        </CardContent>
      </Card>
    </Box>
  )
}

export const BulkAddToOfferButton = ({ selectedIds, handleAdd }) => {
  const unselectAll = useUnselectAll()
  const loading = useLoading()

  const listData = useListContext()

  const onClick = async () => {
    const items = selectedIds.map(id => listData.data[id])

    handleAdd(items)
    unselectAll('also_resellable_products')
  }

  return (
    <Button label="actions.addToOffer" disabled={loading} onClick={onClick}>
      <AddToPhotos />
    </Button>
  )
}

const ResellableProductListBulkActionButtons = props => (
  <React.Fragment>
    <BulkAddToOfferButton {...props} />
  </React.Fragment>
)

const TruncatedField = ({ source, record, truncate }) => {
  const value = get(record, source)

  if (truncate && value.length > truncate) {
    return (
      <Tooltip title={value} placement="right">
        <span>{value.substring(0, truncate)}...</span>
      </Tooltip>
    )
  }

  return <span>{value}</span>
}

const ResellableProductList = ({ relatedToProducts, handleAdd }) => {
  const { refetch } = useResellerContext()
  useEffect(() => {
    refetch()
  }, [])
  const loading = useLoading()

  const filter = relatedToProducts ? { relatedToProducts } : null

  return (
    <>
      {loading && <LinearProgress />}
      <List
        resource="also_resellable_products"
        basePath="/also_resellable_products"
        exporter={false}
        bulkActionButtons={
          <ResellableProductListBulkActionButtons handleAdd={handleAdd} />
        }
        aside={<FilterSidebar />}
        pagination={<CustomPagination />}
        filter={filter}
      >
        <Datagrid>
          <TruncatedField source="name" truncate={40} />
          <TextField source="manufacturer" />
          <TextField source="sku" />
          <TextField source="ean" />
          <RateField source="price" />
          <AddButton handleAdd={handleAdd} />
        </Datagrid>
      </List>
    </>
  )
}

export default ResellableProductList
