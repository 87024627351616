import React from 'react'
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  ImageField,
  List,
  SimpleForm,
  TextField,
  SelectInput,
  TextInput,
} from 'react-admin'
import ImageInput from 'Components/Common/InputFields/ImageInput'
import EditToolbar from 'Components/Common/EditToolbar'
import EditActions from 'Components/Common/EditActions'
import { validateRequired } from 'Resources/Aluxo/devices'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import ReferenceInput from 'Components/Common/ReferenceInput'
import PropTypes from 'prop-types'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

export const SlideList = props => (
  <List
    aside={<LanguageSwitcherAside />}
    pagination={<CustomPagination />}
    {...props}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="link" />
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <TextField source="storeView.name" />
      )}
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)

SlideList.propTypes = {
  permissions: PropTypes.array.isRequired,
}

export const SlideEdit = props => (
  <Edit actions={<EditActions />} aside={<LanguageSwitcherAside />} {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="name" validate={validateRequired} />
      <TextInput source="link" />
      <ImageField source="image" />
      <ImageInput source="imageFile" maxSize={5_000_000}>
        <ImageField source="image" title="title" />
      </ImageInput>
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <ReferenceInput
          source="storeView"
          reference="store_views"
          validate={validateRequired}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
    </SimpleForm>
  </Edit>
)

SlideEdit.propTypes = {
  permissions: PropTypes.array.isRequired,
}

export const SlideCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="link" />
      <ImageField source="image" />
      <ImageInput source="imageFile" maxSize={5_000_000}>
        <ImageField source="image" title="title" />
      </ImageInput>
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <ReferenceInput
          source="storeView"
          reference="store_views"
          validate={validateRequired}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
    </SimpleForm>
  </Create>
)

SlideCreate.propTypes = {
  permissions: PropTypes.array.isRequired,
}
