import { SaveButton, Toolbar } from 'react-admin'
import React from 'react'

const CustomerEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton redirect={false} />
  </Toolbar>
)

export default CustomerEditToolbar
