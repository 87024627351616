import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import './styles.css'

const size = (width, height) => ({
  height: `${height}em`,
  width: `${width}em`,
})

const square = length => size(length, length)

const wrapperStyle = ({ size, factor = 1 }) => ({
  ...square(size * factor),
  position: 'absolute',
  top: `${((1 - factor) / 2) * size}em`,
  left: `${((1 - factor) / 2) * size}em`,
})

const pieStyle = ({ size, factor, percent }) => ({
  clip:
    percent > 0.5
      ? 'rect(auto, auto, auto, auto)'
      : `rect(0, ${size * factor}em, ${size * factor}em, ${(size * factor) /
          2}em)`,
})
const halfCircleStyle = ({ size, factor, width, color }) => ({
  clip: `rect(0, ${(size * factor) / 2}em, ${size * factor}em, 0)`,
  borderWidth: `${width}em`,
  borderColor: color,
})

const rotateRight = percent => ({
  display: percent <= 0.5 && 'none',
  transform: percent > 0.5 && 'rotate(180deg)',
})

const rotateLeft = percent => ({
  transform: `rotate(${percent * 360}deg)`,
})

const Dial = ({ size, dialSize, percent, width, color }) => {
  const factor = dialSize / size
  return (
    <div className="pie-wrapper" style={wrapperStyle({ size, factor })}>
      <div
        className="pie"
        style={{
          ...pieStyle({ size, factor, percent }),
        }}
      >
        <div
          className={classnames('half-circle', 'left-side')}
          style={{
            ...halfCircleStyle({ size, factor, width, color }),
            ...rotateLeft(percent),
          }}
        />
        <div
          className={classnames('half-circle', 'right-side')}
          style={{
            ...halfCircleStyle({ size, factor, width, color }),
            ...rotateRight(percent),
          }}
        />
      </div>
    </div>
  )
}

Dial.propTypes = {
  size: PropTypes.number.isRequired,
  dialSize: PropTypes.number,
  percent: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string,
}

Dial.defaultProps = {
  dialSize: 0.7,
  width: 1,
  percent: 0.8,
  color: '#ccc',
}

export default Dial
