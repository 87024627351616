import React from 'react'
import moment from 'moment/moment'

const FlagListItemText = ({ value, user, createdAt }) => {
  if (!value) {
    return null
  }

  const createdBy = user ? user.email : 'System'
  return (
    <div>{`${moment(createdAt).format(
      'DD.MM.YYYY HH:mm',
    )} / ${createdBy}`}</div>
  )
}

export default FlagListItemText
