import React from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  EditButton,
  ReferenceManyField,
  required,
  TextField,
  TextInput,
  useEditController,
  useRefresh,
} from 'react-admin'
import { Field, Form, FormSpy } from 'react-final-form'
import CommonInputFieldsCategoryBasedOnStoreviewInput from 'Components/Common/InputFields/CategoryBasedOnStoreviewInput'
import DeviceVariantNamingSpecInput from 'Components/Aluxo/InputFields/DeviceVariantNamingSpecInput'
import DisplaySpecsField from 'Components/Common/ViewFields/DisplaySpecsField'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import PercentageField from 'Components/Common/ViewFields/PercentageField'
import TruncatedTextField from 'Components/Common/ViewFields/TruncatedTextField'
import Button from 'Components/Common/Buttons/Button'
import MissingSpecsField from 'Components/Common/ViewFields/MissingSpecsField'
import SlugInput from 'Components/Common/InputFields/SlugInput'
import CopyAccessorySKUButton from 'Components/Common/ActionButtons/CopyAccessorySKUButton'

const validateRequired = required()

const DeviceStepContent = props => {
  const refresh = useRefresh()
  const {
    loaded, // boolean that is false until the record is available
    record, // record fetched via dataProvider.getOne() based on the id from the location
    save, // the update callback, to be passed to the underlying form as submit handler
  } = useEditController({
    basePath: 'devices',
    resource: 'devices',
    id: props.deviceDetails.currentDeviceId,
    undoable: false,
  })

  const handleSubmit = async values => {
    await new Promise((resolve, reject) => {
      save(values, false, { onSuccess: resolve, onFailure: reject })
    })
    refresh()
  }

  if (!loaded) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <div>
      <Form initialValues={record} onSubmit={handleSubmit}>
        {({ handleSubmit, submitting }) => (
          <form id="device-variant-wizard-form" onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ dirty: true }}
              onChange={({ dirty }) => {
                props.setDirty(dirty)
              }}
            />
            <Field name="name">
              {({ input: { value } }) => <h1>{value}</h1>}
            </Field>

            <TextInput
              source="name"
              label="resources.devices.fields.name"
              fullWidth
              validate={validateRequired}
            />

            <SlugInput
              source="slug"
              dependOn="name"
              label="resources.devices.fields.slug"
              fullWidth
              validate={validateRequired}
            />

            <CommonInputFieldsCategoryBasedOnStoreviewInput label="resources.devices.fields.category.name" />
            <BooleanInput
              options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
              source="active"
              validate={validateRequired}
              label="resources.devices.fields.active"
            />

            <DeviceVariantNamingSpecInput label="resources.devices.fields.variantNamingGroups" />

            <ReferenceManyField
              label="resources.devices.fields.variants"
              reference="device_variants"
              target="device"
              record={record}
              basePath="device_variants"
            >
              <Datagrid style={{ width: '100%' }}>
                <TextField source="sku" />
                <TruncatedTextField source="name" truncate={40} />
                <DisplaySpecsField source="displaySpecs" />
                <MissingSpecsField source="missingDisplaySpecs" />
                <BooleanField source="active" />
                <CurrencyField source="price" />
                <PercentageField source="margin" />
                <EditButton />
              </Datagrid>
            </ReferenceManyField>

            <div>
              <TextInput
                rows={3}
                multiline
                fullWidth
                source="accessorySKUs"
                format={arr => arr.join('\n')}
                parse={value => value.split(/[\n,;]+/)}
                label="resources.devices.fields.accessorySKUs"
              />
            </div>
            <div>
              <CopyAccessorySKUButton record={record} />
            </div>
            <div>
              <TextInput
                multiline
                rows={3}
                fullWidth
                label="resources.devices.fields.serviceExtensionSKUs"
                source="serviceExtensionSKUs"
                format={arr => arr.join('\n')}
                parse={value => value.split(/[\n,;]+/)}
              />
            </div>
            <div>
              <TextInput
                source="includedServiceExtension"
                label="resources.devices.fields.includedServiceExtension"
              />
            </div>

            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                type="submit"
                loading={submitting}
                label="actions.submit"
                variant="contained"
              />
            </Box>
          </form>
        )}
      </Form>
    </div>
  )
}

export default DeviceStepContent
