import { Button } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import Orders from 'Components/Common/Dashboard/Orders'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useQuery } from 'react-query'
import apiClient from 'Rest/fetchHydra'
import styled from 'styled-components/macro'

const Grid = styled.div`
  margin-top: 2em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
  grid-gap: 1em;
  grid-auto-rows: minmax(125px, max-content);
`

const HeaderBar = styled.div`
  margin-top: -0.5em;
  position: relative;
  background-color: grey;
  display: flex;
  justify-content: flex-end;
  margin-right: -1.5em;
`

const markings = [
  'opened',
  'changed',
  'review',
  'approved',
  'created',
  'rejected',
  'customer_signed',
  'reseller_signed',
  'ordered',
  'order_confirmed',
  'shipped',
  'delivered',
  'confirmed',
  'refused',
  'completed',
  'cancelled',
  'invoiced',
  'payment_pending',
  'paid',
]

const ResellerDashboard = () => {
  const [filterEmpty, setFilterEmpty] = useState(true)
  const t = useTranslate()
  // const apiClient = useApiClient()

  const orderQuery = useQuery(
    ['orders'],
    ({ signal }) =>
      apiClient({
        endpoint: `/orders`,
        headers: {
          Accept: 'application/json',
        },
        params: {
          pagination: false,
        },
        signal,
      }).then(res => res.data),

    {
      initialData: [],
    },
  )

  const { data: orders, isFetching } = orderQuery

  const toggleFilterEmpty = () => {
    setFilterEmpty(!filterEmpty)
  }

  return (
    <div style={{ paddingTop: '0' }}>
      <HeaderBar>
        <Button labelStyle={{ color: '#ffffff' }} onClick={toggleFilterEmpty}>
          {filterEmpty ? (
            <Visibility color="#ffffff" />
          ) : (
            <VisibilityOff color="#ffffff" />
          )}
          {filterEmpty
            ? t('dashboard.actions.showAllOrders')
            : t('dashboard.actions.filterOrders')}
        </Button>
      </HeaderBar>
      {isFetching && <div>Bestellungen werden geladen...</div>}
      <Grid>
        {markings.map(marking => (
          <Orders
            key={marking}
            marking={marking}
            {...{ orders, fetching: isFetching, filterEmpty }}
          />
        ))}
      </Grid>
    </div>
  )
}

export default ResellerDashboard
