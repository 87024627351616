import GenericRestClientButton from 'Components/Common/ActionButtons/GenericRestClientButton'
import PropTypes from 'prop-types'
import React from 'react'

const ResendEmailButton = ({ record }) => (
  <GenericRestClientButton
    type="resendMail"
    action="resendMail"
    resource="reseller_email_events"
    payload={{ id: record.id }}
  />
)

ResendEmailButton.propTypes = {
  record: PropTypes.object.isRequired,
}

export default ResendEmailButton
