import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import {
  NonSortableLabel,
  TableWrapper,
} from 'Components/Aluxo/OrderLines/OrderLinesTable'
import currencyText from 'Lib/currencyText'
import { sumBy } from 'lodash'
import { useOrder } from 'OrderContext'
import React from 'react'
import {
  EditButton,
  ShowButton,
  useGetManyReference,
  useTranslate,
} from 'react-admin'

const OrderLines = () => {
  const { record } = useOrder()
  const t = useTranslate()

  const {
    palette: {
      primary: { main },
    },
  } = useTheme()

  const { data: dataObjects, ids, loading } = useGetManyReference(
    'also_workplace_order_lines',
    'order',
    record.id,
    false,
    false,
    false,
    'also_workplace_order_lines',
  )

  if (loading) {
    return <LinearProgress style={{ marginTop: '1em' }} />
  }

  const data = ids
    .map(id => dataObjects[id])
    .map((entry, index) => ({
      ...entry,
      reference: 'also_workplace_order_lines',
      pos: index + 1,
    }))

  return (
    <TableWrapper>
      <Table size="small" stripedRows fixedHeader={false} fixedFooter={false}>
        <TableHead displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableCell style={{ width: '40px' }}>
              <NonSortableLabel>
                {t('alsoOrderLines.fields.position')}
              </NonSortableLabel>
            </TableCell>
            <TableCell>
              <NonSortableLabel>
                {t('alsoOrderLines.fields.workplaceUserDevice')}
              </NonSortableLabel>
            </TableCell>
            <TableCell style={{ width: '100px' }}>
              <NonSortableLabel>
                {t('alsoOrderLines.fields.term')}
              </NonSortableLabel>
            </TableCell>
            <TableCell style={{ width: '40px', textAlign: 'right' }}>
              <NonSortableLabel>
                {t('alsoOrderLines.fields.quantity')}
              </NonSortableLabel>
            </TableCell>
            <TableCell style={{ width: '60px', textAlign: 'right' }}>
              <NonSortableLabel>
                {t('alsoOrderLines.fields.monthlyRate')}
              </NonSortableLabel>
            </TableCell>

            <TableCell style={{ width: '60px', textAlign: 'right' }}>
              <NonSortableLabel>
                {t('alsoOrderLines.fields.total')}
              </NonSortableLabel>
            </TableCell>
            <TableCell style={{ width: '100px' }}>
              <NonSortableLabel>
                {t('alsoOrderLines.fields.transmitted')}
              </NonSortableLabel>
            </TableCell>

            <TableCell style={{ width: '150px' }}>
              <NonSortableLabel />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody displayRowCheckbox={false}>
          {data
            .filter(d => d.workplaceConfigurationFields.length > 0)
            .map(
              ({
                pos,
                reference,
                id,
                workplaceConfigurationFields,
                subscriptionIds,
                monthlyRate,
                quantity,
                term,
                order: { marking },
              }) => (
                <TableRow key={id}>
                  <TableCell>{pos}</TableCell>
                  <TableCell>
                    {
                      workplaceConfigurationFields.find(
                        f => f.field === 'WorkplaceUserDevice',
                      )?.value
                    }
                  </TableCell>
                  <TableCell>
                    {t('alsoOrderLines.values.months', { months: term })}
                  </TableCell>
                  <TableCell style={{ textAlign: 'right' }}>
                    {quantity}
                  </TableCell>

                  <TableCell style={{ textAlign: 'right' }}>
                    {currencyText(monthlyRate)}
                  </TableCell>
                  <TableCell style={{ textAlign: 'right' }}>
                    {currencyText(monthlyRate * quantity)}
                  </TableCell>
                  <TableCell>
                    {subscriptionIds.length > 0 ? 'Ja' : 'Nein'}
                  </TableCell>

                  <TableCell>
                    {marking === 'opened' ? (
                      <EditButton basePath={`/${reference}`} record={{ id }} />
                    ) : (
                      <ShowButton basePath={`/${reference}`} record={{ id }} />
                    )}
                  </TableCell>
                </TableRow>
              ),
            )}
        </TableBody>
        <TableFooter adjustForCheckbox={false} style={{ color: main }}>
          <TableRow>
            <TableCell colSpan="4" />
            <TableCell style={{ textAlign: 'right' }}>
              {currencyText(sumBy(data, 'monthlyRate'))}
            </TableCell>

            <TableCell style={{ textAlign: 'right' }}>
              {currencyText(
                sumBy(
                  data,
                  ({ monthlyRate, quantity }) => quantity * monthlyRate,
                ),
              )}
            </TableCell>

            <TableCell colSpan="2" />
          </TableRow>
        </TableFooter>
      </Table>
    </TableWrapper>
  )
}

export default OrderLines
