import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import React from 'react'
import { Datagrid, Filter, List, TextField, TextInput } from 'react-admin'
import UpdateAlsoServicePortfolioButton from 'Components/Also/ActionButtons/UpdateAlsoServicePortfolioButton'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

const AlsoACMPServiceFilter = props => (
  <Filter {...props}>
    <TextInput source="displayName" />
    <TextInput source="value" />
    <TextInput source="field" />
  </Filter>
)
const Actions = ({
  resource,
  filters,
  displayedFilters,
  filterValues,
  showFilter,
}) => (
  <RightAlignedCardActions>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <UpdateAlsoServicePortfolioButton />
    {/* <UpdateAlsoApiDataButton /> */}
  </RightAlignedCardActions>
)

const AlsoACMPServiceList = props => (
  <List
    pagination={<CustomPagination />}
    {...props}
    actions={<Actions />}
    filters={<AlsoACMPServiceFilter />}
  >
    <Datagrid>
      <TextField source="displayName" />
      <TextField source="category" />
      <TextField source="externalId" />
      <TextField source="status" />
    </Datagrid>
  </List>
)

export default AlsoACMPServiceList
