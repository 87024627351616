import { LinearProgress } from '@material-ui/core'
import { debounce, max, round, sortBy } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useLoading } from 'react-admin'
import { useApiClient } from 'Rest/fetchHydra'

const getMaxStock = prices => max(prices.map(p => p.stock))

const FetchData = ({ children, includeItScope, itScopeSearchValue }) => {
  const loading = useLoading()

  const [itScopeProducts, setItScopeProducts] = useState([])

  const apiClient = useApiClient()

  const fetch = async value => {
    try {
      const res = await apiClient({
        endpoint: `/it_scope_products?keywords=${value}`,
      }).then(res => res.data)

      const filtered = Object.values(res).filter(
        ({ prices }) => prices.length > 0,
      )
      const sorted = sortBy(filtered, 'manufacturerSKU')

      setItScopeProducts(sorted)
    } catch (e) {
      // showNotification('Produkte konnten nicht geladen werden.')
    }
  }

  const debouncedFetch = useCallback(
    debounce(fetch, 1000, { trailing: true, leading: false }),
    [],
  )

  useEffect(() => {
    if (!itScopeSearchValue || itScopeSearchValue.length < 3) {
      return
    }

    debouncedFetch(itScopeSearchValue)
  }, [itScopeSearchValue, includeItScope])

  if (loading) {
    return (
      <div
        style={{
          minHeight: '72px',
          display: 'flex',
          alignItems: 'flex-end',
        }}
      >
        <LinearProgress style={{ width: '100%' }} mode="indeterminate" />
      </div>
    )
  }

  const itScopeData = itScopeProducts.map(p => ({
    ...p,
    price: round(p.averagePrice, 2),
    stock: getMaxStock(p.prices),
  }))

  return children({ itScopeData, loading })
}

export default FetchData
