import { put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { CRUD_UPDATE_SUCCESS, refreshView } from 'react-admin'
import { getIdOrIri } from 'Lib/helper'

function* afterUpdate(action) {
  const {
    meta: { resource },
    payload: { data },
  } = action
  // if (
  //   includes(
  //     [
  //       // 'device_order_lines',
  //       'product_order_lines',
  //       'service_order_lines',
  //       'protection_plan_order_lines',
  //     ],
  //     resource,
  //   )
  // ) {
  //   const {
  //     order: { id },
  //   } = data
  //   yield put(push(`/orders/${id}`))
  // }
  // if (
  //   includes(
  //     [
  //       'device_order_lines',
  //       'product_order_lines',
  //       'service_order_lines',
  //       'protection_plan_order_lines',
  //     ],
  //     resource,
  //   )
  // ) {
  //   const {
  //     order: { id },
  //   } = data
  //   yield put(push(`/orders/${id}`))
  // }
  // if (resource === 'also_workplace_order_lines') {
  //   const { order } = data
  //
  //   yield put(push(`/also_orders/${getIdOrIri(order.id)}`))
  // }
  if (resource === 'resellers') {
    const { id } = data
    yield put(push(`/resellers/${id}`))
  }
  if (resource === 'itscope_settings') {
    yield put(refreshView())
  }
  if (resource === 'orders') {
    yield put(refreshView())
  }
}

export default function* afterUpdateSaga() {
  yield takeLatest(CRUD_UPDATE_SUCCESS, afterUpdate)
}
