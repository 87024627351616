import React, { useEffect, useMemo } from 'react'
import { required, TextInput } from 'react-admin'
import { useField, useFormState } from 'react-final-form'
import DateInput from 'Components/Common/InputFields/DateInput'
import CasCustomerDiscountProducts from './CasCustomerDiscountProducts'
import CasCustomerExpiredProjects from './CasCustomerExpiredProjects'

export const DEAL_REG = 'dealReg'
export const ACCELERATE_PRICING = 'acceleratePricing'
export const NCAB = 'ncab'

export const validateNcabExpiresAt = (v, p) => {
  if (p.activeProject?.lines?.find(e => e.ncabDiscount > 0) && !v) {
    return 'dialogs.createCustomerCasProject.validate.missingNcabExpiresAt'
  }

  return null
}

const CasCustomerDiscountEdit = () => {
  const {
    values: { projects },
  } = useFormState({ subscription: { values: true } })

  const { input } = useField('activeProject')

  const [activeProject, archivedProjects] = useMemo(() => {
    const today = new Date().setHours(0, 0, 0, 0)
    const activeProject = projects.find(
      p => !p.archived && Date.parse(p.expiresAt) >= today,
    )
    const archivedProjects = projects.filter(p => p.id !== activeProject?.id)
    return [activeProject, archivedProjects]
  }, [projects])

  useEffect(() => {
    if (activeProject) {
      input.onChange(activeProject)
    }
  }, [activeProject])

  if (!activeProject) {
    return <CasCustomerExpiredProjects archivedProjects={archivedProjects} />
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', width: 256 }}>
        <TextInput
          source="activeProject.casNumber"
          label="resources.discounts.cas.cas"
          validate={[required()]}
        />
        <DateInput
          source="activeProject.expiresAt"
          label="resources.discounts.cas.expiresAt"
          options={{ disablePast: true }}
          validate={[required()]}
        />
        <DateInput
          source="activeProject.ncabExpiresAt"
          label="resources.discounts.cas.ncabExpiresAt"
          options={{ disablePast: true }}
          validate={validateNcabExpiresAt}
        />
      </div>
      <CasCustomerDiscountProducts />
      <CasCustomerExpiredProjects archivedProjects={archivedProjects} />
    </>
  )
}

export default CasCustomerDiscountEdit
