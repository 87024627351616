import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { Labeled } from 'react-admin'
import DeviceAutoSuggestField from 'Components/Common/InputFields/AutoSuggests/DeviceAutoSuggest/DeviceAutoSuggestField'
import { useField } from 'react-final-form'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  progress: {
    margin: theme.spacing(2),
  },
}))

const DeviceVariantListInput = ({ source, resource }) => {
  const classes = useStyles()

  const {
    input: { value, onChange },
  } = useField(source)

  const handleAdd = item => {
    if (!value.some(i => i.id === item.id)) {
      onChange([...value, item])
    }
  }

  const handleRemove = item => {
    onChange([...value.filter(i => i.id !== item.id)])
  }

  return (
    <Labeled source={source} resource={resource} fullWidth>
      <div className={classes.root}>
        <List>
          {value.map(d => (
            <ListItem>
              <ListItemText primary={d.name} secondary={d.sku} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleRemove(d)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <DeviceAutoSuggestField
          onChange={(item, stateAndHelpers) => {
            if (item) {
              const { id, name, sku } = item

              handleAdd({ id, name, sku })
            }
            stateAndHelpers.clearSelection()
          }}
          withITScope={false}
        />
      </div>
    </Labeled>
  )
}

DeviceVariantListInput.propTypes = {
  source: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
}

export default DeviceVariantListInput
