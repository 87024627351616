import React from 'react'
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  Edit,
  EditButton,
  List,
  SimpleForm,
  RefreshButton,
  TextField,
} from 'react-admin'
import EditToolbar from 'Components/Common/EditToolbar'
import EditActions from 'Components/Common/EditActions'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import GenericRestClientButton from 'Components/Common/ActionButtons/GenericRestClientButton'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

const DistributorListActions = ({
  resource,
  filters,
  displayedFilters,
  filterValues,
  basePath,
  showFilter,
}) => (
  <RightAlignedCardActions>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <RefreshButton />
    {/* Add your custom actions */}
    <GenericRestClientButton
      type="itScopeDistributorRefresh"
      action="itScopeDistributorRefresh"
    />
  </RightAlignedCardActions>
)

export const DistributorList = props => (
  <List
    {...props}
    pagination={<CustomPagination />}
    actions={<DistributorListActions />}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="supplierId" />
      <BooleanField source="active" hideFalseValues />
      <EditButton />
    </Datagrid>
  </List>
)

const LinkField = ({ record }) => (
  <a href={record.deepLink} target="_blank" rel="noopener noreferrer">
    {record.deepLink}
  </a>
)
LinkField.defaultProps = { addLabel: true }

export const DistributorEdit = props => (
  <Edit actions={<EditActions />} {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextField source="name" />
      <TextField source="supplierId" />
      <TextField source="customerAccountNumber" />
      <LinkField source="deepLink" />
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="active"
      />
    </SimpleForm>
  </Edit>
)
