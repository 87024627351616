import { CardActions } from '@material-ui/core'
import EditToolbar from 'Components/Common/EditToolbar'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import ReferenceInput from 'Components/Common/ReferenceInput'
import BooleanField from 'Components/Common/ViewFields/BooleanField'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import {
  BooleanInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  List,
  ListButton,
  NumberInput,
  SelectInput,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  usePermissions,
  useTranslate,
} from 'react-admin'
import { validateRequired } from 'Resources/Aluxo/devices'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

const EnhancedDeleteButton = props => {
  if (get(props.record, 'specialType')) return null
  return <DeleteButton {...props} />
}

const SpecialTypeField = ({ source, record = {} }) => {
  const t = useTranslate()
  if (!record[source]) {
    return null
  }
  return <span>{t(`resources.links.specialType.${record[source]}`)}</span>
}

export const LinkList = props => {
  const { permissions = [] } = usePermissions()

  return (
    <List
      {...props}
      pagination={<CustomPagination />}
      aside={<LanguageSwitcherAside />}
    >
      <Datagrid>
        <TextField source="name" />
        <TextField source="to" />
        <TextField source="area" />
        <TextField source="position" />
        {permissions.includes('ROLE_STOREVIEW') && (
          <TextField source="storeView.name" />
        )}
        <BooleanField source="active" />
        <SpecialTypeField source="specialType" />
        <EditButton />
        <EnhancedDeleteButton />
      </Datagrid>
    </List>
  )
}

const EditActions = ({ basePath, data, hasDelete, hasShow, hasList }) => (
  <CardActions>
    {hasShow && <ShowButton basePath={basePath} record={data} />}
    {hasList && <ListButton basePath={basePath} />}
    {hasDelete && <EnhancedDeleteButton basePath={basePath} record={data} />}
  </CardActions>
)

EditActions.propTypes = {
  basePath: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  hasShow: PropTypes.bool.isRequired,
  hasDelete: PropTypes.bool.isRequired,
  hasList: PropTypes.bool.isRequired,
}

const Title = ({ record }) => `Link ${get(record, 'name')}`

export const LinkEdit = props => {
  const { permissions = [] } = usePermissions() || []
  return (
    <Edit
      actions={<EditActions />}
      title={<Title />}
      aside={<LanguageSwitcherAside />}
      {...props}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="name" validate={validateRequired} />
        <TextInput source="to" />
        <BooleanInput source="active" />
        <NumberInput source="position" />
        <SelectInput
          source="area"
          choices={[
            { id: 'header', name: 'resources.links.area.header' },
            { id: 'mainmenu', name: 'resources.links.area.mainmenu' },
            { id: 'footer', name: 'resources.links.area.footer' },
            { id: 'footerBar', name: 'resources.links.area.footerBar' },
          ]}
        />
        {permissions.includes('ROLE_STOREVIEW') && (
          <ReferenceInput
            source="storeView"
            reference="store_views"
            validate={validateRequired}
          >
            <SelectInput optionText={choice => choice.name} />
          </ReferenceInput>
        )}
      </SimpleForm>
    </Edit>
  )
}

export const LinkCreate = props => {
  const { permissions = [] } = usePermissions()
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="to" />
        <SelectInput
          source="area"
          choices={[
            { id: 'header', name: 'resources.links.area.header' },
            { id: 'mainmenu', name: 'resources.links.area.mainmenu' },
            { id: 'footer', name: 'resources.links.area.footer' },
            { id: 'footerBar', name: 'resources.links.area.footerBar' },
          ]}
        />
        {permissions.includes('ROLE_STOREVIEW') && (
          <ReferenceInput
            source="storeView"
            reference="store_views"
            validate={validateRequired}
          >
            <SelectInput optionText={choice => choice.name} />
          </ReferenceInput>
        )}
      </SimpleForm>
    </Create>
  )
}
