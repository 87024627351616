import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import {
  Datagrid,
  EditButton,
  List,
  TextField,
  Filter,
  TextInput,
  ShowButton,
  usePermissions,
} from 'react-admin'

import { isAlsoAdmin, isAlsoReseller } from 'Lib/helper'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

const CustomerFilter = props => (
  <Filter {...props}>
    <TextInput source="addresses.firstName" />
    <TextInput source="addresses.lastName" />
    <TextInput source="addresses.company" />
  </Filter>
)

// eslint-disable-next-line
const AddressFieldField = ({ record, source }) => (
  <span>
    {get(
      record.addresses.find(a => a.type === 'billing'),
      source,
    )}
  </span>
)

const CustomerList = props => {
  const { permissions = [] } = usePermissions()

  if (!(isAlsoAdmin(permissions) || isAlsoReseller(permissions))) {
    return null
  }

  const alsoAdmin = isAlsoAdmin(permissions)

  return (
    <List
      pagination={<CustomPagination />}
      {...props}
      filters={<CustomerFilter />}
    >
      <Datagrid>
        {alsoAdmin && <TextField source="reseller.name" />}
        <TextField source="customerNumber" />
        <AddressFieldField source="lastName" />
        <AddressFieldField source="firstName" />
        <AddressFieldField source="company" />
        {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
          <TextField source="storeView.name" />
        )}
        {alsoAdmin ? <ShowButton /> : <EditButton />}
      </Datagrid>
    </List>
  )
}

CustomerList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default CustomerList
