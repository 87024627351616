import {
  Datagrid,
  EditButton,
  ShowButton,
  List,
  TextField,
  usePermissions,
} from 'react-admin'
import moment from 'moment'
import React from 'react'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import BooleanField from 'Components/Common/ViewFields/BooleanField'
import DateField from 'Components/Common/ViewFields/DateField'
import StatusField from 'Components/Common/Orders/Fields/StatusField'
import OrderFilter from 'Components/Common/Orders/OrderFilter'

import { isAlsoAdmin, isAlsoReseller } from 'Lib/helper'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

moment.locale('de')

const OrderList = props => {
  const { permissions = [] } = usePermissions()

  if (!(isAlsoAdmin(permissions) || isAlsoReseller(permissions))) {
    return null
  }

  const alsoAdmin = isAlsoAdmin(permissions)

  return (
    <List
      {...props}
      filters={<OrderFilter />}
      pagination={<CustomPagination />}
      sort={{ field: 'orderNumber', order: 'DESC' }}
    >
      <Datagrid>
        {alsoAdmin && <TextField source="reseller.name" />}
        <TextField source="orderNumber" />
        <StatusField source="marking" sortable={false} />
        <DateField source="created" format="DD.MM.YYYY" />
        <TextField source="billingAddress.company" />
        <CurrencyField
          label="resources.also_orders.fields.total"
          source="total"
        />
        <BooleanField source="tradingModel" />
        {alsoAdmin ? <ShowButton /> : <EditButton />}
      </Datagrid>
    </List>
  )
}

export default OrderList
