import { Checkbox, FormControlLabel, TextField } from '@material-ui/core'
import React from 'react'
import { Field } from 'react-final-form'

const getField = ({ label, name, type, initialValue, parse, format, rows }) => {
  switch (type) {
    case 'checkbox':
      return (
        <Field
          name={name}
          type={type}
          label={label}
          initialValue={initialValue}
          format={format}
          parse={parse}
        >
          {({ input, name, label }) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={input.checked}
                  onChange={input.onChange}
                  value={name}
                />
              }
              label={label}
            />
          )}
        </Field>
      )
    case 'textarea':
    case 'text':
      return (
        <Field
          name={name}
          type={type}
          label={label}
          format={format}
          parse={parse}
          render={({ input, name, label }) => (
            <TextField
              name={name}
              label={label}
              multiline={type === 'textarea'}
              rows={rows}
              fullWidth
              {...input}
            />
          )}
          initialValue={initialValue}
        />
      )

    default:
      return null
  }
}

const getContextFormElements = ({ context, withDialog }) => {
  if (!context) {
    return []
  }
  const { content, dialog } = context

  const elements = [...content]

  if (withDialog && dialog) {
    dialog.forEach(element => elements.push(element))
  }

  return elements.map(getField)
}

export default getContextFormElements
