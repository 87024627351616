export default {
  labels: {
    priceableItemId: 'ACMP Item ID',
    chargeType: 'Berechnungsart',
    isUnitDependent: 'Mengenabhängig',
    fieldDisplayName: 'Auswahlwart',
    fieldDisplayValue: 'Auswahlausprägung',
    unitDependentFieldDisplayName: 'Menge abhängig von',
    internalChargeType: 'Berechnungsart',
    internalDependendOn: 'Abhängig von',
  },
  chargeTypes: {
    none: 'keine',
    fixed: 'Einmalig',
    monthly: 'Monatlich',
  },
  dependentOn: {
    none: 'keine',
    workplaces: 'Arbeitsplatz',
  },
  actions: {
    save: 'Speichern',
  },
}
