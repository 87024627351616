import React from 'react'
import GenericRestClientButton from '../../Common/ActionButtons/GenericRestClientButton'

const RefreshITScopePricesButton = () => (
  <GenericRestClientButton
    action="refreshITScopePrices"
    type="itScopeUpdatePrices"
  />
)

export default RefreshITScopePricesButton
