import React from 'react'
import {
  Edit,
  email,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  translate,
  TopToolbar,
} from 'react-admin'

import EditToolbar from 'Components/Common/EditToolbar'
import TestSMTPButton from 'Components/Common/ActionButtons/TestSMTPButton'
import { validateRequired } from 'Resources/Aluxo/devices'

const EditActions = () => (
  <TopToolbar>
    <TestSMTPButton />
  </TopToolbar>
)

const SMTPConfigurationTitle = translate(({ translate: t }) =>
  t('resources.reseller_smtp_configurations.name'),
)

export const validateRequiredEmail = [required(), email()]

export const SMTPConfigurationEdit = props => (
  <Edit title={<SMTPConfigurationTitle />} actions={<EditActions />} {...props}>
    <SimpleForm toolbar={<EditToolbar />} redirect={false}>
      <TextInput
        source="username"
        validate={validateRequired}
        options={{ autocomplete: 'off' }}
      />
      <TextInput
        type="password"
        source="password"
        options={{ autocomplete: 'new-password' }}
      />
      <TextInput source="host" validate={validateRequired} />
      <TextInput source="port" validate={validateRequired} />
      <SelectInput
        source="encryption"
        choices={[
          { id: 'ssl', name: 'SSL' },
          { id: 'tls', name: 'TLS' },
          { id: 'starttls', name: 'STARTTLS' },
        ]}
        validate={validateRequired}
      />
      <TextInput source="senderName" validate={validateRequired} />
      <TextInput source="senderAddress" validate={validateRequiredEmail} />
    </SimpleForm>
  </Edit>
)
