import React from 'react'
import PropTypes from 'prop-types'
import padStart from 'lodash/padStart'
import {
  ListItem,
  IconButton,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'

const SortableItem = ({
  item,
  moveItem,
  sortProperty,
  length,
  displayProperty,
}) => {
  const moveUp = () => {
    moveItem(item, item[sortProperty] - 1)
  }
  const moveDown = () => {
    moveItem(item, item[sortProperty] + 1)
  }

  return (
    <ListItem style={{ color: item.changed ? 'grey' : undefined }}>
      <ListItemText
        primary={`${padStart(item[sortProperty] + 1, 2)}. ${
          item[displayProperty]
        }`}
        secondary={item.position}
      />
      <ListItemSecondaryAction>
        {item[sortProperty] > 0 && (
          <IconButton>
            <ArrowDropUp onClick={moveUp} />
          </IconButton>
        )}
        {item[sortProperty] < length - 1 && (
          <IconButton>
            <ArrowDropDown onClick={moveDown} />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

SortableItem.propTypes = {
  length: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  moveItem: PropTypes.func.isRequired,
  sortProperty: PropTypes.string.isRequired,
  displayProperty: PropTypes.string.isRequired,
}

export default SortableItem
