import get from 'lodash/get'
import { calculateBundlePrice } from './OrderLines'

const useWorkplaceLines = ({ lines, bundleLine }) => {
  const bundledLines = bundleLine
    ? lines.filter(
        line =>
          bundleLine.deviceOrderLine === line['@id'] ||
          bundleLine.productOrderLines.some(iri => iri === line['@id']),
      )
    : []

  const bundleLineToDisplay =
    get(bundleLine, 'type') !== 'internal'
      ? bundleLine
      : calculateBundlePrice({ bundleLine, bundledLines })

  const linesToDisplay = [
    bundleLineToDisplay,
    ...lines.filter(l => !bundledLines.some(({ id }) => id === l.id)),
  ].filter(line => line)

  return { linesToDisplay, bundledLines }
}

export default useWorkplaceLines
