import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'
import { validateRequired } from 'Resources/Aluxo/devices'
import CKEInput from 'Components/Common/InputFields/CKEInput'

const AlsoResellerCustomServiceCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={validateRequired} />
      <TextInput source="manufacturer" validate={validateRequired} />
      <TextInput source="category" validate={validateRequired} />
      <CKEInput source="description" fullWidth />
    </SimpleForm>
  </Create>
)

export default AlsoResellerCustomServiceCreate
