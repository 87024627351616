import { SelectInput } from 'react-admin'
import React, { useContext, useMemo } from 'react'
import { ResellerContext } from 'ResellerContext'
import { useQuery } from 'react-query'
import { useApiClient } from 'Rest/fetchHydra'
import { useField } from 'react-final-form'
import { Box } from '@material-ui/core'

export default function DllAssetTypeSelect() {
  const { flags } = useContext(ResellerContext)
  const apiClient = useApiClient()

  const {
    input: { value: dllAssetTypeValue },
  } = useField('dllAssetType')

  const apiData = useQuery(
    ['dll_asset_types'],
    () =>
      apiClient({
        endpoint: `/dll_get_asset_types`,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        method: 'POST',
        body: {},
      }).then(res => res.data.dllAssetTypes),

    {
      initialData: [],
    },
  )

  const { assetTypes, brandNames } = useMemo(() => {
    const assetTypes = apiData.data.map(t => t.name)
    const brandNames =
      apiData.data
        .find(t => t.name === dllAssetTypeValue)
        ?.brands.map(t => t.name) || []

    return { assetTypes, brandNames }
  }, [apiData, dllAssetTypeValue])

  if (!flags.multipleFinancePartners) {
    return null
  }

  return (
    <Box display="flex" width="100%">
      <Box mr={1}>
        <SelectInput
          source="dllAssetType"
          label="resources.device_order_lines.fields.dllAssetType"
          choices={assetTypes.map(value => ({ id: value, name: value }))}
        />
      </Box>
      <Box>
        <SelectInput
          source="dllBrandName"
          label="resources.device_order_lines.fields.dllBrandName"
          choices={brandNames.map(value => ({ id: value, name: value }))}
        />
      </Box>
    </Box>
  )
}
