import { Box } from '@material-ui/core'
import DevicesTable from 'Components/Aluxo/ExternalProtectionPlans/DevicesTable'
import DateInput from 'Components/Common/InputFields/DateInput'
import StatusLabel from 'Components/Common/StatusLabel'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import React from 'react'
import {
  Edit,
  FormDataConsumer,
  FunctionField,
  required,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'
import { dateSettingsRent } from './ExternalProtectionPlanCreate'
import { OrderLabel } from './labels'
import styles from './styles'

const defaultValues = {
  orderCreate: false,
}

const validateRequired = required()

const BaseExternalProtectionPlanEdit = props => (
  <Edit {...props}>
    <SimpleForm defaultValue={defaultValues}>
      <TextInput
        options={{
          validate: [required],
          style: { width: '100%' },
          listStyle: { width: '100%' },
          textFieldStyle: { width: '100%' },
        }}
        style={styles.firstColumn}
        source="customer"
        validate={validateRequired}
      />

      <DateInput
        options={dateSettingsRent}
        source="date"
        style={styles.firstColumn}
        validate={validateRequired}
        label="resources.external_protection_plans.fields.deliveryDate"
      />

      <DateInput
        options={dateSettingsRent}
        source="insuranceDate"
        style={styles.firstColumn}
        validate={validateRequired}
        label="resources.external_protection_plans.fields.insuranceDate"
      />

      <FunctionField
        style={styles.firstColumn}
        label="resources.external_protection_plans.labels.status"
        render={record => <StatusLabel record={record} />}
      />

      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.order && (
            <Box mb={2}>
              <OrderLabel />
              <TextField
                label="Bestellnummer"
                source="order.orderNumber"
                style={styles.indentedColumn}
                {...rest}
              />
              <CurrencyField
                source="order.totalVolume"
                style={styles.indentedColumn}
                label="Bruttoverkaufspreis inkl. MwSt."
                {...rest}
              />
            </Box>
          )
        }
      </FormDataConsumer>

      <DevicesTable name="devices" />
    </SimpleForm>
  </Edit>
)

export default BaseExternalProtectionPlanEdit
