import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslate } from 'react-admin'

export const NullTitle = () => null

const TranslatedTitle = ({ translateKey, style, smartCount }) => {
  const translate = useTranslate()

  return (
    <Typography
      variant="h1"
      style={{ fontSize: '1.6rem', fontWeight: '500', ...style }}
      gutterBottom
    >
      {translate(translateKey, { smart_count: smartCount })}
    </Typography>
  )
}

TranslatedTitle.propTypes = {
  translateKey: PropTypes.string.isRequired,
  style: PropTypes.object,
  smartCount: PropTypes.number,
}

TranslatedTitle.defaultProps = {
  smartCount: 1,
  style: {},
}

export default TranslatedTitle
