import BackToAlsoProductButton from 'Components/Also/ActionButtons/BackToAlsoProductButton'
import CreateInformationTabButton from 'Components/Common/Buttons/CreateInformationTabButton'
import ImagesInput from 'Components/Common/InputFields/ImagesInput'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import {
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FormTab,
  ReferenceManyField,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin'
import CNetImagesView from 'Components/Common/ViewFields/CNetImagesView'
import UpdateCNetMetaDataButton from 'Components/Also/ActionButtons/UpdateCNetMetaDataButton'

const AlsoProductVariantTitle = ({ record }) => get(record, 'name')

const EditActions = ({ data /* , basePath */ }) => (
  <RightAlignedCardActions>
    <BackToAlsoProductButton data={data} />
    <UpdateCNetMetaDataButton data={data} />
    <CreateInformationTabButton
      type="also_product_variant_information_tabs"
      reference={get(data, '@id')}
    />
  </RightAlignedCardActions>
)

EditActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
}

export const AlsoProductVariantEdit = props => (
  <Edit
    title={<AlsoProductVariantTitle />}
    actions={<EditActions />}
    aside={<LanguageSwitcherAside />}
    {...props}
  >
    <TabbedForm redirect={false}>
      <FormTab label="resources.also_product_variants.tabs.general">
        <TextField source="enumMember.displayName" />
        <TextInput source="name" fullWidth />

        <RichTextInput source="shortDescription" />
        <TextInput source="sku" />
        <TextInput source="alsoProductId" />
        <CurrencyField source="monthlyPrices.18" />
        <CurrencyField source="monthlyPrices.24" />
        <CurrencyField source="monthlyPrices.36" />
        <CurrencyField source="monthlyPrices.48" />

        <ImagesInput
          resourceName="also_product_images"
          referenceName="productVariant"
        />
      </FormTab>
      <FormTab label="resources.also_product_variants.tabs.information_tabs">
        <ReferenceManyField
          label="resources.also_product_variants.fields.information_tabs"
          reference="also_product_variant_information_tabs"
          target="reference"
        >
          <Datagrid>
            <TextField source="title" />
            <EditButton />
            <DeleteButton redirect={false} />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      <FormTab label="resources.also_product_variants.tabs.cnet">
        <CNetImagesView
          filterName="productVariant"
          imageResource="also_product_variant_cnet_images"
        />
      </FormTab>
    </TabbedForm>
  </Edit>
)

export default AlsoProductVariantEdit
