import { get } from 'lodash'
import { GSALES_CUSTOMER_ID } from 'Lib/constants'
import Fuse from 'fuse.js'

const getSuggestions = (data, value) => {
  const suggestions = data.map(c => ({
    value: c.id,
    id: c.id,
    email: c.email,
    company: c.company,
    label: c.company,
    aluxo: c['@type'] === 'Customer',
    gsales: Boolean(
      get(c, `fields.${GSALES_CUSTOMER_ID}`) ||
        c['@type'] === 'gsales_customer',
    ),
  }))

  const fuse = new Fuse(suggestions, {
    keys: ['company', 'email'],
  })

  const inputValue = value || ''.trim().toLowerCase()

  const results = fuse.search(inputValue)

  return results.map(r => r.item)
}
export default getSuggestions
