import germanMessages from 'ra-language-german'
import { defaultsDeep } from 'lodash'

export default defaultsDeep(
  {},
  {
    action: {
      // list: 'Zurück',
    },
    page: {
      loading: 'Daten werden geladen',
      empty: 'Keine %{name} vorhanden',
      invite: 'Möchten Sie ein neues anlegen?',
    },
    notification: {
      deleted:
        'Element wird gelöscht |||| %{smart_count} Elemente werden gelöscht',
      updated:
        'Element wird aktualisiert |||| %{smart_count} Elemente werden aktualisiert',
      created: 'Element wird erstellt',
      logged_out: 'Ihre Sitzung ist abgelaufen.',
    },
    auth: {
      useraccount: 'Benutzeraccount',
      username: 'E-Mail',
      reset: 'Neues Passwort anfordern',
      newPassword: 'Neues Passwort',
      setPassword: 'Passwort setzen',
      auth_check_error: 'Bitte melden Sie sich erneut an.',
    },
    input: {
      file: {
        max_size: 'Maximale Dateigröße: %{maxSize}',
      },
      autocomplete: {
        none: '',
      },
      embedded_array: {
        add_product: 'Produkt hinzufügen',
        remove_product: 'Produkt löschen',
      },
    },
  },
  germanMessages.ra,
)
