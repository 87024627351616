import { Field } from 'react-final-form'
import React from 'react'
import { Labeled } from 'react-admin'
import PropTypes from 'prop-types'
import currencyText from 'Lib/currencyText'

const renderArrayField = ({ input, label }) => (
  <Labeled label={label}>
    <div>
      {Object.entries(input.value || {}).map(([key, value]) => (
        <div>
          <span>{`${key}: ${currencyText(value || 0)}`}</span>
        </div>
      ))}
    </div>
  </Labeled>
)

renderArrayField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.node.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
}

const KeyValueCurrencyField = ({ source, ...rest }) => (
  <Field name={source} component={renderArrayField} {...rest} />
)

KeyValueCurrencyField.propTypes = {
  source: PropTypes.string.isRequired,
  addLabel: PropTypes.bool,
}

KeyValueCurrencyField.defaultProps = {
  addLabel: false,
}

export default KeyValueCurrencyField
