import get from 'lodash/get'
import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  FormTab,
  List,
  NumberField,
  NumberInput,
  ReferenceManyField,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin'
import { validateRequired } from 'Resources/Aluxo/devices'
import EditActions from 'Components/Common/EditActions'
import EditToolbar from 'Components/Common/EditToolbar'
import FontawesomeIconInput from 'Components/Common/FontawesomeIconInput'
import MetaInput from 'Components/Common/InputFields/MetaInput'
import TinyMCEInput from 'Components/Common/InputFields/TinyMCEInput'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

const AlsoCategoryFilter = props => (
  <Filter {...props}>
    <TextInput source="name" />
    <BooleanInput
      options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
      source="active"
    />
  </Filter>
)

export const AlsoCategoryList = props => (
  <List
    {...props}
    filters={<AlsoCategoryFilter />}
    pagination={<CustomPagination />}
    aside={<LanguageSwitcherAside />}
  >
    <Datagrid>
      <TextField source="name" />
      <BooleanField source="active" hideFalseValues />
      <NumberField source="position" />
      <EditButton />
    </Datagrid>
  </List>
)

const AlsoCategoryTitle = ({ record }) => get(record, 'name')

export const AlsoCategoryEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<AlsoCategoryTitle />}
    aside={<LanguageSwitcherAside />}
    {...props}
  >
    <TabbedForm toolbar={<EditToolbar />}>
      <FormTab label="resources.also_categories.tabs.general">
        <TextInput source="slug" validate={validateRequired} />
        <TextInput source="name" validate={validateRequired} />

        <FontawesomeIconInput
          source="iconString"
          validate={validateRequired}
          style={{ width: '5em' }}
        />
        <NumberInput source="position" validate={validateRequired} />
        <RichTextInput source="description" />
        <BooleanInput
          options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
          source="active"
        />
        <MetaInput />
      </FormTab>
      <FormTab label="resources.also_categories.tabs.products">
        <ReferenceManyField
          label="resources.also_categories.tabs.products"
          reference="also_products"
          target="category"
        >
          <Datagrid>
            <TextField source="name" />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
)

const defaultValues = {
  active: true,
}

export const AlsoCategoryCreate = props => (
  <Create {...props}>
    <SimpleForm defaultValue={defaultValues}>
      <TextInput source="name" validate={validateRequired} />
      <TinyMCEInput source="description" />
      <FontawesomeIconInput
        source="iconString"
        validate={validateRequired}
        style={{ width: '5em' }}
      />
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="active"
      />
    </SimpleForm>
  </Create>
)
