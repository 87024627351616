import { Button, FormControl, FormLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CloudUpload, Description } from '@material-ui/icons'
import { head } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useMutation, useNotify, useTranslate } from 'react-admin'
import Dropzone from 'react-dropzone'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components/macro'

const StyledForm = styled.form`
  display: flex;
  align-items: flex-end;

  & > * {
    margin: 0 1em;
  }
`

const StyledDropzone = styled(Dropzone)`
  background-color: #f0f0f0;
  border: dashed;
  border-color: #c8c8c8;
  cursor: pointer;
  height: 144px;
  width: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DropZoneLink = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
  height: 20px;
  margin-top: 5px;
`

const DropZoneContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  & > *:first-child {
    margin-bottom: 1rem;
  }
`

const useStyles = makeStyles(theme => ({
  formLabel: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export const required = value => (value ? undefined : 'Required')

const CommonMediaObjectUploadForm = ({ onUpload }) => {
  const t = useTranslate()
  const showNotification = useNotify()
  const classes = useStyles()

  const [mutate, { loading }] = useMutation()

  const handleUpload = async values => {
    mutate(
      {
        type: 'createMultipart',
        resource: 'media_objects',
        payload: {
          data: {
            ...values,
          },
        },
      },
      {
        onSuccess: async res => {
          showNotification('notifications.attachmentCreated')
          onUpload(res)
        },
        onFailure: () => {
          showNotification('notifications.attachment.fail', 'warning')
        },
      },
    )
  }

  return (
    <Form
      onSubmit={handleUpload}
      render={({ handleSubmit, form, invalid }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Field
            name="file"
            validate={required}
            render={({ input }) => (
              <FormControl>
                <FormLabel className={classes.formLabel}>Dateiupload</FormLabel>
                <StyledDropzone
                  onDrop={acceptedFiles => {
                    input.onChange(head(acceptedFiles))
                  }}
                  multiple={false}
                >
                  {input.value ? (
                    <DropZoneContent>
                      <Description />
                      <DropZoneLink>{input.value.name}</DropZoneLink>
                    </DropZoneContent>
                  ) : (
                    <CloudUpload />
                  )}
                </StyledDropzone>
              </FormControl>
            )}
          />
          <Buttons>
            <Button disabled={invalid} color="primary" onClick={handleSubmit}>
              {loading ? t('state.uploading') : t('actions.upload')}
            </Button>
            <Button disabled={loading} onClick={form.reset}>
              {t('actions.cancel')}
            </Button>
          </Buttons>
        </StyledForm>
      )}
    />
  )
}

CommonMediaObjectUploadForm.propTypes = {
  record: PropTypes.object, // eslint-disable-line
}

export default CommonMediaObjectUploadForm
