import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Button from 'Components/Common/Buttons/Button'
import produce from 'immer/dist/immer'
import calculateOrders, {
  addShippingToOrder,
  getInitialOrderComment,
} from 'Lib/calculateOrders'
import { head, sortBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useGetOne, useMutation, useNotify } from 'react-admin'
import { useHistory, useParams } from 'react-router'
import { useApiClient } from 'Rest/fetchHydra'
import ITScopeSplitOrder from './ITScopeSplitOrder'

const Transition = props => <Slide direction="up" {...props} />

const useStyles = makeStyles({
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
})

const ITScopeOrderDialog = () => {
  const { id } = useParams()
  const history = useHistory()

  const { data: record } = useGetOne('orders', id)
  const classes = useStyles()
  const showNotification = useNotify()
  const apiClient = useApiClient()

  const [prices, setPrices] = useState([])
  const [distributors, setDistributors] = useState([])

  const [splitOrder, setSplitOrder] = useState(null)
  const [errors, setErrors] = useState([])

  const orderIsEnabled = useMemo(() => {
    if (
      record?.workflow === 'rent_order' &&
      record?.marking === 'reseller_signed'
    ) {
      return true
    }
    if (record?.workflow === 'buy_order' && record?.marking === 'opened') {
      return true
    }
    return false
  }, [record])

  useEffect(() => {
    if (distributors.length > 0) {
      const { orders, errors } = calculateOrders(prices, distributors)
      const sorted = sortBy(Object.values(orders), 'total')
      setSplitOrder(head(sorted))
      setErrors(errors)
    }
  }, [prices, distributors])

  const handleClose = () => {
    history.push(`/orders/${id}`)
  }

  const [mutate] = useMutation()

  const fetchPrices = async lines => {
    const priceRes = await apiClient({
      endpoint: '/it_scope_stock_and_prices',
      method: 'POST',
      body: { lines },
    }).then(res => res.data)

    setPrices(priceRes)
  }

  const fetchDistributors = () => {
    mutate(
      {
        type: 'getList',
        resource: 'itscope_distributors',
        payload: {
          pagination: false,
          sort: false,
          filter: { active: true },
        },
      },
      {
        onSuccess: ({ data }) => {
          setDistributors(data)
        },
      },
    )
    // const distRes = await fetchJson(GET_LIST, 'itscope_distributors', {
    //   pagination: false,
    //   sort: {},
    //   filter: { active: true },
    // })

    // setDistributors(distRes.data)
  }

  const handleClick = async () => {
    try {
      const lineRes = await apiClient({
        endpoint: `/orders/${id}/get_lines`,
      }).then(res => res.data)

      await Promise.all([fetchPrices(lineRes), fetchDistributors()])
    } catch (e) {
      showNotification('notifications.createITScopeOrderOrders.fail')
    }
  }

  useEffect(() => {
    handleClick()
  }, [id])

  const createOrders = async () => {
    mutate(
      {
        type: 'createItScopeOrders',
        resource: 'orders',
        payload: {
          id,
          data: splitOrder,
        },
      },
      {
        onSuccess: () => {
          handleClose()
          showNotification('notifications.createITScopeOrderOrders.success')
        },
        onFailure: () => {
          showNotification('notifications.createITScopeOrderOrders.fail')
        },
      },
    )
  }

  const handleChange = ({ index, lineId, values }) => {
    setSplitOrder(splitOrder =>
      produce(splitOrder, draft => {
        draft.orders[index].lines[lineId].price.price = values.price
        draft.orders[index].lines[lineId].quantity = values.quantity
      }),
    )
  }

  const handleRemove = ({ index, lineId }) => {
    setSplitOrder(splitOrder =>
      produce(splitOrder, draft => {
        delete draft.orders[index].lines[lineId]
      }),
    )
  }

  const handleComment = ({ index, comment }) => {
    setSplitOrder(splitOrder =>
      produce(splitOrder, draft => {
        draft.orders[index].comment = comment
      }),
    )
  }

  const handleChangeSupplier = ({ index, alternative, lineId }) => {
    setSplitOrder(splitOrder =>
      produce(splitOrder, draft => {
        const newLine = {
          ...draft.orders[index].lines[lineId],
          price: {
            ...alternative,
            // price:
            //   draft.orders[index].lines[lineId].discount > 0
            //     ? alternative.price - draft.orders[index].lines[lineId].discount
            //     : alternative.price,
          },
        }

        delete draft.orders[index].lines[lineId]

        draft.orders[index].comment = getInitialOrderComment(
          draft.orders[index],
        )

        const newIndex = draft.orders.findIndex(
          o => o.supplierId === alternative.supplierId,
        )
        if (newIndex >= 0) {
          draft.orders[newIndex].lines[lineId] = newLine
          draft.orders[newIndex].comment = getInitialOrderComment(
            draft.orders[newIndex],
          )
        } else {
          const newOrder = {
            supplierId: alternative.supplierId,
            supplier: {
              name: alternative.supplierName,
            },
            lines: {
              [lineId]: newLine,
            },
          }
          newOrder.comment = getInitialOrderComment(newOrder)
          draft.orders.push(newOrder)
        }

        if (Object.keys(draft.orders[index].lines).length === 0) {
          draft.orders.splice(index, 1)
        }

        draft.orders = addShippingToOrder({
          orders: draft.orders,
          distributors,
        })
      }),
    )
  }
  const handleSetSupplier = ({ alternative, details }) => {
    const lineId = alternative.puid
    setSplitOrder(splitOrder =>
      produce(splitOrder, draft => {
        const newLine = {
          ...details,
          quantity: details.totalQuantity,
          puid: alternative.puid,
          sku: details.manufacturerSKU,
          name: details.name,
          manufacturerSKU: details.manufacturerSKU,
          deepLink: 'https://www.itscope.com/red/app#products/page/',
          price: {
            ...alternative,
            // price:
            //   details.discount > 0
            //     ? alternative.price - details.discount
            //     : alternative.price,
          },
        }
        const newIndex = draft.orders.findIndex(
          o => o.supplierId === alternative.supplierId,
        )
        if (newIndex >= 0) {
          draft.orders[newIndex].lines[lineId] = newLine
          draft.orders[newIndex].comment = getInitialOrderComment(
            draft.orders[newIndex],
          )
        } else {
          const newOrder = {
            supplierId: alternative.supplierId,
            supplier: {
              name: alternative.supplierName,
            },
            lines: {
              [lineId]: newLine,
            },
          }
          newOrder.comment = getInitialOrderComment(newOrder)
          draft.orders.push(newOrder)
        }

        draft.orders = addShippingToOrder({
          orders: draft.orders,
          distributors,
        })
      }),
    )
  }

  return (
    <Dialog
      fullWidth
      fullScreen
      maxWidth={false}
      aria-labelledby="simple-dialog-title"
      open
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="title" color="inherit" className={classes.flex}>
            Simulierte Bestellung
          </Typography>
          <Button
            onClick={handleClick}
            disableOnGlobalLoad
            spinOnGlobal
            color="secondary"
            label="Neu laden"
          />
          <Button
            onClick={createOrders}
            disableOnGlobalLoad
            spinOnGlobal
            color="inherit"
            label="Abschicken"
            disabled={!orderIsEnabled}
          />
        </Toolbar>
      </AppBar>
      <div>
        <ITScopeSplitOrder
          splitOrder={splitOrder}
          errors={errors}
          expected={record?.itScopeVolume}
          handleChange={handleChange}
          handleRemove={handleRemove}
          handleComment={handleComment}
          handleChangeSupplier={handleChangeSupplier}
          handleSetSupplier={handleSetSupplier}
        />
      </div>
    </Dialog>
  )
}

export default ITScopeOrderDialog
