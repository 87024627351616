import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useNotify, useTranslate, useUpdateLoading } from 'react-admin'
import { useHistory } from 'react-router-dom'
import { useMenuStyles } from '../Layout/Menu'
import { getLinkFromIri } from 'Lib/helper'
import { useApiClient } from 'Rest/fetchHydra'

const CustomSettingsMenuItem = ({ icon, type }) => {
  const classes = useMenuStyles()
  const { startLoading, stopLoading } = useUpdateLoading()
  const showNotification = useNotify()
  const translate = useTranslate()
  const history = useHistory()
  const apiClient = useApiClient()

  const handleClick = async () => {
    try {
      startLoading()

      const res = await apiClient({ endpoint: '/me' }).then(res => res.data)

      const iri = get(res, `${type}.@id`) || get(res, type)

      if (iri) {
        // Quick fix for store views
        if (iri.includes('store_views'))
          history.push(
            getLinkFromIri(iri).replace(
              'store_views',
              'reseller_website_configurations',
            ),
          )
        else {
          history.push(getLinkFromIri(iri))
        }
      }
    } catch (error) {
      showNotification(translate('errors.errorResellerNotFound'), 'warning')
    }
    stopLoading()
  }

  return (
    <Tooltip title={translate(`menu.settings.${type}`)} placement="right">
      <MenuItem onClick={handleClick}>
        {icon && (
          <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
        )}
        <ListItemText
          className={classes.listItemText}
          primary={translate(`menu.settings.${type}`)}
        />
      </MenuItem>
    </Tooltip>
  )
}

CustomSettingsMenuItem.propTypes = {
  record: PropTypes.object,
  type: PropTypes.string.isRequired,
}

CustomSettingsMenuItem.defaultProps = {
  icon: null,
}

export default CustomSettingsMenuItem
