export const DOWNLOAD_ATTACHMENT = 'DOWNLOAD_ATTACHMENT'
export const DOWNLOAD_ALL_ATTACHMENTS = 'DOWNLOAD_ALL_ATTACHMENTS'
export const PROCESS_ORDER = 'PROCESS_ORDER'
export const SEND_ORDER_EMAIL = 'SEND_ORDER_EMAIL'
export const LOCK_ORDER = 'LOCK_ORDER'
export const UNLOCK_ORDER = 'UNLOCK_ORDER'
export const REPORTING_ORDER = 'REPORTING_ORDER'
export const ASSIGN_CUSTOMER_TO_ORDER = 'ASSIGN_CUSTOMER_TO_ORDER'
export const REFRESH_ACMP = 'REFRESH_ACMP' // TODO remove this
export const TEST_SMTP = 'TEST_SMTP'
export const TEST_CREDENTIALS = 'TEST_CREDENTIALS'
export const REFRESH_ITSCOPE_DISTRIBUTORS = 'REFRESH_ITSCOPE_DISTRIBUTORS'
export const REFRESH_ITSCOPE_PRICES = 'REFRESH_ITSCOPE_PRICES'
export const REFRESH_PRICES = 'REFRESH_PRICES'
export const UPDATE_ITSCOPE_PRICE = 'UPDATE_ITSCOPE_PRICE'
export const UPDATE_ITSCOPE_METADATA = 'UPDATE_ITSCOPE_METADATA'
export const FIND_ITSCOPE_BUNDLE = 'FIND_ITSCOPE_BUNDLE'
export const BULK_UPDATE_ITSCOPE_METADATA = 'BULK_UPDATE_ITSCOPE_METADATA'
export const UPDATE_ORDER_LINES_STOCK = 'UPDATE_ORDER_LINES_STOCK'
export const CANCEL_EXTERNAL_PROTECTION_PLAN_DEVICE =
  'CANCEL_EXTERNAL_PROTECTION_PLAN_DEVICE'
export const LOAD_ORDERS_CUSTOMER = 'LOAD_ORDERS_CUSTOMER'
export const RESET_CUSTOMER_PASSWORD = 'RESET_CUSTOMER_PASSWORD'
export const CREATE_IT_SCOPE_CART = 'CREATE_IT_SCOPE_CART'
export const GET_CONTEXT = 'GET_CONTEXT'
export const MARK_ATTACHMENT_AS_CURRENT = 'MARK_ATTACHMENT_AS_CURRENT'
export const CREATE_MULTIPART = 'CREATE_MULTIPART'
export const COPY_ACCESSORY_TO_VARIANTS = 'COPY_ACCESSORY_TO_VARIANTS'
export const UPDATE_ALSO_API_DATA = 'UPDATE_ALSO_API_DATA'
export const GET_IT_SCOPE_ORDERS = 'GET_IT_SCOPE_ORDERS'
export const CREATE_IT_SCOPE_ORDERS = 'CREATE_IT_SCOPE_ORDERS'
export const RESEND_EMAIL = 'RESEND_EMAIL'
export const SAVE_TRANSLATIONS = 'SAVE_TRANSLATIONS'
export const REFRESH_ACMP_TRADING_MODEL_MARGINS =
  'REFRESH_ACMP_TRADING_MODEL_MARGINS'
export const ADD_PROTECTION_PLAN = 'ADD_PROTECTION_PLAN'
export const DUPLICATE_AS_OFFER = 'DUPLICATE_AS_OFFER'
export const UPDATE_ALSO_SERVICE_PORTFOLIO = 'UPDATE_ALSO_SERVICE_PORTFOLIO'
export const ALSO_ADD_EDI_ITEM_TO_PORTFOLIO = 'ALSO_ADD_EDI_ITEM_TO_PORTFOLIO'
export const ALSO_UPDATE_RESELLABLE_PRODUCT = 'ALSO_UPDATE_RESELLABLE_PRODUCT'
