import { CardActions } from '@material-ui/core'
import React from 'react'
import { Edit, ImageField, SimpleForm, translate, required } from 'react-admin'
import {
  ColorField,
  ColorInput,
  isColor,
} from 'Components/Common/InputFields/ColorInput/ColorInput'
import EditToolbar from 'Components/Common/EditToolbar'
import ImageInput from 'Components/Common/InputFields/ImageInput'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'

const EditActions = () => <CardActions />

const CorporateIdentityTitle = translate(({ translate: t }) =>
  t('resources.reseller_corporate_identities.name'),
)

const validateColor = [required(), isColor()]

export const CorporateIdentityEdit = props => (
  <Edit
    title={<CorporateIdentityTitle />}
    actions={<EditActions />}
    aside={<LanguageSwitcherAside />}
    {...props}
  >
    <SimpleForm toolbar={<EditToolbar />} redirect={false}>
      <ImageField source="companyLogo" />
      <ImageInput source="companyLogoFile" maxSize={26214400}>
        <ImageField source="companyLogo" title="title" />
      </ImageInput>
      <ColorField source="firstColor" />
      <ColorInput
        source="firstColor"
        picker="Sketch"
        validate={validateColor}
      />
      <ColorField source="secondColor" />
      <ColorInput
        source="secondColor"
        picker="Sketch"
        validate={validateColor}
      />
    </SimpleForm>
  </Edit>
)

export default CorporateIdentityEdit
