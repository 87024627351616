import React from 'react'
import MuiTextField from '@material-ui/core/TextField'

import NumberFormat from 'react-number-format'

const NumberFormatCustom = ({ inputRef, onChange, name, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={values => {
      onChange({
        target: {
          name,
          value: values.floatValue,
        },
      })
    }}
    decimalSeparator=","
    suffix=" €"
    decimalScale={2}
    fixedDecimalScale
  />
)

// NumberFormatCustom.propTypes = {
//   inputRef: PropTypes.func.isRequired,
//   onChange: PropTypes.func.isRequired,
// }

const renderCurrencyInputField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <MuiTextField
    label={label}
    errorText={touched && error}
    margin="normal"
    {...input}
    {...custom}
    InputProps={{
      inputComponent: NumberFormatCustom,
    }}
  />
)

export default renderCurrencyInputField
