import React, { useEffect, useState, useRef } from 'react'
import { addField } from 'react-admin'
import { compose } from 'recompose'
import { get } from 'lodash'
import md5 from 'md5'
import { Editor } from '@tinymce/tinymce-react'
import tinymce from 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/table'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/paste'

import 'tinymce/skins/ui/oxide/skin.min.css'

window.tinymce = tinymce

const TinyMCEInput = ({
  id,
  disabled,
  label,
  name,
  input: { value, onChange },
  config,
}) => {
  const editorRef = useRef(null)

  const save = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent()
      editorRef.current.setDirty(false)
      onChange(content)
    }
  }

  return (
    <Editor
      id={id.replace('.', '-')}
      initialValue={value}
      onInit={(evt, editor) => (editorRef.current = editor)}
      onBlur={save}
      init={{
        skin_url: '/tinymce/skins/ui/oxide',
        language: 'de',
        language_url: '/tinymce/langs/de.js',
        plugins: 'table lists link image autolink paste',
        toolbar:
          'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | link image | paste pastetext',
        paste_data_images: true,
        min_height: 400,
      }}
    />
  )
}

const TinyMCEInputContainer = ({ record, source, ...props }) => {
  // const contentLanguage = useSelector(
  //   ({ language: { contentLanguage } }) => contentLanguage,
  // )

  const [key, setKey] = useState(null)

  useEffect(() => {
    const content = get(record, `record.${source}`)
    const hash = content ? md5(content) : 'initial'
    setKey(hash)
  }, [record, source])

  return <TinyMCEInput key={key} {...props} />
}

TinyMCEInputContainer.defaultProps = {
  addLabel: true,
}
const enhance = compose(addField)

export default enhance(TinyMCEInputContainer)
