import { Chip, makeStyles, Grid } from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import ReferenceInput from 'Components/Common/ReferenceInput'
import React, { Fragment } from 'react'
import {
  Button,
  Create,
  Datagrid,
  Edit,
  Filter,
  FunctionField,
  List,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  useTranslate,
  useUnselectAll,
  useUpdateMany,
} from 'react-admin'
import { validateRequired } from 'Resources/Aluxo/devices'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'
import {
  useListController,
  ListContextProvider,
  ResourceContextProvider,
} from 'ra-core'

const useQuickFilterStyles = makeStyles(theme => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}))

const ProductAttributeValueFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
)

const UnAssignButton = ({ selectedIds }) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll()

  const [updateMany, { loading }] = useUpdateMany(
    'common_cnet_metadata_values',
    selectedIds,
    {
      productAttributeValue: null,
    },
    {
      onSuccess: () => {
        refresh()
        notify('notifications.unassign.success')
        unselectAll('common_cnet_metadata_values')
      },
      // eslint-disable-next-line no-unused-vars
      onFailure: error => notify('notifications.unassign.fail', 'warning'),
    },
  )

  return (
    <Button label="actions.unassign" disabled={loading} onClick={updateMany}>
      <LinkOffIcon />
    </Button>
  )
}

const AssignButton = ({ selectedIds, valueId }) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll()

  const [updateMany, { loading }] = useUpdateMany(
    'common_cnet_metadata_values',
    selectedIds,
    {
      productAttributeValue: `/api/common_product_attribute_values/${valueId}`,
    },
    {
      onSuccess: () => {
        refresh()
        notify('notifications.assign.success')
        unselectAll('common_cnet_metadata_values')
      },
      // eslint-disable-next-line no-unused-vars
      onFailure: error => notify('notifications.assign.fail', 'warning'),
    },
  )

  return (
    <Button label="actions.assign" disabled={loading} onClick={updateMany}>
      <LinkIcon />
    </Button>
  )
}

const ValueBulkActionButtons = props => (
  <Fragment>
    <AssignButton {...props} />
    <UnAssignButton {...props} />
  </Fragment>
)

const QuickFilter = ({ label }) => {
  const translate = useTranslate()
  const classes = useQuickFilterStyles()
  return <Chip className={classes.chip} label={translate(label)} />
}

const CnetValueFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="value" alwaysOn />
    <QuickFilter
      label="assigned"
      source="productAttributeValue"
      defaultValue={props.valueId}
    />
  </Filter>
)

const ValueList = ({ valueId }) => {
  const controllerProps = useListController({
    resource: 'common_cnet_metadata_values',
    basePath: '/common_cnet_metadata_values',
  })

  return (
    <ResourceContextProvider value="common_cnet_metadata_values">
      <ListContextProvider value={controllerProps}>
        <List
          resource="common_cnet_metadata_values"
          basePath="/common_cnet_metadata_values"
          // filter={{ productAttributeValue: props.id }}
          bulkActionButtons={<ValueBulkActionButtons valueId={valueId} />}
          filters={<CnetValueFilter valueId={valueId} />}
          pagination={<CustomPagination />}
        >
          <Datagrid>
            <TextField source="value" />
            <TextField source="group.name" />
            <TextField source="group.parent.name" />
            <TextField source="productAttributeValue.value" />
          </Datagrid>
        </List>
      </ListContextProvider>
    </ResourceContextProvider>
  )
}

export const ProductAttributeValueEdit = props => (
  <Grid container>
    <Grid item xs={12}>
      <Edit {...props}>
        <SimpleForm>
          <TextInput source="value" validate={validateRequired} />
          <TextInput source="shortValue" validate={validateRequired} />
          <ReferenceInput
            source="group"
            reference="common_product_attribute_groups"
            validate={validateRequired}
          >
            <SelectInput optionText={choice => choice.name} />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    </Grid>
    <Grid item xs={12}>
      <ValueList valueId={props.id} />
    </Grid>
  </Grid>
)

export const ProductAttributeValueCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="value" validate={validateRequired} />
      <TextInput source="shortValue" validate={validateRequired} />
      <ReferenceInput
        source="group"
        reference="common_product_attribute_groups"
        validate={validateRequired}
      >
        <SelectInput optionText={choice => choice.name} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

// eslint-disable-next-line import/prefer-default-export
export const ProductAttributeValueList = props => (
  <List {...props} filters={<ProductAttributeValueFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="value" />
      <TextField source="shortValue" />
      <TextField source="group.name" />
      <FunctionField
        label="resources.common_product_attribute_values.fields.children"
        render={record => record.cnetMetaDataValues.length}
      />
    </Datagrid>
  </List>
)
