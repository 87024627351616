import React from 'react'
import ClearStoreViewCacheButton from './ActionButtons/ClearStoreViewCacheButton'
import WarmupStoreViewCacheButton from './ActionButtons/WarmupStoreViewCacheButton'
import ToggleStoreViewCacheButton from './ActionButtons/ToggleStoreViewCacheButton'

const StoreViewCacheActions = ({ data }) => (
  <>
    <ToggleStoreViewCacheButton data={data} />
    <ClearStoreViewCacheButton data={data} />
    <WarmupStoreViewCacheButton data={data} />
  </>
)

export default StoreViewCacheActions
