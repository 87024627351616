import { Button } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import arrayMove from 'array-move'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useMutation, useNotify, useRefresh, useTranslate } from 'react-admin'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import ImageUploadInput from './ImageUploadInput'

const ImagesInputLOC = ({ record, referenceName, resourceName }) => {
  const t = useTranslate()
  const showNotification = useNotify()
  const refreshView = useRefresh()
  const [images, setImages] = useState(record.images)

  const [mutate] = useMutation()

  const updatePosition = async values => {
    try {
      const sourceIndex = values.source.index
      const destinationIndex = values.destination.index

      if (sourceIndex !== destinationIndex) {
        const newImages = arrayMove(images, sourceIndex, destinationIndex)
        setImages(newImages.map((i, index) => ({ ...i, position: index })))
        mutate(
          {
            type: 'update',
            resource: resourceName,
            payload: {
              id: values.draggableId,
              data: { position: destinationIndex },
            },
          },
          {
            onSuccess: () =>
              showNotification('notifications.resortImage.success'),
            onFailure: () =>
              showNotification('notifications.resortImage.fail', 'warning'),
          },
        )
      }
    } catch (e) {
      showNotification('notifications.resortImage.fail', 'warning')
    }
  }

  const handleDelete = image => {
    mutate(
      {
        type: 'delete',
        resource: resourceName,
        payload: {
          id: image.id,
        },
      },
      {
        onSuccess: () => {
          showNotification('notifications.deleteImage.success')
          refreshView()
        },
        onFailure: () =>
          showNotification('notifications.deleteImage.fail', 'warning'),
      },
    )
  }

  return (
    <div style={{ marginTop: '1em', marginBottom: '2em' }}>
      <span>{t('resources.devices.fields.images')}</span>
      <DragDropContext onDragEnd={updatePosition}>
        <Droppable droppableId="droppable" direction="horizontal">
          {provided => (
            <div
              style={{ display: 'flex', flexWrap: 'wrap' }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {images
                .sort((a, b) => a.position - b.position)
                .map(image => (
                  <Draggable
                    key={image.id}
                    draggableId={image.id}
                    index={image.position}
                  >
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div
                          style={{
                            padding: '1em',
                            margin: '1em',
                            border: '1px solid rgba(0, 0, 0, 0.37)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <img
                            src={image.image}
                            style={{
                              margin: '0.5rem',
                              maxHeight: '10rem',
                            }}
                            alt=""
                          />
                          <Button onClick={() => handleDelete(image)}>
                            <Delete />
                          </Button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <ImageUploadInput
        record={record}
        resourceName={resourceName}
        referenceName={referenceName}
      />
    </div>
  )
}

ImagesInputLOC.propTypes = {
  record: PropTypes.object.isRequired,
  referenceName: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  addLabel: PropTypes.bool,
  addField: PropTypes.bool,
}

ImagesInputLOC.defaultProps = {
  addLabel: true,
  addField: true,
}

const ImagesInput = props => {
  const {
    record: { images },
  } = props

  if (!images) {
    return null
  }
  const key = images.map(image => image.id).join('-')
  return <ImagesInputLOC {...props} key={key} />
}

ImagesInput.propTypes = {
  record: PropTypes.object.isRequired,
}

export default ImagesInput
