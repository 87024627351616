import React from 'react'
import PropTypes from 'prop-types'
import { ImageInput as RAImageInput, useNotify } from 'react-admin'

const ImageInput = ({ children, ...props }) => {
  const showNotification = useNotify()
  return (
    <RAImageInput
      accept="image/*"
      options={{
        onDropRejected: (/* rejectedFiles */) => {
          showNotification('notifications.imageNotAccepted')
        },
      }}
      {...props}
    >
      {children}
    </RAImageInput>
  )
}

ImageInput.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default ImageInput
