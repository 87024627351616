/**
 * These are here because final-form-arrays is broken af
 * These mutators are broken, and almost everything else relies on it
 * https://github.com/final-form/react-final-form-arrays/issues/138
 * let's keep an eye on this
 */

export const insert = ([name, index, value], state, { changeValue }) => {
  changeValue(state, name, array => {
    const copy = [...(array || [])]
    copy.splice(index, 0, value)
    return copy
  })
}

export const remove = ([name, index], state, { changeValue }) => {
  changeValue(state, name, array => {
    const copy = [...(array || [])]
    copy.splice(index, 1)
    return copy
  })
}
