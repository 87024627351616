import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import AlsoOrderLine from 'Components/Also/Orders/Show/OrderLine'
import { NonSortableLabel } from 'Components/Aluxo/OrderLines/OrderLinesTable'
import currencyText from 'Lib/currencyText'
import { sumBy } from 'lodash'
import React from 'react'
import { Loading, useGetManyReference, useTranslate } from 'react-admin'
import PropTypes from 'prop-types'

const AlsoOrderShowLines = ({ orderId }) => {
  const { data: dataObjects, ids, loading } = useGetManyReference(
    'also_workplace_order_lines',
    'order',
    orderId,
    false,
    false,
    false,
    'also_workplace_order_lines',
  )
  const t = useTranslate()

  const {
    palette: {
      primary: { main },
    },
  } = useTheme()

  if (loading) {
    return <Loading />
  }

  const data = ids
    .map(id => dataObjects[id])
    .map((entry, index) => ({
      ...entry,
      reference: 'also_workplace_order_lines',
      pos: index + 1,
    }))

  return (
    <Table size="small" stripedRows fixedHeader={false} fixedFooter={false}>
      <TableHead displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          <TableCell style={{ width: '40px' }}>
            <NonSortableLabel>
              {t('alsoOrderLines.fields.position')}
            </NonSortableLabel>
          </TableCell>
          <TableCell>
            <NonSortableLabel>
              {t('alsoOrderLines.fields.workplaceUserDevice')}
            </NonSortableLabel>
          </TableCell>
          <TableCell style={{ width: '100px' }}>
            <NonSortableLabel>
              {t('alsoOrderLines.fields.term')}
            </NonSortableLabel>
          </TableCell>
          <TableCell style={{ width: '40px', textAlign: 'right' }}>
            <NonSortableLabel>
              {t('alsoOrderLines.fields.quantity')}
            </NonSortableLabel>
          </TableCell>
          <TableCell style={{ width: '60px', textAlign: 'right' }}>
            <NonSortableLabel>
              {t('alsoOrderLines.fields.monthlyRate')}
            </NonSortableLabel>
          </TableCell>

          <TableCell style={{ width: '60px', textAlign: 'right' }}>
            <NonSortableLabel>
              {t('alsoOrderLines.fields.total')}
            </NonSortableLabel>
          </TableCell>
          <TableCell style={{ width: '100px' }}>
            <NonSortableLabel>
              {t('alsoOrderLines.fields.transmitted')}
            </NonSortableLabel>
          </TableCell>

          <TableCell style={{ width: '150px' }}>
            <NonSortableLabel />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody displayRowCheckbox={false}>
        {data
          .filter(d => d.workplaceConfigurationFields.length > 0)
          .map(line => (
            <AlsoOrderLine key={line.id} line={line} />
          ))}
      </TableBody>
      <TableFooter adjustForCheckbox={false} style={{ color: main }}>
        <TableRow>
          <TableCell colSpan="4" />
          <TableCell style={{ textAlign: 'right' }}>
            {currencyText(sumBy(data, 'monthlyRate'))}
          </TableCell>

          <TableCell style={{ textAlign: 'right' }}>
            {currencyText(
              sumBy(
                data,
                ({ monthlyRate, quantity }) => quantity * monthlyRate,
              ),
            )}
          </TableCell>

          <TableCell colSpan="2" />
        </TableRow>
      </TableFooter>
    </Table>
  )
}

AlsoOrderShowLines.propTypes = {
  orderId: PropTypes.string.isRequired,
}

export default AlsoOrderShowLines
