import { Security } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React, { createElement } from 'react'
import { MenuItemLink } from 'react-admin'
import { TranslatedResourceName } from '../../Common/Layout/MenuTranslations'

const Menu = ({ onMenuClick }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    }}
  >
    <div>
      <MenuItemLink
        to="/resellers"
        primaryText={<TranslatedResourceName resource="resellers" />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/store_views"
        primaryText={<TranslatedResourceName resource="store_views" />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/resellable_protection_plans"
        primaryText={<TranslatedResourceName resource="protection_plans" />}
        onClick={onMenuClick}
        leftIcon={createElement(Security)}
      />
      <MenuItemLink
        to="/external_protection_plan_devices"
        primaryText={
          <TranslatedResourceName resource="external_protection_plans" />
        }
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/string_translations"
        primaryText={<TranslatedResourceName resource="string_translations" />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/email_templates"
        primaryText={<TranslatedResourceName resource="email_templates" />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/common_product_attribute_groups"
        primaryText={
          <TranslatedResourceName resource="common_product_attribute_groups" />
        }
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/common_product_attribute_values"
        primaryText={
          <TranslatedResourceName resource="common_product_attribute_values" />
        }
        onClick={onMenuClick}
      />
    </div>
  </div>
)

Menu.propTypes = {
  onMenuClick: PropTypes.func,
}

Menu.defaultProps = {
  onMenuClick: () => null,
}

export default Menu
