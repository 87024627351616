import NumberWithUnitInput from 'Components/Common/InputFields/NumberWithUnitInput'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useField } from 'react-final-form'

export const OrderLineAbsoluteDiscountInput = ({
  source,
  resource,
  label,
  disabled,
}) => {
  const t = useTranslate()

  const { input: absoluteDiscountInput } = useField(source, {})

  return (
    <NumberWithUnitInput
      label={t(label || `resources.${resource}.fields.${source}_absolute`)}
      input={absoluteDiscountInput}
      unit="€"
      disabled={disabled}
    />
  )
}

OrderLineAbsoluteDiscountInput.propTypes = {
  source: PropTypes.string.isRequired,
  resource: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
}

OrderLineAbsoluteDiscountInput.defaultProps = {
  resource: null,
  label: '',
  disabled: false,
}

export const OrderLineRelativeDiscountInput = ({
  source,
  resource,
  label,
  disabled,
}) => {
  const t = useTranslate()
  const {
    input: { value: netPrice },
  } = useField('netPrice')

  const { input: relativeDiscountInput } = useField(source, {
    parse: e => netPrice * (e / 100),
    format: e => (e / netPrice) * 100,
  })

  return (
    <NumberWithUnitInput
      key="relativeDiscount"
      label={t(label || `resources.${resource}.fields.${source}_relative`)}
      input={relativeDiscountInput}
      unit="%"
      precision={4}
      disabled={disabled}
    />
  )
}

OrderLineRelativeDiscountInput.propTypes = {
  source: PropTypes.string.isRequired,
  resource: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
}

OrderLineRelativeDiscountInput.defaultProps = {
  label: '',
  disabled: false,
  resource: null,
}

export default OrderLineAbsoluteDiscountInput
