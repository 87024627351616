import React from 'react'
import PropTypes from 'prop-types'
import Dial from './Dial'

const Dials = ({ size, circles, label }) => (
  <div
    style={{ position: 'relative', width: `${size}em`, height: `${size}em` }}
  >
    {circles.map(circle => (
      <Dial {...circle} size={size} />
    ))}
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
      }}
      className="label-container"
    >
      {label}
    </div>
  </div>
)

Dials.defaultProps = {
  circles: [
    {
      color: '#ccc',
      percent: 0.6,
      dialSize: 8,
      width: 5,
    },
  ],
  size: 10,
}

Dials.propTypes = {
  circles: PropTypes.arrayOf(
    PropTypes.shape({
      dialSize: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ),
  size: PropTypes.number,
  label: PropTypes.node.isRequired,
}

export default Dials
