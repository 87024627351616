import GenericRestClientButton from 'Components/Common/ActionButtons/GenericRestClientButton'
import React from 'react'
import PropTypes from 'prop-types'

const TestCredentialsAction = ({ credentials }) => (
  <GenericRestClientButton
    type="testCredentials"
    action="testCredentials"
    resource="credentials"
    payload={{ id: credentials.id, data: credentials }}
  />
)

TestCredentialsAction.propTypes = {
  credentials: PropTypes.object.isRequired,
}

export default TestCredentialsAction
