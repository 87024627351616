import React, { useState } from 'react'

const SubMenu = ({ mainItem, children }) => {
  const [target, setTarget] = useState(null)
  const hide = () => setTarget(null)
  return (
    <div>
      {mainItem(setTarget)}
      {children(target, hide)}
    </div>
  )
}

export default SubMenu
