import Button from '@material-ui/core/Button'
import { Check, Close } from '@material-ui/icons'
import DownloadAttachmentAction from 'Components/Common/ActionButtons/DownloadAttachmentAction'
import PropTypes from 'prop-types'
import React from 'react'
import { DeleteButton, useMutation, useNotify, useRefresh } from 'react-admin'

const AttachmentActions = ({ basePath, record }) => {
  const showNotification = useNotify()
  const refreshView = useRefresh()

  const { id: recordId, current: recordCurrent } = record || {}

  const [mutate] = useMutation(
    {
      type: 'update',
      resource: 'attachments',
      payload: {
        id: recordId,
        data: { current: !recordCurrent },
      },
    },
    {
      onSuccess: () => {
        showNotification('notifications.updateAttachment.success')
        refreshView()
      },
      onFailure: error => {
        showNotification(`Error: ${error}`, 'warning') // TODO errormessage
      },
    },
  )

  if (!record) {
    return null
  }

  const fileName = record.type === 'Angebot' ? record.comment : record.type

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button color={record.current ? 'secondary' : 'primary'} onClick={mutate}>
        {record.current ? <Close /> : <Check />}
      </Button>
      <DownloadAttachmentAction id={record.id} name={fileName} />
      <DeleteButton
        basePath={basePath}
        record={record}
        resource="attachments"
        label=""
        redirect={false}
      />
    </div>
  )
}

AttachmentActions.propTypes = {
  record: PropTypes.object, //eslint-disable-line
  basePath: PropTypes.string.isRequired,
}

export default AttachmentActions
