import { LinearProgress, Paper } from '@material-ui/core'

import Downshift from 'downshift'
import PropTypes from 'prop-types'
import React from 'react'
import { useField } from 'react-final-form'
import FetchData from 'Components/Common/InputFields/AutoSuggests/OnlineServiceAutoSuggest/FetchData'
import SelectList from 'Components/Common/InputFields/AutoSuggests/Lib/SelectList'
import { Input } from 'Components/Common/InputFields/AutoSuggests/Lib/renderFunctions'
import getSuggestions from 'Components/Common/InputFields/AutoSuggests/OnlineServiceAutoSuggest/getSuggestions'

const itemToString = item => item.label

const OnlineServiceAutoSuggest = ({
  label,
  source,
  name,
  onItemSelect,
  autoFocus,
}) => {
  const {
    input: { value, onChange },
  } = useField(source || name)

  const selectedItem = { value }

  return (
    <Downshift
      onChange={selection => {
        if (selection) {
          onChange(selection.value)
          onItemSelect(selection)
        }
      }}
      itemToString={itemToString}
      selectedItem={selectedItem}
    >
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        isOpen,
        selectedItem,
        inputValue,
        highlightedIndex,
      }) => (
        <div>
          <Input
            label={label}
            fullWidth
            autoFocus={autoFocus}
            InputProps={getInputProps()}
          />

          <Paper
            style={{
              display: isOpen ? 'block' : 'none',
              visibility: isOpen ? 'visible' : 'hidden',
            }}
            square
            {...getMenuProps()}
          >
            {isOpen ? (
              <FetchData searchValue={inputValue}>
                {({ onlineServices, loading }) => {
                  const suggestions = getSuggestions({
                    onlineServices,
                    inputValue,
                  })

                  const rows = []
                  suggestions.forEach(group => {
                    rows.push({ label: group.title })
                    group.suggestions.forEach(suggestion => {
                      rows.push(suggestion)
                    })
                  })

                  return (
                    <>
                      {loading && (
                        <LinearProgress
                          style={{ width: '100%' }}
                          mode="indeterminate"
                        />
                      )}

                      <SelectList
                        rows={rows}
                        getItemProps={getItemProps}
                        highlightedIndex={highlightedIndex}
                        selectedItem={selectedItem}
                        itemToString={itemToString}
                      />
                    </>
                  )
                }}
              </FetchData>
            ) : null}
          </Paper>
        </div>
      )}
    </Downshift>
  )
}

OnlineServiceAutoSuggest.propTypes = {
  label: PropTypes.string.isRequired,
  source: PropTypes.string,
  name: PropTypes.string,
  onItemSelect: PropTypes.func,
  autoFocus: PropTypes.bool,
}

OnlineServiceAutoSuggest.defaultProps = {
  source: null,
  name: null,
  autoFocus: true,
  onItemSelect: () => {},
}

export default OnlineServiceAutoSuggest
