import EditToolbar from 'Components/Common/EditToolbar'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import PropTypes from 'prop-types'
import React from 'react'
import { Edit, NumberInput, SimpleForm, translate } from 'react-admin'
import NumberWithUnitInput from 'Components/Common/InputFields/NumberWithUnitInput'

const EditActions = () => <RightAlignedCardActions />

EditActions.propTypes = {
  data: PropTypes.object.isRequired,
}

const AlsoOfferSettingsTitle = translate(({ translate: t }) =>
  t('resources.also_offer_settings.name'),
)

// eslint-disable-next-line
export const AlsoOfferSettingsEdit = props => {
  return (
    <Edit
      title={<AlsoOfferSettingsTitle />}
      actions={<EditActions />}
      {...props}
    >
      <SimpleForm toolbar={<EditToolbar />} redirect={false}>
        <NumberWithUnitInput source="defaultMargin" unit="%" />
        <NumberInput source="startNumber" />
      </SimpleForm>
    </Edit>
  )
}
