import { Warning, Forward } from '@material-ui/icons'
import { FORM_ERROR } from 'final-form'

const hasOnlineService = record =>
  record.serviceLines && record.serviceLines.some(l => !l.managed)
const hasManagedService = record =>
  record.serviceLines && record.serviceLines.some(l => l.managed)

export const rentFlagTypes = {
  dllApplicationCreated: {
    key: 'dllApplicationCreated',
  },
  albisApplicationCreated: {
    key: 'albisApplicationCreated',
  },
  ordered: {
    key: 'ordered',
  },
  order_confirmed: {
    key: 'order_confirmed',
  },
  shipped: {
    key: 'shipped',
  },
  delivered: {
    key: 'delivered',
  },
  invoiced: {
    key: 'invoiced',
  },
  onlineServicesConfigured: {
    key: 'onlineServicesConfigured',
    dependsOn: hasOnlineService,
  },
  managedServicesConfigured: {
    key: 'managedServicesConfigured',
    dependsOn: hasManagedService,
  },
  protectionPlanCreated: {
    key: 'protectionPlanCreated',
  },
}

export const buyFlagTypes = {
  ordered: {
    key: 'ordered',
  },
  order_confirmed: {
    key: 'order_confirmed',
  },
  shipped: {
    key: 'shipped',
  },
  invoiced: {
    key: 'invoiced',
  },
  onlineServicesConfigured: {
    key: 'onlineServicesConfigured',
    dependsOn: hasOnlineService,
  },
  managedServicesConfigured: {
    key: 'managedServicesConfigured',
    dependsOn: hasManagedService,
  },
  protectionPlanCreated: {
    key: 'protectionPlanCreated',
  },
}

export const flagTypes = {
  buy_order: buyFlagTypes,
  rent_order: rentFlagTypes,
}

export const offerFlagTypes = {
  gSalesOfferCreated: {
    key: 'gSalesOfferCreated',
  },
}

export const offerMarkings = {
  offer_draft: {
    key: 'offer_draft',
    statusBarIndex: 0,
  },
  offer_created: {
    key: 'offer_created',
    statusBarIndex: 1,
  },
  offer_rejected: {
    key: 'offer_rejected',
    statusBarIndex: 2,
  },
}

export const buyMarkings = {
  draft: {
    key: 'draft',
    statusBarIndex: 0,
  },
  opened: {
    key: 'opened',
    statusBarIndex: 0,
  },
  ordered: {
    key: 'ordered',
    statusBarIndex: 1,
  },
  order_confirmed: {
    key: 'order_confirmed',
    statusBarIndex: 2,
    flags: [buyFlagTypes.shipped],
  },
  shipped: {
    key: 'shipped',
    statusBarIndex: 3,
    flags: [buyFlagTypes.delivered],
  },
  invoiced: {
    key: 'invoiced',
    statusBarIndex: 4,
  },
  paid: {
    key: 'paid',
    statusBarIndex: 5,
  },
  cancelled: {
    key: 'cancelled',
  },
}

export const rentMarkings = {
  draft: {
    key: 'draft',
    statusBarIndex: 0,
  },
  opened: {
    key: 'opened',
    statusBarIndex: 0,
  },
  changed: {
    key: 'changed',
    statusBarIndex: 0,
  },
  review: {
    key: 'review',
    statusBarIndex: 1,
  },
  approved: {
    key: 'approved',
    statusBarIndex: 2,
  },
  rejected: {
    key: 'rejected',
    statusBarIndex: 2,
  },
  created: {
    key: 'created',
    statusBarIndex: 3,
  },
  customer_signed: {
    key: 'customer_signed',
    statusBarIndex: 4,
  },
  reseller_signed: {
    key: 'reseller_signed',
    statusBarIndex: 5,
    flags: [rentFlagTypes.ordered],
  },
  ordered: {
    key: 'ordered',
    statusBarIndex: 6,
  },
  order_confirmed: {
    key: 'order_confirmed',
    statusBarIndex: 7,
    flags: [rentFlagTypes.shipped],
  },
  shipped: {
    key: 'shipped',
    statusBarIndex: 8,
    flags: [rentFlagTypes.delivered],
  },
  delivered: {
    key: 'delivered',
    statusBarIndex: 9,
    flags: [rentFlagTypes.licenseConfigured],
  },
  confirmed: {
    key: 'confirmed',
    statusBarIndex: 10,
  },
  refused: {
    key: 'refused',
    statusBarIndex: 10,
  },
  completed: {
    key: 'completed',
    statusBarIndex: 11,
  },
  invoiced: {
    key: 'invoiced',
    statusBarIndex: 12,
  },
  paid: {
    key: 'paid',
    statusBarIndex: 13,
  },
  cancelled: {
    key: 'cancelled',
  },
}

export const markings = {
  buy_order: buyMarkings,
  rent_order: rentMarkings,
}

export const manualTransitions = {
  open: {
    key: 'open',
    name: 'open',
    target: 'opened',
    icon: Forward,
    color: undefined,
  },
  review: {
    key: 'review',
    name: 'review',
    target: 'review',
    icon: Forward,
    color: undefined,
  },
  approve: {
    key: 'approve',
    name: 'approve',
    target: 'approved',
    icon: Forward,
    color: undefined,
  },
  reject: {
    key: 'reject',
    name: 'reject',
    target: 'rejected',
    icon: Forward,
    color: undefined,
  },
  change: {
    key: 'change',
    name: 'change',
    target: 'changed',
    icon: Forward,
    color: undefined,
  },
  create: {
    key: 'create',
    name: 'create',
    target: 'created',
    icon: Forward,
    color: undefined,
  },
  create_manually: {
    key: 'create_manually',
    name: 'create_manually',
    target: 'created',
    icon: Forward,
    color: undefined,
    visible: (resellerFlags, orderFields) => false,
  },
  customer_sign: {
    key: 'customer_sign',
    name: 'customer_sign',
    target: 'customer_signed',
    icon: Forward,
    color: undefined,
  },
  reseller_sign: {
    key: 'reseller_sign',
    name: 'reseller_sign',
    target: 'reseller_signed',
    icon: Forward,
    color: undefined,
  },
  order: {
    key: 'order',
    name: 'order',
    target: 'ordered',
    icon: Forward,
    color: undefined,
  },
  confirm_order: {
    key: 'confirm_order',
    name: 'confirm_order',
    target: 'order_confirmed',
    icon: Forward,
    color: undefined,
  },
  ship: {
    key: 'ship',
    name: 'ship',
    target: 'shipped',
    icon: Forward,
    color: undefined,
  },
  deliver: {
    key: 'deliver',
    name: 'deliver',
    target: 'delivered',
    icon: Forward,
    color: undefined,
  },
  confirm: {
    key: 'confirm',
    name: 'confirm',
    target: 'confirmed',
    icon: Forward,
    color: undefined,
    potentialFlags: record => {
      const flags = []
      if (hasOnlineService(record)) {
        flags.push(rentFlagTypes.onlineServicesConfigured)
      }
      if (hasManagedService(record)) {
        flags.push(rentFlagTypes.managedServicesConfigured)
      }
      return flags
    },
  },
  refuse: {
    key: 'refuse',
    name: 'refuse',
    target: 'refused',
    icon: Forward,
    danger: true,
  },
  complete: {
    key: 'complete',
    name: 'complete',
    target: 'completed',
    icon: Forward,
    color: undefined,
  },
  invoice: {
    key: 'invoice',
    name: 'invoice',
    target: 'invoiced',
    icon: Forward,
    color: undefined,
  },
  pay: {
    key: 'pay',
    name: 'pay',
    target: 'paid',
    icon: Forward,
    color: undefined,
  },
  cancel: {
    key: 'cancel',
    name: 'cancel',
    target: 'cancelled',
    icon: Warning,
    danger: true,
  },
  create_offer: {
    key: 'create_offer',
    name: 'create_offer',
    target: 'offer_created',
    icon: Forward,
    color: undefined,
    context: {
      content: [
        {
          name: 'buy',
          label: 'Kaufangebot',
          type: 'checkbox',
        },
        {
          name: 'rent',
          label: 'Mietangebot',
          type: 'checkbox',
        },
        {
          name: 'email',
          label: 'Per E-Mail versenden',
          type: 'checkbox',
          initialValue: true,
        },
      ],
      validate: (order, { buy, rent }) => {
        if (!(buy || rent)) {
          return { [FORM_ERROR]: 'Bitte Angebotsart(en) wählen' }
        }
        if (rent && !order.period) {
          return {
            [FORM_ERROR]: 'Für ein Mietangebot muss eine Laufzeit gewählt sein',
          }
        }
      },
      dialogCondition: (order, values) => values.email,
      dialog: [
        // {
        //   name: 'emailRecipients',
        //   label: 'Empfänger (für Rechnungsadresse leer lassen)',
        //   type: 'textarea',
        //   initialValue: [],
        //   format: v => v.join('\n'),
        //   parse: v => v.split('\n'),
        // },
        {
          name: 'comment',
          label: 'Anmerkungen',
          rows: 4,
          type: 'textarea',
        },
      ],
    },
  },
  open_buy: {
    key: 'open_buy',
    name: 'open_buy',
    target: 'opened',
    icon: Forward,
    color: undefined,
    customAction: ({ history, record }) => {
      history.push(`/orders/${record.id}`)
    },
  },
  open_rent: {
    key: 'open_rent',
    name: 'open_rent',
    target: 'opened',
    icon: Forward,
    color: undefined,
    customAction: ({ history, record }) => {
      history.push(`/orders/${record.id}`)
    },
  },
  reject_offer: {
    key: 'reject_offer',
    name: 'reject_offer',
    target: 'offer_rejected',
    icon: Warning,
    danger: true,
  },
}
