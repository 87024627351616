import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import ButtonLabel from 'Components/Common/Buttons/ButtonLabel'
import get from 'lodash/get'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  FormTab,
  ImageField,
  List,
  ListButton,
  ReferenceManyField,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'
import { Link } from 'react-router-dom'
import EditToolbar from 'Components/Common/EditToolbar'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import PercentageField from 'Components/Common/ViewFields/PercentageField'
import ImageInput from 'Components/Common/InputFields/ImageInput'
import MetaInput from 'Components/Common/InputFields/MetaInput'
import ReferenceInput from 'Components/Common/ReferenceInput'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import { useFullWidthTabStyles } from 'Lib/styles'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'
import CommonInputFieldsCategoryBasedOnStoreviewInput from 'Components/Common/InputFields/CategoryBasedOnStoreviewInput'
import { validateRequired } from './devices'

const ProductFilter = props => (
  <Filter {...props}>
    <TextInput source="name" />
    <ReferenceInput
      label="resources.products.fields.manufacturer.name"
      source="manufacturer"
      reference="manufacturers"
    >
      <SelectInput optionText={choice => choice.name} />
    </ReferenceInput>
    <TextInput source="slug" />
    <ReferenceInput
      label="resources.products.fields.category.name"
      source="category"
      reference="categories"
    >
      <SelectInput optionText={choice => choice.name} />
    </ReferenceInput>
    <BooleanInput
      options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
      source="active"
    />
  </Filter>
)

export const ProductList = props => (
  <List
    {...props}
    pagination={<CustomPagination />}
    filters={<ProductFilter />}
    aside={<LanguageSwitcherAside />}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="manufacturer.name" />
      <TextField source="slug" />
      <TextField source="category.name" />
      <BooleanField source="active" />
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <TextField source="storeView.name" />
      )}
      <EditButton />
    </Datagrid>
  </List>
)

ProductList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const ProductEditActions = ({ basePath, data }) => {
  const translate = useTranslate()
  const linkParams = {
    product: get(data, '@id'),
  }
  return (
    <RightAlignedCardActions>
      <ListButton basePath={basePath} />
      <Button
        color="primary"
        component={Link}
        to={`/product_variants/create?source=${JSON.stringify(linkParams)}`}
      >
        <Add />
        <ButtonLabel>{translate('actions.add_variant')}</ButtonLabel>
      </Button>
    </RightAlignedCardActions>
  )
}

ProductEditActions.propTypes = {
  basePath: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
}

const ProductTitle = ({ record }) => get(record, 'name')

export const ProductEdit = props => {
  const tabStyles = useFullWidthTabStyles()
  return (
    <Edit
      title={<ProductTitle />}
      actions={<ProductEditActions />}
      aside={<LanguageSwitcherAside />}
      {...props}
    >
      <TabbedForm toolbar={<EditToolbar hasDelete />} redirect="/products">
        <FormTab label="resources.products.tabs.general">
          <TextInput source="slug" validate={validateRequired} />
          <TextInput source="name" validate={validateRequired} />

          <ReferenceInput
            source="manufacturer"
            reference="manufacturers"
            validate={validateRequired}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <CommonInputFieldsCategoryBasedOnStoreviewInput label="resources.products.fields.category.name" />

          <BooleanInput
            options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
            source="directDelivery"
            validate={validateRequired}
          />
          <BooleanInput
            options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
            source="active"
            validate={validateRequired}
          />
          <BooleanInput
            options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
            source="genericAccessory"
          />
          <ImageField source="image" />
          <ImageInput source="imageFile" maxSize={26214400}>
            <ImageField source="image" title="title" />
          </ImageInput>

          <MetaInput />
          {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
            <ReferenceInput
              source="storeView"
              reference="store_views"
              validate={validateRequired}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          )}
        </FormTab>
        <FormTab
          label="resources.products.tabs.configurableProducts"
          contentClassName={tabStyles.fullWidthTab}
        >
          <ReferenceManyField
            label="resources.products.tabs.configurableProducts"
            reference="product_variants"
            target="product"
          >
            <Datagrid style={{ width: '100%' }}>
              <TextField source="sku" />
              <CurrencyField source="price" />
              <PercentageField source="margin" />
              <BooleanField source="autoDisabled" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

ProductEdit.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const defaultValues = {
  vat: 19,
  active: true,
  genericAccessory: false,
  stock: 0,
  directDelivery: true,
  informationTabs: [],
  variants: [],
}

export const ProductCreate = props => (
  <Create title="Produkt anlegen" {...props}>
    <SimpleForm defaultValue={defaultValues}>
      <TextInput source="name" />

      <ReferenceInput
        source="manufacturer"
        reference="manufacturers"
        validate={validateRequired}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>

      <CommonInputFieldsCategoryBasedOnStoreviewInput label="resources.products.fields.category.name" />

      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="directDelivery"
      />
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="active"
      />
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="genericAccessory"
      />
      <ImageInput source="imageFile" maxSize={26214400}>
        <ImageField source="image" title="title" />
      </ImageInput>
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <ReferenceInput
          source="storeView"
          reference="store_views"
          validate={validateRequired}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
    </SimpleForm>
  </Create>
)

ProductCreate.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}
