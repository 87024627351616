import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import renderTextField from 'Components/Common/InputFields/renderComponents/renderTextField'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import {
  useDataProvider,
  useMutation,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'
import { Field, Form, useForm, useFormState } from 'react-final-form'
import EnumMemberAutoSuggest from './EnumMemberAutoSuggest'

const required = value => (value ? undefined : 'Required')

const EnumMemberSubscriber = () => {
  const dataProvider = useDataProvider()
  const { change } = useForm()
  const {
    values: { enumMember_reference_id: enumMemberId },
  } = useFormState({ subscription: { values: true } })

  useEffect(() => {
    const updateValues = async () => {
      if (enumMemberId) {
        change('name', 'Wird geladen...')
        const res = await dataProvider.getOne('also_enum_members', {
          id: enumMemberId,
        })

        change('name', res.data.displayName)
        change('sku', res.data.sku)
      }
    }

    updateValues()
  }, [enumMemberId])

  return null
}

const CreateProductVariant = ({ record }) => {
  const translate = useTranslate()
  const showNotification = useNotify()
  const refreshView = useRefresh()

  const [mutate, { loading }] = useMutation()

  const handleSubmit = values => {
    mutate(
      {
        type: 'create',
        resource: 'also_product_variants',
        payload: { data: values },
      },

      {
        onSuccess: () => {
          showNotification('notifications.alsoProductVariant.create.success')
          refreshView()
        },
        onFailure: () => {
          showNotification(
            'notifications.alsoProductVariant.create.fail',
            'warning',
          )
        },
      },
    )
  }

  return (
    <div style={{ marginTop: '1rem;' }}>
      <Typography variant="subtitle1" gutterBottom>
        {translate('actions.createAlsoProductVariant')}
      </Typography>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          enumMember_reference_id: '',
          product_reference_id: record.id,
          temporaryName: '',
          sku: '',
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              validate={required}
              name="enumMember_reference_id"
              render={({ input, meta }) => (
                <EnumMemberAutoSuggest {...input} meta={meta} record={record} />
              )}
            />
            <Field
              name="name"
              validate={required}
              component={renderTextField}
              fullWidth
              label={translate('resources.also_product_variants.fields.name')}
            />
            <EnumMemberSubscriber />

            <div style={{ margin: '0.5em 0' }}>
              <Button
                variant="outlined"
                type="submit"
                color="primary"
                disabled={loading}
              >
                {loading && <CircularProgress size={25} thickness={2} />}
                {translate('actions.createAlsoProductVariant')}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  )
}

CreateProductVariant.propTypes = {
  record: PropTypes.object.isRequired,
}

export default CreateProductVariant
