import GenericRestClientButton from 'Components/Common/ActionButtons/GenericRestClientButton'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useToggle } from 'react-use'
import axios from 'axios'

const ToggleStoreViewCacheButton = ({ data }) => {
  const [loading, setLoading] = useToggle(true)
  const [isActive, toggleIsActive] = useToggle(true)

  const fetchStatus = async apiKey => {
    const res = await axios(
      `${process.env.REACT_APP_FRONTEND_NEXT_URL}/cache/${apiKey}/status`,
    ).then(response => response.data)
    toggleIsActive(res.status)
    setLoading(false)
  }

  useEffect(() => {
    if (data) {
      fetchStatus(data.apiKey)
    }
  }, [data])

  if (!data) {
    return null
  }

  const handleClick = async () => {
    const res = await axios({
      url: `${process.env.REACT_APP_FRONTEND_NEXT_URL}/cache/${data.apiKey}/toggle`,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => res.data)
    toggleIsActive(res.status)
  }

  return (
    <GenericRestClientButton
      type={isActive ? 'deactivateStoreViewCache' : 'activateStoreViewCache'}
      color={isActive ? 'primary' : 'secondary'}
      label={loading ? 'notifications.fetchCacheStatus' : null}
      loading={loading}
      diabled={loading}
      onClick={handleClick}
    />
  )
}

ToggleStoreViewCacheButton.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
}

ToggleStoreViewCacheButton.defaultProps = {
  data: null,
}

export default ToggleStoreViewCacheButton
