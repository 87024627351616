import {
  LinearProgress,
  ListSubheader,
  MenuItem,
  Paper,
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Autosuggest from 'react-autosuggest'
import './theme.css'

export const renderSuggestion = renderSuggestionLabel => (
  suggestion,
  { isHighlighted },
) => (
  <MenuItem component="div" selected={isHighlighted}>
    {renderSuggestionLabel(suggestion)}
  </MenuItem>
)

export const renderSuggestionsContainer = ({ containerProps, children }) =>
  React.Children.count(children) > 0 ? (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  ) : null

renderSuggestionsContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  containerProps: PropTypes.object,
  children: PropTypes.node.isRequired,
}

renderSuggestionsContainer.defaultProps = {
  containerProps: {},
}

export function renderSectionTitle(section) {
  return <ListSubheader>{section.title}</ListSubheader>
}

export function getSectionSuggestions(section) {
  return section.suggestions
}

// eslint-disable-next-line
export const renderInputComponent = (label, variant = 'standard') => ({
  meta,
  ...inputProps
}) => (
  <TextField
    label={label}
    // errorText={meta.touched && meta.error}
    {...inputProps}
    fullWidth
    variant={variant}
  />
)

const GenericAutoSuggest = ({
  loading,
  clearOnSelect,
  onSuggestionsFetchRequested,
  onSuggestionSelected,
  renderSuggestionLabel,
  shouldRenderSuggestions,
  getSuggestionValue,
  inputLabel,
  inputValue,
  meta,
  autosuggestProps,
  resetOnBlur,
  inputProps,
  variant,
}) => {
  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([])

  const onChange = (event, { newValue }) => {
    setValue(newValue)
  }

  useEffect(() => {
    // if (inputValue === '') setValue('')
    setValue(inputValue)
  }, [inputValue])

  const style = {
    minHeight: '72px',
    paddingTop: '2rem',
    display: 'flex',
    alignItems: 'flex-end',
  }

  if (loading) {
    return (
      <div style={style}>
        <LinearProgress style={{ width: '100%' }} mode="indeterminate" />
      </div>
    )
  }

  const handleOnSuggestionSelected = (...args) => {
    if (clearOnSelect) {
      setValue('')
    }
    onSuggestionSelected(...args)
  }

  const onBlur = () => {
    if (resetOnBlur) {
      if (value === '') {
        setValue(inputValue)
      }
    }
  }

  const alwaysRenderSuggestions = () => true

  return (
    <>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested(
          setSuggestions,
        )}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion(renderSuggestionLabel)}
        renderSectionTitle={renderSectionTitle}
        highlightFirstSuggestion
        getSectionSuggestions={getSectionSuggestions}
        inputProps={{
          placeholder: '',
          value,
          onChange,
          meta,
          onBlur,
          ...inputProps,
        }}
        renderInputComponent={renderInputComponent(inputLabel, variant)}
        onSuggestionSelected={handleOnSuggestionSelected}
        shouldRenderSuggestions={
          shouldRenderSuggestions || alwaysRenderSuggestions
        }
        renderSuggestionsContainer={renderSuggestionsContainer}
        {...autosuggestProps}
      />
    </>
  )
}

GenericAutoSuggest.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  autosuggestProps: PropTypes.object,
  clearOnSelect: PropTypes.bool,
  getSuggestionValue: PropTypes.func.isRequired,
  inputLabel: PropTypes.string,
  inputValue: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  meta: PropTypes.object.isRequired,
  onSuggestionSelected: PropTypes.func,
  onSuggestionsFetchRequested: PropTypes.func,
  renderSuggestionLabel: PropTypes.func.isRequired,
  shouldRenderSuggestions: PropTypes.func,
  translate: PropTypes.func.isRequired,
  resetOnBlur: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
}

GenericAutoSuggest.defaultProps = {
  autosuggestProps: {},
  clearOnSelect: false,
  onSuggestionSelected: () => {},
  onSuggestionsFetchRequested: () => {},
  inputLabel: '',
  inputValue: '',
  shouldRenderSuggestions: () => true,
  resetOnBlur: false,
  variant: 'standard',
}

export default GenericAutoSuggest
