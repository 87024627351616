import { useTheme } from '@material-ui/core/styles'
import { GetApp } from '@material-ui/icons'
import Button from 'Components/Common/Buttons/Button'
import mime from 'mime-types'
import { useOrder } from 'OrderContext'
import b64toBlob from 'b64-to-blob'
import React from 'react'
import {
  useNotify,
  useTranslate,
  useDataProvider,
  useLoading,
} from 'react-admin'

const DownloadAllAttachmentsAsArchiveButton = () => {
  const { record } = useOrder()
  const t = useTranslate()
  const showNotification = useNotify()
  const {
    palette: {
      primary: { main },
    },
  } = useTheme()
  const loading = useLoading()

  const dataProvider = useDataProvider()

  const handleClick = async () => {
    try {
      const { data: attachments } = await dataProvider.getManyReference(
        'attachments',
        {
          target: 'order',
          id: record.id,
          pagination: false,
          sort: false,
          filter: false,
        },
      )
      attachments.forEach(attachment => {
        const fileName =
          attachment.type === 'Angebot' ? attachment.comment : attachment.type

        dataProvider
          .downloadAttachment('attachments', {
            id: attachment.id,
          })
          .then(async ({ data: response }) => {
            const contentType = response.headers.get('Content-Type')
            const resText = await response.text()
            const blob = b64toBlob(resText, contentType)
            const blobUrl = URL.createObjectURL(blob)
            const a = document.createElement('a')
            document.body.appendChild(a)
            const extension = mime.extension(contentType)
            a.style = 'display: none'
            a.href = blobUrl
            a.download = `${fileName}.${extension}`
            a.click()
            URL.revokeObjectURL(blobUrl)
          })
          .catch(() => {
            showNotification(`Es ist ein Fehler aufgetreten`, 'warning')
          })
      })
    } catch (e) {
      showNotification(`Es ist ein Fehler aufgetreten`, 'warning')
    }
  }

  return (
    <Button
      onClick={handleClick}
      label={t('actions.downloadAll')}
      loading={loading}
    >
      <GetApp color={main} />
    </Button>
  )
}

export default DownloadAllAttachmentsAsArchiveButton
