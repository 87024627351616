import PropTypes from 'prop-types'
import { get } from 'lodash'

const PercentageField = ({ label, source, record = {} }) =>
  `${get(record, source)}%`

PercentageField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

PercentageField.defaultProps = {
  addLabel: true,
}

export default PercentageField
