import {
  Datagrid,
  Filter,
  TextInput,
  List,
  TextField,
  DateField,
} from 'react-admin'
import UpdateAlsoApiDataButton from 'Components/Also/ActionButtons/UpdateAlsoApiDataButton'
import React from 'react'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'
import AlsoActionDialogsCreateProductWizardButton from 'Components/Also/ActionDialogs/CreateProductVariant/Button'

const AlsoEnumFilter = props => (
  <Filter {...props}>
    <TextInput source="displayName" />
    <TextInput source="value" />
    <TextInput source="field" />
  </Filter>
)
const Actions = ({
  resource,
  filters,
  displayedFilters,
  filterValues,
  showFilter,
}) => (
  <RightAlignedCardActions>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <UpdateAlsoApiDataButton />
  </RightAlignedCardActions>
)

const AlsoEnumMemberList = props => (
  <List
    {...props}
    pagination={<CustomPagination />}
    actions={<Actions />}
    filters={<AlsoEnumFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <Datagrid>
      <TextField source="displayName" />
      <TextField source="value" />
      <TextField source="field" />
      <DateField source="createdAt" />
      <AlsoActionDialogsCreateProductWizardButton />
    </Datagrid>
  </List>
)

export default AlsoEnumMemberList
