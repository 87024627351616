import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate } from 'react-admin'

export const FormWizardStep = ({ children }) => children

FormWizardStep.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 960,
    margin: '60px auto',
  },
  heading: {
    fontSize: '1.8rem',
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const FormWizard = ({
  initialValues,
  nextLabel,
  prevLabel,
  submitLabel,
  children,
  ...props
}) => {
  const classes = useStyles(props)
  const [activeStep, setActiveStep] = useState(0)
  const [values, setValues] = useState(initialValues || {})
  const stepComponents = React.Children.toArray(children)
  const translate = useTranslate()

  const nextStep = values => {
    setActiveStep(prevStep => Math.min(prevStep + 1, children.length - 1))
    setValues(values)
  }

  const previousStep = () => {
    setActiveStep(prevStep => Math.max(prevStep - 1, 0))
  }

  const validate = values => {
    const activePage = stepComponents[activeStep]
    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

  const handleSubmit = values => {
    const { onSubmit } = props
    const isLastPage = activeStep === React.Children.count(children) - 1
    if (isLastPage) {
      return onSubmit(values)
    }
    nextStep(values)
    return undefined
  }

  const currentStepComponent = stepComponents[activeStep]
  const isLastPage = activeStep === React.Children.count(children) - 1

  return (
    <div>
      <Stepper activeStep={activeStep}>
        {React.Children.map(children, child => (
          <Step key={child.props.title}>
            <StepLabel>{child.props.title}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Form initialValues={values} validate={validate} onSubmit={handleSubmit}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            {currentStepComponent}
            <div>
              {activeStep > 0 && (
                <Button
                  variant="contained"
                  color="default"
                  type="button"
                  onClick={previousStep}
                  className={classes.button}
                >
                  {translate(prevLabel)}
                </Button>
              )}
              {!isLastPage && (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.button}
                >
                  {translate(nextLabel)}
                </Button>
              )}
              {isLastPage && (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.button}
                  disabled={submitting}
                >
                  {translate(submitLabel)}
                </Button>
              )}
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}

FormWizard.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  nextLabel: PropTypes.string,
  prevLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
}

FormWizard.defaultProps = {
  initialValues: {},
  nextLabel: 'forms.formWizard.nextStep',
  prevLabel: 'forms.formWizard.prevStep',
  submitLabel: 'forms.formWizard.submit',
}

export default FormWizard
