import React, { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import MuiAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MenuIcon from '@material-ui/icons/Menu'
import { toggleSidebar as toggleSidebarAction } from 'ra-core'
import {
  UserMenu,
  HideOnScroll,
  LoadingIndicator,
  usePermissions,
} from 'react-admin'
import { isAlso } from 'Lib/helper'
import Title from '../Title'

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24,
  },
  menuButton: {
    marginLeft: '0.5em',
    marginRight: '0.5em',
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(0deg)',
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(180deg)',
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}))

const AppBar = ({
  children,
  className,
  logo,
  logout,
  open,
  title,
  userMenu,
  ...rest
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const widthMatches = useMediaQuery(theme.breakpoints.only('xs'))
  const dispatch = useDispatch()
  const toggleSidebar = () => dispatch(toggleSidebarAction())
  const { permissions } = usePermissions()

  const color = isAlso(permissions) ? 'white' : 'primary'

  return (
    <HideOnScroll>
      <MuiAppBar className={className} color={color} {...rest}>
        <Toolbar
          disableGutters
          variant={widthMatches ? 'regular' : 'dense'}
          className={classes.toolbar}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleSidebar}
            className={classNames(classes.menuButton)}
          >
            <MenuIcon
              classes={{
                root: open
                  ? classes.menuButtonIconOpen
                  : classes.menuButtonIconClosed,
              }}
            />
          </IconButton>
          {Children.count(children) === 0 ? (
            <Typography
              variant="title"
              color="inherit"
              className={classes.title}
              id="react-admin-title"
            />
          ) : (
            children
          )}
          <Typography color="inherit" className={classes.title}>
            <Title />
          </Typography>
          <LoadingIndicator />
          {cloneElement(userMenu, { logout })}
        </Toolbar>
      </MuiAppBar>
    </HideOnScroll>
  )
}

AppBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  toggleSidebar: PropTypes.func,
  userMenu: PropTypes.node,
}

AppBar.defaultProps = {
  userMenu: <UserMenu />,
}

export default AppBar
