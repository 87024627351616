import React from 'react'
import GenericRestClientButton from '../../Common/ActionButtons/GenericRestClientButton'

const FindITSCopeBundleButton = ({ id }) => (
  <GenericRestClientButton
    action="findITScopeBundle"
    resource="promo_bundles"
    payload={{ id }}
    type="findITScopeBundle"
  />
)

export default FindITSCopeBundleButton
