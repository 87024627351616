import React from 'react'

import { Box, Step, StepLabel, Stepper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ConfirmationDialog from 'Components/Aluxo/ActionDialogs/BulkCreateDeviceVariantsDialog/ConfirmationDialog'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
}))

function StepContent({
  content,
  buttons,
  steps,
  activeStep,
  needsConfirmation,
  cancelConfirmation,
  handleConfirmation,
}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {content}
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {steps.map(({ label }, index) => {
          const stepProps = {}
          const labelProps = {}
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <div>
        <ConfirmationDialog
          open={needsConfirmation}
          cancel={cancelConfirmation}
          confirm={handleConfirmation}
        />
        <Box display="flex" justifyContent="flex-end">
          {buttons}
        </Box>
      </div>
    </div>
  )
}

export default StepContent
