import { Box, ListItem, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InStockDisplay from 'Components/Aluxo/InStockDisplay/InStockDisplay'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles({
  primary: {
    whiteSpace: 'nowrap',
  },
})

export default function RenderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  style,
  selectedItem,
  itemToString,
}) {
  const classes = useStyles()

  const isHighlighted = highlightedIndex === index
  const isSelected = selectedItem && selectedItem.value === suggestion.value

  return (
    <ListItem
      button
      {...itemProps}
      selected={isHighlighted}
      divider
      style={{
        ...style,
        fontWeight: isSelected ? 600 : 400,
      }}
    >
      <ListItemText classes={classes}>
        <Box display="flex">
          <InStockDisplay stock={suggestion.stock} sku={suggestion.sku} />
          {itemToString(suggestion)}
        </Box>
      </ListItemText>
    </ListItem>
  )
}
RenderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  itemToString: PropTypes.func.isRequired,
  selectedItem: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    stock: PropTypes.number.isRequired,
    sku: PropTypes.string.isRequired,
  }).isRequired,
}
