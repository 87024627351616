import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'

import { Close, Check } from '@material-ui/icons'

const BooleanField = ({
  source,
  record = {},
  elStyle = {},
  hideFalseValues = false,
}) => {
  if (!hideFalseValues && get(record, source) === false) {
    return <Close style={elStyle} />
  }

  if (get(record, source) === true) {
    return <Check style={elStyle} />
  }

  return <span style={elStyle} />
}

BooleanField.propTypes = {
  addLabel: PropTypes.bool,
  elStyle: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  hideFalseValues: PropTypes.bool,
}

BooleanField.defaultProps = {
  addField: true,
  addLabel: true,
  hideFalseValues: false,
  elStyle: {
    display: 'block',
    margin: 'auto',
  },
}

export default BooleanField
