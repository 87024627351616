import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import { useToggle } from 'react-use'
import ProductAutoSuggest from 'Components/Common/InputFields/Also/ProductAutoSuggest/ProductAutoSuggest'
import { Box, makeStyles } from '@material-ui/core'
import Button from 'Components/Common/Buttons/Button'
import Grid from '@material-ui/core/Grid'
// import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import MuiButton from '@material-ui/core/Button'

const WorkplaceConfigurationField = ({
  workplaceConfigurationField,
  index,
  handleRemove,
}) => {
  const { input } = useField(
    `workplaceConfigurationFields[${index}]productVariant`,
  )

  const { input: valueInput } = useField(
    `workplaceConfigurationFields[${index}]value`,
  )

  const [editing, toggle] = useToggle(false)

  const buttonLabel = useMemo(() => {
    if (editing) {
      return 'Abbrechen'
    }
    if (!input.value) {
      return 'Auswählen'
    }
    return 'Ändern'
  }, [editing, input])

  return (
    <Box display="flex" width="100%">
      <div style={{ width: '200px' }}>{workplaceConfigurationField.field}</div>
      {editing ? (
        <Box ml={1} flexGrow={1}>
          <ProductAutoSuggest
            type={workplaceConfigurationField.field}
            value={input.value.substr('/api/also_product_variants/'.length)}
            onChange={selection => {
              input.onChange(`/api/also_product_variants/${selection.value}`)
              valueInput.onChange(selection.label)
              toggle()
            }}
          />
        </Box>
      ) : (
        <Box ml={1}>{valueInput.value}</Box>
      )}
      <Box ml={1}>
        <Button type="button" onClick={toggle} label={buttonLabel} />
      </Box>
      {workplaceConfigurationField.field !== 'WorkplaceUserDevice' && (
        <Box ml={1}>
          <Button type="button" onClick={handleRemove} label="Entfernen" />
        </Box>
      )}
    </Box>
  )
}

const groups = [
  'WorkplaceUserDevice',
  'DockingStation',
  'Display',
  'Printer',
  'KeyboardandMouse',
  'AdditionalAccessories',
  'Tablet',
  'Scanner',
  'Headset',
  'AddOnKeyboard',
]

const useStyles = makeStyles(theme => ({
  popper: {
    zIndex: theme.zIndex.modal,
  },
}))

function SplitButton({ onClick, groups }) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleMenuItemClick = (event, index) => {
    setOpen(false)
    onClick(groups[index].type)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup
          variant="contained"
          color="primary"
          ref={anchorRef}
          aria-label="split button"
        >
          <MuiButton
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            Hinzufügen <ArrowDropDownIcon />
          </MuiButton>
        </ButtonGroup>
        <Popper
          className={classes.popper}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {groups.map((option, index) => (
                      <MenuItem
                        key={option.type}
                        disabled={option.disabled}
                        onClick={event => handleMenuItemClick(event, index)}
                      >
                        {option.type}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  )
}

const WorkplaceConfigurationDisplay = () => {
  const {
    input: { value, onChange },
  } = useField('workplaceConfigurationFields')

  return (
    <Grid container spacing={1}>
      {value.map((workplaceConfigurationField, index) => (
        <Grid item xs={12}>
          <WorkplaceConfigurationField
            workplaceConfigurationField={workplaceConfigurationField}
            index={index}
            handleRemove={() => {
              const newValue = value.slice()
              newValue.splice(index, 1)
              onChange(newValue)
            }}
          />
        </Grid>
      ))}

      <Grid item>
        <SplitButton
          groups={groups.map(g => ({
            type: g,
            disabled: value.some(v => v.field === g),
          }))}
          onClick={type => {
            onChange([
              ...value,
              {
                quantity: 1,
                field: type,
              },
            ])
          }}
        />
      </Grid>
    </Grid>
  )
}

WorkplaceConfigurationDisplay.propTypes = {
  record: PropTypes.shape({
    workplaceConfigurationFields: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
}

WorkplaceConfigurationDisplay.defaultProps = {
  addLabel: true,
}

export default WorkplaceConfigurationDisplay
