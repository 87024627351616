import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Box } from '@material-ui/core'

const MissingSpecsField = ({ source, record, elStyle }) => {
  const value = get(record, source) || []
  return (
    <Box color="error.main" style={elStyle}>
      {value.map(spec => spec.name).join(', ')}
    </Box>
  )
}

MissingSpecsField.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  addLabel: PropTypes.bool,
  elStyle: PropTypes.object,
  record: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
}

MissingSpecsField.defaultProps = {
  addLabel: true,
  elStyle: {},
}

export default MissingSpecsField
