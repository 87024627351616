import React from 'react'
import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  regex,
  SelectInput,
  SimpleForm,
  TextInput,
  translate,
  useTranslate,
} from 'react-admin'
import { Box, CardActions, Grid, Typography } from '@material-ui/core'

import EditToolbar from 'Components/Common/EditToolbar'
import DateField from 'Components/Common/ViewFields/DateField'
import RefreshITScopePricesButton from 'Components/Aluxo/ActionButtons/RefreshITScopePricesButton'
import BulkUpdateITScopeMetadataButton from 'Components/Aluxo/ActionButtons/BulkUpdateITScopeMetadataButton'

const EditActions = () => <CardActions />

const ItScopeSettingsTitle = translate(({ translate: t }) =>
  t('resources.itscope_settings.name'),
)

// eslint-disable-next-line import/prefer-default-export
export const ITScopeSettingsEdit = ({ ...props }) => {
  const t = useTranslate()

  return (
    <Edit title={<ItScopeSettingsTitle />} actions={<EditActions />} {...props}>
      <SimpleForm toolbar={<EditToolbar />} redirect={false}>
        <TextInput source="username" fullWidth />
        <TextInput source="apiKey" fullWidth />

        <BulkUpdateITScopeMetadataButton />
        <RefreshITScopePricesButton />
        <BooleanInput
          options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
          source="priceUpdateActive"
          fullWidth
        />
        <BooleanInput
          options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
          source="stockUpdateActive"
          fullWidth
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData.priceUpdateActive || formData.stockUpdateActive) && (
              <Grid container spacing={1}>
                <Grid item>
                  <SelectInput
                    source="updateInterval"
                    choices={['daily', 'weekly'].map(c => ({
                      id: c,
                      name: `resources.itscope_settings.intervals.${c}`,
                    }))}
                    {...rest}
                  />
                </Grid>
                <Grid item>
                  <TextInput
                    source="updateTime"
                    validate={regex(
                      /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
                      'Must be a valid time',
                    )}
                    {...rest}
                  />
                </Grid>

                <FormDataConsumer {...rest}>
                  {({ formData, ...rest }) =>
                    formData.updateInterval === 'weekly' && (
                      <Grid item>
                        <SelectInput
                          source="updateDay"
                          choices={[
                            'monday',
                            'tuesday',
                            'wednesday',
                            'thursday',
                            'friday',
                            'saturday',
                            'sunday',
                          ].map(c => ({
                            id: c,
                            name: `resources.itscope_settings.intervals.days.${c}`,
                          }))}
                          {...rest}
                        />
                      </Grid>
                    )
                  }
                </FormDataConsumer>
                <Grid item>
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    pt={2}
                    flexDirection="column"
                    height="100%"
                  >
                    <Typography color="textSecondary">
                      {t('resources.itscope_settings.fields.nextUpdate')}
                    </Typography>
                    <DateField
                      source="nextUpdate"
                      keepLocalTime={false}
                      {...rest}
                    />
                  </Box>
                </Grid>
              </Grid>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
}
