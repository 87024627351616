import get from 'lodash/get'
import Fuse from 'fuse.js'

export default function getSuggestions({
  productVariants,
  itScopeData,
  inputValue,
}) {
  const searchValue = inputValue || ''.trim().toLowerCase()

  const fuse = new Fuse(productVariants, {
    keys: ['sku', 'name'],
  })

  const results = fuse.search(searchValue)

  const groups = []
  results.forEach(({ item }) => {
    const suggestion = {
      ...item,
      value: item.id,
      label: `${item.sku} - ${item.fullName}`,
      type: 'aluxo365',
    }
    const group = groups.find(group => group.id === item.product.id)
    if (group) {
      group.suggestions.push(suggestion)
    } else {
      groups.push({
        id: item.product.id,
        title: `${get(item, 'product.manufacturer.name')} ${item.product.name}`,
        suggestions: [suggestion],
      })
    }
  })

  if (itScopeData.length > 0) {
    groups.push({
      id: 'itscope',
      title: 'ITScope',
      suggestions: itScopeData.map(item => ({
        ...item,
        id: item.puid,
        sku: item.manufacturerSKU,
        label: `${item.manufacturerSKU} - ${item.productName}`,
        value: item.puid,
        type: 'itscope',
      })),
    })
  }

  return groups
}
