import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  List,
  ListButton,
  SimpleForm,
  TextField,
  TextInput,
  SelectInput,
} from 'react-admin'
import { get } from 'lodash'
import React from 'react'

import EditToolbar from 'Components/Common/EditToolbar'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import PercentageField from 'Components/Common/ViewFields/PercentageField'
import NumberWithUnitInput from 'Components/Common/InputFields/NumberWithUnitInput'
import TinyMCEInput from 'Components/Common/InputFields/TinyMCEInput'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import ReferenceInput from 'Components/Common/ReferenceInput'

import UpdateITScopeMetadataButton from 'Components/Aluxo/ActionButtons/UpdateITScopeMetadataButton'
import UpdateITScopeSinglePriceButton from 'Components/Aluxo/ActionButtons/UpdateITScopeSinglePriceButton'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import { validateRequired } from './devices'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

const EditActions = ({ data, resource, basePath }) => (
  <RightAlignedCardActions>
    <UpdateITScopeMetadataButton data={data} resource={resource} />
    <UpdateITScopeSinglePriceButton data={data} resource={resource} />
    <ListButton basePath={basePath} />
  </RightAlignedCardActions>
)

const ServiceExtensionFilter = props => (
  <Filter {...props}>
    <TextInput source="sku" />
    <TextInput source="name" />
    <ReferenceInput
      label="resources.service_extensions.fields.manufacturer.name"
      source="manufacturer"
      reference="manufacturers"
    >
      <SelectInput optionText={choice => choice.name} />
    </ReferenceInput>
    <NumberWithUnitInput source="price[lt]" label="filters.monthlyPrice.lt" />
    <NumberWithUnitInput source="price[gt]" label="filters.monthlyPrice.gt" />
    <BooleanInput
      options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
      source="active"
    />
  </Filter>
)

export const ServiceExtensionList = props => (
  <List
    {...props}
    filters={<ServiceExtensionFilter />}
    pagination={<CustomPagination />}
    filter={{ managed: props.resource === 'managed_services' }}
    aside={<LanguageSwitcherAside />}
  >
    <Datagrid>
      <TextField source="sku" />
      <TextField source="name" />
      <TextField source="term" />
      <TextField source="manufacturer.name" />
      <CurrencyField source="price" />
      <PercentageField source="margin" />
      <BooleanField source="active" />
      <EditButton />
    </Datagrid>
  </List>
)

const defaultValues = props => ({
  active: true,
  price: 0,
  margin: 0,
})

const Title = ({ record }) => get(record, 'name')

export const ServiceExtensionEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<Title />}
    aside={<LanguageSwitcherAside />}
    {...props}
  >
    <SimpleForm
      toolbar={<EditToolbar hasDelete />}
      redirect="/service_extensions"
    >
      <TextInput source="sku" />
      <TextInput source="ean" validate={validateRequired} />
      <TextField source="puid" />
      <TextInput source="name" validate={validateRequired} />
      <SelectInput
        source="term"
        choices={[
          { id: 24, name: '24' },
          { id: 36, name: '36' },
        ]}
      />
      <ReferenceInput
        source="manufacturer"
        reference="manufacturers"
        validate={validateRequired}
      >
        <SelectInput optionText={choice => choice.name} />
      </ReferenceInput>

      <TinyMCEInput source="description" validate={validateRequired} />
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="active"
        validate={validateRequired}
      />
      <NumberWithUnitInput source="price" />
      <NumberWithUnitInput source="margin" unit="%" />
    </SimpleForm>
  </Edit>
)

export const ServiceExtensionCreate = props => (
  <Create {...props}>
    <SimpleForm defaultValue={defaultValues(props)}>
      <TextInput source="sku" />
      <TextInput source="ean" validate={validateRequired} />
      <TextInput source="name" validate={validateRequired} />
      <SelectInput
        source="term"
        choices={[
          { id: 24, name: '24' },
          { id: 36, name: '36' },
        ]}
      />
      <ReferenceInput
        source="manufacturer"
        reference="manufacturers"
        validate={validateRequired}
      >
        <SelectInput optionText={choice => choice.name} />
      </ReferenceInput>

      <TinyMCEInput source="description" validate={validateRequired} />
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="active"
        validate={validateRequired}
      />
      <NumberWithUnitInput source="price" />
      <NumberWithUnitInput source="margin" unit="%" />
    </SimpleForm>
  </Create>
)
