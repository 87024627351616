import ra from './ra'
import notifications from './notifications'
import resources from './resources'
import actions from './actions'
import orders from './orders'
import alsoOrders from './alsoOrders'
import menu from './menu'
import materialTable from './materialTable'
import events from './events'
import orderLines from './orderLines'
import alsoOrderLines from './alsoOrderLines'
import alsoWorkplaceServices from './alsoWorkplaceServices'
import texts from './texts'
import alsoServices from './alsoServices'
import alsoResellerCustomServices from './alsoResellerCustomServices'
import forms from './forms'
import dialogs from './dialogs'
import processes from './processes'

export default {
  ra,
  protectionPlans: {
    preview: 'Schutzbriefübersicht ansehen',
    previewCurrent: 'Aktuelle Schutzbriefübersicht ansehen',
    download: {
      button: 'Schutzbriefübersicht herunterladen',
      fileName: 'Schutzbriefübersicht',
      noFile: 'Für diesen Schutzbrief ist keine Übersicht vorhanden',
    },
    serviceOverview: 'Leistungsübersicht',
    with: 'mit',
    without: 'ohne',
    NO_PROTECTION_PLAN: 'Kein Schutzbrief',
    PROTECTION_PLAN: 'Schutzbrief',
    PROTECTION_PLAN_NAME:
      'Schutzbrief (%{duration} Monate / %{theft} Diebstahlschutz)',
    PROTECTION_PLAN_WITH_THEFT_PROTECTION: 'Schutzbrief mit Diebstahlschutz',
  },
  notifications,
  errors: {
    transitionNotPossible: 'Nächster Prozesschritt nicht möglich',
    transitionNotApplied: 'Bestellung wurde nicht prozessiert',
    orderNotLocked: 'Bestellung konnte nicht gesperrt werden',
    orderNotUnlocked: 'Bestellung konnte nicht freigegeben werden',
    dataCouldNotBeLoaded: 'Daten konnten nicht geladen werden',
    noData: 'Keine Daten vorhanden',
    errorResellerNotFound: 'Fehler beim Aufrufen der Einstellungen',
    'Albis application already created':
      'Prüfungsanfrage wurde bereits erstellt',
  },
  services: {
    albis: {
      api: 'Albis API',
      testapi: 'Albis TEST API',
    },
    itscope: 'IT Scope',
    'albis-portal': 'Albis Portal',
  },
  generalFields: {
    metaData: {
      metaTitle: 'Meta Titel',
      metaDescription: 'Meta Beschreibung',
      metaKeywords: 'Meta Schlüsselwörter',
    },
    specs: {
      header: 'Spezifikationen',
      title: 'Spezifikation Titel',
      spec: 'Spezifikation Inhalt',
    },
  },
  Unauthorized: 'Benutzername oder Passwort nicht korrekt',
  actions,
  state: {
    uploading: 'Lade hoch...',
  },
  filters: {
    orders: {
      createdBefore: 'Bestellt bis Datum',
      createdAfter: 'Bestellt seit Datum',
    },
    monthlyPrice: {
      lt: 'Monatlicher Preis weniger als',
      gt: 'Monatlicher Preis mehr als',
    },
  },
  orders,
  alsoOrders,
  events,
  orderLines,
  alsoOrderLines,
  menu,
  materialTable,
  dashboard: {
    ordersLoading: 'Bestellungen werden geladen...',
    highPriority: 'Priorisiert',
    overdue: 'Überfällig',
    actions: {
      showAllOrders: 'Alle Bestellungen einblenden',
      filterOrders: 'Leere Status ausblenden',
    },
  },
  placeholders: {
    loading_email_templates: 'E-Mail Vorlagen werden geladen',
    select_email_template: 'E-Mail Vorlage auswählen',
    loading_attribute_values: 'Attributswerte werden geladen',
    select_attribute_values: 'Attributswert auswählen',
  },
  auth: {
    user_or_pass_not_correct: 'Benutzername oder Passwort nicht korrekt',
    unknown_method: 'Es ist ein Fehler aufgetreten',
  },
  validation: {
    colorHexFormat:
      'Farbwert muss hexadezimal sein und mit # beginnen (bspw. #EF12AB)',
  },
  resources,
  messages: {
    acmpConfiguration: {
      pleaseWait: 'Bitte warten...',
    },
    orders: {
      selectStatus: 'Bitte Status wählen',
      emailSent: 'E-Mail wurde verschickt',
      markedAsShipped: 'Auftrag als verschickt markiert',
      undoMarkedAsShipped: 'Auftrag als nicht verschickt markiert',
      markedAsOfficeConfigured: 'Auftrag als eingerichtet markiert',
      undoMarkedAsOfficeConfigured: 'Auftrag als nicht eingerichtet markiert',
    },
  },
  texts,
  alsoServices,
  alsoResellerCustomServices,
  alsoWorkplaceServices,
  forms,
  dialogs,
  processes,
}
