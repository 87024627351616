import GenericRestClientButton from 'Components/Common/ActionButtons/GenericRestClientButton'
import PropTypes from 'prop-types'
import React from 'react'
import axios from 'axios'

const ClearStoreViewCacheButton = ({ data }) => {
  if (!data) {
    return null
  }

  return (
    <GenericRestClientButton
      type="clearStoreViewCache"
      onClick={async () => {
        await axios.delete(
          `${process.env.REACT_APP_FRONTEND_NEXT_URL}/cache/${data.apiKey}`,
        )
      }}
    />
  )
}

ClearStoreViewCacheButton.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
}

ClearStoreViewCacheButton.defaultProps = {
  data: null,
}

export default ClearStoreViewCacheButton
