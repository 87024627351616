import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { useProcessContext } from 'Components/Aluxo/ActionDialogs/BulkCreateDeviceVariantsDialog/ProcessContext'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Warning from '@material-ui/icons/Warning'
import CheckCircle from '@material-ui/icons/CheckCircle'
import SvgIcon from '@material-ui/core/SvgIcon'

const useColorlibStepIconStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    color: theme.palette.text.disabled,
    '&$completed': {
      color: theme.palette.primary.main,
    },
    '&$active': {
      color: theme.palette.primary.main,
    },
    '&$error': {
      color: theme.palette.error.main,
    },
  },
  /* Styles applied to the SVG text element. */
  text: {
    fill: theme.palette.primary.contrastText,
    fontSize: theme.typography.caption.fontSize,
    fontFamily: theme.typography.fontFamily,
  },
  /* Pseudo-class applied to the root element if `active={true}`. */
  active: {},
  /* Pseudo-class applied to the root element if `completed={true}`. */
  completed: {},
  /* Pseudo-class applied to the root element if `error={true}`. */
  error: {},
}))

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles()
  const {
    completed = false,
    icon,
    active = false,
    error = false,
    status,
  } = props

  if (typeof icon === 'number' || typeof icon === 'string') {
    const className = clsx(classes.root, {
      [classes.active]: active,
      [classes.error]: error,
      [classes.completed]: completed,
    })

    if (status === 'PROCESSING') {
      return <CircularProgress className={className} size={16} />
    }

    if (error) {
      return <Warning className={className} />
    }

    if (completed) {
      return <CheckCircle className={className} />
    }

    return (
      <SvgIcon className={className}>
        <circle cx="12" cy="12" r="12" />
        <text className={classes.text} x="12" y="16" textAnchor="middle">
          {icon}
        </text>
      </SvgIcon>
    )
  }

  return icon
}

const ProcessingInnerSteps = ({ prepareItem }) => {
  const { creationResult } = useProcessContext()

  return (
    <Stepper activeStep={null} orientation="vertical">
      <Step>
        <StepLabel
          StepIconComponent={ColorlibStepIcon}
          StepIconProps={{ status: prepareItem.status }}
          completed={prepareItem.status === 'success'}
          error={prepareItem.status === 'error'}
        >
          <Typography>{prepareItem.label}</Typography>
          <Typography>{prepareItem.comment}</Typography>
        </StepLabel>
      </Step>
      {creationResult.map((device, index) => [
        <Step key={`device-${index}`}>
          <StepLabel
            StepIconComponent={ColorlibStepIcon}
            StepIconProps={{ status: device.status }}
            completed={device.status === 'success'}
            error={device.status === 'error'}
          >
            <Typography>{device.label}</Typography>
            <Typography>{device.comment}</Typography>
          </StepLabel>
        </Step>,
        ...device.variants.map((variant, innerIdx) => {
          const { sku, status } = variant
          const labelProps = {
            error: status === 'error',
            completed: status === 'success',
          }
          return (
            <Step key={`device-${index}-variant-${innerIdx}`}>
              <StepLabel
                {...labelProps}
                StepIconComponent={ColorlibStepIcon}
                StepIconProps={{ status }}
              >
                <Typography>{variant.label}</Typography>
                <Typography>{variant.comment}</Typography>
              </StepLabel>
            </Step>
          )
        }),
      ])}
    </Stepper>
  )
}
export default ProcessingInnerSteps
