import React from 'react'
import PropTypes from 'prop-types'
import MuiTextField from '@material-ui/core/TextField'

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  showHelperText,
  fullWidth,
  ...custom
}) => (
  <MuiTextField
    label={label}
    error={Boolean(touched && error)}
    helperText={showHelperText && touched ? error : null}
    margin="normal"
    variant="filled"
    style={{ width: fullWidth ? '100%' : 256 }}
    fullWidth={fullWidth}
    {...input}
    {...custom}
  />
)

renderTextField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({ touched: PropTypes.bool, error: PropTypes.object })
    .isRequired,
  showHelperText: PropTypes.bool,
  fullWidth: PropTypes.bool,
}

renderTextField.defaultProps = {
  label: '',
  showHelperText: false,
  fullWidth: false,
}

export default renderTextField
