import { Button } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import ButtonLabel from './ButtonLabel'

const BackButton = ({ to, label }) => {
  const translate = useTranslate()
  return (
    <Button color="primary" component={Link} to={to}>
      <ArrowBack />
      <ButtonLabel>{translate(label)}</ButtonLabel>
    </Button>
  )
}

BackButton.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string,
}

BackButton.defaultProps = {
  label: 'actions.back',
}

export default BackButton
