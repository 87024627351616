import { CancelToken } from 'axios'
import { useDataProvider } from 'ra-core'
import { useDispatch } from 'react-redux'
import { FETCH_END } from 'react-admin'

const useCanceableDataProvider = () => {
  const dataProvider = useDataProvider()
  const dispatch = useDispatch()
  return (method, resource, params) => {
    const source = CancelToken.source()

    const promise = dataProvider[method](resource, {
      ...params,
      cancelToken: source.token,
    })

    return [
      promise,
      () => {
        source.cancel('Query was cancelled by React Query')
        dispatch({ type: FETCH_END })
      },
    ]
  }
}

export const useGetCancelToken = () => () => {
  const source = CancelToken.source()
  return [
    source.token,
    () => {
      source.cancel('Query was cancelled by React Query')
    },
  ]
}

export default useCanceableDataProvider
