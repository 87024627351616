export default [
  'GmbH',
  'GmbH & Co KG',
  'AG',
  'KG',
  'GbR',
  'Gewerbebetrieb',
  'OHG',
  'e.K. - eingetragener Kaufmann',
  'Staat',
  'Kirche',
  'Partei',
  'Freiberufler',
  'e.V.',
  'e.G.',
  'UG',
]
