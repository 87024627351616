export default {
  also_reseller_services: {
    subHeader: 'Hier können Sie Services zu ihrem Portfolio hinzufügen.',
    notAvailable:
      'Um diesen Service anbieten zu können, müssen Sie ihn zunächst im ACMP hinzufügen.',
  },
  also_reseller_products: {
    subHeader: 'Hier können Sie Produkte zu ihrem Portfolio hinzufügen.',
  },
  knowledge_items: {
    header: 'Knowledge Base',
  },
  additional: 'Weitere',
}
