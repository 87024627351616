import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import { usePermissions, useTranslate } from 'react-admin'

import AddDialog from 'Components/Aluxo/OrderLines/Lines/AddDialog'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import SetProtectionPlanButton from 'Components/Aluxo/Buttons/SetProtectionPlanButton'
import currencyText from 'Lib/currencyText'
import grey from '@material-ui/core/colors/grey'
import { isReseller } from 'Lib/helper'
import styled from 'styled-components/macro'
import { ResellerContext } from 'ResellerContext'
import { useOrder } from 'OrderContext'
import { useTheme } from '@material-ui/core/styles'
import useWorkplaceLines from './useWorkplaceLines'
import TableLine from './Lines/TableLine'
import { DIALOG_TYPE_EXISTING, DIALOG_TYPE_CUSTOM } from './Lines/AddDialogForm'

const minBlack = grey[500]

export const TableWrapper = styled.div`
  overflow-x: auto;
`

export const NonSortableLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${minBlack};
`

const OrderLineTableRow = ({ workplace, outerIndex, mode }) => {
  const { flags } = useContext(ResellerContext)
  const t = useTranslate()

  const lines = [
    workplace,
    ...workplace.serviceLines,
    workplace.protectionPlanLine,
    ...workplace.productLines,
    ...workplace.serviceExtensionLines,
  ].filter(line => line)
  const { linesToDisplay, bundledLines } = useWorkplaceLines({
    lines,
    bundleLine: workplace.bundleLine,
  })

  const { canChange } = useOrder()

  return (
    <>
      <TableRow>
        <TableCell colSpan="2">
          {flags.multipleFinancePartners ? (
            <Tooltip
              title={
                <span>
                  {t('orderLines.fields.creditGroup', {
                    creditGroup: workplace.creditGroup,
                  })}
                </span>
              }
            >
              <span> Arbeitsplatz {outerIndex + 1}</span>
            </Tooltip>
          ) : (
            `Arbeitsplatz ${outerIndex + 1}`
          )}
        </TableCell>
        <TableCell colSpan="9" align="right">
          {mode === 'general' && canChange && (
            <>
              <AddDialog
                reference="product_order_lines"
                dialogProps={{
                  supportedTypes: [DIALOG_TYPE_EXISTING, DIALOG_TYPE_CUSTOM],
                  withITScope: true,
                }}
                initialValues={{
                  deviceOrderLine: workplace.originId,
                  quantity: workplace.quantity,
                }}
              />
              <AddDialog
                reference="generic_order_lines"
                label="actions.add_text_order_lines"
                dialogProps={{
                  supportedTypes: [DIALOG_TYPE_CUSTOM],
                }}
                initialValues={{
                  deviceOrderLine: workplace.originId,
                  quantity: workplace.quantity,
                }}
              />
              <AddDialog
                reference="service_order_lines"
                dialogProps={{
                  supportedTypes: [DIALOG_TYPE_EXISTING, DIALOG_TYPE_CUSTOM],
                }}
                initialValues={{
                  deviceOrderLine: workplace.originId,
                  quantity: workplace.quantity,
                }}
              />
              <AddDialog
                reference="service_extension_order_lines"
                dialogProps={{
                  supportedTypes: [DIALOG_TYPE_EXISTING],
                  withITScope: true,
                }}
                initialValues={{
                  deviceOrderLine: workplace.originId,
                  quantity: workplace.quantity,
                }}
              />
              <SetProtectionPlanButton
                deviceLineId={workplace.id}
                type="addProtectionPlan"
                protectionPlanType="PROTECTION_PLAN"
                showLabel
              />
              <SetProtectionPlanButton
                deviceLineId={workplace.id}
                type="addProtectionPlanWithTheftProtection"
                protectionPlanType="PROTECTION_PLAN_WITH_THEFT_PROTECTION"
                showLabel
              />
            </>
          )}
        </TableCell>
      </TableRow>
      {linesToDisplay.map((line, index) => (
        <TableLine
          {...line}
          lineType={line['@type']}
          pos={`${outerIndex + 1}.${index + 1}`}
          bundledLines={bundledLines}
          mode={mode}
        />
      ))}
    </>
  )
}

OrderLineTableRow.propTypes = {
  workplace: PropTypes.object.isRequired,
  outerIndex: PropTypes.number.isRequired,
}

const OrderLinesTable = props => {
  const {
    workplaces,
    rest,
    totalNetSum,
    totalMargin,
    totalDiscount,
    mode,
  } = props

  const { permissions = [] } = usePermissions()

  const {
    palette: {
      primary: { main },
    },
  } = useTheme()
  const t = useTranslate()

  return (
    <TableWrapper>
      <Table
        id="order-lines"
        fixedHeader={false}
        fixedFooter={false}
        style={{ minWidth: '1550px' }}
        size="small"
        padding="none"
      >
        <TableHead displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableCell>
              <NonSortableLabel>
                {t('orderLines.fields.position')}
              </NonSortableLabel>
            </TableCell>
            <TableCell>
              <NonSortableLabel>{t('orderLines.fields.sku')}</NonSortableLabel>
            </TableCell>
            <TableCell>
              <NonSortableLabel>
                {t('orderLines.fields.description')}
              </NonSortableLabel>
            </TableCell>
            <TableCell align="right">
              <NonSortableLabel>
                {t('orderLines.fields.quantity')}
              </NonSortableLabel>
            </TableCell>
            {mode === 'general' ? (
              <>
                <TableCell align="right">
                  <NonSortableLabel>
                    {t('orderLines.fields.purchasePrice')}
                  </NonSortableLabel>
                </TableCell>
                <TableCell align="right">
                  <NonSortableLabel>
                    {t('orderLines.fields.netPrice')}
                  </NonSortableLabel>
                </TableCell>

                <TableCell align="right">
                  <NonSortableLabel>
                    {t('orderLines.fields.total')}
                  </NonSortableLabel>
                </TableCell>
                <TableCell align="right">
                  <NonSortableLabel>
                    {t('orderLines.fields.margin')}
                  </NonSortableLabel>
                </TableCell>

                <TableCell align="right">
                  <NonSortableLabel>
                    {t('orderLines.fields.marginEuro')}
                  </NonSortableLabel>
                </TableCell>

                <TableCell align="right">
                  <NonSortableLabel>
                    {t('orderLines.fields.discount')}
                  </NonSortableLabel>
                </TableCell>
              </>
            ) : (
              <>
                <TableCell>
                  <NonSortableLabel>
                    {t(
                      'resources.device_order_lines.fields.itScopeProductType',
                    )}
                  </NonSortableLabel>
                </TableCell>
                <TableCell>
                  <NonSortableLabel>
                    {t('resources.device_order_lines.fields.creditGroup')}
                  </NonSortableLabel>
                </TableCell>
                <TableCell align="left">
                  <NonSortableLabel>
                    {t('resources.device_order_lines.fields.dllAssetType')}
                  </NonSortableLabel>
                </TableCell>
                <TableCell align="left">
                  <NonSortableLabel>
                    {t('resources.device_order_lines.fields.dllBrandName')}
                  </NonSortableLabel>
                </TableCell>
                <TableCell align="left">
                  <NonSortableLabel>
                    {t('resources.device_order_lines.fields.hasSerialNumbers')}
                  </NonSortableLabel>
                </TableCell>
              </>
            )}
            {isReseller(permissions) && (
              <TableCell>
                <NonSortableLabel />
              </TableCell>
            )}
          </TableRow>
        </TableHead>

        <TableBody displayRowCheckbox={false}>
          {workplaces.map((workplace, outerIndex) => (
            <OrderLineTableRow
              workplace={workplace}
              outerIndex={outerIndex}
              mode={mode}
            />
          ))}
          {rest.map(line => (
            <TableLine
              {...line}
              permissions={permissions}
              lineType={line['@type']}
              pos={line.index}
              mode={mode}
            />
          ))}
        </TableBody>
        <TableFooter adjustForCheckbox={false} style={{ color: main }}>
          <TableRow>
            {mode === 'general' ? (
              <>
                <TableCell colSpan="5" />

                <TableCell className="total-net-sum" align="right">
                  {currencyText(totalNetSum)}
                </TableCell>
                <TableCell />
                <TableCell className="total-margin" align="right">
                  {currencyText(totalMargin)}
                </TableCell>

                <TableCell className="total-discount" align="right">
                  {totalDiscount > 0 && `-${currencyText(totalDiscount)}`}
                </TableCell>
              </>
            ) : (
              <>
                <TableCell colSpan="9" />
              </>
            )}

            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
    </TableWrapper>
  )
}

OrderLinesTable.propTypes = {
  workplaces: PropTypes.object.isRequired,
  rest: PropTypes.object.isRequired,
  totalNetSum: PropTypes.number.isRequired,
  totalMargin: PropTypes.number.isRequired,
  totalDiscount: PropTypes.number.isRequired,
}

export default OrderLinesTable
