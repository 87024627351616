import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import TranslatedTitle from 'Components/Common/TranslatedTitle'
import { useOrder } from 'OrderContext'
import PropTypes from 'prop-types'
import React from 'react'
import { SimpleShowLayout, useTranslate } from 'react-admin'

const OrderLink = ({ itScopeOrderId }) => (
  <a
    href={`https://www.itscope.com/red/app#deals/page/${itScopeOrderId}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {itScopeOrderId}
  </a>
)

OrderLink.propTypes = {
  itScopeOrderId: PropTypes.string.isRequired,
}

const OrderITScopeInfo = () => {
  const { resource, basePath, record } = useOrder()

  const itScopeOrders = record?.fields?.IT_SCOPE_ORDER_IDS || []
  const translate = useTranslate()

  return itScopeOrders.length > 0 ? (
    <SimpleShowLayout resource={resource} basePath={basePath} record={record}>
      <TranslatedTitle translateKey="resources.orders.tabs.it_scope_orders" />
      <Table>
        <TableHead>
          <TableCell>
            {translate('resources.order_it_scope_orders.fields.order_id')}
          </TableCell>
        </TableHead>
        <TableBody>
          {itScopeOrders.map(itScopeOrderId => (
            <TableRow>
              <TableCell>
                <OrderLink itScopeOrderId={itScopeOrderId} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </SimpleShowLayout>
  ) : null
}

export default OrderITScopeInfo
