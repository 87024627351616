import { Card, CardContent, Grid } from '@material-ui/core'
import AlsoOrderShowLines from 'Components/Also/Orders/Show/OrderLines'
import TranslatedTitle from 'Components/Common/TranslatedTitle'
import BooleanField from 'Components/Common/ViewFields/BooleanField'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import React from 'react'
import {
  DateField,
  Loading,
  SimpleShowLayout,
  TextField,
  useShowController,
} from 'react-admin'

const AlsoOrderShow = props => {
  const controllerProps = useShowController(props)

  const { record, loading } = controllerProps

  if (loading) {
    return (
      <Card>
        <CardContent>
          <Loading />
        </CardContent>
      </Card>
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card>
          <SimpleShowLayout {...controllerProps}>
            <TranslatedTitle translateKey="resources.also_orders.tabs.general" />
            <TextField source="reseller.name" />
            <TextField source="billingAddress.company" />
            <DateField source="created" format="DD.MM.YYYY" />
            <CurrencyField source="total" />
            <TextField source="marking" />
            <BooleanField
              options={{ labelStyle: { color: '#000000' } }}
              source="tradingModel"
            />
          </SimpleShowLayout>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <SimpleShowLayout {...controllerProps}>
            <TranslatedTitle translateKey="resources.also_orders.tabs.billingAddress" />
            <TextField source="billingAddress.salutation" />
            <TextField source="billingAddress.firstName" />
            <TextField source="billingAddress.lastName" />
            <TextField source="billingAddress.legalForm" />
            <TextField source="billingAddress.company" />
            <TextField source="billingAddress.street" />
            <TextField source="billingAddress.postcode" />
            <TextField source="billingAddress.city" />
            <TextField source="billingAddress.phone" />
            <TextField source="billingAddress.email" />
          </SimpleShowLayout>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <SimpleShowLayout {...controllerProps}>
            <TranslatedTitle translateKey="resources.also_orders.tabs.shippingAddress" />
            <TextField source="shippingAddress.salutation" />
            <TextField source="shippingAddress.firstName" />
            <TextField source="shippingAddress.lastName" />
            <TextField source="shippingAddress.legalForm" />
            <TextField source="shippingAddress.company" />
            <TextField source="shippingAddress.street" />
            <TextField source="shippingAddress.postcode" />
            <TextField source="shippingAddress.city" />
            <TextField source="shippingAddress.phone" />
            <TextField source="shippingAddress.email" />
          </SimpleShowLayout>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <SimpleShowLayout {...controllerProps}>
            <TranslatedTitle translateKey="resources.also_orders.tabs.orderLines" />
            <AlsoOrderShowLines orderId={record.id} />
          </SimpleShowLayout>
        </Card>
      </Grid>
    </Grid>
  )
}

export default AlsoOrderShow
