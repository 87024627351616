import { ListItemIcon, ListItemText } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import MUIMenu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import {
  Cloud,
  Computer,
  Dashboard,
  DevicesOther,
  EuroSymbol,
  LocalShipping,
  Security,
  Settings,
  ShopTwo,
  ViewList,
} from '@material-ui/icons'
import UserIcon from '@material-ui/icons/People'
import PropTypes from 'prop-types'
import React, { createElement, useContext } from 'react'
import { MenuItemLink } from 'react-admin'
import WebsiteDataSubmenu from '../../../WebsiteDataSubmenu'
import CustomSettingsMenuItem from '../../Common/Menu/CustomSettingsMenuItem'
import SettingsMenuItem from '../../Common/Menu/SettingsMenuItem'
import PersonalSmtpSettingsMenuItem from '../../Common/Menu/PersonalSmtpSettingsMenuItem'
import {
  TranslatedResourceName,
  TranslatedString,
} from '../../Common/Layout/MenuTranslations'
import SubMenu from '../../Common/Layout/SubMenu'
import { useMenuStyles } from '../../Common/Layout/Menu'
import { ResellerContext } from 'ResellerContext'

const Menu = ({ onMenuClick, permissions }) => {
  const classes = useMenuStyles()

  const { flags } = useContext(ResellerContext)
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <div>
        <MenuItemLink
          to="/"
          primaryText={<TranslatedResourceName resource="dashboard" />}
          onClick={onMenuClick}
          leftIcon={createElement(Dashboard)}
        />
        <Divider />
        <MenuItemLink
          to="/orders"
          primaryText={<TranslatedResourceName resource="orders" />}
          onClick={onMenuClick}
          leftIcon={createElement(EuroSymbol)}
        />
        {flags.createOrder && (
          <MenuItemLink
            to="/offers"
            primaryText={<TranslatedResourceName resource="offers" />}
            onClick={onMenuClick}
            leftIcon={createElement(EuroSymbol)}
          />
        )}
        <Divider />
        <MenuItemLink
          to="/categories"
          primaryText={<TranslatedResourceName resource="categories" />}
          onClick={onMenuClick}
          leftIcon={createElement(ViewList)}
        />
        <MenuItemLink
          to="/manufacturers"
          primaryText={<TranslatedResourceName resource="manufacturers" />}
          onClick={onMenuClick}
          leftIcon={createElement(DevicesOther)}
        />
        <MenuItemLink
          to="/devices"
          primaryText={<TranslatedResourceName resource="devices" />}
          onClick={onMenuClick}
          leftIcon={createElement(Computer)}
        />
        <MenuItemLink
          to="/products"
          primaryText={<TranslatedResourceName resource="products" />}
          onClick={onMenuClick}
          leftIcon={createElement(DevicesOther)}
        />
        <Divider />
        <MenuItemLink
          to="/protection_plans"
          primaryText={<TranslatedResourceName resource="protection_plans" />}
          onClick={onMenuClick}
          leftIcon={createElement(Security)}
        />
        <MenuItemLink
          to="/external_protection_plans"
          primaryText={
            <TranslatedResourceName resource="external_protection_plans" />
          }
          onClick={onMenuClick}
          leftIcon={createElement(Security)}
        />
        <MenuItemLink
          to="/service_extensions"
          primaryText={<TranslatedResourceName resource="service_extensions" />}
          onClick={onMenuClick}
          leftIcon={createElement(Security)}
        />
        {flags.promoBundles && (
          <MenuItemLink
            to="/promo_bundles"
            primaryText={<TranslatedResourceName resource="promo_bundles" />}
            onClick={onMenuClick}
            leftIcon={createElement(ShopTwo)}
          />
        )}
        <Divider />
        <MenuItemLink
          to="/online_services"
          primaryText={<TranslatedResourceName resource="online_services" />}
          onClick={onMenuClick}
          leftIcon={createElement(Cloud)}
        />
        <MenuItemLink
          to="/managed_services"
          primaryText={<TranslatedResourceName resource="managed_services" />}
          onClick={onMenuClick}
          leftIcon={createElement(ShopTwo)}
        />
        <Divider />
        <MenuItemLink
          to="/customers"
          primaryText={<TranslatedResourceName resource="customers" />}
          onClick={onMenuClick}
          leftIcon={createElement(UserIcon)}
        />
        <Divider />
        {permissions.includes('ROLE_STOREVIEW') && (
          <MenuItemLink
            to="/store_views"
            primaryText={<TranslatedResourceName resource="store_views" />}
            onClick={onMenuClick}
            leftIcon={createElement(UserIcon)}
          />
        )}
        <WebsiteDataSubmenu onMenuClick={onMenuClick} />
        <SubMenu
          mainItem={setTarget => (
            <Tooltip
              title={<TranslatedResourceName resource="itscope" />}
              placement="right"
            >
              <MenuItem
                onClick={({ currentTarget }) => setTarget(currentTarget)}
                className={classes.menu}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <LocalShipping />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  inset
                  primary={<TranslatedResourceName resource="itscope" />}
                />
              </MenuItem>
            </Tooltip>
          )}
        >
          {(target, hide) => (
            <MUIMenu anchorEl={target} open={Boolean(target)} onClose={hide}>
              <CustomSettingsMenuItem type="itScopeSettings" />
              <MenuItemLink
                to="/itscope_distributors"
                primaryText={
                  <TranslatedResourceName resource="itscope_distributors" />
                }
                onClick={onMenuClick}
              />
            </MUIMenu>
          )}
        </SubMenu>
      </div>

      <Divider />
      <SubMenu
        mainItem={set => (
          <Tooltip
            title={<TranslatedString i18nkey="menu.settings.name" />}
            placement="right"
          >
            <MenuItem
              onClick={({ currentTarget }) => set(currentTarget)}
              className={classes.menu}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Settings />
              </ListItemIcon>
              <ListItemText
                inset
                primary={<TranslatedString i18nkey="menu.settings.name" />}
                className={classes.listItemText}
              />
            </MenuItem>
          </Tooltip>
        )}
      >
        {(target, hide) => (
          <MUIMenu anchorEl={target} open={Boolean(target)} onClose={hide}>
            <SettingsMenuItem />
            <CustomSettingsMenuItem type="smtpConfiguration" />
            <PersonalSmtpSettingsMenuItem />
            {permissions.includes('ROLE_STOREVIEW') ? (
              <CustomSettingsMenuItem type="corporateIdentity" />
            ) : (
              <CustomSettingsMenuItem type="defaultStoreView" />
            )}
          </MUIMenu>
        )}
      </SubMenu>
    </div>
  )
}

Menu.propTypes = {
  onMenuClick: PropTypes.func,
}

Menu.defaultProps = {
  onMenuClick: () => null,
}

export default Menu
