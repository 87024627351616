import { put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { CRUD_DELETE_SUCCESS } from 'react-admin'
import { getIdOrIri } from 'Lib/helper'

function* afterDelete(action) {
  const {
    meta: { resource },
    requestPayload: { previousData },
  } = action

  if (resource === 'also_product_variants') {
    yield put(push(`/also_products/${getIdOrIri(previousData.product)}`))
  }
}

export default function* afterDeleteSaga() {
  yield takeLatest(CRUD_DELETE_SUCCESS, afterDelete)
}
