import currencyText from 'Lib/currencyText'
import Fuse from 'fuse.js'

const getSuggestions = ({ onlineServices, inputValue }) => {
  const searchValue = (inputValue || ''.trim()).toLowerCase()

  const fuse = new Fuse(onlineServices, {
    keys: ['sku', 'name'],
  })

  const results = fuse.search(searchValue)

  const groups = []
  results.forEach(({ item }) => {
    const suggestion = {
      ...item,
      value: item.id,
      label: `${item.sku} - ${item.name} (${currencyText(item.netPrice)})`,
    }
    const group = groups.find(group => group.type === item.type)
    if (group) {
      group.suggestions.push(suggestion)
    } else {
      groups.push({
        type: item.type,
        title: item.type,
        suggestions: [suggestion],
      })
    }
  })

  return groups
}

export default getSuggestions
