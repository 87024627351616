import ResendEmailButton from 'Components/Common/ActionButtons/ResendEmailButton'
import { useOrder } from 'OrderContext'
import React from 'react'
import {
  Datagrid,
  ReferenceManyField,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin'
import DateField from 'Components/Common/ViewFields/DateField'
import OrderCommentInput from './OrderCommentInput'
import OrderEvents from './OrderEvents'
import TranslatedStatus from './TranslatedStatus'

const OrderAdditionalInfo = () => {
  const { resource, basePath, record } = useOrder()
  return record ? (
    <TabbedShowLayout resource={resource} basePath={basePath} record={record}>
      <Tab label="resources.orders.tabs.comments">
        <ReferenceManyField
          label="resources.order_comments.name"
          reference="order_comments"
          target="order"
          perPage={10}
          css="margin-bottom: 2rem;"
        >
          <Datagrid>
            <DateField source="createdAt" />
            <TextField source="content" />
            <TextField source="user.email" />
          </Datagrid>
        </ReferenceManyField>
        <OrderCommentInput />
      </Tab>
      <Tab label="resources.orders.tabs.events">
        <OrderEvents />
      </Tab>
      <Tab label="resources.orders.tabs.communication">
        <ReferenceManyField
          label="resources.reseller_email_events.name"
          reference="reseller_email_events"
          sort={{ field: 'createdAt', order: 'DESC' }}
          target="order"
        >
          <Datagrid>
            <DateField source="createdAt" />
            <TranslatedStatus source="type" />
            <TextField source="user.email" />
            <ResendEmailButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  ) : null
}

export default OrderAdditionalInfo
