import { ListSubheader } from '@material-ui/core'
import GenericRestClientButton from 'Components/Common/ActionButtons/GenericRestClientButton'
import EditToolbar from 'Components/Common/EditToolbar'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import { get, round } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import {
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  translate,
} from 'react-admin'

const RefreshAcmpTradingModelMarginsButton = ({ data }) => (
  <GenericRestClientButton
    type="refreshAcmpTradingModelMargins"
    action="refreshAcmpTradingModelMargins"
    resource="also_acmp_settings"
    payload={{ id: data ? data.id : null }}
  />
)

RefreshAcmpTradingModelMarginsButton.propTypes = {
  data: PropTypes.object.isRequired,
}

const EditActions = ({ data }) => (
  <RightAlignedCardActions>
    <RefreshAcmpTradingModelMarginsButton data={data} />
  </RightAlignedCardActions>
)

EditActions.propTypes = {
  data: PropTypes.object.isRequired,
}

const AlsoACMPSettingsTitle = translate(({ translate: t }) =>
  t('resources.also_acmp_settings.name'),
)

const ACMPTradingMargins = ({ record }) => {
  const tradingModel = get(record, 'tradingModel')
  if (!tradingModel) {
    return null
  }
  const tradingModelMargins = get(record, 'tradingModelMargins') || []
  if (tradingModelMargins.length === 0) {
    return null
  }

  return (
    <div>
      <ListSubheader>Margen</ListSubheader>
      {tradingModelMargins
        .sort((a, b) => a.period - b.period)
        .map(({ period, tradingModelMargin }) => (
          <div>
            Laufzeit {period} Monate: {round((tradingModelMargin - 1) * 100)}%
          </div>
        ))}
    </div>
  )
}

ACMPTradingMargins.propTypes = {
  record: PropTypes.object.isRequired,
}

// eslint-disable-next-line
export const AlsoACMPSettingsEdit = props => (
  <Edit title={<AlsoACMPSettingsTitle />} actions={<EditActions />} {...props}>
    <SimpleForm toolbar={<EditToolbar />} redirect={false}>
      <TextInput source="username" />
      <TextInput type="password" source="password" />
      <TextInput source="parentAccountId" />
      <TextInput source="marketplace" />
      <BooleanInput source="tradingModel" />
      <ACMPTradingMargins />
    </SimpleForm>
  </Edit>
)
