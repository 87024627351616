import React, { useEffect } from 'react'
import Button from 'Components/Common/Buttons/Button'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent as MUIDialogContent,
  Slide,
  Typography,
} from '@material-ui/core'
import { Field, Form } from 'react-final-form'
import {
  BooleanInput,
  required,
  TextInput,
  useLoading,
  useNotify,
  useQuery,
  useTranslate,
} from 'react-admin'
import { useApiClient } from 'Rest/fetchHydra'
import { useHistory, useParams } from 'react-router'
import AlsoAutoSuggestsProductAutoSuggest from 'Components/Also/AutoSuggests/ProductAutoSuggest'
import { useToggle } from 'react-use'

const Transition = props => <Slide direction="up" {...props} />

const AlsoActionDialogsCreateProductVariantDialog = () => {
  const loading = useLoading()

  const history = useHistory()

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      aria-labelledby="simple-dialog-title"
      open
      onClose={() => {
        if (!loading) {
          history.push('/also_enum_members')
        }
      }}
      TransitionComponent={Transition}
    >
      <DialogContent />
    </Dialog>
  )
}

const DialogContent = () => {
  const { id } = useParams()

  const apiClient = useApiClient()
  const history = useHistory()
  const [loading, setLoading] = useToggle(false)
  const t = useTranslate()

  const showNotification = useNotify()

  const { data: enumMember, loading: queryLoading } = useQuery({
    type: 'getOne',
    resource: 'also_enum_members',
    payload: { id },
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  const handleSubmit = async values => {
    try {
      const res = await apiClient({
        endpoint: '/also/create_product_variant',
        method: 'POST',
        body: values,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })

      history.push(`/also_product_variants/${res.data.id}`)
      showNotification('notifications.alsoCreateProductVariant.success')
    } catch (e) {
      console.log(e)
      showNotification('notifications.alsoCreateProductVariant.fail', 'warning')
    }
  }

  const closeDialog = () => {
    history.push('/also_enum_members')
  }

  if (loading) {
    return (
      <MUIDialogContent>
        <Box display="flex" justifyContent="center" my={8}>
          <CircularProgress />
        </Box>
      </MUIDialogContent>
    )
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        enumMember: id,
        createNew: false,
        newProductName: enumMember?.displayName,
        variantName: enumMember?.displayName,
      }}
      validate={values => {
        const errors = {}
        if (values.createNew) {
          errors.newProductName = values.newProductName ? undefined : 'required'
          errors.alsoProduct = undefined
        } else {
          errors.alsoProduct = values.alsoProduct ? undefined : 'required'
          errors.newProductName = undefined
        }
        return errors
      }}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <MUIDialogContent>
            <Typography variant="h5" gutterBottom>
              {t('forms.alsoCreateProductVariant.title')}
            </Typography>
            <TextInput
              label="forms.alsoCreateProductVariant.fields.variantName"
              name="variantName"
              validate={required()}
              fullWidth
            />
            <TextInput
              label="forms.alsoCreateProductVariant.fields.sku"
              name="sku"
              validate={required()}
              fullWidth
            />
            <Field name="createNew" subscription={{ value: true }}>
              {({ input: { value: createNew } }) => (
                <Field
                  name="alsoProduct"
                  render={({ input, meta }) => (
                    <AlsoAutoSuggestsProductAutoSuggest
                      {...input}
                      meta={meta}
                      type={
                        enumMember?.field === 'WorkplaceUserDevice'
                          ? 'WorkplaceDevice'
                          : 'Accessory'
                      }
                      label="forms.alsoCreateProductVariant.fields.product"
                      inputProps={{
                        disabled: createNew,
                      }}
                    />
                  )}
                />
              )}
            </Field>

            <BooleanInput
              source="createNew"
              label="forms.alsoCreateProductVariant.fields.createNew"
            />

            <Field name="createNew" subscription={{ value: true }}>
              {({ input: { value: createNew } }) =>
                createNew && (
                  <TextInput
                    label="forms.alsoCreateProductVariant.fields.newProductName"
                    name="newProductName"
                    fullWidth
                    disabled={!createNew}
                  />
                )
              }
            </Field>
          </MUIDialogContent>
          <DialogActions>
            <Button
              label="Anlegen"
              type="submit"
              disabled={submitting}
              loading={submitting}
              color="primary"
              variant="contained"
            />
            <Button
              label="Schließen"
              type="button"
              disabled={submitting}
              color="secondary"
              variant="text"
              onClick={closeDialog}
            />
          </DialogActions>
        </form>
      )}
    </Form>
  )
}

export default AlsoActionDialogsCreateProductVariantDialog
