import React from 'react'
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  FormTab,
  List,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  BooleanInput,
} from 'react-admin'
import { CardActions } from '@material-ui/core'
import { get } from 'lodash'
import DateField from 'Components/Common/ViewFields/DateField'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'
import { isReseller } from 'Lib/helper'
import EditToolbar from 'Components/Common/EditToolbar'
import CredentialsInput from 'Components/Common/InputFields/CredentialsInput'
import { validateRequiredEmail } from './reseller_smtp_configurations'
import { validateRequired } from '../Aluxo/devices'

const ResellerFilter = props => (
  <Filter {...props}>
    <TextInput source="name" />
    <TextInput source="contactEmail" />
  </Filter>
)

const DefaultStoreViewField = props => {
  const domain = props.record?.defaultStoreView.primaryDomain
  return (
    <a href={`https://${domain}`} target="_blank" rel="noopener noreferrer">
      {domain}
    </a>
  )
}

export const ResellerList = props => (
  <List
    {...props}
    pagination={<CustomPagination />}
    filters={<ResellerFilter />}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="contactEmail" />
      <TextField source="type" />
      <DateField source="createdAt" format="DD.MM.YYYY" />
      <DefaultStoreViewField label="resources.resellers.fields.primaryDomain" />
      <EditButton />
    </Datagrid>
  </List>
)

const EditActions = () => <CardActions />

const ResellerTitle = ({ record }) => get(record, 'name')

export const ResellerEdit = ({ permissions, ...props }) => (
  <Edit title={<ResellerTitle />} actions={<EditActions />} {...props}>
    <TabbedForm toolbar={<EditToolbar />} redirect={false}>
      <FormTab label="resources.resellers.tabs.general">
        <TextInput source="name" validate={validateRequired} />
        <TextInput source="contactEmail" validate={validateRequiredEmail} />
        <TextField source="apiKey" />
      </FormTab>
      <FormTab label="resources.resellers.tabs.address">
        <TextInput source="address.company" validate={validateRequired} />
        <SelectInput
          source="address.salutation"
          choices={[
            { id: 'Frau', name: 'Frau' },
            { id: 'Herr', name: 'Herr' },
          ]}
          validate={validateRequired}
        />
        <TextInput source="address.firstName" validate={validateRequired} />
        <TextInput source="address.lastName" validate={validateRequired} />
        <TextInput source="address.street" validate={validateRequired} />
        <TextInput source="address.postcode" validate={validateRequired} />
        <TextInput source="address.city" validate={validateRequired} />
        <TextInput source="address.phone" validate={validateRequired} />
        <TextInput
          type="email"
          source="address.email"
          validate={validateRequired}
        />
      </FormTab>

      {isReseller(permissions) && (
        <FormTab label="resources.resellers.tabs.credentials">
          <CredentialsInput type="albis" prod />
          <CredentialsInput type="albis" />
          <BooleanInput
            source="albisProd"
            label="resources.resellers.fields.credentials.prodMode"
          />
        </FormTab>
      )}
    </TabbedForm>
  </Edit>
)

export const ResellerCreate = props => (
  <Create {...props}>
    <TabbedForm defaultValue={{ domains: [] }}>
      <FormTab label="resources.resellers.tabs.general">
        <TextInput source="name" validate={validateRequired} />
        <TextInput source="platformName" validate={validateRequired} />
        <TextInput
          multiline
          source="domains"
          format={arr => arr.join('\n')}
          parse={value => value.split(/[\n,;]+/)}
          validate={validateRequired}
        />
        <TextInput source="contactEmail" validate={validateRequired} />
        <TextInput source="accountEmail" validate={validateRequired} />
        <SelectInput
          source="type"
          choices={[
            { id: 'ALBIS', name: 'Albis' },
            { id: 'ALSO', name: 'ALSO' },
          ]}
          validate={validateRequired}
        />
      </FormTab>
      <FormTab label="resources.resellers.tabs.address">
        <SelectInput
          source="address.salutation"
          choices={[
            { id: 'Frau', name: 'Frau' },
            { id: 'Herr', name: 'Herr' },
          ]}
          validate={validateRequired}
        />
        <TextInput source="address.firstName" validate={validateRequired} />
        <TextInput source="address.lastName" validate={validateRequired} />
        <TextInput source="address.street" validate={validateRequired} />
        <TextInput source="address.postcode" validate={validateRequired} />
        <TextInput source="address.city" validate={validateRequired} />
        <TextInput source="address.phone" validate={validateRequired} />
      </FormTab>
    </TabbedForm>
  </Create>
)
