import PropTypes from 'prop-types'
import React from 'react'
import { DeleteButton, EditButton, useTranslate } from 'react-admin'

const ConditionalActionButton = ({ record, basePath, label, action }) => {
  const translate = useTranslate()
  const buttonLabel = action ? `ra.action.${action}` : label

  const returnValue = []

  // Not Uploaded yet
  if (record.externalProtectionPlan.uploaded === false) {
    // Add Edit Button
    returnValue.push(
      <EditButton
        record={record.externalProtectionPlan}
        basePath="/external_protection_plans"
        label={buttonLabel}
        translate={translate}
        resource="externalProtectionPlans"
      />,
    )

    returnValue.push(
      <DeleteButton
        record={record}
        basePath={basePath}
        resource="external_protection_plan_devices"
        // label="aor.action.delete"
        translate={translate}
      />,
    )
  } else if (record.status === 'ADD' || record.status === 'CHANGE') {
    returnValue.push(
      <DeleteButton
        record={record}
        basePath={basePath}
        resource="external_protection_plan_devices"
        label="resources.external_protection_plans.cancel"
        translate={translate}
      />,
    )
  }

  return returnValue
}

ConditionalActionButton.propTypes = {
  label: PropTypes.string,
  basePath: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired, //eslint-disable-line
  translate: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
}

export default ConditionalActionButton
