import Portal from '@material-ui/core/Portal'
import PersonIcon from '@material-ui/icons/Person'
import React from 'react'
import {
  Layout as RALayout,
  MenuItemLink,
  Notification,
  UserMenu,
} from 'react-admin'
import { useResellerTheme } from 'ResellerContext'
import AppBar from './Appbar'
import Menu from './Menu'

const CustomUserMenu = props => (
  <UserMenu {...props}>
    <MenuItemLink
      to="/profile"
      primaryText="Profile"
      leftIcon={<PersonIcon />}
    />
  </UserMenu>
)

const CustomAppBar = props => (
  <AppBar {...props} userMenu={<CustomUserMenu />} />
)

const CustomNotification = props => (
  <Portal>
    <Notification {...props} />
  </Portal>
)

const Layout = props => {
  const theme = useResellerTheme()
  return (
    <RALayout
      {...props}
      menu={Menu}
      theme={theme}
      appBar={CustomAppBar}
      notification={CustomNotification}
    />
  )
}
export default Layout
