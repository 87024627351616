import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { Button as RAButton, useLoading } from 'react-admin'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'inline-block',
  },
  progress: {
    color: 'green',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: ' -12px',
    marginLeft: ' -12px',
  },
})

const Button = ({
  loading,
  spinOnGlobal,
  disabled,
  disableOnLoad,
  disableOnGlobalLoad,
  ...props
}) => {
  const classes = useStyles()
  const globalLoading = useLoading()

  const spin = loading || (spinOnGlobal && globalLoading)

  const shouldBeDisabled =
    disabled ||
    (disableOnLoad && loading) ||
    (disableOnGlobalLoad && globalLoading)

  return (
    <div className={classes.root}>
      <RAButton disabled={shouldBeDisabled} {...props} />
      {spin ? (
        <CircularProgress size={24} className={classes.progress} />
      ) : null}
    </div>
  )
}

Button.propTypes = {
  loading: PropTypes.bool,
  spinOnGlobal: PropTypes.bool,
  disabled: PropTypes.bool,
  disableOnLoad: PropTypes.bool,
  disableOnGlobalLoad: PropTypes.bool,
}

Button.defaultProps = {
  loading: false,
  spinOnGlobal: false,
  disabled: false,
  disableOnLoad: false,
  disableOnGlobalLoad: true,
}

export default Button
