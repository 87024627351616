export default {
  orders: {
    customer: {
      writeNested: true,
    },
    billingAddress: {
      type: 'address',
      writeNested: true,
    },
    shippingAddress: {
      type: 'address',
      writeNested: true,
    },
    contactAddress: {
      type: 'address',
      writeNested: true,
    },
    ceo: {
      writeNested: true,
    },
  },
  resellers: {
    address: {
      writeNested: true,
    },
  },
  customers: {
    addresses: {
      writeNested: true,
    },
    addressSets: {
      writeNested: true,
    },
    ceo: {
      writeNested: true,
    },
    bankDetails: {
      writeNested: true,
    },
    projects: {
      writeNested: true,
    },
    aluxoDiscounts: {
      writeNested: true,
    },
  },
  store_views: {
    seoConfiguration: {
      type: 'seo_configuration',
      writeNested: true,
    },
    smtpConfiguration: {
      type: 'smtp_configuration',
      writeNested: true,
    },
    notes: {
      writeNested: true,
    },
  },
  also_offers: {
    offerLines: {
      writeNested: true,
    },
  },
  also_product_variants: {
    cNetImages: {
      writeNested: true,
    },
  },
  also_resellable_products: {
    cNetImages: {
      writeNested: true,
    },
  },
  also_reseller_custom_services: {
    prices: {
      writeNested: true,
    },
  },
  devices: {
    variantNamingGroups: {
      writeNested: true,
    },
  },
  device_variants: {
    cNetImages: {
      writeNested: true,
    },
  },
  staff: {
    smtpConfiguration: {
      writeNested: true,
    },
  },
  also_workplace_order_lines: {
    workplaceConfigurationFields: {
      writeNested: true,
    },
  },
  external_protection_plans: {
    devices: {
      writeNested: true,
    },
  },
}
