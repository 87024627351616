import { makeStyles } from '@material-ui/core'
import React from 'react'
import { Login } from 'react-admin'
import { useParams } from 'react-router-dom'
import PasswordResetForm from './PasswordResetForm'

const useStyles = makeStyles({
  main: {
    backgroundPosition: 'center',
  },
})

const backgroundImage =
  process.env.REACT_APP_WORKPLACE_TYPE === 'also' ? 'also365' : 'aluxo365'

const PasswordReset = () => {
  const { token } = useParams()
  const classes = useStyles()
  return (
    <Login classes={classes} backgroundImage={`/${backgroundImage}.jpg`}>
      <PasswordResetForm token={token} />
    </Login>
  )
}

export default PasswordReset
