import React, { useContext, useMemo } from 'react'
import { AutocompleteArrayInput, useQuery, useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { ResellerContext } from 'ResellerContext'

const useStyles = makeStyles({
  suggestionsContainer: {
    zIndex: 2100,
  },
})

const DeviceVariantNamingSpecInput = props => {
  const { flags } = useContext(ResellerContext)

  const classes = useStyles()
  const { data = [] } = useQuery({
    type: 'getList',
    resource: 'common_product_attribute_groups',
    payload: {
      pagination: false,
      sort: false,
      filter: false,
    },
  })
  const t = useTranslate()

  const helperText = useMemo(
    () =>
      t('resources.devices.fields.variantNamingGroupsAvailable', {
        groups: data.map(group => group.name).join(', '),
      }),
    [data],
  )

  if (!flags.cNetSpecs) {
    return null
  }

  return (
    <AutocompleteArrayInput
      source="variantNamingGroups"
      helperText={helperText}
      parse={values =>
        values.map((iri, index) => ({
          group: iri,
          position: index,
        }))
      }
      format={values => values.map(({ group }) => group)}
      choices={data.map(group => ({
        id: group['@id'],
        name: group.name,
      }))}
      {...props}
      options={{
        suggestionsContainerProps: {
          className: classes.suggestionsContainer,
        },
      }}
    />
  )
}

export default DeviceVariantNamingSpecInput
