import DateInput from 'Components/Common/InputFields/DateInput'
import PureDateField from 'Components/Common/ViewFields/DateField'
import { isAfter, isBefore, isSameDay } from 'date-fns'
import { get } from 'lodash'
import RichTextInput from 'ra-input-rich-text'
import React from 'react'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  List,
  ListButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'
import FindITSCopeBundleButton from 'Components/Aluxo/ActionButtons/FindITScopeBundleButton'
import EditToolbar from 'Components/Common/EditToolbar'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import JoinedArrayInputField from 'Components/Common/InputFields/JoinedArrayInputField'
import KeyValueCurrencyField from 'Components/Common/InputFields/KeyValueCurrencyField'
import KeyValueJoinedArrayInputField from 'Components/Common/InputFields/KeyValueJoinedArrayInputField'
import DeviceVariantListInput from 'Components/Common/InputFields/DeviceVariantListInput'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import NumberWithUnitInput from 'Components/Common/InputFields/NumberWithUnitInput'
import JoinedArrayField from 'Components/Common/ViewFields/JoinedArrayField'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'
import ProductVariantListInput from 'Components/Common/InputFields/ProductVariantListInput'
import { validateRequired } from './devices'

const typeChoices = [
  { id: 'internal', name: 'Intern' },
  { id: 'external', name: 'Extern' },
]

const optionRenderer = choice => `orders.markings.${choice.id}`

const PromoBundleFilter = props => (
  <Filter {...props}>
    <TextInput source="name" />
    <SelectInput
      source="type"
      choices={['internal', 'external']}
      optionText={optionRenderer}
    />
    <BooleanInput
      options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
      source="active"
    />
  </Filter>
)

export const PromoBundleList = props => (
  <List
    {...props}
    filters={<PromoBundleFilter />}
    pagination={<CustomPagination />}
    aside={<LanguageSwitcherAside />}
  >
    <Datagrid>
      <TextField source="name" />
      <JoinedArrayField source="skus" />
      <TextField source="type" />
      <PureDateField source="validFrom" format="DD.MM.YYYY" />
      <PureDateField source="validTo" format="DD.MM.YYYY" />
      <BooleanField source="active" />
      <EditButton />
    </Datagrid>
  </List>
)

const EditActions = ({ basePath, data, resource, hasShow, hasList }) => (
  <RightAlignedCardActions>
    <ListButton basePath={basePath} />
    {data && data.type === 'external' && (
      <FindITSCopeBundleButton id={data && data.id} />
    )}
  </RightAlignedCardActions>
)

const Title = ({ record }) => get(record, 'name')

export const PromoBundleEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<Title />}
    aside={<LanguageSwitcherAside />}
    {...props}
    transform={values => {
      console.log(values)
      return {
        ...values,
        allowedProducts_reference_ids: values.allowedProducts.map(p => p.id),
        requiredDevices_reference_ids: values.requiredDevices.map(p => p.id),
      }
    }}
  >
    <SimpleForm toolbar={<EditToolbar hasDelete />} redirect={false}>
      <TextInput source="name" validate={validateRequired} />
      <JoinedArrayInputField
        source="skus"
        label="resources.promo_bundles.fields.skus"
      />

      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.type === 'external' && (
            <KeyValueJoinedArrayInputField
              source="puids"
              label="resources.promo_bundles.fields.puids"
              {...rest}
            />
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.type === 'external' && (
            <KeyValueCurrencyField
              label="resources.promo_bundles.fields.prices"
              source="prices"
              {...rest}
            />
          )
        }
      </FormDataConsumer>

      <NumberWithUnitInput source="margin" unit="%" />

      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.type === 'external' && (
            <KeyValueCurrencyField
              label="resources.promo_bundles.fields.netPrices"
              source="netPrices"
              {...rest}
            />
          )
        }
      </FormDataConsumer>

      <RichTextInput source="shortDescription" validate={validateRequired} />
      <SelectInput source="type" choices={typeChoices} />
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="active"
        validate={validateRequired}
      />

      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <DateInput
            source="validFrom"
            options={{
              shouldDisableDate: date => isAfter(date, formData.validTo),
            }}
            {...rest}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <DateInput
            source="validTo"
            options={{
              shouldDisableDate: date =>
                !isSameDay(date, formData.validFrom) &&
                isBefore(date, formData.validFrom),
            }}
            {...rest}
          />
        )}
      </FormDataConsumer>

      <DeviceVariantListInput
        source="requiredDevices"
        resource="promo_bundles"
        referenceResource="device_variants"
        categoryFilter
        fullWidth
      />

      <ProductVariantListInput
        source="allowedProducts"
        resource="promo_bundles"
        referenceResource="product_variants"
        fullWidth
      />
    </SimpleForm>
  </Edit>
)

export const PromoBundleCreate = props => (
  <Create {...props}>
    <SimpleForm
      defaultValue={{
        active: true,
        allowedProducts: [],
        requiredDevices: [],
      }}
    >
      <TextInput source="name" validate={validateRequired} />
      <SelectInput source="type" choices={typeChoices} />
    </SimpleForm>
  </Create>
)
