import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import numbro from 'numbro'
import de from 'numbro/languages/de-DE'
import PropTypes from 'prop-types'
import React, { useMemo, useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useField } from 'react-final-form'

const useStyles = makeStyles(theme => ({
  textField: {
    // width: '256px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

numbro.registerLanguage(de)
numbro.setLanguage('de-DE')

const transformValue = value =>
  numbro(value).format({
    thousandSeparated: true,
    mantissa: 2,
  })

const NumberWithUnitTableInput = ({ label, unit, name, validate }) => {
  const {
    input: { value, onChange },
    meta,
  } = useField(name, {
    validate,
    formatOnBlur: v => (v ? transformValue(v) : 0),
  })

  const [editing, setEditing] = useState(false)
  const textFieldRef = useRef(null)

  const handleBlur = () => {
    const unformattedValue = numbro(value).value() || 0
    setEditing(false)
    onChange(unformattedValue)
  }

  const handleFocus = () => {
    setEditing(true)
    // setValue(transformValue(value))
    if (textFieldRef.current) {
      textFieldRef.current.select()
    }
  }

  const handleKeyPress = e => {
    if (/,/.test(e.key) && /,/.test(value)) {
      e.preventDefault()
    }
    if (!/[\d,]/.test(e.key)) {
      e.preventDefault()
    }
  }

  const classes = useStyles()
  const displayValue = useMemo(() => {
    const v = value || 0
    return editing ? v : `${v} ${unit}`
  }, [editing, value, unit])
  const t = useTranslate()

  return (
    <TextField
      inputRef={textFieldRef}
      type="tel"
      label={label && t(label)}
      value={displayValue}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={onChange}
      onKeyPress={handleKeyPress}
      className={classes.textField}
      error={meta.error}
    />
  )
}

NumberWithUnitTableInput.propTypes = {
  unit: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  validate: PropTypes.func,
}

NumberWithUnitTableInput.defaultProps = {
  unit: '€',
  label: '',
  name: '',
  validate: () => undefined,
}

export default NumberWithUnitTableInput
