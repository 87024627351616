import React from 'react'
import PropTypes from 'prop-types'
import {
  CreateButton,
  List,
  RefreshButton,
  Datagrid,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  TextField,
  EditButton,
  DeleteButton,
  DateField,
  Filter,
  TextInput,
  BooleanInput,
} from 'react-admin'
import BaseExternalProtectionPlanCreate from 'Components/Aluxo/ExternalProtectionPlans/ExternalProtectionPlanCreate'
import BaseExternalProtectionPlanEdit from 'Components/Aluxo/ExternalProtectionPlans/ExternalProtectionPlanEdit'
import { isReseller, isAdmin } from 'Lib/helper'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import ChipArrayField from 'Components/Common/ViewFields/ChipArrayField'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

const ExternalProtectionPlanFilter = props => (
  <Filter {...props}>
    <TextInput
      source="customer"
      label="resources.external_protection_plans.fields.customer"
    />
    <TextInput
      source="devices.serial"
      label="resources.external_protection_plans.fields.devices.serialNumber"
    />
    <BooleanInput
      options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
      source="theft"
      label="resources.external_protection_plans.fields.devices.theftProtection"
    />
  </Filter>
)

const ExternalProtectionPlanActions = ({
  permissions,
  basePath,
  resource,
  filters,
  displayedFilters,
  filterValues,
  showFilter,
}) => (
  <RightAlignedCardActions>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    {isReseller(permissions) && <CreateButton basePath={basePath} />}
    <RefreshButton />
  </RightAlignedCardActions>
)

ExternalProtectionPlanActions.propTypes = {
  permissions: PropTypes.array.isRequired,
  resource: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  displayedFilters: PropTypes.object.isRequired,
  filterValues: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  showFilter: PropTypes.func.isRequired,
}

const ExternalProtectionPlanDatagrid = ({ permissions }) => {
  if (isAdmin(permissions)) {
    return (
      <Datagrid>
        <ReferenceManyField
          reference="external_protection_plan_devices"
          label="resources.external_protection_plans.fields.devices.orderNumbers"
          target="externalProtectionPlan"
        >
          <SingleFieldList>
            <ChipField source="orderNumber" />
          </SingleFieldList>
        </ReferenceManyField>

        <TextField
          source="reseller.name"
          label="resources.external_protection_plans.fields.reseller"
        />

        <TextField source="customer" />
        <DateField
          source="date"
          label="resources.external_protection_plans.fields.deliveryDate"
        />

        <ChipArrayField
          source="devices"
          accessor="serial"
          elStyle={{ marginBottom: 6 }}
          label="resources.external_protection_plans.fields.devices.serialNumbers"
          truncate={10}
        />

        <ChipArrayField
          source="devices"
          accessor="deviceName"
          elStyle={{ marginBottom: 6 }}
          label="resources.external_protection_plans.fields.devices.serialNumbers"
          truncate={10}
        />
      </Datagrid>
    )
  } else if (isReseller(permissions)) {
    return (
      <Datagrid>
        <TextField source="customer" />
        <DateField
          source="date"
          label="resources.external_protection_plans.fields.deliveryDate"
        />
        <DateField source="insuranceDate" />

        <ChipArrayField
          source="devices"
          accessor="serial"
          elStyle={{ marginBottom: 6 }}
          label="resources.external_protection_plans.fields.devices.serialNumbers"
          truncate={10}
        />

        <ChipArrayField
          source="devices"
          accessor="deviceName"
          elStyle={{ marginBottom: 6 }}
          label="resources.external_protection_plans.fields.devices.serialNumbers"
          truncate={10}
        />

        <EditButton />
        <DeleteButton />
      </Datagrid>
    )
  }

  return null
}

export const ExternalProtectionPlanList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={<ExternalProtectionPlanFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    pagination={<CustomPagination />}
    actions={<ExternalProtectionPlanActions permissions={permissions} />}
  >
    <ExternalProtectionPlanDatagrid permissions={permissions} />
  </List>
)

ExternalProtectionPlanList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export const ExternalProtectionPlanCreate = BaseExternalProtectionPlanCreate
export const ExternalProtectionPlanEdit = BaseExternalProtectionPlanEdit
