import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ITScopeOrderTableEntry from './ITScopeOrderTableEntry'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    // minWidth: 700,
  },
}))

const ITScopeOrderTable = ({ data, onChange, onRemove, onSetSupplier }) => {
  const classes = useStyles()
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>PUID</TableCell>
          <TableCell>SKU</TableCell>
          <TableCell>Artikel</TableCell>
          <TableCell numeric>Anzahl</TableCell>
          <TableCell numeric>Preis</TableCell>
          <TableCell numeric>bereits abgezogener Rabatt</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(data).map(([id, n]) => (
          <ITScopeOrderTableEntry
            id={id}
            entry={n}
            onChange={values => onChange({ lineId: id, values })}
            onRemove={() => onRemove({ lineId: id })}
            onSetSupplier={alternative =>
              onSetSupplier({ alternative, lineId: id })
            }
          />
        ))}
      </TableBody>
    </Table>
  )
}

//  Because we can't easily validate the data object with dynamic keys, we can validate it in the ITScopeOrderTableEntry
ITScopeOrderTable.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSetSupplier: PropTypes.func.isRequired,
}

export default ITScopeOrderTable
