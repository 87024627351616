import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { Save } from '@material-ui/icons'
import { useTranslate } from 'react-admin'

const DisabledSaveButton = ({ label }) => {
  const translate = useTranslate()

  const handleClick = event => {
    event.preventDefault()
  }

  return (
    <Button color="primary" onClick={handleClick} disabled>
      <Save />
      {label && translate(label)}
    </Button>
  )
}

DisabledSaveButton.propTypes = {
  label: PropTypes.string,
}

DisabledSaveButton.defaultProps = {
  label: 'ra.action.save',
}

export default DisabledSaveButton
