import { useTranslate } from 'react-admin'

const passwordRegex = /(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$)|(\w{3,}( |$)){3,}/

const useTranslatedPasswordValidation = () => {
  const translate = useTranslate()
  return value =>
    passwordRegex.test(String(value))
      ? undefined
      : translate('forms.validation.insecurePassword')
}

export default useTranslatedPasswordValidation
