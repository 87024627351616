import { useOrder } from 'OrderContext'
import PropTypes from 'prop-types'
import React from 'react'
import {
  useLoading,
  useNotify,
  useRefresh,
  useUpdateLoading,
} from 'react-admin'
import { Form } from 'react-final-form'
import AddPromoBundleDialogForm from 'Components/Aluxo/OrderLines/Lines/PromoBundleLine/AddPromoBundleDialogForm'
import { useApiClient } from 'Rest/fetchHydra'

export default function AddDialogContent({ handleClose, initialValues }) {
  const showNotification = useNotify()
  const refreshView = useRefresh()
  const { startLoading, stopLoading } = useUpdateLoading()
  const loading = useLoading()

  const { record } = useOrder()

  const apiClient = useApiClient()

  const handleSave = async values => {
    startLoading()

    try {
      await apiClient({
        endpoint: '/aluxo/add_promo_bundle_order_line',
        method: 'POST',
        body: values,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      showNotification(`notifications.add_promo_bundle_order_lines.success`)
      handleClose()
      refreshView()
    } catch (e) {
      showNotification(
        `notifications.add_promo_bundle_order_lines.fail`,
        'warning',
      )
    }
    stopLoading()
  }

  return (
    <Form
      initialValues={{
        order: record.originId,
        ...initialValues,
      }}
      onSubmit={handleSave}
      component={AddPromoBundleDialogForm}
      reference="promo_bundle_order_lines"
      handleClose={handleClose}
      record={record}
      loading={loading}
    />
  )
}

AddDialogContent.propTypes = {
  handleClose: PropTypes.func,
  initialValues: PropTypes.object,
}

AddDialogContent.defaultProps = {
  initialValues: {},
  handleClose: () => {},
}
