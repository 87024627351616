import { List } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { translate } from 'react-admin'
import FlagListItem from './FlagListItem'

const Flags = ({ orderType, record, flagTypes }) => (
  <List>
    {Object.values(flagTypes)
      .filter(f => !f.dependsOn || f.dependsOn(record))
      .map(({ key }) => (
        <FlagListItem
          orderType={orderType}
          key={key}
          type={key}
          record={record}
        />
      ))}
  </List>
)

Flags.propTypes = {
  record: PropTypes.object, // eslint-disable-line
  flagTypes: PropTypes.object.isRequired, // eslint-disable-line
  orderType: PropTypes.string.isRequired,
}

export default translate(Flags)
