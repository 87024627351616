import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import { TextInput } from 'react-admin'
import { useField } from 'react-final-form'
import slugify from 'slugify'
import { useApiClient } from 'Rest/fetchHydra'
import PropTypes from 'prop-types'
import { useToggle } from 'react-use'

const SlugInput = ({ dependOn, ...props }) => {
  const apiClient = useApiClient()

  const {
    input: { value: excludeId },
  } = useField('id')

  const dependendInput = useField(dependOn, {
    // subscription: { value: true, modified: true, active: true },
  })
  const {
    input: { value: dependendValue },
    meta: dependendMeta,
  } = dependendInput

  const [lastDependendValue, setLastDependendValue] = useState(dependendValue)
  const [knownSlugs, setKnownSlugs] = useState([])
  const [loading, setLoading] = useToggle(false)

  const {
    input: { value: storeView },
  } = useField('storeView_reference_id', { subscription: { value: true } })

  const {
    input: { onChange },
    meta,
  } = useField(props.source)

  useEffect(() => {
    const loadSlugs = async () => {
      setLoading(true)
      const res = await apiClient({
        endpoint: '/common/slugs',
        method: 'POST',
        body: { storeView, excludeId },
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })

      setKnownSlugs(res.data.map(({ slug }) => slug))
      setLoading(false)
    }
    loadSlugs()
  }, [storeView])

  const isSlugValid = useCallback(
    value => {
      const error = knownSlugs.some(slug => slug === value)
        ? 'invalid-slug'
        : undefined

      return error
    },
    [knownSlugs],
  )

  useEffect(() => {
    if (dependendMeta.active && dependendMeta.modified) {
      if (lastDependendValue === dependendValue) {
        return
      }
      const newSlug = slugify(dependendValue, {
        replacement: '-',
        lower: true,
      })
      let error = isSlugValid(newSlug)
      let nextSlug = newSlug
      let lastNumber = 0
      while (error !== undefined) {
        const regex = /.*(\d+)$/gm
        const match = regex.exec(nextSlug)
        if (match) {
          lastNumber = Number(match[1])
          nextSlug = `${nextSlug.substr(0, nextSlug.length - 1)}${lastNumber +
            1}`
        } else {
          nextSlug = `${nextSlug}-${lastNumber + 1}`
        }
        error = isSlugValid(nextSlug)
      }

      onChange(nextSlug)
      setLastDependendValue(dependendValue)
    }
  }, [dependendValue, dependendMeta])

  const validationFunction = useMemo(() => {
    if (!props.validate) {
      return [isSlugValid]
    }
    if (typeof props.validate[Symbol.iterator] === 'function') {
      return [...props.validate, isSlugValid]
    }
    return [props.validate, isSlugValid]
  }, [props.validate, isSlugValid])

  return (
    <Box display="flex" alignItems="center">
      <TextInput {...props} validate={validationFunction} />
      <Box ml={1}>
        {(meta.validating || loading) && <CircularProgress size={20} />}
      </Box>
    </Box>
  )
}

SlugInput.propTypes = {
  dependOn: PropTypes.string.isRequired,
}

export default SlugInput
