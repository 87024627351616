import React from 'react'
import { BooleanInput, translate } from 'react-admin'
import { ListSubheader } from '@material-ui/core'

const VisibleSectionsInput = translate(({ translate: t }) => (
  <div style={{ marginBottom: '1em' }}>
    <ListSubheader>
      {t('resources.store_views.fields.sectionsVisible.title')}
    </ListSubheader>
    <div>
      {[
        'slider',
        'featured',
        'benefits',
        'testimonials',
        'marketingLogos',
        'team',
        'stats',
        'seo',
        'contact',
      ].map(s => (
        <BooleanInput
          source={`sectionsVisible.${s}`}
          label={t(`resources.store_views.fields.sectionsVisible.${s}`)}
        />
      ))}
    </div>
  </div>
))

VisibleSectionsInput.defaultProps = {}
export default VisibleSectionsInput
