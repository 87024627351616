import TranslatedTitle from 'Components/Common/TranslatedTitle'
import { get } from 'lodash'
import { useOrder } from 'OrderContext'
import React from 'react'
import {
  BooleanInput,
  Button,
  TextInput,
  useMutation,
  useNotify,
} from 'react-admin'
import { Field, Form } from 'react-final-form'
import { validateZipCode } from 'Components/Aluxo/Customers/CustomerCreate'

const identityOrNull = value => (!value ? null : value)

const OrderReporting = () => {
  const showNotification = useNotify()
  const { record } = useOrder()

  const [mutate] = useMutation()

  const handleReportingSubmit = async values => {
    mutate(
      {
        type: 'update',
        resource: 'orders',
        payload: {
          id: record.id,
          data: {
            casReporting: values,
          },
        },
      },
      {
        onSuccess: () => {
          showNotification('notifications.reportingUpdated')
        },
        onFailure: () => {
          showNotification('notifications.reportingNotUpdated', 'warning')
        },
      },
    )
  }

  const initialValues = get(record, 'casReporting')

  return (
    <Form
      onSubmit={handleReportingSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit}>
          <TranslatedTitle translateKey="resources.orders.fields.reportingName" />
          <Field
            fullWidth
            component={BooleanInput}
            label="resources.order_cas_reporting.fields.enabled"
            name="enabled"
            type="checkbox"
          />
          <TextInput
            fullWidth
            label="resources.order_cas_reporting.fields.casNumber"
            source="casNumber"
            parse={identityOrNull}
          />
          <TextInput
            fullWidth
            label="resources.order_cas_reporting.fields.company"
            source="company"
            parse={identityOrNull}
            type="text"
          />
          <TextInput
            fullWidth
            label="resources.order_cas_reporting.fields.street"
            source="street"
            parse={identityOrNull}
            type="text"
          />
          <TextInput
            source="postcode"
            validate={validateZipCode}
            parse={identityOrNull}
            label="resources.order_cas_reporting.fields.postcode"
          />
          <TextInput
            fullWidth
            label="resources.order_cas_reporting.fields.city"
            source="city"
            parse={identityOrNull}
            type="text"
          />
          <Button
            disabled={invalid}
            color="primary"
            onClick={handleSubmit}
            label="ra.action.save"
          />
        </form>
      )}
    />
  )
}

export default OrderReporting
