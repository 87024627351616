import { useOrder } from 'OrderContext'
import React from 'react'
import {
  Button,
  useMutation,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components/macro'
import { MuiTextField } from './AttachmentInput'

const StyledForm = styled.form`
  display: flex;
  flexdirection: row;
  alignitems: flex-end;
`

const OrderCommentInput = () => {
  const [mutate, { loading }] = useMutation()

  const refreshView = useRefresh()
  const showNotification = useNotify()
  const t = useTranslate()
  const { record } = useOrder()

  const handleFormSubmit = ({ comment }) => {
    mutate(
      {
        type: 'create',
        resource: 'order_comments',
        payload: {
          data: {
            order: record['@id'],
            content: comment,
          },
        },
      },
      {
        onSuccess: () => {
          showNotification('notifications.commentCreated')
          refreshView()
        },
        onFailure: () => {
          showNotification('notifications.commentNotCreated', 'warning')
        },
      },
    )
  }

  return (
    <Form
      onSubmit={handleFormSubmit}
      render={({ handleSubmit, invalid }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Field
            fullWidth
            component={MuiTextField}
            label={t('resources.order_comments.labels.newComment')}
            name="comment"
            type="text"
          />
          <Button
            disabled={invalid}
            color="primary"
            onClick={handleSubmit}
            label={loading ? t('actions.submitting') : t('actions.submit')}
          />
        </StyledForm>
      )}
    />
  )
}

export default OrderCommentInput
