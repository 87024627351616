import {
  ADD_PROTECTION_PLAN,
  BULK_UPDATE_ITSCOPE_METADATA,
  CANCEL_EXTERNAL_PROTECTION_PLAN_DEVICE,
  COPY_ACCESSORY_TO_VARIANTS,
  CREATE_IT_SCOPE_CART,
  CREATE_IT_SCOPE_ORDERS,
  CREATE_MULTIPART,
  DOWNLOAD_ALL_ATTACHMENTS,
  DOWNLOAD_ATTACHMENT,
  DUPLICATE_AS_OFFER,
  FIND_ITSCOPE_BUNDLE,
  GET_CONTEXT,
  LOCK_ORDER,
  PROCESS_ORDER,
  REFRESH_ACMP_TRADING_MODEL_MARGINS,
  REFRESH_ITSCOPE_DISTRIBUTORS,
  REFRESH_ITSCOPE_PRICES,
  REPORTING_ORDER,
  RESEND_EMAIL,
  RESET_CUSTOMER_PASSWORD,
  SAVE_TRANSLATIONS,
  TEST_CREDENTIALS,
  TEST_SMTP,
  UNLOCK_ORDER,
  UPDATE_ALSO_API_DATA,
  UPDATE_ALSO_SERVICE_PORTFOLIO,
  UPDATE_ITSCOPE_METADATA,
  UPDATE_ITSCOPE_PRICE,
  UPDATE_ORDER_LINES_STOCK,
  ALSO_UPDATE_RESELLABLE_PRODUCT,
  ASSIGN_CUSTOMER_TO_ORDER,
  REFRESH_PRICES,
} from 'Rest/actions'
import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
} from 'react-admin'
import {
  ReactAdminDocument,
  reactAdminDocumentsCache,
} from 'Rest/convertHydraResponseToReactAdminResponse'

import PromiseFileReader from 'promise-file-reader'
import apiStructure from 'Rest/apiStructure'
import { get, isObject } from 'lodash'
import isPlainObject from 'lodash.isplainobject'
import produce from 'immer'

export const iriRegex = new RegExp(/\/api\/.*\/(.*)/)
export const uuidRegex = new RegExp(
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g,
)

const replaceReferenceIds = obj => {
  const newValue = {}
  Object.entries(obj).forEach(([valueKey, nestedValue]) => {
    const matches = /([^@]*)_reference_id?$/g.exec(valueKey)
    if (matches) {
      const [_, originalNestedKey] = matches

      if (nestedValue === null) {
        newValue[originalNestedKey] = null
      } else {
        newValue[originalNestedKey] = reactAdminDocumentsCache[nestedValue]
      }
    } else if (!newValue[valueKey]) {
      newValue[valueKey] = nestedValue
    }
  })

  return newValue
}

/**
 * @param {Object} resource
 * @param {Object} document
 *
 * @returns {Promise}
 */
export const convertReactAdminDataToHydraData = (resource, document = {}) => {
  let res

  const data = {}

  // First we go through the reference id fields

  const referenceFields = Object.keys(document).filter(s =>
    s.includes('reference_id'),
  )

  // Then through the other fields

  const rest = Object.keys(document).filter(s => !s.includes('reference_id'))

  referenceFields.forEach(key => {
    const value = document[key]

    // Either it's an array or a single node. We'll handle arrays first
    if (Array.isArray(value) && value.length) {
      // Array
      // We know at this point that it's a list of uuids
      // Find the original key and see if its configured as a nested write
      const matches = /([^@]*)_reference_ids?$/g.exec(key)
      if (matches) {
        const [_, originalKey] = matches

        const isNested = get(
          apiStructure,
          `${resource}.${originalKey}.writeNested`,
        )

        /**
         * When is set to write nested, we just keep it as is, otherwise we replace it with the iris
         * this might be relevant, when we assign many things without changing them (manufacturers for example)
         *
         * In this case it won't be set later, because we have already set the key
         */

        if (!isNested) {
          data[originalKey] = value.map(uuid => reactAdminDocumentsCache[uuid])
        }
      }
    } else {
      // Single Item
      // Also here we know that its a uuid, do almost the same as above
      const matches = /([^@]*)_reference_ids?$/g.exec(key)
      if (matches) {
        const [_, originalKey] = matches

        const isNested = get(
          apiStructure,
          `${resource}.${originalKey}.writeNested`,
        )

        /**
         * When is set to write nested, we just keep it as is, otherwise we replace it with the IRI
         *
         * In this case it won't be set later, because we have already set the key
         */

        if (!isNested) {
          if (value === null) {
            data[originalKey] = null
          } else {
            data[originalKey] = reactAdminDocumentsCache[value]
          }
        }
      }
    }
  })

  // For each other key, we can just go and set it to the data object if it hasn't been set before
  rest.forEach(key => {
    const value = document[key]

    if (!data[key]) {
      // If it's an object we might need to replace the references here too (see storeview -> seoconfiguration -> serviceExtensionOverviewPage)
      if (typeof value === 'object' && value && !(value instanceof Date)) {
        data[key] = replaceReferenceIds(value)
      } else {
        data[key] = value
      }
    }
  })

  switch (resource) {
    default:
      res = data
  }

  return Promise.resolve(res)
}

const readFileToDataURL = (data, key) =>
  data[key]
    ? PromiseFileReader.readAsDataURL(data[key].rawFile)
        .then(fileContent =>
          Promise.resolve({
            ...data,
            [key]: fileContent,
          }),
        )
        .catch(err => {
          Promise.reject(err)
        })
    : Promise.resolve(data)

/**
 * @param {Object} resource
 * @param {Object} data
 *
 * @returns {Promise}
 */
const transformReactAdminDataToRequestBody = (resource, data = {}) =>
  convertReactAdminDataToHydraData(resource, data)
    .then(data =>
      // Entites like team have a imageFile Property containing the image file
      Promise.resolve(readFileToDataURL(data, 'imageFile')),
    )
    .then(data => {
      switch (resource) {
        case 'reseller_corporate_identities':
          // TODO: make this a multipart form too, like in store views
          return Promise.resolve(readFileToDataURL(data, 'companyLogoFile'))
        case 'resellable_protection_plans':
          // TODO: make this a multipart form too, like in store views
          return Promise.resolve(readFileToDataURL(data, 'file'))
        case 'external_protection_plans': {
          // external protection plans need to spread out the serial numbers over multiple devices
          const devices = []
          Object.values(data.devices).forEach(device => {
            if (device.serials) {
              device.serials
                .filter(s => s !== '')
                .map(s => s.trim())
                .forEach(serial => {
                  devices.push({
                    ...device,
                    serial,
                    theft: data.theft,
                    duration: data.duration,
                  })
                })
            } else {
              devices.push(device)
            }
          })
          return Promise.resolve({ ...data, devices })
        }
        case 'customers':
          // customers might have shipToBilling
          return Promise.resolve(
            produce(data, draft => {
              if (draft.shipToBilling) {
                draft.addresses[1] = { ...draft.addresses[0] }
                draft.addresses[1].type = 'shipping'
              }
            }),
          )

        case 'also_product_variants':
          return Promise.resolve(
            produce(data, draft => {
              if (isObject(draft.enumMember)) {
                draft.enumMember = draft.enumMember['@id']
              }
            }),
          )

        default:
          return Promise.resolve(data)
      }
    })

/**
 * @param {Object} resource
 * @param {Object} data
 *
 * @returns {Promise}
 */
const transformReactAdminDataToMultipartRequestBody = (resource, data = {}) => {
  const formData = new FormData()

  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value)
  })

  return Promise.resolve(formData)
}

/**
 * @param {string} type
 * @param {string} originalResource
 * @param {Object} params
 *
 * @returns {Object}
 */
const convertReactAdminRequestToHydraRequest = (
  type,
  originalResource,
  params,
  entrypoint,
) => {
  let resource = originalResource

  if (originalResource === 'offers') {
    resource = 'orders'
    if (type === CREATE) {
      resource = 'create_offer'
    }
  }

  if (originalResource === 'managed_services') {
    resource = 'online_services'
  }
  if (originalResource === 'reseller_website_configurations') {
    resource = 'store_views'
  }

  if (originalResource === 'also_customers') {
    resource = 'customers'
  }

  const entrypointUrl = new URL(entrypoint)

  switch (type) {
    case GET_CONTEXT:
      return Promise.resolve({
        options: {},
        url: new URL(params.context, entrypointUrl),
      })
    case REFRESH_ITSCOPE_PRICES:
      return Promise.resolve({
        options: {
          method: 'POST',
          body: {},
        },
        url: new URL(`${entrypoint}/bulk_update_itscope_status`, entrypointUrl),
      })
    case BULK_UPDATE_ITSCOPE_METADATA:
      return Promise.resolve({
        options: {
          method: 'POST',
          body: {},
        },
        url: new URL(`${entrypoint}/update_itscope_metadata`, entrypointUrl),
      })
    case UPDATE_ALSO_API_DATA:
      return Promise.resolve({
        options: {
          method: 'POST',
          body: {},
        },
        url: new URL(`${entrypoint}/update_also_api_data`, entrypointUrl),
      })
    case UPDATE_ALSO_SERVICE_PORTFOLIO:
      return Promise.resolve({
        options: {
          method: 'POST',
          body: {},
        },
        url: new URL(
          `${entrypoint}/also_update_service_portfolios`,
          entrypointUrl,
        ),
      })
    case SAVE_TRANSLATIONS:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            body,
            method: 'POST',
          },
          url: new URL(`${entrypoint}/update_translations`, entrypointUrl),
        }),
      )

    case ASSIGN_CUSTOMER_TO_ORDER:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            method: 'POST',
            body,
            type: 'json',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          },
          url: new URL(`${entrypoint}/orders_assign_customer`, entrypointUrl),
        }),
      )

    case REFRESH_ITSCOPE_DISTRIBUTORS:
      return Promise.resolve({
        options: {
          method: 'POST',
          body: {},
        },
        url: new URL(
          `${entrypoint}/refresh_itscope_distributors`,
          entrypointUrl,
        ),
      })
    case TEST_SMTP:
      let url = new URL(
        `${entrypoint}/smtp_configurations/test${
          params.apiKey ? `/${params.apiKey}` : ''
        }`,
        entrypointUrl,
      )
      if (params.personal) {
        url = new URL(
          `${entrypoint}/smtp_configurations/test?personal=true`,
          entrypointUrl,
        )
      }
      return Promise.resolve({
        options: {
          method: 'PUT',
          body: {},
        },
        url,
      })
    case ALSO_UPDATE_RESELLABLE_PRODUCT:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            method: 'POST',
            body,
          },
          url: `${entrypoint}/also_update_resellable_product`,
        }),
      )
    default:
      break
  }

  if (!resource || !params) {
    throw new Error(`Fetch action type ${type} needs resource and params`)
  }

  const collectionUrl = new URL(`${entrypoint}/${resource}`, entrypointUrl)

  const id = params.id instanceof ReactAdminDocument ? params.id.id : params.id

  const itemUrl = new URL(`${entrypoint}/${resource}/${id}`, entrypointUrl)

  switch (type) {
    case CREATE:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            body,
            method: 'POST',
          },
          url: collectionUrl,
        }),
      )
    case CREATE_MULTIPART:
      return transformReactAdminDataToMultipartRequestBody(
        resource,
        params.data,
      ).then(body => ({
        options: {
          body,
          method: 'POST',
          type: 'multipart',
        },
        url: collectionUrl,
      }))

    case DELETE:
      return Promise.resolve({
        options: {
          method: 'DELETE',
        },
        url: itemUrl,
      })

    case GET_LIST:
    case GET_MANY_REFERENCE: {
      const {
        pagination,
        sort: { field, order },
      } = params

      if (order) collectionUrl.searchParams.set(`_order[${field}]`, order)
      if (!pagination) {
        collectionUrl.searchParams.set('pagination', false)
      } else {
        const { page, perPage } = pagination
        if (page) collectionUrl.searchParams.set('page', page)
        if (perPage) collectionUrl.searchParams.set('itemsPerPage', perPage)
      }

      if (params.filter) {
        const buildFilterParams = (key, nestedFilter, rootKey) => {
          const filterValue = nestedFilter[key]

          if (Array.isArray(filterValue)) {
            filterValue.forEach((arrayFilterValue, index) => {
              collectionUrl.searchParams.set(
                `${rootKey}[${index}]`,
                arrayFilterValue,
              )
            })
            return
          }

          if (!isPlainObject(filterValue)) {
            collectionUrl.searchParams.set(rootKey, filterValue)
            return
          }

          Object.keys(filterValue).forEach(subKey => {
            buildFilterParams(subKey, filterValue, `${rootKey}.${subKey}`)
          })
        }

        Object.keys(params.filter).forEach(key => {
          buildFilterParams(key, params.filter, key)
        })
      }

      if (type === GET_MANY_REFERENCE && params.target) {
        collectionUrl.searchParams.set(params.target, params.id)
      }

      return Promise.resolve({
        options: {},
        url: collectionUrl,
      })
    }

    case GET_ONE:
      return Promise.resolve({
        options: {},
        url: itemUrl,
      })

    case UPDATE:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            body,
            method: 'PUT',
          },
          url: itemUrl,
        }),
      )
    case PROCESS_ORDER:
      return Promise.resolve({
        options: {
          method: 'POST',
          body: {
            order: params.id,
            action: params.data.action,
            context: params.data.context,
          },
        },
        url: new URL(`${entrypoint}/apply_order_transition`, entrypointUrl),
      })
    case DUPLICATE_AS_OFFER:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            method: 'POST',
            body,
          },
          url: `${itemUrl}/duplicate_as_offer`,
        }),
      )
    case LOCK_ORDER:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            method: 'PUT',
            body,
          },
          url: `${itemUrl}/lock`,
        }),
      )
    case UNLOCK_ORDER:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            method: 'PUT',
            body,
          },
          url: `${itemUrl}/unlock`,
        }),
      )
    case ADD_PROTECTION_PLAN:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            method: 'PUT',
            body,
          },
          url: `${itemUrl}/set_protection_plan`,
        }),
      )

    case REFRESH_ACMP_TRADING_MODEL_MARGINS:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            method: 'PUT',
            body,
          },
          url: `${itemUrl}/refresh_margins`,
        }),
      )

    case UPDATE_ORDER_LINES_STOCK:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            method: 'PUT',
            body,
          },
          url: `${itemUrl}/check_stock`,
        }),
      )

    case FIND_ITSCOPE_BUNDLE:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            method: 'POST',
            body,
          },
          url: `${itemUrl}/find_itscope`,
        }),
      )
    case CREATE_IT_SCOPE_CART:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            method: 'PUT',
            body,
          },
          url: `${itemUrl}/create_cart`,
        }),
      )
    case CANCEL_EXTERNAL_PROTECTION_PLAN_DEVICE:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            method: 'PUT',
            body,
          },
          url: `${itemUrl}/cancel`,
        }),
      )
    case UPDATE_ITSCOPE_METADATA:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            method: 'PUT',
            body,
          },
          url: `${itemUrl}/update_itscope`,
        }),
      )
    case UPDATE_ITSCOPE_PRICE:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            method: 'PUT',
            body,
          },
          url: `${itemUrl}/update_itscope_status`,
        }),
      )
    case COPY_ACCESSORY_TO_VARIANTS:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            method: 'PUT',
            body,
          },
          url: `${itemUrl}/copy_accessory_to_variants`,
        }),
      )
    case DOWNLOAD_ATTACHMENT:
      return Promise.resolve({
        options: {
          type: 'binary',
        },
        url: `${itemUrl}/download`,
      })

    case DOWNLOAD_ALL_ATTACHMENTS:
      return Promise.resolve({
        options: {
          type: 'blob',
        },
        url: `${itemUrl}/download_attachments`,
      })

    case RESEND_EMAIL:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            method: 'PUT',
            body,
          },
          url: `${itemUrl}/resend`,
        }),
      )
    case REFRESH_PRICES:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            method: 'PUT',
            body,
          },
          url: `${itemUrl}/refresh_prices`,
        }),
      )

    case TEST_CREDENTIALS:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            method: 'POST',
            body,
          },
          url: `${itemUrl}/test`,
        }),
      )
    case REPORTING_ORDER:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            body,
            method: 'PUT',
          },
          url: `${itemUrl}/reporting`,
        }),
      )
    case CREATE_IT_SCOPE_ORDERS:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            body,
            method: 'POST',
          },
          url: `${itemUrl}/send_to_itscope`,
        }),
      )
    case RESET_CUSTOMER_PASSWORD:
      return transformReactAdminDataToRequestBody(resource, params.data).then(
        body => ({
          options: {
            body,
            method: 'PUT',
          },
          url: `${itemUrl}/reset-password`,
        }),
      )
    default:
      throw new Error(`Unsupported fetch action type ${type}`)
  }
}

export default convertReactAdminRequestToHydraRequest
