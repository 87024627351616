import { useTheme } from '@material-ui/core/styles'
import get from 'lodash/get'
import { useOrder } from 'OrderContext'
import PropTypes from 'prop-types'
import React from 'react'
import StatusBars from './StatusBars'

const StatusBarsContainer = ({ markings, orderType }) => {
  const { record } = useOrder()
  const { marking } = record
  const currentStatusBarIndex = get(markings, `${marking}.statusBarIndex`)
  const {
    palette: {
      primary: { main },
    },
  } = useTheme()

  if (!record) {
    return null
  }

  return (
    <StatusBars
      record={record}
      primaryColor={main}
      currentStatusBarIndex={currentStatusBarIndex}
      markings={markings}
      orderType={orderType}
    />
  )
}

StatusBarsContainer.propTypes = {
  markings: PropTypes.object.isRequired,
  orderType: PropTypes.string.isRequired,
}

export default StatusBarsContainer
