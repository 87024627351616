import React, { memo } from 'react'
import get from 'lodash/get'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'

const sanitizeRestProps: (props: any) => any = ({
  addLabel,
  allowEmpty,
  basePath,
  cellClassName,
  className,
  emptyText,
  formClassName,
  fullWidth,
  headerClassName,
  label,
  linkType,
  link,
  locale,
  record,
  resource,
  sortable,
  sortBy,
  sortByOrder,
  source,
  textAlign,
  translateChoice,
  ...props
}) => props

export const fieldPropTypes = {
  addLabel: PropTypes.bool,
  sortBy: PropTypes.string,
  sortByOrder: PropTypes.oneOf(['ASC', 'DESC']),
  source: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  sortable: PropTypes.bool,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  textAlign: PropTypes.oneOf(['right', 'left']),
  emptyText: PropTypes.string,
}

const TruncatedTextField = memo(
  ({ className, source, record = {}, emptyText, truncate, ...rest }) => {
    const value = get(record, source)

    if (truncate && value && value.length > truncate) {
      return (
        <Tooltip title={value} placement="right">
          <Typography
            component="span"
            variant="body2"
            className={className}
            {...sanitizeRestProps(rest)}
          >
            {value.substring(0, truncate)}...
          </Typography>
        </Tooltip>
      )
    }

    return (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeRestProps(rest)}
      >
        {value != null && typeof value !== 'string'
          ? JSON.stringify(value)
          : value || emptyText}
      </Typography>
    )
  },
)

// what? TypeScript looses the displayName if we don't set it explicitly
TruncatedTextField.displayName = 'TruncatedTextField'

TruncatedTextField.defaultProps = {
  addLabel: true,
}

TruncatedTextField.propTypes = {
  // @ts-ignore
  ...Typography.propTypes,
  ...fieldPropTypes,
}

export default TruncatedTextField
