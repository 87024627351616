import React from 'react'
import Button from 'Components/Common/Buttons/Button'
import { Link } from 'react-router-dom'
import { useTranslate } from 'react-admin'

const AlsoActionDialogsCreateProductVariantButton = props => {
  const translate = useTranslate()

  return (
    <Button
      component={Link}
      to={`/also_products/create/${props.record?.id}`}
      color="primary"
      disableOnGlobalLoad
      label={translate('actions.also.createProductVariant')}
    />
  )
}

export default AlsoActionDialogsCreateProductVariantButton
