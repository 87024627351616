import { ListItem, ListItemText, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslate } from 'react-admin'

const itemToString = item => item.label

export function Input(inputProps) {
  const { InputProps, autoFocus, ref, label, ...other } = inputProps
  const t = useTranslate()

  return (
    <TextField
      autoFocus={autoFocus}
      label={t(label)}
      InputProps={{
        inputRef: ref,
        // classes: {
        //   root: classes.inputRoot,
        // },
        ...InputProps,
      }}
      variant="filled"
      {...other}
    />
  )
}

export function renderSuggestion({
  suggestion,
  index,
  key,
  itemProps,
  highlightedIndex,
  style,
  selectedItem,
}) {
  const isHighlighted = highlightedIndex === index
  const isSelected = selectedItem && selectedItem.value === suggestion.value

  return (
    <ListItem
      {...itemProps}
      key={key}
      selected={isHighlighted}
      component="div"
      style={{
        ...style,
        fontWeight: isSelected ? 600 : 400,
      }}
    >
      <ListItemText
        primary={itemToString(suggestion)}
        secondary={suggestion.secondary}
      />
    </ListItem>
  )
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
}
