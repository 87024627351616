import React from 'react'
import {
  BooleanInput,
  DateInput,
  Filter,
  SelectInput,
  TextInput,
  usePermissions,
} from 'react-admin'
import { isAlsoReseller as checkAlsoReseller } from 'Lib/helper'

const DateTimeFormat = new Intl.DateTimeFormat('de-DE', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
})

const aluxoChoices = [
  'opened',
  'review',
  'approved',
  'rejected',
  'created',
  'customer_signed',
  'reseller_signed',
  'ordered',
  'order_confirmed',
  'shipped',
  'delivered',
  'payment_pending',
  'confirmed',
  'completed',
  'cancelled',
  'invoiced',
  'paid',
].map(status => ({ id: status }))

const alsoChoices = [
  'opened',
  'transmitted',
  'completed',
  'cancelled',
].map(status => ({ id: status }))

export const dateInputOptions = {
  formatDate: DateTimeFormat.format,
}

const optionRenderer = (choice, isAlsoReseller) =>
  isAlsoReseller
    ? `alsoOrders.markings.${choice.id}`
    : `orders.markings.${choice.id}`

const OrderFilter = props => {
  const { permissions = [] } = usePermissions()
  const isAlsoReseller = checkAlsoReseller(permissions)
  return (
    <Filter {...props}>
      <TextInput source="orderNumber" />
      <TextInput source="billingAddress.company" />
      <SelectInput
        source="marking"
        choices={isAlsoReseller ? alsoChoices : aluxoChoices}
        optionText={choice => optionRenderer(choice, isAlsoReseller)}
      />
      <DateInput
        source="created[before]"
        label="filters.orders.createdBefore"
        options={{ ...dateInputOptions }}
      />
      <DateInput
        source="created[after]"
        label="filters.orders.createdAfter"
        options={{ ...dateInputOptions }}
      />
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="priority"
      />
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="overdue"
      />
    </Filter>
  )
}

export default OrderFilter
