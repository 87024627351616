import React from 'react'
import {
  Create,
  Datagrid,
  Edit,
  FunctionField,
  List,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'
import { validateRequired } from 'Resources/Aluxo/devices'

export const ProductAttributeGroupEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect={false}>
      <TextInput source="name" validate={validateRequired} />
      <TextInput source="displayName" validate={validateRequired} />
    </SimpleForm>
  </Edit>
)

export const ProductAttributeGroupCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={validateRequired} />
      <TextInput source="displayName" validate={validateRequired} />
    </SimpleForm>
  </Create>
)

// eslint-disable-next-line import/prefer-default-export
export const ProductAttributeGroupList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <FunctionField
        label="resources.common_product_attribute_groups.fields.children"
        render={record => record.values.length}
      />
    </Datagrid>
  </List>
)
