import PropTypes from 'prop-types'
import React from 'react'
import { MenuItemLink } from 'react-admin'
import { Divider } from '@material-ui/core'
import { TranslatedResourceName } from '../../Common/Layout/MenuTranslations'

const Menu = ({ onMenuClick }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    }}
  >
    <div>
      <MenuItemLink
        to="/resellers"
        primaryText={<TranslatedResourceName resource="resellers" />}
        onClick={onMenuClick}
      />

      <Divider />
      <MenuItemLink
        to="/also_categories"
        primaryText={<TranslatedResourceName resource="also_categories" />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/also_products"
        primaryText={<TranslatedResourceName resource="also_products" />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/also_services"
        primaryText={<TranslatedResourceName resource="also_services" />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/also_enum_members"
        primaryText={<TranslatedResourceName resource="also_enum_members" />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/also_acmp_services"
        primaryText={<TranslatedResourceName resource="also_acmp_services" />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/string_translations"
        primaryText={<TranslatedResourceName resource="string_translations" />}
        onClick={onMenuClick}
      />
      <Divider />
      <MenuItemLink
        to="/also_orders"
        primaryText={<TranslatedResourceName resource="also_orders" />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/also_customers"
        primaryText={<TranslatedResourceName resource="also_customers" />}
        onClick={onMenuClick}
      />
      <Divider />
      <MenuItemLink
        to="/knowledge_items"
        primaryText={<TranslatedResourceName resource="knowledge_items" />}
        onClick={onMenuClick}
      />
      <Divider />
      <MenuItemLink
        to="/common_product_attribute_values"
        primaryText={
          <TranslatedResourceName resource="common_product_attribute_values" />
        }
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/also_edi_items"
        primaryText={<TranslatedResourceName resource="also_edi_items" />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/also_resellable_products"
        primaryText={
          <TranslatedResourceName resource="also_resellable_products" />
        }
        onClick={onMenuClick}
      />
    </div>
  </div>
)

Menu.propTypes = {
  onMenuClick: PropTypes.func,
}

Menu.defaultProps = {
  onMenuClick: () => null,
}

export default Menu
