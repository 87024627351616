export const SHOW_DIALOG = 'WAAS::SHOW_DIALOG'
export const HIDE_DIALOG = 'WAAS::HIDE_DIALOG'

export const showDialog = (record, resource, type) => ({
  type: SHOW_DIALOG,
  payload: { record, resource, type },
})
export const hideDialog = () => ({
  type: HIDE_DIALOG,
})

const initialState = { record: null, resource: null, type: null }

const reducer = (state = initialState, { type, payload }) => {
  if (type === SHOW_DIALOG) {
    return {
      ...state,
      resource: payload.resource,
      type: payload.type,
      record: payload.record,
    }
  }
  if (type === HIDE_DIALOG) {
    return initialState
  }
  return state
}

export default reducer
