import { translate } from 'react-admin'

export const OrderLabel = translate(({ translate: t }) =>
  t('resources.external_protection_plans.labels.orderData'),
)
export const UploadedLabel = translate(({ translate: t }) =>
  t('resources.external_protection_plans.labels.status_uploaded'),
)
export const ReleasedLabel = translate(({ translate: t }) =>
  t('resources.external_protection_plans.labels.status_released'),
)
export const UnreleasedLabel = translate(({ translate: t }) =>
  t('resources.external_protection_plans.labels.status_unreleased'),
)
export const CanceledLabel = translate(({ translate: t }) =>
  t('resources.external_protection_plans.labels.status_canceled'),
)
