import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useTranslate } from 'react-admin'

const CasCustomerArchivedProjects = ({ archivedProjects }) => {
  const translate = useTranslate()

  if (archivedProjects.length === 0) {
    return null
  }

  return (
    <div style={{ marginTop: 24 }}>
      <Typography variant="h5" style={{ paddingLeft: 8 }}>
        {translate('resources.discounts.cas.expiredProject', {
          smart_count: 2,
        })}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{translate('resources.discounts.cas.cas')}</TableCell>
            <TableCell>
              {translate('resources.discounts.cas.expiredAt')}
            </TableCell>
            <TableCell>
              {translate('resources.discounts.cas.ncabExpiredAt')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {archivedProjects.map(ep => (
            <TableRow>
              <TableCell>{ep.casNumber}</TableCell>
              <TableCell>{moment(ep.expiresAt).format('DD.MM.YYYY')}</TableCell>
              <TableCell>
                {ep.ncabExpiresAt
                  ? moment(ep.expiresAt).format('DD.MM.YYYY')
                  : '-'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

CasCustomerArchivedProjects.propTypes = {
  archivedProjects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default CasCustomerArchivedProjects
