import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { ResellerContext } from 'ResellerContext'

const DisplaySpecsField = ({ source, record, elStyle }) => {
  const { flags } = useContext(ResellerContext)
  if (!flags.cNetSpecs) {
    return null
  }
  const value = get(record, source) || {}

  return (
    <span style={elStyle}>
      {Object.entries(value)
        .map(([, spec]) => spec)
        .join(' / ')}
    </span>
  )
}

DisplaySpecsField.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  addLabel: PropTypes.bool,
  elStyle: PropTypes.object,
  record: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
}

DisplaySpecsField.defaultProps = {
  addLabel: true,
  elStyle: {},
}

export default DisplaySpecsField
