export default {
  workflows: {
    loading: 'Laden...',
    offer: 'Angebot',
    buy_order: 'Kauf',
    rent_order: 'Miete',
  },
  transitions: {
    create_offer: 'Angebot erzeugen',
    reject_offer: 'Angebot ablehnen',
    open_buy: 'Kaufauftrag erzeugen',
    open_rent: 'Mietauftrag erzeugen',
    open: 'Auftrag öffnen',
    change: 'Auftrag ändern',
    create: 'Vertrag erstellen',
    review: 'Bonität prüfen',
    cancel: 'Auftrag Stornieren',
    approve: 'Auftrag genehmigen',
    reject: 'Auftrag ablehnen',
    customer_sign: 'Kunden-Unterschrift bestätigen',
    reseller_sign: 'Wiederverkäufer-Unterschrift bestätigen',
    order: 'Als bestellt markieren',
    confirm_order: 'Bestellung bestätigen',
    ship: 'Versand bestätigen',
    deliver: 'Zustellung bestätigen',
    confirm: 'Übernahmebestätigung prüfen',
    complete: 'Bestellung abschließen',
    refuse: 'Bestellung verweigern',
    invoice: 'In Rechnung stellen',
    pay: 'Als bezahlt markieren',
  },
  markings: {
    offer_draft: 'Angebotsentwurf',
    offer_created: 'Angebot(e) in gSales angelegt',
    offer_accepted: 'Angebot angenommen',
    draft: 'Entwurf',
    opened: 'Eröffnet',
    changed: 'Geändert',
    approved: 'Genehmigt',
    review: 'Prüfung',
    created: 'Erstellt',
    rejected: 'Abgelehnt',
    error: 'Fehlerhaft',
    completed: 'Abgeschlossen',
    cancelled: 'Storniert',
    customer_signed: 'Gezeichnet Kunde',
    reseller_signed: 'Gezeichnet Wiederverkäufer',
    ordered: 'Bestellt',
    order_confirmed: 'Bestellung bestätigt',
    shipped: 'Versandt',
    delivered: 'Zugestellt',
    payment_pending: 'Warte auf Bezahlung',
    confirmed: 'Bestätigt',
    refused: 'Verweigert',
    invoiced: 'In Rechnung gestellt',
    paid: 'Bezahlt',
  },
  markingsShort: {
    offer_draft: 'Angebotsentwurf',
    offer_created: 'Angebot(e) in gSales angelegt',
    draft: 'Entwurf',
    opened: 'Eröffnet',
    changed: 'Geändert',
    approved: 'Genehmigt',
    review: 'Prüfung',
    created: 'Erstellt',
    rejected: 'Abgelehnt',
    error: 'Fehlerhaft',
    completed: 'Abgeschlossen',
    cancelled: 'Storniert',
    customer_signed: 'Gez. KD',
    reseller_signed: 'Gez. WK',
    ordered: 'Bestellt',
    order_confirmed: 'Bestellung bestätigt',
    shipped: 'Versandt',
    delivered: 'Zugestellt',
    payment_pending: 'Bez. ausstehend',
    confirmed: 'Bestätigt',
    refused: 'Verweigert',
    invoiced: 'Rechnung gestellt',
    paid: 'Bezahlt',
  },
  flags: {
    dllApplicationCreated: 'DLL Antrag gestellt',
    albisApplicationCreated: 'Albis Antrag gestellt',
    albisApplicationNoStatus: 'Nicht verfügbar',
    ordered: 'Bestellt',
    order_confirmed: 'Bestellung bestätigt',
    shipped: 'Versandt',
    delivered: 'Zugestellt',
    payment_pending: 'Warte auf Bezahlung',
    invoiced: 'In Rechnung gestellt',
    licenseConfigured: 'Lizenzen eingerichtet',
    onlineServicesConfigured: 'Online Services eingerichtet',
    managedServicesConfigured: 'Managed Services eingerichtet',
    protectionPlanCreated: 'Schutzbriefe eingebucht',
    gSalesOfferCreated: 'Angebot bei GSales angelegt',
    actions: {
      ordered: 'Bestellt',
      shipped: 'Versandt',
      delivered: 'Zugestellt',
      payment_pending: 'Warte auf Bezahlung',
      licenseConfigured: 'Lizenzen eingerichtet',
      onlineServicesConfigured: 'Online Services eingerichtet',
      managedServicesConfigured: 'Managed Services eingerichtet',
      protectionPlanCreated: 'Schutzbriefe eingebucht',
    },
    eventType: {
      CREATE: 'Gesetzt',
      REMOVE: 'Entfernt',
    },
    meta: {
      LEASING: {
        APPLICATION_STATUS: 'Finanzierungsantrag Status',
      },
      DLL: {
        APPLICATION_ID: 'Antrag ID',
        APPLICATION_CREATED: 'Antrag angelegt',
        PROPOSAL_CREATED: 'Objekte hinzugefügt',
        PROPOSAL_VALID: 'Rate validiert',
        ASSESSMENT_INITIATED: 'Kreditprüfung beantragt',
        APPLICATION_STATUS: 'Antragstatus',
      },
    },
  },
  links: {
    toCustomer: 'Zum Kunden wechseln',
  },
  actions: {
    process: 'Prozessieren',
  },
}
