import { useOrder } from 'OrderContext'
import React from 'react'
import { ListButton, useTranslate } from 'react-admin'
import Button from '@material-ui/core/Button'
import UserIcon from '@material-ui/icons/People'
import { Link } from 'react-router-dom'

const OrderEditActions = ({ customer }) => {
  const translate = useTranslate()
  const { basePath } = useOrder()
  return (
    <>
      {customer && (
        <Button
          component={Link}
          to={`/customers/${customer.id}`}
          color="primary"
          size="small"
          startIcon={<UserIcon />}
        >
          {translate('orders.links.toCustomer')}
        </Button>
      )}
      <ListButton basePath={basePath} />
    </>
  )
}

export default OrderEditActions
