import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Done } from '@material-ui/icons'
import { orderBy } from 'lodash'
import { useOrder } from 'OrderContext'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslate } from 'react-admin'
import FlagListItemText from './FlagListItemText'
import FlagListItemTitle from './FlagListItemTitle'

const FlagListItem = ({ orderType, type }) => {
  const { record } = useOrder()
  const t = useTranslate()

  const flags = orderBy(record.flags, 'createdAt', 'desc')
  const flag = flags.find(f => f.type === type) || {}

  const metadata = Object.entries(flag.data ?? {}).map(([key, value]) => {
    let displayValue = value
    if (value === true) {
      displayValue = 'Ja'
    } else if (value === false) {
      displayValue = 'Nein'
    }

    if (key === 'DLL.APPLICATION_ID' && value) {
      const href = `https://secure4.atoncefinance.com/Sprint3/WFFinance_New.aspx?Tab=30&P=${value.substr(
        3,
      )}&WFID=1&page=new#/`
      displayValue = (
        <a href={href} target="_blank" rel="noreferrer">
          {value}
        </a>
      )
    }

    return (
      <div key={key}>
        <strong>{t(`orders.flags.meta.${key}`)}:</strong> {displayValue}
      </div>
    )
  })

  return (
    <ListItem>
      {flag.value ? (
        <ListItemIcon>
          <Done />
        </ListItemIcon>
      ) : null}
      <ListItemText
        inset={!flag.value}
        primary={
          <FlagListItemTitle orderType={orderType} type={type} {...flag} />
        }
        secondary={
          <>
            <FlagListItemText orderType={orderType} type={type} {...flag} />
            {metadata}
          </>
        }
      />
    </ListItem>
  )
}

FlagListItem.propTypes = {
  orderType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default FlagListItem
