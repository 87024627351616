import React from 'react'
import PropTypes from 'prop-types'

const WorkplaceServicesDisplay = ({ record }) => (
  <>
    {record.workplaceServices.map(workplaceService => (
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            width: '200px',
          }}
        >
          {workplaceService.category}
        </div>
        <div
          style={{
            marginLeft: '1em',
          }}
        >
          {workplaceService.name}
        </div>
      </div>
    ))}
    {record.workplaceResellerCustomServices.map(workplaceService => (
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            width: '200px',
          }}
        >
          {workplaceService.category}
        </div>
        <div
          style={{
            marginLeft: '1em',
          }}
        >
          {workplaceService.name}
        </div>
      </div>
    ))}
  </>
)

WorkplaceServicesDisplay.propTypes = {
  record: PropTypes.shape({
    workplaceServices: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
}

WorkplaceServicesDisplay.defaultProps = {
  addLabel: true,
}

export default WorkplaceServicesDisplay
