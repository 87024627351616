export default {
  transitions: {
    transmit: 'Auftrag übermitteln',
    cancel: 'Auftrag Stornieren',
    complete: 'Bestellung abschließen',
  },
  markings: {
    opened: 'Eröffnet',
    transmitted: 'Übermittelt',
    completed: 'Abgeschlossen',
    cancelled: 'Storniert',
  },
  markingsShort: {
    opened: 'Eröffnet',
    transmitted: 'Übermittelt',
    completed: 'Abgeschlossen',
    cancelled: 'Storniert',
  },
  flags: {
    transmitted: 'Übermittelt',
    actions: {},
    eventType: {
      CREATE: 'gesetzt',
      REMOVE: 'entfernt',
    },
  },
  actions: {
    process: 'Prozessieren',
  },
}
