import Button from '@material-ui/core/Button'
import React from 'react'
import { useTranslate, useLocale, useSetLocale } from 'react-admin'

export const CHANGE_LANGUAGE_REQUEST = 'APP:_CHANGE_LANGUAGE_REQUEST'

const langs = ['en', 'fr', 'de', 'it']

const SystemLanguageSwitcher = () => {
  const locale = useLocale()
  const setLocale = useSetLocale()
  const translate = useTranslate()

  return (
    <div>
      <div>{translate('resources.profile.systemlanguage')}</div>
      {langs.map(lang => (
        <Button
          key={lang}
          variant={locale === lang && 'outlined'}
          onClick={() => setLocale(lang)}
        >
          {lang}
        </Button>
      ))}
    </div>
  )
}

export default SystemLanguageSwitcher
