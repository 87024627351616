import {
  Datagrid,
  BooleanInput,
  EditButton,
  EditContextProvider,
  ReferenceManyField,
  required,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  useEditController,
  useLoading,
  useMutation,
  useNotify,
  usePermissions,
  useTranslate,
} from 'react-admin'
import React, { useContext } from 'react'

import AluxoDiscountInput from 'Components/Aluxo/Discounts/AluxoDiscountInput'
import { Button, Grid } from '@material-ui/core'
import CasCustomerDiscountEdit from 'Components/Aluxo/Discounts/CasCustomerDiscountEdit'
import ContextEdit from 'Components/Common/ContextEdit'
import { NullTitle } from 'Components/Common/TranslatedTitle'
import NullToolbar from 'Components/Common/NullToolbar'
import OverdueField from 'Components/Common/Orders/Fields/OverdueField'
import PropTypes from 'prop-types'
import PureAffiliateField from 'Components/Common/ViewFields/AffiliateField'
import PureBooleanField from 'Components/Common/ViewFields/BooleanField'
import PureCurrencyField from 'Components/Common/ViewFields/CurrencyField'
import PureDateField from 'Components/Common/ViewFields/DateField'
import { ResellerContext } from 'ResellerContext'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import StatusField from 'Components/Common/Orders/Fields/StatusField'
import { get } from 'lodash'
import { isReseller } from 'Lib/helper'
import styled from 'styled-components/macro'
import BillingAddress from 'Components/Aluxo/Customers/Edit/BillingAddress'
import AddressSets from 'Components/Aluxo/Customers/Edit/AddressSets'
import CEO from 'Components/Aluxo/Customers/Edit/CEO'
import BankDetails from 'Components/Aluxo/Customers/Edit/BankDetails'
import CustomerEditToolbar from 'Components/Aluxo/Customers/Edit/Toolbar'
import { Add } from '@material-ui/icons'
import { useHistory } from 'react-router'
import produce from 'immer'
import DLLFields from 'Components/Aluxo/Customers/Edit/Dll'

const CustomerTitle = ({ record }) => {
  const translate = useTranslate()
  return `${translate('resources.customers.name', { smart_count: 1 })} - ${get(
    record,
    'email',
    '',
  )}`
}

const TranslatedText = ({ text }) => {
  const translate = useTranslate()
  return translate(text, { smart_count: 2 })
}

const FormHeading = ({ text }) => (
  <span
    style={{ fontSize: '1.5rem', margin: '15px 0 5px 0', display: 'block' }}
  >
    <TranslatedText text={text} />
  </span>
)

FormHeading.propTypes = {
  text: PropTypes.string.isRequired,
}

const CenteredDiv = styled.div`
  display: flex;
  min-height: 80px;
  align-items: center;
  justify-content: center;
`

const Row = styled.div`
  display: flex;

  & > div:nth-child(2) {
    margin-left: 20px;
  }
`

const CustomDatagrid = props => {
  const translate = useTranslate()

  if (props.data && Object.keys(props.data).length > 0) {
    return <Datagrid {...props} />
  }

  return (
    <CenteredDiv>
      {translate(
        props.loaded
          ? 'resources.customers.noOrders'
          : 'resources.customers.loadOrders',
      )}
    </CenteredDiv>
  )
}

CustomDatagrid.propTypes = {
  data: PropTypes.object.isRequired,
  loaded: PropTypes.bool.isRequired,
}

const Orders = props => {
  const { permissions = [] } = usePermissions()

  return (
    <ReferenceManyField
      addLabel={false}
      reference="orders"
      target="customer"
      filter={{ offer: false }}
      {...props}
    >
      <CustomDatagrid style={{ width: '100%' }}>
        <TextField source="orderNumber" />
        <PureBooleanField source="priority" hideFalseValues />
        <StatusField source="marking" sortable={false} />
        <OverdueField source="overdue" />
        <PureDateField source="created" format="DD.MM.YYYY" />
        <TextField source="billingAddress.company" />
        {permissions && permissions.includes('ROLE_STOREVIEW') && (
          <TextField source="storeView.name" />
        )}
        <PureCurrencyField
          label="resources.orders.fields.totalVolume"
          source="totalVolume"
        />
        {isReseller(permissions) && <PureAffiliateField source="affiliate" />}
        <EditButton />
      </CustomDatagrid>
    </ReferenceManyField>
  )
}

const Offers = props => (
  <ReferenceManyField
    addLabel={false}
    reference="offers"
    target="customer"
    filter={{ offer: true }}
    {...props}
  >
    <CustomDatagrid style={{ width: '100%' }}>
      <TextField source="offerNumbers" />
      <StatusField source="marking" sortable={false} />
      <PureDateField source="created" format="DD.MM.YYYY" />
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <TextField source="storeView.name" />
      )}
      <PureCurrencyField
        label="resources.orders.fields.totalVolume"
        source="totalVolume"
      />
      <EditButton />
    </CustomDatagrid>
  </ReferenceManyField>
)

Offers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  permissions: PropTypes.array.isRequired,
}

const CustomerActions = ({ data }) => {
  const translate = useTranslate()
  const showNotification = useNotify()
  const loading = useLoading()
  const [resetPassword, { loading: mutationLoading }] = useMutation(
    {
      type: 'resetCustomerPassword',
      resource: 'customers',
      payload: { id: data.id },
    },
    {
      undoable: true,
      onSuccess: () => {
        showNotification(
          'notifications.resetCustomerPassword.success',
          'info',
          {},
          true,
        )
      },
      onFailure: () => {
        showNotification('notifications.resetCustomerPassword.fail')
      },
    },
  )

  return (
    <TopToolbar>
      <Button
        color="primary"
        onClick={resetPassword}
        disabled={mutationLoading || loading}
      >
        <RotateLeftIcon style={{ marginRight: '10px' }} />
        {translate('ra.auth.reset')}
      </Button>
    </TopToolbar>
  )
}

CustomerActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
}

const CasActions = ({ data }) => {
  const translate = useTranslate()
  const loading = useLoading()
  const history = useHistory()

  return (
    <TopToolbar>
      <Button
        color="primary"
        onClick={() => history.push(`/customers/${data.id}/createCasProject`)}
        disabled={loading}
      >
        <Add style={{ marginRight: '10px' }} />
        {translate('actions.createNewCasProject')}
      </Button>
    </TopToolbar>
  )
}

const CustomerEdit = props => {
  const controllerProps = useEditController({
    ...props,
    transform: values => {
      const { activeProject } = values

      if (activeProject) {
        const newValues = produce(values, draftState => {
          const p = draftState.projects.find(p => p.id === activeProject.id)
          p.casNumber = activeProject.casNumber
          p.expiresAt = activeProject.expiresAt
          p.ncabExpiresAt = activeProject.ncabExpiresAt

          p.discounts = activeProject.lines.flatMap(
            ({ sku, productName, ...discounts }) =>
              Object.entries(discounts)
                .filter(([, relativeDiscount]) => relativeDiscount)
                .map(([discountType, relativeDiscount]) => ({
                  sku,
                  productName,
                  discountType,
                  relativeDiscount: parseFloat(relativeDiscount),
                })),
          )

          delete draftState.activeProject
        })
        return newValues
      }

      return values
    },
  })
  const { permissions = [] } = usePermissions()
  const { flags } = useContext(ResellerContext)

  return (
    <EditContextProvider value={controllerProps}>
      <Grid container spacing={1}>
        <Grid item xs="12">
          <ContextEdit
            {...controllerProps}
            title={<CustomerTitle />}
            action={<CustomerActions />}
          >
            <SimpleForm redirect={false} toolbar={<CustomerEditToolbar />}>
              <TextInput source="email" validate={[required()]} />
              <TextField source="customerNumber" />
              <BooleanInput source="referencesRequired" />
              <BooleanInput source="autopilotExport" />
              <DLLFields />

              {permissions && permissions.includes('ROLE_STOREVIEW') && (
                <TextField source="storeView.name" />
              )}
            </SimpleForm>
          </ContextEdit>
        </Grid>
        <Grid item xs="12">
          <ContextEdit
            title={<TranslatedText text="resources.orders.name" />}
            {...controllerProps}
          >
            <SimpleForm {...props} toolbar={<NullToolbar />}>
              <Orders />
            </SimpleForm>
          </ContextEdit>
        </Grid>
        {flags.createOrder && (
          <Grid item xs="12">
            <ContextEdit
              title={<TranslatedText text="resources.offers.name" />}
              {...controllerProps}
            >
              <SimpleForm {...props} toolbar={<NullToolbar />}>
                <Offers />
              </SimpleForm>
            </ContextEdit>
          </Grid>
        )}
        {isReseller(permissions) && (
          <>
            <Grid item xs="12">
              <ContextEdit
                {...controllerProps}
                title={<TranslatedText text="resources.discounts.cas.name" />}
                action={<CasActions />}
              >
                <SimpleForm redirect={false} toolbar={<CustomerEditToolbar />}>
                  <CasCustomerDiscountEdit />
                </SimpleForm>
              </ContextEdit>
            </Grid>
            <Grid item xs="12">
              <ContextEdit
                {...controllerProps}
                title={<TranslatedText text="resources.discounts.aluxo.name" />}
                actions={null}
              >
                <SimpleForm redirect={false} toolbar={<CustomerEditToolbar />}>
                  <Row>
                    <AluxoDiscountInput
                      label="resources.discounts.aluxo.itScopeDeviceMargin"
                      discountType="itScopeDeviceMargin"
                    />
                    <AluxoDiscountInput
                      label="resources.discounts.aluxo.defaultMargin"
                      discountType="defaultItScopeDeviceMargin"
                    />
                  </Row>
                  <Row>
                    <AluxoDiscountInput
                      label="resources.discounts.aluxo.itScopeProductMargin"
                      discountType="itScopeProductMargin"
                    />
                    <AluxoDiscountInput
                      label="resources.discounts.aluxo.defaultMargin"
                      discountType="defaultItScopeProductMargin"
                    />
                  </Row>
                  <Row>
                    <AluxoDiscountInput
                      label="resources.discounts.aluxo.itScopeServiceExtensionsMargin"
                      discountType="itScopeServiceExtensionsMargin"
                    />
                    <AluxoDiscountInput
                      label="resources.discounts.aluxo.defaultMargin"
                      discountType="defaultItScopeServiceExtensionsMargin"
                    />
                  </Row>
                  <Row>
                    <AluxoDiscountInput
                      label="resources.discounts.aluxo.protectionPlanMargin"
                      discountType="protectionPlanMargin"
                    />
                    <AluxoDiscountInput
                      label="resources.discounts.aluxo.defaultMargin"
                      discountType="defaultProtectionPlanMargin"
                    />
                  </Row>
                  <Row>
                    <AluxoDiscountInput
                      label="resources.discounts.aluxo.cloudServiceMargin"
                      discountType="cloudServiceMargin"
                    />
                    <AluxoDiscountInput
                      label="resources.discounts.aluxo.defaultMargin"
                      discountType="defaultCloudServiceMargin"
                    />
                  </Row>
                  <Row>
                    <AluxoDiscountInput
                      label="resources.discounts.aluxo.managedServiceMargin"
                      discountType="managedServiceMargin"
                    />
                    <AluxoDiscountInput
                      label="resources.discounts.aluxo.defaultMargin"
                      discountType="defaultManagedServiceMargin"
                    />
                  </Row>
                </SimpleForm>
              </ContextEdit>
            </Grid>
          </>
        )}
        <Grid item xs="12" sm="6">
          <Grid container spacing={1}>
            <Grid item xs="12">
              <ContextEdit
                {...controllerProps}
                title={<NullTitle />}
                actions={null}
              >
                <BillingAddress />
              </ContextEdit>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs="12" sm="6">
          <Grid container spacing={1}>
            <Grid item xs="12">
              <ContextEdit
                {...controllerProps}
                title={<NullTitle />}
                actions={null}
              >
                <BankDetails />
              </ContextEdit>
            </Grid>

            <Grid item xs="12">
              <ContextEdit
                {...controllerProps}
                title={<NullTitle />}
                actions={null}
              >
                <CEO />
              </ContextEdit>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs="12">
        <ContextEdit {...controllerProps} title={<NullTitle />} actions={null}>
          <AddressSets />
        </ContextEdit>
      </Grid>
    </EditContextProvider>
  )
}

export default CustomerEdit
