import { isAffiliateReseller, isReseller } from 'Lib/helper'
import { useOrder } from 'OrderContext'
import PropTypes from 'prop-types'
import SetProtectionPlanButton from 'Components/Aluxo/Buttons/SetProtectionPlanButton'
import ActionDelete from '@material-ui/icons/Delete'
import React from 'react'
import GenericRestClientButton from 'Components/Common/ActionButtons/GenericRestClientButton'
import { useApiClient } from 'Rest/fetchHydra'
import {
  usePermissions,
  ResourceContextProvider,
  useRefresh,
  useDelete,
  useNotify,
} from 'react-admin'
import { useQueryClient } from 'react-query'
import Button from 'Components/Common/Buttons/Button'
import BundleLine from './BundleLine'
import DefaultLine from './DefaultLine'
import EditDialog from './EditDialog'
import { orderLineReferences } from '../OrderLinesContainer'

const references = {
  DeviceOrderLine: 'device_order_lines',
  ProductOrderLine: 'product_order_lines',
  ServiceOrderLine: 'service_order_lines',
  ServiceExtensionOrderLine: 'service_extension_order_lines',
  ProtectionPlanOrderLine: 'protection_plan_order_lines',
  GenericOrderLine: 'generic_order_lines',
  PromoBundleOrderLine: 'promo_bundle_order_lines',
}

const DeleteOrderLineButton = ({ reference, id, disabled }) => {
  const queryClient = useQueryClient()
  const refreshView = useRefresh()
  const [deleteOne] = useDelete(
    reference,
    id,
    {},
    {
      onSuccess: () => {
        refreshView()
        queryClient.invalidateQueries(reference)
      },
    },
  )

  return (
    <Button
      label={<ActionDelete />}
      onClick={deleteOne}
      color="secondary"
      disabled={disabled}
    />
  )
}

const OrderLineActions = ({ reference, id, line, mode }) => {
  const { canChange } = useOrder()
  const refreshView = useRefresh()
  const { permissions = [] } = usePermissions()
  const apiClient = useApiClient()
  const showNotification = useNotify()
  const queryClient = useQueryClient()

  return (
    <>
      {mode === 'financing' && (
        <GenericRestClientButton
          action="detectDLLMetaData"
          type="detectDLLMetaData"
          onClick={async () => {
            try {
              await apiClient({
                endpoint: `/dll_detect_metadata_for_orderline_request`,
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
                method: 'POST',
                body: {
                  className: Object.entries(orderLineReferences).find(
                    ([, r]) => r === reference,
                  )[0],
                  entityId: line.id,
                },
              })
            } catch (e) {
              if (e.isAxiosError) {
                showNotification(e.response.data.detail, 'warning')
              }

              throw new Error('failed')
            } finally {
              refreshView()
              queryClient.invalidateQueries(reference)
            }
          }}
          // icon={icon || <Add />}
          // noLabel={!showLabel}
        />
      )}
      {isReseller(permissions) &&
        [
          'device_order_lines',
          'product_order_lines',
          'protection_plan_order_lines',
          'generic_order_lines',
          'service_extension_order_lines',
          'service_order_lines',
          'promo_bundle_order_lines',
        ].includes(reference) && <EditDialog reference={reference} id={id} />}
      {isAffiliateReseller(permissions) &&
        ['service_order_lines'].includes(reference) && (
          <EditDialog reference={reference} id={id} />
        )}
      {isReseller(permissions) &&
        [
          'device_order_lines',
          'product_order_lines',
          'generic_order_lines',
          'service_extension_order_lines',
          'service_order_lines',
          'promo_bundle_order_lines',
        ].includes(reference) && (
          <DeleteOrderLineButton
            disabled={!canChange}
            id={id}
            reference={reference}
          />
        )}
      {isReseller(permissions) &&
        reference === 'protection_plan_order_lines' && (
          <SetProtectionPlanButton
            deviceLineId={line.deviceOrderLine_reference_id}
            type="addProtectionPlan"
            protectionPlanType="NO_PROTECTION_PLAN"
            icon={<ActionDelete color={canChange ? 'secondary' : 'disabled'} />}
            disabled={!canChange}
          />
        )}
    </>
  )
}

OrderLineActions.propTypes = {
  reference: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

const TableLine = props => {
  const { lineType, id } = props

  const reference = references[lineType]

  const actions = (
    <OrderLineActions
      reference={reference}
      id={id}
      line={props}
      mode={props.mode}
    />
  )

  return (
    <ResourceContextProvider value={reference}>
      {lineType === 'PromoBundleOrderLine' ? (
        <BundleLine {...props} actions={actions} />
      ) : (
        <DefaultLine {...props} actions={actions} />
      )}
    </ResourceContextProvider>
  )
}

TableLine.propTypes = {
  lineType: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default TableLine
