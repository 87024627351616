import { ListItemIcon, ListItemText } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import MUIMenu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {
  Cloud,
  Dashboard,
  EuroSymbol,
  Security,
  Settings,
  ShopTwo,
} from '@material-ui/icons'
import PropTypes from 'prop-types'
import React, { createElement } from 'react'
import { MenuItemLink } from 'react-admin'
import WebsiteDataSubmenu from '../../../WebsiteDataSubmenu'
import CustomSettingsMenuItem from '../Menu/CustomSettingsMenuItem'
import SettingsMenuItem from '../Menu/SettingsMenuItem'

import { TranslatedString, TranslatedResourceName } from './MenuTranslations'
import SubMenu from './SubMenu'

const Menu = ({ onMenuClick }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    }}
  >
    <div>
      <MenuItemLink
        to="/"
        primaryText={<TranslatedResourceName resource="dashboard" />}
        onClick={onMenuClick}
        leftIcon={createElement(Dashboard)}
      />
      <Divider />
      <MenuItemLink
        to="/orders"
        primaryText={<TranslatedResourceName resource="orders" />}
        onClick={onMenuClick}
        leftIcon={createElement(EuroSymbol)}
      />
      <Divider />

      <MenuItemLink
        to="/protection_plans"
        primaryText={<TranslatedResourceName resource="protection_plans" />}
        onClick={onMenuClick}
        leftIcon={createElement(Security)}
      />
      <Divider />
      <MenuItemLink
        to="/online_services"
        primaryText={<TranslatedResourceName resource="online_services" />}
        onClick={onMenuClick}
        leftIcon={createElement(Cloud)}
      />
      <MenuItemLink
        to="/managed_services"
        primaryText={<TranslatedResourceName resource="managed_services" />}
        onClick={onMenuClick}
        leftIcon={createElement(ShopTwo)}
      />
      <Divider />

      <WebsiteDataSubmenu onMenuClick={onMenuClick} />
    </div>

    <div>
      <Divider />
      <SubMenu
        mainItem={set => (
          <MenuItem onClick={({ currentTarget }) => set(currentTarget)}>
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText
              inset
              primary={<TranslatedString i18nkey="menu.settings.name" />}
            />
          </MenuItem>
        )}
      >
        {(target, hide) => (
          <MUIMenu anchorEl={target} open={Boolean(target)} onClose={hide}>
            <SettingsMenuItem />
            <CustomSettingsMenuItem type="smtpConfiguration" icon={Settings} />
            <CustomSettingsMenuItem type="corporateIdentity" icon={Settings} />
            <CustomSettingsMenuItem type="seoConfiguration" icon={Settings} />
          </MUIMenu>
        )}
      </SubMenu>
    </div>
  </div>
)

Menu.propTypes = {
  onMenuClick: PropTypes.func,
}

Menu.defaultProps = {
  onMenuClick: () => null,
}

export default Menu
