import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'react-final-form'
import renderTextField from 'Components/Common/InputFields/renderComponents/renderTextField'
import FormWizard, { FormWizardStep } from 'Components/Common/Layout/FormWizard'
import Grid from '@material-ui/core/Grid'
import useTranslatedRequiredValidation from 'Hooks/formValidation/useTranslatedRequiredValidation'
import useTranslatedEmailValidation from 'Hooks/formValidation/useTranslatedEmailValidation'
import { useTranslate, useNotify, useRedirect, Notification } from 'react-admin'
import renderSelectInput from 'Components/Common/InputFields/renderComponents/renderSelectInput'
import legalForms from 'Lib/legalForms'
import set from 'lodash/set'
import useTranslatedPasswordValidation from 'Hooks/formValidation/useTranslatedPasswordValidation'
import { useApiClient } from 'Rest/fetchHydra'

const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

const Step1 = () => {
  const translate = useTranslate()
  const required = useTranslatedRequiredValidation()
  const email = useTranslatedEmailValidation()
  const securePassword = useTranslatedPasswordValidation()
  return (
    <Grid container>
      <Grid item xs={12}>
        <Field
          name="email"
          type="email"
          label={translate('forms.alsoEdiResellerRegister.email')}
          render={renderTextField}
          validate={composeValidators(required, email)}
          showHelperText
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="password"
          type="password"
          label={translate('forms.alsoEdiResellerRegister.password')}
          render={renderTextField}
          validate={composeValidators(required, securePassword)}
          showHelperText
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

const Step2 = () => {
  const translate = useTranslate()
  const required = useTranslatedRequiredValidation()
  const email = useTranslatedEmailValidation()
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Field
          name="companyName"
          type="text"
          label={translate('forms.alsoEdiResellerRegister.companyName')}
          render={renderTextField}
          validate={required}
          showHelperText
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Field
          name="address.legalForm"
          label={translate('forms.alsoEdiResellerRegister.address.legalForm')}
          render={renderSelectInput}
          choices={legalForms}
          validate={required}
          showHelperText
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="address.street"
          type="text"
          label={translate('forms.alsoEdiResellerRegister.address.street')}
          render={renderTextField}
          validate={required}
          showHelperText
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Field
          name="address.postcode"
          type="text"
          label={translate('forms.alsoEdiResellerRegister.address.postcode')}
          render={renderTextField}
          validate={required}
          showHelperText
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Field
          name="address.city"
          type="text"
          label={translate('forms.alsoEdiResellerRegister.address.city')}
          render={renderTextField}
          validate={required}
          showHelperText
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="address.country"
          type="text"
          label={translate('forms.alsoEdiResellerRegister.address.country')}
          render={renderTextField}
          validate={required}
          showHelperText
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="website"
          type="text"
          label={translate('forms.alsoEdiResellerRegister.address.website')}
          render={renderTextField}
          validate={required}
          showHelperText
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="contactEmail"
          type="email"
          label={translate('forms.alsoEdiResellerRegister.contactEmail')}
          render={renderTextField}
          validate={composeValidators(required, email)}
          showHelperText
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

const Step3 = () => {
  const translate = useTranslate()
  const required = useTranslatedRequiredValidation()
  const email = useTranslatedEmailValidation()
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={2}>
        <Field
          name="address.salutation"
          label={translate('forms.alsoEdiResellerRegister.address.salutation')}
          render={renderSelectInput}
          choices={['Herr', 'Frau']}
          validate={required}
          showHelperText
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Field
          name="address.firstName"
          type="text"
          label={translate('forms.alsoEdiResellerRegister.address.firstName')}
          render={renderTextField}
          validate={required}
          showHelperText
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Field
          name="address.lastName"
          type="text"
          label={translate('forms.alsoEdiResellerRegister.address.lastName')}
          render={renderTextField}
          validate={required}
          showHelperText
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="address.email"
          type="email"
          label={translate('forms.alsoEdiResellerRegister.address.email')}
          render={renderTextField}
          validate={composeValidators(required, email)}
          showHelperText
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="address.phone"
          type="phone"
          label={translate('forms.alsoEdiResellerRegister.address.phone')}
          render={renderTextField}
          validate={required}
          showHelperText
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles({
  root: {
    maxWidth: 960,
    margin: '60px auto',
  },
  heading: {
    fontSize: '1.8rem',
  },
})

const RegisterWizard = props => {
  const classes = useStyles(props)
  const translate = useTranslate()
  const notify = useNotify()
  const redirect = useRedirect()
  const apiClient = useApiClient()

  const handleSubmit = async values => {
    try {
      set(values, 'address.company', values.companyName) // copy already given company name in address too
      const response = await apiClient({
        endpoint: '/resellers/edi',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: values,
      })
      if (!response.ok) {
        notify('notifications.alsoCreateEdiReseller.fail', 'warning')
      } else {
        redirect('/login')
        notify('notifications.alsoCreateEdiReseller.success')
      }
    } catch (e) {
      notify('notifications.alsoCreateEdiReseller.fail', 'warning')
    }
  }

  return (
    <>
      <Notification />
      <Card className={classes.root}>
        <CardHeader
          title={translate('forms.alsoEdiResellerRegister.formTitle')}
        />
        <CardContent>
          <FormWizard
            onSubmit={handleSubmit}
            submitLabel="actions.registerEdiReseller"
          >
            <FormWizardStep title="Anmeldeinformationen">
              <Step1 />
            </FormWizardStep>
            <FormWizardStep title="Firmeninformationen">
              <Step2 />
            </FormWizardStep>
            <FormWizardStep title="Ansprechpartner">
              <Step3 />
            </FormWizardStep>
          </FormWizard>
        </CardContent>
      </Card>
    </>
  )
}

export default RegisterWizard
