export default {
  genericError: 'Es ist ein Fehler aufgetreten',
  fetchCacheStatus: 'Cache Status wird abgerufen',
  imageNotAccepted:
    'Die Datei ist zu groß oder das Format wird nicht unterstützt',
  attachmentCreated: 'Anhang angelegt',
  deleteSuccess: 'Element gelöscht',
  pleaseEnterSerialNumbers:
    'Bitte hinterlegen Sie zum Versand der Übernahmebestätigung zuerst die Seriennummer(n) in den Bestellpositionen.',
  commentCreated: 'Kommentar hinzugefügt',
  commentNotCreated: 'Kommentar konnte nicht hinzugefügt werden',
  reportingUpdated: 'Reporting-Einstellungen wurden aktualisiert',
  reportingNotUpdated:
    'Reporting-Einstellungen konnten nicht aktualisiert werden',
  flagSet: 'Markiert',
  flagNotSet: 'Markierung konnte nicht gesetzt werden',
  flagRemoved: 'Markierung wurde entfernt',
  requestedCreditLimitCalculated: 'Kreditlimit wurde neu berechnet',
  flagsMissing:
    'Um in den nächsten Prozesschritt zu gelangen, müssen die markierten Status gesetzt sein',
  transitionApplied: 'Bestellung wurde prozessiert',
  orderLocked: 'Bestellung gesperrt',
  orderUnlocked: 'Bestellung freigegeben',
  passwordRequest:
    'Sie erhalten eine E-Mail mit weiteren Schritten zum Setzen Ihres Passworts',
  passwordSet: 'Ihr Passwort wurde gesetzt, Sie können sich nun einloggen',
  passwordSetFailed: 'Das Passwort konnte nicht zurückgesetzt werden.',
  deleteImage: {
    success: 'Bild gelöscht',
    fail: 'Bild konnte nicht gelöscht werden',
  },
  uploadImage: {
    success: 'Bild hochgeladen',
    fail: 'Bild konnte nicht hochgeladen werden',
  },
  resortImage: {
    success: 'Reihenfolge geändert',
    fail: 'Reihenfolge konnte nicht geändert werden',
  },
  credentialsTest: {
    success: 'Zugangsdaten erfolgreich geprüft',
  },
  testSMTP: {
    success: 'Test E-Mail wurde verschickt',
    fail: 'Test E-Mail konnte nicht verschickt werden',
  },
  copyAccessoryToVariants: {
    success: 'Zuweisung kopiert',
    fail: 'Zuweisung konnte nicht kopiert werden',
  },
  itScopeDistributorRefresh: {
    success: 'Distributoren von IT Scope erfolgreich abgerufen',
    fail: 'Fehler beim Abrufen der Distributoren von IT Scope',
  },
  refreshPrices: {
    success: 'Preise erfolgreich aktualisiert',
    fail: 'Fehler beim Aktualisieren der Preise',
  },
  itScopeUpdatePrices: {
    success: 'Preise von IT Scope erfolgreich abgerufen',
    fail: 'Fehler beim Abrufen der Preise von IT Scope',
  },
  updateItScopeMetadata: {
    success: 'IT Scope Produkt ID erfolgreich abgerufen',
    fail: 'Fehler beim Abrufen der IT Scope Produkt ID',
  },
  updateItScopePrice: {
    success: 'Preis von IT Scope erfolgreich abgerufen',
    fail: 'Fehler beim Abrufen der Preises von IT Scope',
  },
  bulkUpdateItScopeMetadata: {
    success: 'IT Scope Produkt IDs erfolgreich aktualisiert',
    fail: 'Fehler beim Aktualisieren der IT Scope Produkt IDs',
  },
  itScopeCheckStock: {
    success: 'Verfügbarkeiten erfolgreich aktualisiert',
    fail: 'Fehler beim Aktualisieren der Verfügbarkeiten',
  },
  itScopeCreateCart: {
    success: 'Warenkorb erfolgreich übermittelt',
    fail: 'Fehler beim Übermitteln des Warenkorbs',
  },
  attachment: {
    success: 'Dateianhang erfolgreich erstellt',
    fail: 'Fehler beim Anfügen der Datei',
    notSupported: 'Dieser Dateityp wird nicht akzeptiert',
  },
  protectionPlanOverview: {
    success: 'Schutzbriefübersicht erfolgreich hochgeladen',
    fail: 'Hochladen fehlgeschlagen',
    notSupported: 'Es werden nur PDF-Dateien für die Übersicht unterstützt',
  },
  updateAttachment: {
    success: 'Anhang erfolgreich aktualisiert',
    fail: 'Fehler beim Aktualisieren des Anhangs',
  },
  updateAlsoApiData: {
    success: 'Daten erfolgreich aktualisiert',
    fail: 'Fehler beim Aktualisieren der Daten',
  },
  alsoProductVariant: {
    create: {
      success: 'Produktvariante erfolgreich angelegt',
      fail: 'Fehler beim Anlegen der Produktvariante',
    },
  },
  enableMails: {
    success: 'E-Mail Versand aktiviert',
    fail: 'Fehler beim Aktivieren des E-Mail Versands',
  },
  suppressMails: {
    success: 'E-Mail Versand deaktiviert',
    fail: 'Fehler beim Deaktivieren des E-Mail Versands',
  },
  sendAutopilotMail: {
    success: 'E-Mail versendet',
    fail: 'Fehler beim Versenden der E-Mail',
  },
  resendMail: {
    success: 'E-Mail erneut versendet',
    fail: 'Fehler beim Versenden der E-Mail',
  },
  save: {
    success: 'Gespeichert',
    fail: 'Fehler beim Speichern',
  },
  createITScopeOrderOrders: {
    success: 'Bestellungen bei ITScope angelegt',
    fail: 'Fehler beim Erzeugen der Bestellungen',
  },
  refreshAcmpTradingModelMargins: {
    success: 'Margen aktualisiert',
    fail: 'Fehler beim Aktualisieren der Margen',
  },
  findITScopeBundle: {
    success: 'IT Scope Bundleartikel aktualisiert',
    fail: 'Fehler beim Aktualisieren des Bundles',
  },
  resetCustomerPassword: {
    success: 'Neues Passwort angefordert',
    fail: 'Fehler beim Anfordern eines neuen Passworts',
  },
  createCustomerCasProject: {
    success: 'Projektpreise angelegt',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  edit_protection_plan_order_lines: {
    success: 'Schutzbrief bearbeitet',
    fail: 'Fehler beim Bearbeiten des Schutzbriefes',
  },
  addProtectionPlan: {
    success: 'Schutzbrief hinzugefügt',
    fail: 'Fehler beim Hinzufügen des Schutzbriefes',
  },
  addProtectionPlanWithTheftProtection: {
    success: 'Schutzbrief mit Diebstahlschutz hinzugefügt',
    fail: 'Fehler beim Hinzufügen des Schutzbriefes',
  },
  genericOrderLine: {
    create: {
      success: 'Erfolgreich hinzugefügt',
      fail: 'Hinzufügen nicht möglich',
    },
  },
  subscriptionsNotYetCreated:
    'Service Subscriptions wurden noch nicht angelegt.',
  duplicateAsOffer: {
    success: 'Bestellung als Angebot dupliziert',
    fail: 'Fehler beim Anlegen von Angebot',
  },
  alsoResellerProduct: {
    create: {
      success: 'Produkt hinzugefügt',
      fail: 'Fehler beim Hinzufügen',
      failSome: 'Es konnten nicht alle Produkte hinzugefügt werden',
      successAll: 'Alle Produkte hinzugefügt',
    },
    delete: {
      success: 'Produkt entfernt',
      fail: 'Fehler beim Entfernen',
    },
  },
  alsoResellerService: {
    create: {
      success: 'Service hinzugefügt',
      fail: 'Fehler beim Hinzufügen',
    },
    delete: {
      success: 'Service entfernt',
      fail: 'Fehler beim Entfernen',
    },
  },
  alsoAcmpServiceBillingItems: {
    save: {
      success: 'Preismapping aktualisiert',
      fail: 'Es ist ein Fehler aufgetreten',
    },
  },
  clearStoreViewCache: {
    success: 'Frontend Cache erfolgreich geleert',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  warmupStoreViewCache: {
    success: 'Frontend Cache erfolgreich aktualisiert',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  activateStoreViewCache: {
    success: 'Frontend Cache erfolgreich aktiviert',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  deactivateStoreViewCache: {
    success: 'Frontend Cache erfolgreich deaktiviert',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  assignCustomer: {
    assigning: 'Kunde wird zugewiesen',
    success: 'Kunde zugewiesen',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  assignNewCustomer: {
    success: 'Kundeninformationen entfernt',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  add_device_order_lines: {
    success: 'Arbeitsplatz hinzugefügt',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  add_generic_order_lines: {
    success: 'Position hinzugefügt',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  add_product_order_lines: {
    success: 'Zubehör hinzugefügt',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  add_service_order_lines: {
    success: 'Service hinzugefügt',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  add_service_extension_order_lines: {
    success: 'Garantieerweiterung hinzugefügt',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  add_promo_bundle_order_lines: {
    success: 'Bundle hinzugefügt',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  edit_device_order_lines: {
    success: 'Arbeitsplatz geändert',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  edit_generic_order_lines: {
    success: 'Position geändert',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  edit_product_order_lines: {
    success: 'Zubehör geändert',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  edit_service_order_lines: {
    success: 'Service geändert',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  edit_service_extension_order_lines: {
    success: 'Garantieerweiterung geändert',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  updatePriceAndStock: {
    pending: 'Preis & Verfügbarkeit wird aktualisiert',
    success: 'Preis & Verfügbarkeit aktualisiert',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  updateStock: {
    pending: 'Verfügbarkeit wird aktualisiert',
    success: 'Verfügbarkeit aktualisiert',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  updatePrice: {
    pending: 'Preis wird aktualisiert',
    success: 'Preis aktualisiert',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  updatePrices: {
    success: 'Preise aktualisiert',
    fail: 'Es ist ein Fehler aufgetreten',
  },
  transitions: {
    success: {
      create_offer: 'Angebot erzeugt',
      reject_offer: 'Angebot abgelehnt',
      open_buy: 'Kaufauftrag geöffnet',
      open_rent: 'Mietauftrag geöffnet',
      open: 'Auftrag geöffnet',
      change: 'Auftrag geändert',
      create: 'Vertrag erstellt',
      review: 'Bonität wird geprüft',
      cancel: 'Auftrag storniert',
      approve: 'Auftrag genehmigt',
      reject: 'Auftrag abgelehnt',
      customer_sign: 'Kunden-Unterschrift bestätigt',
      reseller_sign: 'Wiederverkäufer-Unterschrift bestätigt',
      order: 'Als bestellt markiert',
      confirm_order: 'Bestellung bestätigt',
      ship: 'Versand bestätigt',
      deliver: 'Zustellung bestätigt',
      confirm: 'Übernahmebestätigung geprüft',
      complete: 'Bestellung abgeschlossen',
      refuse: 'Bestellung verweigert',
      invoice: 'In Rechnung gestellt',
      pay: 'Als bezahlt markiert',
    },
    fail: 'Fehler beim Prozessieren der Bestellung',
  },
  copiedToClipboard: {
    success: 'In Zwischenablage kopiert',
    fail: 'Kopieren in Zwischenablage nicht möglich',
  },
  alsoCreateProductVariant: {
    success: 'Hinzugefügt',
    fail: 'Es ist ein technischer Fehler aufgetreten',
  },
  alsoCreateEdiReseller: {
    success: 'Konto erfolgreich erstellt',
    fail: 'Es ist ein technischer Fehler aufgetreten',
  },
  updateCNetMetaData: {
    success: 'Meta-Daten von CNet erfolgreich ausgelesen',
    fail: 'Es ist ein technischer Fehler aufgetreten',
  },
  assign: {
    success: 'Erfolgreich zugewiesen',
    fail: 'Es ist ein technischer Fehler aufgetreten',
  },
  unassign: {
    success: 'Zuweisung erfolgreich entfernt',
    fail: 'Es ist ein technischer Fehler aufgetreten',
  },
  addOfferLine: {
    success: 'Hinzugefügt',
    fail: 'Es ist ein technischer Fehler aufgetreten',
  },
  alsoUpdateResellableProduct: {
    success: 'Aktualisiert',
    fail: 'Es ist ein technischer Fehler aufgetreten',
  },
  bulkCreateDeviceVariants: {
    success: 'Produktvarianten angelegt.',
    fail: 'Es ist ein technischer Fehler aufgetreten',
  },
  ordersSetAddressSet: {
    success: 'Liefer- & Kontaktaddresse ausgewählt.',
    fail: 'Es ist ein technischer Fehler aufgetreten',
  },
  markAddressSetDefault: {
    success: 'Standardadresse gesetzt.',
    fail: 'Es ist ein technischer Fehler aufgetreten',
  },
  errors: {
    'Credit Group not set': 'Finanzierungsgruppe ist nicht gesetzt',
  },
  detectDLLMetaData: {
    success: 'DLL-Metadaten erfolgreich ermittelt',
    fail: 'Es ist ein Fehler aufgetreten',
  },
}
