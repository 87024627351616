import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { getLinkFromIri } from 'Lib/helper'
import BackButton from 'Components/Common/Buttons/BackButton'

const BackToReferenceButton = ({ reference, data }) => {
  const ref = reference || get(data, 'reference[@id]') || get(data, 'reference')
  return <BackButton to={getLinkFromIri(ref)} />
}

BackToReferenceButton.propTypes = {
  reference: PropTypes.string,
}

export default BackToReferenceButton
