import React from 'react'
import PropTypes from 'prop-types'
import {
  useTranslate,
  useUpdateLoading,
  useNotify,
  useRefresh,
  useLoading,
  useMutation,
} from 'react-admin'
import Button from 'Components/Common/Buttons/Button'

const GenericRestClientButton = ({
  type,
  variant,
  label,
  onClick,
  children,
  color,
  disabled,
  action,
  resource,
  payload,
  noLabel,
  icon,
  ...rest
}) => {
  const { startLoading, stopLoading } = useUpdateLoading()
  const showNotification = useNotify()
  const translate = useTranslate()
  const refreshView = useRefresh()
  const loading = useLoading()
  const [mutate] = useMutation()

  const clickAction = async () => {
    if (onClick) {
      await onClick()
    } else {
      return new Promise((resolve, reject) => {
        mutate(
          { type: action, resource, payload },
          {
            onSuccess: () => {
              resolve()
            },
            onFailure: error => {
              reject(error)
            },
          },
        )
      })
    }
  }

  const handleClick = async () => {
    try {
      startLoading()
      await clickAction()
      showNotification(`notifications.${type}.success`)
      refreshView()
    } catch (error) {
      showNotification(`notifications.${type}.fail`, 'warning')
    }
    stopLoading()
  }

  return (
    <Button
      color={color}
      variant={variant}
      onClick={handleClick}
      disabled={disabled || loading}
      label={!noLabel && (children || translate(label || `actions.${type}`))}
      {...rest}
    >
      {icon}
    </Button>
  )
}

GenericRestClientButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string.isRequired,
  variant: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  icon: PropTypes.node,
  color: PropTypes.string,
  action: PropTypes.string,
  resource: PropTypes.string,
  payload: PropTypes.object,
  noLabel: PropTypes.bool,
}

GenericRestClientButton.defaultProps = {
  color: 'primary',
  variant: 'text',
  label: null,
  onClick: null,
  disabled: false,
  children: null,
  icon: null,
  action: null,
  resource: null,
  payload: null,
  noLabel: false,
}

export default GenericRestClientButton
