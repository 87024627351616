export default {
  title: 'CAS Projektpreise anlegen',
  content: '.',
  form: {
    casNumber: 'CAS-Nummer',
    expiresAt: 'Gültig bis',
    ncabExpiresAt: 'NCAB gültig bis',
  },
  table: {
    header: {
      sku: 'SKU',
      dealReg: 'Deal Reg',
      acceleratePricing: 'Accelerate Pricing',
      ncab: 'NCAB',
      productName: 'Produktname',
    },
  },
  validate: {
    missingCas: 'Bitte tragen Sie zunächst eine CAS-Nummer ein',
    noDeviceSet: 'Sie haben kein passendes Produkt gewählt',
    discountsTooLow: 'Deal Reg und Accelerate Pricing jeweils 0%',
    missingExpiresAt: 'Eintrag nur mit Gültigkeitsdatum möglich',
    missingNcabExpiresAt:
      'Eintrag für einen NCAB-Rabatt nur mit NCAB-Gültigkeitsdatum möglich',
    deviceDuplicate: 'Gewähltes Produkt existiert bereits mit einem Rabatt',
  },
}
