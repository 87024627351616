import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
} from '@material-ui/core'
import { required, TextInput, useNotify, useTranslate } from 'react-admin'
import { useHistory, useParams } from 'react-router'
import { Form } from 'react-final-form'
import DateInput from 'Components/Common/InputFields/DateInput'
import arrayMutators from 'final-form-arrays'
import Box from '@material-ui/core/Box'
import Button from 'Components/Common/Buttons/Button'
import apiClient from 'Rest/fetchHydra'
import DiscountTable from 'Components/Aluxo/ActionDialogs/CustomerCreateCasProjectDialog/DiscountTable'

const Transition = props => <Slide direction="up" {...props} />

const CreateCustomerCasProjectForm = formProps => {
  const t = useTranslate()
  return (
    <form onSubmit={formProps.handleSubmit}>
      <DialogTitle id="alert-dialog-title">
        {t('dialogs.createCustomerCasProject.title')}
      </DialogTitle>
      <DialogContent>
        <div>
          <TextInput
            source="casNumber"
            validate={required()}
            label="dialogs.createCustomerCasProject.form.casNumber"
          />
        </div>
        <div>
          <DateInput
            source="expiresAt"
            label="dialogs.createCustomerCasProject.form.expiresAt"
            validate={required()}
            options={{ disablePast: true }}
          />
        </div>
        <div>
          <DateInput
            source="ncabExpiresAt"
            label="dialogs.createCustomerCasProject.form.ncabExpiresAt"
            options={{ disablePast: true }}
          />
        </div>
        <Box mt={4}>
          <DiscountTable name="lines" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={formProps.closeDialog}
          color="secondary"
          label={t('actions.cancel')}
        />
        <Button
          color="primary"
          type="submit"
          variant="contained"
          disabled={formProps.invalid}
          loading={formProps.submitting}
          label={t('actions.submit')}
        />
      </DialogActions>
    </form>
  )
}

export const validate = project => {
  const errors = {}

  if (project?.lines?.find(e => e.ncabDiscount > 0 && !project.ncabExpiresAt)) {
    errors.ncabExpiresAt =
      'dialogs.createCustomerCasProject.validate.missingNcabExpiresAt'
  }

  return errors
}

const AluxoActionDialogsBulkCreateDeviceVariantsDialog = () => {
  const history = useHistory()
  const showNotification = useNotify()
  const { customerId } = useParams()

  const closeDialog = () => {
    history.push(`/customers/${customerId}`)
  }

  const handleSubmit = async values => {
    try {
      await apiClient({
        endpoint: '/aluxo/create_customer_cas_project',
        method: 'POST',
        body: { ...values, customer: customerId },
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })

      showNotification('notifications.createCustomerCasProject.success')
      closeDialog()
    } catch (e) {
      console.log(e)
      showNotification('notifications.createCustomerCasProject.fail', 'warning')
    }
  }

  return (
    <Dialog fullWidth maxWidth={false} open TransitionComponent={Transition}>
      <Form
        onSubmit={handleSubmit}
        component={CreateCustomerCasProjectForm}
        closeDialog={closeDialog}
        keepDirtyOnReinitialize
        validate={validate}
        mutators={{ ...arrayMutators }}
        // initialValues={{
        //   casNumber: 'TEst-123',
        //   expiresAt: '2021-01-10',
        //   lines: [
        //     {
        //       sku: 'TNU-00005',
        //       productName: 'Surface Laptop Go (i5 / 8 GB / 128 GB)',
        //       ncabDiscount: 0,
        //       dealRegDiscount: 55,
        //       acceleratePricingDiscount: null,
        //     },
        //     {
        //       sku: 'HNS-00004',
        //       productName: 'Surface Laptop Go (i5 / 8 GB / 128 GB)',
        //       ncabDiscount: 0,
        //       dealRegDiscount: 0,
        //       acceleratePricingDiscount: 10,
        //     },
        //   ],
        // }}
      />
    </Dialog>
  )
}

export default AluxoActionDialogsBulkCreateDeviceVariantsDialog
