import React from 'react'
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  ImageField,
  List,
  SimpleForm,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin'
import get from 'lodash/get'
import ImageInput from 'Components/Common/InputFields/ImageInput'
import EditToolbar from 'Components/Common/EditToolbar'
import EditActions from 'Components/Common/EditActions'
import ReferenceInput from 'Components/Common/ReferenceInput'
import { validateRequired } from 'Resources/Aluxo/devices'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

const MarketingLogoFilter = props => (
  <Filter {...props}>
    <TextInput source="name" />
  </Filter>
)

export const MarketingLogoList = props => (
  <List
    {...props}
    pagination={<CustomPagination />}
    filters={<MarketingLogoFilter />}
  >
    <Datagrid>
      <TextField source="name" />
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <TextField source="storeView.name" />
      )}
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)

const Title = ({ record }) => get(record, 'name')

export const MarketingLogoEdit = props => (
  <Edit actions={<EditActions />} title={<Title />} {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="name" />
      <ImageField source="image" />
      <ImageInput source="imageFile" maxSize={26214400}>
        <ImageField source="image" title="title" />
      </ImageInput>
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <ReferenceInput
          source="storeView"
          reference="store_views"
          validate={validateRequired}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
    </SimpleForm>
  </Edit>
)

export const MarketingLogoCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ImageField source="image" />
      <ImageInput source="imageFile" maxSize={26214400}>
        <ImageField source="image" title="title" />
      </ImageInput>
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <ReferenceInput
          source="storeView"
          reference="store_views"
          validate={validateRequired}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
    </SimpleForm>
  </Create>
)
