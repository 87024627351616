import React from 'react'
import PropTypes from 'prop-types'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  List,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'
import { get } from 'lodash'

import EditActions from 'Components/Common/EditActions'
import EditToolbar from 'Components/Common/EditToolbar'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import PercentageField from 'Components/Common/ViewFields/PercentageField'
import NumberWithUnitInput from 'Components/Common/InputFields/NumberWithUnitInput'
import TinyMCEInput from 'Components/Common/InputFields/TinyMCEInput'
import { validateRequired } from './devices'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

const OnlineServiceFilter = props => (
  <Filter {...props}>
    <TextInput source="sku" />
    <TextInput source="name" />
    <TextInput source="manufacturer" />
    <TextInput source="type" />
    <NumberWithUnitInput source="price[lt]" label="filters.monthlyPrice.lt" />
    <NumberWithUnitInput source="price[gt]" label="filters.monthlyPrice.gt" />
    <BooleanInput
      options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
      source="active"
    />
  </Filter>
)

export const OnlineServiceList = props => (
  <List
    {...props}
    filters={<OnlineServiceFilter />}
    filter={{ managed: props.resource === 'managed_services' }}
    pagination={<CustomPagination />}
    aside={<LanguageSwitcherAside />}
  >
    <Datagrid>
      <TextField source="sku" />
      <TextField source="name" />
      <TextField source="manufacturer" />
      <TextField source="type" />
      <CurrencyField source="price" />
      <PercentageField source="margin" />
      <BooleanField source="active" />
      <EditButton />
    </Datagrid>
  </List>
)

OnlineServiceList.propTypes = {
  resource: PropTypes.string.isRequired,
}

const defaultValues = props => ({
  active: true,
  type: 'Office Lizenz',
  price: 0,
  margin: 0,
  managed: props.resource === 'managed_services',
})

const Title = ({ record }) => get(record, 'name')

export const OnlineServiceEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<Title />}
    aside={<LanguageSwitcherAside />}
    {...props}
  >
    <SimpleForm
      toolbar={<EditToolbar hasDelete />}
      redirect={`/${props.resource}`}
    >
      <TextInput source="sku" />
      <TextInput source="name" validate={validateRequired} />
      <TextInput source="manufacturer" validate={validateRequired} />
      <TextInput source="type" validate={validateRequired} />
      <TinyMCEInput source="description" validate={validateRequired} />
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="managed"
        validate={validateRequired}
      />
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="active"
        validate={validateRequired}
      />
      <NumberWithUnitInput source="price" />
      <NumberWithUnitInput source="margin" unit="%" />
    </SimpleForm>
  </Edit>
)

export const OnlineServiceCreate = props => (
  <Create {...props}>
    <SimpleForm defaultValue={defaultValues(props)}>
      <TextInput source="sku" />
      <TextInput source="name" validate={validateRequired} />
      <TextInput source="manufacturer" validate={validateRequired} />
      <TextInput source="type" validate={validateRequired} />
      <TinyMCEInput source="description" validate={validateRequired} />
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="managed"
        validate={validateRequired}
      />
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="active"
        validate={validateRequired}
      />
      <NumberWithUnitInput source="price" />
      <NumberWithUnitInput source="margin" unit="%" />
    </SimpleForm>
  </Create>
)
