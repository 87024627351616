import { CircularProgress, Dialog } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useToggle } from 'react-use'
import { makeStyles } from '@material-ui/core/styles'
import { sortBy, last } from 'lodash'
import { useApiClient } from 'Rest/fetchHydra'

const useImageStyles = makeStyles({
  imageContainer: {
    display: 'inline',
    cursor: 'pointer',
  },
  image: {
    height: '30px',
  },
  largeImage: {
    maxWidth: '100%',
  },
})

const useDialogStyles = makeStyles(theme => ({
  paper: {
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
}))

const PopoverContent = ({ popoverImage, description }) => {
  const classes = useImageStyles()

  if (!popoverImage) {
    return null
  }

  return (
    <div className={classes.backdropContainer}>
      <img
        src={popoverImage.externalUrl}
        className={classes.largeImage}
        alt={description}
      />
    </div>
  )
}

const ImageCell = props => {
  const classes = useImageStyles()
  const dialogStyles = useDialogStyles()

  const [open, setOpen] = useToggle(false)

  const [images, setImages] = React.useState([])
  const [loading, setLoading] = useToggle(false)
  const apiClient = useApiClient()

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      const response = await apiClient({
        endpoint: '/also_resellable_product_cnet_images',
        method: 'GET',
        params: {
          page: 1,
          itemsPerPage: 1,
          product: props.line.resellableProduct,
          '_order[image.cnetImageId]': 'asc',
          '_order[image.width]': 'asc',
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })

      setImages(sortBy(response.data, ['iamge.cnetImageId', 'image.width']))
      setLoading(false)
    }

    fetch()
  }, [])

  const handleClick = () => {
    setOpen()
  }

  if (loading) {
    return <CircularProgress size={25} />
  }

  if (images.length === 0) {
    return null
  }

  const image = images[0]

  const sorted = sortBy(image.image.sizedImages, 'width')

  const displayImage = sorted.find(i => i.width > 100)

  const popoverImage = last(sorted)

  return (
    <>
      <div
        className={classes.imageContainer}
        onClick={handleClick}
        onKeyPress={handleClick}
        tabIndex={0}
        role="button"
      >
        <img
          className={classes.image}
          alt={image.image.description}
          src={displayImage.externalUrl}
        />
      </div>
      <Dialog
        onClose={() => setOpen(false)}
        open={open}
        fullWidth
        classes={dialogStyles}
      >
        <PopoverContent
          popoverImage={popoverImage}
          description={image.image.description}
        />
      </Dialog>
    </>
  )
}

export default ImageCell
