import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { Login } from 'react-admin'
import PasswordRequestForm from './PasswordRequestForm'

const useStyles = makeStyles({
  main: {
    backgroundPosition: 'center',
  },
})

const backgroundImage =
  process.env.REACT_APP_WORKPLACE_TYPE === 'also' ? 'also365' : 'aluxo365'

const PasswordRequest = () => {
  const classes = useStyles()
  return (
    <Login classes={classes} backgroundImage={`/${backgroundImage}.jpg`}>
      <PasswordRequestForm />
    </Login>
  )
}

export default PasswordRequest
