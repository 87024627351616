import Button from 'Components/Common/Buttons/Button'
import PropTypes from 'prop-types'
import React from 'react'
import {
  useLoading,
  useMutation,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'

const AlsoActionButtonsUpdateResellableProductButton = ({ label, data }) => {
  const showNotification = useNotify()
  const refreshView = useRefresh()
  const translate = useTranslate()
  const disabled = useLoading()

  const [mutate] = useMutation()

  const handleClick = async () => {
    try {
      await new Promise((resolve, reject) => {
        mutate(
          {
            type: 'alsoUpdateResellableProduct',
            resource: 'also_resellable_products',
            payload: { data: { resellableProduct: data['@id'] } },
          },
          {
            onSuccess: () => {
              resolve()
            },
            onFailure: error => {
              reject(error)
            },
          },
        )
      })
      showNotification('notifications.alsoUpdateResellableProduct.success')
      refreshView()
    } catch (error) {
      console.log(error)
      showNotification(
        'notifications.alsoUpdateResellableProduct.fail',
        'warning',
      )
    }
  }

  return (
    <Button
      color="primary"
      onClick={handleClick}
      disabled={disabled}
      label={translate(label)}
    />
  )
}

AlsoActionButtonsUpdateResellableProductButton.propTypes = {
  label: PropTypes.string,
}

AlsoActionButtonsUpdateResellableProductButton.defaultProps = {
  label: 'actions.alsoUpdateResellableProduct',
}

export default AlsoActionButtonsUpdateResellableProductButton
