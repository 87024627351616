import {
  AutocompleteInput,
  BooleanInput,
  Create,
  FormDataConsumer,
  FormTab,
  SelectInput,
  TabbedForm,
  TextInput,
  regex,
  required,
} from 'react-admin'

import React from 'react'
import TranslatedTitle from 'Components/Common/TranslatedTitle'
import { isValidIBAN } from 'ibantools'
import legalForms from 'Lib/legalForms'
import { validateRequired } from 'Resources/Aluxo/devices'
import { validateRequiredEmail } from 'Resources/Common/reseller_smtp_configurations'

const validateIbanValidator = () => value =>
  !value || isValidIBAN(value)
    ? undefined
    : 'resources.customers.validation.iban'

export const validateIban = validateIbanValidator()
export const validateRequiredIban = [validateIbanValidator(), required()]
export const validateZipCode = regex(
  /^\d{5}$/,
  'resources.customers.validation.zip',
)
export const validateRequiredZipCode = [required(), validateZipCode]

const CustomerCreate = props => (
  <Create {...props}>
    <TabbedForm
      defaultValue={{ addresses: [{ type: 'billing' }] }}
      redirect="list"
    >
      <FormTab label="ra.auth.useraccount">
        <TextInput source="email" validate={validateRequiredEmail} />
      </FormTab>
      <AddressTab {...props} />
      <FormTab label="resources.customers.fields.bankDetails.name">
        <TextInput
          source="bankDetails.IBAN"
          label="resources.customers.fields.bankDetails.iban"
          validate={validateRequiredIban}
        />
      </FormTab>
      <FormTab label="resources.customers.fields.ceo.name">
        <TextInput
          source="ceo.firstName"
          label="resources.customers.fields.ceo.firstName"
        />
        <TextInput
          source="ceo.lastName"
          label="resources.customers.fields.ceo.lastName"
        />
        <TextInput
          source="ceo.street"
          label="resources.customers.fields.ceo.street"
        />
        <TextInput
          source="ceo.postcode"
          label="resources.customers.fields.ceo.postcode"
          validate={validateZipCode}
        />
        <TextInput
          source="ceo.city"
          label="resources.customers.fields.ceo.city"
        />
        <TextInput
          source="ceo.birthdate"
          label="resources.customers.fields.ceo.birthdate"
        />
      </FormTab>
    </TabbedForm>
  </Create>
)

const AddressTab = props => (
  <FormTab label="Adressen" {...props}>
    <TranslatedTitle
      translateKey="resources.customers.fields.billingAddress.name"
      style={{ marginTop: '20px' }}
    />
    <SelectInput
      source="addresses.0.salutation"
      choices={[
        { id: 'Frau', name: 'Frau' },
        { id: 'Herr', name: 'Herr' },
      ]}
      validate={validateRequired}
      label="resources.customers.fields.addresses.salutation"
    />
    <TextInput
      source="addresses.0.firstName"
      validate={validateRequired}
      label="resources.customers.fields.addresses.firstName"
    />
    <TextInput
      source="addresses.0.lastName"
      validate={validateRequired}
      label="resources.customers.fields.addresses.lastName"
    />
    <AutocompleteInput
      source="addresses.0.legalForm"
      choices={legalForms.map(f => ({ id: f, name: f }))}
      validate={validateRequired}
      label="resources.customers.fields.addresses.legalForm"
    />
    <TextInput
      source="addresses.0.company"
      validate={validateRequired}
      label="resources.customers.fields.addresses.company"
    />
    <TextInput
      source="addresses.0.street"
      validate={validateRequired}
      label="resources.customers.fields.addresses.street"
    />
    <TextInput
      source="addresses.0.postcode"
      validate={validateRequiredZipCode}
      label="resources.customers.fields.addresses.postcode"
    />
    <TextInput
      source="addresses.0.city"
      validate={validateRequired}
      label="resources.customers.fields.addresses.city"
    />
    <TextInput
      source="addresses.0.phone"
      validate={validateRequired}
      label="resources.customers.fields.addresses.phone"
    />
    <TextInput
      source="addresses.0.email"
      label="resources.customers.fields.addresses.email"
    />
    <BooleanInput
      source="shipToBilling"
      label="resources.customers.fields.shipToBilling"
      defaultValue
    />
    <FormDataConsumer>
      {({ formData, ...rest }) =>
        !formData.shipToBilling && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TranslatedTitle
              translateKey="resources.customers.fields.shippingAddress.name"
              style={{ marginTop: '20px' }}
              {...rest}
            />
            <TextInput
              source="addresses.1.firstName"
              validate={validateRequired}
              label="resources.customers.fields.addresses.firstName"
              {...rest}
            />
            <TextInput
              source="addresses.1.lastName"
              validate={validateRequired}
              label="resources.customers.fields.addresses.lastName"
              {...rest}
            />
            <TextInput
              source="addresses.1.company"
              validate={validateRequired}
              label="resources.customers.fields.addresses.company"
              {...rest}
            />
            <TextInput
              source="addresses.1.street"
              validate={validateRequired}
              label="resources.customers.fields.addresses.street"
              {...rest}
            />
            <TextInput
              source="addresses.1.postcode"
              validate={validateRequiredZipCode}
              label="resources.customers.fields.addresses.postcode"
              {...rest}
            />
            <TextInput
              source="addresses.1.city"
              validate={validateRequired}
              label="resources.customers.fields.addresses.city"
              {...rest}
            />
          </div>
        )
      }
    </FormDataConsumer>
  </FormTab>
)

export default CustomerCreate
