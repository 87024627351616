import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { Labeled, useNotify } from 'react-admin'
import { useField } from 'react-final-form'
import { get } from 'lodash'
import { useApiClient } from 'Rest/fetchHydra'
import currencyText from 'Lib/currencyText'
import { LinearProgress } from '@material-ui/core'

const SELECTOR = 'LEASING.ORDER_FINANCE_PARTNER'
const ALBIS_RATES = 'LEASING.ALBIS'
const DLL_RATES = 'LEASING.DLL'

function TotalRateField({ record }) {
  const {
    input: { value: fieldsValue },
  } = useField('fields')

  const value = get(fieldsValue, SELECTOR) || ALBIS_RATES

  const [rate, setRate] = useState(null)

  const showNotification = useNotify()
  const apiClient = useApiClient()

  const updateRate = useCallback(async (order, financePartner) => {
    try {
      setRate(null)
      const res = await apiClient({
        endpoint: '/get_rates_for_order',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: {
          order,
          financePartner,
        },
      })
        .then(res => res.data)
        .catch(error => {
          showNotification(
            `notifications.${error.response?.data?.detail || 'genericError'}`,
            'warning',
          )
          return 0
        })

      setRate(res)
    } catch (e) {
      showNotification(`notifications.genericError`, 'warning')
      setRate(0)
    }
  }, [])

  useEffect(() => {
    updateRate(record.id, value)
  }, [value])

  return (
    <>
      <Labeled label="resources.orders.fields.totalRate">
        {rate !== null ? <span>{currencyText(rate)}</span> : <LinearProgress />}
      </Labeled>
    </>
  )
}

TotalRateField.propTypes = {
  record: PropTypes.object.isRequired,
}

export default function TotalRateFieldWrapper(props) {
  if (props.record.workflow === 'buy_order') {
    return null
  }
  return <TotalRateField {...props} />
}

TotalRateFieldWrapper.propTypes = {
  record: PropTypes.object.isRequired,
}
