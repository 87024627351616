import CheckITScopeStockButton from 'Components/Aluxo/ActionButtons/CheckITScopeStockButton'
import ITScopeOrderButton from 'Components/Aluxo/ITScope/ITScopeOrderButton'
import Button from 'Components/Common/Buttons/Button'
import AddDialog from 'Components/Aluxo/OrderLines/Lines/AddDialog'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import { isReseller } from 'Lib/helper'
import { useOrder } from 'OrderContext'
import React, { useContext, useMemo } from 'react'
import { usePermissions, useTranslate } from 'react-admin'
import { FormControlLabel, Switch } from '@material-ui/core'
import { ResellerContext } from 'ResellerContext'
import AddPromoBundleDialog from 'Components/Aluxo/OrderLines/Lines/PromoBundleLine/AddPromoBundleDialog'
import RefreshPricesButton from 'Components/Aluxo/ActionButtons/RefreshPricesButton'
import { DIALOG_TYPE_CUSTOM, DIALOG_TYPE_EXISTING } from './Lines/AddDialogForm'

const OrderLineActions = ({ mode, handleChangeMode }) => {
  const { record } = useOrder()

  const orderIsEnabled = useMemo(() => {
    if (
      record?.workflow === 'rent_order' &&
      record?.marking === 'reseller_signed'
    ) {
      return true
    }
    if (record?.workflow === 'buy_order' && record?.marking === 'opened') {
      return true
    }
    return false
  }, [record])

  const { flags } = useContext(ResellerContext)
  const { permissions = [] } = usePermissions()
  const t = useTranslate()

  if (!isReseller(permissions)) {
    return null
  }

  return (
    <RightAlignedCardActions>
      <FormControlLabel
        control={
          <Switch
            checked={mode === 'financing'}
            onChange={handleChangeMode}
            name="Finanzierungsansicht"
          />
        }
        label="Finanzierungsansicht"
      />
      {record.itscopeCartId && (
        <Button
          color="primary"
          component="a"
          target="_blank"
          href={`https://www.itscope.com/red/app#deals/portal/${record.itscopeCartId}`}
          label={t('actions.jumpToItScopeCart')}
        />
      )}

      {flags.directITScopeOrder && (
        <ITScopeOrderButton
          data={record}
          label={
            orderIsEnabled
              ? 'actions.createITScopeOrder'
              : 'actions.previewITScopeOrder'
          }
        />
      )}
      <RefreshPricesButton />
      <CheckITScopeStockButton />
      <AddDialog
        reference="product_order_lines"
        dialogProps={{
          supportedTypes: [DIALOG_TYPE_EXISTING, DIALOG_TYPE_CUSTOM],
          withITScope: true,
        }}
      />
      <AddPromoBundleDialog reference="promo_bundle_order_lines" />
      <AddDialog
        reference="device_order_lines"
        dialogProps={{
          supportedTypes: [DIALOG_TYPE_EXISTING, DIALOG_TYPE_CUSTOM],
          withITScope: true,
        }}
      />
    </RightAlignedCardActions>
  )
}

export default OrderLineActions
