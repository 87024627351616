import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { usePermissions } from 'react-admin'
import {
  isAdmin,
  isAffiliateReseller,
  isAlsoAdmin,
  isAlsoReseller,
  isReseller,
  isAlsoEdiReseller,
} from 'Lib/helper'
import AdminMenu from 'Components/Aluxo/Layout/AdminMenu'
import AlsoAdminMenu from 'Components/Also/Layout/AlsoAdminMenu'
import AlsoResellerMenu from 'Components/Also/Layout/AlsoResellerMenu'
import ResellerMenu from 'Components/Aluxo/Layout/ResellerMenu'
import AlsoEdiResellerMenu from 'Components/Also/Layout/AlsoEdiResellerMenu'
import AffiliateResellerMenu from 'Components/Common/Layout/AffiliateResellerMenu'

export const useMenuStyles = makeStyles(theme => ({
  menu: { maxHeight: '36px' },
  listItemIcon: {
    minWidth: '40px',
  },
  listItemText: {
    color: theme.palette.text.secondary,
    paddingLeft: 0,
  },
}))

const Menu = ({ onMenuClick }) => {
  const { permissions = [] } = usePermissions()

  if (isAdmin(permissions)) {
    return <AdminMenu onMenuClick={onMenuClick} />
  }

  if (isReseller(permissions)) {
    return <ResellerMenu onMenuClick={onMenuClick} permissions={permissions} />
  }

  if (isAffiliateReseller(permissions)) {
    return (
      <AffiliateResellerMenu
        onMenuClick={onMenuClick}
        permissions={permissions}
      />
    )
  }

  if (isAlsoAdmin(permissions)) {
    return <AlsoAdminMenu onMenuClick={onMenuClick} />
  }

  if (isAlsoReseller(permissions)) {
    return (
      <AlsoResellerMenu onMenuClick={onMenuClick} permissions={permissions} />
    )
  }

  if (isAlsoEdiReseller(permissions)) {
    return (
      <AlsoEdiResellerMenu
        onMenuClick={onMenuClick}
        /* permissions={permissions} */
      />
    )
  }

  return null
}

export default Menu
