import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas as solidIcons } from '@fortawesome/free-solid-svg-icons'
import { fab as brands } from '@fortawesome/free-brands-svg-icons'
import { Field } from 'react-final-form'
import Select from 'react-select'
import styled from 'styled-components/macro'
import { FixedSizeList as List } from 'react-window'

const allIcons = [...Object.values(solidIcons), ...Object.values(brands)]

const IconName = styled.span`
  margin-left: 1rem;
`

const Container = styled.div`
  width: 600px;
`

const Entry = styled.div`
  display: flex;
  justify-content: space-between;
`

const options = allIcons.map(icon => ({
  value: `${icon.prefix} ${icon.iconName}`,
  label: (
    <Entry>
      <FontAwesomeIcon width={20} icon={icon} />
      <IconName>{icon.iconName}</IconName>
    </Entry>
  ),
}))

const height = 35

const MenuList = ({ options, children, maxHeight, getValue }) => {
  const [value] = getValue()
  const initialOffset = options.indexOf(value) * height

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  )
}

const customStyles = {
  menu: provided => ({
    ...provided,
    zIndex: 2,
  }),
}

const FontawesomeIconInput = ({ source }) => (
  <Container>
    <Field
      name={source}
      component={({ input: { onChange, value } }) => (
        <Select
          styles={customStyles}
          components={{ MenuList }}
          value={options.filter(option => option.value === value)}
          onChange={({ value }) => onChange(value)}
          options={options}
          clearable={false}
        />
      )}
    />
  </Container>
)

FontawesomeIconInput.propTypes = {
  source: PropTypes.string.isRequired,
  addLabel: PropTypes.bool, // eslint-disable-line
}

FontawesomeIconInput.defaultProps = {
  addLabel: true,
}

export default FontawesomeIconInput
