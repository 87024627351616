import EditActions from 'Components/Common/EditActions'
import PropTypes from 'prop-types'
import EditToolbar from 'Components/Common/EditToolbar'
import FontawesomeIconInput from 'Components/Common/FontawesomeIconInput'
import MetaInput from 'Components/Common/InputFields/MetaInput'
import TinyMCEInput from 'Components/Common/InputFields/TinyMCEInput'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import ReferenceInput from 'Components/Common/ReferenceInput'
import get from 'lodash/get'
import React from 'react'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  List,
  NumberField,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'
import { validateRequired } from './devices'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

const CategoryFilter = props => (
  <Filter {...props}>
    <TextInput source="name" />
    <BooleanInput
      options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
      source="active"
    />
  </Filter>
)

export const CategoryList = props => (
  <List
    {...props}
    filters={<CategoryFilter />}
    pagination={<CustomPagination />}
    aside={<LanguageSwitcherAside />}
  >
    <Datagrid>
      <TextField source="name" />
      {props.permissions.includes('ROLE_STOREVIEW') && (
        <TextField source="storeView.name" />
      )}
      <BooleanField source="active" hideFalseValues />
      <NumberField source="position" />
      <EditButton />
    </Datagrid>
  </List>
)

CategoryList.propTypes = {
  permissions: PropTypes.array,
}

CategoryList.defaultProps = {
  permissions: [],
}

const CategoryTitle = ({ record }) => get(record, 'name')

export const CategoryEdit = props => (
  <Edit
    actions={<EditActions />}
    aside={<LanguageSwitcherAside />}
    title={<CategoryTitle />}
    {...props}
  >
    <SimpleForm toolbar={<EditToolbar hasDelete />}>
      <TextInput source="slug" validate={validateRequired} />
      <TextInput source="name" validate={validateRequired} />

      {props.permissions.includes('ROLE_STOREVIEW') && (
        <ReferenceInput
          source="storeView"
          reference="store_views"
          validate={validateRequired}
        >
          <SelectInput optionText={choice => choice.name} />
        </ReferenceInput>
      )}
      <FontawesomeIconInput
        source="iconString"
        validate={validateRequired}
        style={{ width: '5em' }}
      />
      <NumberInput source="position" validate={validateRequired} />
      <TinyMCEInput source="description" />
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="active"
      />

      <MetaInput />
    </SimpleForm>
  </Edit>
)

CategoryEdit.propTypes = {
  permissions: PropTypes.array,
}

CategoryEdit.defaultProps = {
  permissions: [],
}

const defaultValues = {
  active: true,
}

export const CategoryCreate = props => (
  <Create {...props}>
    <SimpleForm defaultValue={defaultValues}>
      <TextInput source="name" validate={validateRequired} />

      <TinyMCEInput source="description" />
      <FontawesomeIconInput
        source="iconString"
        validate={validateRequired}
        style={{ width: '5em' }}
      />
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="active"
      />
      {props.permissions.includes('ROLE_STOREVIEW') && (
        <ReferenceInput
          source="storeView"
          reference="store_views"
          validate={validateRequired}
        >
          <SelectInput optionText={choice => choice.name} />
        </ReferenceInput>
      )}
    </SimpleForm>
  </Create>
)

CategoryCreate.propTypes = {
  permissions: PropTypes.array,
}

CategoryCreate.defaultProps = {
  permissions: [],
}
