import numbro from 'numbro'
import de from 'numbro/languages/de-DE'

numbro.registerLanguage(de)
numbro.setLanguage('de-DE')

export default value =>
  `${numbro(value).format({
    output: 'percent',
    mantissa: 2,
    spaceSeparated: true,
  })}`
