import { LinearProgress } from '@material-ui/core'
import OrderLines from 'Components/Aluxo/OrderLines/OrderLines'
import React, { useContext } from 'react'
import { DataProviderContext } from 'react-admin'
import { Form } from 'react-final-form'
import { useOrder } from 'OrderContext'
import { useQueries } from 'react-query'

export const orderLineReferences = {
  DeviceOrderLine: 'device_order_lines',
  ProductOrderLine: 'product_order_lines',
  ServiceOrderLine: 'service_order_lines',
  ServiceExtensionOrderLine: 'service_extension_order_lines',
  ProtectionPlanOrderLine: 'protection_plan_order_lines',
  GenericOrderLine: 'generic_order_lines',
  PromoBundleOrderLine: 'promo_bundle_order_lines',
}

const OrderLinesContainer = ({ mode }) => {
  const dataProvider = useContext(DataProviderContext)

  const { record } = useOrder()

  const orderLinesQueries = useQueries(
    Object.values(orderLineReferences).map(reference => ({
      queryKey: [reference, record.id],
      queryFn: async () => {
        const res = await dataProvider.getManyReference(reference, {
          target: 'order',
          id: record.id,
          pagination: false,
          sort: false,
          filter: false,
        })

        if (!res) {
          return { data: [], reference }
        }
        const data = res.data.reduce(
          (acc, item) => ({
            ...acc,
            [item.id]: item,
          }),
          {},
        )

        return { ...res, data, reference }
      },
    })),
  )

  const loading = orderLinesQueries.some(q => q.isLoading)

  if (loading) {
    return <LinearProgress style={{ marginTop: '1em' }} />
  }

  const lines = orderLinesQueries.reduce(
    (acc, query) => ({
      ...acc,
      [query.data.reference]: query.data,
    }),
    {},
  )

  return (
    <Form
      onSubmit={values => console.log(values)}
      initialValues={lines}
      component={OrderLines}
      mode={mode}
    />
  )
}

export default OrderLinesContainer
