import React, { useContext } from 'react'
import Button from 'Components/Common/Buttons/Button'
import { Link } from 'react-router-dom'
import { useTranslate } from 'react-admin'
import { ResellerContext } from 'ResellerContext'

const AluxoActionDialogsBulkCreateDeviceVariantsDialog = () => {
  const { flags } = useContext(ResellerContext)

  const translate = useTranslate()

  if (!flags.massCreateDeviceVariants) {
    return null
  }

  return (
    <Button
      component={Link}
      to="/devices/create/bulk"
      color="primary"
      disableOnGlobalLoad
      label={translate('actions.massCreateDeviceVariants')}
    />
  )
}

export default AluxoActionDialogsBulkCreateDeviceVariantsDialog
