import { red } from '@material-ui/core/colors'
import { filter, get, groupBy, head, map } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'
import StatusBar from './StatusBar'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-gap: 1em;
`

const getColor = ({ marking, overdue }, statusBarIndex, markings) => {
  if (marking === 'cancelled') {
    return 'rgba(0, 0, 0, 0.54)' // TODO: with theme
  }
  const currentIndex = get(markings, `${marking}.statusBarIndex`)
  if (statusBarIndex < currentIndex) {
    return false
  }
  if (overdue) {
    return red[500]
  }
  return false
}

const getMarking = (currentMarking, markings) => {
  if (currentMarking === 'cancelled') {
    return null
  }
  const marking = markings.find(({ key }) => currentMarking === key)

  return marking ? marking.key : head(markings.map(({ key }) => key))
}

const StatusBars = ({
  markings,
  record,
  primaryColor,
  currentStatusBarIndex,
  orderType,
}) => (
  <Container>
    {map(
      groupBy(
        filter(markings, ({ statusBarIndex }) => statusBarIndex !== undefined),
        'statusBarIndex',
      ),
      (markings, statusBarIndex) => (
        <StatusBar
          key={statusBarIndex}
          currentStatusBarIndex={currentStatusBarIndex}
          highlighted={
            record.marking === 'cancelled' ||
            statusBarIndex <= currentStatusBarIndex
          }
          marking={getMarking(record.marking, markings)}
          orderType={orderType}
          color={getColor(record, statusBarIndex, markings) || primaryColor}
        />
      ),
    )}
  </Container>
)

StatusBars.propTypes = {
  record: PropTypes.object, // eslint-disable-line
  markings: PropTypes.array.isRequired, // eslint-disable-line
  primaryColor: PropTypes.string.isRequired,
  orderType: PropTypes.string.isRequired,
  currentStatusBarIndex: PropTypes.number.isRequired,
}

export default StatusBars
