import React from 'react'
import { ExportButton, RefreshButton, TopToolbar } from 'react-admin'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

const OrderListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  history,
  selectedIds,
  showFilter,
  total,
}) => (
  <TopToolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <RefreshButton />
  </TopToolbar>
)

export default compose(withRouter)(OrderListActions)
