import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslate } from 'react-admin'

const useStyles = makeStyles(({ palette }) => ({
  statusBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '0.7em',
    color: palette.action.active,
    textTransform: 'uppercase',
  },
  bar: {
    border: `2px solid ${palette.action.disabledBackground}`,
    width: '100%',
    marginBottom: '1em',
  },
}))

const StatusBar = ({ orderType, marking, color, highlighted }) => {
  const classes = useStyles()
  const t = useTranslate()
  return (
    <div className={classes.statusBar}>
      <div
        className={classes.bar}
        style={{ borderColor: highlighted && color }}
      />
      {highlighted && marking && t(`${orderType}.markingsShort.${marking}`)}
    </div>
  )
}

StatusBar.propTypes = {
  marking: PropTypes.string.isRequired,
  orderType: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  highlighted: PropTypes.bool.isRequired,
}

export default StatusBar
