import React from 'react'
import {
  BooleanInput,
  Edit,
  Labeled,
  SimpleForm,
  TextInput,
  useTranslate,
  NumberInput,
} from 'react-admin'
import EditActions from 'Components/Common/EditActions'
import { validateRequired } from 'Resources/Aluxo/devices'
import get from 'lodash/get'
import CKEInput from 'Components/Common/InputFields/CKEInput'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useFieldArray } from 'react-final-form-arrays'
import { useField } from 'react-final-form'
import NumberWithUnitTableInput from 'Components/Common/InputFields/TableInputs/NumberWithUnitTableInput'
import Button from 'Components/Common/Buttons/Button'
import Add from '@material-ui/icons/Add'

const ServiceTitle = ({ record }) => get(record, 'name')

const BillingItemRow = props => {
  const {
    input: { value: values, onChange },
  } = useField(`${props.name}`)

  const t = useTranslate()
  const handleChange = ({ target: { value, name } }) => {
    onChange({ ...values, [name]: value || null })
  }

  return (
    <TableRow>
      <TableCell>
        <FormControl>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="chargeType"
            displayEmpty
            value={values.chargeType || ''}
            onChange={handleChange}
          >
            <MenuItem value="monthly">
              {t('alsoResellerCustomServices.chargeTypes.monthly')}
            </MenuItem>
            <MenuItem value="totalDuration">
              {t('alsoResellerCustomServices.chargeTypes.totalDuration')}
            </MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <FormControl>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="dependentOn"
            displayEmpty
            value={values.dependentOn || ''}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>{t('alsoResellerCustomServices.dependentOn.fixed')}</em>
            </MenuItem>
            <MenuItem value="workplaces">
              {t('alsoResellerCustomServices.dependentOn.workplaces')}
            </MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <NumberWithUnitTableInput name={`${props.name}.price`} unit="€" />
      </TableCell>
    </TableRow>
  )
}

const BillingItems = () => {
  const t = useTranslate()

  const { fields } = useFieldArray('prices')

  return (
    <Labeled label="alsoResellerCustomServices.labels.prices">
      <>
        <Table size="small" stripedRows fixedHeader={false} fixedFooter={false}>
          <TableHead displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableCell>
                {t('alsoResellerCustomServices.labels.chargeType')}
              </TableCell>
              <TableCell>
                {t('alsoResellerCustomServices.labels.dependentOn')}
              </TableCell>{' '}
              <TableCell>
                {t('alsoResellerCustomServices.labels.price')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody displayRowCheckbox={false}>
            {fields.map(name => (
              <BillingItemRow name={name} key={name} />
            ))}
          </TableBody>
        </Table>
        <Button
          onClick={() => fields.push({ chargeType: 'monthly' })}
          label="actions.addPrice"
        >
          <Add />
        </Button>
      </>
    </Labeled>
  )
}

const AlsoResellerCustomServiceEdit = props => (
  <Edit actions={<EditActions />} title={<ServiceTitle />} {...props}>
    <SimpleForm redirect={false}>
      <TextInput source="name" validate={validateRequired} />
      <TextInput source="manufacturer" validate={validateRequired} />
      <TextInput source="category" validate={validateRequired} />
      <NumberInput source="position" validate={validateRequired} />
      <CKEInput source="description" fullWidth />
      <BooleanInput source="active" />
      <BillingItems />
    </SimpleForm>
  </Edit>
)

export default AlsoResellerCustomServiceEdit
