import { max, round, sortBy } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useApiClient } from 'Rest/fetchHydra'
import { useQuery, useQueryClient } from 'react-query'
import { useDebounce } from 'use-debounce'
import PropTypes from 'prop-types'
import { useGetCancelToken } from 'Hooks/useCanceableQuery'
import { gql } from '@urql/core'
import { useClient } from 'urql'

const GET_DEVICE_VARIANTS_QUERY = gql`
  query deviceVariants($search: String, $storeView: String) {
    deviceVariants(first: 1000, search: $search, storeView: $storeView) {
      edges {
        node {
          id
          name
          sku
          price
          puid
          stock
          margin
          device {
            id
            name
            creditGroup
            manufacturer {
              name
            }
          }
        }
      }
    }
  }
`

const getMaxStock = prices => max(prices.map(p => p.stock))

const FetchData = ({ children, includeItScope, searchValue, storeView }) => {
  const apiClient = useApiClient()
  const getCancelToken = useGetCancelToken()
  const queryClient = useQueryClient()

  const client = useClient()

  useEffect(() => {
    if (!includeItScope) {
      queryClient.cancelQueries(['it_scope_products'])
    }
  }, [includeItScope])

  const [searchQuery] = useDebounce(searchValue, 400, {
    leading: false,
  })

  const queriesActive = searchQuery?.length > 2

  const deviceVariantQuery = useQuery(
    ['device_variants', searchQuery, storeView],
    () =>
      client
        .query(
          GET_DEVICE_VARIANTS_QUERY,
          {
            search: searchQuery,
            storeView,
          },
          { requestPolicy: 'network-only' },
        )
        .toPromise()
        .then(res => res.data.deviceVariants.edges.map(e => e.node)),
    {
      enabled: queriesActive,
      keepPreviousData: true,
      initialData: [],
    },
  )

  const itScopeProductQuery = useQuery(
    ['it_scope_products', searchQuery],
    () => {
      const [cancelToken, cancel] = getCancelToken()
      const promise = apiClient({
        endpoint: `/it_scope_products?keywords=${searchQuery}`,
        cancelToken,
      }).then(res => res.data)

      promise.cancel = cancel
      return promise
    },
    {
      enabled: Boolean(includeItScope) && queriesActive,
      keepPreviousData: true,
      initialData: [],
    },
  )

  const itScopeProducts = useMemo(() => {
    if (!includeItScope) {
      return []
    }
    const filtered = itScopeProductQuery.data.filter(
      ({ prices }) => prices.length > 0,
    )
    const sorted = sortBy(filtered, 'manufacturerSKU')

    return sorted
  }, [includeItScope, itScopeProductQuery.data])

  const loading =
    deviceVariantQuery.isFetching || itScopeProductQuery.isFetching

  const deviceVariants = deviceVariantQuery.data

  const itScopeData = itScopeProducts.map(p => ({
    ...p,
    price: round(p.averagePrice, 2),
    stock: getMaxStock(p.prices),
  }))

  return children({ deviceVariants, itScopeData, loading })
}

FetchData.propTypes = {
  includeITScope: PropTypes.boolean,
}

FetchData.defaultProps = {
  includeITScope: false,
}

export default FetchData
