import {
  AutocompleteInput,
  CardContentInner,
  FormInput,
  maxLength,
  RadioButtonGroupInput,
  required,
  SelectInput,
  TextInput,
  usePermissions,
  useTranslate,
} from 'react-admin'
import { flagTypes, manualTransitions, markings } from 'Lib/aluxo/markings'

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Box,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { validateIban } from 'Components/Aluxo/Customers/CustomerCreate'
import OrderLinesEdit from 'Components/Aluxo/OrderLines/OrderLinesEdit'
import ProcessOrderActions from 'Components/Common/Actions/Order/ProcessOrderActions'
import DateInput from 'Components/Common/InputFields/DateInput'
import OrderAdditionalInfo from 'Components/Common/Orders/OrderAdditionalInfo'
import OrderITScopeInfo from 'Components/Common/Orders/OrderITScopeInfo'
import OrderAttachments from 'Components/Common/Orders/OrderAttachments'
import OrderDiscountList from 'Components/Common/Orders/OrderDiscountList'
import OrderEditActions from 'Components/Common/Orders/OrderEditActions'
import OrderEditToolbar from 'Components/Common/Orders/OrderEditToolbar'
import OrderExtra from 'Components/Common/Orders/OrderExtra'
import OrderFlagsShow from 'Components/Common/Orders/OrderFlagsShow'
import OrderGeneralEdit from 'Components/Common/Orders/OrderGeneralEdit'
import OrderReporting from 'Components/Common/Orders/OrderReporting'
import OrderShipments from 'Components/Common/Orders/OrderShipments'
import StatusBarsContainer from 'Components/Common/Orders/StatusBars/StatusBarsContainer'
import TranslatedTitle from 'Components/Common/TranslatedTitle'
import { isReseller } from 'Lib/helper'
import legalForms from 'Lib/legalForms'
import { get } from 'lodash'
import moment from 'moment'
import { useOrder } from 'OrderContext'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import { ResellerContext } from 'ResellerContext'
import Button from 'Components/Common/Buttons/Button'
import { useField } from 'react-final-form'
import OrderInputField from 'Components/Common/InputFields/OrderInputField'
import ChooseAddressSetDialog from './ChooseAddressSetDialog'

moment.locale('de')

const Grid = styled.div`
  display: grid;
  grid-template: auto auto 1fr / 1fr 1fr;
  grid-gap: 1em;

  grid-template-areas:
    'status status'
    'general  actions'
    'general  flags'
    'billing shipping'
    'fullWidth fullWidth';
`

export const useOrderEditStyles = makeStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    padding: 24,
    flexGrow: 1,
  },
  cardActions: {
    padding: 0,
    '& > div:first-child': {
      width: '100%',
    },
  },
  fullWidthCard: {
    flex: '1 1 auto',
    gridColumn: '1 / -1',
  },
})

export const Inputs = ({ children, basePath, record, resource }) =>
  React.Children.map(children, input => (
    <FormInput
      basePath={basePath}
      input={input}
      record={record}
      resource={resource}
    />
  ))

const CopyBillingToShippingAddressButton = () => {
  const t = useTranslate()
  const {
    input: {
      value: {
        salutation,
        firstName,
        lastName,
        company,
        street,
        postcode,
        city,
      },
    },
  } = useField('billingAddress')
  const {
    input: { onChange },
  } = useField('shippingAddress')

  const handleClick = () =>
    onChange({
      salutation,
      firstName,
      lastName,
      company,
      street,
      postcode,
      city,
    })
  return (
    <Button
      label={t('actions.copyBillingToShipping')}
      type="button"
      onClick={handleClick}
    />
  )
}

const GridFormView = ({
  invalid,
  pristine,
  handleSubmit,
  submitting,
  mode,
  setMode,
}) => {
  const classes = useOrderEditStyles()

  const { resource, basePath, record } = useOrder()

  const { flags } = useContext(ResellerContext)

  const { permissions = [] } = usePermissions()

  const Toolbar = React.cloneElement(<OrderEditToolbar />, {
    basePath,
    handleSubmitWithRedirect: handleSubmit,
    handleSubmit,
    invalid,
    pristine,
    record,
    redirect: false,
    resource,
    saving: submitting,
    submitOnEnter: false,
    undoable: true,
    permitted: isReseller(permissions),
  })

  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <div style={{ gridArea: 'status', minHeight: '20px' }}>
          <StatusBarsContainer
            record={record}
            markings={markings[record.workflow]}
            orderType="orders"
          />
        </div>
        <Card
          className={classes.card} // eslint-disable-line react/prop-types
          style={{
            gridArea: 'general',
          }}
        >
          <CardHeader
            action={<OrderEditActions customer={record.customer} />}
          />

          <CardContent
            className={classes.cardContent} // eslint-disable-line react/prop-types
          >
            <OrderGeneralEdit />
          </CardContent>
          <CardActions disableSpacing className={classes.cardActions}>
            {Toolbar}
          </CardActions>
        </Card>
        {isReseller(permissions) && (
          <Card
            className={classes.card}
            style={{
              gridArea: 'actions',
            }}
          >
            <CardContent className={classes.cardContent}>
              <TranslatedTitle translateKey="resources.orders.tabs.process" />
              <ProcessOrderActions
                orderType="orders"
                manualTransitions={manualTransitions}
                permitted={isReseller(permissions)}
              />
            </CardContent>
          </Card>
        )}
        <div
          style={{
            gridArea: 'flags',
          }}
        >
          <OrderFlagsShow
            orderType="orders"
            flagTypes={flagTypes[record.workflow]}
          />

          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <CardContentInner>
                <Inputs resource={resource} basePath={basePath} record={record}>
                  <TranslatedTitle translateKey="resources.orders.fields.contactAddress.name" />

                  <OrderInputField
                    component={SelectInput}
                    source="contactAddress.salutation"
                    choices={[
                      { id: 'Frau', name: 'Frau' },
                      { id: 'Herr', name: 'Herr' },
                    ]}
                  />
                  <OrderInputField
                    component={TextInput}
                    source="contactAddress.firstName"
                    validate={required()}
                  />
                  <OrderInputField
                    component={TextInput}
                    source="contactAddress.lastName"
                    validate={required()}
                  />
                  <OrderInputField
                    component={TextInput}
                    source="contactAddress.email"
                    validate={required()}
                  />

                  <TextInput
                    source="contactAddress.ccEmail"
                    multiline
                    fullWidth
                    label="resources.customers.fields.addresses.ccEmail"
                    format={arr => (arr ?? []).join('\n')}
                    parse={value => value.split(/[\n,;]+/)}
                  />

                  {flags.createOrder && (
                    <OrderInputField
                      component={RadioButtonGroupInput}
                      fullWidth
                      source="contactAddress.emailType"
                      choices={[
                        {
                          id: 'formal',
                          name: 'resources.customers.emailTypes.formal',
                        },
                        {
                          id: 'informal',
                          name: 'resources.customers.emailTypes.informal',
                        },
                        {
                          id: 'personal',
                          name: 'resources.customers.emailTypes.personal',
                        },
                      ]}
                    />
                  )}
                </Inputs>
              </CardContentInner>
              {Toolbar}
            </CardContent>
          </Card>
        </div>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Inputs resource={resource} basePath={basePath} record={record}>
              <TranslatedTitle translateKey="resources.orders.fields.billingAddress.name" />

              <OrderInputField
                component={SelectInput}
                source="billingAddress.salutation"
                choices={[
                  { id: 'Frau', name: 'Frau' },
                  { id: 'Herr', name: 'Herr' },
                ]}
              />
              <OrderInputField
                component={TextInput}
                source="billingAddress.firstName"
              />
              <OrderInputField
                component={TextInput}
                source="billingAddress.lastName"
              />
              <OrderInputField
                component={TextInput}
                source="billingAddress.company"
              />
              <OrderInputField
                component={AutocompleteInput}
                source="billingAddress.legalForm"
                choices={legalForms.map(f => ({ id: f, name: f }))}
              />

              <OrderInputField
                component={TextInput}
                source="billingAddress.companyShort"
                validate={[maxLength(50)]}
              />
              <OrderInputField
                component={TextInput}
                source="billingAddress.street"
              />
              <OrderInputField
                component={TextInput}
                source="billingAddress.postcode"
              />
              <OrderInputField
                component={TextInput}
                source="billingAddress.city"
              />
              <OrderInputField
                component={TextInput}
                source="billingAddress.phone"
              />
              <OrderInputField
                component={TextInput}
                source="billingAddress.email"
              />
            </Inputs>
          </CardContent>
          <CardActions disableSpacing className={classes.cardActions}>
            {Toolbar}
          </CardActions>
        </Card>

        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Inputs resource={resource} basePath={basePath} record={record}>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                fullWidth
              >
                <TranslatedTitle translateKey="resources.orders.fields.shippingAddress.name" />
                <CopyBillingToShippingAddressButton />
              </Box>
              {/* <OrderInputField
                component={TextInput}
                source="shippingAddress.firstName"
              />
              <OrderInputField
                component={TextInput}
                source="shippingAddress.lastName"
              /> */}
              <OrderInputField
                component={TextInput}
                source="shippingAddress.company"
              />
              <OrderInputField
                component={TextInput}
                parse={value => value || ''}
                source="shippingAddress.addressAddon"
              />

              <OrderInputField
                component={TextInput}
                source="shippingAddress.street"
              />
              <OrderInputField
                component={TextInput}
                source="shippingAddress.postcode"
              />
              <OrderInputField
                component={TextInput}
                source="shippingAddress.city"
              />
            </Inputs>
            <ChooseAddressSetDialog
              customerId={record?.customer?.id}
              orderId={record?.id}
            />
            {record.workflow === 'rent_order' && (
              <>
                <TranslatedTitle
                  translateKey="resources.orders.fields.ceo.name"
                  style={{ marginTop: '16px' }}
                />
                <Inputs resource={resource} basePath={basePath} record={record}>
                  <OrderInputField
                    component={TextInput}
                    source="ceo.firstName"
                  />
                  <OrderInputField
                    component={TextInput}
                    source="ceo.lastName"
                  />
                  <OrderInputField component={TextInput} source="ceo.street" />
                  <OrderInputField
                    component={TextInput}
                    source="ceo.postcode"
                  />
                  <OrderInputField component={TextInput} source="ceo.city" />
                  <OrderInputField
                    component={DateInput}
                    source="ceo.birthdate"
                  />
                </Inputs>
              </>
            )}
          </CardContent>
          <CardActions disableSpacing className={classes.cardActions}>
            {Toolbar}
          </CardActions>
        </Card>

        {record.workflow === 'rent_order' && (
          <Card className={classes.fullWidthCard}>
            <CardContent className={classes.cardContent}>
              <Inputs resource={resource} basePath={basePath} record={record}>
                <TranslatedTitle translateKey="resources.orders.tabs.bankInformation" />
                <OrderInputField
                  component={TextInput}
                  source="IBAN"
                  validate={validateIban}
                />
              </Inputs>
            </CardContent>
            <CardActions disableSpacing className={classes.cardActions}>
              {Toolbar}
            </CardActions>
          </Card>
        )}

        <OrderLinesEdit mode={mode} setMode={setMode} />

        {get(record, 'deviceLines', []).find(
          v => v.deviceOrderLineDiscount,
        ) && (
          <Card className={classes.fullWidthCard}>
            <CardContent className={classes.cardContent}>
              <CardContent>
                <TranslatedTitle
                  translateKey="resources.discounts.cas.name"
                  smartCount={2}
                />
                <OrderDiscountList record={record} />
              </CardContent>
            </CardContent>
          </Card>
        )}

        <OrderAttachments withInput />

        <Card className={classes.fullWidthCard}>
          <CardContent className={classes.cardContent}>
            <OrderAdditionalInfo />
          </CardContent>
        </Card>

        <Card className={classes.fullWidthCard}>
          <CardContent className={classes.cardContent}>
            <OrderITScopeInfo />
          </CardContent>
        </Card>

        <Card className={classes.fullWidthCard}>
          <CardContent className={classes.cardContent}>
            <OrderShipments />
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <OrderExtra />
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <OrderReporting />
          </CardContent>
        </Card>
      </Grid>
    </form>
  )
}

GridFormView.propTypes = {
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export default GridFormView
