import { ListItemText, MenuItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useHistory } from 'react-router-dom'
import { useApiClient } from 'Rest/fetchHydra'
import { useTheme } from '@material-ui/core/styles'

const SettingsMenuItem = () => {
  const showNotification = useNotify()
  const translate = useTranslate()
  const history = useHistory()
  const theme = useTheme()
  const apiClient = useApiClient()

  const handleClick = async () => {
    try {
      const res = await apiClient({ endpoint: '/me' }).then(res => res.data)

      history.push(`/resellers/${res.id}`)
    } catch (error) {
      console.error(error)
      showNotification(translate('errors.errorResellerNotFound'), 'warning')
    }
  }

  return (
    <MenuItem
      onClick={handleClick}
      style={{ color: theme.palette.text.secondary }}
    >
      <ListItemText primary={translate('menu.settings.general')} />
    </MenuItem>
  )
}

SettingsMenuItem.propTypes = {
  record: PropTypes.object,
}

export default SettingsMenuItem
