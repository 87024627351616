import { Button, Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'
import LanguageSwitcher from 'Components/Common/Layout/LanguageSwitcher'
import produce from 'immer'
import { isString } from 'lodash'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import {
  Pagination,
  Title,
  Toolbar,
  useDataProvider,
  useMutation,
  useNotify,
  useTranslate,
} from 'react-admin'
import { useSelector } from 'react-redux'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 1000,
  },
  longRow: {
    minWidth: 300,
  },
  shortRow: {
    maxWidth: 100,
  },
})

const useStyles = makeStyles(styles)

const StringTranslationsEdit = () => {
  const classes = useStyles()
  const contentLanguage = useSelector(
    ({ language: { contentLanguage } }) => contentLanguage,
  )
  const showNotification = useNotify()
  const translate = useTranslate()
  const [changed, setChanged] = useState({})
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [type, setType] = useState('admin')

  const dataProvider = useDataProvider()
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)

  const fetchData = () =>
    dataProvider
      .getList('string_translations', {
        pagination: null,
        sort: false,
        filter: { platformType: ['also', 'generic'], type },
      })
      .then(({ data, total }) => {
        setData(data)
        setTotal(total)
        setChanged({})
      })

  useEffect(() => {
    fetchData()
  }, [contentLanguage, type])

  const items = data ? data.slice(perPage * (page - 1), perPage * page) : []

  const handleChange = (id, value) => {
    setChanged(
      produce(changed, draft => {
        draft[id] = value || ''
      }),
    )
  }

  const [mutate] = useMutation()

  const handleSave = () =>
    mutate(
      {
        type: 'saveTranslations',
        resource: false,
        payload: {
          data: changed,
        },
      },
      {
        onSuccess: () => {
          showNotification('notifications.save.success')

          fetchData()
        },
        onFailure: () => showNotification('notifications.save.fail', 'warning'),
      },
    )

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Card>
        <Title
          title={translate(`resources.string_translations.name`, {
            smart_count: 2,
          })}
        />
        <Tabs value={type} onChange={(e, value) => setType(value)}>
          <Tab label="Admin" value="admin" />
          <Tab label="Shop" value="frontend" />
        </Tabs>

        <Table padding="dense" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                {translate(`resources.string_translations.fields.key`)}
              </TableCell>
              <TableCell className={classes.longRow}>
                {translate(`resources.string_translations.fields.content`)}
              </TableCell>
              <TableCell className={classes.shortRow}>
                {translate(`resources.string_translations.fields.type`)}
              </TableCell>
              <TableCell className={classes.shortRow}>
                {translate(`resources.string_translations.fields.updatedAt`)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.key}</TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    value={
                      isString(changed[row.id]) ? changed[row.id] : row.content
                    }
                    onChange={({ target: { value } }) =>
                      handleChange(row.id, value)
                    }
                  />
                </TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>
                  {moment(row.updatedAt).format('DD.MM.YYYY, hh:mm')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Toolbar>
          <Button variant="contained" color="primary" onClick={handleSave}>
            speichern
          </Button>

          <Pagination
            setPage={setPage}
            setPerPage={setPerPage}
            page={page}
            perPage={perPage}
            total={total}
          />
        </Toolbar>
      </Card>
      <Card>
        <LanguageSwitcher
          availableLanguages={[
            { code: 'de', countryCode: 'de' },
            { code: 'en', countryCode: 'gb' },
            { code: 'it', countryCode: 'it' },
            { code: 'fr', countryCode: 'fr' },
            { code: 'nl', countryCode: 'nl' },
          ]}
        />
      </Card>
    </div>
  )
}

export default StringTranslationsEdit
