import React from 'react'
import PropTypes from 'prop-types'
import { SaveButton, Toolbar, DeleteButton } from 'react-admin'
import DiscardChangesButton from 'Components/Common/Buttons/DiscardChangesButton'
import DisabledSaveButton from 'Components/Common/Buttons/DisabledSaveButton'

import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    '& .ra-delete-button': {
      marginLeft: 'auto',
    },
  },
})

const EditToolbar = props => (
  <Toolbar {...props} classes={useStyles()}>
    {props.disabled ? (
      <DisabledSaveButton />
    ) : (
      <SaveButton {...props} redirect={props.redirect} />
    )}
    <DiscardChangesButton disabled={props.disabled} />
    {props.hasDelete && <DeleteButton redirect={props.redirect} />}
  </Toolbar>
)

EditToolbar.propTypes = {
  disabled: PropTypes.bool,
  hasDelete: PropTypes.node,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
}

EditToolbar.defaultProps = {
  disabled: false,
  hasDelete: false,
  redirect: () => undefined,
}

export default EditToolbar
