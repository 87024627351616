import GenericRestClientButton from 'Components/Common/ActionButtons/GenericRestClientButton'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { ResellerContext } from 'ResellerContext'
import axios from 'axios'

const WarmupStoreViewCacheButton = ({ data }) => {
  const { flags } = useContext(ResellerContext)

  if (!flags.warmupCacheButton) {
    return null
  }

  if (!data) {
    return null
  }

  return (
    <GenericRestClientButton
      type="warmupStoreViewCache"
      onClick={async () => {
        await axios.put(
          `${process.env.REACT_APP_FRONTEND_NEXT_URL}/cache/${data.apiKey}/refresh`,
        )
      }}
    />
  )
}

WarmupStoreViewCacheButton.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
}

WarmupStoreViewCacheButton.defaultProps = {
  data: null,
}

export default WarmupStoreViewCacheButton
