import Add from '@material-ui/icons/Add'
import CopyAccessorySKUButton from 'Components/Common/ActionButtons/CopyAccessorySKUButton'
import ButtonLabel from 'Components/Common/Buttons/ButtonLabel'
import CreateInformationTabButton from 'Components/Common/Buttons/CreateInformationTabButton'
import EditToolbar from 'Components/Common/EditToolbar'
import ImagesInput from 'Components/Common/InputFields/ImagesInput'
import MetaInput from 'Components/Common/InputFields/MetaInput'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import ReferenceInput from 'Components/Common/ReferenceInput'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import PercentageField from 'Components/Common/ViewFields/PercentageField'
import { useFullWidthTabStyles } from 'Lib/styles'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import {
  BooleanField,
  BooleanInput,
  Create,
  CreateButton,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  FormTab,
  List,
  ListButton,
  ReferenceManyField,
  required,
  sanitizeListRestProps,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useTranslate,
} from 'react-admin'
import { Link } from 'react-router-dom'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'
import { Button } from '@material-ui/core'
import BulkCreateDeviceVariantsDialogButton from 'Components/Aluxo/ActionDialogs/BulkCreateDeviceVariantsDialog/Button'
import DisplaySpecsField from 'Components/Common/ViewFields/DisplaySpecsField'
import CommonInputFieldsCategoryBasedOnStoreviewInput from 'Components/Common/InputFields/CategoryBasedOnStoreviewInput'
import DeviceVariantNamingSpecInput from 'Components/Aluxo/InputFields/DeviceVariantNamingSpecInput'
import MissingSpecsField from 'Components/Common/ViewFields/MissingSpecsField'
import DateField from 'Components/Common/ViewFields/DateField'
import SlugInput from 'Components/Common/InputFields/SlugInput'
import CreditGroupSelect from 'Components/Aluxo/InputFields/CreditGroupSelect'
import { ResellerContext } from 'ResellerContext'

const DeviceFilter = props => (
  <Filter {...props}>
    <TextInput source="name" />
    <ReferenceInput
      label="resources.devices.fields.manufacturer.name"
      source="manufacturer"
      reference="manufacturers"
    >
      <SelectInput optionText={choice => choice.name} />
    </ReferenceInput>
    <ReferenceInput
      label="resources.devices.fields.category.name"
      source="category"
      reference="categories"
    >
      <SelectInput optionText={choice => choice.name} />
    </ReferenceInput>
    <TextInput source="slug" />
    <BooleanInput
      options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
      source="active"
    />
  </Filter>
)

const ListActions = props => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <BulkCreateDeviceVariantsDialogButton />
    </TopToolbar>
  )
}

export const DeviceList = props => (
  <List
    {...props}
    actions={<ListActions />}
    pagination={<CustomPagination />}
    filters={<DeviceFilter />}
    aside={<LanguageSwitcherAside />}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="manufacturer.name" />
      <TextField source="slug" />
      <TextField source="category.name" />
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <TextField source="storeView.name" />
      )}
      <BooleanField source="active" />
      <DateField source="createdAt" sortByOrder="DESC" />
      <EditButton basePath="/devices" />
    </Datagrid>
  </List>
)

DeviceList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const DeviceEditActions = ({ basePath, data }) => {
  const t = useTranslate()
  const linkParams = {
    device: get(data, '@id'),
  }
  return (
    <RightAlignedCardActions>
      <ListButton basePath={basePath} />
      <Button
        color="primary"
        component={Link}
        to={`/device_variants/create?source=${JSON.stringify(linkParams)}`}
      >
        <Add />
        <ButtonLabel>{t('actions.add_variant')}</ButtonLabel>
      </Button>

      <CreateInformationTabButton
        type="device_information_tabs"
        reference={get(data, '@id')}
      />
    </RightAlignedCardActions>
  )
}

DeviceEditActions.propTypes = {
  basePath: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
}

const DeviceTitle = ({ record }) => get(record, 'name')

export const validateRequired = required()

export const DeviceEdit = props => {
  const { flags } = useContext(ResellerContext)
  const tabClasses = useFullWidthTabStyles()
  return (
    <Edit
      title={<DeviceTitle />}
      actions={<DeviceEditActions />}
      aside={<LanguageSwitcherAside />}
      {...props}
    >
      <TabbedForm toolbar={<EditToolbar hasDelete />} redirect={false}>
        <FormTab label="resources.devices.tabs.general">
          <TextInput source="name" validate={validateRequired} />
          <SlugInput
            source="slug"
            dependOn="name"
            validate={validateRequired}
          />

          <ReferenceInput
            source="manufacturer"
            reference="manufacturers"
            fullWidth
          >
            <SelectInput optionText="name" />
          </ReferenceInput>

          {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
            <ReferenceInput
              source="storeView"
              reference="store_views"
              validate={validateRequired}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          )}

          <CommonInputFieldsCategoryBasedOnStoreviewInput label="resources.devices.fields.category.name" />

          <ImagesInput resourceName="device_images" referenceName="device" />
          <BooleanInput
            options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
            source="active"
            validate={validateRequired}
          />

          <CreditGroupSelect />
          <TextInput source="autopilotString" />

          <MetaInput />
        </FormTab>
        <FormTab
          label="resources.devices.tabs.variants"
          contentClassName={tabClasses.fullWidthTab}
        >
          <DeviceVariantNamingSpecInput />
          <BooleanInput
            options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
            source="namingGroupsSet"
            validate={validateRequired}
          />
          <ReferenceManyField
            label="resources.devices.fields.variants"
            reference="device_variants"
            target="device"
          >
            <Datagrid style={{ width: '100%' }}>
              <TextField source="sku" />
              <TextField source="name" />
              {flags.cNetSpecs && <DisplaySpecsField source="displaySpecs" />}
              {flags.cNetSpecs && (
                <MissingSpecsField source="missingDisplaySpecs" />
              )}
              <BooleanField source="active" />
              <BooleanField source="autoDisabled" />
              <CurrencyField source="price" />
              <PercentageField source="margin" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab
          label="resources.devices.tabs.information_tabs"
          contentClassName={tabClasses.fullWidthTab}
        >
          <ReferenceManyField
            label="resources.devices.fields.information_tabs"
            reference="device_information_tabs"
            target="reference"
          >
            <Datagrid style={{ width: '100%' }}>
              <TextField source="title" />
              <EditButton />
              <DeleteButton redirect={false} />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab
          label="resources.devices.tabs.accessories"
          contentClassName={tabClasses.fullWidthTab}
        >
          <TextInput
            multiline
            source="accessorySKUs"
            format={arr => arr.join('\n')}
            parse={value => value.split(/[\n,;]+/)}
          />
          <CopyAccessorySKUButton />
          <TextInput
            multiline
            source="serviceExtensionSKUs"
            format={arr => arr.join('\n')}
            parse={value => value.split(/[\n,;]+/)}
          />
          <TextInput source="includedServiceExtension" />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

DeviceEdit.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const defaultValues = {
  variants: [],
  informationTabs: [],
  active: true,
  description: '',
}

export const DeviceCreate = props => (
  <Create {...props}>
    <SimpleForm defaultValue={defaultValues}>
      <TextInput source="name" validate={validateRequired} />

      <ReferenceInput
        source="manufacturer"
        reference="manufacturers"
        validate={validateRequired}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>

      <CommonInputFieldsCategoryBasedOnStoreviewInput label="resources.devices.fields.category.name" />

      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="active"
      />
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <ReferenceInput
          source="storeView"
          reference="store_views"
          validate={validateRequired}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
    </SimpleForm>
  </Create>
)

DeviceCreate.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}
