const styles = {
  firstColumn: {
    width: '99%',
    maxWidth: '350px',
    minWidth: '200px',
    marginRight: '1em',
    minHeight: '72px',
  },
  innerContainer: {
    // width: '98%',
    background: '#f9f9f9',
    marginBottom: '1em',
    marginTop: '1em',
    // paddingLeft: '1%',
    // display: 'grid',
    // gridTemplateColumns: '25% 25% 25% 25%',
    position: 'relative',
  },
  actionButtonContainer: {
    marginTop: '-250px',
    marginRight: '1rem',
    float: 'right',
    // position: 'absolute',
    // top: '1rem',
    // right: '1rem',
  },
  labelContainerStyle: {
    marginTop: '1.5em',
  },
  thirdColumn: {
    width: '99%',
    minWidth: '200px',
    minHeight: '101px',
    gridRow: '2',
  },
  thirdColumnClear: {
    width: '99%',
    minWidth: '200px',
    minHeight: '101px',
  },
  fullColumn: {
    width: '50%',
    maxWidth: '450px',
    height: '50px',
  },
  indentedColumn: {
    paddingLeft: '1em',
    width: '50%',
    maxWidth: '450px',
  },
  subLabel: {
    marginTop: '1em',
  },
}

export default styles
