import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { getLinkFromIri } from 'Lib/helper'
import BackButton from 'Components/Common/Buttons/BackButton'

const BackToProductButton = ({ data, product }) => {
  const productIri = product || get(data, 'product[@id]')
  return <BackButton to={getLinkFromIri(productIri)} />
}

BackToProductButton.propTypes = {
  data: PropTypes.object, // eslint-disable-line
  product: PropTypes.string,
}

BackToProductButton.defaultProps = {
  data: {},
  product: null,
}

export default BackToProductButton
