import { useField } from 'react-final-form'
import React, { useEffect } from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const DeviceSelect = ({ availableDevices }) => {
  const {
    input: { value, onChange },
  } = useField('device')

  useEffect(() => {
    if (availableDevices.length > 0) {
      onChange(availableDevices[0].id)
    }
  }, [availableDevices])

  const handleChange = ({ target: { value } }) => {
    onChange(value)
  }

  if (availableDevices.length === 0) {
    return null
  }

  if (availableDevices.length === 1) {
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">Arbeitsplatzgerät</FormLabel>
        <List>
          {availableDevices.map(d => (
            <ListItem>
              <ListItemText primary={d.name} secondary={d.sku} />
            </ListItem>
          ))}
        </List>
      </FormControl>
    )
  }

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Arbeitsplatzgerät</FormLabel>
      <RadioGroup
        aria-label="device"
        name="device"
        value={value}
        onChange={handleChange}
      >
        {availableDevices.map(d => (
          <FormControlLabel
            value={d.id}
            control={<Radio />}
            label={`${d.sku} - ${d.name} `}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default DeviceSelect
