import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import pure from 'recompose/pure'
import { Check } from '@material-ui/icons'

const AffiliateField = ({ source, record = {}, elStyle = {} }) => {
  if (get(record, source)) {
    return <Check style={elStyle} />
  }

  return <span style={elStyle} />
}

AffiliateField.propTypes = {
  elStyle: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

AffiliateField.defaultProps = {
  elStyle: {
    display: 'block',
    margin: 'auto',
  },
}

const PureAffiliateField = pure(AffiliateField)

export default PureAffiliateField
