import Divider from '@material-ui/core/Divider'
import { Dashboard, EuroSymbol, Settings } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React, { createElement } from 'react'
import { MenuItemLink } from 'react-admin'
import {
  TranslatedResourceName,
  TranslatedString,
} from 'Components/Common/Layout/MenuTranslations'
import CustomSettingsMenuItem from 'Components/Common/Menu/CustomSettingsMenuItem'
import SubMenu from 'Components/Common/Layout/SubMenu'
import {
  ListItemIcon,
  ListItemText,
  Menu as MUIMenu,
  MenuItem,
  Tooltip,
} from '@material-ui/core'
import SettingsMenuItem from 'Components/Common/Menu/SettingsMenuItem'
import { useMenuStyles } from 'Components/Common/Layout/Menu'

const AlsoEdiResellerMenu = ({ onMenuClick /* , permissions */ }) => {
  const classes = useMenuStyles()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <div>
        <MenuItemLink
          to="/"
          primaryText={<TranslatedResourceName resource="dashboard" />}
          onClick={onMenuClick}
          leftIcon={createElement(Dashboard)}
        />
        <Divider />
        <MenuItemLink
          to="/also_offers"
          primaryText={<TranslatedResourceName resource="also_offers" />}
          onClick={onMenuClick}
          leftIcon={createElement(EuroSymbol)}
        />
        <Divider />
        <div>
          <Divider />
          <SubMenu
            mainItem={set => (
              <Tooltip
                title={<TranslatedString i18nkey="menu.settings.name" />}
                placement="right"
              >
                <MenuItem onClick={({ currentTarget }) => set(currentTarget)}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText
                    inset
                    primary={<TranslatedString i18nkey="menu.settings.name" />}
                    className={classes.listItemText}
                  />
                </MenuItem>
              </Tooltip>
            )}
          >
            {(target, hide) => (
              <MUIMenu anchorEl={target} open={Boolean(target)} onClose={hide}>
                <SettingsMenuItem />
                <CustomSettingsMenuItem type="alsoOfferSettings" />
                <CustomSettingsMenuItem type="corporateIdentity" />
              </MUIMenu>
            )}
          </SubMenu>
        </div>
      </div>
    </div>
  )
}

AlsoEdiResellerMenu.propTypes = {
  onMenuClick: PropTypes.func,
}

AlsoEdiResellerMenu.defaultProps = {
  onMenuClick: () => null,
}

export default AlsoEdiResellerMenu
