import {
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
  TableRow,
  Box,
  Typography,
} from '@material-ui/core'
import currencyText from 'Lib/currencyText'
import { isNumber } from 'lodash'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import React, { useState, useEffect, useMemo } from 'react'
import {
  useLoading,
  useMutation,
  useNotify,
  useDataProvider,
  useTranslate,
  useVersion,
} from 'react-admin'
import UpdateAlsoServicePortfolioButton from 'Components/Also/ActionButtons/UpdateAlsoServicePortfolioButton'
import RightAlignedCardActions from 'Components/Common/RightAlignedCardActions'

const InfoButton = () => {
  const t = useTranslate()
  return (
    <Tooltip title={t('texts.also_reseller_services.notAvailable')}>
      <InfoOutlinedIcon />
    </Tooltip>
  )
}

const AlsoResellerServiceList = () => {
  const loading = useLoading()
  const t = useTranslate()
  const showNotification = useNotify()
  const version = useVersion()
  const dataProvider = useDataProvider()

  const [alsoServices, setAlsoServices] = useState([])
  const [resellerServices, setResellerServices] = useState([])
  const [rows, setRows] = useState([])

  useEffect(() => {
    const refreshData = async () => {
      const resellerServices = await dataProvider.getList(
        'also_reseller_services',
        {
          pagination: false,
          sort: false,
          filter: { active: true },
        },
      )

      const alsoServices = await dataProvider.getList('also_services', {
        pagination: false,
        sort: false,
        filter: { active: true },
      })

      setResellerServices(resellerServices.data || [])

      setAlsoServices(alsoServices.data || [])
    }

    refreshData()
  }, [version])

  const filtered = useMemo(
    () =>
      alsoServices.filter(d =>
        d.acmpService.billingItems.some(i => i.chargeType === 'monthly'),
      ),
    [alsoServices],
  )

  useEffect(() => {
    if (filtered.length > 0) {
      setRows(
        filtered.map(row => ({
          ...row,
          resellerService: resellerServices.find(r => r.service.id === row.id),
        })),
      )
    }
  }, [filtered, resellerServices])

  const [mutate] = useMutation()

  const handleUpdateItem = (type, data) => {
    setResellerServices(services => {
      if (type === 'delete') {
        return services.filter(s => s.id !== data.id)
      }
      return services.concat(data)
    })
  }

  const handleToggle = ({ target: { value: id } }, value) => {
    const type = value ? 'create' : 'delete'
    const resource = 'also_reseller_services'
    const payload = value ? { data: { service_reference_id: id } } : { id }

    mutate(
      {
        type,
        resource,
        payload,
      },
      {
        onSuccess: res => {
          console.log(res)
          showNotification(`notifications.alsoResellerService.${type}.success`)
          handleUpdateItem(type, res.data.id ? res.data : { id })
        },
        onFailure: () => {
          showNotification(
            `notifications.alsoResellerService.${type}.fail`,
            'warning',
          )
        },
      },
    )
  }

  return (
    <Card style={{ marginTop: '1em' }}>
      <RightAlignedCardActions>
        <UpdateAlsoServicePortfolioButton />
      </RightAlignedCardActions>
      <CardHeader title={t('actions.createAlsoResellerService')} />
      <CardContent>
        <Typography paragraph gutterBottom>
          {t('texts.also_reseller_services.subHeader')}
        </Typography>
        {loading && <LinearProgress>Loading</LinearProgress>}
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                {t('resources.also_reseller_services.fields.name')}
              </TableCell>
              <TableCell>
                {t('resources.also_reseller_services.fields.category')}
              </TableCell>
              <TableCell>
                {t('resources.also_reseller_services.fields.manufacturer')}
              </TableCell>
              <TableCell>
                {t('resources.also_reseller_services.fields.monthlyPrice')}
              </TableCell>
              <TableCell>
                {t('resources.also_reseller_services.fields.active')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.category}</TableCell>
                <TableCell>{row.manufacturer}</TableCell>
                <TableCell>
                  {isNumber(row.monthlyPrice)
                    ? currencyText(row.monthlyPrice)
                    : null}
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Switch
                      disabled={!row.available}
                      checked={Boolean(row.resellerService)}
                      onChange={handleToggle}
                      color="primary"
                      value={
                        row.resellerService ? row.resellerService.id : row.id
                      }
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    {!row.available && <InfoButton />}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default AlsoResellerServiceList
