import React from 'react'
import { useFormState } from 'react-final-form'
import { TextInput, useTranslate } from 'react-admin'
import { Inputs } from 'Components/Common/Orders/OrderEdit'
import { useOrder } from 'OrderContext'
import ToggleEditable, {
  MaybeUndefinedStringWithTranslation,
} from 'Components/Also/Offers/ToggleEditable'

const EditableAdditionalData = () => {
  const { values } = useFormState()
  const t = useTranslate()

  const { resource, basePath, record } = useOrder()

  return (
    <ToggleEditable
      displayChildren={
        <>
          <div>
            {t('resources.also_offers.fields.vatId')}:
            <MaybeUndefinedStringWithTranslation value={values.vatId} ml={1} />
          </div>
          <div>
            {t('resources.also_offers.fields.domain')}:
            <MaybeUndefinedStringWithTranslation value={values.domain} ml={1} />
          </div>
          <div>
            {t('resources.also_offers.fields.bank')}:
            <MaybeUndefinedStringWithTranslation value={values.bank} ml={1} />
          </div>
          <div>
            {t('resources.also_offers.fields.IBAN')}:
            <MaybeUndefinedStringWithTranslation value={values.IBAN} ml={1} />
          </div>
          <div>
            {t('resources.also_offers.fields.BIC')}:
            <MaybeUndefinedStringWithTranslation value={values.BIC} ml={1} />
          </div>
        </>
      }
      inputChildren={
        <Inputs resource={resource} basePath={basePath} record={record}>
          <TextInput source="vatId" resettable />
          <TextInput source="domain" resettable />
          <TextInput source="bank" resettable />
          <TextInput source="IBAN" resettable />
          <TextInput source="BIC" resettable />
        </Inputs>
      }
    />
  )
}

export default EditableAdditionalData
