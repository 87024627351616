import { LinearProgress } from '@material-ui/core'
import produce from 'immer'
import { debounce, max, round, sortBy } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useLoading, useQuery } from 'react-admin'
import { useApiClient } from 'Rest/fetchHydra'

const getMaxStock = prices => max(prices.map(p => p.stock))

const FetchData = ({ children, includeItScope, itScopeSearchValue }) => {
  const loading = useLoading()
  const { data: entries = [] } = useQuery({
    type: 'getList',
    resource: 'service_extensions',
    payload: {
      pagination: false,
      sort: false,
      filter: { active: true },
    },
  })

  const { data: manufacturers = [] } = useQuery({
    type: 'getList',
    resource: 'manufacturers',
    payload: {
      pagination: false,
      sort: false,
      filter: false,
    },
  })

  const [itScopeProducts, setItScopeProducts] = useState([])

  const apiClient = useApiClient()

  const fetch = async value => {
    try {
      const res = await apiClient({
        endpoint: `/it_scope_products?keywords=${value}`,
      }).then(res => res.data)

      const filtered = Object.values(res).filter(
        ({ prices }) => prices.length > 0,
      )
      const sorted = sortBy(filtered, 'manufacturerSKU')

      setItScopeProducts(sorted)
    } catch (e) {
      // showNotification('Produkte konnten nicht geladen werden.')
    }
  }

  const debouncedFetch = useCallback(
    debounce(fetch, 1000, { trailing: true, leading: false }),
    [],
  )

  useEffect(() => {
    if (!includeItScope) {
      setItScopeProducts([])
      return
    }
    if (!itScopeSearchValue || itScopeSearchValue.length < 3) {
      return
    }

    debouncedFetch(itScopeSearchValue)
  }, [itScopeSearchValue, includeItScope])

  if (loading) {
    return (
      <div
        style={{
          minHeight: '72px',
          display: 'flex',
          alignItems: 'flex-end',
        }}
      >
        <LinearProgress style={{ width: '100%' }} mode="indeterminate" />
      </div>
    )
  }

  const serviceExtensions = entries.map(v =>
    produce(v, draft => {
      draft.manufacturer = manufacturers.find(
        m => m.id === draft.manufacturer_reference_id,
      )
    }),
  )

  const itScopeData = itScopeProducts.map(p => ({
    ...p,
    price: round(p.averagePrice, 2),
    stock: getMaxStock(p.prices),
  }))

  return children({ serviceExtensions, itScopeData, loading })
}

export default FetchData
