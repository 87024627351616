import { put, takeLatest, call } from 'redux-saga/effects'
import { zipObjectDeep } from 'lodash'
import { CHANGE_LANGUAGE_REQUEST } from 'Components/Common/Layout/SystemLanguageSwitcher'
// import { restClient } from 'App'
import { GET_LIST, USER_CHECK, FETCH_START, FETCH_END } from 'react-admin'
// import { DECLARE_RESOURCES } from 'ra-core'
import de from 'I18n/messages/de'
import queryString from 'query-string'

const parsed = queryString.parse(window.location.search)

const { showTranslationKeys } = parsed

const defaultsDeep = require('lodash/defaultsDeep')

const restClient = null

const startFetch = () => ({
  type: FETCH_START,
})

const endFetch = () => ({
  type: FETCH_END,
})

function* changeLanguage(action) {
  yield put(startFetch())

  const {
    payload: { locale },
  } = action

  const pagination = false
  const sort = {}
  const filter = { type: 'admin' }

  const res = yield call(
    restClient,
    GET_LIST,
    'string_translations',
    {
      pagination,
      sort,
      filter,
    },
    {
      'X-Locale': locale,
    },
  )

  const strings = res.data
  const paths = strings.map(s => s.key)
  const values = strings.map(s => s.content)

  const messages = zipObjectDeep(paths, values)

  const mergedMessages = defaultsDeep(
    {}, // Do not mutate sourceConfig!!!
    de,
    messages,
  )

  localStorage.setItem(
    'locale-and-messages',
    JSON.stringify({ locale, messages: mergedMessages }),
  )

  yield put({
    type: 'RA/CHANGE_LOCALE_SUCCESS',
    payload: {
      locale,
      messages: mergedMessages,
    },
  })
  yield put(endFetch())
}

function* fetchInitialLanguage(action) {
  if (action.payload.route || showTranslationKeys) {
    return
  }

  yield put(startFetch())

  const localeAndMessages = JSON.parse(
    localStorage.getItem('locale-and-messages'),
  )

  const messages = localeAndMessages ? localeAndMessages.messages : de
  const locale = localeAndMessages ? localeAndMessages.locale : 'de'

  const mergedMessages = defaultsDeep(
    {}, // Do not mutate sourceConfig!!!
    de,
    messages,
  )

  const payload = {
    locale,
    messages: mergedMessages,
  }

  yield put({
    type: 'RA/CHANGE_LOCALE_SUCCESS',
    payload,
  })

  yield put(endFetch())
}

export default function* changeLanguageSaga() {
  yield takeLatest(CHANGE_LANGUAGE_REQUEST, changeLanguage)
  yield takeLatest(USER_CHECK, fetchInitialLanguage)
}
