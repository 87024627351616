import React from 'react'
import GenericRestClientButton from '../../Common/ActionButtons/GenericRestClientButton'

const BulkUpdateITScopeMetadataButton = () => (
  <GenericRestClientButton
    action="bulkUpdateItscopeMetadata"
    type="bulkUpdateItScopeMetadata"
  />
)

export default BulkUpdateITScopeMetadataButton
