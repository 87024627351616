import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ContentCreate from '@material-ui/icons/Create'
import Button from 'Components/Common/Buttons/Button'
import { useOrder } from 'OrderContext'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Error, Loading, useNotify, useQuery, useRefresh } from 'react-admin'
import { useApiClient } from 'Rest/fetchHydra'
import { isAllowed } from '../InputFields/OrderInputField'

const AddressSet = ({ addressSet, onSetSelect }) => {
  const shippingAddress = addressSet.addresses.find(a => a.type === 'shipping')
  const contactAddress = addressSet.addresses.find(a => a.type === 'contact')

  const primaryTextElements = [
    contactAddress?.firstName,
    contactAddress?.lastName,
    addressSet.defaultSet ? '(Standard)' : null,
  ]

  const primaryText = primaryTextElements.filter(e => e).join(' ')

  return (
    <ListItem alignItems="flex-start" button onClick={onSetSelect}>
      <ListItemText
        primary={primaryText}
        secondary={`${shippingAddress?.street} ${shippingAddress?.postcode} ${shippingAddress?.city}`}
      />
    </ListItem>
  )
}

const ChooseAddressSetDialogContent = ({
  handleClose,
  customerId,
  orderId,
}) => {
  const showNotification = useNotify()
  const refreshView = useRefresh()
  const apiClient = useApiClient()
  const [loading, setLoading] = useState(false)

  const { data: record, loading: queryLoading, error } = useQuery({
    type: 'getOne',
    resource: 'customers',
    payload: { id: customerId },
  })

  const handleSetSelect = async set => {
    setLoading(true)
    try {
      await apiClient({
        endpoint: '/orders_set_address_set',
        method: 'POST',
        body: { order: orderId, customerAddressSet: set.id },
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })

      showNotification('notifications.ordersSetAddressSet.success')

      handleClose()

      refreshView()
    } catch (e) {
      showNotification('notifications.ordersSetAddressSet.fail', 'warning')
    }
    setLoading(false)
  }

  if (queryLoading)
    return (
      <DialogContent>
        <Loading />
      </DialogContent>
    )
  if (error) return <Error />

  return (
    <>
      <DialogContent>
        <List>
          {record.addressSets.map(set => (
            <AddressSet
              key={set.id}
              addressSet={set}
              onSetSelect={() => handleSetSelect(set)}
            />
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          label="Abbrechen"
          onClick={handleClose}
          color="secondary"
          disabled={loading}
        />
      </DialogActions>
    </>
  )
}

ChooseAddressSetDialogContent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
}

export default function ChooseAddressSetDialog({ orderId, customerId }) {
  const { editableFields } = useOrder()
  const [open, setOpen] = React.useState(false)

  const enabled = !!customerId && isAllowed('shippingAddress', editableFields)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        label="Ansprechpartner & Lieferadresse auswählen"
        color="primary"
        onClick={handleClickOpen}
        disabled={!enabled}
      >
        <ContentCreate />
      </Button>
      <Dialog
        className="edit-dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          Ansprechpartner & Lieferadresse auswählen
        </DialogTitle>

        <ChooseAddressSetDialogContent
          handleClose={handleClose}
          customerId={customerId}
          orderId={orderId}
        />
      </Dialog>
    </>
  )
}

ChooseAddressSetDialog.propTypes = {
  customerId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
}
