import { Link } from 'react-router-dom'
import Button from 'Components/Common/Buttons/Button'
import { useOrder } from 'OrderContext'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslate } from 'react-admin'

const ITScopeOrderButton = ({ label, variant, disabled, fullWidth }) => {
  const { record } = useOrder()
  const translate = useTranslate()

  if (!record) {
    return null
  }

  return (
    <Button
      component={Link}
      to={`/orders/${record.id}/order-it-scope`}
      color="primary"
      variant={variant}
      fullWidth={fullWidth}
      disableOnGlobalLoad
      disabled={disabled}
      label={translate(label)}
    />
  )
}

ITScopeOrderButton.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
}

ITScopeOrderButton.defaultProps = {
  fullWidth: false,
  disabled: false,
}

export default ITScopeOrderButton
