import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import numbro from 'numbro'
import de from 'numbro/languages/de-DE'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useField } from 'react-final-form'

const useStyles = makeStyles(theme => ({
  root: {
    width: '256px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

numbro.registerLanguage(de)
numbro.setLanguage('de-DE')

const transformValue = (value, mantissa = 2) =>
  numbro(value).format({
    thousandSeparated: true,
    mantissa,
  })

const NumberWithUnitInput = ({
  input: inputFromProps,
  source,
  resource,
  label,
  onChange,
  unit,
  precision,
  disabled,
  defaultValue,
  inputClasses,
  inputProps,
}) => {
  const field = useField(source)

  const input = inputFromProps || field.input

  const [value, setValue] = useState(0)
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    const inputValue = input.value || defaultValue
    if (inputValue !== null) {
      setValue(transformValue(inputValue, precision))
    }
  }, [input.value])

  const handleChange = ({ target: { value } }) => {
    setValue(value)
  }

  const handleBlur = () => {
    const unformattedValue = numbro(value).value() || 0
    setValue(transformValue(unformattedValue, precision))
    setEditing(false)
    input.onChange(unformattedValue)
    onChange(unformattedValue)
  }

  const handleFocus = () => {
    setEditing(true)
    setValue(transformValue(value, precision))
  }

  const handleKeyPress = e => {
    if (/,/.test(e.key) && /,/.test(value)) {
      e.preventDefault()
    }
    if (!/[\d,]/.test(e.key)) {
      e.preventDefault()
    }
  }

  const classes = useStyles()
  const displayValue = editing ? value : `${value} ${unit}`
  const t = useTranslate()

  return (
    <TextField
      disabled={disabled}
      type="tel"
      label={
        label !== false && t(label || `resources.${resource}.fields.${source}`)
      }
      value={displayValue}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      classes={inputClasses || classes}
      variant="filled"
      inputProps={inputProps}
    />
  )
}

NumberWithUnitInput.propTypes = {
  unit: PropTypes.string,
  source: PropTypes.string,
  resource: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  precision: PropTypes.number,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  disabled: PropTypes.bool,
  defaultValue: PropTypes.number,
  inputClasses: PropTypes.object,
  inputProps: PropTypes.object,
}

NumberWithUnitInput.defaultProps = {
  unit: '€',
  label: '',
  precision: 2,
  onChange: () => {},
  disabled: false,
  defaultValue: 0,
  inputClasses: null,
  inputProps: {},
  resource: null,
  input: null,
  source: null,
}

export default NumberWithUnitInput
