import {
  AutocompleteInput,
  SelectInput,
  TextInput,
  usePermissions,
} from 'react-admin'
import { Card, CardActions, CardContent, CardHeader } from '@material-ui/core'
import { flagTypes, manualTransitions, markings } from 'Lib/also/markings'

import CancelDialog from 'Components/Common/Orders/CancelDialog'
import { Inputs } from 'Components/Common/Orders/OrderEdit'
import OrderAdditionalInfo from 'Components/Common/Orders/OrderAdditionalInfo'
import OrderEditActions from 'Components/Common/Orders/OrderEditActions'
import OrderEditToolbar from 'Components/Common/Orders/OrderEditToolbar'
import OrderFlagsShow from 'Components/Common/Orders/OrderFlagsShow'
import OrderGeneralEdit from './OrderGeneralEdit'
import OrderLinesEdit from './OrderLines/OrderLinesEdit'
import ProcessOrderActions from 'Components/Common/Actions/Order/ProcessOrderActions'
import PropTypes from 'prop-types'
import React from 'react'
import StatusBarsContainer from 'Components/Common/Orders/StatusBars/StatusBarsContainer'
import TranslatedTitle from 'Components/Common/TranslatedTitle'
import { isAlsoReseller } from 'Lib/helper'
import legalForms from 'Lib/legalForms'
import moment from 'moment'
import styled from 'styled-components/macro'
import { useOrder } from 'OrderContext'
import { useOrderEditStyles } from 'Components/Common/Orders/OrderEditGridFormView'
import { validateRequired } from 'Resources/Aluxo/devices'
import { validateRequiredEmail } from 'Resources/Common/reseller_smtp_configurations'
import OrderServicesEdit from './OrderLines/OrderServicesEdit'

moment.locale('de')

const Grid = styled.div`
  display: grid;
  grid-template: auto auto 1fr / 1fr 1fr;
  grid-gap: 1em;
`

const GridFormView = ({ invalid, pristine, handleSubmit, submitting }) => {
  const classes = useOrderEditStyles()

  const { resource, basePath, record } = useOrder()

  const { permissions = [] } = usePermissions()

  const Toolbar = React.cloneElement(<OrderEditToolbar />, {
    basePath,
    handleSubmitWithRedirect: handleSubmit,
    handleSubmit,
    invalid,
    pristine,
    record,
    redirect: false,
    resource,
    saving: submitting,
    submitOnEnter: false,
    undoable: true,
    permitted: true,
  })

  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <CancelDialog />
        <div style={{ gridColumn: '1 / 3', minHeight: '20px' }}>
          <StatusBarsContainer markings={markings} orderType="alsoOrders" />
        </div>

        <Card
          className={classes.card} // eslint
          style={{
            gridRow: '2 / 4',
          }}
        >
          <CardHeader action={<OrderEditActions />} />
          <CardContent
            className={classes.cardContent} // eslint-disable-line react/prop-types
          >
            <OrderGeneralEdit />
          </CardContent>
          <CardActions disableSpacing className={classes.cardActions}>
            {Toolbar}
          </CardActions>
        </Card>
        <div>
          <ProcessOrderActions
            orderType="alsoOrders"
            manualTransitions={manualTransitions}
            permitted={isAlsoReseller(permissions)}
          />
        </div>

        <div
          style={{
            gridRow: '3 / 4',
          }}
        >
          <OrderFlagsShow orderType="alsoOrders" flagTypes={flagTypes} />
        </div>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Inputs resource={resource} basePath={basePath} record={record}>
              <TranslatedTitle translateKey="resources.orders.fields.billingAddress.name" />
              <SelectInput
                source="billingAddress.salutation"
                choices={[
                  { id: 'Frau', name: 'Frau' },
                  { id: 'Herr', name: 'Herr' },
                ]}
                validate={validateRequired}
              />
              <TextInput
                source="billingAddress.firstName"
                validate={validateRequired}
              />
              <TextInput
                source="billingAddress.lastName"
                validate={validateRequired}
              />
              <TextInput
                source="billingAddress.company"
                validate={validateRequired}
              />
              <AutocompleteInput
                source="billingAddress.legalForm"
                choices={legalForms.map(f => ({ id: f, name: f }))}
                validate={validateRequired}
              />
              <TextInput
                source="billingAddress.street"
                validate={validateRequired}
              />
              <TextInput
                source="billingAddress.postcode"
                validate={validateRequired}
              />
              <TextInput
                source="billingAddress.city"
                validate={validateRequired}
              />
              <TextInput
                source="billingAddress.phone"
                validate={validateRequired}
              />
              <TextInput
                source="billingAddress.email"
                validate={validateRequiredEmail}
              />
            </Inputs>
          </CardContent>
          <CardActions disableSpacing className={classes.cardActions}>
            {Toolbar}
          </CardActions>
        </Card>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Inputs resource={resource} basePath={basePath} record={record}>
              <TranslatedTitle translateKey="resources.orders.fields.shippingAddress.name" />
              <SelectInput
                source="shippingAddress.salutation"
                choices={[
                  { id: 'Frau', name: 'Frau' },
                  { id: 'Herr', name: 'Herr' },
                ]}
                validate={validateRequired}
              />
              <TextInput
                source="shippingAddress.firstName"
                validate={validateRequired}
              />
              <TextInput
                source="shippingAddress.lastName"
                validate={validateRequired}
              />
              <AutocompleteInput
                source="shippingAddress.legalForm"
                choices={legalForms.map(f => ({ id: f, name: f }))}
                validate={validateRequired}
              />
              <TextInput
                source="shippingAddress.company"
                validate={validateRequired}
              />
              <TextInput
                source="shippingAddress.street"
                validate={validateRequired}
              />
              <TextInput
                source="shippingAddress.postcode"
                validate={validateRequired}
              />
              <TextInput
                source="shippingAddress.city"
                validate={validateRequired}
              />
              <TextInput
                source="shippingAddress.phone"
                validate={validateRequired}
              />
              <TextInput source="shippingAddress.email" />
            </Inputs>
          </CardContent>
          <CardActions disableSpacing className={classes.cardActions}>
            {Toolbar}
          </CardActions>
        </Card>
        <Card className={classes.fullWidthCard}>
          <CardContent className={classes.cardContent}>
            <Inputs resource={resource} basePath={basePath} record={record}>
              <TranslatedTitle translateKey="resources.also_orders.tabs.additionalInfo" />
              <TextInput source="vatId" resettable />
              <TextInput source="domain" resettable />
              <TextInput source="bank" resettable />
              <TextInput source="IBAN" resettable />
              <TextInput source="BIC" resettable />
            </Inputs>
          </CardContent>
          <CardActions disableSpacing className={classes.cardActions}>
            {Toolbar}
          </CardActions>
        </Card>
        <OrderLinesEdit />
        <OrderServicesEdit />
        <Card className={classes.fullWidthCard}>
          <CardContent className={classes.cardContent}>
            <OrderAdditionalInfo />
          </CardContent>
        </Card>
      </Grid>
    </form>
  )
}

GridFormView.propTypes = {
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export default GridFormView
