import {
  Box,
  Collapse,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useLoading, useQuery } from 'react-admin'
import { useFieldArray } from 'react-final-form-arrays'
import { useField, useForm } from 'react-final-form'
import Switch from '@material-ui/core/Switch'
import Paper from '@material-ui/core/Paper'
import { sortBy } from 'lodash'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import arrayMove from 'array-move'

const useStyles = makeStyles(theme => ({
  pictureContainer: {
    width: 200,
    height: 200,
    padding: theme.spacing(1),
  },
  pictureItem: {
    width: 100,
    height: 100,
    margin: theme.spacing(1),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))

const CNetImageItem = ({ index, sizedImages }) => {
  const classes = useStyles()
  const {
    input: { value, onChange },
  } = useField(`cNetImages[${index}]`)

  const handleEnabledChange = event => {
    onChange({ ...value, enabled: event.target.checked })
  }

  const largestImage = sizedImages[0]
  return (
    <Grid item>
      <Paper className={classes.pictureContainer} variant="outlined" square>
        <Box display="flex" justifyContent="center" mb={1}>
          <img
            className={classes.pictureItem}
            src={largestImage.externalUrl || largestImage.file}
            width="100%"
            alt={largestImage.description}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Switch
            checked={value.enabled}
            onChange={handleEnabledChange}
            name="enabled"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </Box>
        <Typography color="textSecondary" gutterBottom>
          {sizedImages.length} Größen
        </Typography>
      </Paper>
    </Grid>
  )
}

const CNetImagesInner = ({ data }) => {
  const loading = useLoading()

  const { fields } = useFieldArray('cNetImages')

  const form = useForm()

  if (loading) {
    return null
  }

  const updatePosition = item => {
    const { destination, source } = item

    const positions = sortBy(
      fields.value.map((i, index) => ({
        position: i.position,
        index,
      })),
      'position',
    )

    const from = source.index
    const to = destination.index

    const newPositions = arrayMove(positions, from, to)

    form.batch(() => {
      newPositions.forEach(({ index }, position) => {
        form.change(`cNetImages[${index}].position`, position)
      })
    })
  }

  return (
    <>
      <DragDropContext onDragEnd={updatePosition}>
        <Droppable droppableId="droppable" direction="horizontal">
          {provided => (
            <Grid
              container
              spacing={1}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {sortBy(
                fields.value.map((field, index) => ({ ...field, index })),
                'position',
              ).map(value => {
                const image = data.find(d => d.id === value.id)

                if (!image || image.image.sizedImages.length === 0) {
                  return null
                }

                const {
                  image: { sizedImages },
                } = image

                return (
                  <Draggable
                    key={image.id}
                    draggableId={image.id}
                    index={value.position}
                  >
                    {provided => (
                      <Grid
                        item
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <CNetImageItem
                          key={image.id}
                          sizedImages={sizedImages}
                          index={value.index}
                        />
                      </Grid>
                    )}
                  </Draggable>
                )
              })}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

const CNetImagesView = ({ imageResource, filterName, ...props }) => {
  const classes = useStyles()

  const [expanded, setExpanded] = useState(true)

  const { data = [], loading } = useQuery({
    type: 'getList',
    resource: imageResource,
    payload: {
      pagination: false,
      sort: { field: 'position', order: 'DESC' },
      filter: { [filterName]: props.record.id },
    },
  })

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <div>
      {loading && <LinearProgress />}
      <Box display="flex" mt={2}>
        <Typography variant="h5">
          {loading
            ? 'CNet Bilder werden geladen '
            : `${data.length} CNet Bilder`}
        </Typography>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CNetImagesInner data={data} />
      </Collapse>
    </div>
  )
}
export default CNetImagesView
