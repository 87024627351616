import React from 'react'
import { useToggle } from 'react-use'
import { Typography, Box } from '@material-ui/core'
import Button from 'Components/Common/Buttons/Button'
import { useTranslate } from 'react-admin'

export const MaybeUndefinedStringWithTranslation = ({
  value,
  i18nkey,
  ...props
}) => {
  const t = useTranslate()
  return (
    <Box color={!value && 'text.disabled'} display="inline" {...props}>
      {value || t(i18nkey)}
    </Box>
  )
}

const ToggleEditable = ({ displayChildren, inputChildren }) => {
  const [editable, toggleEditable] = useToggle(false)

  return (
    <>
      {editable ? inputChildren : <Typography>{displayChildren}</Typography>}
      <Box display="flex" justifyContent="flex-end">
        <Button
          label={editable ? 'actions.editDone' : 'actions.edit'}
          variant="contained"
          onClick={toggleEditable}
        />
      </Box>
    </>
  )
}

export default ToggleEditable
