import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useField } from 'react-final-form'
import DeviceAutoSuggestField from 'Components/Common/InputFields/AutoSuggests/DeviceAutoSuggest/DeviceAutoSuggestField'

const DeviceAutoSuggest = props => {
  const { source } = props

  const { input } = useField(source)

  const selectedItem = useMemo(() => ({ value: input?.value }), [input])

  return (
    <DeviceAutoSuggestField
      {...props}
      selectedItem={selectedItem}
      onChange={selection => {
        if (selection) {
          if (source) {
            input.onChange(selection.value)
          }
          props.onItemSelect(selection)
        }
      }}
    />
  )
}

DeviceAutoSuggest.propTypes = {
  source: PropTypes.string,
  onItemSelect: PropTypes.func,
  withITScope: PropTypes.bool,
}

DeviceAutoSuggest.defaultProps = {
  source: null,
  onItemSelect: () => {},
  withITScope: false,
}

export default DeviceAutoSuggest
