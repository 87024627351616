import { useEffect } from 'react'
import { useDataProvider, useSafeSetState, useVersion } from 'react-admin'

const useLazyQuery = (query, options) => {
  const dataProvider = useDataProvider()
  const { type, resource, payload } = query
  const version = useVersion()
  const requestSignature = JSON.stringify({ query, options, version })

  const [state, setState] = useSafeSetState({
    data: [],
    error: null,
    total: null,
    loading: false,
    loaded: false,
  })

  useEffect(() => {
    if (state.fetch) {
      setState(state => ({ ...state, loading: true }))
      dataProvider[type](resource, payload, options)
        .then(({ data, total }) => {
          setState({
            data,
            total,
            loading: false,
            loaded: true,
            fetch: false,
          })
        })
        .catch(error => {
          setState({
            error,
            loading: false,
            loaded: false,
            fetch: false,
          })
        })
    }
  }, [requestSignature, dataProvider, setState, state.fetch])

  const fetch = () => setState(state => ({ ...state, fetch: true }))

  return [state, fetch]
}

export default useLazyQuery
