import { get } from 'lodash'
import { useTranslate } from 'react-admin'

const FlagListItemTitle = ({ orderType, type, data, value }) => {
  const t = useTranslate()

  if (type === 'albisApplicationCreated' && value) {
    return `${t(`orders.flags.${type}`)} (Antragsnummer: ${get(
      data,
      'albisApplicationNumber',
    )}, Status: ${get(data, 'albisApplicationStatusText') ||
      t('orders.flags.albisApplicationNoStatus')})`
  }
  return t(`${orderType}.flags.${type}`)
}

export default FlagListItemTitle
