import React, { useMemo } from 'react'
import {
  Grid,
  TextField,
  Typography,
  Box,
  Select,
  MenuItem,
  Checkbox,
} from '@material-ui/core'
import { required, useTranslate } from 'react-admin'
import { useField, useForm } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'

import { useBlockLayout, useTable } from 'react-table'
import NumberWithUnitTableInput from 'Components/Common/InputFields/TableInputs/NumberWithUnitTableInput'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from 'Components/Common/Buttons/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import { gql } from '@urql/core'
import { useClient } from 'urql'
import DeviceAutoSuggest from 'Components/Common/InputFields/AutoSuggests/DeviceAutoSuggest/DeviceAutoSuggest'
import clsx from 'clsx'

const GET_DEVICE_VARIANTS_QUERY = gql`
  query deviceVariants($search: String, $storeView: String) {
    deviceVariants(first: 1000, search: $search, storeView: $storeView) {
      edges {
        node {
          id
          name
          sku
          price
          puid
          stock
          margin
          device {
            id
            name
            creditGroup
            manufacturer {
              name
            }
          }
        }
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cell: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))

const EditableNumberWithUnitCell = ({
  row: { index },
  column: { id, name, validate },
}) => (
  <NumberWithUnitTableInput
    name={`${name}.${index}.${id}`}
    unit="€"
    validate={validate}
  />
)

// Create an editable cell renderer
const SKUEditableCell = ({
  row: { index },
  column: { id, autoFocus, name },
}) => {
  const classes = useStyles()

  const {
    input: { value, onChange },
    meta: skuMeta,
  } = useField(`${name}.${index}.${id}`, { validate: required() })

  const {
    input: { value: valueName },
  } = useField(`${name}.${index}.deviceName`)

  const form = useForm()

  const client = useClient()

  const handleBlur = () => {
    if (!valueName && skuMeta.modified && value) {
      form.batch(() => {
        form.change(`${name}.${index}.deviceName`, 'loading')
        form.change(`${name}.${index}.manufacturer`, 'loading')
      })

      client
        .query(
          GET_DEVICE_VARIANTS_QUERY,
          {
            search: value,
          },
          { requestPolicy: 'network-only' },
        )
        .toPromise()
        .then(res => {
          const variant = res.data.deviceVariants.edges.find(
            d => d.node.sku === value,
          )?.node

          form.batch(() => {
            form.change(`${name}.${index}.deviceName`, variant?.name || '')
            form.change(
              `${name}.${index}.manufacturer`,
              variant?.device.manufacturer.name || '',
            )
          })
        })
    }
  }

  return (
    <TextField
      label={null}
      value={value}
      onChange={onChange}
      className={classes.textField}
      onBlur={handleBlur}
      fullWidth
      autoFocus={autoFocus}
      error={skuMeta.error}
    />
  )
}

const TypographyCell = ({ value }) => {
  const classes = useStyles()
  return (
    <Typography noWrap className={classes.textField}>
      {value}
    </Typography>
  )
}

const EditableTextCell = ({
  column: {
    id,
    autoFocus,
    name,
    loadingIndicator,
    format,
    parse,
    multiline,
    validate,
  },
  row: { index },
}) => {
  const classes = useStyles()

  const {
    input: { value, onChange },
    meta,
  } = useField(`${name}.${index}.${id}`, {
    validate: validate || required(),
    format,
    parse,
  })

  return (
    <Typography noWrap className={classes.textField}>
      {loadingIndicator && value === 'loading' ? (
        <CircularProgress size="1rem" />
      ) : (
        <TextField
          label={null}
          value={value}
          onChange={onChange}
          autoFocus={autoFocus}
          fullWidth
          error={meta.error}
          multiline={multiline}
        />
      )}
    </Typography>
  )
}

const EditableDropdownCell = ({
  column: { id, name, format, parse, validate, options },
  row: { index },
}) => {
  const classes = useStyles()

  const {
    input: { value, onChange },
  } = useField(`${name}.${index}.${id}`, {
    validate: validate || required(),
    format,
    parse,
  })

  return (
    <Select
      className={classes.textField}
      value={value}
      variant="outlined"
      onChange={onChange}
      label={false}
    >
      {options.map(({ value, label }) => (
        <MenuItem value={value}>{label}</MenuItem>
      ))}
    </Select>
  )
}

const EditableCheckboxCell = ({
  column: { id, name, format, parse, validate },
  row: { index },
}) => {
  const classes = useStyles()

  const {
    input: { checked, onChange },
  } = useField(`${name}.${index}.${id}`, {
    validate: validate || required(),
    type: 'checkbox',
    format,
    parse,
  })

  return (
    <Checkbox
      className={classes.textField}
      checked={checked}
      variant="outlined"
      onChange={onChange}
      label={false}
    />
  )
}

const ActionsCell = ({ row: { index }, column: { remove } }) => {
  const handleRemove = () => {
    remove(index)
  }
  return (
    <Button color="secondary" onClick={handleRemove}>
      <DeleteIcon />
    </Button>
  )
}

const defaultColumn = {
  Cell: TypographyCell,
}

const validate = entries => {
  const errors = entries?.map(entry => {
    const error = {}

    if (!entry.deviceName) {
      error.sku = 'unknown'
    }

    return error
  })

  return errors
}

const DevicesTable = ({ name, spread }) => {
  const { fields } = useFieldArray(name, {
    validate,
  })

  // HNS-00004
  // LQH-00003
  const t = useTranslate()

  const classes = useStyles()

  const data = useMemo(() => fields.value || [], [fields.value])

  const columns = React.useMemo(
    () =>
      [
        {
          Header: (
            <Typography>
              {t('resources.external_protection_plans.fields.devices.sku')}
            </Typography>
          ),
          accessor: 'sku',
          name: fields.name,
          Cell: SKUEditableCell,
          autoFocus: true,
        },
        {
          Header: (
            <Typography>
              {t(
                'resources.external_protection_plans.fields.devices.deviceName',
              )}
            </Typography>
          ),
          accessor: 'deviceName',
          width: 300,
          name: fields.name,
          Cell: EditableTextCell,
          loadingIndicator: true,
        },
        {
          Header: (
            <Typography>
              {t(
                'resources.external_protection_plans.fields.devices.manufacturer',
              )}
            </Typography>
          ),
          accessor: 'manufacturer',
          width: 300,
          name: fields.name,
          Cell: EditableTextCell,
          loadingIndicator: true,
        },
        spread
          ? {
              Header: (
                <Typography>
                  {t(
                    'resources.external_protection_plans.fields.devices.serialNumbers',
                  )}
                </Typography>
              ),
              accessor: 'serials',
              width: 300,
              name: fields.name,
              Cell: EditableTextCell,
              format: arr => arr && arr.join('\n'),
              parse: value => value.split(/[\n,;]+/),
              validate: value => {
                if (!value) {
                  return 'required'
                }
                if (value.length === 0) {
                  return 'required'
                }
                return undefined
              },
              multiline: true,
            }
          : {
              Header: (
                <Typography>
                  {t(
                    'resources.external_protection_plans.fields.devices.serialNumber',
                  )}
                </Typography>
              ),
              accessor: 'serial',
              width: 300,
              name: fields.name,
              Cell: EditableTextCell,
            },

        {
          Header: (
            <Typography>
              {t(
                'resources.external_protection_plans.fields.devices.price.create',
              )}
            </Typography>
          ),
          accessor: `price`,
          name: fields.name,
          Cell: EditableNumberWithUnitCell,
          validate: value => {
            if (!value) {
              return 'required'
            } else if (value <= 0) {
              return 'should be greater than 0'
            }

            return undefined
          },
        },
        !spread && {
          Header: (
            <Typography>
              {t('resources.external_protection_plans.fields.devices.duration')}
            </Typography>
          ),
          accessor: `duration`,
          name: fields.name,
          Cell: EditableDropdownCell,
          options: [
            { value: 24, label: '24 Monate' },
            { value: 36, label: '36 Monate' },
          ],
        },
        !spread && {
          Header: (
            <Typography>
              {t(
                'resources.external_protection_plans.fields.devices.theftProtection',
              )}
            </Typography>
          ),
          accessor: `theft`,
          name: fields.name,
          Cell: EditableCheckboxCell,
        },
        {
          accessor: 'actions',
          Cell: ActionsCell,
          remove: idx => fields.remove(idx),
        },
      ].filter(c => c),
    [spread],
  )

  const addDeviceToTable = (item = null) => {
    const defaultValues = {
      sku: null,
      deviceName: null,
      manufacturer: null,
      price: 0,
      duration: 36,
      theft: false,
    }

    if (item) {
      if (item.type === 'itscope') {
        fields.push({
          ...defaultValues,
          sku: item.sku,
          deviceName: item.productName,
          manufacturer: item.manufacturerName,
        })
      } else {
        fields.push({
          ...defaultValues,
          sku: item.sku,
          deviceName: item.name,
          manufacturer: item.device.manufacturer.name,
        })
      }
    } else {
      fields.push(defaultValues)
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout,
  )

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div {...getTableProps()} className="table">
          <div>
            {headerGroups.map(headerGroup => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map(column => (
                  <div {...column.getHeaderProps()} className="th">
                    {column.render('Header')}
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <div {...row.getRowProps()} className="tr">
                  {row.cells.map(cell => (
                    <div
                      {...cell.getCellProps()}
                      className={clsx('td', classes.cell)}
                    >
                      {cell.render('Cell')}
                    </div>
                  ))}
                </div>
              )
            })}
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <Box display="flex" alignItems="center" height="100%">
              <Button
                type="button"
                label="Manuell Hinzufügen"
                color="primary"
                variant="contained"
                disableOnGlobalLoad={false}
                onClick={() => {
                  // instead of onClick={addDeviceToTable}, do not pass event to addDeviceToTable
                  addDeviceToTable()
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={10}>
            <DeviceAutoSuggest
              label="Produkt suchen"
              onItemSelect={addDeviceToTable}
              includeItScope
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

DevicesTable.defaultProps = {
  spread: false,
}

export default DevicesTable
