import React from 'react'
import { get } from 'lodash'
import { useTranslate } from 'react-admin'
import grey from '@material-ui/core/colors/grey'
import PropTypes from 'prop-types'

const lightBlack = grey[900]

const OrderTitle = ({ record }) => {
  const translate = useTranslate()
  const resourceName = translate('resources.orders.name', { smart_count: 1 })
  const orderNumber = get(record, 'orderNumber')
  const orderType = record['@type'] === 'also_order' ? 'alsoOrders' : 'orders'
  const marking = translate(`${orderType}.markings.${get(record, 'marking')}`)
  const affiliate = get(record, 'affiliate')
  return (
    <span>
      {`${resourceName} ${orderNumber || '-----'} ${
        affiliate ? `(${translate('resources.orders.fields.affiliate')})` : ''
      }`}
      <br />
      <span
        style={{
          color: lightBlack,
        }}
      >
        ({marking})
      </span>
    </span>
  )
}

OrderTitle.propTypes = {
  record: PropTypes.object,
}

OrderTitle.defaultProps = {
  record: null,
}

export default OrderTitle
