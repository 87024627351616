import { Paper } from '@material-ui/core'
import { Input } from 'Components/Common/InputFields/AutoSuggests/Lib/renderFunctions'
import Downshift from 'downshift'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { BooleanInput } from 'react-admin'
import { useField } from 'react-final-form'
import SelectList from 'Components/Common/InputFields/AutoSuggests/Lib/SelectList'
import FetchData from './FetchData'
import getSuggestions from './getSuggestions'

const itemToString = item => item.label

const ServiceExtensionAutoSuggest = ({
  label,
  source,
  onItemSelect,
  withITScope,
  autoFocus,
}) => {
  const {
    input: { value, onChange },
  } = useField(source)

  const selectedItem = useMemo(() => ({ value }), [value])

  const {
    input: { value: includeItScope },
  } = useField('includeItScope', {
    initialValue: false,
  })

  return (
    <Downshift
      onChange={selection => {
        if (selection) {
          onChange(selection.value)
          onItemSelect(selection)
        }
      }}
      itemToString={itemToString}
      selectedItem={selectedItem}
    >
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        isOpen,
        selectedItem,
        highlightedIndex,
        inputValue,
      }) => (
        <div>
          {withITScope && (
            <BooleanInput label="mit ITScope" source="includeItScope" />
          )}
          <Input
            label={label}
            fullWidth
            autoFocus={autoFocus}
            InputProps={getInputProps()}
          />

          <Paper
            style={{
              display: isOpen ? 'block' : 'none',
              visibility: isOpen ? 'visible' : 'hidden',
            }}
            square
            {...getMenuProps()}
          >
            {isOpen ? (
              <FetchData
                includeItScope={includeItScope}
                itScopeSearchValue={inputValue}
              >
                {({ serviceExtensions, itScopeData }) => {
                  const suggestions = getSuggestions({
                    serviceExtensions,
                    itScopeData,
                    inputValue,
                  })

                  const rows = []
                  suggestions.forEach(group => {
                    rows.push({ label: group.title })
                    group.suggestions.forEach(suggestion => {
                      rows.push(suggestion)
                    })
                  })

                  return (
                    <SelectList
                      rows={rows}
                      getItemProps={getItemProps}
                      highlightedIndex={highlightedIndex}
                      selectedItem={selectedItem}
                      itemToString={itemToString}
                    />
                  )
                }}
              </FetchData>
            ) : null}
          </Paper>
        </div>
      )}
    </Downshift>
  )
}

ServiceExtensionAutoSuggest.propTypes = {
  source: PropTypes.string,
  onItemSelect: PropTypes.func,
  withITScope: PropTypes.bool,
}

ServiceExtensionAutoSuggest.defaultProps = {
  source: null,
  onItemSelect: () => {},
  withITScope: false,
}

export default ServiceExtensionAutoSuggest
