import { Button } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import Orders from 'Components/Common/Dashboard/Orders'
import React, { useState } from 'react'
import { useQuery, useTranslate } from 'react-admin'
import styled from 'styled-components/macro'

const Grid = styled.div`
  margin-top: 2em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
  grid-gap: 1em;
  grid-auto-rows: minmax(125px, max-content);
`

const HeaderBar = styled.div`
  margin-top: -0.5em;
  position: relative;
  background-color: grey;
  display: flex;
  justify-content: flex-end;
  margin-right: -1.5em;
`

const markings = ['opened', 'transmitted']

const AlsoResellerDashboard = () => {
  const [filterEmpty, setFilterEmpty] = useState(true)
  const t = useTranslate()

  const { data: orders, loading } = useQuery({
    type: 'getList',
    resource: 'also_orders',
    payload: {
      pagination: false,
      sort: {},
      filter: {},
    },
  })

  const toggleFilterEmpty = () => {
    setFilterEmpty(!filterEmpty)
  }

  return (
    <div>
      <HeaderBar>
        <Button labelStyle={{ color: '#ffffff' }} onClick={toggleFilterEmpty}>
          {filterEmpty ? (
            <Visibility color="#ffffff" />
          ) : (
            <VisibilityOff color="#ffffff" />
          )}
          {filterEmpty
            ? t('dashboard.actions.showAllOrders')
            : t('dashboard.actions.filterOrders')}
        </Button>
      </HeaderBar>
      <Grid>
        {markings.map(marking => (
          <Orders
            key={marking}
            marking={marking}
            also
            orders={orders}
            fetching={loading}
            filterEmpty={filterEmpty}
          />
        ))}
      </Grid>
    </div>
  )
}

export default AlsoResellerDashboard
