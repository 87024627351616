import { Card } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import useMutationPromise from 'Hooks/useMutationPromise'
import produce from 'immer'
import clamp from 'lodash/clamp'
import findKey from 'lodash/findKey'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import {
  Title,
  Toolbar,
  useLoading,
  useMutation,
  useTranslate,
} from 'react-admin'
import { useParams } from 'react-router'
import SaveButton from './SaveButton'
import SortableList from './SortableList'
import SortActions from './SortActions'

const Sort = ({ sortProperty }) => {
  const [data, setData] = useState([])
  const [groupProperty, setGroupProperty] = useState(null)
  const [displayProperty, setDisplayProperty] = useState(null)
  const loading = useLoading()
  const translate = useTranslate()
  const { resource } = useParams()

  const [query] = useMutationPromise()

  const fetchData = async () => {
    const res = await query({
      type: 'getList',
      resource,
      payload: {
        pagination: false,
        sort: false,
        filter: false,
      },
    })

    const context = await query({
      type: 'getContext',
      resource: null,
      payload: {
        context: res.context,
      },
    })

    const sortGroup = get(context, 'data.@context.position.sortGroup')
    const displayProperty = findKey(
      get(context, 'data.@context'),
      property => property.displayProperty,
    )

    setData(res.data)
    setGroupProperty(sortGroup)
    setDisplayProperty(displayProperty)
  }

  const moveItem = async (item, position) => {
    const collection = data.filter(i => i.area === item.area)

    const newPosition = clamp(position, 0, collection.length - 1)

    const newData = produce(data, draft => {
      const itemToReplace = draft.find(
        i =>
          i.area === item.area &&
          i[sortProperty] === newPosition &&
          i.id !== item.id,
      )
      if (itemToReplace) {
        itemToReplace[sortProperty] = item[sortProperty]
      }
      const movedItem = draft.find(i => i.id === item.id)
      movedItem[sortProperty] = newPosition
      movedItem.changed = true
    })

    setData([...newData])
  }

  const [mutate] = useMutation()

  const handleSubmitWithRedirect = async () => {
    const promises = data
      .filter(item => item.changed)
      .map(
        item =>
          new Promise((resolve, reject) => {
            mutate(
              {
                type: 'update',
                resource,
                payload: { id: item.id, data: { position: item.position } },
              },
              {
                onSuccess: () => resolve(),
                onFailure: error => reject(error),
              },
            )
          }),
      )

    await Promise.all(promises)
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [resource])

  if (loading) {
    return <LinearProgress mode="indeterminate" />
  }
  if (data.length === 0) {
    return <span>{translate('errors.noData')}</span>
  }

  return (
    <Card>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Title
          title={translate(`resources.${resource}.name`, { smart_count: 2 })}
        />
        <SortActions resource={resource} handleRefreshClick={fetchData} />
      </div>
      <SortableList
        resource={resource}
        items={data}
        moveItem={moveItem}
        sortProperty={sortProperty}
        displayProperty={displayProperty}
        groupProperty={groupProperty}
      />
      <Toolbar>
        <SaveButton handleSubmit={handleSubmitWithRedirect} saving={loading} />
      </Toolbar>
    </Card>
  )
}

Sort.propTypes = {
  sortProperty: PropTypes.string,
}

Sort.defaultProps = {
  sortProperty: 'position',
}

export default Sort
