/* eslint-disable camelcase */
import { get, last, sumBy } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import OrderLinesTable from './OrderLinesTable'

export const calculateBundlePrice = ({ bundleLine, bundledLines }) => {
  const price = sumBy(bundledLines, 'price')

  const newMargin = bundleLine.margin

  return {
    ...bundleLine,
    netPrice: price * (1 + bundleLine.margin / 100),
    price,
    margin: newMargin,
  }
}

const OrderLines = props => {
  const { values: lines, perPage, handleSubmit, mode } = props

  const {
    device_order_lines,
    promo_bundle_order_lines,
    protection_plan_order_lines,
    product_order_lines,
    service_extension_order_lines,
    generic_order_lines,
  } = lines

  // calculate bundle line prices
  const bundleLines = Object.values(promo_bundle_order_lines.data).map(
    bundleLine => {
      const bundledLines = [
        device_order_lines.data[last(bundleLine.deviceOrderLine.split('/'))],
        ...bundleLine.productOrderLines.map(iri => {
          const split = iri.split('/')
          const reference = split[2]
          const id = split[3]
          return lines[reference].data[id]
        }),
      ]

      if (bundleLine.type === 'internal') {
        return calculateBundlePrice({ bundleLine, bundledLines })
      }

      return {
        ...bundleLine,
      }
    },
  )

  // group these lines into workplaces
  const workplaces = Object.values(device_order_lines.data).map(workplace => {
    const serviceLines = get(workplace, 'serviceLines_reference_ids', []).map(
      id => lines.service_order_lines.data[id],
    )

    const protectionPlanLine =
      workplace.protectionPlanLine_reference_id &&
      protection_plan_order_lines.data[
        workplace.protectionPlanLine_reference_id
      ]

    const productLines = get(workplace, 'productLines_reference_ids', []).map(
      id => product_order_lines.data[id],
    )

    const serviceExtensionLines = get(
      workplace,
      'serviceExtensionLines_reference_ids',
      [],
    ).map(id => service_extension_order_lines.data[id])

    const bundleLine = bundleLines.find(
      line => line.id === get(workplace, 'bundleLine_reference_id'),
    )

    return {
      ...workplace,
      serviceLines,
      protectionPlanLine,
      productLines,
      serviceExtensionLines,
      bundleLine,
    }
  })

  const rest = Object.values(lines)
    .filter(({ reference }) => reference !== 'device_order_lines')
    .reduce(
      (acc, { data }) => [
        ...acc,
        ...Object.values(data).filter(d => !d.deviceOrderLine),
      ],
      [],
    )

  const restLines = rest.map((line, index) => ({
    ...line,
    index: Object.values(lines).length - rest.length + index + 1,
  }))

  const linesToCalculate = [
    ...Object.values(protection_plan_order_lines.data),
    ...Object.values(lines.service_order_lines.data),
    ...Object.values(service_extension_order_lines.data),
    ...Object.values(generic_order_lines.data),
    ...Object.values(device_order_lines.data).filter(
      ({ bundleLine }) => !bundleLine,
    ),
    ...Object.values(product_order_lines.data).filter(
      ({ bundleLine }) => !bundleLine,
    ),
    ...bundleLines,
  ]

  const totalNetSum = sumBy(
    linesToCalculate,
    ({ netPrice, quantity, deviceOrderLineDiscount }) => {
      const absoluteDeviceOrderLineDiscount = get(
        deviceOrderLineDiscount,
        'absoluteDiscount',
        0,
      )

      return quantity * (netPrice - absoluteDeviceOrderLineDiscount)
    },
  )

  const totalMargin = sumBy(
    linesToCalculate.filter(({ margin }) => margin),
    ({ price, quantity, margin, deviceOrderLineDiscount }) => {
      const absoluteDeviceOrderLineDiscount = get(
        deviceOrderLineDiscount,
        'absoluteDiscount',
        0,
      )
      return (
        quantity * ((price - absoluteDeviceOrderLineDiscount) * (margin / 100))
      )
    },
  )

  const totalDiscount = sumBy(
    linesToCalculate.filter(({ discount }) => discount),
    ({ quantity, discount }) => quantity * discount,
  )

  return (
    <form onSubmit={handleSubmit}>
      <OrderLinesTable
        workplaces={workplaces}
        rest={restLines}
        totalNetSum={totalNetSum}
        totalMargin={totalMargin}
        totalDiscount={totalDiscount}
        perPage={perPage}
        mode={mode}
      />
    </form>
  )
}

OrderLines.propTypes = {
  values: PropTypes.object.isRequired,
  perPage: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
  }),
}

OrderLines.defaultProps = {
  perPage: 500,
  sort: { field: 'id', order: 'DESC' },
}

export default OrderLines
