import { Field } from 'react-final-form'
import React from 'react'
import { Labeled } from 'react-admin'
import PropTypes from 'prop-types'

const renderArrayField = ({ input, label, joinString }) => (
  <Labeled label={label}>
    <span>{(input.value || []).join(joinString)}</span>
  </Labeled>
)

renderArrayField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.node.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  joinString: PropTypes.string.isRequired,
}

const JoinedArrayInputField = ({ source, ...rest }) => (
  <Field name={source} component={renderArrayField} {...rest} />
)

JoinedArrayInputField.propTypes = {
  source: PropTypes.string.isRequired,
  addLabel: PropTypes.bool,
  joinString: PropTypes.string,
}

JoinedArrayInputField.defaultProps = {
  addLabel: false,
  joinString: ', ',
}

export default JoinedArrayInputField
