import ITScopeCartButton from 'Components/Aluxo/ActionButtons/ITScopeCartButton'
import ITScopeOrderButton from 'Components/Aluxo/ITScope/ITScopeOrderButton'
import React, { useContext } from 'react'
import { ResellerContext } from 'ResellerContext'
import { useOrder } from 'OrderContext'

const ITScopeOrderActions = ({ record }) => {
  const { flags: resellerFlags } = useContext(ResellerContext)

  return (
    <>
      {resellerFlags.directITScopeOrder && (
        <ITScopeOrderButton
          data={record}
          variant="contained"
          fullWidth
          label="actions.createITScopeOrder"
        />
      )}
      <ITScopeCartButton
        color="primary"
        variant="contained"
        data={record}
        fullWidth
      />
    </>
  )
}

const SpecialActions = () => {
  const { record } = useOrder()
  if (record.workflow === 'rent_order') {
    switch (record.marking) {
      case 'reseller_signed':
        return <ITScopeOrderActions record={record} />
      default:
        return null
    }
  } else if (record.workflow === 'buy_order') {
    switch (record.marking) {
      case 'opened':
        return <ITScopeOrderActions record={record} />
      default:
        return null
    }
  }
  return null
}

export default SpecialActions
