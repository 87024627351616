import AffiliateField from 'Components/Common/ViewFields/AffiliateField'
import BooleanField from 'Components/Common/ViewFields/BooleanField'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import DateField from 'Components/Common/ViewFields/DateField'
import WorkflowField from 'Components/Common/ViewFields/WorkflowField'
import { isReseller } from 'Lib/helper'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Datagrid, EditButton, List, TextField } from 'react-admin'
import OverdueField from './Fields/OverdueField'
import StatusField from './Fields/StatusField'
import OrderListActions from './ListActions'
import OrderFilter from './OrderFilter'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

moment.locale('de')

const OrderList = ({ permissions, ...props }) => (
  <List
    actions={<OrderListActions />}
    {...props}
    filters={<OrderFilter />}
    filter={{ offer: false }}
    pagination={<CustomPagination />}
    sort={{ field: 'orderNumber', order: 'DESC' }}
  >
    <Datagrid>
      <TextField source="orderNumber" />
      <BooleanField source="priority" hideFalseValues />
      <StatusField source="marking" sortable={false} />
      <OverdueField source="overdue" />
      <WorkflowField source="workflow" />
      <DateField source="created" format="DD.MM.YYYY" />
      <TextField source="billingAddress.company" />
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <TextField source="storeView.name" />
      )}

      <CurrencyField
        label="resources.orders.fields.totalVolume"
        source="totalVolume"
      />
      {isReseller(permissions) && (
        <AffiliateField source="affiliate" sortable={false} />
      )}
      <EditButton />
    </Datagrid>
  </List>
)

OrderList.propTypes = {
  permissions: PropTypes.array.isRequired,
}

export default OrderList
