import { ListItemIcon, ListItemText } from '@material-ui/core'
import MUIMenu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import { LibraryBooks } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'
import { MenuItemLink } from 'react-admin'
import SubMenu from 'Components/Common/Layout/SubMenu'
import { TranslatedResourceName } from 'Components/Common/Layout/MenuTranslations'
import { useMenuStyles } from 'Components/Common/Layout/Menu'

const WebsiteDataSubMenu = ({ onMenuClick }) => {
  const classes = useMenuStyles()
  return (
    <SubMenu
      mainItem={set => (
        <Tooltip
          title={<TranslatedResourceName resource="website_data" />}
          placement="right"
        >
          <MenuItem
            onClick={({ currentTarget }) => set(currentTarget)}
            className={classes.menu}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <LibraryBooks />
            </ListItemIcon>
            <ListItemText
              inset
              primary={<TranslatedResourceName resource="website_data" />}
              className={classes.listItemText}
            />
          </MenuItem>
        </Tooltip>
      )}
    >
      {(target, hide) => (
        <MUIMenu anchorEl={target} open={Boolean(target)} onClose={hide}>
          <MenuItemLink
            to="/slides"
            primaryText={<TranslatedResourceName resource="slides" />}
            onClick={hide}
          />
          <MenuItemLink
            to="/benefits"
            primaryText={<TranslatedResourceName resource="benefits" />}
            onClick={hide}
          />
          <MenuItemLink
            to="/testimonials"
            primaryText={<TranslatedResourceName resource="testimonials" />}
            onClick={hide}
          />
          <MenuItemLink
            to="/team_members"
            primaryText={<TranslatedResourceName resource="team_members" />}
            onClick={hide}
          />
          <MenuItemLink
            to="/marketing_logos"
            primaryText={<TranslatedResourceName resource="marketing_logos" />}
            onClick={hide}
          />
          <MenuItemLink
            to="/pages"
            primaryText={<TranslatedResourceName resource="pages" />}
            onClick={hide}
          />
          <MenuItemLink
            to="/links"
            primaryText={<TranslatedResourceName resource="links" />}
            onClick={hide}
          />
        </MUIMenu>
      )}
    </SubMenu>
  )
}

WebsiteDataSubMenu.propTypes = {
  onMenuClick: PropTypes.func,
}

WebsiteDataSubMenu.defaultProps = {
  onMenuClick: () => null,
}

export default WebsiteDataSubMenu
