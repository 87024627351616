import { sortBy, sumBy, sum, get, round } from 'lodash'
import md5 from 'md5'
import currencyText from './currencyText'

export const getInitialOrderComment = order => {
  const comments = Object.values(order.lines).map(
    ({ discountComposition, casNumber, totalQuantity }) => {
      const discountComments = discountComposition
        ? discountComposition
            .map(
              partialDiscount =>
                `${partialDiscount.description} (${round(
                  partialDiscount.relativeDiscount,
                  2,
                )} %): ${currencyText(
                  round(partialDiscount.discount, 2),
                )}/Gerät`,
            )
            .join(' | ')
        : ''

      const relativeDiscountSum = round(
        sumBy(discountComposition, 'relativeDiscount'),
        2,
      )
      const absoluteDiscountSum = round(
        sumBy(discountComposition, 'discount') * totalQuantity,
        2,
      )

      const comment =
        [
          casNumber && `ACHTUNG / Preisanpassung / ${casNumber}`,
          discountComments && `${discountComments}`,
          (relativeDiscountSum > 0 || absoluteDiscountSum > 0) &&
            `Gesamtrabatt (${relativeDiscountSum} %): ${currencyText(
              absoluteDiscountSum,
            )}`,
        ]
          .filter(v => v)
          .join(' | ') || ''

      return comment
    },
  )

  return comments.filter(c => c).join('\n')
}

const calculateTotal = orders =>
  sum(Object.values(orders).map(o => o.total + o.totalShipping))

// Might use this later
// eslint-disable-next-line no-unused-vars
const addShippingToPrice = ({ supplierPrice, distributors, orders = [] }) => {
  const { shippingFixed, shippingVariable } = distributors.find(
    d => d.supplierId === supplierPrice.supplierId,
  )

  let totalShipping = shippingFixed
  let priceInclShipping = supplierPrice.price

  // Check if we have a order for this supplier
  const orderAtSupplier = orders.find(
    o => o.supplierId === supplierPrice.supplierId,
  )

  if (orderAtSupplier) {
    if (shippingVariable > 0) {
      const supplierOrderTotal = sumBy(
        Object.values(orderAtSupplier.lines),
        'totalPrice',
      )
      totalShipping += (shippingVariable / 100) * supplierOrderTotal
    }

    const totalQuantity = sumBy(
      Object.values(orderAtSupplier.lines),
      'quantity',
    )
    const shippingPerItem = totalShipping / totalQuantity

    priceInclShipping += shippingPerItem
  }

  return { ...supplierPrice, priceInclShipping, totalShipping }
}

const createOrderSplit = (lines, distributors) => {
  const orders = []

  // eslint-disable-next-line
  for (const line of lines) {
    const {
      totalQuantity,
      prices: unsortedPrices,
      name,
      manufacturerSKU,
      sku,
      discount,
      deepLink,
      discountComposition,
      casNumber,
    } = line

    // All prices sorted by preferred distributor first and then price incl shipping
    const prices = sortBy(unsortedPrices, ['ranking', 'priceInclShipping']).map(
      p => ({
        ...p,
        supplierName: distributors.find(d => d.supplierId === p.supplierId)
          ?.name,
      }),
    )

    const bestPriceWithSufficientStock = prices.find(
      p => Number(p.stock) > totalQuantity,
    )

    if (!bestPriceWithSufficientStock) {
      // eslint-disable-next-line no-continue
      continue
    }

    const { supplierId, puid } = bestPriceWithSufficientStock

    const idx = orders.findIndex(o => o.supplierId === supplierId)

    const order =
      idx >= 0
        ? orders[idx]
        : {
            supplierId,
            supplier: distributors.find(d => d.supplierId === supplierId),
            lines: {},
          }

    const newLine = {
      quantity: totalQuantity,
      puid,
      sku,
      discount,
      name,
      manufacturerSKU,
      deepLink,
      alternatives: prices,
      price: bestPriceWithSufficientStock,
      totalPrice: totalQuantity * bestPriceWithSufficientStock.price,
      totalPriceInclShipping:
        totalQuantity * bestPriceWithSufficientStock.priceInclShipping,
      discountComposition,
      totalQuantity,
      casNumber,
    }

    order.lines[puid] = newLine

    if (idx >= 0) {
      orders.splice(idx, 1, order)
    } else {
      orders.push(order)
    }
  }

  const ordersWithTotal = orders.map(o => ({
    ...o,
    total: Object.values(o.lines).reduce(
      (acc, line) => acc + line.totalPrice,
      0,
    ),
    comment: getInitialOrderComment(o),
  }))

  return ordersWithTotal
}

const createHash = ({ orders, distributors }) => {
  const hashObj = distributors.map(({ supplierId }) => {
    const order = orders.find(o => o.supplierId === supplierId)

    return order
      ? {
          supplierId,
          total: order.total,
          lines: Object.values(order.lines).map(
            ({ puid, quantity, totalPrice }) => ({
              puid,
              quantity,
              totalPrice,
            }),
          ),
        }
      : { supplierId }
  })

  const hash = md5(JSON.stringify(hashObj))

  return hash
}

export const addShippingToOrder = ({ orders, distributors }) =>
  orders.map(order => {
    const totalItems = sum(Object.values(order.lines).map(l => l.quantity))
    const supplier = distributors.find(d => d.supplierId === order.supplierId)

    const { shippingFixed, shippingVariable } = supplier

    let orderShippingVariable = 0

    if (shippingVariable) {
      const orderTotal = sum(Object.values(order.lines).map(l => l.totalPrice))
      orderShippingVariable = (shippingVariable / 100) * orderTotal
    }
    const totalShipping = shippingFixed + orderShippingVariable
    const shippingPerItem = totalShipping / totalItems

    return {
      ...order,
      totalShipping,
      shippingPerItem,
    }
  })

const mapPrice = (ranking, discount = 0) => p => {
  const price = parseFloat(p.price)

  return {
    supplierId: p.supplierId,
    supplierSKU: p.supplierSKU,
    ranking,
    puid: p.puid,
    price: discount > 0 ? price - discount : price,
    priceInclShipping: p.discount > 0 ? price - p.discount : price,
    stock: parseFloat(p.stock),
  }
}

const calculateOrders = (initialLines, distributors) => {
  const errors = []

  const hashedOrders = []

  initialLines.forEach(
    ({
      puids,
      sku,
      name,
      totalQuantity,
      totalAvailableStock,
      deepLink,
      prices,
      preferredPrices,
      discountComposition,
      discount,
      casNumber,
    }) => {
      if (
        ![...prices, ...preferredPrices].some(
          p => Number(p.stock) > totalQuantity,
        )
      ) {
        errors.push({
          status: 'product not on stock',
          message: `Not enough items on stock for puid ${puids}`,
          details: {
            name,
            totalQuantity,
            totalAvailableStock,
            discountComposition,
            discount,
            casNumber,
            puids,
            deepLink,
            manufacturerSKU: sku,
            alternatives: [...preferredPrices, ...prices],
          },
        })
      }
    },
  )

  let lines

  // Parse the strings to floats from the itScope API :/
  lines = initialLines.map(line => ({
    ...line,
    name: get(Object.values(line.details), '0.productName'),
    manufacturerSKU: get(Object.values(line.details), '0.manufacturerSKU'),
    prices: [
      ...line.prices.map(mapPrice(2, line.discount)),
      ...line.preferredPrices.map(mapPrice(2, line.discount)),
    ],
  }))

  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < 10; index++) {
    let orders = []
    // create a naive ordersplit without shipping
    orders = createOrderSplit(lines, distributors)

    // then add shipping to the ordersplit
    orders = addShippingToOrder({ orders, distributors })
    // and calculate the total incl. shipping
    const total = calculateTotal(orders)

    const hash = createHash({ orders, distributors })

    if (hashedOrders.some(o => o.hash === hash)) {
      break
    }
    hashedOrders.push({ hash, orders, total })
    // update the lines with the prices incl. shipping per item (for previous order!)
    lines = lines.map(line => ({
      ...line,
      prices: line.prices.map(p => {
        const orderAtSupplier = orders.find(o => o.supplierId === p.supplierId)
        return orderAtSupplier
          ? {
              ...p,
              priceInclShipping: p.price + orderAtSupplier.shippingPerItem,
            }
          : { ...p }
      }),
    }))
  }

  const validOrders = hashedOrders.filter(orderSplit => {
    const valid = orderSplit.orders.reduce((prev, o) => {
      const supplier = distributors.find(d => d.supplierId === o.supplierId)
      const { shippingFixed, shippingVariable } = supplier
      if (o.totalShipping === 0 && shippingFixed + shippingVariable > 0) {
        return false
      }
      return prev && true
    }, true)
    return valid
  })

  return { orders: validOrders, errors }
}

// const { lines, distributors } = require('./tmp-data')

// const { orders } = calculateOrders(lines, distributors)

export default calculateOrders
