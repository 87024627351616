const monthlyPrices = ['12', '24', '36'].map(p => ({
  option_reference: `Konfiguration bei ${p} Monaten Laufzeit`,
  price: `Preis bei ${p} Monaten Laufzeit`,
  margin: `Marge bei ${p} Monaten Laufzeit`,
  netPrice: `Netto Preis bei ${p} Monaten Laufzeit`,
}))

export default {
  gsales_settings: {
    name: 'GSales Einstellungen',
    fields: {
      customerId: 'Kunden ID von ALBIS',
      wsdlUrl: 'WSDL Url',
      token: 'API Token',
    },
  },
  orders: {
    name: 'Bestellung |||| Bestellungen',
    tabs: {
      general: 'Allgemein',
      billingAddress: 'Rechnungsadresse',
      shippingAddress: 'Lieferadresse',
      orderLines: 'Bestellung',
      events: 'Historie',
      comments: 'Kommentare',
      attachments: 'Anhänge',
      process: 'Prozess',
      flags: 'Statusübersicht',
      bankInformation: 'Bankverbindung',
      communication: 'Kommunikation',
      shipments: 'Versandinformationen',
      extra: 'Weitere Funktionen',
      it_scope_orders: 'ITScope Bestellungen',
    },
    fields: {
      workflow: 'Typ',
      orderNumber: 'Bestellnummer',
      marking: 'Status',
      affiliate: 'Vermittelt',
      startDate: 'Vertragsbeginn',
      document: 'Vetrag',
      bank: 'Kreditinstitut',
      IBAN: 'IBAN',
      BIC: 'BIC',
      birthDate: 'Geburtsdatum',
      created: 'Anfragedatum',
      period: 'Vertragslaufzeit',
      directDelivery: 'Endkundenbelieferung',
      orderedDevices: 'Geräte',
      orderedProducts: 'Zubehör',
      orderedConfigurableProducts: 'Bestelltes konfigurierbares Zubehör',
      orderedOnlineService: 'Bestellter Online Service',
      orderedDeviceQuantity: '# bestellte Geräte',
      additionalInformation: 'Weitere Informationen',
      requestedCreditLimit: 'Angefragtes Kreditlimit',
      approvedCreditLimit: 'Bestätigtes Kreditlimit',
      totalVolume: 'Auftragsvolumen',
      creditVolume: 'Investitionsvolumen',
      itScopeVolume: 'Bestellvolumen ITScope',
      totalRate: 'Gesamtrate',
      reference: 'Bestellreferenz',
      fields: {},
      priority: 'Hohe Priorität',
      overdue: 'Überfällig seit',
      suppressMails: 'E-Mail versand deaktiviert',
      customer_reference: 'Kunde',
      contactAddress: {
        name: 'Ansprechpartner',
        email: 'E-Mail Adresse',
        ccEmail: 'CC E-Mail Adressen',
        legalForm: 'Rechtsform',
        firstName: 'Vorname',
        salutation: 'Anrede',
        lastName: 'Nachname',
        company: 'Firma',
        street: 'Straße',
        postcode: 'PLZ',
        city: 'Ort',
        phone: 'Telefon',
        emailType: 'E-Mail Typ',
      },
      finance_partner: 'Finanzierungspartner',
      storeView: {
        name: 'Store View',
      },
      shippingAddress: {
        name: 'Lieferadresse',
        email: 'E-Mail Adresse',
        legalForm: 'Rechtsform',
        firstName: 'Vorname',
        salutation: 'Anrede',
        lastName: 'Nachname',
        company: 'Firma',
        addressAddon: 'Addresszusatz',
        companyShort: 'Firma (max. 50 Zeichen)',
        street: 'Straße',
        postcode: 'PLZ',
        city: 'Ort',
        phone: 'Telefon',
      },
      billingAddress: {
        name: 'Rechnungsadresse',
        email: 'E-Mail Adresse',
        legalForm: 'Rechtsform',
        salutation: 'Anrede',
        firstName: 'Vorname',
        lastName: 'Nachname',
        company: 'Firma',
        companyShort: 'Firma (max. 50 Zeichen)',
        street: 'Straße',
        postcode: 'PLZ',
        city: 'Ort',
        phone: 'Telefon',
      },
      ceo: {
        saluation: 'Anrede',
        name: 'Geschäftsführer',
        email: 'E-Mail Adresse',
        salutation: 'Anrede',
        firstName: 'Vorname',
        lastName: 'Nachname',
        street: 'Straße',
        postcode: 'PLZ',
        city: 'Ort',
        phone: 'Telefon',
        fax: 'Fax',
        birthdate: 'Geburtsdatum',
      },
      reportingName: 'Reporting Einstellungen',
    },
  },
  offers: {
    name: 'Angebot |||| Angebote',
    tabs: {
      general: 'Allgemein',
      billingAddress: 'Rechnungsadresse',
      shippingAddress: 'Lieferadresse',
      orderLines: 'Bestellung',
      events: 'Historie',
      comments: 'Kommentare',
      attachments: 'Anhänge',
      process: 'Prozess',
      communication: 'Kommunikation',
      shipments: 'Versandinformationen',
      extra: 'Weitere Funktionen',
    },
    fields: {
      workflow: 'Typ',
      offerNumber: 'Angebotsnummer',
      marking: 'Status',
      created: 'Erstelldatum',
      customerEmailType: 'Kunden E-Mail Typ',
      period: 'Vertragslaufzeit',
      offerValidUntil: 'Gültig bis',
      orderedDevices: 'Geräte',
      orderedProducts: 'Zubehör',
      orderedOnlineService: 'Bestellter Online Service',
      orderedDeviceQuantity: '# bestellte Geräte',
      additionalInformation: 'Weitere Informationen',
      totalVolume: 'Auftragsvolumen',
      creditVolume: 'Investitionsvolumen',
      itScopeVolume: 'Bestellvolumen ITScope',
      suppressMails: 'E-Mail versand deaktiviert',
      customer_reference: 'Kunde',
      storeView_reference_id: 'Store View',
      reference: 'Bestellreferenz',
      contactAddress: {
        name: 'Ansprechpartner',
        email: 'E-Mail Adresse',
        ccEmail: 'CC E-Mail Adressen',
        legalForm: 'Rechtsform',
        firstName: 'Vorname',
        salutation: 'Anrede',
        lastName: 'Nachname',
        company: 'Firma',
        street: 'Straße',
        postcode: 'PLZ',
        city: 'Ort',
        phone: 'Telefon',
        emailType: 'E-Mail Typ',
      },
      storeView: {
        name: 'Store View',
      },
      buyOffer: 'Kaufangebotsnummer',
      rentOffer: 'Mietangebotsnummer',
      billingAddress: {
        name: 'Rechnungsadresse',
        email: 'E-Mail Adresse',
        legalForm: 'Rechtsform',
        salutation: 'Anrede',
        firstName: 'Vorname',
        lastName: 'Nachname',
        company: 'Firma',
        companyShort: 'Firma (max. 50 Zeichen)',
        street: 'Straße',
        postcode: 'PLZ',
        city: 'Ort',
        phone: 'Telefon',
      },
    },
  },
  manufacturers: {
    name: 'Hersteller',
    fields: {
      name: 'Name',
      slug: 'Slug',
      distributors: 'Lieferanten',
      distributors_reference_ids: 'Lieferanten',
      protectionPlanSKUs: 'Seriennummern der Schutzbriefe',
      itScopeId: 'ITScope ID',
    },
  },
  categories: {
    name: 'Kategorie |||| Kategorien',
    fields: {
      name: 'Name',
      position: 'Sortierung',
      active: 'Aktiv',
      store_view_reference: 'Store View',
      storeView: {
        name: 'Store View',
      },
      description: 'Beschreibung',
      iconString: 'Icon',
      emptyCallToAction: 'Text, wenn leer in Warenkorb',
    },
  },
  products: {
    name: 'Zubehör',
    tabs: {
      general: 'Allgemein',
      price: 'Preis',
      configurableProducts: 'Varianten',
      information_tabs: 'Beschreibungen',
    },
    fields: {
      name: 'Name',
      position: 'Sortierung',
      active: 'Aktiv',
      stock: 'Lagerbestand',
      vat: 'MWSt.',
      price: 'Preis',
      priceType: 'Abrechnungsart',
      category_reference_id: 'Kategorie',
      storeView_reference_id: 'Store View',
      storeView: {
        name: 'Store View',
      },
      directDelivery: 'Endkundenbelieferung',
      shortDescription: 'Kurzbeschreibung',
      genericAccessory: 'Allgemeines Zubehör',
      monthlyPrices,
      category: {
        name: 'Kategorie',
      },
      information_tabs: 'Beschreibungen',
      manufacturer_reference_id: 'Hersteller',
      manufacturer: {
        name: 'Hersteller',
      },
    },
  },
  product_variants: {
    name: 'Produktvariante',
    tabs: {
      general: 'Allgemein',
      attributes: 'Attribute',
    },
    fields: {
      product_reference: 'Produkt',
      attributeValues: {
        name: 'Attribute',
        value: 'Wert',
        attribute: 'Attribut',
      },
      price: 'Preis',
      netPrice: 'Netto Preis',
      stock: 'Lagerbestand',
      margin: 'Marge',
      monthlyPrices,
    },
    actions: {
      back: 'Zurück zum Produkt',
    },
  },
  product_variant_attribute_values: {
    name: 'Produktattribute',
    fields: {
      product_reference: 'Produkt',
      value_reference: 'Attributswert',
      value: {
        value: 'Attributswert',
      },
      price: 'Preis',
      monthlyPrices,
    },
    actions: {
      back: 'Zurück zum Produkt',
    },
  },
  devices: {
    name: 'Produkt |||| Produkte',
    tabs: {
      general: 'Allgemein',
      variants: 'Varianten',
      information_tabs: 'Beschreibungen',
      accessories: 'Zubehör',
    },
    fields: {
      name: 'Name',
      slug: 'Slug',
      product: {
        name: 'Produkt',
      },
      category: {
        name: 'Kategorie',
      },
      manufacturer: {
        name: 'Hersteller',
      },
      manufacturer_reference_id: 'Hersteller',
      category_reference_id: 'Kategorie',
      storeView_reference_id: 'Store View',
      storeView: {
        name: 'Store View',
      },
      active: 'Aktiv',
      variants: 'Varianten',
      information_tabs: 'Beschreibungen',
      description: 'Beschreibung',
      imageFile: 'Produktbild',
      images: 'Produktbilder',
      accessorySKUs: 'Seriennummern des Zubehörs',
      serviceExtensionSKUs: 'Seriennummern der Service-Erweiterungen',
      includedServiceExtension: 'Service-Erweiterung inklusive',
      protectionPlanSKUs: 'Seriennummern der Schutzbriefe',
      creditGroup: 'Finanzierungsobjektgruppe',
      variantNamingGroups_reference_ids: 'Angezeigte Attribute der Varianten',
      variantNamingGroups: 'Angezeigte Attribute der Varianten',
      variantNamingGroupsAvailable: 'Verfügbare Attribute: %{groups}',
      namingGroupsSet: 'Angezeigte Attribute validiert',
      createdAt: 'Angelegt',
      autopilotString: 'Autopilot Gerätemodell',
    },
  },
  promo_bundles: {
    name: 'Promo Bundle |||| Promo Bundles',
    fields: {
      name: 'Name',
      shortDescription: 'Beschreibung',
      active: 'Aktiv',
      validFrom: 'Gültig von',
      validTo: 'Gültig bis',
      type: 'Art',
      allowedProducts: 'Berechtigtes Zubehör',
      requiredDevices: 'Berechtigte Geräte',
      skus: 'Artikelnummern',
      puids: 'IT Scope IDs',
      prices: 'Preise',
      netPrices: 'Netto Preise',
      margin: 'Marge',
    },
  },
  service_extensions: {
    name: 'Service-Erweiterung |||| Service-Erweiterungen',
    fields: {
      active: 'Aktiv',
      sku: 'Artikelnummer',
      name: 'Name',
      term: 'Laufzeit',
      price: 'Preis',
      margin: 'Marge',
      manufacturer_reference_id: 'Hersteller',
      manufacturer: {
        name: 'Hersteller',
      },
      description: 'Beschreibung',
    },
  },
  online_services: {
    name: 'Online Service |||| Online Services',
    fields: {
      active: 'Aktiv',
      sku: 'Artikelnummer',
      name: 'Name',
      price: 'Monatlicher Preis',
      margin: 'Marge',
      manufacturer: 'Hersteller',
      managed: 'Managed Service',
      description: 'Beschreibung',
      type: 'Art',
    },
  },
  managed_services: {
    name: 'Managed Service |||| Managed Services',
    fields: {
      active: 'Aktiv',
      name: 'Name',
      sku: 'Artikelnummer',
      price: 'Monatlicher Preis',
      store_view_reference: 'Store View',
      storeView: {
        name: 'Store View',
      },
      margin: 'Marge',
      manufacturer: 'Hersteller',
      managed: 'Managed Service',
      description: 'Beschreibung',
      type: 'Art',
    },
  },
  device_variants: {
    name: 'Produktvariante |||| Produktvarianten',
    variant: 'Variante',
    tabs: {
      general: 'Allgemein',
      attributes: 'Attribute',
    },
    fields: {
      name: 'Name',
      description: 'Beschreibung',
      slug: 'Slug',
      monthlyPrices,
      price: 'Preis',
      netPrice: 'Netto Preis',
      sku: 'Artikelnummer',
      ean: 'EAN',
      puid: 'IT Scope ID',
      active: 'Aktiv',
      autoDisabled: 'Automatisch deaktiviert',
      margin: 'Marge',
      stock: 'Lagerbestand',
      displaySpecs: 'Angezeigte Attribute',
      missingDisplaySpecs: 'Fehlende Attribute',
      accessorySKUs: 'Seriennummern des Zubehörs',
      serviceExtensionSKUs: 'Seriennummern der Service-Erweiterungen',
      alsoProductId: 'ALSO Produkt Nummer',
      service: {
        name: 'Microsoft Online Service',
      },
      specs: {
        name: 'Spezifikation',
        title: 'Name',
        spec: 'Spezifikation',
      },
    },
  },
  attributes: {
    name: 'Attribut |||| Attribute',
    fields: {},
  },
  device_information_tabs: {
    name: 'Beschreibung |||| Beschreibungen',
    fields: {
      title: 'Titel',
      content: 'Inhalt',
    },
  },
  product_information_tabs: {
    name: 'Beschreibung |||| Beschreibungen',
    fields: {
      title: 'Titel',
      content: 'Inhalt',
    },
  },
  itscope: {
    name: 'IT Scope',
  },
  itscope_settings: {
    name: 'IT Scope Einstellungen',
    fields: {
      username: 'API Account-ID',
      apiKey: 'API Key',
      priceUpdateActive: 'automatische Preisaktualisierung',
      stockUpdateActive: 'automatische Verfügbarkeitsaktualisierung',
      updateInterval: 'Aktualisierungsintervall',
      updateDay: 'Tag',
      updateTime: 'Zeit',
      nextUpdate: 'Nächste Aktualisierung',
    },
    intervals: {
      daily: 'Täglich',
      weekly: 'Wöchentlich',
      days: {
        monday: 'Montag',
        tuesday: 'Dienstag',
        wednesday: 'Mittwoch',
        thursday: 'Donnerstag',
        friday: 'Freitag',
        saturday: 'Samstag',
        sunday: 'Sonntag',
      },
    },
  },
  itscope_distributors: {
    name: 'IT Scope Distributor |||| IT Scope Distributoren',
    fields: {
      name: 'Name',
      active: 'Aktiviert',
      supplierId: 'Supplier ID',
      deepLink: 'Link',
      customerAccountNumber: 'Eigene Kundennummer',
    },
  },
  device_order_lines: {
    name: 'Geräte',
    title: {
      create: 'Gerät hinzufügen',
      edit: 'Gerät bearbeiten',
    },
    fields: {
      search: 'Arbeitsplatzgerät suchen',
      sku: 'Artikelnummer',
      name: 'Name',
      manufacturer: 'Hersteller',
      quantity: 'Menge',
      price: 'Preis',
      margin: 'Marge',
      defaultMargin: 'Standardmarge',
      referenceString: 'Referenz',
      discount: 'Rabatt',
      discount_relative: 'Rabatt in %',
      discount_absolute: 'Rabatt in €',
      netPrice: 'Verkaufspreis',
      protectionPlan: 'Schutzbrief',
      serialNumbers: 'Seriennummern',
      creditGroup: 'Finanzierungsobjektgruppe',
      dllBrandName: 'DLL Asset Hersteller',
      dllAssetType: 'DLL Asset Typ',
      hasSerialNumbers: 'Seriennummernpflichtig',
      includeInProtectionPlan: 'Im Schutzbrief enthalten',
      itScopeProductType: 'IT Scope Produkttyp',
    },
  },
  promo_bundle_order_lines: {
    name: 'Promo',
    title: {
      create: 'Promo hinzufügen',
      edit: 'Promo bearbeiten',
    },
    fields: {
      search: 'Suchen',
      quantity: 'Menge',
      price: 'Preis',
      margin: 'Marge',
      defaultMargin: 'Standardmarge',
      referenceString: 'Referenz',
      discount: 'Rabatt',
      discount_relative: 'Rabatt in %',
      discount_absolute: 'Rabatt in €',
      netPrice: 'Verkaufspreis',
    },
  },
  service_order_lines: {
    name: 'Services',
    title: {
      create: 'Service hinzufügen',
      edit: 'Service bearbeiten',
    },
    fields: {
      search: 'Service suchen',
      name: 'Name',
      sku: 'Artikelnummer',
      manufacturer: 'Hersteller',
      deviceOrderLine: {
        name: 'Gerät',
      },
      quantity: 'Menge',
      price: 'Preis',
      margin: 'Marge',
      defaultMargin: 'Standardmarge',
      referenceString: 'Referenz',
      discount: 'Rabatt',
      discount_relative: 'Rabatt in %',
      discount_absolute: 'Rabatt in €',
      netPrice: 'Verkaufspreis',
    },
  },
  service_extension_order_lines: {
    name: 'Service-Erweiterungen',
    title: {
      create: 'Service-Erweiterung hinzufügen',
      edit: 'Service-Erweiterung bearbeiten',
    },
    fields: {
      search: 'Service-Erweiterung suchen',
      name: 'Name',
      sku: 'Artikelnummer',
      manufacturer: 'Hersteller',
      deviceOrderLine: {
        name: 'Gerät',
      },
      quantity: 'Menge',
      price: 'Preis',
      margin: 'Marge',
      defaultMargin: 'Standardmarge',
      referenceString: 'Referenz',
      discount: 'Rabatt',
      discount_relative: 'Rabatt in %',
      discount_absolute: 'Rabatt in €',
      netPrice: 'Verkaufspreis',
    },
  },
  protection_plan_order_lines: {
    name: 'Schutzbriefe',
    title: {
      create: 'Schutzbrief hinzufügen',
      edit: 'Schutzbrief bearbeiten',
    },
    fields: {
      name: 'Schutzbrief',
      deviceOrderLine: {
        name: 'Gerät',
      },
      quantity: 'Menge',
      price: 'Preis',
      margin: 'Marge',
      defaultMargin: 'Standardmarge',
      referenceString: 'Referenz',
      discount: 'Rabatt',
      discount_relative: 'Rabatt in %',
      discount_absolute: 'Rabatt in €',
      netPrice: 'Verkaufspreis',
    },
  },
  product_order_lines: {
    name: 'Zubehör',
    title: {
      create: 'Zubehör hinzufügen',
      edit: 'Zubehör bearbeiten',
    },
    fields: {
      search: 'Zubehör suchen',
      sku: 'Artikelnummer',
      name: 'Name',
      manufacturer: 'Hersteller',
      quantity: 'Menge',
      price: 'Preis',
      margin: 'Marge',
      defaultMargin: 'Standardmarge',
      referenceString: 'Referenz',
      discount: 'Rabatt',
      discount_relative: 'Rabatt in %',
      discount_absolute: 'Rabatt in €',
      netPrice: 'Verkaufspreis',
      creditGroup: 'Finanzierungsobjektgruppe',
      serialNumbers: 'Seriennummern',
      hasSerialNumbers: 'Seriennummernpflichtig',
      includeInProtectionPlan: 'Im Schutzbrief enthalten',
    },
  },
  generic_order_lines: {
    name: 'Weitere Produkte',
    title: {
      edit: 'Produkt bearbeiten',
    },
    fields: {
      search: 'Produkt Suchen',
      product: 'ITScope Produkt suchen',
      customProduct: 'Freitextposition',
      name: 'Name',
      sku: 'Artikelnummer',
      manufacturer: 'Hersteller',
      quantity: 'Menge',
      price: 'Preis',
      margin: 'Marge',
      defaultMargin: 'Standardmarge',
      referenceString: 'Referenz',
      discount: 'Rabatt',
      unit: 'Einheit',
      discount_relative: 'Rabatt in %',
      discount_absolute: 'Rabatt in €',
      netPrice: 'Verkaufspreis',
      creditGroup: 'Finanzierungsobjektgruppe',
    },
  },
  external_protection_plans: {
    name: 'Schutzbriefe (Buchung)',
    uploaded: 'Bereits hochgeladen',
    cancel: 'Stornieren',
    labels: {
      orderData: 'Daten zur Bestellung',
      status: 'Status',
      status_released: 'Wartet auf Buchung',
      status_uploaded: 'Bereits gebucht',
      status_unreleased: 'Entwurf',
      status_canceled: 'Storniert',
      search: 'Suche',
    },
    fields: {
      purchaseDate: 'Kaufdatum',
      fromOrder: 'Aus Bestellung erstellen?',
      createdDate: 'Erstellungsdatum',
      deliveryDate: 'Zustelldatum',
      insuranceDate: 'Versicherungsbeginn',
      customer: 'Kundenname',
      customer_order: 'Kunde auswählen',
      customer_new: 'Kundenname',
      purchase: 'Kaufen / Mieten',
      type: 'Typ',
      purchased: 'Kauf',
      rented: 'Miete',
      order: 'Bestellung auswählen',
      reseller: 'Reseller',
      insurance: 'Versicherung',
      dateRent: 'Übernahmedatum',
      theftProtection: 'Diebstahlschutz',
      months: 'Monate',
      duration: 'Dauer',
      orderNumber: 'Interne Nummer',
      externalProtectionPlan: { order: { orderNumber: 'Bestellnummer' } },
      devices: {
        devices: 'Produkte',
        device: 'Produkt',
        device_edit: 'Neues Produkt zuweisen',
        searchDevice: 'Produkt suchen',
        deviceName: 'Produktname',
        manufacturer: 'Hersteller',
        sku: 'SKU',
        serialNumber: 'Seriennummer',
        serialNumbers: 'Seriennummern',
        orderNumbers: 'Bestellnummern',

        theftProtection: 'Diebstahlschutz',
        duration: 'Versicherungsdauer',

        price: {
          list: 'Bruttoverkaufspreis',
          create: 'Bruttoverkaufspreis inkl. Zubehör',
        },
        status: 'Status',
      },
    },
  },
  external_protection_plan_devices: {
    name: 'Schutzbriefe (Buchung)',
  },
  resellable_protection_plans: {
    name: 'Schutzbriefe',
    fields: {
      customer: 'Kunde',
      purchase_date: 'Kaufdatum',
      sku: 'Artikelnummer',
      model: 'Modelnummer',
      duration: 'Dauer',
      theftProtection: 'Diebstahlschutz',
      amount: 'Gedeckter Betrag',
      price: 'Preis',
      margin: 'Marge',
      netPrice: 'Netto Preis',
      generic: 'Für alle Produkte',
    },
  },
  protection_plans: {
    name: 'Schutzbriefe',
    fields: {
      protectionPlan: {
        sku: 'Artikelnummer',
        duration: 'Dauer',
        theftProtection: 'Diebstahlschutz',
        amount: 'Gedeckter Betrag',
        price: 'Preis',
        margin: 'Marge',
        netPrice: 'Einkaufspreis',
      },
      store_view_reference: 'Store View',
      storeView: {
        name: 'Store View',
      },
      price: 'Einkaufspreis',
      margin: 'Marge',
      netPrice: 'Netto Preis',
    },
  },
  order_shipments: {
    name: 'Lieferungen',
    fields: {
      trackingNumber: 'Tracking Nummer',
      shippingCourier: 'Versandunternehmen',
      trackingLink: 'Tracking Link',
    },
    messages: {
      notAvailable: 'Nicht ermittelt',
    },
  },

  order_cas_reporting: {
    fields: {
      enabled: 'Reporting aktiviert',
      casNumber: 'Cas-Nummer',
      company: 'Firma',
      street: 'Straße',
      postcode: 'PLZ',
      city: 'Ort',
    },
  },
  order_it_scope_orders: {
    fields: {
      order_id: 'Bestell ID',
    },
  },
}
