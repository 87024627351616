import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import ResellableProductList from 'Components/Also/EDI/ResellableProductList'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import { useToggle } from 'react-use'
import { useTranslate } from 'react-admin'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(1),
  },
}))

const Transition = props => <Slide direction="up" {...props} />

function AlsoOfferLineAddDialog({
  handleAdd,
  relatedToProducts,
  open,
  handleClose,
}) {
  const classes = useStyles()
  const [showRelated, toggleShowRelated] = useToggle(Boolean(relatedToProducts))
  const t = useTranslate()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xl"
      TransitionComponent={Transition}
    >
      <DialogTitle id="form-dialog-title">Hinzufügen</DialogTitle>

      <DialogContent>
        <Box className={classes.container}>
          {relatedToProducts && (
            <FormControlLabel
              control={
                <Switch
                  checked={showRelated}
                  onChange={toggleShowRelated}
                  name="showRelated"
                  color="primary"
                />
              }
              label={t('resources.also_offers.switches.onlyAssociatedProducts')}
            />
          )}
          <ResellableProductList
            relatedToProducts={showRelated && relatedToProducts}
            handleAdd={handleAdd}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          label="Schließen"
          onClick={handleClose}
          color="primary"
          disableOnGlobalLoad={false}
        />
      </DialogActions>
    </Dialog>
  )
}

AlsoOfferLineAddDialog.propTypes = {
  handleAdd: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  relatedToProducts: PropTypes.string,
}

AlsoOfferLineAddDialog.defaultProps = {
  relatedToProducts: null,
}

export default AlsoOfferLineAddDialog
