import { SimpleForm, TextInput, useTranslate } from 'react-admin'
import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { validateZipCode } from 'Components/Aluxo/Customers/CustomerCreate'
import CustomerEditToolbar from 'Components/Aluxo/Customers/Edit/Toolbar'

const TranslatedText = ({ text }) => {
  const translate = useTranslate()
  return translate(text, { smart_count: 2 })
}

const FormHeading = ({ text }) => (
  <span
    style={{ fontSize: '1.5rem', margin: '15px 0 5px 0', display: 'block' }}
  >
    <TranslatedText text={text} />
  </span>
)

FormHeading.propTypes = {
  text: PropTypes.string.isRequired,
}

const CEO = ({ ...props }) => {
  if (!get(props, 'record.addresses')) {
    return null
  }

  return (
    <SimpleForm {...props} redirect={false} toolbar={<CustomerEditToolbar />}>
      <FormHeading text="resources.customers.fields.ceo.name" />
      <TextInput
        source="ceo.firstName"
        label="resources.customers.fields.ceo.firstName"
      />
      <TextInput
        source="ceo.lastName"
        label="resources.customers.fields.ceo.lastName"
      />
      <TextInput
        source="ceo.street"
        label="resources.customers.fields.ceo.street"
      />
      <TextInput
        source="ceo.postcode"
        label="resources.customers.fields.ceo.postcode"
        validate={validateZipCode}
      />
      <TextInput
        source="ceo.city"
        label="resources.customers.fields.ceo.city"
      />
      <TextInput
        source="ceo.birthdate"
        label="resources.customers.fields.ceo.birthdate"
      />
    </SimpleForm>
  )
}

CEO.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
}

export default CEO
