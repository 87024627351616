import PropTypes from 'prop-types'
import React from 'react'
import { Labeled } from 'react-admin'

const GSalesOffersField = ({ record }) => {
  if (!record.gSalesOffers) {
    return null
  }
  return record.gSalesOffers.map(offer => (
    <Labeled
      key={offer.id}
      label={`resources.offers.fields.${offer.type}Offer`}
    >
      <span>{offer.offerNumber}</span>
    </Labeled>
  ))
}

GSalesOffersField.propTypes = {
  record: PropTypes.object.isRequired,
}

export default GSalesOffersField
