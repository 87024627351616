import { Card, CardContent, Grid } from '@material-ui/core'
import StatusField from 'Components/Common/Orders/Fields/StatusField'
import PureBooleanField from 'Components/Common/ViewFields/BooleanField'
import PureCurrencyField from 'Components/Common/ViewFields/CurrencyField'
import PureDateField from 'Components/Common/ViewFields/DateField'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import {
  Datagrid,
  Loading,
  ReferenceManyField,
  ShowButton,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  usePermissions,
  useShowController,
  useTranslate,
} from 'react-admin'
import styled from 'styled-components/macro'

const TranslatedText = ({ text }) => {
  const translate = useTranslate()
  return translate(text, { smart_count: 2 })
}

const FormHeading = ({ text }) => (
  <span
    style={{ fontSize: '1.5rem', margin: '15px 0 5px 0', display: 'block' }}
  >
    <TranslatedText text={text} />
  </span>
)

FormHeading.propTypes = {
  text: PropTypes.string.isRequired,
}

const AddressTabContent = props => {
  if (!get(props, 'record.addresses')) {
    return <Tab label="resources.customers.fields.addresses.name" {...props} />
  }
  const idxBilling = props.record.addresses.findIndex(a => a.type === 'billing')
  const idxShipping = props.record.addresses.findIndex(
    a => a.type === 'shipping',
  )

  return (
    <Tab label="resources.customers.fields.addresses.name" {...props}>
      <FormHeading text="resources.customers.fields.billingAddress.name" />
      <TextField
        source={`addresses.${idxBilling}.salutation`}
        label="resources.customers.fields.addresses.salutation"
      />
      <TextField
        source={`addresses.${idxBilling}.firstName`}
        label="resources.customers.fields.addresses.firstName"
      />
      <TextField
        source={`addresses.${idxBilling}.lastName`}
        label="resources.customers.fields.addresses.lastName"
      />
      <TextField
        source={`addresses.${idxBilling}.legalForm`}
        label="resources.customers.fields.addresses.legalForm"
      />
      <TextField
        source={`addresses.${idxBilling}.company`}
        label="resources.customers.fields.addresses.company"
      />
      <TextField
        source={`addresses.${idxBilling}.street`}
        label="resources.customers.fields.addresses.street"
      />
      <TextField
        source={`addresses.${idxBilling}.postcode`}
        label="resources.customers.fields.addresses.postcode"
      />
      <TextField
        source={`addresses.${idxBilling}.city`}
        label="resources.customers.fields.addresses.city"
      />
      <TextField
        source={`addresses.${idxBilling}.phone`}
        label="resources.customers.fields.addresses.phone"
      />
      <TextField
        source={`addresses.${idxBilling}.email`}
        label="resources.customers.fields.addresses.email"
      />
      <FormHeading text="resources.customers.fields.shippingAddress.name" />
      <TextField
        source={`addresses.${idxShipping}.firstName`}
        label="resources.customers.fields.addresses.firstName"
      />
      <TextField
        source={`addresses.${idxShipping}.lastName`}
        label="resources.customers.fields.addresses.lastName"
      />
      <TextField
        source={`addresses.${idxShipping}.company`}
        label="resources.customers.fields.addresses.company"
      />
      <TextField
        source={`addresses.${idxShipping}.street`}
        label="resources.customers.fields.addresses.street"
      />
      <TextField
        source={`addresses.${idxShipping}.postcode`}
        label="resources.customers.fields.addresses.postcode"
      />
      <TextField
        source={`addresses.${idxShipping}.city`}
        label="resources.customers.fields.addresses.city"
      />
    </Tab>
  )
}

AddressTabContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
}

const CenteredDiv = styled.div`
  display: flex;
  min-height: 80px;
  align-items: center;
  justify-content: center;
`

const CustomDatagrid = props => {
  const translate = useTranslate()

  if (props.data && Object.keys(props.data).length > 0) {
    return <Datagrid {...props} />
  }

  return (
    <CenteredDiv>
      {translate(
        props.loaded
          ? 'resources.customers.noOrders'
          : 'resources.customers.loadOrders',
      )}
    </CenteredDiv>
  )
}

CustomDatagrid.propTypes = {
  data: PropTypes.object.isRequired,
  loaded: PropTypes.bool.isRequired,
}

const Orders = props => {
  const { permissions = [] } = usePermissions()

  return (
    <ReferenceManyField
      addLabel={false}
      reference="also_orders"
      target="customer"
      {...props}
    >
      <CustomDatagrid style={{ width: '100%' }}>
        <TextField source="orderNumber" />
        <PureBooleanField source="priority" hideFalseValues />
        <StatusField source="marking" sortable={false} />
        <PureDateField source="created" format="DD.MM.YYYY" />
        <TextField source="billingAddress.company" />
        {permissions && permissions.includes('ROLE_STOREVIEW') && (
          <TextField source="storeView.name" />
        )}
        <PureCurrencyField
          label="resources.also_orders.fields.total"
          source="total"
        />
        <ShowButton />
      </CustomDatagrid>
    </ReferenceManyField>
  )
}

const AlsoCustomerShow = props => {
  const controllerProps = useShowController(props)
  const { /* record, */ loading } = controllerProps

  const { permissions = [] } = usePermissions()

  if (loading) {
    return (
      <Card>
        <CardContent>
          <Loading />
        </CardContent>
      </Card>
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card>
          <SimpleShowLayout {...controllerProps}>
            <TextField source="email" />
            <TextField source="customerNumber" />
            {permissions && permissions.includes('ROLE_STOREVIEW') && (
              <TextField source="storeView.name" />
            )}
          </SimpleShowLayout>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <SimpleShowLayout {...controllerProps}>
            <Orders />
          </SimpleShowLayout>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <TabbedShowLayout {...controllerProps}>
            <AddressTabContent />
            <Tab label="resources.customers.fields.bankDetails.name">
              <TextField
                source="bankDetails.bank"
                label="resources.customers.fields.bankDetails.bank"
              />
              <TextField
                source="bankDetails.IBAN"
                label="resources.customers.fields.bankDetails.iban"
              />
              <TextField
                source="bankDetails.BIC"
                label="resources.customers.fields.bankDetails.bic"
              />
            </Tab>
          </TabbedShowLayout>
        </Card>
      </Grid>
    </Grid>
  )
}

export default AlsoCustomerShow
