import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-admin'

const SubscriptionsField = ({ record = {} }) => {
  const t = useTranslate()
  if (record.subscriptionIds.length === 0) {
    return <div>{t('notifications.subscriptionsNotYetCreated')}</div>
  }
  return (
    <div>
      {record.subscriptionIds.map(subscriptionId => (
        <div>
          <a
            href={`https://marketplace.also.de/#/Account/View/${subscriptionId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {subscriptionId}
          </a>
        </div>
      ))}
    </div>
  )
}

SubscriptionsField.propTypes = {
  addLabel: PropTypes.bool,
  elStyle: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

SubscriptionsField.defaultProps = {
  addLabel: true,
}

export default SubscriptionsField
