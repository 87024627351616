import React from 'react'
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  SimpleForm,
  TextField,
  SelectInput,
  TextInput,
} from 'react-admin'
import { get } from 'lodash'
import ListActions from 'Components/Common/Actions/ListActions'
import EditToolbar from 'Components/Common/EditToolbar'
import EditActions from 'Components/Common/EditActions'
import FontawesomeIconInput from 'Components/Common/FontawesomeIconInput'
import TinyMCEInput from 'Components/Common/InputFields/TinyMCEInput'
import { validateRequired } from 'Resources/Aluxo/devices'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import ReferenceInput from 'Components/Common/ReferenceInput'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

const BenefitFilter = props => (
  <Filter {...props}>
    <TextInput source="title" />
  </Filter>
)

export const BenefitList = props => (
  <List
    {...props}
    pagination={<CustomPagination />}
    filters={<BenefitFilter />}
    actions={<ListActions {...props.options} />}
    aside={<LanguageSwitcherAside />}
  >
    <Datagrid>
      <TextField source="title" />
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <TextField source="storeView.name" />
      )}
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)

const Title = ({ record }) => get(record, 'title')

export const BenefitEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<Title />}
    aside={<LanguageSwitcherAside />}
    {...props}
  >
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="title" validate={validateRequired} />
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <ReferenceInput
          source="storeView"
          reference="store_views"
          validate={validateRequired}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
      <FontawesomeIconInput
        source="icon"
        validate={validateRequired}
        style={{ width: '5em' }}
      />
      <TinyMCEInput source="text" validate={validateRequired} />
    </SimpleForm>
  </Edit>
)

export const BenefitCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" validate={validateRequired} />
      <FontawesomeIconInput
        source="icon"
        validate={validateRequired}
        style={{ width: '5em' }}
      />
      <TinyMCEInput source="text" validate={validateRequired} />
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <ReferenceInput
          source="storeView"
          reference="store_views"
          validate={validateRequired}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
    </SimpleForm>
  </Create>
)
