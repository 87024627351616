import React from 'react'
import PropTypes from 'prop-types'
import {
  BooleanInput,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  SimpleForm,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin'
import { get } from 'lodash'
import ReferenceInput from 'Components/Common/ReferenceInput'
import TinyMCEInput from 'Components/Common/InputFields/TinyMCEInput'
import EditToolbar from 'Components/Common/EditToolbar'
import EditActions from 'Components/Common/EditActions'
import { validateRequired } from 'Resources/Aluxo/devices'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'
import MetaInput from 'Components/Common/InputFields/MetaInput'

const PageFilter = props => (
  <Filter {...props}>
    <TextInput source="name" />
    <BooleanInput
      options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
      source="active"
    />
  </Filter>
)

export const PageList = props => (
  <List
    {...props}
    pagination={<CustomPagination />}
    filters={<PageFilter />}
    aside={<LanguageSwitcherAside />}
  >
    <Datagrid>
      <TextField source="slug" />
      <TextField source="title" />
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <TextField source="storeView.name" />
      )}
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)

PageList.propTypes = {
  permissions: PropTypes.array.isRequired,
}

const PageTitle = ({ record }) => get(record, 'title')

export const PageEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<PageTitle />}
    aside={<LanguageSwitcherAside />}
    {...props}
  >
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="slug" validate={validateRequired} />
      <TextInput source="title" validate={validateRequired} />
      <TinyMCEInput source="content" validate={validateRequired} />
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <ReferenceInput
          source="storeView"
          reference="store_views"
          validate={validateRequired}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
      <MetaInput />
    </SimpleForm>
  </Edit>
)

export const PageCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" validate={validateRequired} />
      <TinyMCEInput source="content" validate={validateRequired} />
      {props.permissions && props.permissions.includes('ROLE_STOREVIEW') && (
        <ReferenceInput
          source="storeView"
          reference="store_views"
          validate={validateRequired}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
    </SimpleForm>
  </Create>
)
