import {
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { clamp } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { AutoSizer, List } from 'react-virtualized'
import getSuggestions from 'Components/Common/InputFields/AutoSuggests/OrderCustomerAutoSuggest/getSuggestions'

function renderSuggestion({
  suggestion,
  index,
  key,
  itemProps,
  highlightedIndex,
  style,
  selectedItem,
  classes,
  itemToString,
}) {
  const isHighlighted = highlightedIndex === index
  const isSelected = selectedItem && selectedItem.value === suggestion.value

  return (
    <ListItem {...itemProps} key={key} selected={isHighlighted} style={style}>
      <ListItemText>
        <Typography
          variant="body2"
          style={{ fontWeight: isSelected ? 600 : 400 }}
        >
          {itemToString(suggestion)}
        </Typography>
      </ListItemText>

      {suggestion.gsales && (
        <img
          className={classes.logo}
          src="/assets/logos/gsales.png"
          alt="gsales"
        />
      )}
      {suggestion.aluxo && (
        <img
          className={classes.logo}
          src="/assets/logos/aluxo.png"
          alt="gsales"
        />
      )}
    </ListItem>
  )
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    aluxo: PropTypes.bool.isRequired,
    gsales: PropTypes.bool.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    logo: PropTypes.string.isRequired,
  }).isRequired,
}

const useStyles = makeStyles({
  logo: {
    width: '20px',
    height: '20px',
    borderRadius: '20px',
    margin: '0 5px',
  },
})

const SelectList = ({
  data,
  getItemProps,
  highlightedIndex,
  selectedItem,
  inputValue,
  itemToString,
}) => {
  const suggestions = getSuggestions(data, inputValue)
  const classes = useStyles()

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          height={clamp(suggestions.length * 36, 0, 400)}
          rowHeight={36}
          rowCount={suggestions.length}
          rowRenderer={({ index, key, style }) => {
            const suggestion = suggestions[index]
            return renderSuggestion({
              suggestion,
              index,
              key,
              style,
              itemProps: getItemProps({ item: suggestion, index }),
              highlightedIndex,
              selectedItem,
              classes,
              itemToString,
            })
          }}
          width={width}
        />
      )}
    </AutoSizer>
  )
}

SelectList.propTypes = {
  data: PropTypes.array.isRequired,
  getItemProps: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number.isRequired,
  selectedItem: PropTypes.object.isRequired,
  inputValue: PropTypes.string.isRequired,
  itemToString: PropTypes.func.isRequired,
}

export default SelectList
