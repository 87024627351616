import GenericRestClientButton from 'Components/Common/ActionButtons/GenericRestClientButton'
import React from 'react'
import { useOrder } from 'OrderContext'
import { useApiClient } from 'Rest/fetchHydra'

const SendAutopilotEmailButton = () => {
  const { record } = useOrder()
  const apiClient = useApiClient()

  const handleClick = async () => {
    await apiClient({
      endpoint: '/send_autopilot_mail_for_order_request',
      method: 'POST',
      body: { order: record.id },
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  return (
    <GenericRestClientButton type="sendAutopilotMail" onClick={handleClick} />
  )
}

export default SendAutopilotEmailButton
