import {
  AutocompleteInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import legalForms from 'Lib/legalForms'
import { validateRequired } from 'Resources/Aluxo/devices'
import CustomerEditToolbar from 'Components/Aluxo/Customers/Edit/Toolbar'
import { validateRequiredZipCode } from '../CustomerCreate'

const TranslatedText = ({ text }) => {
  const translate = useTranslate()
  return translate(text, { smart_count: 2 })
}

const FormHeading = ({ text }) => (
  <span
    style={{ fontSize: '1.5rem', margin: '15px 0 5px 0', display: 'block' }}
  >
    <TranslatedText text={text} />
  </span>
)

FormHeading.propTypes = {
  text: PropTypes.string.isRequired,
}

const BillingAddress = ({ ...props }) => {
  if (!get(props, 'record.addresses')) {
    return null
  }
  const idxBilling = props.record.addresses.findIndex(a => a.type === 'billing')

  return (
    <SimpleForm {...props} redirect={false} toolbar={<CustomerEditToolbar />}>
      <FormHeading text="resources.customers.fields.billingAddress.name" />
      <SelectInput
        source={`addresses.${idxBilling}.salutation`}
        choices={[
          { id: 'Frau', name: 'Frau' },
          { id: 'Herr', name: 'Herr' },
        ]}
        validate={validateRequired}
        label="resources.customers.fields.addresses.salutation"
      />
      <TextInput
        source={`addresses.${idxBilling}.firstName`}
        validate={validateRequired}
        label="resources.customers.fields.addresses.firstName"
      />
      <TextInput
        source={`addresses.${idxBilling}.lastName`}
        validate={validateRequired}
        label="resources.customers.fields.addresses.lastName"
      />
      <AutocompleteInput
        source={`addresses.${idxBilling}.legalForm`}
        choices={legalForms.map(f => ({ id: f, name: f }))}
        label="resources.customers.fields.addresses.legalForm"
      />
      <TextInput
        source={`addresses.${idxBilling}.company`}
        validate={validateRequired}
        label="resources.customers.fields.addresses.company"
      />
      <TextInput
        source={`addresses.${idxBilling}.street`}
        validate={validateRequired}
        label="resources.customers.fields.addresses.street"
      />
      <TextInput
        source={`addresses.${idxBilling}.postcode`}
        validate={validateRequiredZipCode}
        label="resources.customers.fields.addresses.postcode"
      />
      <TextInput
        source={`addresses.${idxBilling}.city`}
        validate={validateRequired}
        label="resources.customers.fields.addresses.city"
      />
      <TextInput
        source={`addresses.${idxBilling}.phone`}
        validate={validateRequired}
        label="resources.customers.fields.addresses.phone"
      />
      <TextInput
        source={`addresses.${idxBilling}.email`}
        label="resources.customers.fields.addresses.email"
      />
    </SimpleForm>
  )
}

BillingAddress.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
}

export default BillingAddress
