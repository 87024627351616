import React from 'react'
import { Field } from 'react-final-form'
import { NumberInput, translate, TextField } from 'react-admin'
import { ListSubheader } from '@material-ui/core'

const StatsInput = translate(({ translate: t, record }) => (
  <div style={{ marginBottom: '1em' }}>
    <ListSubheader>
      {t('resources.store_views.fields.stats.title')}
    </ListSubheader>
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridColumnGap: '1em',
      }}
    >
      {[0, 1, 2].map(i => (
        <div>
          <div>
            <TextField source={`stats.${i}.type`} record={record} />
          </div>
          <div>
            <Field
              name={`stats.${i}.number`}
              label={t('resources.store_views.fields.stats.number')}
              component={NumberInput}
            />
          </div>
        </div>
      ))}
    </div>
  </div>
))

StatsInput.defaultProps = {}
export default StatsInput
