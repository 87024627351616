import React from 'react'
import {
  Edit,
  email,
  required,
  SelectInput,
  TextInput,
  translate,
  TopToolbar,
  SimpleForm,
  BooleanInput,
  FormDataConsumer,
} from 'react-admin'

import TestSMTPButton from 'Components/Common/ActionButtons/TestSMTPButton'
import { validateRequired } from 'Resources/Aluxo/devices'

const EditActions = () => (
  <TopToolbar>
    <TestSMTPButton personal />
  </TopToolbar>
)

const StaffConfigurationTitle = translate(({ translate: t }) =>
  t('resources.staff.name'),
)

export const validateRequiredEmail = [required(), email()]

export const StaffConfigurationEdit = props => (
  <Edit
    title={<StaffConfigurationTitle />}
    actions={<EditActions />}
    {...props}
  >
    <SimpleForm redirect={false}>
      <BooleanInput source="smtpConfiguration.active" />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.smtpConfiguration &&
          formData.smtpConfiguration.active && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <TextInput
                source="smtpConfiguration.username"
                validate={validateRequired}
                options={{ autocomplete: 'off' }}
                {...rest}
              />
              <TextInput
                type="password"
                source="smtpConfiguration.password"
                options={{ autocomplete: 'new-password' }}
                {...rest}
              />
              <TextInput
                source="smtpConfiguration.host"
                validate={validateRequired}
                {...rest}
              />
              <TextInput
                source="smtpConfiguration.port"
                validate={validateRequired}
                {...rest}
              />
              <SelectInput
                source="smtpConfiguration.encryption"
                choices={[
                  { id: 'ssl', name: 'SSL' },
                  { id: 'tls', name: 'TLS' },
                  { id: 'starttls', name: 'STARTTLS' },
                ]}
                validate={validateRequired}
                {...rest}
              />
              <TextInput
                source="smtpConfiguration.senderName"
                validate={validateRequired}
                {...rest}
              />
              <TextInput
                source="smtpConfiguration.senderAddress"
                validate={validateRequiredEmail}
                {...rest}
              />
            </div>
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
)
