import { CardActions } from '@material-ui/core'
import React from 'react'
import { Edit, SimpleForm, TextInput, translate } from 'react-admin'
import EditToolbar from 'Components/Common/EditToolbar'

const EditActions = () => <CardActions />

const GsalesSettingsTitle = translate(({ translate: t }) =>
  t('resources.gsales_settings.name'),
)

// eslint-disable-next-line import/prefer-default-export
export const GsalesSettingsEdit = props => (
  <Edit title={<GsalesSettingsTitle />} actions={<EditActions />} {...props}>
    <SimpleForm toolbar={<EditToolbar />} redirect={false}>
      <TextInput source="token" />
      <TextInput source="wsdlUrl" />
      <TextInput source="customerId" />
    </SimpleForm>
  </Edit>
)
