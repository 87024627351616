import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { setContentLanguage as setContentLanguageAction } from 'Ducks/language'
import 'flag-icon-css/css/flag-icon.css'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { useRefresh } from 'react-admin'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 8px;
  & > * {
    margin: 8px 0;
  }
`

const LanguageSwitcher = ({ availableLanguages }) => {
  const contentLanguage = useSelector(
    ({ language: { contentLanguage } }) => contentLanguage,
  )
  const dispatch = useDispatch()
  const setContentLanguage = code => dispatch(setContentLanguageAction(code))
  const refreshView = useRefresh()
  return (
    <Container>
      {availableLanguages.map(({ code, countryCode }) => (
        <div>
          <Button
            onClick={() => {
              setContentLanguage(code)
              refreshView()
            }}
            variant={contentLanguage === code && 'outlined'}
          >
            <span className={`flag-icon flag-icon-${countryCode}`} />
          </Button>
        </div>
      ))}
    </Container>
  )
}

LanguageSwitcher.propTypes = {
  availableLanguages: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default LanguageSwitcher
