import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { translate } from 'react-admin'

const TranslatedStatus = translate(({ translate, source, record = {} }) => (
  <span>{translate(`orders.markings.${get(record, source)}`)}</span>
))

TranslatedStatus.propTypes = {
  addLabel: PropTypes.bool,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

TranslatedStatus.defaultProps = {
  addLabel: true,
  sortable: false,
}

export default TranslatedStatus
