import React from 'react'
import PropTypes from 'prop-types'
import {
  Create,
  Edit,
  FormTab,
  ImageField,
  TabbedForm,
  TextField,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  BooleanInput,
} from 'react-admin'
import { CardActions } from '@material-ui/core'
import { get } from 'lodash'
import RichTextInput from 'ra-input-rich-text'

import NumberWithUnitInput from 'Components/Common/InputFields/NumberWithUnitInput'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import BackToProductButton from 'Components/Aluxo/ActionButtons/BackToProductButton'
import UpdateITScopeMetadataButton from 'Components/Aluxo/ActionButtons/UpdateITScopeMetadataButton'
import UpdateITScopeSinglePriceButton from 'Components/Aluxo/ActionButtons/UpdateITScopeSinglePriceButton'
import ImageInput from 'Components/Common/InputFields/ImageInput'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import EditToolbar from 'Components/Common/EditToolbar'
import { validateRequired } from './devices'

const ProductVariantName = ({ record }) => (
  <span>ProductAttributeValue {record ? `"${record.value.value}"` : ''}</span>
)
ProductVariantName.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
}

const ProductVariantEditActions = ({ data, resource }) => (
  <CardActions>
    <BackToProductButton data={data} />
    <UpdateITScopeMetadataButton data={data} resource={resource} />
    <UpdateITScopeSinglePriceButton data={data} resource={resource} />
  </CardActions>
)

ProductVariantEditActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
}

const ProductVariantTitle = ({ record }) =>
  `${get(record, 'product.name')} - ${get(record, 'sku')}`

const redirect = (basePath, id, data) => `/products/${data.product.id}`

export const ProductVariantEdit = props => (
  <Edit
    title={<ProductVariantTitle />}
    actions={<ProductVariantEditActions />}
    name={ProductVariantName}
    aside={<LanguageSwitcherAside />}
    {...props}
  >
    <TabbedForm toolbar={<EditToolbar hasDelete />} redirect={redirect}>
      <FormTab label="resources.product_variants.tabs.general">
        <TextInput source="name" />
        <TextInput source="sku" />
        <TextInput source="ean" />
        <TextField source="puid" />

        <BooleanInput
          options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
          source="autoDisabled"
          validate={validateRequired}
        />

        <RichTextInput source="shortDescription" />
        <NumberWithUnitInput source="price" />
        <NumberWithUnitInput source="margin" unit="%" />
        <CurrencyField source="netPrice" />

        <ImageField source="image" />
        <ImageInput source="imageFile" maxSize={26214400}>
          <ImageField source="image" title="title" />
        </ImageInput>
      </FormTab>
      <FormTab label="resources.product_variants.tabs.attributes">
        <ArrayInput
          label="resources.product_variants.fields.attributeValues.name"
          reference="product_variant_attribute_values"
          source="attributeValues"
        >
          <SimpleFormIterator>
            <TextInput
              source="attribute"
              label="resources.product_variants.fields.attributeValues.attribute"
              style={{ display: 'inline-block' }}
              elStyle={{ width: '50%' }}
            />
            <TextInput
              source="value"
              label="resources.product_variants.fields.attributeValues.value"
              style={{ display: 'inline-block' }}
              elStyle={{ width: '50%' }}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
)

const defaultValues = product => ({
  product: { '@id': product },
  price: 0,
  netPrice: 0,
  margin: 0,
  stock: 0,
})

const CreateActions = ({ product }) => (
  <CardActions>
    {product && <BackToProductButton product={product} />}
  </CardActions>
)

CreateActions.propTypes = {
  product: PropTypes.string.isRequired,
}

export const ProductVariantCreate = props => {
  const {
    history: {
      location: { state },
    },
  } = props
  const product = get(state, 'product')
  return (
    <Create actions={<CreateActions product={product} />} {...props}>
      <TabbedForm defaultValue={defaultValues(product)}>
        <FormTab label="resources.product_variants.tabs.general">
          <TextInput source="name" />
          <TextInput source="sku" />
          <TextInput source="ean" />
          <TextField source="puid" />
          <NumberWithUnitInput source="price" />
          <NumberWithUnitInput source="margin" unit="%" />
          <CurrencyField source="netPrice" defaultValue={0} />
        </FormTab>
        <FormTab label="resources.product_variants.tabs.attributes">
          <ArrayInput source="attributeValues">
            <SimpleFormIterator>
              <TextInput
                source="attribute"
                label="resources.product_variants.fields.attributeValues.attribute"
              />
              <TextInput
                source="value"
                label="resources.product_variants.fields.attributeValues.value"
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}

ProductVariantCreate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
}
