import React from 'react'
import PropTypes from 'prop-types'
import ChipInput from 'material-ui-chip-input'
import Chip from '@material-ui/core/Chip'
import { blue } from '@material-ui/core/colors'
import { useNotify, useTranslate } from 'react-admin'
import { Field } from 'react-final-form'
import { useCopyToClipboard } from 'react-use'
import Typography from '@material-ui/core/Typography'

const defaultChipRenderer = (
  showNotification,
  [copyState, copyToClipboard],
) => (
  { value, text, isFocused, isDisabled, handleClick, handleDelete, className }, // eslint-disable-line
  key,
) => {
  const clickHandler = () => {
    copyToClipboard(value)
    if (!copyState.error) {
      showNotification('notifications.copiedToClipboard.success')
    } else {
      showNotification('notifications.copiedToClipboard.fail')
    }
    handleClick()
  }
  return (
    <Chip
      key={key}
      className={className}
      style={{
        pointerEvents: isDisabled ? 'none' : undefined,
        backgroundColor: isFocused ? blue[300] : undefined,
      }}
      onClick={clickHandler}
      onDelete={handleDelete}
      label={text}
    />
  )
}

defaultChipRenderer.propTypes = {
  value: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  className: PropTypes.string, //eslint-disable-line
}

const SerialNumberInput = () => {
  const translate = useTranslate()
  const showNotification = useNotify()
  const copyHook = useCopyToClipboard()

  return (
    <>
      <Field
        name="serialNumbers"
        component={({ input: { value, onChange } }) => (
          <ChipInput
            chipRenderer={defaultChipRenderer(showNotification, copyHook)}
            defaultValue={value}
            onPaste={event => {
              const clipboardText = event.clipboardData.getData('Text')
              event.preventDefault()
              const splitChar = clipboardText.includes(',') ? ',' : '\n'

              onChange([
                ...value,
                ...clipboardText.split(splitChar).filter(t => t.length > 0),
              ])
            }}
            onChange={chips => onChange(chips)}
          />
        )}
      />
      <Typography variant="body2" color="textSecondary">
        {translate('forms.inputs.serialNumberInput.helperText')}
      </Typography>
    </>
  )
}

export default SerialNumberInput
