import React from 'react'
import PropTypes from 'prop-types'
import { RefreshButton, CreateButton, TopToolbar } from 'react-admin'
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys'

import SortButton from '../ActionButtons/SortButton'

const ListActions = ({
  resource,
  filters,
  displayedFilters,
  filterValues,
  hasCreate,
  basePath,
  sortable,
  showFilter,
}) => (
  <TopToolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    {hasCreate && <CreateButton basePath={basePath} />}
    {sortable && <SortButton resource={resource} />}
    <RefreshButton />
  </TopToolbar>
)

ListActions.propTypes = {
  basePath: PropTypes.string.isRequired,
  hasCreate: PropTypes.bool.isRequired,
  displayedFilters: PropTypes.object.isRequired, //eslint-disable-line
  filters: PropTypes.element.isRequired,
  filterValues: PropTypes.object.isRequired, //eslint-disable-line
  sortable: PropTypes.bool,
  resource: PropTypes.string.isRequired,
  showFilter: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired, //eslint-disable-line
}

ListActions.defaultProps = {
  sortable: false,
}

export default onlyUpdateForKeys([
  'resource',
  'filters',
  'displayedFilters',
  'filterValues',
  'theme',
])(ListActions)
