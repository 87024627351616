import { zipObject } from 'lodash'

const periods = ['18', '24', '36', '48']
const values = periods.map(p => `Preis bei ${p} Monaten Laufzeit`)

const alsoMonthlyPrices = zipObject(periods, values)

export default {
  also_products: {
    name: 'Produkt |||| Produkte',
    fields: {
      name: 'Name',
      slug: 'Slug',
      manufacturer: 'Hersteller',
      type: 'Typ',
      active: 'Aktiv',
      updatedAt: 'Zuletzt geändert',
      accessorySKUs: 'Zubehör (SKU)',
      featuredSKUs: 'Hervorgehobenes Zubehör (SKU)',
      genericAccessory: 'Allgemeines Zubehör',
      category_reference_id: 'Kategorie',
      category: {
        name: 'Kategorie',
      },
      product: {
        name: 'Produkt',
        search: 'Produkt suchen',
      },
      shortDescription: 'Kurzbeschreibung',
      information_tabs: 'Beschreibungen',
    },
    tabs: {
      general: 'Allgemein',
      variants: 'Produktvarianten',
      information_tabs: 'Beschreibungen',
    },
  },
  also_categories: {
    name: 'Kategorie |||| Kategorien',
    fields: {
      name: 'Name',
      slug: 'Slug',
      iconString: 'Icon',
      description: 'Beschreibung',
      active: 'Aktiv',
      position: 'Position',
      category: {
        name: 'Kategorie',
      },
    },
    tabs: {
      general: 'Allgemein',
      products: 'Produkte',
    },
  },
  also_product_variants: {
    name: 'Produktvarianten',
    fields: {
      enumMember: { displayName: 'Enum Auswahl' },
      name: 'Name',
      monthlyPrices: alsoMonthlyPrices,
      information_tabs: 'Beschreibungen',
      genericAccessory: 'Allgemeines Zubehör',
      alsoProductId: 'ALSO Produkt ID',
    },
    tabs: {
      general: 'Allgemein',
      information_tabs: 'Beschreibungen',
      cnet: 'CNet',
    },
  },
  also_fields: {
    name: 'ACMP Auswahlgruppen',
    fields: {
      displayName: 'Anzeigename',
      displayTemplate: 'Anzeigeart',
      displayType: 'Anzeigetyp',
      hint: 'Hinweis',
      isList: 'ist Liste',
      isUnique: 'ist Unique',
      name: 'Name',
      type: 'Typ',
      isPartialValidation: 'isPartialValidation',
      defaultValue: 'defaultValue',
    },
  },
  also_enum_members: {
    name: 'ACMP Auswahlmöglichkeiten',
    fields: {
      displayName: 'Anzeigename',
      value: 'Wert',
      createdAt: 'Angelegt',
      field: 'Auswahlgruppe',
    },
  },
  also_reseller_products: {
    name: 'Produkt |||| Produkte',
    fields: {
      name: 'Name',
      category: 'Kategorie',
      manufacturer: 'Hersteller',
      type: 'Produkttyp',
      active: 'Aktiviert',
      customAccessories: 'Eigene Zubehörzuweisung',
      accessorySKUs: 'Zubehör',
      featuredAccessorySKUs: 'Primäres Zubehör',
      prioAccessorySKUs: 'Hervorgehobenes Zubehör',
    },
  },
  also: {
    name: 'ALSO',
  },
  also_acmp_settings: {
    name: 'ACMP Einstellungen',
    fields: {
      username: 'Benutzername',
      password: 'Passwort',
      parentAccountId: 'Parent Account',
      marketplace: 'Marktplatz ID',
      tradingModel: 'Handelsmodell',
      fixedTradingModelMargin: 'Absolute Marge',
      relativeTradingModelMargin: 'Prozentuale Marge',
    },
  },
  also_product_information_tabs: {
    name: 'Beschreibung |||| Beschreibungen',
    fields: {
      title: 'Titel',
      content: 'Inhalt',
    },
  },
  also_orders: {
    name: 'Bestellung |||| Bestellungen',
    tabs: {
      general: 'Allgemein',
      billingAddress: 'Rechnungsadresse',
      shippingAddress: 'Lieferadresse',
      orderLines: 'Bestellung',
      events: 'Historie',
      comments: 'Kommentare',
      attachments: 'Anhänge',
      process: 'Prozess',
      flags: 'Statusübersicht',
      bankInformation: 'Bankverbindung',
      communication: 'Kommunikation',
      additionalInfo: 'Zusätzliche Informationen',
    },
    fields: {
      reseller: {
        name: 'Wiederverkäufer',
      },
      orderNumber: 'Bestellnummer',
      marking: 'Status',
      startDate: 'Vertragsbeginn',
      document: 'Vertrag',
      domain: 'Domain',
      bank: 'Kreditinstitut',
      IBAN: 'IBAN',
      BIC: 'BIC',
      vatId: 'Umsatzsteuer ID',
      tradingModel: 'Händlermodell',
      birthDate: 'Geburtsdatum',
      created: 'Anfragedatum',
      directDelivery: 'Endkundenbelieferung',
      orderedDevices: 'Geräte',
      orderedProducts: 'Zubehör',
      additionalInformation: 'Weitere Informationen',
      total: 'Monatliche Gesamtrate',
      priority: 'Hohe Priorität',
      overdue: 'Überfällig seit',
      shippingAddress: {
        name: 'Lieferadresse',
        email: 'E-Mail Adresse',
        legalForm: 'Rechtsform',
        firstName: 'Vorname',
        salutation: 'Anrede',
        lastName: 'Nachname',
        company: 'Firma',
        street: 'Straße',
        postcode: 'PLZ',
        city: 'Ort',
        phone: 'Telefon',
      },
      billingAddress: {
        name: 'Rechnungsadresse',
        email: 'E-Mail Adresse',
        legalForm: 'Rechtsform',
        salutation: 'Anrede',
        firstName: 'Vorname',
        lastName: 'Nachname',
        company: 'Firma',
        street: 'Straße',
        postcode: 'PLZ',
        city: 'Ort',
        phone: 'Telefon',
      },
    },
  },
  also_workplace_order_lines: {
    name: 'Arbeitsplätze',
    title: {
      create: 'Arbeitsplatz hinzufügen',
      edit: 'Arbeitsplatz bearbeiten',
    },
    fields: {
      quantity: 'Menge',
      term: 'Laufzeit',
      workplaceConfiguration: 'Arbeitsplatzkonfiguration',
      workplaceServices: 'Services',
      accessories: 'Zubehör',
      monthlyRate: 'Rate pro Arbeitsplatz',
      subscriptions: 'Service Subscriptions',
      total: 'Gesamtrate',
    },
  },
  also_services: {
    name: 'Services',
    fields: {
      selectService: 'ACMP Service auswählen',
      name: 'Name',
      category: 'Kategorie',
      description: 'Kurzbeschreibung',
      manufacturer: 'Anbieter',
      active: 'Aktiviert',
      updatedAt: 'Aktualisiert',
    },
  },
  also_acmp_services: {
    name: 'ACMP Services',
    fields: {
      displayName: 'Name',
      category: 'Kategorie',
      externalId: 'Externe ID',
      status: 'Status',
    },
  },
  also_reseller_services: {
    name: 'Services',
    notes: {
      notInPortfolio: 'Service nicht im ACMP Portfolio',
    },
    fields: {
      name: 'Name',
      category: 'Kategorie',
      description: 'Kurzbeschreibung',
      manufacturer: 'Anbieter',
      monthlyPrice: 'monatlicher Preis',
      active: 'Aktiviert',
    },
  },
  also_reseller_custom_services: {
    name: 'Eigene Services',
    fields: {
      name: 'Name',
      category: 'Kategorie',
      description: 'Kurzbeschreibung',
      manufacturer: 'Anbieter',
      prices: 'Preise',
      active: 'Aktiviert',
      createdAt: 'Angelegt',
      updatedAt: 'Zuletzt aktualisiert',
    },
  },
  also_customers: {
    name: 'Kunde |||| Kunden',
    loadOrders: 'Bestellungen werden geladen...',
    noOrders: 'Keine Bestellungen',
    fields: {
      reseller: {
        name: 'Wiederverkäufer',
      },

      email: 'E-Mail',
      customerNumber: 'Kundennummer',
      firstName: 'Vorname',
      lastName: 'Nachname',

      company: 'Firma',
      storeView: {
        name: 'Store View',
      },
      billingAddress: {
        name: 'Rechnungsadresse',
      },
      shippingAddress: {
        name: 'Lieferadresse',
      },
      addresses: {
        saluation: 'Anrede',
        name: 'Adressen',
        email: 'E-Mail Adresse',
        legalForm: 'Rechtsform',
        salutation: 'Anrede',
        firstName: 'Vorname',
        lastName: 'Nachname',
        company: 'Firma',
        companyShort: 'Firma (max. 50 Zeichen)',
        street: 'Straße',
        postcode: 'PLZ',
        city: 'Ort',
        phone: 'Telefon',
      },
      ceo: {
        saluation: 'Anrede',
        name: 'Geschäftsführer',
        email: 'E-Mail Adresse',
        salutation: 'Anrede',
        firstName: 'Vorname',
        lastName: 'Nachname',
        street: 'Straße',
        postcode: 'PLZ',
        city: 'Ort',
        phone: 'Telefon',
        fax: 'Fax',
        birthdate: 'Geburtsdatum',
      },
      bankDetails: {
        name: 'Bankverbindung',
        bank: 'Kreditinstitut',
        iban: 'IBAN',
        bic: 'BIC',
      },
      shipToBilling: 'Lieferadresse wie Rechnungsadresse',
    },
    validation: {
      zip: 'Bitte geben Sie eine gültige Postleitzahl ein',
      iban: 'Bitte geben Sie eine gültige IBAN ein',
    },
    notifications: {
      noOrders: 'Bisher keine Bestellungen getätigt',
    },
  },
  also_offers: {
    tabs: {
      general: 'Allgemein',
      lines: 'Positionen',
    },
    name: 'Angebote',
    fields: {
      offerAddress: {
        name: 'Adresse',
        email: 'E-Mail Adresse',
        legalForm: 'Rechtsform',
        salutation: 'Anrede',
        firstName: 'Vorname',
        lastName: 'Nachname',
        company: 'Firma',
        street: 'Straße',
        postcode: 'PLZ',
        city: 'Ort',
        phone: 'Telefon',
      },
      period: 'Vertragslaufzeit',
      offerNumber: 'Angebotsnummer',
      domain: 'Domain',
      bank: 'Kreditinstitut',
      IBAN: 'IBAN',
      BIC: 'BIC',
      vatId: 'Umsatzsteuer ID',
    },
    switches: {
      onlyAssociatedProducts: 'Nur Zubehör anzeigen',
    },
  },
  also_edi_items: {
    name: 'Edi Daten',
    fields: {
      manufacturerPartNumber: 'SKU',
      manufacturerName: 'Hersteller',
      description: 'Name',
      categoryText1: 'Kategorie 1',
      categoryText2: 'Kategorie 2',
      categoryText3: 'Kategorie 3',
      netPrice: 'Net Preis',
    },
  },
  also_resellable_products: {
    name: 'Angebots Produkte',
    fields: {
      name: 'Name',
      manufacturer: 'Hersteller',
      sku: 'SKU',
      price: 'Preis',
      relatedSKUs: 'SKU des Zubehörs',
      relatedSKUCount: 'Zubehöranzahl',
    },
  },
}
