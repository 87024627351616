import React from 'react'
import {
  isAdmin,
  isAlsoAdmin,
  isReseller,
  isAlsoReseller,
  isAlsoEdiReseller,
} from 'Lib/helper'
import AdminDashboard from 'Components/Aluxo/Dashboard/AdminDashboard'
import ResellerDashboard from 'Components/Aluxo/Dashboard/ResellerDashboard'
import AlsoResellerDashboard from 'Components/Also/Dashboard/AlsoResellerDashboard'
import AlsoEdiResellerDashboard from 'Components/Also/Dashboard/EdiResellerDashboard'

const Dashboard = ({ permissions }) => {
  if (isAlsoAdmin(permissions)) {
    return <AdminDashboard />
  }
  if (isAdmin(permissions)) {
    return <AdminDashboard />
  }
  if (isReseller(permissions)) {
    return <ResellerDashboard />
  }
  if (isAlsoReseller(permissions)) {
    return <AlsoResellerDashboard />
  }
  if (isAlsoEdiReseller(permissions)) {
    return <AlsoEdiResellerDashboard />
  }
  return null
}

export default Dashboard
