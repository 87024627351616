import { Add } from '@material-ui/icons'
import GenericRestClientButton from 'Components/Common/ActionButtons/GenericRestClientButton'
import PropTypes from 'prop-types'
import React from 'react'

const SetProtectionPlanButton = ({
  deviceLineId,
  protectionPlanType,
  type,
  disabled,
  icon,
  showLabel,
}) => (
  <GenericRestClientButton
    action="addProtectionPlan"
    resource="device_order_lines"
    payload={{
      id: deviceLineId,
      data: { protectionPlanType },
    }}
    type={type}
    disabled={disabled}
    icon={icon || <Add />}
    noLabel={!showLabel}
  />
)

SetProtectionPlanButton.propTypes = {
  deviceLineId: PropTypes.string.isRequired,
  protectionPlanType: PropTypes.oneOf([
    'PROTECTION_PLAN',
    'PROTECTION_PLAN_WITH_THEFT_PROTECTION',
    'NO_PROTECTION_PLAN',
  ]).isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  showLabel: PropTypes.bool,
}

SetProtectionPlanButton.defaultProps = {
  disabled: false,
  icon: null,
  showLabel: false,
}

export default SetProtectionPlanButton
