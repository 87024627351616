import { SimpleForm, TextInput, useTranslate } from 'react-admin'
import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { validateRequiredIban } from 'Components/Aluxo/Customers/CustomerCreate'
import CustomerEditToolbar from 'Components/Aluxo/Customers/Edit/Toolbar'

const TranslatedText = ({ text }) => {
  const translate = useTranslate()
  return translate(text, { smart_count: 2 })
}

const FormHeading = ({ text }) => (
  <span
    style={{ fontSize: '1.5rem', margin: '15px 0 5px 0', display: 'block' }}
  >
    <TranslatedText text={text} />
  </span>
)

FormHeading.propTypes = {
  text: PropTypes.string.isRequired,
}

const BankDetails = ({ ...props }) => {
  if (!get(props, 'record.addresses')) {
    return null
  }

  return (
    <SimpleForm {...props} redirect={false} toolbar={<CustomerEditToolbar />}>
      <FormHeading text="resources.customers.fields.bankDetails.name" />
      <TextInput
        source="bankDetails.IBAN"
        label="resources.customers.fields.bankDetails.iban"
        validate={validateRequiredIban}
      />
    </SimpleForm>
  )
}

BankDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
}

export default BankDetails
