import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'

export const ProcessContext = React.createContext({
  processStatus: 'pending',
  setProcessStatus: () => {},
  creationResult: [],
  setCreationResult: () => {},
})

export const ProcessProvider = ({ children }) => {
  const states = {
    PENDING: 'PENDING',
    PROCESSING: 'PROCESSING',
    PROCESSED: 'PROCESSED',
    EDITING: 'EDITING',
  }
  const [creationResult, setCreationResult] = useState([])
  const [processStatus, setProcessStatus] = useState(states.PENDING)

  return (
    <ProcessContext.Provider
      value={{
        creationResult,
        setCreationResult,
        processStatus,
        setProcessStatus,
        states,
      }}
    >
      {children}
    </ProcessContext.Provider>
  )
}

ProcessProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useProcessContext = () => {
  const context = useContext(ProcessContext)
  if (context === undefined) {
    throw new Error('useProcessContext must be used within a ProcessProvider')
  }
  return context
}
