import PropTypes from 'prop-types'
import React from 'react'
import { useTranslate, useQuery } from 'react-admin'
import GenericAutoSuggest from 'Components/Common/InputFields/AutoSuggest'
import Fuse from 'fuse.js'

const renderSuggestionLabel = suggestion => suggestion.displayName

const getSuggestionValue = suggestion => suggestion.displayName

const getSuggestions = (suggestions, value) => {
  const inputValue = value.trim().toLowerCase()

  const fuzzySuggestions = suggestions.map(s => ({
    ...s,
    fuzzySearchKey: `${s.field} ${s.displayName}`,
  }))

  const fuse = new Fuse(fuzzySuggestions, {
    keys: ['displayName', 'field', 'fuzzySearchKey'],
  })

  const results = fuse.search(inputValue)

  const groups = results.reduce((acc, { item }) => {
    const group = acc.find(group => group.title === item.field)
    if (group) {
      group.suggestions.push(item)
    } else {
      acc.push({ title: item.field, suggestions: [item] })
    }
    return acc
  }, [])

  return groups
}

const EnumMemberAutoSuggest = ({
  record: { variants, type },
  onChange,
  meta,
}) => {
  const t = useTranslate()
  const { data: alsoEnumMembers, loading } = useQuery({
    type: 'getList',
    resource: 'also_enum_members',
    payload: {
      pagination: false,
      sort: false,
      filter: false,
    },
  })

  const onSuggestionsFetchRequested = setSuggestions => ({ value }) => {
    const references = alsoEnumMembers
      .filter(item =>
        type === 'Accessory'
          ? item.field !== 'WorkplaceUserDevice'
          : item.field === 'WorkplaceUserDevice',
      )
      .filter(item => !variants.some(variant => variant.enumMember === item.id))

    setSuggestions(getSuggestions(references, value))
  }

  const onSuggestionSelected = (event, { suggestion }) => {
    onChange(suggestion.id)
  }

  return (
    <GenericAutoSuggest
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionSelected={onSuggestionSelected}
      renderSuggestionLabel={renderSuggestionLabel}
      getSuggestionValue={getSuggestionValue}
      inputLabel={t(
        'resources.also_product_variants.fields.enumMember.displayName',
      )}
      autosuggestProps={{ multiSection: true }}
      meta={meta}
      loading={loading}
    />
  )
}

EnumMemberAutoSuggest.propTypes = {
  meta: PropTypes.object.isRequired, // eslint-disable-line
  onChange: PropTypes.func.isRequired,
  record: PropTypes.shape({
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string.isRequired,
      }),
    ).isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
}

export default EnumMemberAutoSuggest
