import { withStyles } from '@material-ui/core'
import React from 'react'
import { Login } from 'react-admin'
import LoginForm from './LoginForm'

const styles = {
  main: {
    backgroundPosition: 'center',
  },
}

const backgroundImage =
  process.env.REACT_APP_WORKPLACE_TYPE === 'also' ? 'also365' : 'aluxo365'

const CustomLoginPage = withStyles(styles)(({ classes }) => (
  <div>
    <Login classes={classes} backgroundImage={`/${backgroundImage}.jpg`}>
      <LoginForm />
    </Login>
  </div>
))

export default CustomLoginPage
