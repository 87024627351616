import React, { useMemo } from 'react'
import { TextField, Typography } from '@material-ui/core'
import { required, useDataProvider, useTranslate } from 'react-admin'
import { useField } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'

import { useBlockLayout, useTable } from 'react-table'
import {
  ACCELERATE_PRICING,
  DEAL_REG,
  NCAB,
} from 'Components/Aluxo/Discounts/CasCustomerDiscountEdit'
import NumberWithUnitTableInput from 'Components/Common/InputFields/TableInputs/NumberWithUnitTableInput'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from 'Components/Common/Buttons/Button'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const EditableNumberWithUnitCell = ({
  row: { index },
  column: { id, name },
}) => <NumberWithUnitTableInput name={`${name}.${index}.${id}`} unit="%" />

// Create an editable cell renderer
const SKUEditableCell = ({
  row: { index },
  column: { id, autoFocus, name },
}) => {
  const classes = useStyles()

  const {
    input: { value, onChange },
    meta,
  } = useField(`${name}.${index}.${id}`, { validate: required() })

  const {
    input: { onChange: onChangeName },
  } = useField(`${name}.${index}.productName`)

  const dataProvider = useDataProvider()

  const handleBlur = () => {
    if (meta.modified && value) {
      onChangeName('loading')
      dataProvider
        .getList('device_variants', {
          pagination: false,
          sort: false,
          filter: {
            sku: value,
          },
        })
        .then(res =>
          onChangeName(res.data.find(r => r.sku === value)?.name || ''),
        )
    }
  }

  return (
    <TextField
      label={null}
      value={value}
      onChange={onChange}
      className={classes.textField}
      onBlur={handleBlur}
      fullWidth
      autoFocus={autoFocus}
      error={meta.error}
    />
  )
}

const TypographyCell = ({ value }) => {
  const classes = useStyles()
  return (
    <Typography noWrap className={classes.textField}>
      {value}
    </Typography>
  )
}

const LoadingCell = ({ value }) => {
  const classes = useStyles()
  return (
    <Typography noWrap className={classes.textField}>
      {value === 'loading' ? <CircularProgress size="1rem" /> : value}
    </Typography>
  )
}

const ActionsCell = ({ row: { index }, column: { remove } }) => {
  const handleRemove = () => {
    remove(index)
  }
  return (
    <Button color="secondary" onClick={handleRemove}>
      <DeleteIcon />
    </Button>
  )
}

const defaultColumn = {
  Cell: TypographyCell,
}

const validate = entries => {
  const errors = entries?.map(entry => {
    const error = {}
    if (entries.filter(e => e.sku === entry.sku).length > 1) {
      error.sku = 'duplicate'
    }
    if (!entry.productName) {
      error.sku = 'unknown'
    }

    if ((entry.dealReg ?? 0) <= 0 && (entry.acceleratePricing ?? 0) <= 0) {
      error.dealReg = 'noDiscount'
      error.acceleratePricing = 'noDiscount'
    }

    return error
  })

  return errors
}

const DiscountTable = ({ name }) => {
  const { fields } = useFieldArray(name, {
    validate,
  })

  // HNS-00004
  // LQH-00003
  const t = useTranslate()

  const data = useMemo(() => fields.value || [], [fields.value])

  const columns = React.useMemo(
    () => [
      {
        Header: (
          <Typography>
            {t('dialogs.createCustomerCasProject.table.header.sku')}
          </Typography>
        ),
        accessor: 'sku',
        autoFocus: true,
        name: fields.name,
        Cell: SKUEditableCell,
      },
      {
        Header: (
          <Typography>
            {t('dialogs.createCustomerCasProject.table.header.dealReg')}
          </Typography>
        ),
        accessor: `${DEAL_REG}`,
        name: fields.name,
        Cell: EditableNumberWithUnitCell,
      },
      {
        Header: (
          <Typography>
            {t(
              'dialogs.createCustomerCasProject.table.header.acceleratePricing',
            )}
          </Typography>
        ),
        accessor: `${ACCELERATE_PRICING}`,
        name: fields.name,
        Cell: EditableNumberWithUnitCell,
      },
      {
        Header: (
          <Typography>
            {t('dialogs.createCustomerCasProject.table.header.ncab')}
          </Typography>
        ),
        accessor: `${NCAB}`,
        name: fields.name,
        Cell: EditableNumberWithUnitCell,
      },
      {
        Header: (
          <Typography>
            {t('dialogs.createCustomerCasProject.table.header.productName')}
          </Typography>
        ),
        accessor: 'productName',
        width: 300,
        Cell: LoadingCell,
      },
      {
        accessor: 'actions',
        Cell: ActionsCell,
        remove: idx => fields.remove(idx),
      },
    ],
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout,
  )

  return (
    <>
      <div {...getTableProps()} className="table">
        <div>
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map(column => (
                <div {...column.getHeaderProps()} className="th">
                  {column.render('Header')}
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <div {...row.getRowProps()} className="tr">
                {row.cells.map(cell => (
                  <div {...cell.getCellProps()} className="td">
                    {cell.render('Cell')}
                  </div>
                ))}
              </div>
            )
          })}
        </div>
      </div>
      <Button
        type="button"
        label="Hinzufügen"
        color="primary"
        disableOnGlobalLoad={false}
        onClick={() =>
          fields.push({
            sku: null,
          })
        }
      />
    </>
  )
}

export default DiscountTable
