import axios from 'axios'
import decode from 'jwt-decode'
import { intersection } from 'lodash'
import { showNotification } from 'ra-core'
import { url } from './httpClient'
import { store } from './App'

const headers = {
  'Content-Type': 'application/json',
  'X-Admin': true,
}

export default {
  login: ({ email, password }) =>
    axios
      .post(
        `${url}/login_check`,
        { email, password },
        {
          headers,
        },
      )
      .then(({ data: { token } }) => {
        const decoded = decode(token)
        if (
          intersection(decoded.roles, [
            'ROLE_RESELLER',
            'ROLE_ALSO_RESELLER',
            'ROLE_ALSO_EDI_RESELLER',
            'ROLE_AFFILIATE_RESELLER',
            'ROLE_ALSO_ADMIN',
            'ROLE_ADMIN',
          ]).length > 0
        ) {
          localStorage.setItem('roles', JSON.stringify(decoded.roles))
          localStorage.setItem('token', token)
        } else {
          throw new Error('auth.user_or_pass_not_correct')
        }
      })
      .catch(error => {
        if (error.response?.status < 200 || error.response?.status >= 300) {
          throw new Error(error.response.data.message)
        }

        throw error
      }),
  authRequestReset: async params => {
    const { email } = params

    return axios
      .post(
        `${url}/users/request-password`,
        { email },
        {
          headers,
        },
      )
      .catch(error => {
        if (error.response?.status < 200 || error.response?.status >= 300) {
          throw new Error(error.response.data.message)
        }

        throw error
      })
  },
  authPasswordReset: async params => {
    const { password, token } = params
    return axios
      .post(
        `${url}/users/reset-password`,
        { password, token },
        {
          headers,
        },
      )
      .catch(error => {
        if (error.response?.status < 200 || error.response?.status >= 300) {
          throw new Error(error.response.data.message)
        }

        throw error
      })
  },
  logout: () => {
    localStorage.removeItem('token')
    return Promise.resolve()
  },
  checkAuth: () =>
    localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),
  checkError: error => {
    const {
      response: { status },
    } = error

    switch (status) {
      case 403:
        store.dispatch(
          showNotification(
            'You are not authorized to perform this action.',
            'error',
          ),
        )
        return Promise.reject()
      case 401:
        localStorage.removeItem('token')
        return Promise.reject()
      default:
        return Promise.resolve()
    }
  },
  getPermissions: () =>
    Promise.resolve(JSON.parse(localStorage.getItem('roles'))),
}
