export default {
  name: 'Diensteistungen',
  nameOnce: 'Diensteistungen einmalig für die Bestellung',
  namePerWorkplace: 'Diensteistungen abhängig von Arbeitsplätzen',
  fields: {
    position: 'Pos',
    name: 'Name',
    category: 'Kategorie',
    monthlyRate: 'monatliche Rate',
    actions: 'Aktionen',
    quantity: 'Menge',
    total: 'Gesamtrate',
    provisioned: 'Angelegt',
  },
}
