import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import { grey, red } from '@material-ui/core/colors'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Dials from 'Components/Common/Dials'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'

const grey500 = grey[500]
const red500 = red[500]

const useStyles = makeStyles({
  titleLink: { textDecoration: 'none', color: '#000' },
  card: {
    flex: 1,
    marginRight: '1em',
  },
  cardTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.25rem',
    color: 'white',
  },
  icon: {
    float: 'right',
    width: 32,
    height: 32,
  },
  progressBarContainer: { padding: '16px' },
  progressBar: { width: '100%' },
  dialContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    paddingTop: '24px',
  },
  orderDetailContainer: {
    fontSize: '1em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0.5em 0',
  },
})

const determineColor = filteredOrders => {
  if (filteredOrders.find(({ overdue }) => overdue)) {
    return red500
  }
  if (filteredOrders.find(({ priority }) => priority)) {
    return undefined
  }
  return grey500
}

const OrderPanel = ({ orders, marking, also }) => {
  const filteredOrders = useMemo(
    () => orders.filter(o => o.marking === marking),
    [orders],
  )

  const {
    palette: {
      primary: { main },
      common: { white },
      action: { disabledBackground, active },
    },
  } = useTheme()
  const translate = useTranslate()
  const classes = useStyles()

  const basePath = also ? 'also_orders' : 'orders'

  const highPriorityOrders = filteredOrders.filter(o => o.priority)
  const overdueOrders = filteredOrders.filter(o => o.overdue)
  return (
    <Card
      showExpandableButton
      style={{
        border: `1px solid ${determineColor(filteredOrders) || main}`,
        paddingBottom: '16px',
      }}
    >
      <CardHeader
        style={{
          backgroundColor: determineColor(filteredOrders) || main,
          padding: '8px 16px',
        }}
        titleTypographyProps={{ style: { color: white } }}
        title={translate(
          also
            ? `alsoOrders.markingsShort.${marking}`
            : `orders.markingsShort.${marking}`,
        )}
      />
      <div className={classes.dialContainer}>
        <Dials
          size={8}
          circles={[
            {
              color: disabledBackground,
              percent: 1,
              dialSize: 8,
              width: 0.5,
            },
            {
              color: main,
              percent:
                filteredOrders.length > 0
                  ? highPriorityOrders.length / filteredOrders.length
                  : 0,
              dialSize: 8,
              width: 0.25,
            },
            {
              color: red500,
              percent:
                filteredOrders.length > 0
                  ? overdueOrders.length / filteredOrders.length
                  : 0,
              dialSize: 7.5,
              width: 0.25,
            },
          ]}
          label={
            <Link
              to={`/${basePath}?filter={"marking":"${marking}"}&order=DESC&page=1&perPage=10&sort=orderNumber`}
              className="label"
              style={{
                display: 'block',
                fontSize: '3em',
                textAlign: 'center',
                background: 'none',
                color: active,
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              {filteredOrders.length}
            </Link>
          }
        />
      </div>

      <div
        className={classes.orderDetailContainer}
        style={{
          color: main,
        }}
      >
        <Link
          style={{
            textDecoration: 'none',
            color: highPriorityOrders.length > 0 ? main : grey500,
          }}
          to={`/${basePath}?filter={"marking":"${marking}","priority":"true"}&order=DESC&page=1&perPage=10&sort=orderNumber`}
        >
          {`${highPriorityOrders.length} ${translate(
            'dashboard.highPriority',
          )}`}
        </Link>
      </div>
      <div
        className={classes.orderDetailContainer}
        style={{
          color: red500,
        }}
      >
        <Link
          style={{
            textDecoration: 'none',
            color: overdueOrders.length > 0 ? red500 : grey500,
          }}
          to={`/${basePath}?filter={"marking":"${marking}","overdue":"true"}&order=DESC&page=1&perPage=10&sort=orderNumber`}
        >
          {`${overdueOrders.length} ${translate('dashboard.overdue')}`}
        </Link>
      </div>
    </Card>
  )
}

OrderPanel.propTypes = {
  also: PropTypes.bool,
  marking: PropTypes.string.isRequired,
}

OrderPanel.defaultProps = {
  also: false,
}

export default OrderPanel
