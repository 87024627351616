import React from 'react'
import {
  useEditController,
  TitleForRecord,
  sanitizeEmptyValues,
} from 'react-admin'
import { Box, CircularProgress } from '@material-ui/core'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import AlsoOfferGridFormView from 'Components/Also/Offers/GridFormView'
import OrderProvider from 'OrderContext'
import { sortBy } from 'lodash'
import { insert } from 'Lib/finalFormMutators'

const AlsoOfferEdit = props => {
  const { resource, record, save, basePath, version } = useEditController(props)

  if (!record) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <CircularProgress />
      </Box>
    )
  }

  const initialValues = {
    ...record,
    offerLines: sortBy(record.offerLines, 'position'),
  }

  const handleSubmit = values => {
    save(sanitizeEmptyValues(initialValues, values), false)
  }

  return (
    <OrderProvider record={record} basePath={basePath} resource={resource}>
      <>
        <TitleForRecord
          record={record}
          title={`Angebot ${record.offerNumber}`}
        />
        <Form
          key={version}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          keepDirtyOnReinitialize
          mutators={{
            ...arrayMutators,
            insertAt: insert,
          }}
        >
          {formProps => (
            <AlsoOfferGridFormView
              resource={resource}
              basePath={basePath}
              record={record}
              {...formProps}
            />
          )}
        </Form>
      </>
    </OrderProvider>
  )
}

export default AlsoOfferEdit
