import { Box, CircularProgress } from '@material-ui/core'
import GridFormView from 'Components/Also/Orders/OrderEditGridFormView'
import OrderTitle from 'Components/Common/Orders/OrderTitle'
import moment from 'moment'
import OrderProvider from 'OrderContext'
import PropTypes from 'prop-types'
import React from 'react'
import {
  sanitizeEmptyValues,
  TitleForRecord,
  useEditController,
} from 'react-admin'
import { Form } from 'react-final-form'

moment.locale('de')

const OrderEdit = props => {
  const { resource, record, save, basePath, version } = useEditController(props)

  if (!record) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <CircularProgress />
      </Box>
    )
  }

  const initialValues = record

  const handleSubmit = values => {
    save(sanitizeEmptyValues(initialValues, values), false)
  }

  return (
    <OrderProvider record={record} basePath={basePath} resource={resource}>
      <TitleForRecord record={record} title={<OrderTitle />} />
      <Form
        key={version}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        // keepDirtyOnReinitialize
      >
        {formProps => (
          <GridFormView
            resource={resource}
            basePath={basePath}
            record={record}
            {...formProps}
          />
        )}
      </Form>
    </OrderProvider>
  )
}

OrderEdit.propTypes = {
  id: PropTypes.string.isRequired,
}

export default OrderEdit
