import EditActions from 'Components/Common/EditActions'
import EditToolbar from 'Components/Common/EditToolbar'
import get from 'lodash/get'
import React from 'react'
import {
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'
import { validateRequired } from './devices'

const ManufacturerFilter = props => (
  <Filter {...props}>
    <TextInput source="name" />
  </Filter>
)

export const ManufacturerList = props => (
  <List {...props} filters={<ManufacturerFilter />}>
    <Datagrid>
      <TextField source="name" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
)

const ManufacturerTitle = ({ record }) => get(record, 'name')

export const ManufacturerEdit = props => (
  <Edit actions={<EditActions />} title={<ManufacturerTitle />} {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="slug" validate={validateRequired} />
      <TextInput source="name" validate={validateRequired} />
      <ReferenceArrayInput
        source="distributors_reference_ids"
        reference="itscope_distributors"
        filter={{ active: true }}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>

      <TextInput
        multiline
        source="protectionPlanSKUs"
        format={arr => arr.join('\n')}
        parse={value => value.split(/[\n,;]+/)}
      />

      <TextInput source="itScopeId" />
    </SimpleForm>
  </Edit>
)

const defaultValues = {
  active: true,
  distributors: [],
  protectionPlanSKUs: [],
}

export const ManufacturerCreate = props => (
  <Create {...props}>
    <SimpleForm defaultValue={defaultValues}>
      <TextInput source="name" validate={validateRequired} />
    </SimpleForm>
  </Create>
)
