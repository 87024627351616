import AccessorySkuInput from 'Components/Also/AccessorySkuInput'
import CreateProductVariant from 'Components/Also/CreateProductVariant'
import CreateInformationTabButton from 'Components/Common/Buttons/CreateInformationTabButton'
import ImagesInput from 'Components/Common/InputFields/ImagesInput'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import BooleanField from 'Components/Common/ViewFields/BooleanField'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import { get } from 'lodash'
import RichTextInput from 'ra-input-rich-text'
import React, { Fragment } from 'react'
import {
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  FormDataConsumer,
  FormTab,
  List,
  ListButton,
  ReferenceManyField,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin'
import ReferenceInput from 'Components/Common/ReferenceInput'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

const AlsoProductTitle = ({ record }) => get(record, 'name')

export const AlsoProductList = props => (
  <List
    pagination={<CustomPagination />}
    aside={<LanguageSwitcherAside />}
    {...props}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="manufacturer" />
      <TextField source="type" />
      <TextField source="category.name" />
      <BooleanField source="active" />
      <DateField source="updatedAt" />
      <EditButton />
    </Datagrid>
  </List>
)

const AlsoProductEditActions = ({ basePath, data }) => (
  <RightAlignedCardActions>
    <ListButton basePath={basePath} />
    <CreateInformationTabButton
      type="also_product_information_tabs"
      reference={get(data, '@id')}
    />
  </RightAlignedCardActions>
)

export const AlsoProductEdit = props => (
  <Edit
    title={<AlsoProductTitle />}
    actions={<AlsoProductEditActions />}
    aside={<LanguageSwitcherAside />}
    {...props}
  >
    <TabbedForm redirect={false}>
      <FormTab label="resources.also_products.tabs.general">
        <TextInput source="name" />
        <TextInput source="manufacturer" />
        <TextInput source="slug" />
        <RichTextInput source="shortDescription" />

        <BooleanInput source="active" />
        <SelectInput
          source="type"
          choices={[
            { id: 'WorkplaceDevice', name: 'WorkplaceDevice' },
            { id: 'Accessory', name: 'Accessory' },
          ]}
        />

        <FormDataConsumer fullWidth>
          {({ formData, ...rest }) =>
            formData.type === 'WorkplaceDevice' && (
              <Fragment>
                <ReferenceInput
                  source="category"
                  reference="also_categories"
                  {...rest}
                >
                  <SelectInput optionText={choice => choice.name} />
                </ReferenceInput>

                <AccessorySkuInput
                  fullWidth
                  source="accessorySKUs"
                  inputLabel="resources.also_products.fields.product.search"
                  {...rest}
                />

                <AccessorySkuInput
                  fullWidth
                  source="featuredSKUs"
                  inputLabel="resources.also_products.fields.product.search"
                  {...rest}
                />
              </Fragment>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, rest }) =>
            formData.type === 'Accessory' && (
              <BooleanInput source="genericAccessory" {...rest} />
            )
          }
        </FormDataConsumer>
        <ImagesInput
          resourceName="also_product_images"
          referenceName="product"
        />
      </FormTab>
      <FormTab label="resources.also_products.tabs.variants">
        <ReferenceManyField
          label="resources.also_products.tabs.variants"
          reference="also_product_variants"
          target="product"
        >
          <Datagrid>
            <TextField source="name" />
            <CurrencyField source="monthlyPrices.18" />
            <CurrencyField source="monthlyPrices.24" />
            <CurrencyField source="monthlyPrices.36" />
            <CurrencyField source="monthlyPrices.48" />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
        <CreateProductVariant {...props} />
      </FormTab>
      <FormTab label="resources.also_products.tabs.information_tabs">
        <ReferenceManyField
          label="resources.also_products.fields.information_tabs"
          reference="also_product_information_tabs"
          target="reference"
        >
          <Datagrid>
            <TextField source="title" />
            <EditButton />
            <DeleteButton redirect={false} />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
)

export const AlsoProductCreate = props => (
  <Create {...props}>
    <SimpleForm defaultValue={{ genericAccessory: true }}>
      <TextInput source="name" />
      <TextInput source="manufacturer" />

      <SelectInput
        source="type"
        choices={[
          { id: 'WorkplaceDevice', name: 'WorkplaceDevice' },
          { id: 'Accessory', name: 'Accessory' },
        ]}
      />

      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.type === 'WorkplaceDevice' ? (
            <ReferenceInput
              source="category"
              reference="also_categories"
              {...rest}
            >
              <SelectInput optionText={choice => choice.name} />
            </ReferenceInput>
          ) : (
            <BooleanInput source="genericAccessory" {...rest} />
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Create>
)
