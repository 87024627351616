import React from 'react'

const JoinedArrayField = ({ source, record = {} }) => (
  <span>{record[source] ? record[source].join(', ') : ''}</span>
)

JoinedArrayField.defaultProps = {
  addLabel: true,
}

export default JoinedArrayField
