import GenericRestClientButton from 'Components/Common/ActionButtons/GenericRestClientButton'
import React from 'react'
import { useOrder } from 'OrderContext'

const SuppressEmailsButton = () => {
  const { record } = useOrder()
  return (
    <GenericRestClientButton
      type={record.suppressMails ? 'enableMails' : 'suppressMails'}
      action="update"
      resource="orders"
      payload={{
        id: record.id,
        data: { suppressMails: !record.suppressMails },
      }}
    />
  )
}

export default SuppressEmailsButton
