import Chip from '@material-ui/core/Chip'
import GenericAutoSuggest from 'Components/Common/InputFields/AutoSuggest'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { addField, Labeled, useTranslate } from 'react-admin'
import styled from 'styled-components/macro'
import Fuse from 'fuse.js'
import { useQuery } from 'react-query'
import { useApiClient } from 'Rest/fetchHydra'

const renderSuggestionLabel = suggestion =>
  `${suggestion.sku} - ${suggestion.fullName}`

const getSuggestionValue = suggestion =>
  `${suggestion.sku} - ${suggestion.fullName}`

const getSuggestions = (suggestions, value) => {
  const inputValue = value.trim().toLowerCase()
  const inputLength = inputValue.length

  if (inputLength === 0) {
    return suggestions
  }

  const fuzzySuggestions = suggestions.map(s => ({
    ...s,
    fuzzySearchKey: `${s.sku} ${s.manufacturer} ${s.name}`,
  }))

  const fuse = new Fuse(fuzzySuggestions, {
    keys: ['sku', 'name', 'manufacturer', 'fuzzySearchKey'],
  })

  const results = fuse.search(inputValue)

  return results.map(r => r.item)
}

const ChipContainer = styled.div`
  display: flex;
  margin: 0 -0.5em;
  & > * {
    margin: 0 0.5em;
  }
`

const AccessorySkuInput = ({ input, meta, inputLabel, ...rest }) => {
  const translate = useTranslate()
  const apiClient = useApiClient()

  const alsoProductVariantsQuery = useQuery(
    ['also_product_variants'],
    () =>
      apiClient({
        endpoint: `/also_product_variants`,
        headers: {
          Accept: 'application/json',
        },
        params: {
          pagination: false,
          'product.type': 'Accessory',
        },
      }).then(res => res.data),

    {
      initialData: [],
    },
  )
  const alsoResellerProductsQuery = useQuery(
    ['also_reseller_products'],
    () =>
      apiClient({
        endpoint: `/also_reseller_products`,
        headers: {
          Accept: 'application/json',
        },
        params: {
          pagination: false,
          'product.type': 'Accessory',
        },
      }).then(res => res.data),

    {
      initialData: [],
    },
  )

  const loading =
    alsoResellerProductsQuery.isFetching || alsoProductVariantsQuery.isFetching

  const { data: alsoResellerProducts } = alsoResellerProductsQuery
  const { data: alsoProductVariants } = alsoProductVariantsQuery

  const activeVariants = useMemo(
    () =>
      alsoProductVariants.filter(
        v =>
          v.active &&
          alsoResellerProducts.some(p => p.active && p.product === v.product),
      ),
    [alsoResellerProducts, alsoProductVariants],
  )

  const onSuggestionsFetchRequested = setSuggestions => ({ value }) => {
    const variants = activeVariants
      .filter(r => typeof r !== 'undefined')
      .filter(v => !input.value.some(sku => sku === v.sku))

    setSuggestions(getSuggestions(variants, value))
  }

  const onSuggestionSelected = (event, { suggestion }) => {
    input.onChange([...input.value, suggestion.sku])
  }

  return (
    <Labeled {...rest} fullWidth>
      <div>
        {input.value && (
          <ChipContainer>
            {input.value.map(value => (
              <Chip
                onDelete={() =>
                  input.onChange(input.value.filter(v => v !== value))
                }
                label={value}
              />
            ))}
          </ChipContainer>
        )}
        <GenericAutoSuggest
          clearOnSelect
          loading={loading}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionSelected={onSuggestionSelected}
          renderSuggestionLabel={renderSuggestionLabel}
          getSuggestionValue={getSuggestionValue}
          inputLabel={translate(
            inputLabel ||
              'resources.also_reseller_products.fields.product.name',
          )}
          meta={meta}
        />
      </div>
    </Labeled>
  )
}

AccessorySkuInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export default addField(AccessorySkuInput)
