export default {
  bulkCreateDeviceVariantsDialog: {
    tasks: {
      labels: {
        prepareCNet: 'CNet Vorbereiten',
        createDevice: 'Gerät %{index} mit SKU %{sku} anlegen',
        createVariant: 'Variante mit SKU %{sku} anlegen',
        deviceCreated: 'Gerät %{name} angelegt',
      },
    },
    steps: {
      labels: {
        dataEntry: 'Dateneingabe',
        process: 'Verarbeitung',
        editing: 'Nachbearbeitung',
      },
    },
  },
}
