import React from 'react'
import PropTypes from 'prop-types'

const WorkplaceConfigurationDisplay = ({ record }) =>
  record.workplaceConfigurationFields.map(workplaceConfigurationField => (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '200px' }}>{workplaceConfigurationField.field}</div>
      <div style={{ marginLeft: '1em' }}>
        {workplaceConfigurationField.value}
      </div>
    </div>
  ))

WorkplaceConfigurationDisplay.propTypes = {
  record: PropTypes.shape({
    workplaceConfigurationFields: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
}

WorkplaceConfigurationDisplay.defaultProps = {
  addLabel: true,
}

export default WorkplaceConfigurationDisplay
