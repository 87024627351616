import React from 'react'
import PropTypes from 'prop-types'
import InStockDisplay from 'Components/Aluxo/InStockDisplay/InStockDisplay'
import { Box } from '@material-ui/core'

const OrderLineQuantity = ({ stock, quantity, sku, puid }) => (
  <Box display="flex">
    {puid && <InStockDisplay stock={stock} stockNeeded={quantity} sku={sku} />}
    {quantity}
  </Box>
)

OrderLineQuantity.propTypes = {
  stock: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  sku: PropTypes.string.isRequired,
  puid: PropTypes.string,
}

OrderLineQuantity.defaultProps = {
  puid: null,
}

export default OrderLineQuantity
