import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from 'Components/Common/Buttons/Button'
import produce from 'immer'
import { useApiClient } from 'Rest/fetchHydra'
import { useProcessContext } from 'Components/Aluxo/ActionDialogs/BulkCreateDeviceVariantsDialog/ProcessContext'
import StepContent from 'Components/Aluxo/ActionDialogs/BulkCreateDeviceVariantsDialog/BeforeCreationWizard/StepContent'
import { useTranslate } from 'react-admin'
import ProcessingInnerSteps from 'Components/Aluxo/ActionDialogs/BulkCreateDeviceVariantsDialog/BeforeCreationWizard/ProcessingInnerSteps'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
}))

const ProcessingStep = ({
  stepperDetails: {
    steps,
    activeStep,
    needsConfirmation,
    cancelConfirmation,
    handleConfirmation,
  },
  closeDialog,
}) => {
  const {
    processStatus,
    creationResult,
    setCreationResult,
    setProcessStatus,
    states,
  } = useProcessContext()
  const apiClient = useApiClient()

  const classes = useStyles()
  const t = useTranslate()

  const handleComplete = () => {
    setProcessStatus(states.EDITING)
  }

  const [prepareItem, setPrepareItem] = useState({
    label: t(
      'processes.bulkCreateDeviceVariantsDialog.tasks.labels.prepareCNet',
    ),
    status: 'pending',
    async execute() {
      try {
        await apiClient({
          endpoint: '/common/prepare_cnet_files',
          method: 'POST',
          body: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        this.status = 'success'
      } catch (e) {
        this.status = 'error'
      }
    },
  })

  const processItems = useCallback(async () => {
    setProcessStatus(states.PROCESSING)
    setPrepareItem(prepareItem =>
      produce(prepareItem, draft => {
        draft.status = states.PROCESSING
      }),
    )

    await prepareItem.execute()

    setPrepareItem(prepareItem)

    for (let index = 0; index < creationResult.length; index++) {
      const device = creationResult[index]
      setCreationResult(creationResult =>
        produce(creationResult, draft => {
          draft[index].status = states.PROCESSING
        }),
      )

      await device.execute()

      setCreationResult(creationResult =>
        produce(creationResult, draft => {
          draft[index] = device
        }),
      )

      for (let j = 0; j < device.variants.length; j++) {
        if (device.status === 'error' || !device.currentDeviceId) {
          break
        } else {
          const variant = device.variants[j]
          setCreationResult(creationResult =>
            produce(creationResult, draft => {
              draft[index].variants[j].status = states.PROCESSING
            }),
          )
          await variant.execute(device.currentDeviceId)
          setCreationResult(creationResult =>
            produce(creationResult, draft => {
              draft[index].variants[j] = variant
            }),
          )
        }
      }
    }
    setProcessStatus(states.PROCESSED)
  }, [])

  const handleNext = async () => {
    if (processStatus === states.PENDING) {
      await processItems()
    } else {
      handleComplete()
    }
  }

  return (
    <StepContent
      steps={steps}
      activeStep={activeStep}
      needsConfirmation={needsConfirmation}
      cancelConfirmation={cancelConfirmation}
      handleConfirmation={handleConfirmation}
      content={<ProcessingInnerSteps prepareItem={prepareItem} />}
      buttons={
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={processStatus === states.PROCESSING}
            className={classes.button}
            label="actions.next"
          />
          <Button
            label="Schließen"
            type="button"
            disabled={processStatus === states.PROCESSING}
            color="secondary"
            variant="text"
            onClick={closeDialog}
          />
        </>
      }
    />
  )
}
export default ProcessingStep
