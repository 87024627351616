import {
  RadioButtonGroupInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
  useRefresh,
  useNotify,
} from 'react-admin'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useField } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { Grid, Button, Box } from '@material-ui/core'
import { validateRequired } from 'Resources/Aluxo/devices'
import CustomerEditToolbar from 'Components/Aluxo/Customers/Edit/Toolbar'
import { useApiClient } from 'Rest/fetchHydra'
import { validateRequiredZipCode } from '../CustomerCreate'

const TranslatedText = ({ text }) => {
  const translate = useTranslate()
  return translate(text, { smart_count: 2 })
}

const FormHeading = ({ text }) => (
  <span
    style={{ fontSize: '1.5rem', margin: '15px 0 5px 0', display: 'block' }}
  >
    <TranslatedText text={text} />
  </span>
)

FormHeading.propTypes = {
  text: PropTypes.string.isRequired,
}

const FormatForm = ({ children }) =>
  children.map(child => <div className="ra-input">{child}</div>)

const AddressSet = ({ name, onRemove }) => {
  const apiClient = useApiClient()
  const refreshView = useRefresh()
  const showNotification = useNotify()

  const fieldName = `${name}.addresses`
  const { input } = useField(fieldName)
  const {
    input: { value: isDefaultSet },
  } = useField(`${name}.defaultSet`)

  const {
    input: { value: id },
  } = useField(`${name}.id`)

  const idxShipping = input.value.findIndex(a => a.type === 'shipping')
  const idxContact = input.value.findIndex(a => a.type === 'contact')

  useEffect(() => {
    if (idxShipping === -1) {
      input.onChange([
        ...input.value,
        {
          company: '',
          legalForm: '',
          salutation: '',
          firstName: '',
          lastName: '',
          addressAddon: '',
          street: '',
          postcode: '',
          city: '',
          email: '',
          ccEmail: [],
          phone: '',
          type: 'shipping',
          emailType: '',
        },
      ])
    }
    if (idxContact === -1) {
      input.onChange([
        ...input.value,
        {
          company: '',
          legalForm: '',
          salutation: '',
          firstName: '',
          lastName: '',
          street: '',
          postcode: '',
          city: '',
          email: '',
          ccEmail: [],
          phone: '',
          type: 'contact',
          emailType: 'formal',
        },
      ])
    }
  }, [idxShipping, idxContact])

  const handleSetDefaultClick = async () => {
    try {
      await apiClient({
        endpoint: '/mark_address_set_as_default',
        method: 'POST',
        body: { customerAddressSet: id },
        headers: {
          'Content-Type': 'application/json',
        },
      })

      showNotification('notifications.markAddressSetDefault.success')
      refreshView()
    } catch (e) {
      showNotification('notifications.markAddressSetDefault.fail', 'warning')
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs="12" sm="6">
        <Grid container spacing={1}>
          <Grid item>
            <FormHeading text="resources.customers.fields.shippingAddress.name" />
          </Grid>
          {isDefaultSet ? (
            <Grid item>
              <span
                style={{
                  fontSize: '1.5rem',
                  margin: '15px 0 5px 0',
                  display: 'block',
                  color: 'rgba(0, 0, 0, 0.57)',
                }}
              >
                Standard
              </span>
            </Grid>
          ) : null}
        </Grid>
        <FormatForm>
          <TextInput
            source={`${fieldName}.${idxShipping}.company`}
            validate={validateRequired}
            fullWidth
            label="resources.customers.fields.addresses.company"
          />
          <TextInput
            source={`${fieldName}.${idxShipping}.addressAddon`}
            validate={validateRequired}
            fullWidth
            label="resources.customers.fields.addresses.addressAddon"
            parse={value => value || ''}
          />
          <TextInput
            source={`${fieldName}.${idxShipping}.street`}
            validate={validateRequired}
            fullWidth
            label="resources.customers.fields.addresses.street"
          />
          <TextInput
            source={`${fieldName}.${idxShipping}.postcode`}
            validate={validateRequiredZipCode}
            label="resources.customers.fields.addresses.postcode"
          />
          <TextInput
            source={`${fieldName}.${idxShipping}.city`}
            validate={validateRequired}
            fullWidth
            label="resources.customers.fields.addresses.city"
          />
        </FormatForm>
      </Grid>
      <Grid item xs="12" sm="6">
        <FormHeading text="resources.customers.fields.contactAddress.name" />
        <FormatForm>
          <SelectInput
            source={`${fieldName}.${idxContact}.salutation`}
            choices={[
              { id: 'Frau', name: 'Frau' },
              { id: 'Herr', name: 'Herr' },
            ]}
            validate={validateRequired}
            fullWidth
            label="resources.customers.fields.addresses.salutation"
          />
          <TextInput
            source={`${fieldName}.${idxContact}.firstName`}
            validate={validateRequired}
            fullWidth
            label="resources.customers.fields.addresses.firstName"
          />
          <TextInput
            source={`${fieldName}.${idxContact}.lastName`}
            validate={validateRequired}
            fullWidth
            label="resources.customers.fields.addresses.lastName"
          />
          <TextInput
            source={`${fieldName}.${idxContact}.email`}
            fullWidth
            label="resources.customers.fields.addresses.email"
          />
          <TextInput
            source={`${fieldName}.${idxContact}.ccEmail`}
            // rows={1}
            multiline
            fullWidth
            label="resources.customers.fields.addresses.ccEmail"
            format={arr => (arr ?? []).join('\n')}
            parse={value => value.split(/[\n,;]+/)}
          />
          <RadioButtonGroupInput
            fullWidth
            source={`${fieldName}.${idxContact}.emailType`}
            label="resources.customers.fields.addresses.emailType"
            choices={[
              {
                id: 'formal',
                name: 'resources.customers.emailTypes.formal',
              },
              {
                id: 'informal',
                name: 'resources.customers.emailTypes.informal',
              },
              {
                id: 'personal',
                name: 'resources.customers.emailTypes.personal',
              },
            ]}
          />
        </FormatForm>
      </Grid>

      <Grid item xs="12">
        <Box display="flex" justifyContent="space-between">
          {id && !isDefaultSet ? (
            <Button
              type="button"
              color="primary"
              variant="outlined"
              onClick={handleSetDefaultClick}
            >
              als Standard-Adresse markieren
            </Button>
          ) : null}
          {!isDefaultSet ? (
            <Button
              type="button"
              color="secondary"
              variant="contained"
              onClick={onRemove}
            >
              Entfernen
            </Button>
          ) : null}
        </Box>
      </Grid>
    </Grid>
  )
}

const AddressSets = ({ ...props }) => {
  if (!get(props, 'record.addresses')) {
    return null
  }

  return (
    <SimpleForm {...props} redirect={false} toolbar={<CustomerEditToolbar />}>
      <FormHeading text="resources.customers.fields.addressSets.name" />
      <FieldArray name="addressSets">
        {({ fields }) => (
          <Grid container spacing={1}>
            {fields.map((name, index) => (
              <Grid item xs="12">
                <AddressSet name={name} onRemove={() => fields.remove(index)} />
              </Grid>
            ))}
            <Grid item xs="12">
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={() =>
                  fields.push({
                    default: false,
                    addresses: [
                      {
                        company: '',
                        legalForm: '',
                        salutation: '',
                        firstName: '',
                        lastName: '',
                        street: '',
                        postcode: '',
                        city: '',
                        email: '',
                        ccEmail: [],
                        phone: '',
                        type: 'contact',
                        emailType: 'formal',
                      },
                      {
                        company: '',
                        legalForm: '',
                        salutation: '',
                        firstName: '',
                        lastName: '',
                        street: '',
                        postcode: '',
                        city: '',
                        email: '',
                        ccEmail: [],
                        phone: '',
                        type: 'shipping',
                        emailType: '',
                      },
                    ],
                  })
                }
              >
                neue Lieferadresse hinzufügen
              </Button>
            </Grid>
          </Grid>
        )}
      </FieldArray>
    </SimpleForm>
  )
}

AddressSets.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
}

export default AddressSets
