import { AddToPhotos } from '@material-ui/icons'
import Button from 'Components/Common/Buttons/Button'
import { last } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import {
  useLoading,
  useNotify,
  useRefresh,
  useTranslate,
  useUnselectAll,
} from 'react-admin'
import { Link } from 'react-router-dom'
import { useApiClient } from 'Rest/fetchHydra'

export const BulkAddToPortfolioButton = ({ selectedIds }) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll()
  const loading = useLoading()
  const apiClient = useApiClient()

  const onClick = async () => {
    const res = await apiClient({
      endpoint: '/also_add_edi_items_to_portfolio',
      method: 'POST',
      body: { ediItems: selectedIds },
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })

    const count = res.data.length

    if (count > 0) {
      notify(`${count} items added to portfolio`)
    }

    if (res.length - count > 0) {
      notify(`${res.length - count} items not added to portfolio`, 'warning')
    }

    unselectAll('also_edi_items')
    refresh()
  }

  return (
    <Button
      label="actions.alsoAddEdiItemToPortfolio"
      disabled={loading}
      onClick={onClick}
    >
      <AddToPhotos />
    </Button>
  )
}

export const AddToPortfolioButton = ({ record }) => {
  const showNotification = useNotify()
  const refreshView = useRefresh()
  const loading = useLoading()
  const t = useTranslate()
  const apiClient = useApiClient()

  if (record.resellableProduct) {
    const id = last(record.resellableProduct.split('/'))
    return (
      <Link to={`/also_resellable_products/${id}`}>
        {t('actions.alsoShowEdiItemInPortfolio')}
      </Link>
    )
  }

  const handleSubmit = async () => {
    try {
      const res = await apiClient({
        endpoint: '/also_add_edi_items_to_portfolio',
        method: 'POST',
        body: { ediItems: [record.id] },
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })

      if (res.data.length === 0) {
        throw new Error('Product already in Portfolio')
      }

      showNotification(`notifications.alsoAddEdiItemToPortfolio.success`)

      refreshView()
    } catch (error) {
      console.log(error)
      showNotification(
        `notifications.alsoAddEdiItemToPortfolio.fail`,
        'warning',
      )
    }
  }

  return (
    <>
      <Button
        color="primary"
        variant="text"
        onClick={handleSubmit}
        disabled={loading}
        label="actions.alsoAddEdiItemToPortfolio"
      />
    </>
  )
}

AddToPortfolioButton.propTypes = {
  record: PropTypes.object,
}
