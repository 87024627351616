import AddDialogForm, {
  DIALOG_TYPE_EXISTING,
  DIALOG_TYPE_CUSTOM,
} from 'Components/Aluxo/OrderLines/Lines/AddDialogForm'
import { useOrder } from 'OrderContext'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useMutation, useNotify, useRefresh, useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from 'Components/Common/Layout/TabPanel'
import head from 'lodash/head'
import { useApiClient } from 'Rest/fetchHydra'
import { useQueryClient } from 'react-query'

const snakeToCamel = str =>
  str.replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', ''),
  )

export default function AddDialogContent({
  handleClose,
  reference,
  initialValues,
  dialogProps: { supportedTypes, ...dialogProps },
}) {
  const [activeTab, setActiveTab] = useState(0)
  const showNotification = useNotify()
  const refreshView = useRefresh()
  const [mutate, { loading }] = useMutation()
  const translate = useTranslate()
  const apiClient = useApiClient()

  const queryClient = useQueryClient()

  const { record } = useOrder()

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  const handleSave = (values, form) => {
    const priceFieldState = form.getFieldState('price')
    sessionStorage.setItem(
      `${record.originId}-includeItScope`,
      JSON.stringify(values.includeItScope),
    )

    mutate(
      {
        type: 'create',
        resource: reference,
        payload: { data: values },
      },
      {
        onSuccess: res => {
          showNotification(`notifications.add_${reference}.success`)

          handleClose()

          if (!priceFieldState.modified) {
            showNotification(`notifications.updatePriceAndStock.pending`)
          } else {
            showNotification(`notifications.updateStock.pending`)
          }

          apiClient({
            method: 'POST',
            endpoint: `/refresh_price_and_stock_for_order_line`,
            headers: {
              'Content-Type': 'application/json',
            },
            body: {
              [snakeToCamel(reference.substr(0, reference.length - 1))]: res
                .data.id,
              updatePrice: !priceFieldState.modified,
            },
          })
            .then(() => {
              if (!priceFieldState.modified) {
                showNotification(`notifications.updatePriceAndStock.success`)
              } else {
                showNotification(`notifications.updateStock.success`)
              }
              queryClient.invalidateQueries(reference)
              refreshView()
            })
            .catch(() => {
              if (!priceFieldState.modified) {
                showNotification(`notifications.updatePriceAndStock.fail`)
              } else {
                showNotification(`notifications.updateStock.fail`)
              }
              queryClient.invalidateQueries(reference)
              refreshView()
            })

          // queryClient.invalidateQueries([
          //   'device_order_lines',
          //   'product_order_lines',
          //   'generic_order_lines',
          //   'service_extension_order_lines',
          //   'service_order_lines',
          //   'promo_bundle_order_lines',
          // ])
        },
        onFailure: () => {
          showNotification(`notifications.add_${reference}.fail`, 'warning')
        },
      },
    )
  }

  const includeItScope = sessionStorage.getItem(
    `${record.originId}-includeItScope`,
  )
    ? JSON.parse(sessionStorage.getItem(`${record.originId}-includeItScope`))
    : false

  const PreparedForm = ({ type }) => (
    <Form
      initialValues={{
        order: record.originId,
        includeItScope,
        ...initialValues,
      }}
      onSubmit={handleSave}
      component={AddDialogForm}
      reference={reference}
      handleClose={handleClose}
      record={record}
      loading={loading}
      dialogProps={type ? { type, ...dialogProps } : dialogProps}
    />
  )

  PreparedForm.propTypes = {
    type: PropTypes.oneOf([DIALOG_TYPE_EXISTING, DIALOG_TYPE_CUSTOM]),
  }

  PreparedForm.defaultProps = {
    type: null,
  }

  if (supportedTypes && supportedTypes.length > 1) {
    return (
      <>
        <Tabs value={activeTab} onChange={handleTabChange}>
          {supportedTypes.map(type => (
            <Tab
              key={`add-dialog-tab-${type}`}
              label={
                reference
                  ? translate(`orderLines.addDialog.tabs.${reference}.${type}`)
                  : translate(`orderLines.addDialog.tabs.general.${type}`)
              }
            />
          ))}
        </Tabs>
        {supportedTypes.map((type, idx) => (
          <TabPanel
            key={`add-dialog-tab-panel-${type}`}
            value={activeTab}
            index={idx}
          >
            <PreparedForm type={type} />
          </TabPanel>
        ))}
      </>
    )
  }
  return <PreparedForm type={head(supportedTypes)} />
}

AddDialogContent.propTypes = {
  handleClose: PropTypes.func,
  reference: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  dialogProps: PropTypes.shape({
    supportedTypes: PropTypes.arrayOf(
      PropTypes.oneOf([DIALOG_TYPE_EXISTING, DIALOG_TYPE_CUSTOM]),
    ),
    withITScope: PropTypes.bool,
  }),
}

AddDialogContent.defaultProps = {
  initialValues: {},
  handleClose: () => {},
  dialogProps: {
    supportedTypes: [DIALOG_TYPE_EXISTING],
    withITScope: false,
  },
}
