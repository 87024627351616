import green from '@material-ui/core/colors/green'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useApiClient } from 'Rest/fetchHydra'

const themeOverrides = {
  overrides: {
    MuiTableCell: {
      paddingNone: {
        padding: '6px 12px 6px 8px',
      },
    },
  },
}

const initialTheme = createMuiTheme({
  palette: {
    primary: { main: localStorage.getItem('main') || green[600] },
  },
  ...themeOverrides,
})

const defaultLangs = [
  { code: 'de', countryCode: 'de' },
  { code: 'en', countryCode: 'gb' },
  { code: 'it', countryCode: 'it' },
  { code: 'fr', countryCode: 'fr' },
  { code: 'nl', countryCode: 'nl' },
]

export const ResellerContext = React.createContext({
  theme: initialTheme,
  flags: {},
  setPrimaryColor: () => {},
  availableLanguages: [],
  refetch: () => {},
  resellerType: 'ALBIS',
})

export const ResellerProvider = ({ children }) => {
  const [theme, setTheme] = useState(initialTheme)
  const [flags, setFlags] = useState({})
  const [availableLanguages, setAvailableLanguages] = useState([])
  const [resellerType, setResellerType] = useState([])
  const [customizingValues, setCustomizingValues] = useState({})

  const setPrimaryColor = color =>
    setTheme(
      createMuiTheme({
        palette: { primary: { main: color } },
        ...themeOverrides,
      }),
    )

  const apiClient = useApiClient()

  const fetchData = useCallback(async () => {
    try {
      const res = await apiClient({ endpoint: '/me' })
      const color = get(res, 'data.corporateIdentity.firstColor') || green[300]
      const flags = get(res, 'data.flags')
      const langs = get(res, 'data.locales') || defaultLangs
      const resellerType = get(res, 'data.type')

      const alsoOfferSettings = get(res, 'data.alsoOfferSettings')

      setCustomizingValues(values => ({
        ...values,
        alsoOfferSettings: {
          defaultMargin: alsoOfferSettings.defaultMargin,
        },
      }))

      setFlags(flags)
      setAvailableLanguages(langs)
      setResellerType(resellerType)

      localStorage.setItem('main', color)
      setPrimaryColor(color)
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <ResellerContext.Provider
      value={{
        theme,
        setPrimaryColor,
        flags,
        availableLanguages,
        refetch: fetchData,
        resellerType,
        customizingValues,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ResellerContext.Provider>
  )
}

ResellerProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useResellerTheme = () => {
  const context = useContext(ResellerContext)
  if (context === undefined) {
    throw new Error('useResellerTheme must be used within a ResellerProvider')
  }
  return context.theme
}

export const useResellerContext = () => {
  const context = useContext(ResellerContext)
  if (context === undefined) {
    throw new Error('useResellerTheme must be used within a ResellerProvider')
  }
  return context
}
