export default {
  header: {
    actions: 'Aktionen',
  },
  body: {
    emptyDataSourceMessage: 'Keine Einträge',
    editRow: {
      deleteText: 'Möchten Sie diese Zeile wirklich löschen?',
    },
  },
  toolbar: {
    searchTooltip: 'Suchen',
    searchPlaceholder: 'Suchen...',
  },
}
