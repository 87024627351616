import React from 'react'

function reducer(state, action) {
  switch (action.type) {
    case 'cancel-confirmation':
      return {
        ...state,
        needsConfirmation: false,
        nextStep: undefined,
      }
    case 'set-step':
      if (action.payload.dirty && !action.payload.force) {
        return {
          ...state,
          needsConfirmation: true,
          nextStep: action.payload.step,
        }
      }
      return {
        ...state,
        activeStep: action.payload.step,
        needsConfirmation: false,
      }

    default:
      throw new Error()
  }
}

const useStepper = steps => {
  const [state, dispatch] = React.useReducer(reducer, {
    activeStep: 0,
    totalSteps: steps.length,
    needsConfirmation: false,
  })

  const { activeStep, needsConfirmation } = state

  const isLastStep = () => activeStep === state.totalSteps - 1

  const handleStep = ({ step, dirty }) => {
    dispatch({ type: 'set-step', payload: { step, dirty } })
  }

  const cancelConfirmation = () => {
    dispatch({ type: 'cancel-confirmation' })
  }

  function handleNext() {
    handleStep({
      step:
        state.totalSteps === state.activeStep
          ? state.activeStep
          : state.activeStep + 1,
    })
  }

  const handleBack = () => {
    handleStep({
      step: state.activeStep === 0 ? state.activeStep : state.activeStep - 1,
    })
  }

  const handleConfirmation = () => {
    dispatch({
      type: 'set-step',
      payload: { step: state.nextStep, force: true, dirty: false },
    })
  }

  const setStep = index => {
    handleStep({ step: index })
  }

  const currentStep = steps[activeStep]

  return {
    steps,
    currentStep,
    activeStep,
    handleNext,
    handleBack,
    isLastStep,
    setStep,
    needsConfirmation,
    cancelConfirmation,
    handleConfirmation,
  }
}

export default useStepper
