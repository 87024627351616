import { Typography } from '@material-ui/core'
import { isSameDay, startOfMonth } from 'date-fns'
import { useOrder } from 'OrderContext'
import React from 'react'
import {
  BooleanInput,
  FormDataConsumer,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin'
import { Field } from 'react-final-form'
import { validateRequired } from 'Resources/Aluxo/devices'
import DateInput from 'Components/Common/InputFields/DateInput'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import DateField from 'Components/Common/ViewFields/DateField'
import WorkflowField from 'Components/Common/ViewFields/WorkflowField'
import OrderCustomerAutoSuggest from 'Components/Common/InputFields/AutoSuggests/OrderCustomerAutoSuggest/OrderCustomerAutoSuggest'
import CustomerLink from 'Components/Common/ViewFields/CustomerLink'
import FinancePartnerSelect from 'Components/Aluxo/InputFields/FinancePartnerSelect'
import TotalRateField from 'Components/Common/ViewFields/Aluxo/TotalRateField'
import OrderInputField from 'Components/Common/InputFields/OrderInputField'
import OverdueField from './Fields/OverdueField'
import { Inputs } from './OrderEdit'

const OrderGeneralEdit = () => {
  const orderProps = useOrder()

  return (
    <Inputs {...orderProps}>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.suppressMails && (
            <Field
              name="suppressMails"
              {...rest}
              component={({ input: { value } }) =>
                value && (
                  <Typography variant="subheading" color="error">
                    E-Mail Versand deaktiviert
                  </Typography>
                )
              }
            />
          )
        }
      </FormDataConsumer>

      <WorkflowField source="workflow" />
      <OrderInputField
        component={BooleanInput}
        options={{ labelStyle: { color: '#000000' } }}
        source="priority"
        validate={validateRequired}
      />

      <OrderInputField
        component={BooleanInput}
        options={{ labelStyle: { color: '#000000' } }}
        source="directDelivery"
        validate={validateRequired}
      />
      <TextField source="storeView.name" />

      <CustomerLink
        source="customer_reference_id"
        linkText="billingAddress.company"
        label="resources.orders.fields.billingAddress.company"
      />

      <OrderCustomerAutoSuggest />

      <DateField source="created" format="DD.MM.YYYY" />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.overdue && (
            <OverdueField
              source="overdue"
              record={orderProps.record}
              {...rest}
            />
          )
        }
      </FormDataConsumer>
      <OrderInputField
        component={SelectInput}
        source="period"
        choices={[
          { id: 12, name: '12 Monate' },
          { id: 24, name: '24 Monate' },
          { id: 36, name: '36 Monate' },
          { id: 48, name: '48 Monate' },
          { id: 60, name: '60 Monate' },
        ]}
      />

      <CurrencyField source="totalVolume" />
      <CurrencyField source="creditVolume" />
      <CurrencyField source="itScopeVolume" />
      <CurrencyField source="requestedCreditLimit" />
      <CurrencyField source="approvedCreditLimit" />
      <OrderInputField
        component={DateInput}
        source="startDate"
        options={{
          shouldDisableDate: date => !isSameDay(date, startOfMonth(date)),
        }}
      />

      <TotalRateField />
      <OrderInputField component={FinancePartnerSelect} />
      <OrderInputField
        component={TextInput}
        source="reference"
        validate={validateRequired}
      />
    </Inputs>
  )
}

export default OrderGeneralEdit
