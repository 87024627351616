import { Card, CardContent } from '@material-ui/core'
import { useOrderEditStyles } from 'Components/Common/Orders/OrderEditGridFormView'
import OrderLinesContainer from 'Components/Aluxo/OrderLines/OrderLinesContainer'
import TranslatedTitle from 'Components/Common/TranslatedTitle'
import { useOrder } from 'OrderContext'
import React from 'react'
import { CardContentInner, SimpleShowLayout } from 'react-admin'
import ConnectedOrderLineActions from './Actions/OfferLineActions'

const OfferLinesEdit = ({ mode, setMode }) => {
  const classes = useOrderEditStyles()
  const { record, resource, basePath } = useOrder()

  const handleChangeMode = () => {
    setMode(mode === 'general' ? 'financing' : 'general')
  }

  return (
    <Card className={classes.fullWidthCard}>
      <CardContent className={classes.cardContent}>
        <CardContentInner>
          <ConnectedOrderLineActions
            data={record}
            mode={mode}
            handleChangeMode={handleChangeMode}
          />
        </CardContentInner>
        {record && (
          <SimpleShowLayout
            resource={resource}
            basePath={basePath}
            record={record}
          >
            <TranslatedTitle translateKey="resources.orders.tabs.orderLines" />
            <OrderLinesContainer mode={mode} />
          </SimpleShowLayout>
        )}
      </CardContent>
    </Card>
  )
}

export default OfferLinesEdit
