import { Divider, LinearProgress } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { get, mapValues, orderBy, slice } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useOrder } from 'OrderContext'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Pagination,
  useDataProvider,
  useLoading,
  useTranslate,
} from 'react-admin'

const references = [
  'reseller_email_events',
  'reseller_transition_events',
  'reseller_order_flag_events',
]

const useStyles = makeStyles({
  table: {
    tableLayout: 'auto',
  },
  tbody: {
    height: 'inherit',
  },
  header: {
    th: {
      padding: 0,
    },
    'th:first-child': {
      padding: '0 0 0 12px',
    },
  },
  cell: {
    td: {
      padding: '0 12px',
      whiteSpace: 'normal',
    },
    'td:first-child': {
      padding: '0 12px 0 16px',
      whiteSpace: 'normal',
    },
  },
  sortButton: {
    minWidth: 40,
  },
  nonSortableLabel: {
    position: 'relative',
    paddingLeft: 16,
    paddingRight: 16,
    verticalAlign: 'middle',
    letterSpacing: 0,
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: 14,
  },
})

const OrderEvents = () => {
  const [perPage, setPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const { record } = useOrder()
  const dataProvider = useDataProvider()
  const [data, setData] = useState([])
  const loading = useLoading()
  const t = useTranslate()
  const classes = useStyles()

  useEffect(() => {
    Promise.all(
      references.map(reference =>
        dataProvider
          .getManyReference(reference, {
            target: 'order',
            id: record.id,
            pagination: false,
            sort: false,
            filter: false,
          })
          .then(res => ({ ...res, reference })),
      ),
    ).then(allResponses => {
      const data = []
      for (const response of allResponses) {
        data.push(
          ...response.data.map(entry => ({
            ...entry,
            text: createText(response.reference, entry),
          })),
        )
      }
      setData(data)
    })
  }, [record.id])

  if (loading) {
    return <LinearProgress style={{ marginTop: '1em' }} />
  }
  const sortedData = orderBy(data, 'createdAt', 'desc')
  const start = (page - 1) * perPage
  const dataSlice = slice(sortedData, start, page * perPage)
  return (
    <div>
      <Table stripedRows fixedHeader={false} className={classes.table}>
        <TableHead displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableCell>
              <span className={classes.nonSortableLabel}>
                {t('events.fields.createdAt')}
              </span>
            </TableCell>
            <TableCell>
              <span className={classes.nonSortableLabel}>
                {t('events.fields.user.email')}
              </span>
            </TableCell>
            <TableCell>
              <span className={classes.nonSortableLabel}>
                {t('events.fields.text')}
              </span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody displayRowCheckbox={false}>
          {dataSlice.map(({ createdAt, user, text }, index) => (
            <TableRow key={index}>
              <TableCell>
                {moment(createdAt).format('DD.MM.YYYY HH:mm')}
              </TableCell>
              <TableCell>{get(user, 'email')}</TableCell>
              <TableCell>{text}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Divider />
      <Pagination
        total={data.length}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </div>
  )
}

const Translated = ({ text, args }) => {
  const translate = useTranslate()

  return translate(
    text,
    mapValues(args, value => translate(value)),
  )
}

Translated.propTypes = {
  text: PropTypes.string.isRequired,
  args: PropTypes.object.isRequired,
}

const createText = (reference, entry) => {
  switch (reference) {
    case 'reseller_email_events':
      return (
        <Translated
          text="resources.reseller_email_events.text.emailSent"
          args={{ type: `orders.markings.${entry.type}` }}
        />
      )
    case 'reseller_transition_events':
      return (
        <Translated
          text="resources.reseller_transition_events.text"
          args={{
            fromType: `orders.markings.${entry.from}`,
            toType: `orders.markings.${entry.to}`,
          }}
        />
      )
    case 'reseller_order_flag_events':
      if (get(entry, 'flagEntity.data.albisApplicationNumber')) {
        return (
          <Translated
            text="resources.reseller_flag_events.text.albis"
            args={{
              flag: `orders.flags.${entry.flag}`,
              type: `orders.flags.eventType.${entry.type}`,
              number: entry.flagEntity.data.albisApplicationNumber,
            }}
          />
        )
      }
      return (
        <Translated
          text="resources.reseller_flag_events.text.generic"
          args={{
            flag: `orders.flags.${entry.flag}`,
            type: `orders.flags.eventType.${entry.type}`,
          }}
        />
      )

    default:
      return null
  }
}

export default OrderEvents
