import { useTheme } from '@material-ui/core/styles'
import { GetApp } from '@material-ui/icons'
import Button from 'Components/Common/Buttons/Button'
import mime from 'mime-types'
import { useOrder } from 'OrderContext'
import React from 'react'
import { useMutation, useNotify, useTranslate } from 'react-admin'

const DownloadAllAttachmentsAsArchiveButton = () => {
  const {
    record: { id, orderNumber, workflow },
  } = useOrder()
  const t = useTranslate()
  const showNotification = useNotify()
  const {
    palette: {
      primary: { main },
    },
  } = useTheme()

  const fileName =
    workflow === 'offer' ? 'Angebote' : `Dokumente_${orderNumber}`

  const [mutate, { loading }] = useMutation(
    {
      type: 'downloadAllAttachments',
      resource: 'orders',
      payload: { id },
    },
    {
      onSuccess: async ({ data: response }) => {
        const contentType = response.headers.get('Content-Type')
        const blob = await response.blob()
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        document.body.appendChild(a)
        const extension = mime.extension(contentType)
        a.style = 'display: none'
        a.href = blobUrl
        a.download = `${fileName}.${extension}`
        a.click()
        URL.revokeObjectURL(blobUrl)
      },
      onFailure: error => {
        showNotification(`Error: ${error}`, 'warning') // todo errormessage
      },
    },
  )

  const handleClick = () => {
    mutate()
  }

  return (
    <Button
      onClick={handleClick}
      label={t('actions.downloadAllAsArchive')}
      loading={loading}
    >
      <GetApp color={main} />
    </Button>
  )
}

export default DownloadAllAttachmentsAsArchiveButton
