import { TableCell, TableRow } from '@material-ui/core'
import currencyText from 'Lib/currencyText'
import React from 'react'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'

const AlsoOrderLine = ({ line }) => {
  const t = useTranslate()

  const {
    pos,
    workplaceConfigurationFields,
    subscriptionIds,
    monthlyRate,
    quantity,
    term,
  } = line

  return (
    <>
      <TableRow>
        <TableCell>{pos}</TableCell>
        <TableCell>
          {
            workplaceConfigurationFields.find(
              f => f.field === 'WorkplaceUserDevice',
            ).value
          }
        </TableCell>
        <TableCell>
          {t('alsoOrderLines.values.months', { months: term })}
        </TableCell>
        <TableCell style={{ textAlign: 'right' }}>{quantity}</TableCell>
        <TableCell style={{ textAlign: 'right' }}>
          {currencyText(monthlyRate)}
        </TableCell>
        <TableCell style={{ textAlign: 'right' }}>
          {currencyText(monthlyRate * quantity)}
        </TableCell>
        <TableCell>{subscriptionIds.length > 0 ? 'Ja' : 'Nein'}</TableCell>
        <TableCell />
      </TableRow>
      {workplaceConfigurationFields.map(workplaceConfigurationField => {
        const { field, value, id } = workplaceConfigurationField

        return (
          <TableRow key={id}>
            <TableCell />
            <TableCell>{field}</TableCell>
            <TableCell colSpan="5">{value}</TableCell>
          </TableRow>
        )
      })}
    </>
  )
}

AlsoOrderLine.propTypes = {
  line: PropTypes.shape({
    pos: PropTypes.number.isRequired,
    workplaceConfigurationFields: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
    subscriptionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    monthlyRate: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    term: PropTypes.number.isRequired,
  }).isRequired,
}

export default AlsoOrderLine
