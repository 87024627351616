import BackToReferenceButton from 'Components/Common/ActionButtons/BackToReferenceButton'
import EditToolbar from 'Components/Common/EditToolbar'
import TinyMCEInput from 'Components/Common/InputFields/TinyMCEInput'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import { getLinkFromIri } from 'Lib/helper'
import { get } from 'lodash'
import queryString from 'query-string'
import React from 'react'
import { Create, Edit, SimpleForm, TextInput, TopToolbar } from 'react-admin'
import { validateRequired } from 'Resources/Aluxo/devices'

const Title = ({ record }) => get(record, 'title')

const EditActions = ({ data }) => (
  <TopToolbar>
    <BackToReferenceButton data={data} />
  </TopToolbar>
)


const redirect = (basePath, id, data) => getLinkFromIri(data.reference)

export const InformationTabEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<Title />}
    {...props}
    aside={<LanguageSwitcherAside />}
  >
    <SimpleForm toolbar={<EditToolbar />} redirect={redirect}>
      <TextInput source="title" validate={validateRequired} />
      <TinyMCEInput source="content" validate={validateRequired} />
    </SimpleForm>
  </Edit>
)

const CreateActions = props => (
  <TopToolbar>
    <BackToReferenceButton
      reference={queryString.parse(props.location.search).reference}
    />
  </TopToolbar>
)

const createDefaultValues = props => ({
  reference: {
    '@id': queryString.parse(props.location.search).reference,
  },
})

export const InformationTabCreate = props => (
  <Create actions={<CreateActions {...props} />} {...props}>
    <SimpleForm
      defaultValue={createDefaultValues(props)}
      redirect={getLinkFromIri(
        queryString.parse(props.location.search).reference,
      )}
    >
      <TextInput source="title" validate={validateRequired} />
      <TinyMCEInput source="content" validate={validateRequired} />
    </SimpleForm>
  </Create>
)
