import AddDialog from 'Components/Aluxo/OrderLines/Lines/AddDialog'
import { isReseller } from 'Lib/helper'
import React from 'react'
import { usePermissions } from 'react-admin'
import {
  DIALOG_TYPE_EXISTING,
  DIALOG_TYPE_CUSTOM,
} from 'Components/Aluxo/OrderLines/Lines/AddDialogForm'
import CheckITScopeStockButton from 'Components/Aluxo/ActionButtons/CheckITScopeStockButton'
import RefreshPricesButton from 'Components/Aluxo/ActionButtons/RefreshPricesButton'
import AddPromoBundleDialog from 'Components/Aluxo/OrderLines/Lines/PromoBundleLine/AddPromoBundleDialog'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import { FormControlLabel, Switch } from '@material-ui/core'

const OfferLineActions = ({ mode, handleChangeMode }) => {
  const { permissions = [] } = usePermissions()
  if (!isReseller(permissions)) {
    return null
  }

  return (
    <RightAlignedCardActions>
      <FormControlLabel
        control={
          <Switch
            checked={mode === 'financing'}
            onChange={handleChangeMode}
            name="Finanzierungsansicht"
          />
        }
        label="Finanzierungsansicht"
      />
      <RefreshPricesButton />
      <CheckITScopeStockButton />
      <AddDialog
        reference="product_order_lines"
        dialogProps={{
          supportedTypes: [DIALOG_TYPE_EXISTING, DIALOG_TYPE_CUSTOM],
          withITScope: true,
        }}
      />
      <AddDialog
        reference="service_order_lines"
        dialogProps={{
          supportedTypes: [DIALOG_TYPE_CUSTOM],
        }}
      />
      <AddPromoBundleDialog reference="promo_bundle_order_lines" />
      <AddDialog
        reference="device_order_lines"
        dialogProps={{
          supportedTypes: [DIALOG_TYPE_EXISTING, DIALOG_TYPE_CUSTOM],
          withITScope: true,
        }}
      />
    </RightAlignedCardActions>
  )
}

export default OfferLineActions
