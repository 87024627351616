import { Button, Grid } from '@material-ui/core'
import { CloudUpload } from '@material-ui/icons'
import { required } from 'Components/Common/Orders/AttachmentInput'
import { head } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useRef } from 'react'
import {
  Labeled,
  useDataProvider,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'
import Dropzone from 'react-dropzone'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components/macro'

const InputContainerForm = styled.form`
  display: flex;
  align-items: flex-end;
  & > * {
    margin: 0 1em;
  }
`

const StyledDropzone = styled(Dropzone)`
  background-color: #f0f0f0;
  border: dashed;
  border-color: #c8c8c8;
  cursor: pointer;
  height: 144px;
  width: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`

const DropZoneContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 144px;
  width: 144px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`

const ImageDropZone = ({ input }) => {
  const imageRef = useRef(null)
  const handleDrop = useCallback(acceptedFiles => {
    const file = head(acceptedFiles)

    const reader = new FileReader()

    reader.onload = () => {
      const dataURL = reader.result
      imageRef.current.src = dataURL
    }
    reader.readAsDataURL(file)

    input.onChange(file)
  }, [])

  return (
    <div>
      <StyledDropzone onDrop={handleDrop} multiple={false}>
        {input.value ? (
          <DropZoneContent>
            <img ref={imageRef} alt={input.value.name} />
          </DropZoneContent>
        ) : (
          <CloudUpload />
        )}
      </StyledDropzone>
    </div>
  )
}

ImageDropZone.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
}

const ImageUploadForm = ({ handleSubmit, form, invalid, submitting }) => {
  const t = useTranslate()
  return (
    <InputContainerForm onSubmit={handleSubmit}>
      <Field name="file" validate={required} component={ImageDropZone} />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Button
          style={{ marginBottom: '1em' }}
          disabled={invalid}
          color="primary"
          onClick={handleSubmit}
        >
          {submitting ? t('state.uploading') : t('actions.upload')}
        </Button>

        <Button disabled={submitting} onClick={form.reset}>
          {t('actions.cancel')}
        </Button>
      </div>
    </InputContainerForm>
  )
}

ImageUploadForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
}

const ImageUploadInput = ({
  record,
  resource,
  resourceName,
  referenceName,
  params,
  endpoint,
  withImage,
  source,
  label,
}) => {
  const showNotification = useNotify()
  const refreshView = useRefresh()
  const dataProvider = useDataProvider()

  const handleUpload = async values => {
    const data = {
      [referenceName]: record.id,
      ...values,
    }

    try {
      await dataProvider.createMultipart(endpoint || resourceName, {
        data,
        ...params,
      })
      showNotification('notifications.uploadImage.success')
      refreshView()
    } catch (e) {
      showNotification('notifications.uploadImage.fail', 'warning')
    }
  }

  if (label) {
    return (
      <Labeled label={label} source={source} resource={resource}>
        <Grid container spacing={3}>
          {withImage && source && (
            <Grid item>
              <ImageContainer>
                <img src={`${record[source]}`} alt="" />
              </ImageContainer>
            </Grid>
          )}
          <Grid item>
            <Form onSubmit={handleUpload} component={ImageUploadForm} />
          </Grid>
        </Grid>
      </Labeled>
    )
  }

  if (source && resource) {
    return (
      <Labeled source={source} resource={resource}>
        <Grid container spacing={3}>
          {withImage && source && (
            <Grid item>
              <ImageContainer>
                <img src={`${record[source]}`} alt="" />
              </ImageContainer>
            </Grid>
          )}
          <Grid item>
            <Form onSubmit={handleUpload} component={ImageUploadForm} />
          </Grid>
        </Grid>
      </Labeled>
    )
  }

  return (
    <Grid container spacing={3}>
      {withImage && source && (
        <Grid item>
          <ImageContainer>
            <img src={`${record[source]}`} alt="" />
          </ImageContainer>
        </Grid>
      )}
      <Grid item>
        <Form onSubmit={handleUpload} component={ImageUploadForm} />
      </Grid>
    </Grid>
  )
}

ImageUploadInput.propTypes = {
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  referenceName: PropTypes.string.isRequired,
  params: PropTypes.object,
  endpoint: PropTypes.string,
  withImage: PropTypes.bool,
  source: PropTypes.string,
  label: PropTypes.string,
}

ImageUploadInput.defaultProps = {
  params: {},
  endpoint: null,
  withImage: false,
  source: null,
  label: null,
}

export default ImageUploadInput
