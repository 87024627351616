import { dateInputOptions } from 'Components/Common/Orders/OrderFilter'
import ReferenceInput from 'Components/Common/ReferenceInput'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import PureDateField from 'Components/Common/ViewFields/DateField'
import React from 'react'
import {
  BooleanInput,
  Create,
  Datagrid,
  DateInput,
  EditButton,
  Filter,
  List,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useGetList,
  Loading,
  Toolbar,
} from 'react-admin'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'
import { validateRequired } from './devices'

export { default as OfferEdit } from 'Components/Aluxo/Offers/OfferEdit'

const OfferFilter = props => (
  <Filter {...props}>
    <TextInput source="offerNumber" />
    <TextInput source="billingAddress.company" />
    <DateInput
      source="created[before]"
      label="filters.orders.createdBefore"
      options={{ ...dateInputOptions }}
    />
    <DateInput
      source="created[after]"
      label="filters.orders.createdAfter"
      options={{ ...dateInputOptions }}
    />
    <BooleanInput
      options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
      source="priority"
    />
    <BooleanInput
      options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
      source="overdue"
    />
  </Filter>
)

export const OfferList = props => (
  <List
    {...props}
    filters={<OfferFilter />}
    filter={{ offer: true }}
    pagination={<CustomPagination />}
    perPage={50}
    sort={{ field: 'created', order: 'DESC' }}
  >
    <Datagrid>
      <TextField source="offerNumbers" />
      <PureDateField source="created" format="DD.MM.YYYY" />
      <TextField source="billingAddress.company" />
      <TextField source="storeView.name" />
      <TextField source="marking" />

      <CurrencyField
        label="resources.orders.fields.totalVolume"
        source="totalVolume"
      />
      <EditButton />
    </Datagrid>
  </List>
)

export const OfferCreate = props => {
  const { data, loading } = useGetList('store_views', false, false, false)

  const defaultStoreView = Object.values(data).find(s => s.default)

  if (loading) {
    return <Loading />
  }

  return (
    <Create {...props}>
      <SimpleForm
        toolbar={<Toolbar alwaysEnableSaveButton />}
        initialValues={{
          storeView_reference_id: defaultStoreView ? defaultStoreView.id : null,
        }}
      >
        <ReferenceInput
          source="storeView"
          reference="store_views"
          validate={validateRequired}
        >
          <SelectInput optionText={choice => choice.name} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}
