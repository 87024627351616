import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'
import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { ResellerContext } from 'ResellerContext'
import { useQuery } from 'react-query'
import { useApiClient } from 'Rest/fetchHydra'
import { orderBy } from 'lodash'

const useStyles = makeStyles(theme => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))

const CNetMetaDataList = props => {
  const classes = useStyles()

  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const apiClient = useApiClient()

  const cnetMetaDataQuery = useQuery(
    ['cnet_meta_data', props.record.puid],
    () =>
      apiClient({
        endpoint: `/cnet_get_metadata_for_puid_request`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: {
          puid: props.record.puid,
        },
      })
        .then(res => res.data)
        .then(data =>
          orderBy(data, ['productAttributeValue', 'group.parent.name']),
        ),
    {
      initialData: [],
    },
  )

  const loading = cnetMetaDataQuery.isFetching

  const { data } = cnetMetaDataQuery

  const { flags } = useContext(ResellerContext)
  if (!flags.cNetSpecs) {
    return null
  }

  return (
    <div>
      <Box display="flex" mt={2}>
        <Typography variant="h5">Metadaten</Typography>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {loading && <LinearProgress />}
        <List>
          {data.map(metaData => (
            <ListItem key={metaData.id}>
              <ListItemText
                primary={[
                  metaData.group.parent?.name,
                  metaData.group?.name,
                  metaData.value,
                ]
                  .filter(v => v)
                  .join(' > ')}
                secondary={
                  metaData.productAttributeValue &&
                  metaData.productAttributeValue.value
                }
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  )
}

CNetMetaDataList.propTypes = {}

export default CNetMetaDataList
