import { Card, CardContent } from '@material-ui/core'
import { useOrderEditStyles } from 'Components/Common/Orders/OrderEditGridFormView'
import OrderLineActions from 'Components/Aluxo/OrderLines/OrderLineActions'
import TranslatedTitle from 'Components/Common/TranslatedTitle'
import { useOrder } from 'OrderContext'
import React from 'react'
import { CardContentInner, SimpleShowLayout } from 'react-admin'
import OrderServices from './OrderServices'

const OrderLinesEdit = () => {
  const classes = useOrderEditStyles()
  const { record, resource, basePath } = useOrder()

  return (
    <Card className={classes.fullWidthCard}>
      <CardContent className={classes.cardContent}>
        <CardContentInner>
          <OrderLineActions />
        </CardContentInner>
        {record && (
          <SimpleShowLayout
            resource={resource}
            basePath={basePath}
            record={record}
          >
            <TranslatedTitle translateKey="alsoWorkplaceServices.name" />
            <OrderServices />
          </SimpleShowLayout>
        )}
      </CardContent>
    </Card>
  )
}

export default OrderLinesEdit
