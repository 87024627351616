import PropTypes from 'prop-types'
import React from 'react'
import { TextInput } from 'react-admin'

const NoteInput = ({ label, noteKey, record }) => {
  const idx = record.notes.findIndex(n => n.noteKey === noteKey)
  return (
    <TextInput
      multiline
      source={`notes.${idx}.content`}
      label={label}
      style={{ width: '256px' }}
    />
  )
}

NoteInput.propTypes = {
  label: PropTypes.string.isRequired,
  noteKey: PropTypes.string.isRequired,
  record: PropTypes.shape({
    notes: PropTypes.arrayOf(
      PropTypes.shape({
        noteKey: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
}

export default NoteInput
