import React from 'react'
import PropTypes from 'prop-types'
import { get, isNumber } from 'lodash'
import currencyText from 'Lib/currencyText'

const CurrencyField = ({ source, record, elStyle, defaultValue }) => {
  const value = get(record, source)
  return (
    <span style={elStyle}>
      {currencyText(isNumber(value) ? value : defaultValue)}
    </span>
  )
}

CurrencyField.propTypes = {
  addLabel: PropTypes.bool,
  elStyle: PropTypes.object,
  record: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
  defaultValue: PropTypes.number,
}

CurrencyField.defaultProps = {
  addLabel: true,
  elStyle: {},
  defaultValue: 0,
}

export default CurrencyField
