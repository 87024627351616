import Typography from '@material-ui/core/Typography'
import React, { useContext } from 'react'
import { ResellerContext } from 'ResellerContext'
import LanguageSwitcher from './LanguageSwitcher'

const LanguageSwitcherAside = () => {
  const { availableLanguages } = useContext(ResellerContext)

  if (availableLanguages.length <= 1) {
    return null
  }

  return (
    <div style={{ width: 100, margin: '1em' }}>
      <Typography>Sprache</Typography>
      <LanguageSwitcher availableLanguages={availableLanguages} />
    </div>
  )
}

export default LanguageSwitcherAside
