import { cacheExchange, createClient, dedupExchange, fetchExchange } from 'urql'

const url = `${process.env.REACT_APP_GQL_URL}`

const dllUrqlClient = createClient({
  url: `${url}/graphql`,
  exchanges: [dedupExchange, cacheExchange, fetchExchange],
  fetchOptions: () => {
    const token = localStorage.getItem('token')
    return {
      headers: { authorization: token ? `Bearer ${token}` : '' },
    }
  },
})

export default dllUrqlClient
