export default {
  name: 'E-Mail Historie',
  fields: {
    createdAt: 'Datum / Uhrzeit',
    type: 'Typ',
    user: {
      email: 'Benutzer',
    },
    text: 'Text',
  },
}
