import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ContentCreate from '@material-ui/icons/Create'
import SerialNumberInput from 'Components/Aluxo/InputFields/SerialNumberInput'
import Button from 'Components/Common/Buttons/Button'
import NumberWithUnitInput from 'Components/Common/InputFields/NumberWithUnitInput'

import NetPriceCalculator from 'Components/Common/InputFields/NetPriceCalculatorInput'
import {
  OrderLineAbsoluteDiscountInput,
  OrderLineRelativeDiscountInput,
} from 'Components/Common/InputFields/OrderLineDiscountInput'
import React from 'react'
import PropTypes from 'prop-types'
import {
  Error,
  FormInput,
  Loading,
  NumberInput,
  required,
  useMutation,
  useNotify,
  TextInput,
  useQuery,
  useRefresh,
  useTranslate,
  BooleanInput,
} from 'react-admin'
import { Form } from 'react-final-form'
import { useOrder } from 'OrderContext'
import CreditGroupSelect from 'Components/Aluxo/InputFields/CreditGroupSelect'
import { useQueryClient } from 'react-query'
import DllAssetTypeSelect from 'Components/Aluxo/InputFields/DLLAssetTypeSelect'

const defaultFields = [
  {
    component: TextInput,
    source: 'name',
    validate: required(),
    fullWidth: true,
  },
  { component: TextInput, source: 'sku', validate: required() },
  { component: TextInput, source: 'manufacturer', validate: required() },
  { component: NumberInput, source: 'quantity', validate: required() },
  { component: NumberWithUnitInput, source: 'price', unit: '€' },
  { component: NumberWithUnitInput, source: 'margin', unit: '%' },
  { component: NumberWithUnitInput, source: 'defaultMargin', unit: '%' },
  { component: NetPriceCalculator },
  { component: OrderLineAbsoluteDiscountInput, source: 'discount' },
  { component: OrderLineRelativeDiscountInput, source: 'discount' },
  { component: TextInput, source: 'referenceString' },
]

const inputFields = {
  device_order_lines: [
    ...defaultFields,
    { component: SerialNumberInput, source: 'serialNumbers', addLabel: true },
    { component: CreditGroupSelect, source: 'creditGroup' },
    { component: DllAssetTypeSelect, source: 'dllAssetType' },
    { component: BooleanInput, source: 'hasSerialNumbers' },
  ],
  product_order_lines: [
    ...defaultFields,
    { component: SerialNumberInput, source: 'serialNumbers', addLabel: true },
    { component: CreditGroupSelect, source: 'creditGroup' },
    { component: DllAssetTypeSelect, source: 'dllAssetType' },
    { component: BooleanInput, source: 'hasSerialNumbers' },
    { component: BooleanInput, source: 'includeInProtectionPlan' },
  ],
  protection_plan_order_lines: [
    { component: NumberWithUnitInput, source: 'margin', unit: '%' },
    { component: NumberWithUnitInput, source: 'defaultMargin', unit: '%' },
    { component: NetPriceCalculator },
    { component: OrderLineAbsoluteDiscountInput, source: 'discount' },
    { component: OrderLineRelativeDiscountInput, source: 'discount' },
    { component: TextInput, source: 'referenceString' },
    { component: DllAssetTypeSelect, source: 'dllAssetType' },
  ],
  generic_order_lines: [
    ...defaultFields,
    { component: TextInput, source: 'unit' },

    { component: CreditGroupSelect, source: 'creditGroup' },
    { component: DllAssetTypeSelect, source: 'dllAssetType' },
    { component: BooleanInput, source: 'includeInProtectionPlan' },
  ],
  promo_bundle_order_lines: [
    { component: NumberInput, source: 'quantity', validate: required() },
    {
      component: NumberWithUnitInput,
      source: 'price',
      unit: '€',
      disabled: line => line.type === 'internal',
    },
    { component: NumberWithUnitInput, source: 'margin', unit: '%' },
    { component: NumberWithUnitInput, source: 'defaultMargin', unit: '%' },
    { component: NetPriceCalculator },
    { component: OrderLineAbsoluteDiscountInput, source: 'discount' },
    { component: OrderLineRelativeDiscountInput, source: 'discount' },
    { component: TextInput, source: 'referenceString' },
  ],
  default: [
    ...defaultFields,
    { component: DllAssetTypeSelect, source: 'dllAssetType' },
  ],
}

const Inputs = ({ reference, record, inputsForReference }) => {
  const inputs = inputsForReference.map(
    ({ disabled, component: Component, ...props }) => (
      <Component {...props} disabled={disabled && disabled(record)} />
    ),
  )

  return inputs.map(input => (
    <FormInput
      basePath={reference}
      input={input}
      record={record}
      resource={reference}
      variant="filled"
      margin="dense"
    />
  ))
}

const EditDialogContent = ({
  handleClose,
  id,
  reference,
  inputsForReference,
}) => {
  const showNotification = useNotify()
  const refreshView = useRefresh()
  const queryClient = useQueryClient()
  const { data: record, loading: queryLoading, error } = useQuery({
    type: 'getOne',
    resource: reference,
    payload: { id },
  })

  const [mutate, { loading }] = useMutation()

  const handleSave = async values => {
    mutate(
      {
        type: 'update',
        resource: reference,
        payload: { id, data: values },
      },

      {
        onSuccess: () => {
          showNotification(`notifications.edit_${reference}.success`)
          handleClose()
          refreshView()
          queryClient.invalidateQueries(reference)
        },
        onFailure: () => {
          showNotification(`notifications.edit_${reference}.fail`, 'warning')
        },
      },
    )
  }

  if (queryLoading)
    return (
      <DialogContent>
        <Loading />
      </DialogContent>
    )
  if (error) return <Error />
  if (!record) return null

  return (
    <Form initialValues={record} onSubmit={handleSave}>
      {formProps => (
        <form onSubmit={formProps.handleSubmit}>
          <DialogContent>
            <DialogContentText>{record.name}</DialogContentText>
            <Inputs
              record={record}
              reference={reference}
              inputsForReference={inputsForReference}
            />
          </DialogContent>
          <DialogActions>
            <Button
              label="Abbrechen"
              onClick={handleClose}
              color="secondary"
              disabled={loading}
            />
            <Button
              label="Speichern"
              type="submit"
              color="primary"
              disabled={loading}
              loading={loading}
            />
          </DialogActions>
        </form>
      )}
    </Form>
  )
}

EditDialogContent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
}

export default function EditDialog({ reference, id }) {
  const { editableLineFields } = useOrder()
  const [open, setOpen] = React.useState(false)
  const t = useTranslate()

  const inputsForReference = (
    inputFields[reference] || inputFields.default
  ).filter(field => {
    if (!editableLineFields) {
      return false
    }
    if (editableLineFields.includes('*')) {
      return true
    }
    return editableLineFields.includes(field.source)
  })

  const enabled = inputsForReference.length > 0

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        label={t('ra.action.edit')}
        color="primary"
        onClick={handleClickOpen}
        disabled={!enabled}
      >
        <ContentCreate />
      </Button>

      <Dialog
        className="edit-dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Bearbeiten</DialogTitle>
        <EditDialogContent
          handleClose={handleClose}
          reference={reference}
          inputsForReference={inputsForReference}
          id={id}
        />
      </Dialog>
    </>
  )
}

EditDialog.propTypes = {
  reference: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}
