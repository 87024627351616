import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
  CREATE,
  useLoading,
  useNotify,
  useTranslate,
  useUpdateLoading,
} from 'react-admin'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { hideDialog } from 'Ducks/dialog'
import useMutationPromise from 'Hooks/useMutationPromise'

const restClient = null

const enhance = compose(
  connect(({ dialog }) => ({ ...dialog }), {
    hideDialog,
    // requestProcessOrder,
  }),
)

const CancelDialog = ({ hideDialog, record, resource, type }) => {
  const t = useTranslate()
  const showNotification = useNotify()

  const [comment, setComment] = useState('')

  const [mutate, { loading }] = useMutationPromise()

  if (!(resource === 'orders' && type === 'cancel')) {
    return null
  }

  const submit = async () => {
    try {
      await mutate({
        type: 'create',
        resource: 'order_comments',
        payload: {
          order: record['@id'],
          content: comment,
        },
      })

      await mutate({
        type: 'processOrder',
        resource,
        payload: {
          id: record.id,
          data: { action: 'cancel' },
        },
      })

      hideDialog()
    } catch (error) {
      console.log(error)
      showNotification(error.message, 'warning')
    }
  }

  return (
    <Dialog modal open>
      <DialogTitle>{t('orders.transitions.cancel')}</DialogTitle>
      <DialogContent>
        <TextField
          label={t('resources.order_comments.name')}
          multiline
          fullWidth
          value={comment}
          onChange={({ target: { value } }) => setComment(value)}
          rows="3"
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={hideDialog} disabled={loading}>
          {t('actions.cancel')}
        </Button>
        <Button
          color="primary"
          onClick={submit}
          disabled={loading || comment === ''}
        >
          {t('actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CancelDialog.propTypes = {
  comment: PropTypes.string.isRequired,
  setComment: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
}

export default enhance(CancelDialog)
