import React, { useEffect, useState } from 'react'
import { useTranslate, useInput } from 'react-admin'
import { get, set } from 'lodash'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import de from 'numbro/languages/de-DE'
import { makeStyles } from '@material-ui/core/styles'
import numbro from 'numbro'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginTop: theme.spacing(1),
  },
  textField: {
    width: 256,
  },
}))

numbro.registerLanguage(de)
numbro.setLanguage('de-DE')

const transformValue = value =>
  numbro(value).format({
    thousandSeparated: true,
    mantissa: 2,
  })

const AluxoDiscountInput = ({ label, discountType, unit, defaultValue }) => {
  const classes = useStyles()
  const t = useTranslate()
  const [value, setValue] = useState(defaultValue)
  const [editing, setEditing] = useState(false)
  const [discountActive, setDiscountActive] = useState(false)

  const {
    input: { value: aluxoDiscounts, onChange },
  } = useInput({ name: 'aluxoDiscounts' })

  const getAluxoDiscountObject = discount => ({
    discountType,
    margin: discount,
  })

  useEffect(() => {
    if (aluxoDiscounts) {
      const discount = aluxoDiscounts.find(v => v.discountType === discountType)
      const margin = get(discount, 'margin')

      if (typeof margin === `number` && margin >= 0) {
        setValue(transformValue(margin))
        setDiscountActive(true)
      } else {
        setValue(transformValue(defaultValue))
      }
    }
  }, [aluxoDiscounts, discountType, defaultValue])

  const getDiscountIndex = () =>
    aluxoDiscounts.findIndex(v => v.discountType === discountType)

  const pushDiscount = () => {
    const unformattedValue = numbro(value).value()
    const discountsCopy = [...aluxoDiscounts]
    const idx = getDiscountIndex()
    if (idx > -1) {
      set(discountsCopy[idx], 'margin', unformattedValue)
    } else {
      discountsCopy.push(getAluxoDiscountObject(unformattedValue))
    }
    onChange(discountsCopy)
  }

  const handleSwitch = ({ target: { checked } }) => {
    setDiscountActive(checked)
    if (!checked) {
      const discountsCopy = [...aluxoDiscounts]
      const filtered = discountsCopy.filter(
        discount => discount.discountType !== discountType,
      )
      onChange(filtered)
    } else {
      pushDiscount()
    }
  }

  const handleChange = ({ target: { value } }) => {
    setValue(value)
  }

  const handleBlur = () => {
    const unformattedValue = numbro(value).value()
    setValue(transformValue(unformattedValue))
    setEditing(false)
    pushDiscount()
  }

  const handleFocus = () => {
    setEditing(true)
    setValue(transformValue(value))
  }

  const handleKeyPress = e => {
    if (/,/.test(e.key) && /,/.test(value)) {
      e.preventDefault()
    }
    if (!/[\d,]/.test(e.key)) {
      e.preventDefault()
    }
  }

  const displayValue = editing ? value : `${value} ${unit}`
  const switchLabel = discountActive
    ? ''
    : `${t(label)} ${t('resources.discounts.aluxo.activate')}`
  return (
    <div className={classes.container}>
      <FormControlLabel
        control={
          <Switch
            checked={discountActive}
            onChange={handleSwitch}
            color="primary"
          />
        }
        label={switchLabel}
      />

      {discountActive && (
        <TextField
          className={classes.textField}
          label={t(label)}
          type="tel"
          value={displayValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          variant="filled"
        />
      )}
    </div>
  )
}

AluxoDiscountInput.propTypes = {
  unit: PropTypes.string,
  discountType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number,
}

AluxoDiscountInput.defaultProps = {
  unit: '%',
  defaultValue: 10,
}

export default AluxoDiscountInput
