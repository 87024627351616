import queryString from 'query-string'
import jwtDecode from 'jwt-decode'

const parsed = queryString.parse(window.location.search)

if (parsed.token) {
  const decoded = jwtDecode(parsed.token)
  localStorage.setItem('roles', JSON.stringify(decoded.roles))
  localStorage.setItem('token', parsed.token)
}

export const url = `${parsed.endpoint || process.env.REACT_APP_API_URL}/api`
