import styled from 'styled-components'
import { makeStyles } from '@material-ui/styles'
import { Edit } from 'react-admin'

export const FullWidthEdit = styled(Edit)`
  & div div:last-child div {
    width: 100%;
  }
`

export const useFullWidthTabStyles = makeStyles({
  fullWidthTab: {
    '& > div': {
      width: '100%',
    },
  },
})
