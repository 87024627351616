import { BooleanInput, NumberInput, required, TextInput } from 'react-admin'
import {
  OrderLineAbsoluteDiscountInput,
  OrderLineRelativeDiscountInput,
} from 'Components/Common/InputFields/OrderLineDiscountInput'
import NetPriceCalculator from 'Components/Common/InputFields/NetPriceCalculatorInput'
import NumberWithUnitInput from 'Components/Common/InputFields/NumberWithUnitInput'
import ITScopeAutoSuggest from 'Components/Common/InputFields/AutoSuggests/ITScopeAutoSuggest/ITScopeAutoSuggest'
import DeviceAutoSuggest from 'Components/Common/InputFields/AutoSuggests/DeviceAutoSuggest/DeviceAutoSuggest'
import OnlineServiceAutoSuggest from 'Components/Common/InputFields/AutoSuggests/OnlineServiceAutoSuggest/OnlineServiceAutoSuggest'
import ProductAutoSuggest from 'Components/Common/InputFields/AutoSuggests/ProductAutoSuggest/ProductAutoSuggest'
import ServiceExtensionAutoSuggest from 'Components/Common/InputFields/AutoSuggests/ServiceExtensionAutoSuggest/ServiceExtensionAutoSuggest'
import CreditGroupSelect from 'Components/Aluxo/InputFields/CreditGroupSelect'

export const DIALOG_TYPE_EXISTING = 'existing'
export const DIALOG_TYPE_CUSTOM = 'custom'

export const changeMargin = (form, item) => {
  const { value: margin } = form.getFieldState('margin')
  if (!margin) {
    form.change('margin', item.margin)
  }

  const { value: defaultMargin } = form.getFieldState('defaultMargin')
  if (!defaultMargin) {
    form.change('defaultMargin', item.margin)
  }
}

const commonInputs = (reference, showExistingProductForm) => [
  {
    Component: TextInput,
    key: 'name',
    props: {
      source: 'name',
      label: `resources.${reference}.fields.name`,
      validate: required(),
      fullWidth: true,
    },
  },
  {
    Component: TextInput,
    key: 'sku',
    props: {
      source: 'sku',
      label: `resources.${reference}.fields.sku`,
      disabled: showExistingProductForm,
      initialValue: showExistingProductForm ? '' : 'ALX-',
    },
  },
  {
    Component: TextInput,
    key: 'manufacturer',
    props: {
      source: 'manufacturer',
      label: `resources.${reference}.fields.manufacturer`,
      disabled: showExistingProductForm,
    },
  },

  {
    Component: NumberInput,
    key: 'quantity',
    props: {
      source: 'quantity',
      validate: required(),
      initialValue: 1,
    },
  },
  {
    Component: NumberWithUnitInput,
    key: 'price',
    props: {
      source: 'price',
      unit: '€',
    },
  },
  {
    Component: NumberWithUnitInput,
    key: 'margin',
    props: {
      source: 'margin',
      unit: '%',
      defaultValue: null,
    },
  },
  {
    Component: NumberWithUnitInput,
    key: 'defaultMargin',
    props: {
      source: 'defaultMargin',

      unit: '%',
      defaultValue: null,
    },
  },
  {
    Component: NetPriceCalculator,
    key: 'netPriceCalculator',
    props: {},
  },
  {
    Component: OrderLineAbsoluteDiscountInput,
    key: 'absoluteDiscount',
    props: {
      source: 'discount',
    },
  },
  {
    Component: OrderLineRelativeDiscountInput,
    key: 'relativeDiscount',
    props: {
      source: 'discount',
    },
  },
  {
    Component: TextInput,
    key: 'referenceString',
    props: {
      source: 'referenceString',
      label: `resources.${reference}.fields.referenceString`,
    },
  },
  {
    Component: BooleanInput,
    key: 'hasSerialNumbers',
    props: {
      source: 'hasSerialNumbers',
      label: `resources.${reference}.fields.hasSerialNumbers`,
    },
  },
]

export default function getInputs({
  reference,
  record,
  form,
  dialogProps: { type, withITScope } = {
    type: DIALOG_TYPE_EXISTING,
    withITScope: true,
  },
}) {
  const showExistingProductForm = type === DIALOG_TYPE_EXISTING

  switch (reference) {
    case 'device_order_lines': {
      const inputs = [
        ...commonInputs(reference, showExistingProductForm),
        {
          Component: CreditGroupSelect,
          key: 'creditGroup',
          props: {
            source: 'creditGroup',
          },
        },
      ]

      if (showExistingProductForm) {
        inputs.unshift({
          Component: DeviceAutoSuggest,
          key: 'DeviceAutoSuggest',
          props: {
            autoFocus: true,
            withITScope,
            label: 'resources.device_order_lines.fields.search',
            source: 'device_reference_id',
            storeView: record.storeView_reference_id,
            onItemSelect: item => {
              if (item.type === 'itscope') {
                form.change('puid', item.puid)
                form.change('sku', item.sku)
                form.change('name', item.productName)
                form.change('manufacturer', item.manufacturerName)
                form.change('device_reference_id', null)
                form.change('device', null)
              } else {
                form.change('name', item.name)
                form.change('manufacturer', item.device.manufacturer.name)
                form.change('creditGroup', item.device.creditGroup)
              }
              form.change('sku', item.sku)
              form.change('price', item.price)
              form.resetFieldState('price')

              changeMargin(form, item)
            },
          },
        })
      }
      return inputs
    }
    case 'product_order_lines': {
      const inputs = [
        ...commonInputs(reference, showExistingProductForm),
        {
          Component: CreditGroupSelect,
          key: 'creditGroup',
          props: {
            source: 'creditGroup',
          },
        },
      ]

      if (showExistingProductForm) {
        inputs.unshift({
          Component: ProductAutoSuggest,
          key: 'ProductAutoSuggest',
          props: {
            autoFocus: true,
            withITScope,
            label: 'resources.product_order_lines.fields.search',
            source: 'product_reference_id',
            onItemSelect: item => {
              if (item.type === 'itscope') {
                form.change('puid', item.puid)
                form.change('name', item.productName)
                form.change('manufacturer', item.manufacturerName)
                form.change('product_reference_id', null)
              } else {
                form.change('name', item.fullName)
                form.change('manufacturer', item.product.manufacturer.name)
                form.change('creditGroup', item.product.creditGroup)
              }
              form.change('sku', item.sku)
              form.change('price', item.price)
              form.resetFieldState('price')

              changeMargin(form, item)
            },
          },
        })
      }

      return inputs
    }
    case 'service_order_lines': {
      const inputs = [...commonInputs(reference, showExistingProductForm)]

      if (showExistingProductForm) {
        inputs.unshift({
          Component: OnlineServiceAutoSuggest,
          key: 'OnlineServiceAutoSuggest',
          props: {
            autoFocus: true,
            withITScope,
            label: 'resources.service_order_lines.fields.search',
            source: 'service_reference_id',
            onItemSelect: item => {
              form.change('name', item.name)
              form.change('sku', item.sku)
              form.change('manufacturer', item.manufacturer)
              form.change('price', item.price)
              form.resetFieldState('price')

              changeMargin(form, item)
            },
          },
        })
      }

      return inputs
    }
    case 'service_extension_order_lines':
      return [
        {
          Component: ServiceExtensionAutoSuggest,
          key: 'ServiceExtensionAutoSuggest',
          props: {
            autoFocus: true,
            withITScope,
            label: 'resources.service_extension_order_lines.fields.search',
            source: 'serviceExtension_reference_id',
            onItemSelect: item => {
              if (item.type === 'itscope') {
                form.change('puid', item.puid)
                form.change('name', item.productName)
                form.change('manufacturer', item.manufacturerName)
                form.change('name', item.productName)
                form.change('device_reference_id', null)
              } else {
                form.change('name', item.name)
              }
              form.change('sku', item.sku)
              form.change('price', item.price)
              form.resetFieldState('price')
              changeMargin(form, item)
            },
          },
        },
        ...commonInputs(reference, showExistingProductForm),
      ]

    case 'generic_order_lines': {
      const inputs = [
        ...commonInputs(reference, showExistingProductForm),
        {
          Component: CreditGroupSelect,
          key: 'creditGroup',
          props: {
            source: 'creditGroup',
          },
        },
      ]
      if (withITScope) {
        inputs.unshift({
          Component: ITScopeAutoSuggest,
          key: 'ITScopeAutoSuggest',
          props: {
            autoFocus: true,
            withITScope,
            label: 'resources.generic_order_lines.fields.search',
            source: 'product',
            onItemSelect: item => {
              form.change('puid', item.puid)
              form.change('sku', item.sku)
              form.change('name', item.productName)
              form.change('manufacturer', item.manufacturerName)
              form.change('product_reference_id', null)
              form.change('price', item.price)
              form.resetFieldState('price')
              form.change('margin', 10)
              form.change('unit', 'Stk.')
            },
          },
        })
      }

      return inputs
    }
    case 'promo_bundle_order_lines': {
      const inputs = [
        {
          Component: NumberInput,
          key: 'quantity',
          props: {
            source: 'quantity',
            validate: required(),
            initialValue: 1,
          },
        },
      ]

      return inputs
    }

    default:
      return []
  }
}
