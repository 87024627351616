import { Checkbox, ListItem } from '@material-ui/core'
import { useOrder } from 'OrderContext'
import PropTypes from 'prop-types'
import React from 'react'
import {
  useCreate,
  useDelete,
  useLoading,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'

const FlagInput = ({ type, orderType }) => {
  const { record } = useOrder()

  const flag = record.flags.find(f => f.type === type) || {}

  const showNotification = useNotify()
  const refreshView = useRefresh()
  const t = useTranslate()
  const loading = useLoading()

  const [createFlag] = useCreate(
    'order_flags',
    {
      order: record['@id'],
      type,
    },
    {
      onSuccess: () => {
        showNotification('notifications.flagSet')
        refreshView()
      },
      onFailure: () => {
        showNotification('notifications.flagNotSet', 'warning')
        refreshView()
      },
    },
  )

  const [removeFlag] = useDelete('order_flags', flag.id, null, {
    onSuccess: () => {
      showNotification('notifications.flagRemoved')
      refreshView()
    },
    onFailure: () => {
      showNotification('notifications.flagNotRemoved', 'warning')
      refreshView()
    },
  })

  const disabled = loading

  const handleSetClick = async () => {
    if (disabled) {
      return
    }
    if (flag.id) {
      removeFlag()
    } else {
      createFlag()
    }
  }

  return (
    <ListItem>
      <Checkbox
        onClick={handleSetClick}
        checked={flag && flag.value}
        tabIndex={-1}
        disableRipple
      />
      {t(`${orderType}.flags.actions.${type}`)}
    </ListItem>
  )
}

FlagInput.propTypes = {
  orderType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default FlagInput
