import { useQuery } from 'react-query'
import { useDebounce } from 'use-debounce'
import PropTypes from 'prop-types'
import { gql } from '@urql/core'
import { useClient } from 'urql'
import produce from 'immer'

const GET_PROMO_BUNDLES_QUERY = gql`
  query promoBundles(
    $search: String # $storeView: String
  ) {
    promoBundles(
      first: 1000
      search: $search # storeView: $storeView
    ) {
      edges {
        node {
          id
          name
          skus
          requiredDevices {
            edges {
              node {
                id
                name
                sku
              }
            }
          }

          allowedProducts {
            edges {
              node {
                id
                name
                sku
              }
            }
          }
        }
      }
    }
  }
`

const FetchData = ({ children, searchValue }) => {
  const client = useClient()

  const [searchQuery] = useDebounce(searchValue, 400, {
    leading: false,
  })

  const queriesActive = searchQuery.length > 2

  const promoBundleQuery = useQuery(
    ['promo_bundles', searchQuery],
    () =>
      client
        .query(
          GET_PROMO_BUNDLES_QUERY,
          {
            search: searchQuery,
            // storeView,
          },
          { requestPolicy: 'network-only' },
        )
        .toPromise()
        .then(res => {
          console.log(res)
          return res
        })
        .then(
          produce(draft => {
            draft.data.promoBundles.edges.forEach(p => {
              p.node.requiredDevices = p.node.requiredDevices.edges.map(
                e => e.node,
              )
              p.node.allowedProducts = p.node.allowedProducts.edges.map(
                e => e.node,
              )
            })
          }),
        )
        .then(res => res.data.promoBundles.edges.map(e => e.node)),
    {
      enabled: queriesActive,
      keepPreviousData: true,
      initialData: [],
    },
  )

  const loading = promoBundleQuery.isFetching

  const promoBundles = promoBundleQuery.data

  console.log(promoBundles)

  return children({ promoBundles, loading })
}

FetchData.propTypes = {
  includeITScope: PropTypes.boolean,
}

FetchData.defaultProps = {
  includeITScope: false,
}

export default FetchData
