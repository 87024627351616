import React from 'react'
import { ListButton, ShowButton } from 'react-admin'
import { RightAlignedCardActions } from './RightAlignedCardActions'

const EditActions = ({ basePath, data, resource, hasShow, hasList }) => (
  <RightAlignedCardActions>
    {hasShow && <ShowButton basePath={basePath} record={data} />}
    {hasList && <ListButton basePath={basePath} />}
  </RightAlignedCardActions>
)

EditActions.defaultProps = {
  hasDelete: true,
}

export default EditActions
