import React from 'react'
import jwtDecode from 'jwt-decode'

import alsoLogo from 'Assets/Logos/logo_normal_rgb_50h.png'
import { usePermissions } from 'react-admin'
import { isAlso } from 'Lib/helper'
import { makeStyles } from '@material-ui/core/styles'
import { url } from '../../httpClient'

const UserName = ({ token }) => {
  try {
    return jwtDecode(token).email
  } catch (e) {
    return null
  }
}
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    display: 'inline-block',
    height: '32px',
  },
  image: {
    height: '32px',
  },
}))

const ApiUrl = () => (process.env.NODE_ENV === 'development' ? url : null)

const Title = () => {
  const classes = useStyles()
  const { permissions } = usePermissions()
  if (isAlso(permissions)) {
    return (
      <span className={classes.root}>
        <img className={classes.image} src={alsoLogo} alt="ALSO Logo" />
      </span>
    )
  }
  const token = localStorage.getItem('token') || null
  return (
    <span>
      aluxo365 - <UserName token={token} /> <ApiUrl />
    </span>
  )
}

export default Title
