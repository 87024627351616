export default {
  validation: {
    required: 'Feld erforderlich',
    email: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
    insecurePassword:
      'Das Passwort muss entweder aus mindestens 8 Zeichen mit Groß- und Kleinbuchstaben sowie einer Zahl bestehen oder aus drei mit Leerzeichen getrennten Wörtern',
  },
  formWizard: {
    prevStep: 'Zurück',
    nextStep: 'Weiter',
    submit: 'Absenden',
  },
  alsoEdiResellerRegister: {
    formTitle: 'Als EDI-Reseller registrieren',
    email: 'E-Mail-Adresse',
    password: 'Passwort',
    companyName: 'Firmenname',
    contactEmail: 'E-Mail (Zentrale)',
    address: {
      legalForm: 'Rechtsform',
      street: 'Straße',
      postcode: 'PLZ',
      city: 'Ort',
      country: 'Land',
      website: 'Website',
      salutation: 'Anrede',
      firstName: 'Vorname',
      lastName: 'Nachname',
      email: 'E-Mail-Adresse (Ansprechpartner)',
      phone: 'Telefon (Ansprechpartner)',
    },
  },
  bulkCreateDeviceVariants: {
    fields: {
      storeView: 'Store View',
      skus: 'SKUs',
    },
    helper: {
      skus:
        "Geben Sie hier die SKUs der zu erstellenden Produktvarianten ein. Eine SKU pro Zeile. Verschiedene Produktfamilien getrennt mit einer Zeile mit dem Inhalt '#'",
    },
  },
  inputs: {
    serialNumberInput: {
      helperText:
        'Unterstützt das Einfügen von kommaseparierten oder zeilenbasierten Listen',
    },
  },
  alsoCreateProductVariant: {
    title: 'Neue Produktvariante anlegen',
    fields: {
      variantName: 'Name der Produktvariante',
      sku: 'SKU',
      product: 'Produkt auswählen',
      createNew: 'Für neues Produkt anlegen',
      newProductName: 'Name des neuen Produkts',
    },
  },
}
