import { ListSubheader } from '@material-ui/core'
import TestSMTPButton from 'Components/Common/ActionButtons/TestSMTPButton'
// import EditActions from 'Components/Common/EditActions'
import EditToolbar from 'Components/Common/EditToolbar'
import ImageUploadInput from 'Components/Common/InputFields/ImageUploadInput'
import MetaInput from 'Components/Common/InputFields/MetaInput'
import NoteInput from 'Components/Common/InputFields/NoteInput'
import StatsInput from 'Components/Common/InputFields/StatsInput'
import TinyMCEInput from 'Components/Common/InputFields/TinyMCEInput'
import VisibleSectionsInput from 'Components/Common/InputFields/VisibleSectionsInput'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import { isAlsoReseller, isReseller } from 'Lib/helper'
import { get, head, includes } from 'lodash'
import PropTypes from 'prop-types'
import ReferenceInput from 'Components/Common/ReferenceInput'
import StoreViewCacheActions from 'Components/Common/StoreViewCacheActions'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import React from 'react'
import {
  BooleanInput,
  Create,
  CreateButton,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  FormTab,
  List,
  RefreshButton,
  required,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  TopToolbar,
  translate,
} from 'react-admin'
import {
  isColor,
  ColorField,
  ColorInput,
} from 'Components/Common/InputFields/ColorInput/ColorInput'
import { validateRequired } from 'Resources/Aluxo/devices'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'
import { validateRequiredEmail } from './reseller_smtp_configurations'

const StoreViewListActions = ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  permissions,
  showFilter,
}) => (
  <TopToolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <RefreshButton />
    {permissions && permissions.includes('ROLE_STOREVIEW') && (
      <CreateButton basePath={basePath} />
    )}
  </TopToolbar>
)

StoreViewListActions.propTypes = {
  basePath: PropTypes.string.isRequired,
  bulkActions: PropTypes.element,
  displayedFilters: PropTypes.object,
  filterValues: PropTypes.object,
  filters: PropTypes.element,
  onUnselectItems: PropTypes.func,
  permissions: PropTypes.array.isRequired,
  resource: PropTypes.string.isRequired,
  selectedIds: PropTypes.object,
  showFilter: PropTypes.bool,
}

StoreViewListActions.defaultProps = {
  showFilter: false,
  onUnselectItems: () => {},
  bulkActions: null,
  filters: null,
}

const TranslatedHeader = translate(({ translate, message }) => (
  <ListSubheader>{translate(message)}</ListSubheader>
))

const StoreViewFilter = props => (
  <Filter {...props}>
    <TextInput source="name" />
    <BooleanInput
      options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
      source="active"
    />
  </Filter>
)

export const AdminStoreViewList = props => (
  <List {...props} pagination={<CustomPagination />}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="apiKey" />
      <TextField source="netlifyInstanceId" />
      <TextField source="lastDeploy" />
      <TextField source="lastDeployId" />
      <TextField source="production" />
      <TextField source="resellerType" />
      <EditButton />
    </Datagrid>
  </List>
)

export const StoreViewList = props => (
  <List
    {...props}
    actions={<StoreViewListActions permissions={props.permissions} />}
    filters={<StoreViewFilter />}
    pagination={<CustomPagination />}
    aside={<LanguageSwitcherAside />}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="apiKey" />
      <EditButton />
    </Datagrid>
  </List>
)

const validateColor = [required(), isColor()]

StoreViewList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const StoreViewTitle = ({ record }) => get(record, 'name')

const EditActions = ({ data, resource }) => (
  <RightAlignedCardActions>
    <StoreViewCacheActions data={data} />
  </RightAlignedCardActions>
)

export const StoreViewEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<StoreViewTitle />}
    aside={<LanguageSwitcherAside />}
    {...props}
  >
    <TabbedForm toolbar={<EditToolbar />} redirect={false}>
      <FormTab label="resources.store_views.tabs.general">
        <TextInput source="name" validate={validateRequired} />

        <ImageUploadInput
          source="websiteLogoOriginal"
          resourceName="websiteLogo"
          endpoint="website_logo"
          referenceName="storeView"
          withImage
        />

        <ImageUploadInput
          source="companyLogoOriginal"
          resourceName="companyLogo"
          endpoint="company_logo"
          referenceName="storeView"
          withImage
        />

        <ImageUploadInput
          source="favicon"
          resourceName="favicon"
          referenceName="storeView"
          withImage
        />

        <ColorField source="firstColor" />
        <ColorInput
          source="firstColor"
          picker="Sketch"
          validate={validateColor}
        />

        <ColorField source="errorColor" />
        <ColorInput
          source="errorColor"
          picker="Sketch"
          validate={validateColor}
        />
        <TextInput source="contactEmail" validate={validateRequired} />
        <TextInput source="contactUrl" validate={validateRequired} />
      </FormTab>
      <FormTab label="resources.store_views.tabs.website">
        <VisibleSectionsInput />
        <StatsInput />
        <TranslatedHeader message="resources.store_views.fields.headers.social" />
        <TextInput source="facebookURL" />
        <TextInput source="googlePlusURL" />
        <TextInput source="twitterURL" />
        <TextInput source="youtubeURL" />
        <TextInput source="instagramURL" />
        <TextInput source="linkedInURL" />
        <TextInput source="xingURL" />

        <TranslatedHeader message="resources.store_views.fields.headers.others" />
        <TextInput source="googleAnalyticsCode" />
        <TextInput source="googleTagManagerID" />
        <TextInput source="googleVerification" />
        <TextInput source="password" />
        <ColorField source="outdatedBrowserBackgroundColor" />
        <ColorInput source="outdatedBrowserBackgroundColor" picker="Chrome" />
      </FormTab>
      <FormTab label="resources.store_views.tabs.seo">
        <MetaInput />
        <TextInput source="seoConfiguration.homepageSeoTitle" />
        <TinyMCEInput source="seoConfiguration.homepageSeoText" />
        {includes(props.permissions, 'ROLE_STOREVIEW') && (
          <ReferenceInput
            source="seoConfiguration.serviceExtensionOverviewPage"
            reference="pages"
            filter={{ storeView: get(props, 'id') }}
            allowEmpty
          >
            <SelectInput optionText="title" />
          </ReferenceInput>
        )}
        <TextInput source="seoConfiguration.productOverviewSlug" />
        <TinyMCEInput source="seoConfiguration.productOverviewSeoText" />
        <TranslatedHeader message="resources.store_views.fields.seoConfiguration.productOverviewMetadata" />
        <MetaInput path="seoConfiguration.productOverviewMetadata" />
      </FormTab>
      <FormTab label="resources.store_views.tabs.notes">
        <NoteInput
          noteKey="checkout.billingAddress"
          label="resources.store_views.fields.notes.checkout.billingAddress"
        />
        <NoteInput
          noteKey="checkout.shippingAddress"
          label="resources.store_views.fields.notes.checkout.shippingAddress"
        />
        <NoteInput
          noteKey="checkout.bankDetails"
          label="resources.store_views.fields.notes.checkout.bankDetails"
        />
        {props.permissions && isAlsoReseller(props.permissions) && (
          <NoteInput
            noteKey="checkout.alsoAdditionalInfo"
            label="resources.store_views.fields.notes.checkout.alsoAdditionalInfo"
          />
        )}

        <NoteInput
          noteKey="checkout.comment"
          label="resources.store_views.fields.notes.checkout.comment"
        />
        {props.permissions && isReseller(props.permissions) && (
          <NoteInput
            noteKey="checkout.ceo"
            label="resources.store_views.fields.notes.checkout.ceo"
          />
        )}

        <NoteInput
          noteKey="checkout.account"
          label="resources.store_views.fields.notes.checkout.account"
        />
      </FormTab>

      {includes(props.permissions, 'ROLE_STOREVIEW') && (
        <FormTab label="resources.store_views.tabs.smtp">
          <BooleanInput source="smtpConfiguration.active" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.smtpConfiguration &&
              formData.smtpConfiguration.active && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <TextInput
                    source="smtpConfiguration.username"
                    options={{ autocomplete: 'off' }}
                    validate={validateRequired}
                    {...rest}
                  />
                  <TextInput
                    type="password"
                    source="smtpConfiguration.password"
                    options={{ autocomplete: 'new-password' }}
                    validate={validateRequired}
                    {...rest}
                  />
                  <TextInput
                    source="smtpConfiguration.host"
                    validate={validateRequired}
                    {...rest}
                  />
                  <TextInput
                    source="smtpConfiguration.port"
                    validate={validateRequired}
                    {...rest}
                  />
                  <SelectInput
                    source="smtpConfiguration.encryption"
                    choices={[
                      { id: 'ssl', name: 'SSL' },
                      { id: 'tls', name: 'TLS' },
                      { id: 'starttls', name: 'STARTTLS' },
                    ]}
                    validate={validateRequired}
                    {...rest}
                  />
                  <TextInput
                    source="smtpConfiguration.senderName"
                    validate={validateRequired}
                    {...rest}
                  />
                  <TextInput
                    source="smtpConfiguration.senderAddress"
                    validate={validateRequiredEmail}
                    {...rest}
                  />
                  <TestSMTPButton apiKey={get(rest, 'record.apiKey')} />
                </div>
              )
            }
          </FormDataConsumer>
        </FormTab>
      )}
    </TabbedForm>
  </Edit>
)

StoreViewEdit.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export const StoreViewCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={validateRequired} />
      <TextInput
        source="domains"
        format={array => head(array)}
        parse={domain => [domain]}
        validate={validateRequired}
      />
    </SimpleForm>
  </Create>
)
