import AccessorySkuListInput from 'Components/Also/AccessorySkuListInput'
import { get } from 'lodash'
import React, { Fragment } from 'react'
import { BooleanInput, Edit, FormDataConsumer, SimpleForm } from 'react-admin'
import EditActions from 'Components/Common/EditActions'

const AlsoProductTitle = ({ record }) =>
  get(record, 'product.name') || 'Wird geladen'

export const AlsoResellerProductEdit = props => (
  <Edit actions={<EditActions />} title={<AlsoProductTitle />} {...props}>
    <SimpleForm redirect={false}>
      <BooleanInput source="customAccessories" />
      <FormDataConsumer fullWidth>
        {({ formData, ...rest }) =>
          formData.customAccessories && (
            <Fragment>
              <AccessorySkuListInput
                fullWidth
                source="featuredAccessorySKUs"
                inputLabel="resources.also_products.fields.product.search"
                {...rest}
                onlyEnabled
              />
              <AccessorySkuListInput
                fullWidth
                source="prioAccessorySKUs"
                inputLabel="resources.also_products.fields.product.search"
                {...rest}
                onlyEnabled
              />
              <AccessorySkuListInput
                fullWidth
                source="accessorySKUs"
                inputLabel="resources.also_products.fields.product.search"
                {...rest}
                onlyEnabled
              />
            </Fragment>
          )
        }
      </FormDataConsumer>
      {/* <BooleanInput source="customServices" /> */}
    </SimpleForm>
  </Edit>
)

export default AlsoResellerProductEdit
