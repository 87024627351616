import React from 'react'
import { useField } from 'react-final-form'
import { get } from 'lodash'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core'
import Button from 'Components/Common/Buttons/Button'
import { useQuery } from 'react-query'
import { useApiClient } from 'Rest/fetchHydra'
import { useRefresh } from 'react-admin'

import dllUrqlClient from 'Lib/dllUrqlClient'

import { useDebounce } from 'use-debounce'

import ContentCreate from '@material-ui/icons/Create'
import { gql } from 'urql'
import { useFieldArray } from 'react-final-form-arrays'

const PARTY_BY_NAME_AND_CITY = gql`
  query dllParties($name: String, $city: String) {
    dllParties(searchParams: { city: $city, name: $name }) {
      nodes {
        id
        name
        uniqueIdentifier
        uniqueIdentifierType
        address {
          street
          houseNumber
          postalCode
          city
        }
      }
    }
  }
`

const DLLFields = () => {
  const [open, setOpen] = React.useState(false)
  const {
    input: { value: customerId },
  } = useField('id')
  const { input } = useField('fields')

  const dllLessePartyId = get(input.value, 'DLL.LESSEE_PARTY_ID')
  const dllLesseePartyName = get(input.value, 'DLL.LESSEE_PARTY_NAME')
  const dllLessePartyCrefoNumber = get(
    input.value,
    'DLL.LESSEE_PARTY_CREFO_NUMBER',
  )

  return (
    <div>
      <div>DLL Vertragsnehmer</div>
      <div>
        Name: {dllLesseePartyName || 'Kein DLL Vertragsnehmer zugewiesen'}
      </div>
      <div>Crefo Nummer: {dllLessePartyCrefoNumber}</div>
      <div>DLL ID: {dllLessePartyId ? ` ${dllLessePartyId}` : ''}</div>
      <Button
        label="DLL Vertragsnehmer auswählen"
        color="primary"
        onClick={() => {
          setOpen(true)
        }}
      >
        <ContentCreate />
      </Button>
      <Dialog
        className="edit-dialog"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          DLL Vertragsnehmer auswählen
        </DialogTitle>
        <Inner customerId={customerId} handleClose={() => setOpen(false)} />
      </Dialog>
    </div>
  )
}

function Inner({ customerId, handleClose }) {
  const apiClient = useApiClient()

  const refreshView = useRefresh()

  const addresses = useFieldArray('addresses')

  const idxBilling = addresses.fields.value.findIndex(a => a.type === 'billing')

  const billingAddress = addresses.fields.value[idxBilling]

  let initialSearchParam = billingAddress?.company.split(' ')[0] || ''
  if (initialSearchParam.length < 3) {
    initialSearchParam = billingAddress?.company.substring(0, 5) || ''
  }

  const [searchParams, setSearchParams] = React.useState({
    name: initialSearchParam,
    city: billingAddress?.city,
  })

  const [debouncedSearchParams] = useDebounce(searchParams, 400, {
    leading: false,
  })

  const dllParties = useQuery(
    ['dll_parties', debouncedSearchParams.name, debouncedSearchParams.city],
    async () =>
      dllUrqlClient
        .query(PARTY_BY_NAME_AND_CITY, debouncedSearchParams)
        .toPromise()
        .then(res => res.data.dllParties.nodes),

    {
      placeholderData: [],
    },
  )

  const handleClick = party => async () => {
    if (party.uniqueIdentifier && party.uniqueIdentifier != '0') {
      await apiClient({
        endpoint: `/dll_set_lessee_party_for_customer_request`,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        method: 'POST',
        body: {
          customer: customerId,
          lesseePartyId: party.id,
          lesseePartyName: party.name,
          lesseePartyCrefoNumber: party.uniqueIdentifier,
        },
      })
      handleClose()
      refreshView()
    }
  }

  return (
    <DialogContent>
      <TextField
        label="Name"
        value={searchParams.name}
        onChange={e =>
          setSearchParams({ ...searchParams, name: e.target.value })
        }
      />
      <TextField
        label="Stadt"
        value={searchParams.city}
        onChange={e =>
          setSearchParams({ ...searchParams, city: e.target.value })
        }
      />
      {dllParties.isFetching && <LinearProgress />}
      <List>
        {dllParties.data.map(party => (
          <ListItem
            button={Boolean(
              party.uniqueIdentifier && party.uniqueIdentifier !== '0',
            )}
            key={party.id || party.uniqueIdentifier}
            onClick={handleClick(party)}
          >
            <ListItemText
              primary={`${party.name} (${party.id ? party.id : '-'})`}
              secondary={
                <React.Fragment>
                  <div>
                    {`${party.address.street} ${party.address.houseNumber}`}
                  </div>
                  <div>
                    {`${party.address.postalCode} ${party.address.city}`}
                  </div>
                  <div>
                    {party.uniqueIdentifierType}: {party.uniqueIdentifier}
                  </div>
                </React.Fragment>
              }
            />
          </ListItem>
        ))}
      </List>
    </DialogContent>
  )
}

export default DLLFields
