import PropTypes from 'prop-types'
import React from 'react'
import { SaveButton, Toolbar, useLoading } from 'react-admin'
import DiscardChangesButton from 'Components/Common/Buttons/DiscardChangesButton'
import { useOrder } from 'OrderContext'

const OrderEditToolbar = ({ permitted, ...props }) => {
  const loading = useLoading()

  const { canChange } = useOrder()

  if (!permitted) {
    return null
  }

  const disabled = loading || !canChange

  return (
    <Toolbar {...props}>
      <SaveButton disabled={disabled} />
      <DiscardChangesButton disabled={disabled} />
    </Toolbar>
  )
}

OrderEditToolbar.defaultProps = {
  record: {
    marking: null,
  },
}

OrderEditToolbar.propTypes = {
  permitted: PropTypes.bool.isRequired,
  record: PropTypes.shape({
    marking: PropTypes.string,
  }),
}

export default OrderEditToolbar
