import { useQuery } from 'react-query'
import { useDebounce } from 'use-debounce'
import useCanceableDataProvider from 'Hooks/useCanceableQuery'

const FetchData = ({ children, searchValue }) => {
  const [searchQuery] = useDebounce(searchValue, 400, {
    leading: false,
  })

  const queriesActive = searchQuery.length > 2

  const canceableDataProvider = useCanceableDataProvider()

  const onlineServicesQuery = useQuery(
    ['online_services', searchQuery],
    () => {
      const [promise, cancel] = canceableDataProvider(
        'getList',
        'online_services',
        {
          pagination: false,
          sort: { field: 'price', order: 'DESC' },
          filter: {
            search: searchQuery,
          },
        },
      )

      const resPromise = promise.then(res => res.data)

      resPromise.cancel = cancel
      return resPromise
    },

    {
      enabled: queriesActive,
      keepPreviousData: true,
      initialData: [],
    },
  )

  const onlineServices = onlineServicesQuery.data

  return children({ onlineServices, loading: onlineServicesQuery.isFetching })
}

export default FetchData
