import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FieldTitle, Labeled, useInput } from 'react-admin'

const HTMLEditor = props => {
  const { input, isRequired } = useInput(props)

  const label = props.label !== '' && props.label !== false && (
    <FieldTitle
      label={props.label}
      source={props.source}
      resource={props.resource}
      isRequired={isRequired}
    />
  )

  return (
    <Labeled label={label} fullWidth={props.fullWidth}>
      <CKEditor
        editor={ClassicEditor}
        config={{
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'blockQuote',
          ],
          heading: {
            options: [
              {
                model: 'paragraph',
                title: 'Paragraph',
                class: 'ck-heading_paragraph',
              },
              {
                model: 'heading1',
                view: 'h1',
                title: 'Heading 1',
                class: 'ck-heading_heading1',
              },
              {
                model: 'heading2',
                view: 'h2',
                title: 'Heading 2',
                class: 'ck-heading_heading2',
              },
            ],
          },
        }}
        data={input.value}
        onChange={(event, editor) => {
          const data = editor.getData()
          input.onChange(data)
        }}
      />
    </Labeled>
  )
}

export default HTMLEditor
