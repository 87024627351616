import { Typography } from '@material-ui/core'
import OrderCustomerAutoSuggest from 'Components/Common/InputFields/AutoSuggests/OrderCustomerAutoSuggest/OrderCustomerAutoSuggest'
import GSalesOffersField from 'Components/Common/ViewFields/Aluxo/GSalesOffersField'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import DateField from 'Components/Common/ViewFields/DateField'
import WorkflowField from 'Components/Common/ViewFields/WorkflowField'
import CustomerLink from 'Components/Common/ViewFields/CustomerLink'
import { useOrder } from 'OrderContext'
import React from 'react'
import { FormDataConsumer, SelectInput, TextField } from 'react-admin'
import { Field } from 'react-final-form'
import FinancePartnerSelect from 'Components/Aluxo/InputFields/FinancePartnerSelect'
import DateInput from 'Components/Common/InputFields/DateInput'
import TotalRateField from 'Components/Common/ViewFields/Aluxo/TotalRateField'
import { Inputs } from './OfferEdit'

const OfferGeneralEdit = () => {
  const orderProps = useOrder()

  return (
    <Inputs {...orderProps}>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.suppressMails && (
            <Field
              {...rest}
              name="suppressMails"
              component={({ input: { value } }) =>
                value && (
                  <Typography variant="subheading" color="error">
                    E-Mail Versand deaktiviert
                  </Typography>
                )
              }
            />
          )
        }
      </FormDataConsumer>
      <WorkflowField source="workflow" />
      <TextField source="storeView.name" />

      <CustomerLink
        source="customer_reference_id"
        linkText="billingAddress.company"
        label="resources.orders.fields.billingAddress.company"
      />
      <GSalesOffersField source="gSalesOffers" />

      <OrderCustomerAutoSuggest />

      <DateField source="created" format="DD.MM.YYYY" />
      <SelectInput
        source="period"
        choices={[
          { id: 24, name: '24 Monate' },
          { id: 36, name: '36 Monate' },
          { id: 48, name: '48 Monate' },
          { id: 60, name: '60 Monate' },
        ]}
        disabled={!orderProps.canChange}
      />

      <DateInput
        source="offerValidUntil"
        variant="filled"
        options={{
          disabled: !orderProps.canChange,
          style: { marginTop: 6 },
        }}
      />

      <CurrencyField source="totalVolume" />
      <CurrencyField source="creditVolume" />
      <CurrencyField source="itScopeVolume" />
      <TotalRateField />
      <FinancePartnerSelect />
    </Inputs>
  )
}

export default OfferGeneralEdit
