import { LinearProgress, Paper } from '@material-ui/core'
import { Input } from 'Components/Common/InputFields/AutoSuggests/Lib/renderFunctions'
import Downshift from 'downshift'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import SelectList from 'Components/Common/InputFields/AutoSuggests/Lib/SelectList'
import RenderSuggestion from 'Components/Common/InputFields/Also/ProductAutoSuggest/RenderSuggestion'
import FetchData from './FetchData'
import getSuggestions from './getSuggestions'

const itemToString = item => item.label

const ProductAutoSuggest = ({
  label,
  onChange,
  value,
  onItemSelect,
  storeView,
  autoFocus,
  type,
}) => {
  const selectedItem = useMemo(() => ({ value }), [value])

  return (
    <Downshift
      onChange={selection => {
        if (selection) {
          onChange(selection)
          onItemSelect(selection)
        }
      }}
      itemToString={itemToString}
      selectedItem={selectedItem}
    >
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        isOpen,
        selectedItem,
        inputValue,
        highlightedIndex,
      }) => (
        <div>
          <Input
            label={label}
            fullWidth
            autoFocus={autoFocus}
            InputProps={getInputProps()}
          />

          <Paper
            style={{
              display: isOpen ? 'block' : 'none',
              visibility: isOpen ? 'visible' : 'hidden',
            }}
            square
            {...getMenuProps()}
          >
            {isOpen ? (
              <FetchData
                searchValue={inputValue}
                storeView={storeView}
                type={type}
              >
                {({ activeProducts, loading }) => {
                  const suggestions = getSuggestions({
                    activeProducts,
                    inputValue,
                  })

                  const rows = []
                  suggestions.forEach(group => {
                    rows.push({ label: group.title })
                    group.suggestions.forEach(suggestion => {
                      rows.push(suggestion)
                    })
                  })

                  return (
                    <>
                      {loading && (
                        <LinearProgress
                          style={{ width: '100%' }}
                          mode="indeterminate"
                        />
                      )}

                      <SelectList
                        rows={rows}
                        getItemProps={getItemProps}
                        highlightedIndex={highlightedIndex}
                        selectedItem={selectedItem}
                        itemToString={itemToString}
                        renderSuggestionComponent={RenderSuggestion}
                      />
                    </>
                  )
                }}
              </FetchData>
            ) : null}
          </Paper>
        </div>
      )}
    </Downshift>
  )
}

ProductAutoSuggest.propTypes = {
  source: PropTypes.string,
  onItemSelect: PropTypes.func,
  type: PropTypes.string,
}

ProductAutoSuggest.defaultProps = {
  source: null,
  onItemSelect: () => {},
  type: 'WorkplaceDevice',
}

export default ProductAutoSuggest
