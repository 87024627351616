import { List } from '@material-ui/core'
import { transitionEnabled as getTransitionEnabled } from 'Components/Common/Actions/Order/OrderActionTransitions'
import FlagInput from 'Components/Common/Orders/Flags/FlagInput'
import { useOrder } from 'OrderContext'
import React, { useContext } from 'react'
import { Form } from 'react-final-form'
import { useToggle } from 'react-use'
import styled from 'styled-components/macro'
import { ResellerContext } from 'ResellerContext'
import SubmitButton from './SubmitButton'
import getContextFormElements from './getContextFormFields'
import ContextDialog from './ContextDialog'

const NoticeText = styled.span`
  font-size: small;
  color: rgba(0, 0, 0, 0.57);
  padding: 4px;
  white-space: pre-line;
`

const OrderActionItem = ({
  transition,
  enabled,
  reasons,
  orderType,
  permitted,
  handleClick,
  manualTransitions,
}) => {
  const { flags: resellerFlags } = useContext(ResellerContext)
  const { record } = useOrder()
  const { flags: orderFlags } = record
  const [contextDialogVisible, toggle] = useToggle(false)

  const {
    danger,
    potentialFlags,
    icon,
    customAction,
    context,
    visible,
  } = manualTransitions[transition]

  if (visible !== undefined && !visible(resellerFlags, record.fields)) {
    return null
  }

  const handleSubmit = values => {
    toggle(false)
    handleClick({ transition, customAction, context: values })
  }

  const validate = context ? values => context.validate(record, values) : null

  const transitionEnabled = getTransitionEnabled(
    transition,
    orderFlags,
    manualTransitions,
    record,
  )

  return (
    <>
      <Form onSubmit={handleSubmit} key={transition} validate={validate}>
        {formProps => (
          <form onSubmit={formProps.handleSubmit}>
            {contextDialogVisible && (
              <ContextDialog
                transition={transition}
                orderType={orderType}
                context={context}
                toggle={toggle}
                submitButton={
                  <SubmitButton
                    context={context}
                    onClick={formProps.handleSubmit}
                    transitionEnabled={transitionEnabled}
                    permitted={permitted}
                    valid={formProps.valid}
                    enabled={enabled}
                    transition={transition}
                    orderType={orderType}
                    danger={danger}
                    icon={icon}
                  />
                }
              />
            )}
            {getContextFormElements({ context })}
            {potentialFlags && (
              <List>
                {potentialFlags(record).map(({ key }) => (
                  <FlagInput orderType={orderType} key={key} type={key} />
                ))}
              </List>
            )}
            <SubmitButton
              context={context}
              onClick={toggle}
              transitionEnabled={transitionEnabled}
              permitted={permitted}
              valid={formProps.valid}
              enabled={enabled}
              transition={transition}
              orderType={orderType}
              danger={danger}
              icon={icon}
            />
            {reasons && <NoticeText>{reasons.join('\n')}</NoticeText>}
            {formProps.errors && (
              <NoticeText>
                {Object.values(formProps.errors).join('\n')}
              </NoticeText>
            )}
          </form>
        )}
      </Form>
    </>
  )
}

export default OrderActionItem
