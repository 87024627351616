export default {
  settings: {
    name: 'Einstellungen',
    general: 'Allgemein',
    smtpConfiguration: 'SMTP',
    personalSmtpConfiguration: 'Persönliche SMTP Einstellungen',
    corporateIdentity: 'Corporate Identity',
    seoConfiguration: 'SEO',
    itScopeSettings: 'IT Scope Einstellungen',
    acmpSettings: 'ACMP Einstellungen',
    gsalesSettings: 'GSales Einstellungen',
    defaultStoreView: 'Webseite',
    alsoOfferSettings: 'Angebot Einstellungen',
  },
  help: 'Hilfe',
}
