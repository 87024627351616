import React from 'react'
import { Sort } from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import { useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'

const BackButton = ({ resource }) => {
  const translate = useTranslate()
  return (
    <Button
      color="primary"
      component={Link}
      to={`${resource}/sort`}
      style={{ overflow: 'inherit', padding: '4px 5px' }}
    >
      <Sort />
      {translate('actions.sort')}
    </Button>
  )
}

export default BackButton
