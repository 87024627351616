import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import { useTranslate } from 'react-admin'
import getContextFormElements from './getContextFormFields'

const CommonActionsOrderContextDialog = ({
  transition,
  orderType,
  submitButton,
  toggle,
  context,
}) => {
  const t = useTranslate()
  const handleClose = () => {
    toggle()
  }
  return (
    <Dialog open fullWidth>
      <DialogTitle>{t(`${orderType}.transitions.${transition}`)}</DialogTitle>
      <DialogContent>
        {getContextFormElements({ context, withDialog: true }).map(input => (
          <div>{input}</div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('actions.cancel')}
        </Button>
        {submitButton}
      </DialogActions>
    </Dialog>
  )
}

export default CommonActionsOrderContextDialog
