import React from 'react'
import { Datagrid, List, TextField } from 'react-admin'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'
import DateField from 'Components/Common/ViewFields/DateField'

const AlsoOfferList = props => (
  <List pagination={<CustomPagination />} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="offerNumber" />
      <TextField source="offerAddress.company" />
      <DateField source="createdAt" format="DD.MM.YYYY" />
    </Datagrid>
  </List>
)

export default AlsoOfferList
