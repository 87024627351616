import Fuse from 'fuse.js'

export default function getSuggestions({ itScopeData, inputValue }) {
  const searchValue = inputValue || ''.trim().toLowerCase()

  const fuse = new Fuse(itScopeData, {
    keys: [
      'manufacturerName',
      'productName',
      'puid',
      'manufacturerSKU',
      'longDescription',
    ],
  })

  const results = fuse.search(searchValue)

  return results.map(({ item }) => ({
    ...item,
    id: item.puid,
    sku: item.manufacturerSKU,
    label: `${item.manufacturerSKU} - ${item.productName}`,
    value: item.puid,
  }))
}
