import {
  DialogContent,
  Divider,
  List,
  ListItem,
  TextField,
  ListItemText,
  ListSubheader,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core'
import { sumBy, difference as _dif, sortBy } from 'lodash'
import currencyText from 'Lib/currencyText'
import percentText from 'Components/Common/percentText'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import ITScopeOrderTable from './ITScopeOrderTable'
import OrderAlternativeMenuItem from './OrderAlternativeMenuItem'

const Number = styled.div`
  text-align: right;
  font-variant-numeric: tabular-nums;
`

const ITScopeSplitOrder = ({
  splitOrder,
  handleChange,
  handleRemove,
  handleComment,
  handleChangeSupplier,
  handleSetSupplier,
  expected,
  errors: initalErrors,
}) => {
  const calculatedPrice = useMemo(
    () =>
      splitOrder &&
      Object.values(splitOrder.orders)
        .map(order =>
          Object.values(order.lines).reduce(
            (acc, line) => acc + line.quantity * line.price.price,
            0,
          ),
        )
        .reduce((acc, orderPrice) => acc + orderPrice, 0),
    [splitOrder],
  )

  const difference = useMemo(() => splitOrder && calculatedPrice / expected, [
    splitOrder,
    calculatedPrice,
    expected,
  ])
  const differenceAbs = useMemo(
    () => splitOrder && calculatedPrice - expected,
    [splitOrder, calculatedPrice, expected],
  )

  const errors = useMemo(
    () =>
      difference > 1.05
        ? [...initalErrors, { status: 'difference too high' }]
        : initalErrors,
    [difference, initalErrors],
  )

  const handleForceOrder = ({ alternative, details }) => {
    handleSetSupplier({
      alternative,
      details,
    })
  }

  const puidsInOrders = useMemo(
    () => splitOrder?.orders.flatMap(o => Object.keys(o.lines)),
    [splitOrder],
  )

  if (!splitOrder) return null

  return (
    <>
      <DialogContent>
        <Typography variant="body1">
          <div css="display: flex; justify-content: space-between;">
            <div>
              <div>Erwarteter Preis</div>
              <div>Tatsächlicher Preis</div>
              <div>Differenz</div>
              <div>Geschätzte Versandkosten</div>
            </div>
            <div>
              <Number id="it-scope-order-expectedPrice">
                {currencyText(expected)}
              </Number>
              <Number id="it-scope-order-calculatedPrice">
                {currencyText(calculatedPrice)}
              </Number>
              <Number id="it-scope-order-difference">{`${currencyText(
                differenceAbs,
              )} (${percentText(difference - 1)})`}</Number>
              <Number id="it-scope-order-totalShipping">
                {currencyText(sumBy(splitOrder.orders, 'totalShipping'))}
              </Number>
            </div>
          </div>
        </Typography>
      </DialogContent>

      <List dense>
        {splitOrder.orders.map((o, index) => (
          <>
            <ListSubheader>{`${o.supplier.name}`}</ListSubheader>

            <ITScopeOrderTable
              data={o.lines}
              onChange={({ ...args }) => handleChange({ index, ...args })}
              onRemove={({ ...args }) => handleRemove({ index, ...args })}
              onSetSupplier={({ alternative, lineId }) =>
                handleChangeSupplier({
                  index,
                  alternative,
                  lineId,
                })
              }
            />

            <ListItem>
              <ListItemText
                primary="Versandkosten"
                secondary={currencyText(o.totalShipping || 0)}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Kommentar"
                secondary={
                  <TextField
                    fullWidth
                    multiline
                    value={o.comment}
                    onChange={({ target: { value } }) =>
                      handleComment({ index, comment: value })
                    }
                  />
                }
              />
            </ListItem>
            <Divider />
          </>
        ))}

        {errors.length > 0 && (
          <ListSubheader>{`${errors.length} Fehler`}</ListSubheader>
        )}
        {errors.map(e => {
          if (e.status === 'product not on stock')
            return (
              <ListItem>
                <ListItemText
                  primary="Produkt ist nicht in entsprechender Stückzahl vorhanden."
                  secondary={
                    <div>
                      <div>
                        {e.details.puids.map(puid => (
                          <div>
                            <a
                              href={`${e.details.deepLink}${puid}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {`${puid} (${e.details.manufacturerSKU})`}
                            </a>
                          </div>
                        ))}
                      </div>
                      <div>{e.details.name}</div>
                      <div>
                        {`Benötigt: ${e.details.totalQuantity}. Verfügbar: ${e.details.totalAvailableStock}.`}
                      </div>
                      {!e.details.puids.some(p => puidsInOrders.includes(p)) ? (
                        <div>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value=""
                            onChange={event =>
                              handleForceOrder({
                                alternative: event.target.value,
                                details: e.details,
                              })
                            }
                            displayEmpty
                          >
                            <MenuItem value="">
                              <em>Bestellen bei...</em>
                            </MenuItem>
                            {sortBy(e.details.alternatives, 'price').map(
                              (a, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <MenuItem value={a} key={index}>
                                  <OrderAlternativeMenuItem
                                    a={a}
                                    totalQuantity={e.details.totalQuantity}
                                  />
                                </MenuItem>
                              ),
                            )}
                          </Select>
                        </div>
                      ) : null}
                    </div>
                  }
                />
              </ListItem>
            )

          if (e.status === 'difference too high')
            return (
              <ListItem>
                <ListItemText primary="Differenz im Bestellpreis ist zu hoch." />
              </ListItem>
            )

          return (
            <ListItem>
              <ListItemText primary={e.message} />
            </ListItem>
          )
        })}
      </List>
    </>
  )
}

// Can't validate splitOrder easily because of dynamic keys
ITScopeSplitOrder.propTypes = {
  splitOrder: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleComment: PropTypes.func.isRequired,
  handleChangeSupplier: PropTypes.func.isRequired,
  handleSetSupplier: PropTypes.func.isRequired,
  expected: PropTypes.number.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      details: PropTypes.shape({
        puid: PropTypes.string.isRequired,
        manufacturerSKU: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        totalQuantity: PropTypes.number.isRequired,
        totalAvailableStock: PropTypes.number.isRequired,
      }).isRequired,
    }),
  ).isRequired,
}

export default ITScopeSplitOrder
