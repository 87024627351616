import { SelectInput } from 'react-admin'
import React, { useContext } from 'react'
import { ResellerContext } from 'ResellerContext'

export default function CreditGroupSelect(props) {
  const { flags } = useContext(ResellerContext)

  if (!flags.multipleFinancePartners) {
    return null
  }

  return (
    <SelectInput
      source="creditGroup"
      choices={[
        'Notebooks',
        'Individual Accessories',
        'Services',
        'Workstations',
        'Video Conferencing',
        'Displays',
        'Tablets',
      ].map(value => ({ id: value, name: value }))}
      {...props}
    />
  )
}
