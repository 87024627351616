/* eslint-disable no-underscore-dangle */
/* eslint-disable global-require */
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import { all, fork } from 'redux-saga/effects'
import { adminReducer, adminSaga, USER_LOGOUT } from 'react-admin'
import dialogReducer from 'Ducks/dialog'
import languageReducer from 'Ducks/language'
import createDeviceSaga from 'Sagas/createDeviceSaga'
import afterUpdateSaga from 'Sagas/afterUpdateSaga'
import afterDeleteSaga from 'Sagas/afterDeleteSaga'
import changeLanguageSaga from 'Sagas/changeLanguageSaga'

export default ({ authProvider, dataProvider, history }) => {
  const reducer = combineReducers({
    admin: adminReducer,
    router: connectRouter(history),
    // add your own reducers here
    dialog: dialogReducer,
    language: languageReducer,
  })
  const resettableAppReducer = (state, action) =>
    reducer(action.type !== USER_LOGOUT ? state : undefined, action)

  const saga = function* rootSaga() {
    yield all(
      [
        adminSaga(dataProvider, authProvider),
        // add your own sagas here
      ].map(fork),
    )
  }

  const otherSagas = [
    createDeviceSaga,
    afterUpdateSaga,
    // errorSagas,
    afterDeleteSaga,
    changeLanguageSaga,
    {
      condition: process.env.REACT_APP_FETCH_LOADING === '1',
      loadSaga: () => require('./Sagas/storeLoadingForTestSaga').default,
    },
  ]

  const sagaMiddleware = createSagaMiddleware()

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose

  const store = createStore(
    resettableAppReducer,
    {
      /* set your initial state here */
    },
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        // add your own middlewares here
      ),
      // add your own enhancers here
    ),
  )

  sagaMiddleware.run(saga)

  otherSagas.forEach(saga => {
    if (typeof saga === `function`) {
      sagaMiddleware.run(saga)
    } else if (typeof saga === `object`) {
      if (saga.condition) {
        sagaMiddleware.run(saga.loadSaga())
      }
    }
  })

  return store
}
