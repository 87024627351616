import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { addField, Labeled } from 'react-admin'
import Fuse from 'fuse.js'
import { useQuery } from 'react-query'
import { useApiClient } from 'Rest/fetchHydra'
import {
  Checkbox,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import { useDebounce } from 'use-debounce'
import TextField from '@material-ui/core/TextField'
import { sortBy } from 'lodash'

const useStyles = makeStyles(theme => ({
  list: {
    // width: 200,
    height: 400,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
}))

const AccessorySkuInput = ({ input, meta, inputLabel, ...rest }) => {
  const apiClient = useApiClient()

  const [searchValue, setSearchValue] = useState('')

  const [searchQuery] = useDebounce(searchValue, 400, {
    leading: false,
  })

  const alsoProductVariantsQuery = useQuery(
    ['also_product_variants'],
    () =>
      apiClient({
        endpoint: `/also_product_variants`,
        headers: {
          Accept: 'application/json',
        },
        params: {
          pagination: false,
          'product.type': 'Accessory',
        },
      }).then(res => res.data),

    {
      initialData: [],
    },
  )
  const alsoResellerProductsQuery = useQuery(
    ['also_reseller_products'],
    () =>
      apiClient({
        endpoint: `/also_reseller_products`,
        headers: {
          Accept: 'application/json',
        },
        params: {
          pagination: false,
          'product.type': 'Accessory',
        },
      }).then(res => res.data),

    {
      initialData: [],
    },
  )

  const loading =
    alsoResellerProductsQuery.isFetching || alsoProductVariantsQuery.isFetching

  const { data: alsoResellerProducts } = alsoResellerProductsQuery
  const { data: alsoProductVariants } = alsoProductVariantsQuery

  const activeVariants = useMemo(
    () =>
      sortBy(
        alsoProductVariants.filter(
          v =>
            v.active &&
            alsoResellerProducts.some(p => p.active && p.product === v.product),
        ),
        'name',
      ),
    [alsoResellerProducts, alsoProductVariants],
  )

  const fuse = useMemo(
    () =>
      new Fuse(activeVariants, {
        keys: ['sku', 'name', 'manufacturer'],
        threshold: 0.2,
      }),
    [activeVariants],
  )

  const displayedRows = useMemo(() => {
    if (searchQuery.length <= 2) {
      return activeVariants
    }
    return fuse.search(searchQuery).map(r => r.item)
  }, [searchQuery, activeVariants])

  const handleToggle = sku => {
    if (input.value.indexOf(sku) !== -1) {
      input.onChange(input.value.filter(s => s !== sku))
    } else {
      input.onChange([...input.value, sku])
    }
  }

  const classes = useStyles()

  return (
    <Labeled {...rest} fullWidth>
      <div>
        {loading ? (
          <LinearProgress>Loading</LinearProgress>
        ) : (
          <TextField
            value={searchValue}
            onChange={({ target: { value } }) => setSearchValue(value)}
            label="Filtern"
          />
        )}
        <List className={classes.list} dense component="div" role="list">
          {displayedRows.map(a => {
            const labelId = `transfer-list-all-item-${a.id}-label`

            return (
              <ListItem
                key={a.id}
                role="listitem"
                button
                onClick={() => handleToggle(a.sku)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={input.value.indexOf(a.sku) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${a.name} (${a.sku})`} />
              </ListItem>
            )
          })}
        </List>
      </div>
    </Labeled>
  )
}

AccessorySkuInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export default addField(AccessorySkuInput)
