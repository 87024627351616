import numbro from 'numbro'
import de from 'numbro/languages/de-DE'

numbro.registerLanguage(de)
numbro.setLanguage('de-DE')

const currencyText = value =>
  `${numbro(parseFloat(value)).formatCurrency({
    thousandSeparated: true,
    mantissa: 2,
    spaceSeparated: true,
  })}`

export default currencyText
