import { cacheExchange, createClient, dedupExchange, fetchExchange } from 'urql'
import { map, merge, pipe } from 'wonka'
import { url } from 'httpClient'
import { replaceReferencesInGraphQLResponse } from 'Rest/convertHydraResponseToReactAdminResponse'

export const keepReferencesExchange = ({ forward }) => operations$ => {
  const operationResult$ = forward(operations$)

  const modifiedOps$ = pipe(
    operationResult$,
    map(x => {
      if (x.data) {
        replaceReferencesInGraphQLResponse(x.data)
      }
      return x
    }),
  )

  return merge([modifiedOps$])
}

const urqlClient = createClient({
  url: `${url}/graphql`,
  exchanges: [
    keepReferencesExchange,
    dedupExchange,
    cacheExchange,
    fetchExchange,
  ],
  fetchOptions: () => {
    const token = localStorage.getItem('token')
    return {
      headers: { authorization: token ? `Bearer ${token}` : '' },
    }
  },
})

export default urqlClient
