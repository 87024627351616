import { useField } from 'react-final-form'
import ReferenceInput from 'Components/Common/ReferenceInput'
import { SelectInput } from 'react-admin'
import React from 'react'
import { validateRequired } from 'Resources/Aluxo/devices'

const CommonInputFieldsCategoryBasedOnStoreviewInput = props => {
  const {
    input: { value },
  } = useField('storeView_reference_id')

  return (
    <ReferenceInput
      source="category"
      reference="categories"
      validate={validateRequired}
      filter={{ storeView: value }}
      {...props}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  )
}

export default CommonInputFieldsCategoryBasedOnStoreviewInput
