import { LinearProgress, Paper } from '@material-ui/core'
import { Input } from 'Components/Common/InputFields/AutoSuggests/Lib/renderFunctions'
import Downshift from 'downshift'
import PropTypes from 'prop-types'
import React from 'react'
import { BooleanInput } from 'react-admin'
import { useField } from 'react-final-form'
import SelectList from 'Components/Common/InputFields/AutoSuggests/Lib/SelectList'
import FetchData from './FetchData'
import getSuggestions from './getSuggestions'

const itemToString = item => item?.label

const DeviceAutoSuggestField = ({
  label,
  onChange,
  withITScope,
  storeView,
  autoFocus,
  selectedItem,
  includeItScope: initialIncludeItScope,
}) => {
  const {
    input: { value: includeItScope },
  } = useField('includeItScope', {
    initialValue: initialIncludeItScope,
  })

  return (
    <Downshift
      onChange={onChange}
      itemToString={itemToString}
      selectedItem={selectedItem}
      key={includeItScope}
    >
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        isOpen,
        selectedItem,
        inputValue,
        highlightedIndex,
      }) => (
        <div>
          {withITScope && (
            <BooleanInput label="mit ITScope" source="includeItScope" />
          )}
          <Input
            label={label}
            fullWidth
            autoFocus={autoFocus}
            InputProps={getInputProps()}
          />

          <Paper
            style={{
              display: isOpen ? 'block' : 'none',
              visibility: isOpen ? 'visible' : 'hidden',
            }}
            square
            {...getMenuProps()}
          >
            {isOpen ? (
              <FetchData
                includeItScope={includeItScope}
                searchValue={inputValue}
                storeView={storeView}
              >
                {({ deviceVariants, itScopeData, loading }) => {
                  const suggestions = getSuggestions({
                    deviceVariants,
                    itScopeData,
                    inputValue,
                  })

                  const rows = []
                  suggestions.forEach(group => {
                    rows.push({ label: group.title })
                    group.suggestions.forEach(suggestion => {
                      rows.push(suggestion)
                    })
                  })

                  return (
                    <>
                      {loading && (
                        <LinearProgress
                          style={{ width: '100%' }}
                          mode="indeterminate"
                        />
                      )}

                      <SelectList
                        rows={rows}
                        getItemProps={getItemProps}
                        highlightedIndex={highlightedIndex}
                        selectedItem={selectedItem}
                        itemToString={itemToString}
                      />
                    </>
                  )
                }}
              </FetchData>
            ) : null}
          </Paper>
        </div>
      )}
    </Downshift>
  )
}

DeviceAutoSuggestField.propTypes = {
  source: PropTypes.string,
  onItemSelect: PropTypes.func,
  withITScope: PropTypes.bool,
  includeItScope: PropTypes.bool,
}

DeviceAutoSuggestField.defaultProps = {
  source: null,
  onItemSelect: () => {},
  withITScope: false,
  includeItScope: false,
}

export default DeviceAutoSuggestField
