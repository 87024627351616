import { includes } from 'lodash/fp'

export const isAdmin = includes('ROLE_ADMIN')
export const isAlsoAdmin = includes('ROLE_ALSO_ADMIN')
export const isReseller = includes('ROLE_RESELLER')
export const isAlsoReseller = includes('ROLE_ALSO_RESELLER')
export const isAlsoEdiReseller = includes('ROLE_ALSO_EDI_RESELLER')
export const isAffiliateReseller = includes('ROLE_AFFILIATE_RESELLER')

export const isAlso = permissions =>
  isAlsoAdmin(permissions) ||
  isAlsoReseller(permissions) ||
  isAlsoEdiReseller(permissions)

export const getLinkFromIri = iri => (iri ? iri.substring(4) : '/')

export const getIdOrIri = string => string && string.split('/').slice(-1)[0]

// Dateparser makes sure the date does not shift depending on users timezone
const tzOffset = new Date().getTimezoneOffset() / 60
export const dateParser = v => {
  const regexp = /(\d{4})-(\d{2})-(\d{2})/
  let match = regexp.exec(v)
  if (match === null) {
    return undefined
  }

  let year = match[1]
  let month = match[2]
  let day = match[3]

  if (tzOffset < 0) {
    // negative offset means our picked UTC date got converted to previous day
    const date = new Date(v)
    date.setDate(date.getDate() + 1)
    match = regexp.exec(date.toISOString())
    year = match[1] // eslint-disable-line
    month = match[2] // eslint-disable-line
    day = match[3] // eslint-disable-line
  }
  const d = [year, month, day].join('-')
  return d
}
