import { useField } from 'react-final-form'
import { FormInput, useDataProvider } from 'react-admin'
import React, { useEffect } from 'react'

import Button from 'Components/Common/Buttons/Button'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import getDialogFormInputs from 'Components/Aluxo/OrderLines/Lines/getDialogFormInputs'
import { upperFirst } from 'lodash'

export const DIALOG_TYPE_EXISTING = 'existing'
export const DIALOG_TYPE_CUSTOM = 'custom'

export default function AddDialogForm({
  handleSubmit,
  reference,
  handleClose,
  record,
  loading,
  form,
  dialogProps: { type, withITScope },
}) {
  const dataProvider = useDataProvider()
  const {
    input: { onChange: onChangeMargin },
  } = useField('margin')

  const {
    input: { onChange: onChangeDefaultMargin },
  } = useField('defaultMargin')

  const discountTypes = {
    device_order_lines: 'itScopeDeviceMargin',
    product_order_lines: 'itScopeProductMargin',
    service_extension_order_lines: 'itScopeServiceExtensionsMargin',
    generic_order_lines: 'genericMargin',
    service_order_lines: 'cloudServiceMargin',
    // service_order_lines: "managedServiceMargin",
  }

  useEffect(() => {
    const getData = async () => {
      const { id: customerId } = get(record, 'customer', {}) || {}
      if (!customerId) {
        return
      }

      // get specific margin for customer
      try {
        const res = await dataProvider.getList('aluxo_customer_discounts', {
          pagination: false,
          sort: {},
          filter: {
            customer: customerId,
            discountType: discountTypes[reference],
          },
        })
        const margin = get(res, 'data.0.margin')
        if (margin) {
          onChangeMargin(margin)
        }
      } catch (e) {
        // do nothing
      }

      // get default margin for customer
      try {
        const res = await dataProvider.getList('aluxo_customer_discounts', {
          pagination: false,
          sort: {},
          filter: {
            customer: customerId,
            discountType: `default${upperFirst(discountTypes[reference])}`,
          },
        })
        const defaultMargin = get(res, 'data.0.margin')
        if (defaultMargin) {
          onChangeDefaultMargin(defaultMargin)
        }
      } catch (e) {
        // do nothing
      }
    }
    getData()
  }, [])

  const inputs = getDialogFormInputs({
    handleSubmit,
    reference,
    handleClose,
    record,
    loading,
    form,
    dialogProps: { type, withITScope },
  })

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        {inputs
          .filter(i => i)
          .map(({ Component, key, props }) => (
            <FormInput
              key={key}
              input={<Component {...props} />}
              basePath={reference}
              record={record}
              resource={reference}
              variant="filled"
              margin="dense"
              {...props}
            />
          ))}
      </DialogContent>
      <DialogActions>
        <Button
          label="Abbrechen"
          onClick={handleClose}
          color="secondary"
          disabled={loading}
        />
        <Button
          label="ra.action.save"
          type="submit"
          color="primary"
          loading={loading}
          disabled={loading}
        />
      </DialogActions>
    </form>
  )
}

AddDialogForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  reference: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    change: PropTypes.func.isRequired,
    getFieldState: PropTypes.func.isRequired,
  }).isRequired,
  dialogProps: PropTypes.shape({
    type: PropTypes.oneOf([DIALOG_TYPE_EXISTING, DIALOG_TYPE_CUSTOM]),
    withITScope: PropTypes.bool.isRequired,
  }),
}

AddDialogForm.defaultProps = {
  dialogProps: {
    type: DIALOG_TYPE_CUSTOM,
  },
}
