import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import b64toBlob from 'b64-to-blob'
import Button from 'Components/Common/Buttons/Button'
import mime from 'mime-types'
import PropTypes from 'prop-types'
import React from 'react'
import { useMutation, useNotify, useTranslate } from 'react-admin'

const DownloadActions = ({ buttonText, icon, source, id, preview }) => {
  const translate = useTranslate()
  const showNotification = useNotify()

  const [mutate, { loading }] = useMutation(
    {
      type: 'downloadAttachment',
      resource: source,
      payload: { id },
    },
    {
      onSuccess: async ({ data: response }) => {
        const contentType = response.headers.get('Content-Type')
        const resText = await response.text()
        const blob = b64toBlob(resText, contentType)
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        document.body.appendChild(a)
        const extension = mime.extension(contentType)
        a.style = 'display: none'
        a.href = blobUrl
        if (!preview) {
          a.target = '_blank'
        } else {
          a.download = `${translate(
            'protectionPlans.download.fileName',
          )}.${extension}`
        }
        a.click()
        URL.revokeObjectURL(blobUrl)
      },
      onFailure: error => {
        showNotification(`Error: ${error}`, 'warning') // todo errormessage
      },
    },
  )

  const handleDownloadClick = async () => {
    mutate()
  }

  return (
    <Button
      color="primary"
      icon={icon}
      loading={loading}
      onClick={handleDownloadClick}
      label={translate(buttonText)}
    />
  )
}

DownloadActions.propTypes = {
  source: PropTypes.string.isRequired, // eslint-disable-line
  buttonText: PropTypes.string.isRequired,
  icon: PropTypes.node,
  record: PropTypes.object, //eslint-disable-line
  preview: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
}

DownloadActions.defaultProps = {
  icon: <CloudDownloadIcon />,
  preview: false,
}

export default DownloadActions
