import { Box, CircularProgress } from '@material-ui/core'
import AlsoOfferCreateGridFormView from 'Components/Also/Offers/GridFormView'
import arrayMutators from 'final-form-arrays'
import moment from 'moment'
import OrderProvider from 'OrderContext'
import PropTypes from 'prop-types'
import React from 'react'
import { TitleForRecord, useCreateController } from 'react-admin'
import { Form } from 'react-final-form'
import XLSX from 'xlsx'
import { insert } from 'Lib/finalFormMutators'

moment.locale('de')

export const createWorksheet = values => {
  /* make worksheet */
  const wsData = [
    ['Angebotsadresse'],
    ...Object.entries(values.offerAddress),
    ['Bestellpositionen'],
  ]

  const offerLines = values.offerLines.map(
    ({ id, margin, price, ...line }) => ({
      ...line,
      rate: (price * ((100 + margin) / 100)) / values.period,
    }),
  )

  const lineKeys = Object.keys(offerLines[0])

  if (offerLines.length > 0) {
    wsData.push(lineKeys)
  }

  offerLines.forEach(line => {
    wsData.push(lineKeys.map(key => line[key]))
  })

  const ws = XLSX.utils.aoa_to_sheet(wsData)
  return ws
}

const AlsoOfferCreate = props => {
  const { resource, record, save, basePath, version } = useCreateController(
    props,
  )

  if (!record) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <CircularProgress />
      </Box>
    )
  }

  const handleSubmit = async values => {
    save(values, 'edit')
  }

  return (
    <OrderProvider record={record} basePath={basePath} resource={resource}>
      <>
        <TitleForRecord record={record} title="Neues Angebot erstellen" />
        <Form
          key={version}
          onSubmit={handleSubmit}
          keepDirtyOnReinitialize
          mutators={{ ...arrayMutators, insertAt: insert }}
          initialValues={{ period: 12 }}
        >
          {formProps => (
            <AlsoOfferCreateGridFormView
              resource={resource}
              basePath={basePath}
              record={record}
              isCreateView
              {...formProps}
            />
          )}
        </Form>
      </>
    </OrderProvider>
  )
}

AlsoOfferCreate.propTypes = {
  id: PropTypes.string.isRequired,
}

export default AlsoOfferCreate
