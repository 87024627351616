import PropTypes from 'prop-types'
import React from 'react'
import { useTranslate, useQuery } from 'react-admin'
import GenericAutoSuggest from 'Components/Common/InputFields/AutoSuggest'
import Fuse from 'fuse.js'

const renderSuggestionLabel = suggestion => suggestion.name

const getSuggestionValue = suggestion => suggestion.name

const getSuggestions = (suggestions, value) => {
  const inputValue = value.trim().toLowerCase()

  const fuzzySuggestions = suggestions.map(s => ({
    ...s,
    fuzzySearchKey: `${s.field} ${s.displayName}`,
  }))

  const fuse = new Fuse(fuzzySuggestions, {
    keys: ['name'],
  })

  const results = fuse.search(inputValue)

  const groups = results.reduce((acc, { item }) => {
    const group = acc.find(group => group.title === item.field)
    if (group) {
      group.suggestions.push(item)
    } else {
      acc.push({ title: item.field, suggestions: [item] })
    }
    return acc
  }, [])

  return groups
}

const AlsoAutoSuggestsProductAutoSuggest = ({
  onChange,
  meta,
  label,
  inputProps,
  type,
}) => {
  const t = useTranslate()
  const { data: alsoProducts, loading } = useQuery({
    type: 'getList',
    resource: 'also_products',
    payload: {
      pagination: false,
      sort: false,
      filter: { type },
    },
  })

  const onSuggestionsFetchRequested = setSuggestions => ({ value }) => {
    setSuggestions(getSuggestions(alsoProducts, value))
  }

  const onSuggestionSelected = (event, { suggestion }) => {
    onChange(suggestion.id)
  }

  return (
    <GenericAutoSuggest
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionSelected={onSuggestionSelected}
      renderSuggestionLabel={renderSuggestionLabel}
      getSuggestionValue={getSuggestionValue}
      inputLabel={t(label)}
      autosuggestProps={{ multiSection: true }}
      inputProps={inputProps}
      meta={meta}
      loading={loading}
      disabled
    />
  )
}

AlsoAutoSuggestsProductAutoSuggest.propTypes = {
  meta: PropTypes.object.isRequired, // eslint-disable-line
  onChange: PropTypes.func.isRequired,
}

export default AlsoAutoSuggestsProductAutoSuggest
