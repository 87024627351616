import { Button } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import ButtonLabel from './ButtonLabel'

const CreateInformationTabButton = ({ type, reference, label }) => {
  const t = useTranslate()
  const linkParams = {
    reference,
  }
  return (
    <Button
      color="primary"
      component={Link}
      to={`/${type}/create?source=${JSON.stringify(linkParams)}`}
    >
      <Add />
      <ButtonLabel>{t(label)}</ButtonLabel>
    </Button>
  )
}

CreateInformationTabButton.propTypes = {
  type: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  label: PropTypes.string,
}

CreateInformationTabButton.defaultProps = {
  label: 'actions.add_information_tab',
}

export default CreateInformationTabButton
