import EditToolbar from 'Components/Common/EditToolbar'
import TranslateTitle from 'Components/Common/TranslateTitle'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import get from 'lodash/get'
import React from 'react'
import {
  Edit,
  NumberInput,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
} from 'react-admin'
import SubscriptionsField from 'Resources/Also/order_lines/SubscriptionsField'
import WorkplaceServicesDisplay from 'Resources/Also/order_lines/WorkplaceServicesDisplay'
import { validateRequired } from 'Resources/Aluxo/devices'
import { EditActions } from 'Resources/Aluxo/OrderLines/Common/Actions'
import WorkplaceConfigurationDisplay from './WorkplaceConfigurationDisplay'
import WorkplaceConfigurationEdit from './WorkplaceConfigurationEdit'

export const WorkplaceOrderLineEdit = props => (
  <Edit
    title={<TranslateTitle title={`resources.${props.resource}.title.edit`} />}
    actions={
      <EditActions
        backLinks={data => [
          {
            link: {
              pathname: `/also_orders/${get(data, 'order.id')}`,
            },
          },
        ]}
      />
    }
    {...props}
  >
    <SimpleForm toolbar={<EditToolbar />} redirect={false}>
      <WorkplaceConfigurationEdit
        fullWidth
        label="resources.also_workplace_order_lines.fields.workplaceConfiguration"
      />
      <WorkplaceServicesDisplay label="resources.also_workplace_order_lines.fields.workplaceServices" />
      <NumberInput source="quantity" validate={validateRequired} />
      <SelectInput
        source="term"
        choices={[18, 24, 36, 48].map(term => ({
          id: term,
          name: term,
        }))}
        validate={validateRequired}
      />
      <CurrencyField source="monthlyRate" />
      <SubscriptionsField source="subscriptions" />
    </SimpleForm>
  </Edit>
)

export const WorkplaceOrderLineShow = props => (
  <Edit
    title={<TranslateTitle title={`resources.${props.resource}.title.edit`} />}
    actions={
      <EditActions
        hideDelete
        backLinks={data => [
          {
            link: {
              pathname: `/also_orders/${get(data, 'order.id')}`,
            },
          },
        ]}
      />
    }
    {...props}
  >
    <SimpleShowLayout>
      <WorkplaceConfigurationDisplay label="resources.also_workplace_order_lines.fields.workplaceConfiguration" />{' '}
      <WorkplaceServicesDisplay label="resources.also_workplace_order_lines.fields.workplaceServices" />
      <TextField source="quantity" />
      <TextField source="term" />
      <CurrencyField source="monthlyRate" />
      <SubscriptionsField source="subscriptions" />
    </SimpleShowLayout>
  </Edit>
)
