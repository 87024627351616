import { get, remove } from 'lodash'
import { useContext, useMemo } from 'react'
import { GSALES_CUSTOMER_ID } from 'Lib/constants'
import { ResellerContext } from 'ResellerContext'
import { useDebounce } from 'use-debounce'
import { useQuery } from 'react-query'
import useCanceableDataProvider from 'Hooks/useCanceableQuery'

const FetchData = ({ children, searchValue }) => {
  const { flags } = useContext(ResellerContext)

  const canceableDataProvider = useCanceableDataProvider()

  const [searchQuery] = useDebounce(searchValue, 400, {
    leading: false,
  })

  const queriesActive = searchQuery.length > 2

  const platformCustomersQuery = useQuery(
    ['customers', searchQuery],
    () => {
      const [promise, cancel] = canceableDataProvider('getList', 'customers', {
        pagination: false,
        sort: false,
        filter: {
          search: searchQuery,
        },
      })

      const resPromise = promise.then(res => res.data)

      resPromise.cancel = cancel
      return resPromise
    },

    {
      enabled: queriesActive,
      keepPreviousData: true,
      initialData: [],
    },
  )

  const gSalesCustomersQuery = useQuery(
    ['gsales_customers', searchQuery],
    () => {
      const [promise, cancel] = canceableDataProvider(
        'getList',
        'gsales_customers',
        {
          pagination: false,
          sort: false,
          filter: {
            search: searchQuery,
          },
        },
      )

      const resPromise = promise.then(res => res.data)

      resPromise.cancel = cancel
      return resPromise
    },
    {
      enabled: flags.createOrder && queriesActive,
      keepPreviousData: true,
      initialData: [],
    },
  )
  const platformCustomers = platformCustomersQuery.data

  const gSalesCustomers = useMemo(() => {
    const gSalesCustomers = gSalesCustomersQuery.data

    platformCustomers.forEach(customer => {
      const gSalesCustomerId = get(customer, `fields.${GSALES_CUSTOMER_ID}`)

      if (gSalesCustomerId) {
        remove(gSalesCustomers, c => Number(c.id) === Number(gSalesCustomerId))
      }
    })

    return gSalesCustomers
  }, [gSalesCustomersQuery.data, platformCustomers])

  const loading =
    platformCustomersQuery.isFetching || gSalesCustomersQuery.isFetching

  const data = [...platformCustomers, ...gSalesCustomers]

  return children({ data, loading })
}

export default FetchData
