import { TableCell, TableRow, Tooltip, Typography } from '@material-ui/core'
import { orderLineReferences } from 'Components/Aluxo/OrderLines/OrderLinesContainer'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { Field } from 'react-final-form'
import { useToggle } from 'react-use'
import { get, startsWith } from 'lodash'
import currencyText from 'Lib/currencyText'
import { ResellerContext } from 'ResellerContext'
import { useTranslate } from 'react-admin'
import OrderLineQuantity from '../OrderLineQuantity'
import StyledCell from './StyledCell'

const DefaultLine = ({
  pos,
  sku,
  name,
  netPrice,
  quantity,
  margin,
  defaultMargin,
  discount,
  manufacturer,
  hasSerialNumbers,
  price,
  stock,
  // eslint-disable-next-line no-unused-vars
  deviceOrderLineDiscount,
  puid,
  actions,
  bundled,
  style,
  itScopeProductType,
  creditGroup,
  dllBrandName,
  dllAssetType,
  referenceString,
  id,
  '@type': type,
  mode,
}) => {
  const [edit, _toggle] = useToggle(false)
  const { flags } = useContext(ResellerContext)
  const t = useTranslate()

  const absoluteDeviceOrderLineDiscount = get(
    deviceOrderLineDiscount,
    'absoluteDiscount',
    0,
  )

  const displayedName = startsWith(name, manufacturer) ? (
    name
  ) : (
    <div>
      <Typography variant="body2" component="span" color="textSecondary">
        {manufacturer}
      </Typography>{' '}
      {name}
    </div>
  )

  const displayedNameCellContent = (
    <div>
      {displayedName}
      {referenceString ? (
        <Typography variant="body2" component="div" color="textSecondary">
          {t('orderLines.fields.referenceString', {
            referenceString,
          })}
        </Typography>
      ) : (
        ''
      )}
    </div>
  )

  return (
    <TableRow style={style}>
      <StyledCell bundled={bundled}>{pos}</StyledCell>
      <StyledCell bundled={bundled}>
        <Tooltip title={<span>ITScope ID: {puid}</span>}>
          <span>{sku}</span>
        </Tooltip>
      </StyledCell>
      <StyledCell bundled={bundled}>
        {mode === 'general' && flags.multipleFinancePartners ? (
          <Tooltip
            title={
              <span>
                <div>
                  {t('orderLines.fields.itScopeProductType', {
                    itScopeProductType: itScopeProductType || '',
                  })}
                </div>
                <div>
                  {t('orderLines.fields.creditGroup', {
                    creditGroup: creditGroup || '',
                  })}
                </div>
                <div>
                  {t('orderLines.fields.dllBrandName', {
                    dllBrandName: dllBrandName || '',
                  })}
                </div>
                <div>
                  {t('orderLines.fields.dllAssetType', {
                    dllAssetType: dllAssetType || '',
                  })}
                </div>
                <div>
                  {t(`orderLines.fields.hasSerialNumbers.${hasSerialNumbers}`)}
                </div>
              </span>
            }
          >
            <span>{displayedNameCellContent}</span>
          </Tooltip>
        ) : (
          <span>{displayedNameCellContent}</span>
        )}
      </StyledCell>
      <TableCell align="right">
        {edit ? (
          <Field
            name={`${orderLineReferences[type]}.data.${id}.quantity`}
            component="input"
          />
        ) : (
          <OrderLineQuantity
            stock={stock}
            quantity={quantity}
            puid={puid}
            sku={sku}
          />
        )}
      </TableCell>
      {mode === 'general' ? (
        <>
          <StyledCell align="right" bundled={bundled}>
            <Tooltip
              title={
                <span>
                  {t('orderLines.fields.purchasePriceUndiscounted', {
                    price: currencyText(price),
                  })}
                </span>
              }
            >
              <span>
                {currencyText(price - absoluteDeviceOrderLineDiscount)}
              </span>
            </Tooltip>
          </StyledCell>
          <StyledCell align="right" bundled={bundled}>
            {edit ? (
              <Field
                name={`${orderLineReferences[type]}.data.${id}.netPrice`}
                component="input"
              />
            ) : (
              currencyText(netPrice)
            )}
          </StyledCell>
          <StyledCell align="right" bundled={bundled}>
            {currencyText(netPrice * quantity)}
          </StyledCell>
          <StyledCell align="right" bundled={bundled}>
            {edit ? (
              <Field
                name={`${orderLineReferences[type]}.data.${id}.margin`}
                component="input"
              />
            ) : (
              <Tooltip
                title={
                  <span>
                    {t('orderLines.fields.defaultMargin', {
                      defaultMargin,
                    })}
                  </span>
                }
              >
                <span>{margin && `${margin}%`}</span>
              </Tooltip>
            )}
          </StyledCell>
          <StyledCell align="right" bundled={bundled}>
            {margin &&
              currencyText(
                quantity *
                  (price - absoluteDeviceOrderLineDiscount) *
                  (margin / 100),
              )}
          </StyledCell>

          <StyledCell align="right" bundled={bundled}>
            {discount ? `-${currencyText(discount)}` : currencyText(0)}
          </StyledCell>
        </>
      ) : (
        <>
          <StyledCell bundled={bundled}>{itScopeProductType}</StyledCell>
          <StyledCell bundled={bundled}>{creditGroup}</StyledCell>
          <StyledCell bundled={bundled}>{dllAssetType}</StyledCell>
          <StyledCell bundled={bundled}>{dllBrandName}</StyledCell>
          <StyledCell bundled={bundled}>
            {t(`orderLines.fields.values.hasSerialNumbers.${hasSerialNumbers}`)}
          </StyledCell>
        </>
      )}
      <StyledCell bundled={bundled}>{actions}</StyledCell>
    </TableRow>
  )
}

DefaultLine.propTypes = {
  id: PropTypes.string.isRequired,
  '@type': PropTypes.string.isRequired,
  pos: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  netPrice: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  margin: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  deviceOrderLineDiscount: PropTypes.object,
  stock: PropTypes.number.isRequired,
  puid: PropTypes.string.isRequired,
  actions: PropTypes.node.isRequired,
  bundled: PropTypes.bool,
  style: PropTypes.object,
}

DefaultLine.defaultProps = {
  deviceOrderLineDiscount: {},
  style: {},
  bundled: false,
}

export default DefaultLine
