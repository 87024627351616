import React from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import { Divider, List, ListSubheader } from '@material-ui/core'
import SortableItem from './SortableItem'
import { useTranslate } from 'react-admin'

const SortableList = ({
  items,
  moveItem,
  sortProperty,
  groupProperty,
  displayProperty,
  resource,
}) => {
  const translate = useTranslate()

  if (groupProperty) {
    const grouped = groupBy(items, groupProperty)

    return (
      <div>
        {map(grouped, (collection, key) => (
          <div key={key}>
            <List>
              <ListSubheader>
                {translate(`resources.${resource}.${groupProperty}.${key}`, {
                  _: key,
                })}
              </ListSubheader>
              {sortBy(collection, sortProperty).map(item => (
                <SortableItem
                  key={item.id}
                  length={collection.length}
                  item={item}
                  moveItem={moveItem}
                  sortProperty={sortProperty}
                  displayProperty={displayProperty}
                />
              ))}
            </List>
            <Divider />
          </div>
        ))}
      </div>
    )
  }

  return (
    <List>
      {sortBy(items, sortProperty).map(item => (
        <SortableItem
          key={item.id}
          length={items.length}
          item={item}
          moveItem={moveItem}
          sortProperty={sortProperty}
          displayProperty={displayProperty}
        />
      ))}
    </List>
  )
}

SortableList.propTypes = {
  groupProperty: PropTypes.string,
  items: PropTypes.array.isRequired,
  moveItem: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
  sortProperty: PropTypes.string.isRequired,
  displayProperty: PropTypes.string,
}

SortableList.defaultProps = {
  groupProperty: null,
  displayProperty: 'name',
}

export default SortableList
