import GenericRestClientButton from 'Components/Common/ActionButtons/GenericRestClientButton'
import PropTypes from 'prop-types'
import React from 'react'
import { useApiClient } from 'Rest/fetchHydra'

const UpdateCNetMetaDataButton = ({ data }) => {
  const apiClient = useApiClient()
  if (!data) {
    return null
  }

  const handleClick = async () => {
    await apiClient({
      endpoint: '/also/read_cnet_metadata',
      method: 'POST',
      body: { productVariant: data.id },
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  return (
    <GenericRestClientButton type="updateCNetMetaData" onClick={handleClick} />
  )
}

UpdateCNetMetaDataButton.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
}

UpdateCNetMetaDataButton.defaultProps = {
  data: null,
}

export default UpdateCNetMetaDataButton
