import { ListItemIcon, ListItemText } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import MUIMenu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import { ResellerContext } from 'ResellerContext'
import {
  Computer,
  Dashboard,
  EuroSymbol,
  Settings,
  LocalShipping,
} from '@material-ui/icons'
import UserIcon from '@material-ui/icons/People'
import BookmarksIcon from '@material-ui/icons/Bookmarks'
import PropTypes from 'prop-types'
import React, { createElement, useContext } from 'react'
import { MenuItemLink } from 'react-admin'
import WebsiteDataSubmenu from '../../../WebsiteDataSubmenu'
import CustomSettingsMenuItem from '../../Common/Menu/CustomSettingsMenuItem'
import SettingsMenuItem from '../../Common/Menu/SettingsMenuItem'
import {
  TranslatedResourceName,
  TranslatedString,
} from '../../Common/Layout/MenuTranslations'
import SubMenu from '../../Common/Layout/SubMenu'
import { useMenuStyles } from '../../Common/Layout/Menu'

const Menu = ({ onMenuClick, permissions }) => {
  const classes = useMenuStyles()

  const { flags } = useContext(ResellerContext)
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <div>
        <MenuItemLink
          to="/"
          primaryText={<TranslatedResourceName resource="dashboard" />}
          onClick={onMenuClick}
          leftIcon={createElement(Dashboard)}
        />
        <Divider />
        <MenuItemLink
          to="/also_orders"
          primaryText={<TranslatedResourceName resource="also_orders" />}
          onClick={onMenuClick}
          leftIcon={createElement(EuroSymbol)}
        />
        <Divider />
        <MenuItemLink
          to="/also_reseller_products"
          primaryText={
            <TranslatedResourceName resource="also_reseller_products" />
          }
          onClick={onMenuClick}
          leftIcon={createElement(Computer)}
        />

        {flags.alsoServices && (
          <MenuItemLink
            to="/also_reseller_services"
            primaryText={
              <TranslatedResourceName resource="also_reseller_services" />
            }
            onClick={onMenuClick}
            leftIcon={createElement(Computer)}
          />
        )}
        {flags.alsoResellerCustomServices && (
          <MenuItemLink
            to="/also_reseller_custom_services"
            primaryText={
              <TranslatedResourceName resource="also_reseller_custom_services" />
            }
            onClick={onMenuClick}
            leftIcon={createElement(Computer)}
          />
        )}
        <Divider />
        <MenuItemLink
          to="/also_customers"
          primaryText={<TranslatedResourceName resource="also_customers" />}
          onClick={onMenuClick}
          leftIcon={createElement(UserIcon)}
        />
        <Divider />
        <WebsiteDataSubmenu onMenuClick={onMenuClick} />
        <CustomSettingsMenuItem type="acmpSettings" icon={<LocalShipping />} />
        <Divider />
        <MenuItemLink
          to="/knowledge_items"
          primaryText={<TranslatedResourceName resource="knowledge_items" />}
          onClick={onMenuClick}
          leftIcon={createElement(BookmarksIcon)}
        />
      </div>

      <div>
        <Divider />
        <SubMenu
          mainItem={set => (
            <Tooltip
              title={<TranslatedString i18nkey="menu.settings.name" />}
              placement="right"
            >
              <MenuItem onClick={({ currentTarget }) => set(currentTarget)}>
                <ListItemIcon className={classes.listItemIcon}>
                  <Settings />
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={<TranslatedString i18nkey="menu.settings.name" />}
                  className={classes.listItemText}
                />
              </MenuItem>
            </Tooltip>
          )}
        >
          {(target, hide) => (
            <MUIMenu anchorEl={target} open={Boolean(target)} onClose={hide}>
              <SettingsMenuItem />
              <CustomSettingsMenuItem type="smtpConfiguration" />
              {permissions.includes('ROLE_STOREVIEW') ? (
                <CustomSettingsMenuItem type="corporateIdentity" />
              ) : (
                <CustomSettingsMenuItem type="defaultStoreView" />
              )}
            </MUIMenu>
          )}
        </SubMenu>
      </div>
    </div>
  )
}

Menu.propTypes = {
  onMenuClick: PropTypes.func,
}

Menu.defaultProps = {
  onMenuClick: () => null,
}

export default Menu
