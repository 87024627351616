import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CompanyShortInput from 'Components/Aluxo/InputFields/CompanyShortInput'
import ProcessOfferActions from 'Components/Aluxo/Offers/Actions/ProcessOfferActions'
import OfferGeneralEdit from 'Components/Aluxo/Offers/OfferGeneralEdit'
import OfferLinesEdit from 'Components/Aluxo/Offers/OfferLinesEdit'
import ChooseAddressSetDialog from 'Components/Common/Orders/ChooseAddressSetDialog'
import OrderAttachments from 'Components/Common/Orders/OrderAttachments'
import OrderDiscountList from 'Components/Common/Orders/OrderDiscountList'
import OrderEditActions from 'Components/Common/Orders/OrderEditActions'
import { useOrderEditStyles } from 'Components/Common/Orders/OrderEditGridFormView'
import OrderEditToolbar from 'Components/Common/Orders/OrderEditToolbar'
import OrderFlagsShow from 'Components/Common/Orders/OrderFlagsShow'
import StatusBarsContainer from 'Components/Common/Orders/StatusBars/StatusBarsContainer'
import TranslatedTitle from 'Components/Common/TranslatedTitle'
import useStockRefresh from 'Hooks/useStockRefresh'

import {
  manualTransitions,
  offerFlagTypes,
  offerMarkings,
} from 'Lib/aluxo/markings'
import { isReseller } from 'Lib/helper'
import legalForms from 'Lib/legalForms'
import { get } from 'lodash'
import moment from 'moment'
import OrderProvider from 'OrderContext'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import {
  AutocompleteInput,
  CardContentInner,
  EditContextProvider,
  FormInput,
  RadioButtonGroupInput,
  required,
  SelectInput,
  TextInput,
  useEditController,
  usePermissions,
} from 'react-admin'
import { Form } from 'react-final-form'
import styled from 'styled-components/macro'

moment.locale('de')

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;

  grid-template-areas:
    'status status'
    'general  actions'
    'general  flags'
    'billing shipping';
`

export const Inputs = ({ children, basePath, record, resource }) =>
  React.Children.map(children, input => (
    <FormInput
      basePath={basePath}
      input={input}
      record={record}
      resource={resource}
    />
  ))

const OfferEdit = props => {
  const controllerProps = useEditController(props)
  const { resource, record, save, basePath, version } = controllerProps

  const handleSubmit = values => {
    save(values, false)
  }

  // useStockRefresh(record)

  const [mode, setMode] = React.useState('general')

  return (
    <EditContextProvider value={controllerProps}>
      <OrderProvider record={record} basePath={basePath} resource={resource}>
        <Form
          key={version}
          initialValues={{
            ...record,
            offerValidUntil:
              get(record, 'offerValidUntil') ||
              moment()
                .add(14, 'days')
                .format('YYYY-MM-DD'),
            period: get(record, 'period') || 36,
          }}
          onSubmit={handleSubmit}
          // keepDirtyOnReinitialize
        >
          {formProps => (
            <GridFormView
              resource={resource}
              basePath={basePath}
              record={record}
              {...formProps}
              mode={mode}
              setMode={setMode}
            />
          )}
        </Form>
      </OrderProvider>
    </EditContextProvider>
  )
}

OfferEdit.propTypes = {
  id: PropTypes.string.isRequired,
}

const GridFormView = ({
  invalid,
  pristine,
  handleSubmit,
  submitting,
  resource,
  basePath,
  record,
  mode,
  setMode,
}) => {
  const classes = useOrderEditStyles()

  const { permissions = [] } = usePermissions()

  const Toolbar = React.cloneElement(<OrderEditToolbar />, {
    basePath,
    handleSubmitWithRedirect: handleSubmit,
    handleSubmit,
    invalid,
    pristine,
    record,
    redirect: false,
    resource,
    saving: submitting,
    submitOnEnter: false,
    undoable: true,
    permitted: isReseller(permissions),
  })

  const billingAddressDisabled = record.gSalesCustomerExists

  const billingAddressFieldRequired = useMemo(
    () => (record.gSalesCustomerExists ? null : [required()]),
    record.gSalesCustomerExists,
  )

  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <div style={{ gridArea: 'status', minHeight: '20px' }}>
          <StatusBarsContainer
            record={record}
            markings={offerMarkings}
            orderType="orders"
          />
        </div>
        <Card
          className={classes.card} // eslint-disable-line react/prop-types
          style={{
            gridArea: 'general',
          }}
        >
          <CardContent
            className={classes.cardContent} // eslint-disable-line react/prop-types
          >
            <CardContentInner>
              <OrderEditActions />
            </CardContentInner>
            <CardContentInner>
              <OfferGeneralEdit />
            </CardContentInner>
            {Toolbar}
          </CardContent>
        </Card>
        {isReseller(permissions) && (
          <Card
            className={classes.card}
            style={{
              gridArea: 'actions',
            }}
          >
            <CardContent className={classes.cardContent}>
              <CardContentInner>
                <TranslatedTitle translateKey="resources.orders.tabs.process" />
                <ProcessOfferActions
                  orderType="orders"
                  manualTransitions={manualTransitions}
                  record={record}
                  permitted={isReseller(permissions)}
                />
              </CardContentInner>
            </CardContent>
          </Card>
        )}
        <div
          style={{
            gridArea: 'flags',
          }}
        >
          <OrderFlagsShow orderType="orders" flagTypes={offerFlagTypes} />
        </div>
        <Card className={classes.card} style={{ gridArea: 'billing' }}>
          <CardContent className={classes.cardContent}>
            <CardContentInner>
              <Inputs resource={resource} basePath={basePath} record={record}>
                <TranslatedTitle translateKey="resources.orders.fields.billingAddress.name" />

                <SelectInput
                  source="billingAddress.salutation"
                  choices={[
                    { id: 'Frau', name: 'Frau' },
                    { id: 'Herr', name: 'Herr' },
                  ]}
                  disabled={billingAddressDisabled}
                />
                <TextInput
                  source="billingAddress.firstName"
                  disabled={billingAddressDisabled}
                />
                <TextInput
                  source="billingAddress.lastName"
                  disabled={billingAddressDisabled}
                />
                <TextInput
                  source="billingAddress.company"
                  disabled={billingAddressDisabled}
                  validate={billingAddressFieldRequired}
                />
                <AutocompleteInput
                  source="billingAddress.legalForm"
                  choices={legalForms.map(f => ({ id: f, name: f }))}
                  options={{ disabled: billingAddressDisabled }}
                />
                <CompanyShortInput disabled={billingAddressDisabled} />
                <TextInput
                  source="billingAddress.street"
                  disabled={billingAddressDisabled}
                  validate={billingAddressFieldRequired}
                />
                <TextInput
                  source="billingAddress.postcode"
                  disabled={billingAddressDisabled}
                  validate={billingAddressFieldRequired}
                />
                <TextInput
                  source="billingAddress.city"
                  disabled={billingAddressDisabled}
                  validate={billingAddressFieldRequired}
                />
                <TextInput
                  source="billingAddress.phone"
                  disabled={billingAddressDisabled}
                />
                <TextInput
                  source="billingAddress.email"
                  disabled={billingAddressDisabled}
                  validate={billingAddressFieldRequired}
                />
              </Inputs>
            </CardContentInner>
            {Toolbar}
          </CardContent>
        </Card>

        <Card className={classes.card} style={{ gridArea: 'shipping' }}>
          <CardContent className={classes.cardContent}>
            <CardContentInner>
              <Inputs resource={resource} basePath={basePath} record={record}>
                <TranslatedTitle translateKey="resources.orders.fields.contactAddress.name" />

                <SelectInput
                  source="contactAddress.salutation"
                  choices={[
                    { id: 'Frau', name: 'Frau' },
                    { id: 'Herr', name: 'Herr' },
                  ]}
                />
                <TextInput
                  source="contactAddress.firstName"
                  validate={required()}
                />
                <TextInput
                  source="contactAddress.lastName"
                  validate={required()}
                />
                <TextInput
                  source="contactAddress.email"
                  validate={required()}
                />

                <TextInput
                  source="contactAddress.ccEmail"
                  multiline
                  fullWidth
                  label="resources.customers.fields.addresses.ccEmail"
                  format={arr => (arr ?? []).join('\n')}
                  parse={value => value.split(/[\n,;]+/)}
                />

                <RadioButtonGroupInput
                  fullWidth
                  source="contactAddress.emailType"
                  choices={[
                    {
                      id: 'formal',
                      name: 'resources.customers.emailTypes.formal',
                    },
                    {
                      id: 'informal',
                      name: 'resources.customers.emailTypes.informal',
                    },
                    {
                      id: 'personal',
                      name: 'resources.customers.emailTypes.personal',
                    },
                  ]}
                />
              </Inputs>
              <ChooseAddressSetDialog
                customerId={record?.customer?.id}
                orderId={record?.id}
              />
            </CardContentInner>
            {Toolbar}
          </CardContent>
        </Card>

        <OfferLinesEdit
          mode={mode}
          setMode={setMode}
          style={{ gridColumnStart: 'span 2' }}
        />

        {get(record, 'deviceLines', []).find(
          v => v.deviceOrderLineDiscount,
        ) && (
          <Card className={classes.card} style={{ gridColumn: '1 / -1' }}>
            <CardContent className={classes.cardContent}>
              <CardContentInner>
                <TranslatedTitle
                  translateKey="resources.discounts.cas.name"
                  smartCount={2}
                />
                <OrderDiscountList record={record} />
              </CardContentInner>
            </CardContent>
          </Card>
        )}

        <OrderAttachments style={{ gridColumn: '1 / -1;' }} />
      </Grid>
    </form>
  )
}

export default OfferEdit
