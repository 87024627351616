import React from 'react'
import PropTypes from 'prop-types'
import {
  BooleanField,
  BooleanInput,
  Create,
  CreateButton,
  Datagrid,
  Edit,
  EditButton,
  List,
  NumberField,
  NumberInput,
  translate,
  RefreshButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  FileInput,
  FileField,
} from 'react-admin'
import { get } from 'lodash'
import { CardActions } from '@material-ui/core'
import { Pageview } from '@material-ui/icons'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import NumberWithUnitInput from 'Components/Common/InputFields/NumberWithUnitInput'
import EditToolbar from 'Components/Common/EditToolbar'
import EditActions from 'Components/Common/EditActions'
import PercentageField from 'Components/Common/ViewFields/PercentageField'
import DownloadActions from 'Components/Common/DownloadActions'
import { validateRequired } from './devices'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

const ResellableProtectionPlanActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </CardActions>
)

ResellableProtectionPlanActions.propTypes = {
  basePath: PropTypes.string.isRequired,
}

export const ResellableProtectionPlanList = props => (
  <List
    {...props}
    pagination={<CustomPagination />}
    actions={<ResellableProtectionPlanActions />}
  >
    <Datagrid>
      <TextField source="sku" />
      <NumberField source="duration" />
      <BooleanField source="theftProtection" />
      <CurrencyField source="amount" />
      <CurrencyField source="price" />
      <PercentageField source="margin" />
      <BooleanField source="generic" />
      <EditButton />
    </Datagrid>
  </List>
)

const Title = ({ record }) => get(record, 'sku')

export const DownloadActionsControl = ({ record }) => {
  if (!record.fileName) {
    return null
  }

  return (
    <DownloadActions
      id={get(record, 'id')}
      source="resellable_protection_plan"
      buttonText="protectionPlans.preview"
      icon={<Pageview />}
    />
  )
}

DownloadActionsControl.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fileName: PropTypes.string,
  }).isRequired,
}

export const ResellableProtectionPlanEdit = props => (
  <Edit title={<Title />} actions={<EditActions />} {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <SelectInput
        source="duration"
        choices={[
          { id: 24, name: '24' },
          { id: 36, name: '36' },
        ]}
      />
      <TextInput source="sku" />
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="theftProtection"
      />
      <NumberWithUnitInput source="amount" />
      <NumberWithUnitInput source="margin" unit="%" />
      <NumberWithUnitInput source="price" />
      <CurrencyField source="netPrice" />

      <DownloadActionsControl style={{ marginTop: '20px' }} />
      <FileInput
        source="file"
        label={translate('protectionPlans.serviceOverview')}
        accept="application/pdf"
      >
        <FileField source="file" title="title" />
      </FileInput>
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="generic"
      />
    </SimpleForm>
  </Edit>
)

const defaultValues = {
  duration: 24,
  amount: 0,
  price: 0,
  margin: 0,
}

export const ResellableProtectionPlanCreate = props => (
  <Create {...props}>
    <SimpleForm defaultValue={defaultValues}>
      <NumberInput source="duration" validate={validateRequired} />
      <TextInput source="sku" />
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="theftProtection"
      />
      <NumberWithUnitInput source="amount" />
      <NumberWithUnitInput source="price" />
      <NumberWithUnitInput source="margin" unit="%" />
      <FileInput
        source="file"
        label={translate('protectionPlans.serviceOverview')}
        accept="application/pdf"
      >
        <FileField source="file" title="title" />
      </FileInput>
      <BooleanInput
        options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
        source="generic"
      />
    </SimpleForm>
  </Create>
)
