import { put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { CRUD_CREATE_SUCCESS } from 'react-admin'

function* createNewDeviceVariant(action) {
  const {
    meta: { resource },
    payload: {
      data: { id },
    },
  } = action
  const linkParams = {
    device: `/api/devices/${id}`,
  }
  if (resource === 'devices') {
    yield put(push(`/device_variants/?source=${JSON.stringify(linkParams)}`))
  }
}

export default function* createDeviceSaga() {
  yield takeLatest(CRUD_CREATE_SUCCESS, createNewDeviceVariant)
}
