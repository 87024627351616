import BackButton from 'Components/Common/Buttons/BackButton'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { getLinkFromIri } from 'Lib/helper'

const BackToDeviceButton = ({ data, device }) => {
  const deviceIri = device || get(data, 'device[@id]') || get(data, 'device')
  return <BackButton to={getLinkFromIri(deviceIri)} />
}

BackToDeviceButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  device: PropTypes.string,
}

BackToDeviceButton.defaultProps = {
  data: null,
  device: null,
}

export default BackToDeviceButton
