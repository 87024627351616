import { getLinkFromIri } from 'Lib/helper'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import BackButton from 'Components/Common/Buttons/BackButton'

const BackToAlsoProductButton = ({ data, product }) => {
  const productIri =
    product || get(data, 'product[@id]') || get(data, 'product')
  return <BackButton to={getLinkFromIri(productIri)} />
}

BackToAlsoProductButton.propTypes = {
  productIri: PropTypes.string,
}

export default BackToAlsoProductButton
