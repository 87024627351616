import {
  useLoading,
  useTranslate,
  useNotify,
  useVersion,
  useDataProvider,
  useMutation,
  TopToolbar,
  EditButton,
} from 'react-admin'
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  LinearProgress,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
} from '@material-ui/core'
import React, { useState, useEffect, useContext, useMemo } from 'react'
import moment from 'moment'
import Button from 'Components/Common/Buttons/Button'
import { ResellerContext } from 'ResellerContext'
import { orderBy } from 'lodash'
import TextField from '@material-ui/core/TextField'
import Fuse from 'fuse.js'
import { useDebounce } from 'use-debounce'

const AlsoResellerProductList = () => {
  const loading = useLoading()
  const t = useTranslate()
  const showNotification = useNotify()
  const version = useVersion()
  const dataProvider = useDataProvider()
  const [mutate] = useMutation()

  const [products, setProducts] = useState([])
  const [resellerProducts, setResellerProducts] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const [searchQuery] = useDebounce(searchValue, 400, {
    leading: false,
  })

  const { flags } = useContext(ResellerContext)

  const newDateMarker = moment().subtract(3, 'months')

  useEffect(() => {
    const refreshData = async () => {
      const resellerProducts = await dataProvider.getList(
        'also_reseller_products',
        {
          pagination: false,
          sort: false,
          filter: false,
        },
      )

      const products = await dataProvider.getList('also_products', {
        pagination: false,
        sort: { field: 'name', order: 'ASC' },
        filter: false,
      })

      setResellerProducts(resellerProducts.data || [])

      setProducts(products.data?.filter(p => p.active && !p.autoDisabled) || [])
    }

    refreshData()
  }, [version])

  const rows = useMemo(() => {
    if (products.length > 0) {
      return orderBy(
        products,
        ['type', 'manufacturer', 'name'],
        ['desc', 'asc', 'asc'],
      ).map(row => ({
        ...row,
        new: moment(row.createdAt).isAfter(newDateMarker),
        resellerProduct: resellerProducts.find(r => r.product === row['@id']),
      }))
    }
    return []
  }, [products, resellerProducts])

  const handleUpdateItem = (type, data) => {
    setResellerProducts(products => {
      if (type === 'delete') {
        return products.filter(s => s.id !== data.id)
      }

      return products.concat({ ...data, product: data.product['@id'] })
    })
  }

  const handleToggle = ({ target: { value: id } }, value) => {
    const type = value ? 'create' : 'delete'
    const resource = 'also_reseller_products'
    const payload = value ? { data: { product_reference_id: id } } : { id }

    mutate(
      {
        type,
        resource,
        payload,
      },
      {
        onSuccess: res => {
          showNotification(`notifications.alsoResellerProduct.${type}.success`)
          handleUpdateItem(type, res.data.id ? res.data : { id })
        },
        onFailure: () => {
          showNotification(
            `notifications.alsoResellerProduct.${type}.fail`,
            'warning',
          )
        },
      },
    )
  }

  const handleActivateAll = async () => {
    const idsToActivate = rows.filter(p => !p.resellerProduct).map(r => r.id)

    try {
      const results = await Promise.allSettled(
        idsToActivate.map(
          id =>
            new Promise((resolve, reject) => {
              mutate(
                {
                  type: 'create',
                  resource: 'also_reseller_products',
                  payload: { data: { product_reference_id: id } },
                },
                {
                  onSuccess: res => {
                    resolve(res.data)
                  },
                  onFailure: () => {
                    reject()
                  },
                },
              )
            }),
        ),
      )

      const products = results
        .filter(r => r.status === 'fulfilled')
        .map(r => r.value)
      setResellerProducts(ps => ps.concat(...products))

      if (results.some(r => r.status === 'rejected')) {
        showNotification(
          `notifications.alsoResellerProduct.create.failSome`,
          'warning',
        )
      } else {
        showNotification(`notifications.alsoResellerProduct.create.successAll`)
      }
    } catch (e) {
      showNotification(
        `notifications.alsoResellerProduct.create.fail`,
        'warning',
      )
    }
  }

  const fuse = useMemo(
    () =>
      new Fuse(rows, {
        keys: ['sku', 'name', 'manufacturer'],
        threshold: 0.2,
      }),
    [rows],
  )

  const displayedRows = useMemo(() => {
    if (searchQuery.length <= 2) {
      return rows
    }
    return fuse.search(searchQuery).map(r => r.item)
  }, [searchQuery, rows])

  return (
    <>
      <TopToolbar>
        <Button
          onClick={handleActivateAll}
          label="actions.createAllAlsoResellerProduct"
        />
      </TopToolbar>
      <Card style={{ marginTop: '1em' }}>
        <CardHeader title={t('actions.createAlsoResellerProduct')} />
        <CardContent>
          <Typography paragraph gutterBottom>
            {t('texts.also_reseller_products.subHeader')}
          </Typography>

          {loading ? (
            <LinearProgress>Loading</LinearProgress>
          ) : (
            <TextField
              value={searchValue}
              onChange={({ target: { value } }) => setSearchValue(value)}
              label="Filtern"
            />
          )}
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('resources.also_reseller_products.fields.name')}
                </TableCell>
                {/* <TableCell> */}
                {/*  {t('resources.also_reseller_products.fields.category')} */}
                {/* </TableCell> */}
                <TableCell>
                  {t('resources.also_reseller_products.fields.manufacturer')}
                </TableCell>
                <TableCell>
                  {t('resources.also_reseller_products.fields.type')}
                </TableCell>
                <TableCell>
                  {t('resources.also_reseller_products.fields.active')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedRows.map(row => (
                <TableRow key={row.id}>
                  <TableCell>
                    {row.name}{' '}
                    {row.new && (
                      <Chip size="small" color="primary" label="Neu" />
                    )}
                  </TableCell>
                  {/* <TableCell>{row.category && row.category.name}</TableCell> */}
                  <TableCell>{row.manufacturer}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Switch
                        checked={Boolean(row.resellerProduct)}
                        onChange={handleToggle}
                        color="primary"
                        value={
                          row.resellerProduct ? row.resellerProduct.id : row.id
                        }
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    {flags.customAccessories &&
                      row.type === 'WorkplaceDevice' &&
                      row.resellerProduct && (
                        <EditButton
                          basePath="also_reseller_products"
                          record={{ id: row.resellerProduct.id }}
                        />
                      )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  )
}

export default AlsoResellerProductList
