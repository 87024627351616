import UserIcon from '@material-ui/icons/People'
import authClient from 'authApiPlatformClient'
import Login from 'Components/Common/Auth/Login'
import GenericDashboard from 'Components/Common/Dashboard/GenericDashboard'
import Layout from 'Components/Common/Layout/Layout'
import Title from 'Components/Common/Title'
import customRoutes from 'customRoutes'
import { url } from 'httpClient'
import i18nProvider from 'I18n/i18nProvider'
import {
  isAdmin,
  isAlsoAdmin,
  isAlsoEdiReseller,
  isAlsoReseller,
} from 'Lib/helper'
import React from 'react'
import { Admin, Resource } from 'react-admin'
import { ResellerProvider } from 'ResellerContext'
import { AlsoACMPSettingsEdit } from 'Resources/Also/also_acmp_settings'
import { AlsoOfferSettingsEdit } from 'Resources/Also/also_offer_settings'
import {
  AlsoCategoryCreate,
  AlsoCategoryEdit,
  AlsoCategoryList,
} from 'Resources/Also/also_categories'
import AlsoEnumMemberList from 'Resources/Also/also_enum_members'
import {
  AlsoOrderEdit,
  AlsoOrderList,
  AlsoOrderShow,
} from 'Resources/Also/also_orders'
import {
  AlsoProductCreate,
  AlsoProductEdit,
  AlsoProductList,
} from 'Resources/Also/also_products'
import {
  AlsoServiceCreate,
  AlsoServiceEdit,
  AlsoServiceList,
} from 'Resources/Also/also_services'
import { AlsoProductVariantEdit } from 'Resources/Also/also_product_variants'
import {
  AlsoResellerProductList,
  AlsoResellerProductEdit,
} from 'Resources/Also/also_reseller_products'
import { AlsoResellerServiceList } from 'Resources/Also/also_reseller_services'
import {
  WorkplaceOrderLineEdit,
  WorkplaceOrderLineShow,
} from 'Resources/Also/order_lines/workplace_order_lines'
import {
  CategoryCreate,
  CategoryEdit,
  CategoryList,
} from 'Resources/Aluxo/categories'
import { DeviceCreate, DeviceEdit, DeviceList } from 'Resources/Aluxo/devices'
import {
  DeviceVariantCreate,
  DeviceVariantEdit,
} from 'Resources/Aluxo/device_variants'
import {
  ExternalProtectionPlanCreate,
  ExternalProtectionPlanEdit,
  ExternalProtectionPlanList,
} from 'Resources/Aluxo/external_protection_plans'
import {
  ExternalProtectionPlanDeviceEdit,
  ExternalProtectionPlanDevicesList,
} from 'Resources/Aluxo/external_protection_plan_devices'
import { GsalesSettingsEdit } from 'Resources/Aluxo/gsales_settings'
import {
  DistributorEdit,
  DistributorList,
} from 'Resources/Aluxo/itscope_distributors'
import { ITScopeSettingsEdit } from 'Resources/Aluxo/itscope_settings'
import {
  ManufacturerCreate,
  ManufacturerEdit,
  ManufacturerList,
} from 'Resources/Aluxo/manufacturers'
import { OfferCreate, OfferEdit, OfferList } from 'Resources/Aluxo/offers'
import {
  OnlineServiceCreate,
  OnlineServiceEdit,
  OnlineServiceList,
} from 'Resources/Aluxo/online_services'
import { OrderEdit, OrderList } from 'Resources/Aluxo/orders'
import {
  ProductCreate,
  ProductEdit,
  ProductList,
} from 'Resources/Aluxo/products'
import {
  ProductVariantCreate,
  ProductVariantEdit,
} from 'Resources/Aluxo/product_variants'
import {
  PromoBundleCreate,
  PromoBundleEdit,
  PromoBundleList,
} from 'Resources/Aluxo/promo_bundles'
import {
  ProtectionPlanEdit,
  ProtectionPlanList,
} from 'Resources/Aluxo/protection_plans'
import {
  ResellableProtectionPlanCreate,
  ResellableProtectionPlanEdit,
  ResellableProtectionPlanList,
} from 'Resources/Aluxo/resellable_protection_plans'
import {
  ServiceExtensionCreate,
  ServiceExtensionEdit,
  ServiceExtensionList,
} from 'Resources/Aluxo/service_extensions'
import {
  BenefitCreate,
  BenefitEdit,
  BenefitList,
} from 'Resources/Common/benefits'
import {
  CustomerCreate,
  CustomerEdit,
  CustomerList,
} from 'Resources/Aluxo/customers'
import {
  AlsoCustomerCreate,
  AlsoCustomerEdit,
  AlsoCustomerList,
  AlsoCustomerShow,
} from 'Resources/Also/customers'
import {
  InformationTabCreate,
  InformationTabEdit,
} from 'Resources/Common/information_tabs'
import { LinkCreate, LinkEdit, LinkList } from 'Resources/Common/links'
import {
  MarketingLogoCreate,
  MarketingLogoEdit,
  MarketingLogoList,
} from 'Resources/Common/marketing_logos'
import { PageCreate, PageEdit, PageList } from 'Resources/Common/pages'
import {
  ResellerCreate,
  ResellerEdit,
  ResellerList,
} from 'Resources/Common/resellers'
import { CorporateIdentityEdit } from 'Resources/Common/reseller_corporate_identities'
import { SMTPConfigurationEdit } from 'Resources/Common/reseller_smtp_configurations'
import { SlideCreate, SlideEdit, SlideList } from 'Resources/Common/slides'
import {
  AdminStoreViewList,
  StoreViewCreate,
  StoreViewEdit,
  StoreViewList,
} from 'Resources/Common/store_views'
import {
  MemberCreate,
  MemberEdit,
  MemberList,
} from 'Resources/Common/team_members'
import {
  TestimonialCreate,
  TestimonialEdit,
  TestimonialList,
} from 'Resources/Common/testimonials'
import {
  KnowledgeItemCreate,
  KnowledgeItemEdit,
  KnowledgeItemList,
} from 'Resources/Common/knowledge_items'
import { EdiItemList } from 'Resources/Also/edi_items'
import {
  ResellableProductEdit,
  ResellableProductList,
} from 'Resources/Also/resellable_products'
import {
  AlsoOfferCreate,
  AlsoOfferEdit,
  AlsoOfferList,
} from 'Resources/Also/also_offers'
import dataProviderFactory from 'Rest/api'
import AlsoACMPServiceList from 'Resources/Also/also_acmp_services'
import {
  ProductAttributeValueCreate,
  ProductAttributeValueEdit,
  ProductAttributeValueList,
} from 'Resources/Common/product_attribute_values'
import {
  ProductAttributeGroupCreate,
  ProductAttributeGroupEdit,
  ProductAttributeGroupList,
} from 'Resources/Common/product_attribute_groups'
// import errorSagas from 'Sagas/errorSagas'
import { createHashHistory } from 'history'
import { Provider } from 'react-redux'
import createAdminStore from 'createAdminStore'

import { StaffConfigurationEdit } from 'Resources/Common/staff'
// import errorSagas from 'Sagas/errorSagas'

import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  AlsoResellerCustomServiceCreate,
  AlsoResellerCustomServiceEdit,
  AlsoResellerCustomServiceList,
} from 'Resources/Also/also_reseller_custom_services'

import { Provider as UrqlProvider } from 'urql'

import urqlClient from 'Lib/urqlClient'

export const dataProvider = dataProviderFactory(url)

const history = createHashHistory()

export const store = createAdminStore({
  authProvider: authClient,
  dataProvider,
  history,
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const App = () => (
  <UrqlProvider value={urqlClient}>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <ResellerProvider>
          <Admin
            title={<Title />}
            i18nProvider={i18nProvider}
            dataProvider={dataProvider}
            authProvider={authClient}
            history={history}
            dashboard={GenericDashboard}
            layout={Layout}
            loginPage={Login}
            customRoutes={customRoutes}
          >
            {permissions => {
              const alsoAdmin = isAlsoAdmin(permissions)
              const alsoReseller = isAlsoReseller(permissions)
              const alsoEdiReseller = isAlsoEdiReseller(permissions)
              const admin = isAdmin(permissions)

              return [
                <Resource
                  name="resellers"
                  list={ResellerList}
                  edit={ResellerEdit}
                  create={ResellerCreate}
                />,
                <Resource name="orders" list={OrderList} edit={OrderEdit} />,
                <Resource name="device_order_lines" />,
                <Resource name="service_order_lines" />,
                <Resource name="service_extension_order_lines" />,
                <Resource name="protection_plan_order_lines" />,
                <Resource name="product_order_lines" />,
                <Resource name="generic_order_lines" />,
                <Resource name="promo_bundle_order_lines" />,
                <Resource
                  name="store_views"
                  list={admin ? AdminStoreViewList : StoreViewList}
                  create={StoreViewCreate}
                  edit={StoreViewEdit}
                />,
                <Resource
                  name="reseller_website_configurations"
                  list={StoreViewList}
                  create={StoreViewCreate}
                  edit={StoreViewEdit}
                />,
                <Resource
                  name="manufacturers"
                  list={ManufacturerList}
                  edit={ManufacturerEdit}
                  create={ManufacturerCreate}
                />,
                <Resource
                  name="categories"
                  list={CategoryList}
                  edit={CategoryEdit}
                  create={CategoryCreate}
                />,
                <Resource
                  name="products"
                  list={ProductList}
                  edit={ProductEdit}
                  create={ProductCreate}
                />,
                <Resource
                  name="product_information_tabs"
                  edit={InformationTabEdit}
                  create={InformationTabCreate}
                />,
                <Resource
                  name="product_variants"
                  edit={ProductVariantEdit}
                  create={ProductVariantCreate}
                />,
                <Resource name="product_variant_attribute_values" />,
                <Resource
                  name="devices"
                  list={DeviceList}
                  edit={DeviceEdit}
                  create={DeviceCreate}
                />,
                <Resource
                  name="device_variants"
                  edit={DeviceVariantEdit}
                  create={DeviceVariantCreate}
                />,
                <Resource
                  name="device_information_tabs"
                  edit={InformationTabEdit}
                  create={InformationTabCreate}
                />,
                <Resource
                  name="online_services"
                  list={OnlineServiceList}
                  edit={OnlineServiceEdit}
                  create={OnlineServiceCreate}
                />,
                <Resource
                  name="managed_services"
                  list={OnlineServiceList}
                  edit={OnlineServiceEdit}
                  create={OnlineServiceCreate}
                />,
                <Resource
                  name="service_extensions"
                  list={ServiceExtensionList}
                  edit={ServiceExtensionEdit}
                  create={ServiceExtensionCreate}
                />,
                <Resource
                  name="offers"
                  list={OfferList}
                  edit={OfferEdit}
                  create={OfferCreate}
                />,
                <Resource name="reseller_monthly_prices" />,
                <Resource
                  name="promo_bundles"
                  list={PromoBundleList}
                  edit={PromoBundleEdit}
                  create={PromoBundleCreate}
                />,

                <Resource name="reseller_email_events" />,
                <Resource name="reseller_transition_events" />,
                <Resource name="reseller_order_flag_events" />,
                <Resource name="discounts" />,
                <Resource name="cas_customer_discounts" />,
                <Resource name="gsales_customers" />,
                <Resource
                  name="testimonials"
                  list={TestimonialList}
                  edit={TestimonialEdit}
                  create={TestimonialCreate}
                  // options={{ sortable: true, translatable: true }}
                />,
                <Resource
                  name="team_members"
                  list={MemberList}
                  edit={MemberEdit}
                  create={MemberCreate}
                  options={{ sortable: true }}
                />,
                <Resource
                  name="marketing_logos"
                  list={MarketingLogoList}
                  edit={MarketingLogoEdit}
                  create={MarketingLogoCreate}
                  options={{ sortable: true }}
                />,
                <Resource
                  name="benefits"
                  list={BenefitList}
                  edit={BenefitEdit}
                  create={BenefitCreate}
                  options={{ sortable: true }}
                />,
                <Resource
                  name="resellable_protection_plans"
                  list={ResellableProtectionPlanList}
                  edit={ResellableProtectionPlanEdit}
                  create={ResellableProtectionPlanCreate}
                />,
                <Resource
                  name="external_protection_plans"
                  list={ExternalProtectionPlanList}
                  edit={ExternalProtectionPlanEdit}
                  create={ExternalProtectionPlanCreate}
                />,
                <Resource
                  name="external_protection_plan_devices"
                  list={ExternalProtectionPlanDevicesList}
                  edit={ExternalProtectionPlanDeviceEdit}
                />,
                <Resource
                  name="protection_plans"
                  list={ProtectionPlanList}
                  edit={ProtectionPlanEdit}
                />,
                <Resource
                  name="pages"
                  list={PageList}
                  edit={PageEdit}
                  create={PageCreate}
                />,
                <Resource
                  name="slides"
                  list={SlideList}
                  edit={SlideEdit}
                  create={SlideCreate}
                  options={{ sortable: true }}
                />,
                <Resource
                  name="links"
                  list={LinkList}
                  edit={LinkEdit}
                  create={LinkCreate}
                  options={{ sortable: true }}
                />,
                <Resource name="itscope_settings" edit={ITScopeSettingsEdit} />,
                <Resource
                  name="reseller_smtp_configurations"
                  edit={SMTPConfigurationEdit}
                />,
                <Resource name="staff" edit={StaffConfigurationEdit} />,
                <Resource name="personal_smtp_configurations" />,
                <Resource
                  name="reseller_corporate_identities"
                  edit={CorporateIdentityEdit}
                />,
                <Resource name="gsales_settings" edit={GsalesSettingsEdit} />,
                <Resource
                  name="itscope_distributors"
                  edit={DistributorEdit}
                  list={DistributorList}
                />,
                <Resource name="order_comments" />,
                <Resource name="string_translations" />,
                <Resource name="attachments" />,
                <Resource name="order_flags" />,
                <Resource
                  name="customers"
                  list={CustomerList}
                  edit={CustomerEdit}
                  create={CustomerCreate}
                  icon={UserIcon}
                />,
                <Resource
                  name="also_customers"
                  list={AlsoCustomerList}
                  edit={alsoReseller ? AlsoCustomerEdit : null}
                  create={alsoReseller ? AlsoCustomerCreate : null}
                  show={AlsoCustomerShow}
                  icon={UserIcon}
                />,
                <Resource
                  name="also_enum_members"
                  list={alsoAdmin ? AlsoEnumMemberList : null}
                />,
                <Resource
                  name="also_acmp_services"
                  list={AlsoACMPServiceList}
                />,
                <Resource
                  name="also_acmp_settings"
                  edit={alsoReseller ? AlsoACMPSettingsEdit : null}
                />,
                <Resource
                  name="also_offer_settings"
                  edit={alsoEdiReseller ? AlsoOfferSettingsEdit : null}
                />,
                <Resource
                  name="also_categories"
                  create={alsoAdmin ? AlsoCategoryCreate : null}
                  list={alsoAdmin ? AlsoCategoryList : null}
                  edit={alsoAdmin ? AlsoCategoryEdit : null}
                />,
                <Resource
                  name="also_products"
                  create={alsoAdmin ? AlsoProductCreate : null}
                  list={alsoAdmin ? AlsoProductList : null}
                  edit={alsoAdmin ? AlsoProductEdit : null}
                />,
                <Resource
                  name="also_product_variants"
                  edit={alsoAdmin ? AlsoProductVariantEdit : null}
                />,
                <Resource
                  name="also_reseller_products"
                  list={alsoReseller ? AlsoResellerProductList : null}
                  edit={alsoReseller ? AlsoResellerProductEdit : null}
                />,
                <Resource
                  name="also_reseller_services"
                  list={alsoReseller ? AlsoResellerServiceList : null}
                />,
                <Resource
                  name="also_reseller_custom_services"
                  list={alsoReseller ? AlsoResellerCustomServiceList : null}
                  edit={alsoReseller ? AlsoResellerCustomServiceEdit : null}
                  create={alsoReseller ? AlsoResellerCustomServiceCreate : null}
                />,
                <Resource
                  name="also_orders"
                  list={AlsoOrderList}
                  edit={alsoReseller ? AlsoOrderEdit : null}
                  show={AlsoOrderShow}
                />,
                <Resource
                  name="also_product_information_tabs"
                  edit={alsoAdmin ? InformationTabEdit : null}
                  create={alsoAdmin ? InformationTabCreate : null}
                />,
                <Resource
                  name="also_product_variant_information_tabs"
                  edit={alsoAdmin ? InformationTabEdit : null}
                  create={alsoAdmin ? InformationTabCreate : null}
                />,
                <Resource
                  name="also_workplace_order_lines"
                  edit={alsoReseller ? WorkplaceOrderLineEdit : null}
                  show={alsoReseller ? WorkplaceOrderLineShow : null}
                />,
                <Resource name="also_workplace_services" />,
                <Resource name="also_workplace_reseller_custom_services" />,
                <Resource
                  name="also_services"
                  create={alsoAdmin ? AlsoServiceCreate : null}
                  list={alsoAdmin ? AlsoServiceList : null}
                  edit={alsoAdmin ? AlsoServiceEdit : null}
                />,
                <Resource name="also_acmp_service_billing_items" />,
                <Resource
                  name="knowledge_items"
                  list={KnowledgeItemList}
                  edit={alsoAdmin ? KnowledgeItemEdit : null}
                  create={alsoAdmin ? KnowledgeItemCreate : null}
                />,
                <Resource
                  name="also_edi_items"
                  list={alsoAdmin ? EdiItemList : null}
                />,
                <Resource
                  name="common_product_attribute_groups"
                  list={alsoAdmin || admin ? ProductAttributeGroupList : null}
                  edit={alsoAdmin || admin ? ProductAttributeGroupEdit : null}
                  create={
                    alsoAdmin || admin ? ProductAttributeGroupCreate : null
                  }
                />,
                <Resource
                  name="common_product_attribute_values"
                  list={alsoAdmin || admin ? ProductAttributeValueList : null}
                  edit={alsoAdmin || admin ? ProductAttributeValueEdit : null}
                  create={
                    alsoAdmin || admin ? ProductAttributeValueCreate : null
                  }
                />,
                <Resource name="common_cnet_metadata_values" />,
                <Resource name="common_cnet_metadata_groups" />,
                <Resource
                  name="also_resellable_products"
                  edit={alsoAdmin ? ResellableProductEdit : null}
                  list={alsoAdmin ? ResellableProductList : null}
                />,
                <Resource name="also_resellable_product_images" />,
                <Resource
                  name="also_offers"
                  list={alsoEdiReseller ? AlsoOfferList : null}
                  create={alsoEdiReseller ? AlsoOfferCreate : null}
                  edit={alsoEdiReseller ? AlsoOfferEdit : null}
                />,
              ]
            }}
          </Admin>
        </ResellerProvider>
      </Provider>
    </QueryClientProvider>
  </UrqlProvider>
)

export default App
