import GenericRestClientButton from 'Components/Common/ActionButtons/GenericRestClientButton'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useApiClient } from 'Rest/fetchHydra'
import { ResellerContext } from 'ResellerContext'

const UpdateCNetMetaDataButton = ({ data }) => {
  const { flags } = useContext(ResellerContext)
  const apiClient = useApiClient()
  if (!flags.cNetSpecs) {
    return null
  }

  if (!data) {
    return null
  }

  const handleClick = async () => {
    await apiClient({
      endpoint: '/aluxo/read_cnet_metadata',
      method: 'POST',
      body: { deviceVariant: data.id },
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  return (
    <GenericRestClientButton type="updateCNetMetaData" onClick={handleClick} />
  )
}

UpdateCNetMetaDataButton.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
}

UpdateCNetMetaDataButton.defaultProps = {
  data: null,
}

export default UpdateCNetMetaDataButton
