import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { useTranslate } from 'react-admin'

export default function ConfirmationDialog({ open, cancel, confirm }) {
  const t = useTranslate()
  return (
    <Dialog
      open={open}
      onClose={cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('dialogs.unsavedConfirmationDialog.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('dialogs.unsavedConfirmationDialog.content')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="primary">
          {t('actions.cancel')}
        </Button>
        <Button onClick={confirm} color="primary" autoFocus>
          {t('actions.next')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
