import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import React from 'react'
import DownloadAllAttachmentsAsArchiveButton from '../DownloadAllAttachmentsAsArchiveButton'
import DownloadAllAttachmentsButton from '../DownloadAllAttachmentsButton'

const OrderAttachmentsActions = () => (
  <RightAlignedCardActions>
    <DownloadAllAttachmentsAsArchiveButton />
    <DownloadAllAttachmentsButton />
  </RightAlignedCardActions>
)

export default OrderAttachmentsActions
