import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
} from 'react-admin'
import {
  BULK_UPDATE_ITSCOPE_METADATA,
  LOCK_ORDER,
  PROCESS_ORDER,
  SAVE_TRANSLATIONS,
  UNLOCK_ORDER,
  REFRESH_ITSCOPE_PRICES,
  ADD_PROTECTION_PLAN,
  REFRESH_ACMP_TRADING_MODEL_MARGINS,
  UPDATE_ORDER_LINES_STOCK,
  CREATE_IT_SCOPE_CART,
  CANCEL_EXTERNAL_PROTECTION_PLAN_DEVICE,
  REFRESH_ITSCOPE_DISTRIBUTORS,
  UPDATE_ITSCOPE_METADATA,
  UPDATE_ITSCOPE_PRICE,
  COPY_ACCESSORY_TO_VARIANTS,
  DOWNLOAD_ATTACHMENT,
  DOWNLOAD_ALL_ATTACHMENTS,
  RESEND_EMAIL,
  REPORTING_ORDER,
  GET_CONTEXT,
  CREATE_IT_SCOPE_ORDERS,
  CREATE_MULTIPART,
  FIND_ITSCOPE_BUNDLE,
  TEST_SMTP,
  TEST_CREDENTIALS,
  RESET_CUSTOMER_PASSWORD,
  DUPLICATE_AS_OFFER,
  ALSO_ADD_EDI_ITEM_TO_PORTFOLIO,
  ALSO_UPDATE_RESELLABLE_PRODUCT,
  ASSIGN_CUSTOMER_TO_ORDER,
  REFRESH_PRICES,
} from 'Rest/actions'
import convertHydraResponseToReactAdminResponse from 'Rest/convertHydraResponseToReactAdminResponse'
import convertReactAdminRequestToHydraRequest from 'Rest/convertReactAdminRequestToHydraRequest'
import apiClient from './fetchHydra'

/**
 * Maps react-admin queries to a Hydra powered REST API
 *
 * @see http://www.hydra-cg.com/
 *
 * @example
 * CREATE   => POST http://my.api.url/posts/123
 * DELETE   => DELETE http://my.api.url/posts/123
 * GET_LIST => GET http://my.api.url/posts
 * GET_MANY => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * GET_ONE  => GET http://my.api.url/posts/123
 * UPDATE   => PUT http://my.api.url/posts/123
 */
export default (entrypoint, httpClient = apiClient) => {
  /**
   * @param {string} type
   * @param {string} resource
   * @param {Object} params
   *
   * @returns {Promise}
   */
  const fetchApi = (
    type,
    resource = null,
    { cancelToken, ...params } = { cancelToken: null },
  ) =>
    convertReactAdminRequestToHydraRequest(type, resource, params, entrypoint)
      .then(({ url, options }) => httpClient({ url, ...options, cancelToken }))
      .then(response =>
        convertHydraResponseToReactAdminResponse(type, resource, response),
      )

  return {
    getList: (resource, params) => fetchApi(GET_LIST, resource, params),
    getOne: (resource, params) => fetchApi(GET_ONE, resource, params),
    // Hydra doesn't handle MANY requests, so we fallback to calling the ONE request n times instead
    getMany: (resource, params) =>
      Promise.all(
        params.ids.map(id => fetchApi(GET_ONE, resource, { id })),
      ).then(responses => ({ data: responses.map(({ data }) => data) })),
    getManyReference: (resource, params) =>
      fetchApi(GET_MANY_REFERENCE, resource, params),
    update: (resource, params) => fetchApi(UPDATE, resource, params),
    updateMany: (resource, params) =>
      Promise.all(
        params.ids.map(id =>
          fetchApi(UPDATE, resource, { id, data: params.data }),
        ),
      ).then(() => ({ data: [] })),
    create: (resource, params) => fetchApi(CREATE, resource, params),
    delete: (resource, params) => fetchApi(DELETE, resource, params),
    deleteMany: (resource, params) =>
      Promise.all(
        params.ids.map(id => fetchApi(DELETE, resource, { id })),
      ).then(() => ({ data: [] })),
    createMultipart: (resource, params) =>
      fetchApi(CREATE_MULTIPART, resource, params),
    saveTranslations: (resource, params) =>
      fetchApi(SAVE_TRANSLATIONS, resource, params),
    processOrder: (resource, params) =>
      fetchApi(PROCESS_ORDER, resource, params),
    lockOrder: (resource, params) => fetchApi(LOCK_ORDER, resource, params),
    unlockOrder: (resource, params) => fetchApi(UNLOCK_ORDER, resource, params),
    bulkUpdateItscopeMetadata: () => fetchApi(BULK_UPDATE_ITSCOPE_METADATA),
    updateItScopeMetadata: (resource, params) =>
      fetchApi(UPDATE_ITSCOPE_METADATA, resource, params),
    refreshITScopePrices: () => fetchApi(REFRESH_ITSCOPE_PRICES),
    updateItScopePrice: (resource, params) =>
      fetchApi(UPDATE_ITSCOPE_PRICE, resource, params),
    addProtectionPlan: (resource, params) =>
      fetchApi(ADD_PROTECTION_PLAN, resource, params),
    refreshAcmpTradingModelMargins: (resource, params) =>
      fetchApi(REFRESH_ACMP_TRADING_MODEL_MARGINS, resource, params),
    updateOrderLinesStock: (resource, params) =>
      fetchApi(UPDATE_ORDER_LINES_STOCK, resource, params),
    findITScopeBundle: (resource, params) =>
      fetchApi(FIND_ITSCOPE_BUNDLE, resource, params),
    createITScopeCart: (resource, params) =>
      fetchApi(CREATE_IT_SCOPE_CART, resource, params),
    cancelExternalProtectionPlanDevice: (resource, params) =>
      fetchApi(CANCEL_EXTERNAL_PROTECTION_PLAN_DEVICE, resource, params),
    itScopeDistributorRefresh: () => fetchApi(REFRESH_ITSCOPE_DISTRIBUTORS),
    copyAccessoryToVariants: (resource, params) =>
      fetchApi(COPY_ACCESSORY_TO_VARIANTS, resource, params),
    downloadAttachment: (resource, params) =>
      fetchApi(DOWNLOAD_ATTACHMENT, resource, params),
    downloadAllAttachments: (resource, params) =>
      fetchApi(DOWNLOAD_ALL_ATTACHMENTS, resource, params),
    assignCustomerToOrder: (resource, params) =>
      fetchApi(ASSIGN_CUSTOMER_TO_ORDER, resource, params),
    refreshPrices: (resource, params) =>
      fetchApi(REFRESH_PRICES, resource, params),
    testSMTP: (_, params) => fetchApi(TEST_SMTP, null, params),
    resendMail: (resource, params) => fetchApi(RESEND_EMAIL, resource, params),
    testCredentials: (resource, params) =>
      fetchApi(TEST_CREDENTIALS, resource, params),
    setOrderReporting: (resource, params) =>
      fetchApi(REPORTING_ORDER, resource, params),
    getContext: (resource, params) => fetchApi(GET_CONTEXT, resource, params),
    createItScopeOrders: (resource, params) =>
      fetchApi(CREATE_IT_SCOPE_ORDERS, resource, params),
    resetCustomerPassword: (resource, params) =>
      fetchApi(RESET_CUSTOMER_PASSWORD, resource, params),
    duplicateAsOffer: (resource, params) =>
      fetchApi(DUPLICATE_AS_OFFER, resource, params),
    alsoAddEdiItemToPortfolio: (resource, params) =>
      fetchApi(ALSO_ADD_EDI_ITEM_TO_PORTFOLIO, resource, params),
    alsoUpdateResellableProduct: (resource, params) =>
      fetchApi(ALSO_UPDATE_RESELLABLE_PRODUCT, resource, params),
    fetchApi,
  }
}
