import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const ProductSelect = ({ products }) => {
  if (products.length === 0) {
    return null
  }
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Zubehör</FormLabel>
      <List>
        {products.map(p => (
          <ListItem>
            <ListItemText primary={p.name} secondary={p.sku} />
          </ListItem>
        ))}
      </List>
    </FormControl>
  )
}

export default ProductSelect
