import React from 'react'
import { useTranslate } from 'react-admin'
import { DialogContent } from '@material-ui/core'
import DataEntryStep from 'Components/Aluxo/ActionDialogs/BulkCreateDeviceVariantsDialog/BeforeCreationWizard/DataEntryStep'
import ProcessingStep from 'Components/Aluxo/ActionDialogs/BulkCreateDeviceVariantsDialog/BeforeCreationWizard/ProcessingStep'
import useStepper from 'Components/Aluxo/ActionDialogs/BulkCreateDeviceVariantsDialog/BeforeCreationWizard/useStepper'

const BeforeCreationWizard = ({ closeDialog }) => {
  const t = useTranslate()
  const stepObjects = [
    {
      label: t(
        'processes.bulkCreateDeviceVariantsDialog.steps.labels.dataEntry',
      ),
      content: <DataEntryStep />,
    },
    {
      label: t('processes.bulkCreateDeviceVariantsDialog.steps.labels.process'),
      content: <ProcessingStep />,
    },
  ]

  const stepperDetails = useStepper(stepObjects)
  const {
    currentStep: { content },
  } = stepperDetails

  return (
    <DialogContent>
      {React.cloneElement(content, { stepperDetails, closeDialog })}
    </DialogContent>
  )
}

export default BeforeCreationWizard
