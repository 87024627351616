import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import GetAppIcon from '@material-ui/icons/GetApp'
import b64toBlob from 'b64-to-blob'
import Button from 'Components/Common/Buttons/Button'
import EditActions from 'Components/Common/EditActions'
import EditToolbar from 'Components/Common/EditToolbar'
import CommonMediaObjectUploadForm from 'Components/Common/MediaObject/UploadForm'
import { isAdmin, isAlsoAdmin, isAlsoReseller, isReseller } from 'Lib/helper'
import get from 'lodash/get'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TopToolbar,
  useDataProvider,
  useLoading,
  useMutation,
  useNotify,
  usePermissions,
  useTranslate,
  useVersion,
} from 'react-admin'
import { useField } from 'react-final-form'
import { Link } from 'react-router-dom'
import { validateRequired } from 'Resources/Aluxo/devices'

export const KnowledgeItemList = props => {
  const loading = useLoading()
  const t = useTranslate()
  const showNotification = useNotify()
  const version = useVersion()
  const dataProvider = useDataProvider()

  const [mediaItems, setMediaItems] = useState([])

  const newDateMarker = moment().subtract(3, 'months')

  const { permissions } = usePermissions()

  const typeFilter = useMemo(() => {
    if (isAlsoReseller(permissions) || isAlsoAdmin(permissions)) {
      return 'ALSO'
    }
    if (isReseller(permissions) || isAdmin(permissions)) {
      return 'ALBIS'
    }
    return ''
  }, [permissions])

  useEffect(() => {
    const refreshData = async () => {
      const res = await dataProvider.getList('knowledge_items', {
        pagination: false,
        sort: false,
        filter: { type: typeFilter },
      })
      if (res) {
        setMediaItems(
          res.data.map(item => ({
            ...item,
            new: moment(item.createdAt).isAfter(newDateMarker),
          })) || [],
        )
      }
    }

    if (typeFilter) {
      refreshData()
    }
  }, [version, typeFilter])

  const [mutate] = useMutation()

  const handleDownloadClick = async id => {
    mutate(
      {
        type: 'downloadAttachment',
        resource: 'knowledge_items',
        payload: { id },
      },
      {
        onSuccess: async response => {
          const contentType = response.headers['content-type']
          const disposition = response.headers['content-disposition']
          const filename = disposition.match(
            /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/,
          )
          const resText = response.data
          const blob = b64toBlob(resText, contentType)
          const blobUrl = URL.createObjectURL(blob)
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.style = 'display: none'
          a.href = blobUrl
          a.download = filename[1]
          a.click()
          URL.revokeObjectURL(blobUrl)
        },
        onFailure: error => {
          showNotification(`Error: ${error}`, 'warning') // todo errormessage
        },
      },
    )
  }

  return (
    <>
      {isAlsoAdmin(permissions) && (
        <TopToolbar>
          <Button
            component={Link}
            to="/knowledge_items/create"
            label="ra.action.create"
          />
        </TopToolbar>
      )}
      <Card style={{ marginTop: '1em' }}>
        <CardHeader title={t('texts.knowledge_items.header')} />
        <CardContent>
          <List dense>
            {mediaItems.map(mediaItem => (
              <ListItem key={mediaItem.id}>
                {mediaItem.new && (
                  <ListItemIcon>
                    <Chip size="small" color="primary" label="Neu" />
                  </ListItemIcon>
                )}
                <ListItemText
                  inset={!mediaItem.new}
                  primary={mediaItem.name}
                  secondary={mediaItem.comment}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={() => handleDownloadClick(mediaItem.id)}
                  >
                    <GetAppIcon />
                  </IconButton>

                  {isAlsoAdmin(permissions) && (
                    <IconButton
                      component={Link}
                      edge="end"
                      aria-label="edit"
                      to={`/knowledge_items/${mediaItem.id}`}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </>
  )
}

KnowledgeItemList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const KnowledgeItemTitle = ({ record }) => get(record, 'name')

const AttachmentInput = props => {
  const {
    input: { onChange },
  } = useField(props.source)

  return (
    <CommonMediaObjectUploadForm onUpload={({ data }) => onChange(data.id)} />
  )
}

export const KnowledgeItemEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<KnowledgeItemTitle />}
    {...props}
    redirect="list"
  >
    <SimpleForm toolbar={<EditToolbar hasDelete />}>
      <TextInput source="name" validate={validateRequired} />
      <TextInput multiline rows="5" source="comment" />
      <AttachmentInput source="mediaObject_reference_id" />
    </SimpleForm>
  </Edit>
)

KnowledgeItemEdit.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export const KnowledgeItemCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={validateRequired} />
      <TextInput multiline rows="5" source="comment" />
      <AttachmentInput source="mediaObject_reference_id" />
    </SimpleForm>
  </Create>
)
