import { LinearProgress, ListSubheader, Paper } from '@material-ui/core'
import Downshift from 'downshift'
import { clamp } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useField } from 'react-final-form'
import { useLoading, useQuery } from 'react-admin'
import { AutoSizer, List } from 'react-virtualized'
import {
  renderSuggestion,
  Input,
} from 'Components/Common/InputFields/AutoSuggests/Lib/renderFunctions'
import Fuse from 'fuse.js'

const itemToString = item => item.label

const getSuggestions = (data, value) => {
  const inputValue = value || ''.trim().toLowerCase()

  const fuse = new Fuse(data, {
    keys: ['category', 'displayName'],
  })

  const results = fuse.search(inputValue)

  const groups = []
  results.forEach(({ item }) => {
    const suggestion = {
      ...item,
      value: item.id,
      label: item.displayName,
    }
    const group = groups.find(group => group.id === item.category)
    if (group) {
      group.suggestions.push(suggestion)
    } else {
      groups.push({
        id: item.category,
        title: item.category,
        suggestions: [suggestion],
      })
    }
  })

  return groups
}

const FetchData = ({ children }) => {
  const loading = useLoading()
  const { data } = useQuery({
    type: 'getList',
    resource: 'also_acmp_services',
    payload: {
      pagination: false,
      sort: false,
      filter: { inPortfolio: false, active: true },
    },
  })

  if (loading) {
    return (
      <div
        style={{
          minHeight: '72px',
          display: 'flex',
          alignItems: 'flex-end',
        }}
      >
        <LinearProgress style={{ width: '100%' }} mode="indeterminate" />
      </div>
    )
  }

  return children({ data, loading })
}

const SelectList = ({
  data,
  getItemProps,
  highlightedIndex,
  selectedItem,
  inputValue,
}) => {
  const suggestions = getSuggestions(data, inputValue)

  const rows = []
  suggestions.forEach(group => {
    rows.push({ label: group.title })
    group.suggestions.forEach(suggestion => {
      rows.push(suggestion)
    })
  })

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          height={clamp(rows.length * 36, 0, 400)}
          rowHeight={36}
          rowCount={rows.length}
          rowRenderer={({ index, key, style }) => {
            const row = rows[index]
            if (!row.id) {
              return (
                <ListSubheader
                  key={key}
                  style={style}
                  {...getItemProps({ item: row })}
                >
                  {row.label}
                </ListSubheader>
              )
            }
            return renderSuggestion({
              suggestion: row,
              index,
              key,
              style,
              itemProps: getItemProps({ item: row }),
              highlightedIndex,
              selectedItem,
            })
          }}
          width={width}
        />
      )}
    </AutoSizer>
  )
}

const AcmpServiceAutoSuggest = ({ source, name, onItemSelect, label }) => {
  const {
    input: { value, onChange },
  } = useField(source || name)

  const selectedItem = { value }

  return (
    <Downshift
      onChange={selection => {
        if (selection) {
          onChange(selection.value)
          onItemSelect(selection)
        }
      }}
      itemToString={itemToString}
      selectedItem={selectedItem}
    >
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        isOpen,
        selectedItem,
        inputValue,
        highlightedIndex,
      }) => (
        <div>
          <Input label={label} fullWidth InputProps={getInputProps()} />

          <Paper
            style={{
              display: isOpen ? 'block' : 'none',
              visibility: isOpen ? 'visible' : 'hidden',
            }}
            square
            {...getMenuProps()}
          >
            {isOpen ? (
              <FetchData>
                {({ data }) => (
                  <SelectList
                    data={data}
                    getItemProps={getItemProps}
                    highlightedIndex={highlightedIndex}
                    selectedItem={selectedItem}
                    inputValue={inputValue}
                  />
                )}
              </FetchData>
            ) : null}
          </Paper>
        </div>
      )}
    </Downshift>
  )
}

AcmpServiceAutoSuggest.propTypes = {
  source: PropTypes.string,
  name: PropTypes.string,
  onItemSelect: PropTypes.func,
  label: PropTypes.string.isRequired,
}

AcmpServiceAutoSuggest.defaultProps = {
  source: null,
  name: null,
  onItemSelect: () => {},
}

export default AcmpServiceAutoSuggest
