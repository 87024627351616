import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Slide,
  Typography,
} from '@material-ui/core'
import React, { useContext, useMemo } from 'react'
import { useToggle } from 'react-use'
import { useRefresh, useTranslate } from 'react-admin'
import { Form, useField } from 'react-final-form'
import Button from 'Components/Common/Buttons/Button'
import useLazyQuery from 'Hooks/useLazyQuery'
import { groupBy } from 'lodash'
import { ResellerContext } from 'ResellerContext'
import { useApiClient } from 'Rest/fetchHydra'

const Transition = props => <Slide direction="up" {...props} />

const SelectAttributeValueField = ({
  name,
  productAttributeValues,
  implicit,
}) => {
  const { input } = useField(name)

  const handleToggle = value => () => {
    const newValue = input.value === value ? null : value

    input.onChange(newValue)
  }

  return (
    <>
      <ListSubheader>{name}</ListSubheader>
      {productAttributeValues.map(productAttributeValue => {
        const labelId = `checkbox-list-label-${productAttributeValue.value}`

        const isChecked =
          (implicit && implicit.id === productAttributeValue.id) ||
          input.value === productAttributeValue.id

        return (
          <ListItem
            dense
            key={productAttributeValue.id}
            button
            onClick={handleToggle(productAttributeValue.id)}
            disabled={implicit}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={isChecked}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText primary={productAttributeValue.value} />
          </ListItem>
        )
      })}
    </>
  )
}

const AssignProcutAttributeValueDialog = ({
  attributeValues,
  onAssignSubmit,
}) => {
  const [open, toggle] = useToggle(false)

  const [{ data, loading }, fetch] = useLazyQuery({
    type: 'getList',
    resource: 'common_product_attribute_values',
    payload: {
      pagination: false,
      sort: false,
      filter: false,
    },
  })

  const translate = useTranslate()
  const handleSubmit = async values => {
    await onAssignSubmit(values)
    toggle(false)
  }

  const openDialog = () => {
    fetch()
    toggle(true)
  }

  const closeDialog = () => {
    toggle(false)
  }

  const allAttributeValues = useMemo(() => groupBy(data, 'group.name'), [data])

  const initialValues = {}

  attributeValues
    .filter(v => !v.implicit)
    .forEach(v => {
      initialValues[v.group.name] = v.id
    })

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={false}
        aria-labelledby="simple-dialog-title"
        open={open}
        onClose={() => toggle(false)}
        TransitionComponent={Transition}
      >
        <Form onSubmit={handleSubmit} initialValues={initialValues}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <List dense>
                    {Object.entries(allAttributeValues).map(
                      ([group, productAttributeValues]) => (
                        <SelectAttributeValueField
                          name={group}
                          productAttributeValues={productAttributeValues}
                          implicit={attributeValues.find(
                            v => v.group.name === group && v.implicit,
                          )}
                        />
                      ),
                    )}
                  </List>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  label="actions.submit"
                  type="submit"
                  disabled={submitting}
                  loading={submitting}
                  color="primary"
                  variant="contained"
                />
                <Button
                  label="actions.cancel"
                  type="button"
                  disabled={submitting}
                  color="secondary"
                  variant="text"
                  onClick={closeDialog}
                />
              </DialogActions>
            </form>
          )}
        </Form>
      </Dialog>
      <Button
        color="primary"
        onClick={openDialog}
        disableOnGlobalLoad
        // disabled={disabled}
        label={translate('actions.assignProductAttributeValues')}
      />
    </>
  )
}

const ProductAttributeList = props => {
  const translate = useTranslate()
  const refresh = useRefresh()
  const apiClient = useApiClient()

  const { flags } = useContext(ResellerContext)
  if (!flags.cNetSpecs) {
    return null
  }

  const onAssignSubmit = async values => {
    const productAttributeValues = Object.values(values).filter(v => v)
    await apiClient({
      endpoint: `${props.basePath}/${props.record.id}`,
      method: 'PUT',
      body: { productAttributeValues },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    refresh()
  }

  const data = props.record.attributeValues || []

  return (
    <div>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="h5">
          {translate('resources.common_product_attribute_values.name')}
        </Typography>
        <AssignProcutAttributeValueDialog
          attributeValues={data}
          onAssignSubmit={onAssignSubmit}
        />
      </Box>
      <List>
        {data.map(attributeValue => (
          <ListItem key={attributeValue.id}>
            <ListItemText
              primary={[attributeValue.group?.name, attributeValue.value]
                .filter(v => v)
                .join(' > ')}
              secondary={
                attributeValue.implicit
                  ? translate(
                      'resources.common_product_attribute_values.state.implicit',
                    )
                  : translate(
                      'resources.common_product_attribute_values.state.explicit',
                    )
              }
            />
          </ListItem>
        ))}
      </List>
    </div>
  )
}

export default ProductAttributeList
