import UpdateResellableProductButton from 'Components/Also/ActionButtons/UpdateResellableProductButton'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import React from 'react'
import {
  Datagrid,
  Edit,
  EditButton,
  List as RAList,
  NumberField,
  required,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import CNetMetaDataList from 'Components/Common/ProductAttributes/CNetMetaDataList'
import ProductAttributeList from 'Components/Common/ProductAttributes/ProductAttributeList'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'
import CNetImagesView from 'Components/Common/ViewFields/CNetImagesView'

const Actions = ({ data }) => (
  <RightAlignedCardActions>
    <UpdateResellableProductButton data={data} />
  </RightAlignedCardActions>
)

export const ResellableProductEdit = props => (
  <Edit {...props} actions={<Actions />}>
    <SimpleForm redirect={false}>
      <TextInput disabled label="Id" source="id" />
      <TextInput source="name" validate={required()} />
      <TextInput source="manufacturer" validate={required()} />
      <TextInput disabled source="sku" />
      <TextInput disabled source="ean" />
      <TextInput multiline fullWidth disabled source="marketingText" />
      <TextInput
        multiline
        source="relatedSKUs"
        format={values => (values || []).join('\n')}
        parse={stringValue => stringValue.split('\n').map(v => v.trim())}
      />
      <CNetImagesView
        filterName="product"
        imageResource="also_resellable_product_cnet_images"
      />
      <ProductAttributeList />
      <CNetMetaDataList target="resellableProducts" />
    </SimpleForm>
  </Edit>
)

export const ResellableProductList = props => (
  <RAList {...props} pagination={<CustomPagination />}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="manufacturer" />
      <TextField source="sku" />
      <NumberField source="relatedSKUCount" />
      <CurrencyField source="price" />
      <EditButton />
    </Datagrid>
  </RAList>
)
