import GenericRestClientButton from 'Components/Common/ActionButtons/GenericRestClientButton'
import PropTypes from 'prop-types'
import React from 'react'

const UpdateITScopeSinglePriceButton = ({ resource, data }) => {
  if (!data) {
    return null
  }

  return (
    <GenericRestClientButton
      type="updateItScopePrice"
      action="updateItScopePrice"
      resource={resource}
      payload={{ id: data.id }}
    />
  )
}

UpdateITScopeSinglePriceButton.propTypes = {
  resource: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
}

UpdateITScopeSinglePriceButton.defaultProps = {
  data: null,
}

export default UpdateITScopeSinglePriceButton
