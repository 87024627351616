import GenericRestClientButton from 'Components/Common/ActionButtons/GenericRestClientButton'
import PropTypes from 'prop-types'
import React from 'react'

const UpdateITScopeMetadataButton = ({ data, resource }) => {
  if (!data) {
    return null
  }

  return (
    <GenericRestClientButton
      type="updateItScopeMetadata"
      action="updateItScopeMetadata"
      resource={resource}
      payload={{ id: data.id }}
    />
  )
}

UpdateITScopeMetadataButton.propTypes = {
  resource: PropTypes.string.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
}

UpdateITScopeMetadataButton.defaultProps = {
  data: null,
}

export default UpdateITScopeMetadataButton
