import PropTypes from 'prop-types'
import React from 'react'
import {
  useDataProvider,
  useLoading,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'
import { UPDATE_ALSO_SERVICE_PORTFOLIO } from 'Rest/actions'
import Button from 'Components/Common/Buttons/Button'

const UpdateAlsoServicePortfolioButton = ({ label }) => {
  const showNotification = useNotify()
  const refreshView = useRefresh()
  const translate = useTranslate()
  const disabled = useLoading()

  const dataProvider = useDataProvider()

  const handleClick = async () => {
    try {
      await dataProvider.fetchApi(UPDATE_ALSO_SERVICE_PORTFOLIO)
      showNotification('notifications.updateAlsoApiData.success')
      refreshView()
    } catch (error) {
      showNotification('notifications.updateAlsoApiData.fail', 'warning')
    }
  }

  return (
    <Button
      color="primary"
      onClick={handleClick}
      disabled={disabled}
      label={translate(label)}
    />
  )
}

UpdateAlsoServicePortfolioButton.propTypes = {
  label: PropTypes.string,
}

UpdateAlsoServicePortfolioButton.defaultProps = {
  label: 'actions.updateAlsoApiData',
}

export default UpdateAlsoServicePortfolioButton
