import React from 'react'
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import splashImage from 'Assets/Images/also-edi-reseller-splash.png'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  h2: {
    marginBlockEnd: '0em',
  },
}))

const ComponentsAlsoDashboardEdiResellerDashboard = () => {
  const classes = useStyles()

  return (
    <div>
      <Box display="flex" justifyContent="center">
        <img src={splashImage} alt="" />
      </Box>
      <Typography>
        <h2>Jetzt ist es noch einfacher, WaaS Berater zu sein!</h2>
        <p>
          Mit dem neuen WaaS Self Service Tool können Sie nun Ihre WaaS Angebote
          noch schneller abwickeln!
          <br /> Die statischen Services gehören der Vergangenheit an – nun
          entscheiden Sie selbst, mit welchem WaaS Portfolio sie auftreten
          wollen! Managen Sie das Portfolio, das gewünschte Zubehör und die Care
          Packs ganz einfach und individuell. So können Sie nun noch einfacher,
          schneller und vor allem absolut bedürfnisgerecht auf Ihre Kunden
          eingehen!
        </p>
        <ul>
          <li>
            Erstellen Sie ihr eigenes Portfolio und wählen Sie dabei aus dem
            kompletten ALSO Portfolio ihre Wunschgeräte aus
          </li>
          <li>
            Generieren Sie Offerten direkt aus dem WaaS Self Service Tool als
            PDF, mit ihrem eigenen Logo und offerieren Sie so Ihren Kunden
            innerhalb von wenigen Minuten mit individueller Marge
          </li>
          <li>
            Offerten und Bestellungen können jederzeit eingesehen und abgerufen
            werden
          </li>
          <li>Automatische Buchung im ALSO Cloud Marketplace </li>
        </ul>
      </Typography>
      <Typography>
        <h2 className={classes.h2}>Ihre Ansprechpersonen</h2>
      </Typography>
      <List>
        <ListItem disableGutters>
          <ListItemText
            primary="Robert Granich"
            secondary={
              <div>
                Head of Device as a Service Europe
                <br />
                <a href="mailto:robert.granich@also.com">
                  robert.granich@also.com
                </a>
              </div>
            }
          />
          <ListItemText secondary="" />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="Suat Tombul"
            secondary={
              <div>
                Business Development Manager Device as a Service
                <br />
                <a href="mailto:suat.tombul@also.com">suat.tombul@also.com</a>
              </div>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="Angela Barbieri"
            secondary={
              <div>
                Product Manager Device as a Service
                <br />
                <a href="mailto:angela.barbieri@also.com">
                  angela.barbieri@also.com
                </a>
              </div>
            }
          />
        </ListItem>
      </List>
    </div>
  )
}

export default ComponentsAlsoDashboardEdiResellerDashboard
