import React, { useState, useContext, useEffect } from 'react'
import { Field, Form } from 'react-final-form'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { useTranslate, useLogin, useNotify, fetchEnd } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'

import { ResellerContext } from 'ResellerContext'

const useStyles = makeStyles({
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  button: {
    width: '100%',
  },
  centered: {
    padding: '1em 1em 1em 1em',
  },
})

// see http://react-final-form.com/6.4.3/examples/material-ui/
const renderInput = ({
  meta: { touched, error } = {}, // eslint-disable-line react/prop-types
  input: { ...inputProps }, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
)
const LoginForm = () => {
  const [loading, setLoading] = useState(false)
  const translate = useTranslate()
  const classes = useStyles()
  const login = useLogin()
  const notify = useNotify()
  const { refetch } = useContext(ResellerContext)

  // This is a hack that prevents the loading indicator from infinitely spinning
  const dispatch = useDispatch()
  const loadingState = useSelector(state => state.admin.loading)

  useEffect(() => {
    if (loadingState > 0) {
      dispatch(fetchEnd())
    }
  }, [loadingState])

  const handleSubmit = ({ email, password }) => {
    setLoading(true)
    login({ email, password })
      .catch(e => {
        notify(e.message, 'warning')
      })
      .finally(() => {
        setLoading(false)
        refetch()
      })
  }
  return (
    <Form
      onSubmit={handleSubmit}
      validate={({ email, password }) => {
        const errors = {}
        if (!email) {
          errors.email = translate('ra.validation.required')
        }
        if (!password) {
          errors.password = translate('ra.validation.required')
        }
        return errors
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                id="email"
                name="email"
                component={renderInput}
                label={translate('ra.auth.username')}
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <Field
                id="password"
                name="password"
                component={renderInput}
                label={translate('ra.auth.password')}
                type="password"
                disabled={loading}
              />
            </div>
          </div>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              className={classes.button}
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
          <div className={classes.centered}>
            <Link to="/password/request">Passwort vergessen?</Link>
          </div>
        </form>
      )}
    </Form>
  )
}

export default LoginForm
