import { endsWith } from 'lodash'
import { useMemo } from 'react'
import { useApiClient } from 'Rest/fetchHydra'
import { useQuery } from 'react-query'
import { useDebounce } from 'use-debounce'
import PropTypes from 'prop-types'

const FetchData = ({ children, type, searchValue }) => {
  const apiClient = useApiClient()

  const [searchQuery] = useDebounce(searchValue, 400, {
    leading: false,
  })

  const queriesActive = searchQuery.length > 0

  const alsoProductsQuery = useQuery(
    ['also_product_variants', searchQuery],
    () =>
      apiClient({
        method: 'POST',
        endpoint: `/also/search_products`,
        headers: {
          Accept: 'application/json',
        },
        body: {
          field: type,
          searchString: searchQuery,
        },
      }).then(res => res.data),

    {
      enabled: queriesActive,
      keepPreviousData: true,
      initialData: [],
    },
  )
  const alsoResellerProductsQuery = useQuery(
    ['also_reseller_products'],
    () =>
      apiClient({
        endpoint: `/also_reseller_products`,
        headers: {
          Accept: 'application/json',
        },
        params: {
          pagination: false,
          'product.type': 'WorkplaceDevice',
        },
      }).then(res => res.data),

    {
      enabled: queriesActive,
      keepPreviousData: true,
      initialData: [],
    },
  )

  const loading =
    alsoResellerProductsQuery.isFetching || alsoProductsQuery.isFetching

  const { data: alsoResellerProducts } = alsoResellerProductsQuery
  const { data: alsoProducts } = alsoProductsQuery

  const activeProducts = useMemo(
    () =>
      alsoProducts
        .filter(p => p.variants.some(v => v.enumMember.field === type))
        .map(v => ({
          ...v,
          resellerActive: alsoResellerProducts.some(
            p => p.active && endsWith(p.product, v.id),
          ),
        })),
    [alsoResellerProducts, alsoProducts],
  )

  return children({ activeProducts, loading })
}

FetchData.propTypes = {
  includeITScope: PropTypes.boolean,
}

FetchData.defaultProps = {
  includeITScope: false,
  type: 'WorkplaceDevice',
}

export default FetchData
