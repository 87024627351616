import { Box, LinearProgress, Paper } from '@material-ui/core'
import Downshift from 'downshift'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import Button from 'Components/Common/Buttons/Button'
import { useToggle } from 'react-use'
import AssignNewCustomerButton from 'Components/Common/InputFields/AssignNewCustomerButton'
import { useOrder } from 'OrderContext'
import FetchData from 'Components/Common/InputFields/AutoSuggests/OrderCustomerAutoSuggest/FetchData'
import SelectList from 'Components/Common/InputFields/AutoSuggests/OrderCustomerAutoSuggest/SelectList'
import { Input } from 'Components/Common/InputFields/AutoSuggests/Lib/renderFunctions'
import { useMutation, useNotify, useRefresh } from 'react-admin'

const itemToString = item => item.label

const OrderCustomerAutoSuggest = ({ autoFocus }) => {
  const { record } = useOrder()
  const showNotification = useNotify()
  const refreshView = useRefresh()
  const [mutate] = useMutation()
  const [newCustomerOpen, toggle] = useToggle(false)

  const selectedItem = { value: get(record, 'customer.id') }

  if (
    !['opened', 'offered', 'changed', 'offer_draft'].includes(record.marking)
  ) {
    return null
  }

  const handleAssignCustomer = customer => {
    const payload = {
      order: record.id,
    }

    if (customer.type === 'aluxo') {
      payload.customer = customer.id
    } else {
      payload.gSalesCustomer = customer.id
    }

    mutate(
      {
        type: 'assignCustomerToOrder',
        resource: 'orders',
        payload: {
          data: payload,
        },
      },

      {
        onSuccess: () => {
          showNotification('notifications.assignCustomer.success')
          refreshView()
        },
        onFailure: () => {
          showNotification('notifications.assignCustomer.fail', 'warning')
        },
      },
    )
  }

  return (
    <Downshift
      onChange={selection => {
        if (selection) {
          showNotification('notifications.assignCustomer.assigning')
          handleAssignCustomer({
            type: selection.aluxo ? 'aluxo' : 'gSales',
            id: selection.value,
          })
        }
      }}
      itemToString={itemToString}
      selectedItem={selectedItem}
    >
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        isOpen,
        selectedItem,
        inputValue,
        highlightedIndex,
      }) => (
        <div>
          <div>
            <Button
              label={newCustomerOpen ? 'Abbrechen' : 'Kunden zuweisen'}
              color={newCustomerOpen ? 'secondary' : 'primary'}
              onClick={toggle}
            />
            {!newCustomerOpen && <AssignNewCustomerButton />}
          </div>
          <Box
            mt={1}
            style={{
              display: newCustomerOpen ? 'block' : 'none',
              visibility: newCustomerOpen ? 'visible' : 'hidden',
            }}
          >
            <Input
              label="Kunden wählen"
              fullWidth
              InputProps={getInputProps()}
              autoFocus={autoFocus}
            />
          </Box>
          <Paper
            style={{
              display: isOpen ? 'block' : 'none',
              visibility: isOpen ? 'visible' : 'hidden',
            }}
            square
            {...getMenuProps()}
          >
            {isOpen ? (
              <FetchData searchValue={inputValue}>
                {({ data, loading }) => (
                  <>
                    {loading && (
                      <LinearProgress
                        style={{ width: '100%' }}
                        mode="indeterminate"
                      />
                    )}
                    <SelectList
                      data={data}
                      getItemProps={getItemProps}
                      highlightedIndex={highlightedIndex}
                      selectedItem={selectedItem}
                      inputValue={inputValue}
                      itemToString={itemToString}
                    />
                  </>
                )}
              </FetchData>
            ) : null}
          </Paper>
        </div>
      )}
    </Downshift>
  )
}

OrderCustomerAutoSuggest.propTypes = {
  source: PropTypes.string,
  name: PropTypes.string,
}

OrderCustomerAutoSuggest.defaultProps = {
  source: null,
  name: null,
}

export default OrderCustomerAutoSuggest
