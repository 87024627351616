import { useEffect } from 'react'
import { useFieldArray } from 'react-final-form-arrays'
import { useForm } from 'react-final-form'

const PositionUpdater = () => {
  const { batch, change } = useForm()
  const { fields } = useFieldArray('offerLines')

  const { value } = fields

  useEffect(() => {
    batch(() => {
      fields.forEach((field, index) => {
        change(`offerLines.${index}.position`, index)
      })
    })
  }, [value])

  return null
}

export default PositionUpdater
