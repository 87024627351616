import { ListItemText, MenuItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { useNotify, useTranslate, useUpdateLoading } from 'react-admin'
import { useHistory } from 'react-router-dom'
import { useApiClient } from 'Rest/fetchHydra'
import { useTheme } from '@material-ui/core/styles'

const PersonalSmtpSettingsMenuItem = () => {
  const { startLoading, stopLoading } = useUpdateLoading()
  const showNotification = useNotify()
  const translate = useTranslate()
  const history = useHistory()
  const theme = useTheme()
  const apiClient = useApiClient()

  const handleClick = async () => {
    try {
      startLoading()

      const res = await apiClient({ endpoint: '/profile' }).then(
        res => res.data,
      )

      history.push(`/staff/${res.id}`)
    } catch (error) {
      console.error(error)
      showNotification(translate('errors.errorResellerNotFound'), 'warning')
    }
    stopLoading()
  }

  return (
    <MenuItem
      onClick={handleClick}
      style={{ color: theme.palette.text.secondary }}
    >
      <ListItemText
        primary={translate('menu.settings.personalSmtpConfiguration')}
      />
    </MenuItem>
  )
}

PersonalSmtpSettingsMenuItem.propTypes = {
  record: PropTypes.object,
}

export default PersonalSmtpSettingsMenuItem
