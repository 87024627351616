import GenericRestClientButton from 'Components/Common/ActionButtons/GenericRestClientButton'
import PropTypes from 'prop-types'
import React from 'react'

const TestSMTPButton = ({ apiKey, personal }) => (
  <GenericRestClientButton
    type="testSMTP"
    action="testSMTP"
    payload={{ apiKey, personal }}
  />
)

TestSMTPButton.propTypes = {
  apiKey: PropTypes.string,
  personal: PropTypes.bool,
}

TestSMTPButton.defaultProps = {
  apiKey: null,
  personal: false,
}

export default TestSMTPButton
