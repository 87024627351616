import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  stockNumber: {
    fontSize: theme.typography.fontSize,
    marginLeft: theme.spacing(0.5),
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.grey[500],
    display: 'flex',
    alignItems: 'center',
    height: '12px',
  },
  link: {
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    display: 'inline-block',
    width: '8px',
    height: '8px',
    borderRadius: '8px',
  },
}))

const backgroundColorByStock = ({ stock, stockNeeded }) => {
  if (stock === null) {
    return 'orange'
  }
  if (stock >= stockNeeded) {
    return 'green'
  }
  return 'red'
}

const InStockDisplay = ({ stockNeeded, stock, sku }) => {
  const classes = useStyles()
  return (
    <Box display="flex" alignItems="center" mr={1}>
      <a
        className={classes.link}
        target="_blank"
        rel="noopener noreferrer"
        href={`https://www.itscope.com/red/app#search/portal/${sku}`}
      >
        <i
          className={classes.icon}
          style={{
            backgroundColor: backgroundColorByStock({ stock, stockNeeded }),
          }}
        />
      </a>
      <div className={classes.stockNumber}>{stock > 99 ? '99+' : stock}</div>
    </Box>
  )
}

InStockDisplay.propTypes = {
  stock: PropTypes.number,
  stockNeeded: PropTypes.number,
  sku: PropTypes.string.isRequired,
}

InStockDisplay.defaultProps = {
  stock: null,
  stockNeeded: 1,
}

export default InStockDisplay
