import { ListSubheader } from '@material-ui/core'
import { clamp } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { AutoSizer, List } from 'react-virtualized'
import RenderSuggestion from './RenderSuggestion'

const SelectList = ({
  rows,
  getItemProps,
  highlightedIndex,
  itemToString,
  selectedItem,
  renderSuggestionComponent,
}) => {
  const RenderSuggestionComponent = renderSuggestionComponent

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          height={clamp(rows.length * 36, 0, 400)}
          rowHeight={36}
          rowCount={rows.length}
          rowRenderer={({ index, key, style }) => {
            const row = rows[index]
            if (!row.id) {
              return (
                <ListSubheader
                  key={key}
                  style={style}
                  {...getItemProps({ item: row, disabled: true })}
                >
                  {row.label}
                </ListSubheader>
              )
            }
            return (
              <RenderSuggestionComponent
                suggestion={row}
                index={index}
                key={key}
                style={style}
                itemProps={getItemProps({ item: row })}
                highlightedIndex={highlightedIndex}
                selectedItem={selectedItem}
                itemToString={itemToString}
              />
            )
          }}
          width={width}
        />
      )}
    </AutoSizer>
  )
}

SelectList.propTypes = {
  rows: PropTypes.array.isRequired,
  getItemProps: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number.isRequired,
  selectedItem: PropTypes.object.isRequired,
  itemToString: PropTypes.func.isRequired,
  renderSuggestionComponent: PropTypes.node,
}

SelectList.defaultProps = {
  renderSuggestionComponent: RenderSuggestion,
}

export default SelectList
