import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import { useLoading } from 'react-admin'

const useStyles = makeStyles(() => ({
  card: {
    minHeight: '200px',
  },
  cardHeader: {
    padding: '24px 24px 0px 24px ',
  },
  cardContent: {
    '&:last-child': {
      padding: 0,
    },
  },
}))

const ContextEdit = ({ children, cardStyle, title, action, ...props }) => {
  const loading = useLoading()
  const classes = useStyles()
  const { record, basePath, resource } = props

  const injectedTitle = title ? React.cloneElement(title, { record }) : null
  const injectedAction = action
    ? React.cloneElement(action, { basePath, resource, data: record })
    : null
  const injectedChildren = React.cloneElement(React.Children.only(children), {
    ...props,
    saving: loading,
  })

  if (!record) {
    return <Card style={{ minHeight: '200px' }} />
  }

  return (
    <Card className={classes.card} style={cardStyle}>
      {(title || action) && (
        <CardHeader
          className={classes.cardHeader}
          title={injectedTitle}
          action={injectedAction}
        />
      )}
      <CardContent className={classes.cardContent}>
        {injectedChildren}
      </CardContent>
    </Card>
  )
}

ContextEdit.propTypes = {
  title: PropTypes.element,
  action: PropTypes.element,
  record: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
}

ContextEdit.defaultProps = {
  title: null,
  action: null,
}

export default ContextEdit
