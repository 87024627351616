import React from 'react'
import { useOrder } from 'OrderContext'
import GenericRestClientButton from '../../Common/ActionButtons/GenericRestClientButton'

const enabledStates = ['draft', 'opened', 'changed', 'offer_draft']

const RefreshPricesButton = () => {
  const { record } = useOrder()
  const disabled = !enabledStates.some(s => s === record.marking)
  return (
    <GenericRestClientButton
      action="refreshPrices"
      type="refreshPrices"
      resource="orders"
      payload={{
        id: record.id,
        data: {},
      }}
      disabled={disabled}
    />
  )
}

export default RefreshPricesButton
