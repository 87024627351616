import { useTranslate } from 'react-admin'

// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const useTranslatedEmailValidation = () => {
  const translate = useTranslate()
  return value =>
    emailRegex.test(String(value).toLowerCase())
      ? undefined
      : translate('forms.validation.email')
}

export default useTranslatedEmailValidation
