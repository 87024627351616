import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from 'Components/Common/Buttons/Button'
import currencyText from 'Lib/currencyText'
import React from 'react'
import { useField } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'
import AddRelatedProductButton from 'Components/Also/Offers/OfferLines/AddRelatedProductButton'
import PositionUpdater from 'Components/Also/Offers/OfferLines/PositionUpdater'
import { useToggle } from 'react-use'
import { makeStyles } from '@material-ui/core/styles'
import InlineNumberWithUnitInput from 'Components/Common/InputFields/Inline/InlineNumberWithUnitInput'
import DeleteIcon from '@material-ui/icons/Delete'
import ImageCell from 'Components/Also/Offers/OfferLines/ImageCell'
import TruncatedTextField from 'Components/Common/ViewFields/TruncatedTextField'

const useStyles = makeStyles(theme => ({
  display: {
    textDecoration: 'underline',
    cursor: 'pointer',
    minWidth: '4rem',
    display: 'inline-block',
    color: theme.palette.primary.main,
  },
}))

const useInputStyles = makeStyles({
  root: { width: '4rem', fontSize: 'inherit' },
})

const EditableMarginField = ({ name }) => {
  const classes = useStyles()
  const inputClasses = useInputStyles()
  const [editable, toggleEditable] = useToggle(false)
  const { input } = useField(`${name}.margin`)

  if (!editable) {
    return (
      <span
        className={classes.display}
        onClick={toggleEditable}
        role="button"
        onKeyPress={toggleEditable}
        tabIndex={0}
      >
        {input.value} %
      </span>
    )
  }

  return (
    <InlineNumberWithUnitInput
      unit="%"
      source={`${name}.margin`}
      onBlur={toggleEditable}
      inputClasses={inputClasses}
    />
  )
}

const RateField = ({ price, margin }) => {
  const {
    input: { value: period },
  } = useField('period')

  const rate = (price * ((100 + margin) / 100)) / period

  return <span>{currencyText(rate)}</span>
}

const AlsoOfferLines = () => {
  const {
    fields: { remove, value: offerLines = [] },
  } = useFieldArray('offerLines')

  /* if (loading) {
    return <LinearProgress style={{ marginTop: '1em' }} />
  } */

  const handleRemove = idx => {
    remove(idx)
  }

  let lastParentPos = 0
  let lastChildPos = 0

  if (offerLines.length) {
    return (
      <>
        <Table size="small" stripedRows fixedHeader={false} fixedFooter={false}>
          <TableHead displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableCell colSpan={2}>Pos.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Hersteller</TableCell>
              <TableCell>SKU</TableCell>
              <TableCell>EAN</TableCell>
              <TableCell align="right">Marge</TableCell>
              <TableCell align="right">Monatliche Rate</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {offerLines.map((line, idx) => {
              if (line.parentPosition === null) {
                lastParentPos += 1
                lastChildPos = 0
              }
              let currentPosition = lastParentPos
              if (line.parentPosition !== null) {
                lastChildPos += 1
                currentPosition += `.${lastChildPos}`
              }

              return (
                <TableRow>
                  <TableCell>{currentPosition}</TableCell>
                  <TableCell>
                    <ImageCell line={line} />
                  </TableCell>
                  <TableCell>
                    <TruncatedTextField value={line.name} truncate={40} />
                  </TableCell>
                  <TableCell>{line.manufacturer}</TableCell>
                  <TableCell>{line.sku}</TableCell>
                  <TableCell>{line.ean}</TableCell>
                  <TableCell align="right">
                    <EditableMarginField name={`offerLines[${idx}]`} />
                  </TableCell>
                  <TableCell align="right">
                    <RateField price={line.price} margin={line.margin} />
                  </TableCell>
                  <TableCell align="right">
                    {line.parentPosition === null && (
                      <AddRelatedProductButton
                        relatedToProduct={line.resellableProduct}
                        idx={idx}
                      />
                    )}
                    <Button
                      color="secondary"
                      onClick={() => {
                        handleRemove(idx)
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <PositionUpdater />
      </>
    )
  }
  return <Typography variant="body1">Keine Angebotspositionen</Typography>
}

export default AlsoOfferLines
