export default {
  name: 'Bestellpositionsn',
  fields: {
    workplace: 'Arbeitsplatz |||| Arbeitsplätze',
    position: 'Pos',
    sku: 'SKU',
    description: 'Beschreibung',
    price: 'Einkaufspreis',
    margin: 'Marge %',
    defaultMargin: 'Standardmarge: %{defaultMargin}%',
    creditGroup: 'Finanzierungsobjektgruppe: %{creditGroup}',
    dllBrandName: 'DLL Asset Brand: %{dllBrandName}',
    dllAssetType: 'DLL Asset Typ: %{dllAssetType}',
    hasSerialNumbers: 'Seriennummernpflichtig',
    includeInProtectionPlan: 'Im Schutzbrief enthalten',
    purchasePriceUndiscounted: 'Gemittelter Einkaufspreis: %{price}',
    purchasePrice: 'Einkaufspreis',
    netPrice: 'Einzelverkaufspreis',
    actions: 'Aktionen',
    quantity: 'Menge',
    total: 'Gesamtverkaufspreis',
    marginEuro: 'Marge €',
    discount: 'Rabatt €',
    referenceString: 'Referenz: %{referenceString}',
    values: {
      includeInProtectionPlan: {
        true: 'Ja',
        false: 'Nein',
        undefined: '',
        null: '',
      },
      hasSerialNumbers: {
        true: 'Ja',
        false: 'Nein',
        undefined: '',
        null: '',
      },
    },
  },

  addDialog: {
    tabs: {
      general: {
        existing: 'Produkt aus Katalog',
        custom: 'Benutzerdefiniertes Produkt',
      },
      device_order_lines: {
        existing: 'Arbeitsplatz aus Katalog',
        custom: 'Benutzerdefinierter Arbeitsplatz',
      },
      product_order_lines: {
        existing: 'Zubehör aus Katalog',
        custom: 'Benutzerdefiniertes Zubehör',
      },
      service_order_lines: {
        existing: 'Service aus Katalog',
        custom: 'Benutzerdefinierter Service',
      },
    },
  },
}
