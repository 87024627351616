import { Button } from '@material-ui/core'
import { Refresh } from '@material-ui/icons'
import BackButton from 'Components/Common/Buttons/BackButton'
import PropTypes from 'prop-types'
import React from 'react'
import { translate } from 'react-admin'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'

const SortActions = ({ translate, resource, handleRefreshClick }) => (
  <RightAlignedCardActions>
    <BackButton to={`/${resource}`} />
    <Button color="primary" onClick={handleRefreshClick}>
      <Refresh />
      {translate('actions.refresh')}
    </Button>
  </RightAlignedCardActions>
)

SortActions.propTypes = {
  handleRefreshClick: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
}

export default translate(SortActions)
