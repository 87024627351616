import TranslatedTitle from 'Components/Common/TranslatedTitle'
import { useOrder } from 'OrderContext'
import PropTypes from 'prop-types'
import React from 'react'
import { SimpleShowLayout } from 'react-admin'
import Flags from './Flags/Flags'

const OrderFlagsShow = ({ orderType, flagTypes }) => {
  const { record, basePath, resource } = useOrder()

  return record ? (
    <SimpleShowLayout resource={resource} basePath={basePath} record={record}>
      <TranslatedTitle translateKey="resources.orders.tabs.flags" />
      <Flags orderType={orderType} flagTypes={flagTypes} record={record} />
    </SimpleShowLayout>
  ) : null
}

OrderFlagsShow.propTypes = {
  orderType: PropTypes.string.isRequired,
  flagTypes: PropTypes.object,
}

OrderFlagsShow.defaultProps = {
  flagTypes: {},
}

export default OrderFlagsShow
