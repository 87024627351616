import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from 'Components/Common/Buttons/Button'
import {
  ReferenceInput,
  SelectInput,
  TextInput,
  useTranslate,
} from 'react-admin'
import { Form } from 'react-final-form'
import { useApiClient } from 'Rest/fetchHydra'
import { useProcessContext } from 'Components/Aluxo/ActionDialogs/BulkCreateDeviceVariantsDialog/ProcessContext'
import StepContent from 'Components/Aluxo/ActionDialogs/BulkCreateDeviceVariantsDialog/BeforeCreationWizard/StepContent'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
}))

const DataEntryStep = ({
  stepperDetails: {
    steps,
    activeStep,
    needsConfirmation,
    cancelConfirmation,
    handleConfirmation,
    handleNext,
  },
  closeDialog,
}) => {
  const classes = useStyles()
  const { processStatus, states, setCreationResult } = useProcessContext()
  const t = useTranslate()
  const apiClient = useApiClient()

  function handleSubmit(values) {
    setCreationResult(
      values.devices.map((skus, index) => ({
        variants: skus.map(sku => ({
          sku,
          comment: null,
          label: t(
            'processes.bulkCreateDeviceVariantsDialog.tasks.labels.createVariant',
            { sku },
          ),
          status: 'pending',
          async execute(deviceId) {
            try {
              const { data } = await apiClient({
                endpoint: '/aluxo/create_device_variant',
                method: 'POST',
                body: {
                  device: deviceId,
                  sku,
                },
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                },
              })
              this.status = data.status
              this.comment = data.comment
            } catch (e) {
              console.log(e)
              this.status = 'error'
            }
          },
        })),
        status: 'pending',
        comment: null,
        label: t(
          'processes.bulkCreateDeviceVariantsDialog.tasks.labels.createDevice',
          { index: index + 1, sku: skus[0] },
        ),
        async execute() {
          try {
            const { data } = await apiClient({
              endpoint: '/aluxo/create_dummy_container_device',
              method: 'POST',
              body: {
                storeView: values.storeView,
                sku: skus[0],
                index: index + 1,
              },
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            })
            this.status = data.status
            if (data.status === 'success') {
              this.label = t(
                'processes.bulkCreateDeviceVariantsDialog.tasks.labels.deviceCreated',
                { name: data.device.name },
              )
              this.currentDeviceId = data.device.id
              this.name = data.device.name
            } else {
              this.comment = data.comment
            }
          } catch (e) {
            this.status = 'error'
          }
        },
      })),
    )
    handleNext()
  }

  return (
    <Form onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <StepContent
            steps={steps}
            activeStep={activeStep}
            needsConfirmation={needsConfirmation}
            cancelConfirmation={cancelConfirmation}
            handleConfirmation={handleConfirmation}
            content={
              <>
                <ReferenceInput
                  label="forms.bulkCreateDeviceVariants.fields.storeView"
                  source="storeView"
                  reference="store_views"
                >
                  <SelectInput optionText={choice => choice.name} />
                </ReferenceInput>
                <TextInput
                  helperText="forms.bulkCreateDeviceVariants.helper.skus"
                  label="forms.bulkCreateDeviceVariants.fields.skus"
                  name="devices"
                  fullWidth
                  multiline
                  rows={5}
                  parse={string =>
                    string
                      .split('\n#\n')
                      .map(arr => arr.split('\n').filter(a => a !== '#'))
                  }
                  format={skuArray => {
                    const string = skuArray
                      ?.map(innerArr => innerArr.join('\n'))
                      .join('\n#\n')
                    return string || ''
                  }}
                />
              </>
            }
            buttons={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  className={classes.button}
                  label="actions.next"
                />
                <Button
                  label="Schließen"
                  type="button"
                  disabled={processStatus === states.PROCESSING}
                  color="secondary"
                  variant="text"
                  onClick={closeDialog}
                />
              </>
            }
          />
        </form>
      )}
    </Form>
  )
}

export default DataEntryStep
