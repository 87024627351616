import { Card, CardContent } from '@material-ui/core'
import SystemLanguageSwitcher from 'Components/Common/Layout/SystemLanguageSwitcher'
import React from 'react'
import { useTranslate, Title } from 'react-admin'

const Profile = () => {
  const translate = useTranslate()
  return (
    <Card>
      <Title title={translate(`resources.profile.name`)} />
      <CardContent>
        <SystemLanguageSwitcher />
      </CardContent>
    </Card>
  )
}

export default Profile
