import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { Check } from '@material-ui/icons'
import Button from 'Components/Common/Buttons/Button'
import EditActions from 'Components/Common/EditActions'
import AcmpServiceAutoSuggest from 'Components/Common/InputFields/Also/AcmpServiceAutoSuggest'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  useMutation,
  useNotify,
  useTranslate,
} from 'react-admin'
import { useForm } from 'react-final-form'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

const AlsoServiceTitle = ({ record }) => get(record, 'name')

const HasBillingItemsField = ({ source, record = {} }) =>
  record.acmpService.billingItems.length > 0 ? <Check /> : null

HasBillingItemsField.defaultProps = { label: 'Preismapping' }

export const AlsoServiceList = props => (
  <List
    pagination={<CustomPagination />}
    aside={<LanguageSwitcherAside />}
    {...props}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="manufacturer" />
      <TextField source="category" />
      <DateField source="updatedAt" />
      <HasBillingItemsField />
      <EditButton />
    </Datagrid>
  </List>
)

const BillingItemRow = ({
  billingItem,
  mappedItem,
  externalId,
  updateItem,
  serviceId,
}) => {
  const t = useTranslate()
  const showNotification = useNotify()
  const [values, setValues] = useState({ chargeType: null, dependentOn: null })

  useEffect(() => {
    if (mappedItem) {
      setValues(mappedItem)
    }
  }, [mappedItem])

  const handleChange = ({ target: { value, name } }) => {
    setValues(values => ({ ...values, [name]: value || null }))
  }

  const [mutate, { loading }] = useMutation()

  const handleSubmit = () => {
    const { id, chargeType, dependentOn } = values
    const data = {
      resource: 'also_acmp_service_billing_items',
    }
    if (id) {
      data.type = 'update'
      data.payload = { id, data: { chargeType, dependentOn } }
    } else {
      data.type = 'create'
      data.payload = {
        data: {
          chargeType,
          dependentOn,
          serviceExternalId: externalId,
          priceableItemId: billingItem.PriceableItemId,
          service_reference_id: serviceId,
        },
      }
    }
    mutate(data, {
      onSuccess: ({ data }) => {
        showNotification(
          `notifications.alsoAcmpServiceBillingItems.save.success`,
        )

        updateItem(data)
      },
      onFailure: () => {
        showNotification(
          `notifications.alsoAcmpServiceBillingItems.save.fail`,
          'warning',
        )
      },
    })
  }

  return (
    <TableRow>
      <TableCell>{billingItem.PriceableItemId}</TableCell>
      <TableCell>{billingItem.ChargeType}</TableCell>
      <TableCell>{billingItem.IsUnitDependent ? 'yes' : 'no'}</TableCell>
      <TableCell>{billingItem.FieldDisplayName}</TableCell>
      <TableCell>{billingItem.FieldDisplayValue}</TableCell>
      <TableCell>{billingItem.UnitDependentFieldDisplayName}</TableCell>
      <TableCell>
        <FormControl>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="chargeType"
            displayEmpty
            value={values.chargeType || ''}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>{t('alsoServices.chargeTypes.none')}</em>
            </MenuItem>
            <MenuItem value="fixed">
              {t('alsoServices.chargeTypes.fixed')}
            </MenuItem>
            <MenuItem value="monthly">
              {t('alsoServices.chargeTypes.monthly')}
            </MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <FormControl>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="dependentOn"
            displayEmpty
            value={values.dependentOn || ''}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>{t('alsoServices.dependentOn.none')}</em>
            </MenuItem>
            <MenuItem value="workplaces">
              {t('alsoServices.dependentOn.workplaces')}
            </MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <Button
          onClick={handleSubmit}
          loading={loading}
          label="alsoServices.actions.save"
        />
      </TableCell>
    </TableRow>
  )
}

const BillingItems = props => {
  const { acmpService } = props.record
  const { billing, externalId, id } = acmpService
  const t = useTranslate()

  const [billingItems, setBillingItems] = useState(acmpService.billingItems)

  const handleUpdateItem = data => {
    setBillingItems(items => {
      const filteredItems = items.filter(item => item.id !== data.id)

      return [...filteredItems, data]
    })
  }

  return (
    <>
      <Table size="small" stripedRows fixedHeader={false} fixedFooter={false}>
        <TableHead displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableCell>{t('alsoServices.labels.priceableItemId')}</TableCell>
            <TableCell>{t('alsoServices.labels.chargeType')}</TableCell>
            <TableCell>{t('alsoServices.labels.isUnitDependent')}</TableCell>
            <TableCell>{t('alsoServices.labels.fieldDisplayName')}</TableCell>
            <TableCell>{t('alsoServices.labels.fieldDisplayValue')}</TableCell>
            <TableCell>
              {t('alsoServices.labels.unitDependentFieldDisplayName')}
            </TableCell>
            <TableCell>{t('alsoServices.labels.internalChargeType')}</TableCell>
            <TableCell>
              {t('alsoServices.labels.internalDependendOn')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody displayRowCheckbox={false}>
          {billing.map(billingItem => (
            <BillingItemRow
              key={billingItem.PriceableItemId}
              externalId={externalId}
              serviceId={id}
              updateItem={handleUpdateItem}
              billingItem={billingItem}
              mappedItem={billingItems.find(
                item => item.priceableItemId === billingItem.PriceableItemId,
              )}
            />
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export const AlsoServiceEdit = props => (
  <Edit
    actions={<EditActions />}
    title={<AlsoServiceTitle />}
    aside={<LanguageSwitcherAside />}
    {...props}
  >
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="category" />
      <TextInput source="description" />
      <TextInput source="manufacturer" />
      <BillingItems />
    </SimpleForm>
  </Edit>
)

const ServiceSuggest = () => {
  const { batch, change } = useForm()

  return (
    <AcmpServiceAutoSuggest
      source="acmpService_reference_id"
      label="resources.also_services.fields.selectService"
      onItemSelect={suggestion => {
        batch(() => {
          change('name', suggestion.displayName)
          change('category', suggestion.category)
          change('description', suggestion.shortDescription)
          change('manufacturer', suggestion.owner)
        })
      }}
    />
  )
}

export const AlsoServiceCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <ServiceSuggest />
      <TextInput source="name" />
      <TextInput source="category" />
      <TextInput source="description" />
      <TextInput source="manufacturer" />
    </SimpleForm>
  </Create>
)
