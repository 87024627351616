import React from 'react'
import { translate } from 'react-admin'

const StatusField = translate(({ record = {}, translate: t }) => {
  const orderType = record['@type'] === 'also_order' ? 'alsoOrders' : 'orders'

  return <span>{t(`${orderType}.markings.${record.marking}`)}</span>
})
StatusField.defaultProps = { addLabel: true }

export default StatusField
