import Button from 'Components/Common/Buttons/Button'
import { useOrder } from 'OrderContext'
import React from 'react'
import { useMutation, useNotify, useRefresh } from 'react-admin'

const AssignNewCustomerButton = () => {
  const { record } = useOrder()
  const showNotification = useNotify()
  const refreshView = useRefresh()

  const [mutate, { loading }] = useMutation()

  const handleNewCustomer = () => {
    mutate(
      {
        type: 'assignCustomerToOrder',
        resource: 'orders',
        payload: {
          data: {
            order: record['@id'],
          },
        },
      },

      {
        onSuccess: () => {
          showNotification('notifications.assignNewCustomer.success')
          refreshView()
        },
        onFailure: () => {
          showNotification('notifications.assignNewCustomer.fail', 'warning')
        },
      },
    )
  }

  return (
    <Button
      loading={loading}
      label="actions.assignNewCustomer"
      onClick={handleNewCustomer}
    />
  )
}

export default AssignNewCustomerButton
