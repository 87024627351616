import OrderActionItem from 'Components/Common/Actions/Order/OrderActionItem'
import { every, flatMap, get } from 'lodash'
import { useOrder } from 'OrderContext'
import React from 'react'
import { useMutation, useNotify, useRefresh } from 'react-admin'
import { useHistory } from 'react-router'

export const transitionEnabled = (
  transition,
  flags,
  manualTransitions,
  record,
) => {
  const { potentialFlags } = manualTransitions[transition]
  if (!potentialFlags) {
    return true
  }

  const orderFlags = flags.filter(f => f.value).map(f => f.type)
  const potFlags = potentialFlags(record).map(f => f.key)

  return every(potFlags, flag => orderFlags.find(f => f === flag))
}

const OrderActionTransitions = ({
  orderType,
  manualTransitions,
  permitted,
}) => {
  const { record } = useOrder()
  const showNotification = useNotify()
  const refreshView = useRefresh()
  const { transitions, flags } = record
  const [process] = useMutation()
  const history = useHistory()

  const handleClick = async ({ transition, customAction, context }) => {
    if (!transitionEnabled(transition, flags, manualTransitions, record)) {
      showNotification(`errors.transitionNotPossible`, 'warning')
      return
    }

    process(
      {
        type: 'processOrder',
        resource: `${record['@type']}s`.toLowerCase(),
        payload: {
          id: record.id,
          data: { action: transition, context },
        },
      },
      {
        onSuccess: () => {
          showNotification(`notifications.transitions.success.${transition}`)
          if (customAction) {
            customAction({ record, history })
          }
          refreshView()
        },
        onFailure: error => {
          const detailedMessage = get(error, 'response.data[hydra:description]')
          if (detailedMessage) {
            showNotification(
              `notifications.errors.${detailedMessage}`,
              'warning',
            )
          } else {
            showNotification(`notifications.transitions.fail`, 'warning')
          }
          refreshView()
        },
      },
    )
  }

  return flatMap(transitions, ({ enabled, transition, reasons }) => (
    <OrderActionItem
      key={transition}
      transition={transition}
      enabled={enabled}
      reasons={reasons}
      orderType={orderType}
      permitted={permitted}
      handleClick={handleClick}
      manualTransitions={manualTransitions}
    />
  ))
}

export default OrderActionTransitions
