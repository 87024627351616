import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { CloudUpload, Description } from '@material-ui/icons'
import { head } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useMutation, useNotify, useRefresh, useTranslate } from 'react-admin'
import Dropzone from 'react-dropzone'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components/macro'
import { useOrder } from 'OrderContext'

const StyledForm = styled.form`
  display: flex;
  align-items: flex-end;

  & > * {
    margin: 0 1em;
  }
`

const StyledDropzone = styled(Dropzone)`
  background-color: #f0f0f0;
  border: dashed;
  border-color: #c8c8c8;
  cursor: pointer;
  height: 144px;
  width: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DropZoneLink = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
  height: 20px;
  margin-top: 5px;
`

const DropZoneContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  & > *:first-child {
    margin-bottom: 1rem;
  }
`

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20em;
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`

const typeItems = [
  'Übernahmebestätigung',
  'Anlage zur Übernahmebestätigung',
  'Übernahmebestätigung (unterschrieben)',
  'Ausweiskopie',
  'Mietvertrag',
  'Mietvertrag (unterschrieben)',
  'Sonstige',
]

// const groupItems = ['', 'Übernahmebestätigung', 'Mietvertrag']

export const required = value => (value ? undefined : 'Required')

const MuiSelect = ({ input, items, label }) => (
  <FormControl>
    <InputLabel>{label}</InputLabel>
    <Select
      fullWidth
      onChange={event => input.onChange(event)}
      value={input.value}
    >
      {items.map(item => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

MuiSelect.propTypes = {
  input: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
}

export const MuiTextField = ({
  input: { name, onChange, value, ...restInput },
  meta,
  ...rest
}) => (
  <TextField
    {...rest}
    name={name}
    helperText={meta.touched ? meta.error : undefined}
    error={meta.error && meta.touched}
    inputProps={restInput}
    onChange={onChange}
    value={value}
  />
)

MuiTextField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

const AttachmentInput = () => {
  const t = useTranslate()
  const showNotification = useNotify()
  const refreshView = useRefresh()

  const { record } = useOrder()

  const [mutate, { loading }] = useMutation()

  const handleUpload = async values => {
    mutate(
      {
        type: 'createMultipart',
        resource: 'attachments',
        payload: {
          data: {
            order: record.id,
            ...values,
          },
        },
      },
      {
        onSuccess: async () => {
          showNotification('notifications.attachmentCreated')
          refreshView()
        },
        onFailure: () => {
          showNotification('notifications.attachment.fail', 'warning')
        },
      },
    )
  }

  return (
    <Form
      onSubmit={handleUpload}
      render={({ handleSubmit, form, invalid }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Field
            name="file"
            validate={required}
            render={({ input }) => (
              <div>
                <StyledDropzone
                  onDrop={acceptedFiles => {
                    input.onChange(head(acceptedFiles))
                  }}
                  multiple={false}
                >
                  {input.value ? (
                    <DropZoneContent>
                      <Description />
                      <DropZoneLink>{input.value.name}</DropZoneLink>
                    </DropZoneContent>
                  ) : (
                    <CloudUpload />
                  )}
                </StyledDropzone>
              </div>
            )}
          />
          <SelectContainer>
            <Field
              name="type"
              validate={required}
              component={MuiSelect}
              items={typeItems}
              label={t('resources.attachments.fields.type')}
            />

            <Field
              fullWidth
              component={MuiTextField}
              label={t('resources.attachments.fields.comment')}
              name="comment"
              type="text"
            />
          </SelectContainer>
          <Buttons>
            <Button disabled={invalid} color="primary" onClick={handleSubmit}>
              {loading ? t('state.uploading') : t('actions.upload')}
            </Button>
            <Button disabled={loading} onClick={form.reset}>
              {t('actions.cancel')}
            </Button>
          </Buttons>
        </StyledForm>
      )}
    />
  )
}

AttachmentInput.propTypes = {}

export default AttachmentInput
