import { isAlsoEdiReseller } from 'Lib/helper'
import React from 'react'
import { usePermissions } from 'react-admin'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import AlsoOfferLineAddButton from './AlsoOfferAddLineButton'

const AlsoOfferLineActions = () => {
  const { permissions = [] } = usePermissions()
  if (!isAlsoEdiReseller(permissions)) {
    return null
  }

  return (
    <RightAlignedCardActions>
      <AlsoOfferLineAddButton />
    </RightAlignedCardActions>
  )
}

export default AlsoOfferLineActions
