import Button from '@material-ui/core/Button'
import BackButton from 'Components/Common/Buttons/BackButton'
import ButtonLabel from 'Components/Common/Buttons/ButtonLabel'
import { get } from 'lodash'
import React from 'react'
import { CardActions, useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'

export const createParams = (params, data) =>
  params.map(({ param, value }) => `${param}=${get(data, value)}`).join('&')

export const EditActions = ({ data, addLinks, backLinks, children }) => {
  const t = useTranslate()
  return (
    <CardActions>
      {addLinks(data).map(({ label, link, icon }) => (
        <Button
          color="primary"
          component={Link}
          to={link}
          style={{ overflow: 'inherit' }}
        >
          {icon}
          <ButtonLabel>{t(label)}</ButtonLabel>
        </Button>
      ))}
      {backLinks(data).map(({ label, link }) => (
        <BackButton to={link} label={label} />
      ))}
      {React.Children.toArray(children).map(child =>
        React.cloneElement(child, { data }),
      )}
    </CardActions>
  )
}

EditActions.defaultProps = {
  addLinks: () => [],
  backLinks: () => [],
  hideDelete: false,
}

export const CreateActions = ({ children }) => (
  <CardActions>
    {React.Children.toArray(children).map(child => React.cloneElement(child))}
  </CardActions>
)
