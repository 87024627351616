import alsoResources from './alsoResources'
import aluxoResources from './aluxoResources'

// Maybe remove it completly @Nik Lampe?
// eslint-disable-next-line no-unused-vars
const monthlyPrices = ['12', '24', '36'].map(p => ({
  'option[@id]': `Konfiguration bei ${p} Monaten Laufzeit`,
  price: `Preis bei ${p} Monaten Laufzeit`,
  margin: `Marge bei ${p} Monaten Laufzeit`,
  netPrice: `Netto Preis bei ${p} Monaten Laufzeit`,
}))

export default {
  website_data: {
    name: 'Seiteninhalte',
  },
  testimonials: {
    name: 'Kundenstimme |||| Kundenstimmen',
    forcedCaseName: 'Kundenstimme |||| Kundenstimmen',
    invite: 'Möchten Sie eine neue Kundenstimme anlegen?',
    fields: {
      name: 'Name',
      comment: 'Kommentar',
      imageFile: 'Neues Logo',
      'storeView[@id]': 'Store View',
      storeView: {
        name: 'Store View',
      },
    },
  },
  benefits: {
    name: 'Vorteile',
    fields: {
      title: 'Überschrift',
      text: 'Text',
      icon: 'Icon',
      'storeView[@id]': 'Store View',
      storeView: {
        name: 'Store View',
      },
    },
  },
  team_members: {
    name: 'Team',
    fields: {
      name: 'Name',
      companyPosition: 'Position',
      email: 'E-Mail',
      imageFile: 'Bild',
      'storeView[@id]': 'Store View',
      storeView: {
        name: 'Store View',
      },
    },
  },
  marketing_logos: {
    name: 'Marketing Logos',
    fields: {
      name: 'Name',
      imageFile: 'Bild',
      'storeView[@id]': 'Store View',
      storeView: {
        name: 'Store View',
      },
    },
  },
  dashboard: {
    name: 'Dashboard',
  },
  resellers: {
    name: 'Wiederverkäufer',
    tabs: {
      general: 'Allgemein',
      address: 'Adresse',
      credentials: 'Zugangsdaten zu Drittanbietern',
      storeViews: 'Store Views',
    },
    fields: {
      contactEmail: 'Kontakt E-Mail',
      platformName: 'Name der Platform',
      hirePurchase: 'Kauf über Finanzierung',
      emailFooter: 'E-Mail Signatur',
      primaryDomain: 'Webseite',
      createdAt: 'Angelegt am',
      type: 'Typ',
      address: {
        company: 'Firma',
        firstName: 'Vorname',
        lastName: 'Nachname',
        street: 'Anschrift',
        postcode: 'PLZ',
        city: 'Ort',
        email: 'E-Mail',
        phone: 'Telefon (Zentrale)',
        salutation: 'Anrede',
      },
      credentials: {
        username: 'Benutzername',
        renewPassword: 'Neues Passwort setzen',
        password: 'Passwort',
        prodMode: 'Albis Produktivmodus aktivieren',
      },
      apiKey: 'API Schlüssel',
      netlifyInstanceId: 'Netlify Instanz ID',
    },
  },
  reseller_smtp_configurations: {
    name: 'SMTP Konfiguration',
    fields: {
      username: 'Benutzername',
      password: 'Passwort',
      host: 'Postausgangserver',
      port: 'Port',
      encryption: 'Verschlüsselung',
      senderName: 'Absender',
      senderAddress: 'E-Mail',
      htmlTemplate: 'HTML Vorlage',
    },
  },
  reseller_corporate_identities: {
    name: 'Corporate Identity',
    fields: {
      firstColor: 'Primärfarbe',
      secondColor: 'Sekundärfarbe',
      companyLogo: 'Logo der Firma',
      companyLogoFile: 'Neues Logo hochladen',
    },
  },
  reseller_website_configurations: {
    name: 'Store Views',
    tabs: {
      general: 'Allgemein',
      website: 'Webseite',
      seo: 'SEO',
      smtp: 'SMTP',
    },
    fields: {
      firstColor: 'Primärfarbe',
      secondColor: 'Sekundärfarbe',
      thirdColor: 'Akzentfarbe 1',
      forthColor: 'Akzentfarbe 2',
      outdatedBrowserBackgroundColor: 'Farbe für Outdated Browser Meldung',
      footerCode: 'Footer HTML',
      websiteLogo: 'Logo der Webseite',
      companyLogo: 'Logo der Firma',
      websiteLogoOriginal: 'Logo der Webseite',
      companyLogoOriginal: 'Logo der Firma',
      websiteLogoFile: 'Neues Webseiten-Logo hochladen',
      companyLogoFile: 'Neues Firmenlogo hochladen',
      favicon: 'Favicon',
      facebookURL: 'Facebook URL',
      googlePlusURL: 'Google Plus URL',
      twitterURL: 'Twitter URL',
      youtubeURL: 'YouTube URL',
      xingURL: 'Xing URL',
      instagramURL: 'Instagram URL',
      linkedInURL: 'LinkedIn URL',
      contactUrl: 'Kontakt URL',
      contactEmail: 'Kontakt E-Mail Adresse',

      googleAnalyticsCode: 'Google Analytics Code',
      googleTagManagerID: 'Google Tag Manager ID',
      googleVerification: 'Google Verifikation',

      headers: {
        social: 'Soziale Netzwerke',
        seo: 'Suchmaschinenoptimierung',
        others: 'Weitere Einstellungen',
        productOverviewMetadata: 'Produkt Übersicht Meta',
      },
      benefits: {
        title: 'Vorteile',
        text: 'Titel',
      },
      sectionsVisible: {
        title: 'Startseitenbereiche',
        stats: 'Statistiken anzeigen',
        benefits: 'Vorteile anzeigen',
        contact: 'Kontaktformular anzeigen',
        featured: 'Angebote anzeigen',
        marketingLogos: 'Marketing Logos anzeigen',
        slider: 'Slider anzeigen',
        team: 'Team anzeigen',
        testimonials: 'Kundenstimmen anzeigen',
        seo: 'SEO Text auf Startseite anzeigen',
      },
      stats: {
        title: 'Statistiken',
        type: 'Typ',
        number: 'Anzahl',
      },
      seoConfiguration: {
        'serviceExtensionOverviewPage[@id]':
          'Seite für Service-Erweiterungen Übersicht',

        productOverviewSlug: 'URL für Produktübersicht',
        productOverviewMetadata: 'Metadaten für Produktübersicht',
        homepageSeoTitle: 'Startseite SEO Text Titel',
        homepageSeoText: 'Startseite SEO Text Inhalt',
        headers: {
          productOverviewMetadata: 'Metadaten für Produktübersicht',
        },
      },
      smtpConfiguration: {
        active: 'Eigene SMTP Zugangsdaten',
        username: 'Benutzername',
        password: 'Passwort',
        host: 'Postausgangserver',
        port: 'Port',
        encryption: 'Verschlüsselung',
        senderName: 'Absender',
        senderAddress: 'E-Mail',
        htmlTemplate: 'HTML Vorlage',
      },
    },
  },
  staff: {
    name: 'Persönliche Konfiguration',
    fields: {
      smtpConfiguration: {
        active: 'Persönliche SMTP Konfiguration aktivieren',
        username: 'Benutzername',
        password: 'Passwort',
        host: 'Postausgangserver',
        port: 'Port',
        encryption: 'Verschlüsselung',
        senderName: 'Absender',
        senderAddress: 'E-Mail',
        htmlTemplate: 'HTML Vorlage',
      },
    },
  },
  store_views: {
    name: 'Store Views',
    tabs: {
      general: 'Allgemein',
      website: 'Webseite',
      seo: 'SEO',
      smtp: 'SMTP',
      notes: 'Notizen im Checkoutprozess',
    },
    fields: {
      firstColor: 'Primärfarbe',
      secondColor: 'Sekundärfarbe',
      thirdColor: 'Akzentfarbe 1',
      forthColor: 'Akzentfarbe 2',
      outdatedBrowserBackgroundColor: 'Farbe für Outdated Browser Meldung',
      footerCode: 'Footer HTML',

      favicon: 'Favicon',
      websiteLogoOriginal: 'Logo der Webseite',
      companyLogoOriginal: 'Logo der Firma',
      websiteLogo: 'Logo der Webseite',
      companyLogo: 'Logo der Firma',
      websiteLogoFile: 'Neues Webseiten-Logo hochladen',
      companyLogoFile: 'Neues Firmenlogo hochladen',
      facebookURL: 'Facebook URL',
      googlePlusURL: 'Google Plus URL',
      twitterURL: 'Twitter URL',
      youtubeURL: 'YouTube URL',
      xingURL: 'Xing URL',
      instagramURL: 'Instagram URL',
      linkedInURL: 'LinkedIn URL',
      googleAnalyticsCode: 'Google Analytics Code',
      googleTagManagerID: 'Google Tag Manager ID',
      googleVerification: 'Google Verifikation',
      contactUrl: 'Kontakt URL',
      contactEmail: 'Kontakt E-Mail Adresse',

      headers: {
        social: 'Soziale Netzwerke',
        seo: 'Suchmaschinenoptimierung',
        others: 'Weitere Einstellungen',
      },
      benefits: {
        title: 'Vorteile',
        text: 'Titel',
      },
      sectionsVisible: {
        title: 'Startseitenbereiche',
        stats: 'Statistiken anzeigen',
        benefits: 'Vorteile anzeigen',
        contact: 'Kontaktformular anzeigen',
        featured: 'Angebote anzeigen',
        marketingLogos: 'Marketing Logos anzeigen',
        slider: 'Slider anzeigen',
        team: 'Team anzeigen',
        testimonials: 'Kundenstimmen anzeigen',
        seo: 'SEO Text auf Startseite anzeigen',
      },
      notes: {
        checkout: {
          billingAddress: 'Notiz zur Rechnungsadresse',
          shippingAddress: 'Notiz zur Lieferadresse',
          bankDetails: 'Notiz zu Kontodaten',
          alsoAdditionalInfo: 'Notiz zu Domain und UStID',
          comment: 'Notiz zum Kommentarfeld',
          ceo: 'Notiz zum Geschäftführerformular',
          account: 'Notiz zum Accountformular',
        },
      },
      stats: {
        title: 'Statistiken',
        type: 'Typ',
        number: 'Anzahl',
      },
      seoConfiguration: {
        'serviceExtensionOverviewPage[@id]':
          'Seite für Service-Erweiterungen Übersicht',

        productOverviewSlug: 'URL für Produktübersicht',
        productOverviewMetadata: 'Metadaten für Produktübersicht',
        homepageSeoTitle: 'Startseite SEO Text Titel',
        homepageSeoText: 'Startseite SEO Text Inhalt',
        headers: {
          productOverviewMetadata: 'Metadaten für Produktübersicht',
        },
      },
      smtpConfiguration: {
        active: 'Eigene SMTP Zugangsdaten',
        username: 'Benutzername',
        password: 'Passwort',
        host: 'Postausgangserver',
        port: 'Port',
        encryption: 'Verschlüsselung',
        senderName: 'Absender',
        senderAddress: 'E-Mail',
        htmlTemplate: 'HTML Vorlage',
      },
      netlifyInstanceId: 'Netlify ID',
      lastDeploy: 'Letzter Deploy',
      lastDeployId: 'ID des letzten Deployments',
      production: 'Produktiver Storeview',
      resellerType: 'Typ',
    },
  },
  attachments: {
    name: 'Anhang |||| Anhänge',
    new: 'Neuen Anhang anlegen',
    all: 'Alle Anhänge',
    fields: {
      createdAt: 'Datum / Uhrzeit',
      type: 'Typ',
      groups: 'Email Anhang',
      current: 'Aktuelle Version',
      comment: 'Kommentar',
      user: {
        email: 'Benutzer',
      },
    },
  },
  email_templates: {
    name: 'E-Mail Vorlagen',
    renderer: 'Vorlagenart',
    template: 'Vorlage',
    renderers: {
      offer: { name: 'Angebot', templates: { create_offer: 'create_offer' } },
      rent_order: {
        name: 'Mietbestellung',
        templates: {
          opened: 'opened',
          created: 'created',
          canceled: 'canceled',
          order_confirmed: 'order_confirmed',
          shipped: 'shipped',
          confirmed: 'confirmed',
          delivered: 'delivered',
          invoice_to_albis: 'invoice_to_albis',
        },
      },
      buy_order: {
        name: 'Kaufbestellung',
        templates: {
          opened: 'opened',
          order_confirmed: 'order_confirmed',
          shipped: 'shipped',
          invoice_to_customer: 'invoice_to_customer',
          canceled: 'canceled',
        },
      },
      also_order: {
        name: 'ALSO Bestellung',
        templates: {
          opened: 'opened',
          transmitted: 'transmitted',
          also_online_service_notification: 'also_online_service_notification',
        },
      },
    },
  },
  reseller_email_templates: {
    name: 'System E-Mail Vorlagen',
    fields: {
      subject: 'Betreff',
      body: 'Inhalt',
      placeholders: 'verfügbare Platzhalter',
    },
  },
  custom_email_templates: {
    name: 'Eigene E-Mail Vorlagen',
    fields: {
      subject: 'Betreff',
      body: 'Inhalt',
      placeholders: 'verfügbare Platzhalter',
    },
  },
  reseller_email_events: {
    name: 'E-Mail Historie',
    fields: {
      createdAt: 'Datum / Uhrzeit',
      type: 'Status',
      user: {
        email: 'Benutzer',
      },
    },
    text: {
      emailSent: 'E-Mail gesendet: %{type}',
    },
  },
  reseller_transition_events: {
    name: 'Bestellungs-Historie',
    fields: {
      createdAt: 'Datum / Uhrzeit',
      from: 'Von',
      to: 'Nach',
      user: {
        email: 'Benutzer',
      },
    },
    text: 'Übergang von %{fromType} zu %{toType}',
  },
  reseller_flag_events: {
    text: {
      albis: '%{type}: %{flag} (%{number})',
      generic: '%{type}: %{flag}',
    },
  },
  order_comments: {
    name: 'Kommentar',
    fields: {
      createdAt: 'Datum / Uhrzeit',
      content: 'Kommentar',
      user: {
        email: 'Benutzer',
      },
    },
    labels: {
      newComment: 'Neuer Kommentar',
    },
  },

  customers: {
    name: 'Kunde |||| Kunden',
    loadOrders: 'Bestellungen werden geladen...',
    noOrders: 'Keine Bestellungen',
    fields: {
      search: 'Suche (Name/Firma)',
      email: 'E-Mail',
      customerNumber: 'Kundennummer',
      referencesRequired: 'Bestellpositionsreferenzen benötigt',
      autopilotExport: 'Autopilot Export',

      storeView: {
        name: 'Store View',
      },
      billingAddress: {
        name: 'Rechnungsadresse',
      },
      shippingAddress: {
        name: 'Lieferadresse',
      },
      contactAddress: {
        name: 'Ansprechpartner',
      },
      addressSets: {
        name: 'Lieferadressen',
      },
      addresses: {
        saluation: 'Anrede',
        name: 'Adressen',
        email: 'E-Mail Adresse',
        ccEmail: 'CC E-Mail Adressen',
        legalForm: 'Rechtsform',
        salutation: 'Anrede',
        firstName: 'Vorname',
        lastName: 'Nachname',
        addressAddon: 'Addresszusatz',
        company: 'Firma',
        companyShort: 'Firma (max. 50 Zeichen)',
        street: 'Straße',
        postcode: 'PLZ',
        city: 'Ort',
        phone: 'Telefon',
        emailType: 'E-Mail Typ',
      },
      ceo: {
        saluation: 'Anrede',
        name: 'Geschäftsführer',
        email: 'E-Mail Adresse',
        salutation: 'Anrede',
        firstName: 'Vorname',
        lastName: 'Nachname',
        street: 'Straße',
        postcode: 'PLZ',
        city: 'Ort',
        phone: 'Telefon',
        fax: 'Fax',
        birthdate: 'Geburtsdatum',
      },
      bankDetails: {
        name: 'Bankverbindung',
        bank: 'Kreditinstitut',
        iban: 'IBAN',
        bic: 'BIC',
      },
      shipToBilling: 'Lieferadresse wie Rechnungsadresse',
    },
    emailTypes: {
      formal: 'Formell',
      informal: 'Informell',
      personal: 'Persönlich',
    },
    validation: {
      zip: 'Bitte geben Sie eine gültige Postleitzahl ein',
      iban: 'Bitte geben Sie eine gültige IBAN ein',
    },
    notifications: {
      noOrders: 'Bisher keine Bestellungen getätigt',
    },
  },

  discounts: {
    name: 'Rabatt',
    common: {
      product: 'Produkt',
      quantity: 'Anzahl',
      type: 'Rabatttyp',
      expiresAt: 'Gültig bis',
      discountPerDevice: 'Rabatt/Gerät',
      discountSum: 'Gesamtrabatt',
    },
    cas: {
      name: 'Projektpreis |||| Projektpreise',
      expiredProject: 'Abgelaufenes Projekt |||| Abgelaufene Projekte',
      product: 'Produkt',
      cas: 'CAS-Nummer',
      expiresAt: 'Gültig bis',
      expiredAt: 'Abgelaufen am',
      ncabExpiresAt: 'NCAB gültig bis',
      ncabExpiredAt: 'NCAB abgelaufen am',
      dealReg: 'Deal Reg',
      acceleratePricing: 'Accelerate Pricing',
      ncab_short: 'NCAB',
      add_product: 'Produkt hinzufügen',
    },
    aluxo: {
      name: 'Benutzerdefinierte Marge |||| Benutzerdefinierte Margen',
      activate: 'aktivieren',
      itScopeDeviceMargin: 'Gerätemarge',
      itScopeProductMargin: 'Zubehörmarge',
      itScopeServiceExtensionsMargin: 'Service-Erweiterungsmarge',
      protectionPlanMargin: 'Schutzbriefmarge',
      cloudServiceMargin: 'Cloud-Servicemarge',
      managedServiceMargin: 'Managed-Servicemarge',
      defaultMargin: 'Standardmarge',
    },
  },

  pages: {
    name: 'CMS Seiten ',
    fields: {
      slug: 'URL',
      title: 'Titel',
      content: 'Inhalt',
      'storeView[@id]': 'Store View',
      storeView: {
        name: 'Store View',
      },
    },
  },
  links: {
    name: 'Links ',
    fields: {
      name: 'Name',
      to: 'Ziel',
      area: 'Bereich',
      active: 'Aktiv',
      specialType: 'Typ',
      'storeView[@id]': 'Store View',
      storeView: {
        name: 'Store View',
      },
    },
    area: {
      header: 'Header',
      footer: 'Footer',
      footerBar: 'Fußzeile',
      mainmenu: 'Hauptmenü',
    },
    specialType: {
      terms: 'AGB',
      privacyStatement: 'Datenschutzerklärung',
      impressum: 'Impressum',
    },
  },
  slides: {
    name: 'Slider',
    fields: {
      image: 'Bild',
      imageFile: 'Neues Bild',
      'storeView[@id]': 'Store View',
      storeView: {
        name: 'Store View',
      },
    },
  },
  knowledge_items: {
    name: 'Knowledge Base',
    fields: {
      name: 'Name',
      comment: 'Beschreibung',
    },
  },
  string_translations: {
    name: 'Übersetzungen',
    fields: {
      key: 'Key',
      content: 'Übersetzung',
      type: 'Typ',
      updatedAt: 'Zuletzt geändert',
    },
  },
  profile: {
    name: 'Profil',
    systemlanguage: 'Sprache',
  },
  common_product_attribute_groups: {
    name: 'Produktattributgruppen',
    fields: {
      name: 'Name',
      displayName: 'angezeigter Name',
      children: 'Anzahl Attributswerte',
    },
  },
  common_product_attribute_values: {
    name: 'Produktattribute',
    state: {
      implicit: 'von CNet',
      explicit: 'Manuell zugewiesen',
    },
    fields: {
      value: 'Wert',
      shortValue: 'Kurzform',
      group_reference_id: 'Gruppe',
      group: {
        name: 'Gruppe',
      },
      children: 'Anzahl CNet Datenpunkte',
    },
  },
  common_cnet_metadata_values: {
    name: 'CNet Metadaten',
    fields: {
      value: 'Wert',
      group: {
        name: 'Gruppe',
        parent: {
          name: 'Übergruppe',
        },
      },
      product_attribute_value: {
        value: 'Verknüpftes Produktattribut',
      },
    },
  },

  ...aluxoResources,
  ...alsoResources,
}
