import { Warning, Forward } from '@material-ui/icons'
import { red } from '@material-ui/core/colors'

export const flagTypes = {
  transmitted: {
    key: 'transmitted',
  },
}

export const markings = {
  opened: {
    key: 'opened',
    statusBarIndex: 0,
  },
  transmitted: {
    key: 'transmitted',
    statusBarIndex: 1,
  },
  completed: {
    key: 'completed',
    statusBarIndex: 2,
  },
  cancelled: {
    key: 'cancelled',
  },
}

export const manualTransitions = {
  transmit: {
    key: 'transmit',
    name: 'transmit',
    target: 'transmitted',
    icon: Forward,
    color: undefined,
  },
  complete: {
    key: 'complete',
    name: 'complete',
    target: 'completed',
    icon: Forward,
    color: undefined,
  },
  cancel: {
    key: 'cancel',
    name: 'cancel',
    target: 'cancelled',
    icon: Warning,
    color: red[500],
  },
}
