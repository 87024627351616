import Fuse from 'fuse.js'

export default function getSuggestions({ activeProducts, inputValue }) {
  const searchValue = (inputValue || ''.trim()).toLowerCase()

  const fuse = new Fuse(activeProducts, {
    keys: ['variants.sku', 'name'],
    includeScore: true,
    includeMatches: true,
    // minMatchCharLength: 1,
  })

  const results = fuse.search(searchValue)

  return results.map(({ item }) => ({
    id: item.id,
    title: `${item.manufacturer} ${item.name}`,
    resellerActive: item.resellerActive,
    suggestions: item.variants.map(variant => ({
      id: variant.id,
      sku: variant.sku,
      value: variant.id,
      label: `${variant.sku} - ${variant.name}`,
      resellerActive: item.resellerActive,
    })),
  }))
}
