import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import currencyText from 'Lib/currencyText'
import { Check, Delete, Edit } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import { Field, Form } from 'react-final-form'
import renderCurrencyInputField from 'Components/Common/InputFields/renderComponents/renderCurrencyInput'
import renderNumberInput from 'Components/Common/InputFields/renderComponents/renderNumberInput'
import OrderAlternativeMenuItem from './OrderAlternativeMenuItem'

const ITScopeOrderTableEntry = ({
  id,
  entry,
  onChange,
  onRemove,
  onSetSupplier,
}) => {
  const [edit, setEdit] = useState(false)

  const toggleEdit = values => {
    onChange(values)
    setEdit(edit => !edit)
  }

  const handleChange = event => {
    onSetSupplier(event.target.value)
  }

  return (
    <Form
      onSubmit={toggleEdit}
      initialValues={{
        quantity: entry.quantity,
        price: parseFloat(entry.price.price),
      }}
    >
      {({ handleSubmit }) => (
        <TableRow key={id}>
          <TableCell component="th" scope="row">
            <a
              href={`${entry.deepLink}${entry.puid}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {id}
            </a>
          </TableCell>
          <TableCell>{entry.sku}</TableCell>
          <TableCell>{entry.name}</TableCell>
          <TableCell numeric>
            {edit ? (
              <Field
                name="quantity"
                label="Anzahl"
                component={renderNumberInput}
              />
            ) : (
              <Field
                name="quantity"
                label="Anzahl"
                render={({ input: { value } }) => value}
              />
            )}
          </TableCell>
          <TableCell numeric>
            {edit ? (
              <Field
                name="price"
                label="Preis"
                component={renderCurrencyInputField}
              />
            ) : (
              <Field
                name="price"
                label="Preis"
                render={({ input: { value } }) => currencyText(value)}
              />
            )}
          </TableCell>
          <TableCell numeric>
            {entry.discount && currencyText(entry.discount)}
          </TableCell>
          <TableCell>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value=""
              onChange={handleChange}
              displayEmpty
            >
              <MenuItem value="">
                <em>Bestellen bei...</em>
              </MenuItem>
              {entry.alternatives
                .filter(a => a.supplierId !== entry.price.supplierId)
                .map((a, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <MenuItem value={a} key={index}>
                    <OrderAlternativeMenuItem
                      a={a}
                      totalQuantity={entry.totalQuantity}
                    />
                  </MenuItem>
                ))}
            </Select>
            <IconButton onClick={handleSubmit}>
              {edit ? <Check /> : <Edit />}
            </IconButton>
            <IconButton onClick={onRemove}>
              <Delete />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
    </Form>
  )
}

ITScopeOrderTableEntry.propTypes = {
  id: PropTypes.string.isRequired,
  entry: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    puid: PropTypes.number.isRequired,
    sku: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    manufacturerSKU: PropTypes.string.isRequired,
    price: PropTypes.shape({
      supplierId: PropTypes.string.isRequired,
      supplierSKU: PropTypes.string.isRequired,
      ranking: PropTypes.number.isRequired,
      puid: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      priceInclShipping: PropTypes.number.isRequired,
      stock: PropTypes.number.isRequired,
    }).isRequired,
    alternatives: PropTypes.arrayOf(
      PropTypes.shape({
        supplierId: PropTypes.string.isRequired,
        supplierSKU: PropTypes.string.isRequired,
        ranking: PropTypes.number.isRequired,
        puid: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        priceInclShipping: PropTypes.number.isRequired,
        stock: PropTypes.number.isRequired,
      }),
    ).isRequired,
    totalPrice: PropTypes.number.isRequired,
    totalPriceInclShipping: PropTypes.number.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSetSupplier: PropTypes.func.isRequired,
}

export default ITScopeOrderTableEntry
