import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import RegisterWizard from 'Components/Also/Offers/RegisterWizard'
import PasswordRequest from 'Components/Common/Auth/PasswordRequest'
import PasswordReset from 'Components/Common/Auth/PasswordReset'
import Sort from 'Components/Common/Sorting/Sort'
import Profile from 'Components/Common/Profile/Profile'
import AluxoActionDialogsBulkCreateDeviceVariantsDialog from 'Components/Aluxo/ActionDialogs/BulkCreateDeviceVariantsDialog/Dialog'
import AluxoCustomerCreateCasProjectDialog from 'Components/Aluxo/ActionDialogs/CustomerCreateCasProjectDialog/Dialog'
import StringTranslationsEdit from 'Components/Common/StringTranslations/StringTranslationsEdit'
import AlsoActionDialogsCreateProductWizardDialog from 'Components/Also/ActionDialogs/CreateProductVariant/Dialog'
import RenderEmailTemplates from 'Components/Common/EmailTemplates/Render'
import ITScopeOrderDialog from 'Components/Aluxo/ITScope/ITScopeOrderDialog'

export default [
  <Route
    exact
    path="/also_products/create/:id"
    component={AlsoActionDialogsCreateProductWizardDialog}
  />,
  <Route
    exact
    path="/devices/create/bulk"
    component={AluxoActionDialogsBulkCreateDeviceVariantsDialog}
  />,
  <Route
    exact
    path="/customers/:customerId/createCasProject"
    component={AluxoCustomerCreateCasProjectDialog}
  />,
  <Route
    exact
    path="/string_translations"
    component={StringTranslationsEdit}
  />,
  <Route exact path="/profile" component={Profile} />,
  <Route exact path="/:resource/sort" component={Sort} />,
  <Route exact path="/:resource/sort/:group" component={Sort} />,

  <Route exact path="/external_protection_plans">
    <Redirect to="/external_protection_plan_devices" />
  </Route>,
  <Route exact path="/external_protection_plan_devices/create">
    <Redirect to="/external_protection_plans/create" />
  </Route>,
  <Route exact path="/password/request" component={PasswordRequest} noLayout />,
  <Route
    exact
    path="/password/reset/:token"
    component={PasswordReset}
    noLayout
  />,
  <Route exact path="/email_templates" component={RenderEmailTemplates} />,
  <Route
    exact
    path="/orders/:id/order-it-scope"
    component={ITScopeOrderDialog}
  />,
  <Route exact path="/edi/register" component={RegisterWizard} noLayout />,
]
