export default {
  back: 'Zurück',
  back_to_order: 'Zurück zur Bestellung',
  back_to_device: 'Zurück zum Gerät',
  add_attribute: 'Attribut hinzufügen',
  add_variant: 'Variante hinzufügen',
  add_information_tab: 'Beschreibung hinzufügen',
  add_value: 'Wert hinzufügen',
  add_product_order_lines: 'Zubehör',
  add_device_order_lines: 'Arbeitsplatz',
  add_service_order_lines: 'Service',
  add_protection_plan_order_lines: 'Schutzbrief',
  add_service_extension_order_lines: 'Garantieerweiterung',
  add_line: 'Position hinzufügen',
  add_generic_order_lines: 'Schnell hinzufügen',
  add_promo_bundle_order_lines: 'Bundle hinzufügen',
  add_text_order_lines: 'Freitext Position',
  process_order: 'Prozessieren',
  send_email: 'E-Mail Versenden',
  create_new: 'Neu anlegen',
  refresh: 'Aktualisieren',
  remove: 'Entfernen',
  delete: 'Entfernen',
  deleteEntity: '%{name} entfernen',
  mark_as_shipped: 'Verschickt',
  undo_mark_as_shipped: 'Nicht Verschickt',
  officeConfigured: 'Office eingerichtet',
  undoOfficeConfigured: 'Office nicht eingerichtet',
  upload: 'Hochladen',
  calculate: 'Berechnen',
  dropFile: 'Zum Hochladen Dokument hier hineinziehen',
  cancel: 'Abbrechen',
  discardChanges: 'Änderungen verwerfen',
  submit: 'Speichern',
  submitting: 'Wird gespeichert',
  downloadAll: 'Alle herunterladen',
  downloadAllAsArchive: 'Alle als Archiv herunterladen',
  testSMTP: 'SMTP Einstellungen testen',
  copyAccessoryToVariants: 'Zubehör Zuweisung auf Varianten kopieren',
  itScopeDistributorRefresh: 'Distributoren abrufen',
  itScopeUpdatePrices: 'Preise jetzt abrufen',
  updateItScopeMetadata: 'IT Scope Produkt ID abrufen',
  updateItScopePrice: 'Preis von IT Scope abrufen',
  bulkUpdateItScopeMetadata:
    'IT Scope Produkt IDs für alle Produkte aktualisieren',
  itScopeCheckStock: 'Verfügbarkeiten aktualisieren',
  itScopeCreateCart: 'Warenkorb an IT Scope übermitteln',
  jumpToItScopeCart: 'Warenkorb in IT Scope anzeigen',
  sort: 'Sortieren',
  testCredentials: 'Zugangsdaten prüfen',
  confirmDelete: 'Element löschen?',
  confirm: 'Bestätigen',
  cancelExternalProtectionPlanDevice: 'Schutzbrief stornieren',
  createAlsoProductVariant: 'Produktvariante hinzufügen',
  updateAlsoApiData: 'Daten aus ALSO Cloud Marketplace aktualisieren',
  createAlsoResellerProduct: 'Produkt hinzufügen',
  createAllAlsoResellerProduct: 'Alle Produkte hinzufügen',
  createITScopeOrder: 'Bestellung bei ITScope anlegen',
  previewITScopeOrder: 'Bestellung bei ITScope (Vorschau)',
  orderManually: 'Manuell bestellen',
  orderAutomatically: 'Automatisch bestellen',
  resendMail: 'Erneut Senden',
  quickAddOrderLine: 'Schnell hinzufügen',
  refreshAcmpTradingModelMargins: 'Händlermodell Margen aktualisieren',
  findITScopeBundle: 'IT Scope Bundleartikel aktualisieren',
  detectDLLMetaData: 'DLL Daten ermitteln',
  addProtectionPlan: 'Schutzbrief',
  addProtectionPlanWithTheftProtection: 'Schutzbrief mit Diebstahlschutz',
  enableMails: 'E-Mail Versand aktivieren',
  suppressMails: 'E-Mail Versand unterbinden',
  sendAutopilotMail: 'Autopilot Export E-Mail senden',
  duplicateAsOffer: 'Als Angebot duplizieren',
  createAlsoResellerService: 'Service zum Portfolio hinzufügen',
  assignNewCustomer: 'Neuer Kunde',
  clearStoreViewCache: 'Frontend Cache leeren',
  warmupStoreViewCache: 'Frontend Cache aktualisieren',
  activateStoreViewCache: 'Frontend Cache aktivieren',
  deactivateStoreViewCache: 'Frontend Cache deaktivieren',
  also: {
    createProductVariant: 'Produktvariante anlegen',
  },
  assignCustomer: 'Gewählten Kunden zuweisen',
  refreshPrices: 'Preise aktualisieren',
  copyBillingToShipping: 'Rechnungsadresse als Lieferadresse setzen',
  alsoAddEdiItemToPortfolio: 'Zum Portfolio hinzufügen',
  alsoShowEdiItemInPortfolio: 'Im Portfolio anzeigen',
  registerEdiReseller: 'Registrierung abschließen',
  create_excel: 'Excel erzeugen',
  create_pdf: 'PDF erzeugen',
  massCreateDeviceVariants: 'Mehrere erstellen',
  assignProductAttributeValues: 'Attribute zuweisen',
  updateCNetMetaData: 'Meta-Daten von CNet auslesen',
  addToOffer: 'Zum Angebot hinzufügen',
  assign: 'Zuweisen',
  unassign: 'Zuweisung entfernen',
  edit: 'Bearbeiten',
  editDone: 'Fertig',
  next: 'Weiter',
  finish: 'Fertigstellen',
  alsoUpdateResellableProduct: 'CNet Daten aktualisieren',
  createNewCasProject: 'Neue Projektpreise anlegen',
  addPrice: 'Preis hinzufügen',
}
