import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import currencyText from 'Lib/currencyText'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useTranslate } from 'react-admin'
import { useField, useFormState } from 'react-final-form'

const useStyles = makeStyles(theme => ({
  textField: {
    width: '256px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const NetPriceCalculator = ({ resource }) => {
  const t = useTranslate()
  const classes = useStyles()
  const {
    values: { price, margin },
  } = useFormState()

  const { input } = useField('netPrice')

  useEffect(() => {
    input.onChange(price * ((100 + (margin || 0)) / 100))
  }, [price, margin])

  return (
    <TextField
      label={t(`resources.${resource}.fields.netPrice`)}
      value={currencyText(input.value || 0)}
      disabled
      className={classes.textField}
      variant="filled"
    />
  )
}

NetPriceCalculator.propTypes = {
  resource: PropTypes.string,
}

NetPriceCalculator.defaultProps = {
  resource: null,
}

export default NetPriceCalculator
