import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-admin'
import { Field, useForm } from 'react-final-form'

import TestCredentialsAction from 'Components/Common/Actions/TestCredentialsAction'
import { required } from 'Components/Common/Orders/AttachmentInput'
import TranslatedField from '../TranslatedField'
import renderTextField from './renderComponents/renderTextField'

const TestButton = ({ index }) => {
  const { credentials } = useForm().getState().values
  return <TestCredentialsAction credentials={credentials[index]} />
}

TestButton.propTypes = {
  index: PropTypes.number.isRequired,
}

const CredentialsInput = ({ type, record, prod }) => {
  const index = record.credentials.findIndex(
    c => c.type === type && c.prod === prod,
  )
  const t = useTranslate()
  const messageKey = prod ? 'api' : 'testapi'

  if (index < 0) {
    return null
  }

  return (
    <div>
      <TranslatedField string={`services.${type}.${messageKey}`} />
      <div>
        <Field
          name={`credentials.${index}.username`}
          label={t('resources.resellers.fields.credentials.username')}
          component={renderTextField}
          validate={required}
        />
      </div>
      <div>
        <Field
          name={`credentials.${index}.password`}
          label={t('resources.resellers.fields.credentials.password')}
          type="password"
          component={renderTextField}
          validate={required}
        />
      </div>
      <TestButton index={index} />
    </div>
  )
}

CredentialsInput.propTypes = {
  type: PropTypes.string.isRequired,
  prod: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
}

CredentialsInput.defaultProps = {
  prod: false,
}

export default CredentialsInput
