export default {
  name: 'Bestellpositionen',
  fields: {
    position: 'Pos',
    workplaceUserDevice: 'Arbeitsplatzgerät',
    accessories: 'Zubehör',
    monthlyRate: 'Rate',
    actions: 'Aktionen',
    term: 'Laufzeit',
    quantity: 'Menge',
    total: 'Gesamtrate',
    transmitted: 'Übermittelt',
  },
  values: {
    months: '%{months} Monate',
  },
}
