import { LinearProgress, Paper } from '@material-ui/core'
import { Input } from 'Components/Common/InputFields/AutoSuggests/Lib/renderFunctions'
import SelectList from 'Components/Common/InputFields/AutoSuggests/Lib/SelectList'
import Downshift from 'downshift'
import PropTypes from 'prop-types'
import React from 'react'
import { BooleanInput } from 'react-admin'
import { useField } from 'react-final-form'
import FetchData from './FetchData'
import getSuggestions from './getSuggestions'

const itemToString = item => item?.label

function stateReducer(state, changes) {
  // this prevents the searchvalue to dissappear when itscope is toggled
  switch (changes.type) {
    case Downshift.stateChangeTypes.mouseUp:
    case Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem:
      return {
        ...changes,
        isOpen: state.isOpen,
        inputValue: state.inputValue,
      }
    default:
      return changes
  }
}

const ProductAutoSuggestField = ({
  label,
  onChange,
  withITScope,
  autoFocus,
  selectedItem,
}) => {
  const {
    input: { value: includeItScope },
  } = useField('includeItScope', {
    initialValue: false,
  })

  return (
    <Downshift
      onChange={onChange}
      itemToString={itemToString}
      selectedItem={selectedItem}
      stateReducer={stateReducer}
    >
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        isOpen,
        inputValue,
        selectedItem,
        highlightedIndex,
      }) => (
        <div>
          {withITScope && (
            <BooleanInput label="mit ITScope" source="includeItScope" />
          )}
          <Input
            label={label}
            fullWidth
            InputProps={getInputProps()}
            autoFocus={autoFocus}
          />

          <Paper
            style={{
              display: isOpen ? 'block' : 'none',
              visibility: isOpen ? 'visible' : 'hidden',
            }}
            square
            {...getMenuProps()}
          >
            {isOpen ? (
              <FetchData
                includeItScope={includeItScope}
                searchValue={inputValue}
              >
                {({ productVariants, itScopeData, loading }) => {
                  const suggestions = getSuggestions({
                    productVariants,
                    itScopeData,
                    inputValue,
                  })

                  const rows = []
                  suggestions.forEach(group => {
                    rows.push({ label: group.title })
                    group.suggestions.forEach(suggestion => {
                      rows.push(suggestion)
                    })
                  })

                  return (
                    <>
                      {loading && (
                        <LinearProgress
                          style={{ width: '100%' }}
                          mode="indeterminate"
                        />
                      )}
                      <SelectList
                        rows={rows}
                        getItemProps={getItemProps}
                        highlightedIndex={highlightedIndex}
                        selectedItem={selectedItem}
                        itemToString={itemToString}
                      />
                    </>
                  )
                }}
              </FetchData>
            ) : null}
          </Paper>
        </div>
      )}
    </Downshift>
  )
}

ProductAutoSuggestField.propTypes = {
  source: PropTypes.string,
  onItemSelect: PropTypes.func,
  withITScope: PropTypes.bool,
}

ProductAutoSuggestField.defaultProps = {
  source: null,
  onItemSelect: () => {},
  withITScope: false,
}

export default ProductAutoSuggestField
