import SuppressEmailsButton from 'Components/Common/ActionButtons/SuppressEmailsButton'
import TranslatedTitle from 'Components/Common/TranslatedTitle'
import { useOrder } from 'OrderContext'
import React from 'react'
import { SimpleShowLayout } from 'react-admin'
import SendAutopilotEmailButton from '../ActionButtons/SendAutopilotEmailButton'

const OrderExtra = () => {
  const { resource, basePath, record } = useOrder()

  return record ? (
    <SimpleShowLayout resource={resource} basePath={basePath} record={record}>
      <TranslatedTitle translateKey="resources.orders.tabs.extra" />
      <SuppressEmailsButton data={record} />
      <SendAutopilotEmailButton data={record} />
    </SimpleShowLayout>
  ) : null
}

export default OrderExtra
