import React, { useEffect, useMemo } from 'react'
import { useField, useForm } from 'react-final-form'
import jsonata from 'jsonata'
import DiscountTable from 'Components/Aluxo/ActionDialogs/CustomerCreateCasProjectDialog/DiscountTable'

export const showPercentage = value => `${value.toFixed(2)} %`

const CasCustomerDiscountProducts = () => {
  const {
    input: { value: discounts },
  } = useField('activeProject.discounts')

  const initialValue = useMemo(() => {
    if (!discounts) {
      return []
    }
    const expression = jsonata(
      // eslint-disable-next-line no-template-curly-in-string
      '${sku: $merge([$.{discountType: relativeDiscount, "productName": productName, "sku": sku}])}',
    )
    const jsonataDiscounts = expression.evaluate(Object.values(discounts)) // Sometimes it's array, sometimes it's object

    return Object.values(jsonataDiscounts)
  }, [discounts])

  const form = useForm()

  useEffect(() => {
    form.change('activeProject.lines', initialValue)
  }, [form.change, initialValue])

  return (
    <>
      <DiscountTable name="activeProject.lines" />
    </>
  )
}

CasCustomerDiscountProducts.propTypes = {}

export default CasCustomerDiscountProducts
