import { GetApp } from '@material-ui/icons'
import mime from 'mime-types'
import PropTypes from 'prop-types'
import b64toBlob from 'b64-to-blob'
import React from 'react'
import { useMutation, useNotify } from 'react-admin'
import Button from 'Components/Common/Buttons/Button'

const DownloadAttachmentAction = ({ id, name }) => {
  const showNotification = useNotify()

  const [mutate, { loading }] = useMutation(
    {
      type: 'downloadAttachment',
      resource: 'attachments',
      payload: { id },
    },
    {
      onSuccess: async response => {
        const contentType = response.headers['content-type']
        const resText = response.data
        const blob = b64toBlob(resText, contentType)
        const blobUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        document.body.appendChild(a)
        const extension = mime.extension(contentType)
        a.style = 'display: none'
        a.href = blobUrl
        a.download = `${name}.${extension}`
        a.click()
        URL.revokeObjectURL(blobUrl)
      },
      onFailure: error => {
        showNotification(`Error: ${error}`, 'warning') // todo errormessage
      },
    },
  )

  const handleClick = () => {
    mutate()
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button color="primary" onClick={handleClick} loading={loading}>
        <GetApp />
      </Button>
    </div>
  )
}

DownloadAttachmentAction.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default DownloadAttachmentAction
