export default {
  labels: {
    chargeType: 'Berechnungsart',
    dependentOn: 'Abhängig von',
    price: 'Preis',
    prices: 'Preise',
  },
  chargeTypes: {
    none: 'keine',
    fixed: 'Einmalig',
    monthly: 'Monatlich',
    totalDuration: 'Gesamte Laufzeit',
  },
  dependentOn: {
    none: 'keine',
    fixed: 'Gesamte Bestellung',
    workplaces: 'Arbeitsplatz',
  },
  actions: {
    save: 'Speichern',
  },
}
