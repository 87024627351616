import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import injectTapEventPlugin from 'react-tap-event-plugin'
import App from './App'
import './index.css'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://1dcdb37b34d545b38702435c440f9b02@sentry.io/1387968',
  })
}

try {
  injectTapEventPlugin()
} catch (e) {
  // do nothing
}

ReactDOM.render(<App />, document.getElementById('root'))
