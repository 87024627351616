import OrderActionTransitions from 'Components/Common/Actions/Order/OrderActionTransitions'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components/macro'
import DuplicateAsOfferButton from 'Components/Aluxo/ActionButtons/DuplicateAsOfferButton'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: -0.5em 0;
  & > * {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0.5em 0;
  }
`

const ProcessOfferActions = ({ orderType, manualTransitions, permitted }) => (
  <Container>
    <DuplicateAsOfferButton />
    <OrderActionTransitions
      orderType={orderType}
      manualTransitions={manualTransitions}
      permitted={permitted}
    />
  </Container>
)

ProcessOfferActions.propTypes = {
  orderType: PropTypes.string.isRequired,
  manualTransitions: PropTypes.array.isRequired,
  permitted: PropTypes.bool.isRequired,
}

export default ProcessOfferActions
