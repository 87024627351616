import { createWorksheet } from 'Components/Also/Offers/AlsoOfferCreate'
import { useOrder } from 'OrderContext'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import XLSX from 'xlsx'
import Button from 'Components/Common/Buttons/Button'
import b64toBlob from 'b64-to-blob'
import { useApiClient } from 'Rest/fetchHydra'

const CreateDocumentButton = ({ disabled, type }) => {
  const { values } = useFormState()
  const translate = useTranslate()
  const { record } = useOrder()
  const label = `actions.create_${type}`
  const apiClient = useApiClient()

  const createExcel = () => {
    const wsName = 'Angebot'
    const wb = XLSX.utils.book_new()
    const ws = createWorksheet(values)

    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws, wsName)

    XLSX.writeFile(wb, 'Angebot.xlsx')
    // save(sanitizeEmptyValues(initialValues, values), false)
  }

  const createPdf = async () => {
    const response = await apiClient({
      endpoint: '/also_offers/create_document',
      method: 'POST',
      body: { offer: record['@id'] },
    })

    const contentType = response.headers['content-type']
    const disposition = response.headers['content-disposition']
    const filename = disposition.match(
      /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/,
    )
    const resText = response.data
    const blob = b64toBlob(resText, contentType)
    const blobUrl = URL.createObjectURL(blob)
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = blobUrl
    // eslint-disable-next-line prefer-destructuring
    a.download = filename[1]
    a.click()
    URL.revokeObjectURL(blobUrl)
  }

  const handleClick = () => {
    if (type === 'excel') {
      createExcel()
    }
    createPdf()
  }

  return (
    <Button
      color="primary"
      variant="contained"
      size="medium"
      onClick={handleClick}
      disabled={disabled}
      label={translate(label)}
    />
  )
}

export default CreateDocumentButton
