import { Card, CardActions, CardContent, Grid } from '@material-ui/core'
import { useOrderEditStyles } from 'Components/Common/Orders/OrderEditGridFormView'
import TranslatedTitle from 'Components/Common/TranslatedTitle'
import { useOrder } from 'OrderContext'
import PropTypes from 'prop-types'
import React from 'react'
import { CardContentInner, SaveButton, Toolbar, useLoading } from 'react-admin'
import AlsoOfferLineActions from 'Components/Also/Offers/OfferLines/AlsoOfferLineActions'
import AlsoOfferLines from 'Components/Also/Offers/OfferLines/AlsoOfferLines'
import { useForm } from 'react-final-form'
import Button from 'Components/Common/Buttons/Button'
import CreateDocumentButton from 'Components/Also/Offers/CreateDocumentButton'
import EditableAddress from 'Components/Also/Offers/EditableAddress'
import EditableAdditionalData from 'Components/Also/Offers/EditableAdditionalData'
import EditableGeneralData from 'Components/Also/Offers/EditableGeneralData'

const OfferEditToolbar = ({ record, permitted, isCreateView, ...props }) => {
  const loading = useLoading()

  const disabled = loading

  return (
    <Toolbar {...props}>
      <SaveButton disabled={disabled} />
      {!isCreateView && (
        <CreateDocumentButton disabled={disabled} type="excel" />
      )}
      {!isCreateView && <CreateDocumentButton disabled={disabled} type="pdf" />}
    </Toolbar>
  )
}

export const initialValues = {
  '@context': '/api/contexts/also_offer',
  '@id': '/api/also_offers/9f4e09c1-6742-414b-8a25-4a7d1fecb8fb',
  '@type': 'also_offer',
  id: '9f4e09c1-6742-414b-8a25-4a7d1fecb8fb',
  offerNumber: 1000,
  period: 12,
  offerLines: [
    {
      '@id': '/api/also_offer_lines/2888dcd9-014d-4983-867b-16b6e9b6b889',
      '@type': 'also_offer_line',
      id: '2888dcd9-014d-4983-867b-16b6e9b6b889',
      offer: '/api/also_offers/9f4e09c1-6742-414b-8a25-4a7d1fecb8fb',
      quantity: 1,
      resellableProduct:
        '/api/also_resellable_products/27e823e0-3ad7-450a-be1c-d1e252dd4969',
      name:
        'LENOVO ThinkPad T590 i7-8565U 39,6cm 15,6Zoll HDR UHD 16GB 512GB M.2 SSD W10P64 4G LTE IntelUHD 620 FPR Cam Topseller',
      manufacturer: 'LENOVO',
      sku: '20N40057GE',
      ean: '0194552006327',
      price: 1558.81,
      margin: 10,
      position: 0,
      originId: '/api/also_offer_lines/2888dcd9-014d-4983-867b-16b6e9b6b889',
    },
    {
      '@id': '/api/also_offer_lines/1e27292f-fc70-431a-b134-3e3570bf1ffb',
      '@type': 'also_offer_line',
      id: '1e27292f-fc70-431a-b134-3e3570bf1ffb',
      offer: '/api/also_offers/9f4e09c1-6742-414b-8a25-4a7d1fecb8fb',
      quantity: 1,
      resellableProduct:
        '/api/also_resellable_products/13b746e2-753e-4964-a2aa-e4f39c7cbb4d',
      name: 'LENOVO ThinkPlus ePac 3YR Onsite Next Business Day',
      manufacturer: 'LENOVO',
      sku: '5WS0A23681',
      ean: '',
      price: 94.49,
      margin: 10,
      position: 1,
      originId: '/api/also_offer_lines/1e27292f-fc70-431a-b134-3e3570bf1ffb',
      parentPosition: 0,
    },
    {
      '@id': '/api/also_offer_lines/1e27292f-fc70-431a-b134-3e3570bf1ffb',
      '@type': 'also_offer_line',
      id: '1e27292f-fc70-431a-b134-3e3570bf1ffb',
      offer: '/api/also_offers/9f4e09c1-6742-414b-8a25-4a7d1fecb8fb',
      quantity: 1,
      resellableProduct:
        '/api/also_resellable_products/13b746e2-753e-4964-a2aa-e4f39c7cbb4d',
      name: 'LENOVO ThinkPlus ePac 3YR Onsite Next Business Day',
      manufacturer: 'LENOVO',
      sku: '5WS0A23681',
      ean: '',
      price: 94.49,
      margin: 10,
      position: 2,
      originId: '/api/also_offer_lines/1e27292f-fc70-431a-b134-3e3570bf1ffb',
      parentPosition: 0,
    },
    {
      '@id': '/api/also_offer_lines/8d650b1b-e7ff-486c-b955-a2b992c39b59',
      '@type': 'also_offer_line',
      id: '8d650b1b-e7ff-486c-b955-a2b992c39b59',
      offer: '/api/also_offers/9f4e09c1-6742-414b-8a25-4a7d1fecb8fb',
      quantity: 1,
      resellableProduct:
        '/api/also_resellable_products/268510ff-761b-4ab1-b52b-175a58413d51',
      name:
        'LENOVO ThinkPad T15 G1 i7-10510U 39,6cm 15,6Zoll FHD 16GB 1TB M.2 SSD W10P64 NVIDIA GeForce MX330 FPR Cam 4G LTE Topseller',
      manufacturer: 'LENOVO',
      sku: '20S6003PGE',
      ean: '0195042596144',
      price: 1608.61,
      margin: 10,
      position: 3,
      originId: '/api/also_offer_lines/8d650b1b-e7ff-486c-b955-a2b992c39b59',
    },
    {
      '@id': '/api/also_offer_lines/b8f4fad5-ea84-483a-a6e7-713625e50a79',
      '@type': 'also_offer_line',
      id: 'b8f4fad5-ea84-483a-a6e7-713625e50a79',
      offer: '/api/also_offers/9f4e09c1-6742-414b-8a25-4a7d1fecb8fb',
      quantity: 1,
      resellableProduct:
        '/api/also_resellable_products/0caf11fe-0a4d-411e-b8ca-7a8af8cca793',
      name:
        'HP Chromebook x360 14 G1 Intel Pentium 4415U 35,56cm 14Zoll FHD 8GB 32GB/eMMC WLAN BT Chrome64 1J. Gar. (DE)',
      manufacturer: 'HP INC.',
      sku: '6BP69EA#ABD',
      ean: '0193808252839',
      price: 526,
      margin: 10,
      position: 4,
      originId: '/api/also_offer_lines/b8f4fad5-ea84-483a-a6e7-713625e50a79',
    },
    {
      '@id': '/api/also_offer_lines/1e27292f-fc70-431a-b134-3e3570bf1ffb',
      '@type': 'also_offer_line',
      id: '1e27292f-fc70-431a-b134-3e3570bf1ffb',
      offer: '/api/also_offers/9f4e09c1-6742-414b-8a25-4a7d1fecb8fb',
      quantity: 1,
      resellableProduct:
        '/api/also_resellable_products/13b746e2-753e-4964-a2aa-e4f39c7cbb4d',
      name: 'LENOVO ThinkPlus ePac 3YR Onsite Next Business Day',
      manufacturer: 'LENOVO',
      sku: '5WS0A23681',
      ean: '',
      price: 94.49,
      margin: 10,
      position: 5,
      originId: '/api/also_offer_lines/1e27292f-fc70-431a-b134-3e3570bf1ffb',
      parentPosition: 4,
    },
    {
      '@id': '/api/also_offer_lines/203138cf-3596-4d2a-b224-26aa47e071bc',
      '@type': 'also_offer_line',
      id: '203138cf-3596-4d2a-b224-26aa47e071bc',
      offer: '/api/also_offers/9f4e09c1-6742-414b-8a25-4a7d1fecb8fb',
      quantity: 1,
      resellableProduct:
        '/api/also_resellable_products/3e66f725-da3d-4632-978c-efa0635ebf4c',
      name:
        'HP Chromebook x360 14 G1 Intel i3-8130U 35,56cm 14Zoll FHD 8GB 64GB/eMMC WLAN BT Chrome64 1J. Gar. (DE)',
      manufacturer: 'HP INC.',
      sku: '6BP66EA#ABD',
      ean: '0193808252525',
      price: 604.61,
      margin: 10,
      position: 6,
      originId: '/api/also_offer_lines/203138cf-3596-4d2a-b224-26aa47e071bc',
    },
  ],
  offerAddress: {
    '@id': '/api/addresses/a91cf56f-b104-4391-ac84-5e4900b0c4f2',
    '@type': 'Address',
    id: 'a91cf56f-b104-4391-ac84-5e4900b0c4f2',
    company: 'Eine Firma',
    companyShort: 'Eine Firma',
    legalForm: 'GmbH',
    salutation: 'Herr',
    firstName: 'Test',
    lastName: 'Kunde',
    street: 'Teststraße',
    postcode: '69115',
    city: 'Heidelberg',
    email: 'test@example.com',
    phone: '+4915156343532',
    type: 'default',
    originId: '/api/addresses/a91cf56f-b104-4391-ac84-5e4900b0c4f2',
    '@id_reference_id': 'a91cf56f-b104-4391-ac84-5e4900b0c4f2',
    originId_reference_id: 'a91cf56f-b104-4391-ac84-5e4900b0c4f2',
  },
  bank: 'Test bank',
  IBAN: 'DE85 9999 9999 9999 9999 99',
  BIC: 'BYLADEM1001',
  vatId: 'qwe',
  domain: 'example.com',
  validUntil: null,
  createdAt: '2020-09-09T16:23:49+02:00',
  updatedAt: '2020-09-09T16:23:49+02:00',
  '@context_reference_id': 'also_offer',
  '@id_reference_id': '9f4e09c1-6742-414b-8a25-4a7d1fecb8fb',
  offerLines_reference_ids: [
    '1e27292f-fc70-431a-b134-3e3570bf1ffb',
    '203138cf-3596-4d2a-b224-26aa47e071bc',
    '2888dcd9-014d-4983-867b-16b6e9b6b889',
    '8d650b1b-e7ff-486c-b955-a2b992c39b59',
    'b8f4fad5-ea84-483a-a6e7-713625e50a79',
  ],
  offerAddress_reference_id: 'a91cf56f-b104-4391-ac84-5e4900b0c4f2',
  originId: '/api/also_offers/9f4e09c1-6742-414b-8a25-4a7d1fecb8fb',
}

const LoadInitialValuesButton = () => {
  const form = useForm()

  const handleClick = () => {
    form.initialize(initialValues)
  }

  return (
    <Button
      type="button"
      color="primary"
      variant="contained"
      size="medium"
      onClick={handleClick}
      label="Beispieldaten laden"
    />
  )
}

const GridFormView = ({
  invalid,
  pristine,
  handleSubmit,
  submitting,
  isCreateView,
  // eslint-disable-next-line no-unused-vars
  values,
}) => {
  const classes = useOrderEditStyles()

  const { resource, basePath, record } = useOrder()

  const Toolbar = React.cloneElement(<OfferEditToolbar />, {
    basePath,
    handleSubmitWithRedirect: handleSubmit,
    handleSubmit,
    invalid,
    pristine,
    record,
    redirect: false,
    resource,
    saving: submitting,
    submitOnEnter: false,
    undoable: true,
    permitted: true,
    isCreateView,
  })

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        {!record.id && (
          <Grid item xs="12">
            <LoadInitialValuesButton />
          </Grid>
        )}

        <Grid item xs={12}>
          <Card>
            <CardContent className={classes.cardContent}>
              <TranslatedTitle translateKey="resources.also_offers.tabs.general" />
              <EditableGeneralData />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <TranslatedTitle translateKey="resources.also_offers.fields.offerAddress.name" />
              <EditableAddress source="offerAddress" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <TranslatedTitle translateKey="resources.also_orders.tabs.additionalInfo" />
              <EditableAdditionalData />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent className={classes.cardContent}>
              <TranslatedTitle translateKey="resources.also_offers.tabs.lines" />
              <CardContentInner>
                <TranslatedTitle translateKey="" />
                <AlsoOfferLineActions data={record} />
              </CardContentInner>
              <AlsoOfferLines />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardActions disableSpacing className={classes.cardActions}>
              {Toolbar}
            </CardActions>
          </Card>
        </Grid>

        {/* <OrderLinesEdit />
        <OrderServicesEdit />
        <Card >
          <CardContent className={classes.cardContent}>
            <OrderAdditionalInfo />
          </CardContent>
        </Card> */}
      </Grid>
    </form>
  )
}

GridFormView.propTypes = {
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  isCreateView: PropTypes.bool,
}

GridFormView.defaultProps = {
  isCreateView: false,
}

export default GridFormView
