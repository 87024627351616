import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import moment from 'moment'

/**
 * Display a date value as a locale string.
 *
 * Uses Intl.DateTimeFormat() if available, passing the locales and options props as arguments.
 * If Intl is not available, it outputs date as is (and ignores the locales and options props).
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
 * @example
 * <DateField source="published_at" />
 * // renders the record { id: 1234, published_at: new Date('2012-11-07') } as
 * <span>07/11/2012</span>
 *
 * <DateField source="published_at" elStyle={{ color: 'red' }} />
 * // renders the record { id: 1234, new Date('2012-11-07') } as
 * <span style="color:red;">07/11/2012</span>
 *
 * <DateField source="share" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
 * // renders the record { id: 1234, new Date('2012-11-07') } as
 * <span>Wednesday, November 7, 2012</span>
 *
 * <DateField source="price" locales="fr-FR" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
 * // renders the record { id: 1234, new Date('2012-11-07') } as
 * <span>mercredi 7 novembre 2012</span>
 */

const DateField = ({ elStyle, record, source, format, keepLocalTime }) => {
  if (!record) return null
  const value = get(record, source)
  if (value == null) return null
  const date = value instanceof Date ? value : new Date(value)
  const dateString = moment(date)
    .utc(keepLocalTime)
    .format(format)

  return <span style={elStyle}>{dateString}</span>
}

DateField.propTypes = {
  addLabel: PropTypes.bool, // eslint-disable-line
  elStyle: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  format: PropTypes.string,
  keepLocalTime: PropTypes.bool,
}

DateField.defaultProps = {
  addLabel: true,
  format: 'DD.MM.YYYY HH:mm',
  record: {},
  elStyle: {},
  keepLocalTime: true,
}

export default DateField
