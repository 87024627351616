import React from 'react'
import PropTypes from 'prop-types'
import MuiSelect from '@material-ui/core/Select'
import MuiMenuItem from '@material-ui/core/MenuItem'
import MuiFormControl from '@material-ui/core/FormControl'
import MuiInputLabel from '@material-ui/core/InputLabel'
import MuiFormHelperText from '@material-ui/core/FormHelperText'

const renderSelectInput = ({
  input,
  label,
  meta: { touched, error },
  choices,
  showHelperText,
  fullWidth,
  ...custom
}) => (
  <MuiFormControl
    variant="filled"
    style={{ width: fullWidth ? '100%' : 256, margin: '16px 0 8px 0' }}
    error={Boolean(touched && error)}
  >
    <MuiInputLabel>{label}</MuiInputLabel>
    <MuiSelect
      margin="normal"
      variant="filled"
      fullWidth={fullWidth}
      {...input}
      {...custom}
    >
      {choices.map(choice => (
        <MuiMenuItem key={choice} value={choice}>
          {choice}
        </MuiMenuItem>
      ))}
    </MuiSelect>
    {showHelperText && touched && error && (
      <MuiFormHelperText>{error}</MuiFormHelperText>
    )}
  </MuiFormControl>
)

renderSelectInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({ touched: PropTypes.bool, error: PropTypes.object })
    .isRequired,
  choices: PropTypes.arrayOf(PropTypes.string).isRequired,
  showHelperText: PropTypes.bool,
  fullWidth: PropTypes.bool,
}

renderSelectInput.defaultProps = {
  label: '',
  showHelperText: false,
  fullWidth: false,
}

export default renderSelectInput
