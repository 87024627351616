import BackToDeviceButton from 'Components/Aluxo/ActionButtons/BackToDeviceButton'
import UpdateITScopeMetadataButton from 'Components/Aluxo/ActionButtons/UpdateITScopeMetadataButton'
import UpdateITScopeSinglePriceButton from 'Components/Aluxo/ActionButtons/UpdateITScopeSinglePriceButton'
import UpdateCNetMetaDataButton from 'Components/Aluxo/ActionButtons/UpdateCNetMetaDataButton'
import EditToolbar from 'Components/Common/EditToolbar'
import NumberWithUnitInput from 'Components/Common/InputFields/NumberWithUnitInput'
import LanguageSwitcherAside from 'Components/Common/Layout/LanguageSwitcherAside'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import { getLinkFromIri } from 'Lib/helper'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React, { useContext } from 'react'
import {
  ArrayInput,
  BooleanInput,
  Create,
  Edit,
  FormTab,
  maxLength,
  SimpleForm,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin'
import CNetMetaDataList from 'Components/Common/ProductAttributes/CNetMetaDataList'
import ProductAttributeList from 'Components/Common/ProductAttributes/ProductAttributeList'
import DisplaySpecsField from 'Components/Common/ViewFields/DisplaySpecsField'
import CNetImagesView from 'Components/Common/ViewFields/CNetImagesView'
import { ResellerContext } from 'ResellerContext'
import { validateRequired } from './devices'

const EditActions = ({ data, resource }) => (
  <RightAlignedCardActions>
    <BackToDeviceButton data={data} />
    <UpdateITScopeMetadataButton data={data} resource={resource} />
    <UpdateITScopeSinglePriceButton data={data} resource={resource} />
    <UpdateCNetMetaDataButton data={data} resource={resource} />
  </RightAlignedCardActions>
)

const editDefaultValues = {
  price: 0,
  margin: 0,
  netPrice: 0,
}

const DeviceVariantTitle = ({ record }) => get(record, 'name')

const validateDescription = [maxLength(255)]

export const DeviceVariantEdit = props => {
  const { flags } = useContext(ResellerContext)
  return (
    <Edit
      title={<DeviceVariantTitle />}
      actions={<EditActions />}
      aside={<LanguageSwitcherAside />}
      {...props}
    >
      <TabbedForm
        defaultValue={editDefaultValues}
        redirect={false}
        toolbar={<EditToolbar hasDelete />}
      >
        <FormTab label="resources.device_variants.tabs.general">
          <TextInput source="name" validate={validateRequired} />
          <BooleanInput
            options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
            source="active"
            validate={validateRequired}
          />

          <BooleanInput
            options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
            source="autoDisabled"
            validate={validateRequired}
          />

          <TextInput source="sku" />
          <TextInput source="ean" validate={validateRequired} />
          {flags.cnetSpecs && <TextInput source="alsoProductId" />}
          <TextField source="puid" />
          <NumberWithUnitInput source="price" />
          <NumberWithUnitInput source="margin" unit="%" />
          <CurrencyField source="netPrice" />
          <TextInput
            options={{ fullWidth: true }}
            source="description"
            validate={validateDescription}
          />
          <BooleanInput
            options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
            source="featured"
          />
          <TextInput
            multiline
            source="accessorySKUs"
            format={arr => arr.join('\n')}
            parse={value => value.split(/[\n,;]+/)}
          />
          {flags.cnetSpecs && (
            <CNetImagesView
              filterName="variant"
              imageResource="aluxo_device_variant_cnet_images"
            />
          )}
        </FormTab>
        <FormTab label="resources.device_variants.tabs.attributes">
          {!flags.cnetSpecs && (
            <ArrayInput source="specs">
              <SimpleFormIterator>
                <TextInput
                  source="title"
                  label="resources.device_variants.fields.specs.title"
                  style={{ display: 'inline-block' }}
                  elStyle={{ width: '50%' }}
                />
                <TextInput
                  source="spec"
                  label="resources.device_variants.fields.specs.spec"
                  style={{ display: 'inline-block' }}
                  elStyle={{ width: '50%' }}
                />
              </SimpleFormIterator>
            </ArrayInput>
          )}

          <DisplaySpecsField source="displaySpecs" />
          <ProductAttributeList assignable />
          <CNetMetaDataList />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

const createDefaultValues = device => ({
  device: { '@id': device },
  ...editDefaultValues,
})

const CreateActions = ({ device }) => (
  <RightAlignedCardActions>
    <BackToDeviceButton device={device} />
  </RightAlignedCardActions>
)

export const DeviceVariantCreate = props => {
  const { flags } = useContext(ResellerContext)

  const {
    location: { search },
  } = props
  const { device } = JSON.parse(queryString.parse(search).source)
  return (
    <Create actions={<CreateActions device={device} />} {...props}>
      <SimpleForm
        defaultValue={createDefaultValues(device)}
        redirect={getLinkFromIri(device)}
      >
        <TextInput source="name" validate={validateRequired} />

        <TextInput source="sku" />
        <TextInput source="ean" validate={validateRequired} />

        <NumberWithUnitInput source="price" />
        <NumberWithUnitInput source="margin" unit="%" />
        <TextInput
          options={{ fullWidth: true }}
          source="description"
          validate={validateDescription}
        />
        {!flags.cnetSpecs && (
          <ArrayInput source="specs">
            <SimpleFormIterator>
              <TextInput
                source="title"
                label="resources.device_variants.fields.specs.title"
                style={{ display: 'inline-block' }}
                elStyle={{ width: '50%' }}
              />
              <TextInput
                source="spec"
                label="resources.device_variants.fields.specs.spec"
                style={{ display: 'inline-block' }}
                elStyle={{ width: '50%' }}
              />
            </SimpleFormIterator>
          </ArrayInput>
        )}
        <BooleanInput
          options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
          source="featured"
        />
      </SimpleForm>
    </Create>
  )
}

DeviceVariantCreate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
}
