import { Box, CircularProgress } from '@material-ui/core'
import GridFormView from 'Components/Common/Orders/OrderEditGridFormView'
import OrderTitle from 'Components/Common/Orders/OrderTitle'
import useStockRefresh from 'Hooks/useStockRefresh'
import moment from 'moment'
import OrderProvider from 'OrderContext'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import {
  EditContextProvider,
  FormInput,
  TitleForRecord,
  useEditController,
} from 'react-admin'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'

moment.locale('de')

export const Inputs = ({ children, basePath, record, resource }) =>
  React.Children.map(children, input => (
    <FormInput
      basePath={basePath}
      input={input}
      record={record}
      resource={resource}
    />
  ))

const OrderEdit = props => {
  const controllerProps = useEditController(props)
  const { resource, record, save, basePath, version } = controllerProps
  const history = useHistory()

  const handleSubmit = values => {
    save(values, false)
  }

  useEffect(() => {
    if (record && record.createdOrder_reference_id) {
      history.push(`/orders/${record.createdOrder_reference_id}`)
    }
  }, [record])

  // useStockRefresh(record)

  const [mode, setMode] = React.useState('general')

  if (!record) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <CircularProgress />
      </Box>
    )
  }

  /**
   * Nasty hack, because ceo input fields fail, when initialValues.fields is an empty array
   * This happens only when fields is an empty array and record is populated when mounting this
   * So only when navigating into this order from e.g. order list
   */

  const initialValues = record
    ? {
        ...record,
        fields:
          Array.isArray(record.fields) && record.fields.length === 0
            ? {}
            : record.fields,
      }
    : {}

  return (
    <EditContextProvider value={controllerProps}>
      <OrderProvider record={record} basePath={basePath} resource={resource}>
        <TitleForRecord record={record} title={<OrderTitle />} />
        <Form
          key={version}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          // keepDirtyOnReinitialize
        >
          {formProps => (
            <GridFormView
              resource={resource}
              basePath={basePath}
              record={record}
              {...formProps}
              mode={mode}
              setMode={setMode}
            />
          )}
        </Form>
      </OrderProvider>
    </EditContextProvider>
  )
}

OrderEdit.propTypes = {
  id: PropTypes.string.isRequired,
}

export default OrderEdit
