import {
  AddToPortfolioButton,
  BulkAddToPortfolioButton,
} from 'Components/Also/EDI/AddToPortfolioButtons'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import PropTypes from 'prop-types'
import React from 'react'
import {
  BooleanInput,
  Datagrid,
  Filter,
  List,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin'
import { useApiClient } from 'Rest/fetchHydra'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

const EdiItemsFilter = props => {
  const [filterData, setFilterData] = React.useState({
    categoryText1: [],
    categoryText2: [],
    categoryText3: [],
  })
  const apiClient = useApiClient()

  React.useEffect(() => {
    const loadFilterData = async () => {
      const res = await apiClient({
        endpoint: '/also_edi_items/filters',
        method: 'POST',
        body: {},
      })

      setFilterData(data => ({
        ...data,
        categoryText1: res.data.allCategoryText1.filter(v => v),
        categoryText2: res.data.allCategoryText2.filter(v => v),
        categoryText3: res.data.allCategoryText3.filter(v => v),
      }))
    }

    loadFilterData()
  }, [apiClient])

  return (
    <Filter {...props}>
      <TextInput label="Search" source="description" alwaysOn />
      <TextInput
        label="manufacturerPartNumber"
        source="manufacturerPartNumber"
      />
      <TextInput label="manufacturer" source="manufacturerName" />
      {Object.entries(filterData).map(([name, values]) => (
        <SelectInput
          label={name}
          source={name}
          choices={values.map(v => ({
            id: v,
            name: v,
          }))}
          alwaysOn
        />
      ))}
      <BooleanInput label="inPortfolio" source="inPortfolio" />
    </Filter>
  )
}

const EdiItemBulkActionButtons = props => (
  <React.Fragment>
    <BulkAddToPortfolioButton {...props} />
  </React.Fragment>
)

// eslint-disable-next-line import/prefer-default-export
export const EdiItemList = props => (
  <List
    {...props}
    filters={<EdiItemsFilter />}
    bulkActionButtons={<EdiItemBulkActionButtons />}
    pagination={<CustomPagination />}
  >
    <Datagrid>
      <TextField source="manufacturerPartNumber" />
      <TextField source="manufacturerName" />
      <TextField source="description" />
      <TextField source="categoryText1" />
      <TextField source="categoryText2" />
      <TextField source="categoryText3" />
      <CurrencyField source="netPrice" />
      <AddToPortfolioButton />
    </Datagrid>
  </List>
)

EdiItemList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}
