import { useMutation } from 'react-admin'

const useMutationPromise = (
  options = { onSuccess: () => {}, onFailure: () => {} },
) => {
  const [mutate, ...rest] = useMutation()

  return [
    data =>
      new Promise((resolve, reject) => {
        mutate(data, {
          onSuccess: (...success) => {
            options.onSuccess()
            resolve(...success)
          },
          onFailure: error => {
            options.onFailure()
            reject(error)
          },
        })
      }),
    ...rest,
  ]
}

export default useMutationPromise
