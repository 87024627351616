import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { useTranslate } from 'react-admin'
import renderTextField from 'Components/Common/InputFields/renderComponents/renderTextField'

const getPath = (source, path, type) => {
  if (path) {
    return `${path}.${type}`
  }
  return source ? `${source}.metaData.${type}` : `metaData.${type}`
}

const MetaInput = ({ source, path }) => {
  const t = useTranslate()
  return (
    <div>
      <div>
        <Field
          name={getPath(source, path, 'title')}
          label={t('generalFields.metaData.metaTitle')}
          component={renderTextField}
        />
      </div>
      <div>
        <Field
          name={getPath(source, path, 'description')}
          label={t('generalFields.metaData.metaDescription')}
          component={renderTextField}
        />
      </div>
      <div>
        <Field
          name={getPath(source, path, 'keywords')}
          label={t('generalFields.metaData.metaKeywords')}
          component={renderTextField}
        />
      </div>
    </div>
  )
}

MetaInput.propTypes = {
  source: PropTypes.string,
  path: PropTypes.string,
}

MetaInput.defaultProps = {
  source: undefined,
  path: undefined,
}
export default MetaInput
