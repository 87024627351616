import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { useTranslate, useRefresh } from 'react-admin'

const DiscardChangesButton = ({ label, disabled }) => {
  const refreshView = useRefresh()
  const translate = useTranslate()

  const handleClick = event => {
    event.preventDefault()
    refreshView()
  }
  return (
    <Button color="primary" onClick={handleClick} disabled={disabled}>
      {label && translate(label)}
    </Button>
  )
}

DiscardChangesButton.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
}

DiscardChangesButton.defaultProps = {
  label: 'actions.discardChanges',
  disabled: false,
}

export default DiscardChangesButton
