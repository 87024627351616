import { Card, CardContent } from '@material-ui/core'
import TranslatedTitle from 'Components/Common/TranslatedTitle'
import { useOrder } from 'OrderContext'
import PropTypes from 'prop-types'
import React from 'react'
import {
  CardContentInner,
  Datagrid,
  ReferenceManyField,
  SimpleShowLayout,
  TextField,
} from 'react-admin'
import BooleanField from 'Components/Common/ViewFields/BooleanField'
import ChipArrayField from 'Components/Common/ViewFields/ChipArrayField'
import DateField from 'Components/Common/ViewFields/DateField'
import AttachmentActions from './AttachmentActions'
import AttachmentInput from './AttachmentInput'
import OrderAttachmentsActions from './Attachments/OrderAttachmentsActions'
import { useOrderEditStyles } from './OrderEditGridFormView'

const OrderAttachments = ({ withInput }) => {
  const classes = useOrderEditStyles()

  const { record, basePath, resource } = useOrder()
  return (
    <Card className={classes.fullWidthCard}>
      <CardContent className={classes.cardContent}>
        <CardContentInner>
          <OrderAttachmentsActions />
        </CardContentInner>
        {record && (
          <SimpleShowLayout
            resource={resource}
            basePath={basePath}
            record={record}
          >
            <TranslatedTitle translateKey="resources.orders.tabs.attachments" />
            <ReferenceManyField
              sort={{ field: 'created_at', order: 'DESC' }}
              label="resources.attachments.all"
              reference="attachments"
              target="order"
              css="margin-bottom: 2rem"
            >
              <Datagrid>
                <DateField source="createdAt" />
                <TextField source="type" />
                <ChipArrayField source="groups" />
                <TextField source="comment" />
                <TextField source="user.email" />
                <BooleanField hideFalseValues source="current" />
                <AttachmentActions />
              </Datagrid>
            </ReferenceManyField>

            {withInput && (
              <>
                <TranslatedTitle translateKey="resources.attachments.new" />
                <AttachmentInput />
              </>
            )}
          </SimpleShowLayout>
        )}
      </CardContent>
    </Card>
  )
}

OrderAttachments.propTypes = {
  withInput: PropTypes.bool,
}

OrderAttachments.defaultProps = {
  withInput: false,
}

export default OrderAttachments
