import React from 'react'
import get from 'lodash/get'
import moment from 'moment'
import { useTranslate } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import { showPercentage } from 'Components/Aluxo/Discounts/CasCustomerDiscountProducts'
import currencyText from 'Lib/currencyText'

const OrderDiscountList = ({ record }) => {
  const t = useTranslate()
  const deviceLines = get(record, 'deviceLines', [])
  return deviceLines
    .filter(deviceLine => deviceLine.deviceOrderLineDiscount)
    .map(deviceLine => (
      <>
        <Typography
          variant="h2"
          style={{ fontSize: '1.2rem', marginTop: '30px' }}
        >
          {deviceLine.quantity}x {get(deviceLine, 'device.name', '-')}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('resources.discounts.common.type')}</TableCell>
              <TableCell>{t('resources.discounts.common.expiresAt')}</TableCell>
              <TableCell>{t('resources.discounts.name')}</TableCell>
              <TableCell>
                {t('resources.discounts.common.discountPerDevice')}
              </TableCell>
              <TableCell>
                {t('resources.discounts.common.discountSum')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deviceLine.deviceOrderLineDiscount.suitablePartialDiscounts.map(
              partialDiscount => {
                const expiresAt = get(partialDiscount, 'expiresAt')
                return (
                  <TableRow>
                    <TableCell>
                      {get(partialDiscount, 'description', '')}
                    </TableCell>
                    <TableCell>
                      {expiresAt ? moment(expiresAt).format('DD.MM.YYYY') : ''}
                    </TableCell>
                    <TableCell>
                      {showPercentage(partialDiscount.relativeDiscount)}
                    </TableCell>
                    <TableCell>
                      {currencyText(partialDiscount.absoluteDiscount)}
                    </TableCell>
                    <TableCell>
                      {currencyText(
                        partialDiscount.absoluteDiscount * deviceLine.quantity,
                      )}
                    </TableCell>
                  </TableRow>
                )
              },
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell>
                {currencyText(
                  get(
                    deviceLine,
                    'deviceOrderLineDiscount.absoluteDiscount',
                    0,
                  ),
                )}
              </TableCell>
              <TableCell>
                {currencyText(
                  get(
                    deviceLine,
                    'deviceOrderLineDiscount.absoluteDiscount',
                    0,
                  ) * deviceLine.quantity,
                )}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </>
    ))
}

export default OrderDiscountList
