import PropTypes from 'prop-types'
import { TableCell } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  bundled: {
    color: theme.palette.grey[500],
    fontSize: 14,
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
}))

const StyledCell = ({ children, bundled, nowrap, ...rest }) => {
  const classes = useStyles()
  return (
    <TableCell
      className={classnames({
        [classes.bundled]: bundled,
        [classes.nowrap]: nowrap,
      })}
      {...rest}
    >
      {children}
    </TableCell>
  )
}

StyledCell.propTypes = {
  children: PropTypes.node.isRequired,
  bundled: PropTypes.bool,
  nowrap: PropTypes.bool,
}

StyledCell.defaultProps = {
  bundled: false,
  nowrap: false,
}

export default StyledCell
