import React, { useMemo } from 'react'

import {
  Box,
  DialogContent,
  Step,
  StepButton,
  Stepper,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from 'Components/Common/Buttons/Button'
import { useLoading } from 'react-admin'
import { useToggle } from 'react-use'
import ConfirmationDialog from 'Components/Aluxo/ActionDialogs/BulkCreateDeviceVariantsDialog/ConfirmationDialog'
import { useProcessContext } from 'Components/Aluxo/ActionDialogs/BulkCreateDeviceVariantsDialog/ProcessContext'
import useStepper from 'Components/Aluxo/ActionDialogs/BulkCreateDeviceVariantsDialog/BeforeCreationWizard/useStepper'
import DeviceStepContent from './DeviceStepContent'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
}))

function HorizontalStepper(props) {
  const classes = useStyles()

  const stepperDetails = useStepper(props.steps)

  const {
    currentStep,
    activeStep,
    handleNext,
    handleBack,
    isLastStep,
    needsConfirmation,
    cancelConfirmation,
    handleConfirmation,
    setStep,
  } = stepperDetails

  const steps = props.steps.map(step => step.label)
  const loading = useLoading()

  const handleComplete = () => {
    props.closeDialog()
  }

  return (
    <div className={classes.root}>
      {currentStep.content}
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {}
          const buttonProps = {}
          return (
            <Step key={label} {...stepProps}>
              <StepButton
                disabled={loading}
                onClick={() => setStep(index)}
                {...buttonProps}
              >
                {label}
              </StepButton>
            </Step>
          )
        })}
      </Stepper>
      <div>
        <ConfirmationDialog
          open={needsConfirmation}
          cancel={cancelConfirmation}
          confirm={handleConfirmation}
        />
        <Box display="flex" justifyContent="flex-end">
          <div>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.button}
              label="actions.back"
            />
            {!isLastStep() ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
                label="actions.next"
              />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleComplete}
                label="actions.finish"
              />
            )}
          </div>
        </Box>
      </div>
    </div>
  )
}

const AfterCreationWizard = ({ closeDialog }) => {
  const [dirty, setDirty] = useToggle(false)
  const { creationResult } = useProcessContext()

  const steps = useMemo(() => {
    const steps = creationResult.map(deviceDetails => ({
      label: deviceDetails.name,
      content: (
        <DeviceStepContent deviceDetails={deviceDetails} setDirty={setDirty} />
      ),
      type: 'deviceDetails',
    }))

    return steps
  }, [creationResult])

  return (
    <DialogContent>
      <HorizontalStepper
        steps={steps}
        dirty={dirty}
        closeDialog={closeDialog}
      />
    </DialogContent>
  )
}

export default AfterCreationWizard
