import React from 'react'
import {
  CreateButton,
  Datagrid,
  RefreshButton,
  TextField,
  List,
  BooleanField,
  SimpleForm,
  FormDataConsumer,
  Edit,
  AutocompleteInput,
  TextInput,
  SelectInput,
  BooleanInput,
  NumberInput,
  Filter,
  useTranslate,
} from 'react-admin'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import ConditionalActionButton from 'Components/Common/Buttons/ConditionalActionButton'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import StatusLabel from 'Components/Common/StatusLabel'
import { isAdmin, isReseller } from 'Lib/helper'
import { RightAlignedCardActions } from 'Components/Common/RightAlignedCardActions'
import DownloadAttachmentField from 'Components/Common/DownloadAttachmentField'
import { validateRequired } from './devices'
import ReferenceInput from 'Components/Common/ReferenceInput'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'

const DeviceFilter = props => (
  <Filter {...props}>
    <TextInput
      source="externalProtectionPlan.customer"
      label="resources.external_protection_plans.fields.customer"
    />
    <TextInput
      source="serial"
      label="resources.external_protection_plans.fields.devices.serialNumber"
    />
    <BooleanInput
      options={{ labelStyle: { color: 'rgba(0, 0, 0, 0.87)' } }}
      source="theft"
      label="resources.external_protection_plans.fields.devices.theftProtection"
    />
  </Filter>
)

const ExternalProtectionPlanActions = ({
  resource,
  filters,
  displayedFilters,
  filterValues,
  basePath,
  showFilter,
}) => (
  <RightAlignedCardActions>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </RightAlignedCardActions>
)

ExternalProtectionPlanActions.propTypes = {
  resource: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  displayedFilters: PropTypes.object.isRequired,
  filterValues: PropTypes.object.isRequired,
  basePath: PropTypes.string.isRequired,
  showFilter: PropTypes.func.isRequired,
}

const RentOrBuyField = ({ record }) => {
  const t = useTranslate()
  return (
    <span>
      {t(
        `resources.external_protection_plans.fields.${
          get(record, 'externalProtectionPlan.rented') ? 'rented' : 'purchased'
        }`,
      )}
    </span>
  )
}

RentOrBuyField.propTypes = {
  record: PropTypes.object.isRequired,
}

const InsuranceField = ({ source, record }) => {
  const t = useTranslate()
  const insuranceDate = get(record, source)
  const insuranceDuration = get(record, 'duration', null)
  const formattedDate = insuranceDate
    ? new Date(insuranceDate).toLocaleDateString()
    : null
  return (
    <span>
      {formattedDate}
      <br /> ({insuranceDuration}{' '}
      {t('resources.external_protection_plans.fields.months')})
    </span>
  )
}

InsuranceField.propTypes = {
  source: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
}

export const ExternalProtectionPlanDevicesList = ({
  permissions,
  ...props
}) => (
  <List
    filters={<DeviceFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    pagination={<CustomPagination />}
    actions={<ExternalProtectionPlanActions />}
    {...props}
  >
    <Datagrid>
      {isAdmin(permissions) && (
        <TextField
          source="externalProtectionPlan.reseller.name"
          label="resources.external_protection_plans.fields.reseller"
        />
      )}
      {isAdmin(permissions) && (
        <TextField
          source="orderNumber"
          label="resources.external_protection_plans.fields.orderNumber"
        />
      )}
      <TextField
        source="externalProtectionPlan.order.orderNumber"
        label="resources.external_protection_plans.fields.externalProtectionPlan.order.orderNumber"
      />
      <TextField
        source="externalProtectionPlan.customer"
        label="resources.external_protection_plans.fields.customer"
      />

      <TextField
        source="deviceName"
        label="resources.external_protection_plans.fields.devices.device"
      />
      <TextField
        source="serial"
        label="resources.external_protection_plans.fields.devices.serialNumber"
      />
      <RentOrBuyField label="resources.external_protection_plans.fields.type" />
      <InsuranceField
        source="externalProtectionPlan.insuranceDate"
        label="resources.external_protection_plans.fields.insurance"
      />
      <BooleanField
        headerStyle={{ textAlign: 'center' }}
        sortable={false}
        source="theft"
        label="resources.external_protection_plans.fields.devices.theftProtection"
      />
      <CurrencyField
        source="price"
        label="resources.external_protection_plans.fields.devices.price.list"
      />

      <DownloadAttachmentField source="attachment" />

      {isReseller(permissions) && <StatusLabel />}
      {isReseller(permissions) && (
        <ConditionalActionButton label="Aktionen" action="edit" />
      )}
    </Datagrid>
  </List>
)

ExternalProtectionPlanDevicesList.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export const ExternalProtectionPlanDeviceEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput
        options={{ fullWidth: true }}
        label="resources.external_protection_plans.fields.devices.device"
        source="deviceName"
        disabled
      />

      <FormDataConsumer>
        {({ formData, ...rest }) =>
          !formData.externalProtectionPlan.uploaded && (
            <>
              <ReferenceInput
                label="resources.external_protection_plans.fields.devices.device_edit"
                reference="device_variants"
                source="device"
                key="device_id"
                filterToQuery={searchText => ({ name: searchText })}
                {...rest}
              >
                <AutocompleteInput
                  options={{ fullWidth: true }}
                  source="device"
                  optionText="name"
                  {...rest}
                />
              </ReferenceInput>

              <TextInput
                validate={validateRequired}
                source="serial"
                label="resources.external_protection_plans.fields.devices.serialNumber"
                {...rest}
              />

              <SelectInput
                source="duration"
                validate={validateRequired}
                label="resources.external_protection_plans.fields.devices.duration"
                choices={[
                  { id: 24, name: '24 Monate' },
                  { id: 36, name: '36 Monate' },
                ]}
                {...rest}
              />
              <BooleanInput
                source="theft"
                label="resources.external_protection_plans.fields.devices.theftProtection"
                {...rest}
              />
            </>
          )
        }
      </FormDataConsumer>

      <NumberInput
        validate={validateRequired}
        source="price"
        label="resources.external_protection_plans.fields.devices.price.create"
      />
    </SimpleForm>
  </Edit>
)
