import React, { useMemo } from 'react'
import { useOrder } from 'OrderContext'
import { toPath } from 'lodash'

const checkPath = (path, sourcePath, index = 0) => {
  if (path[index] === '*') {
    return true
  }
  if (path[index] === sourcePath[index]) {
    return checkPath(path, sourcePath, index + 1)
  }
  return false
}

export const isAllowed = (source, allowedFields) => {
  const sourcePath = toPath(source)
  const allowedPaths = allowedFields.map(e => toPath(e))

  const isAllowed = allowedPaths.reduce(
    (acc, allowedPath) => acc || checkPath(allowedPath, sourcePath),
    false,
  )

  return isAllowed
}

const OrderInputField = ({ component, source, ...props }) => {
  const Component = component

  const { editableFields } = useOrder()

  const isDisabled = useMemo(() => !isAllowed(source, editableFields), [
    source,
    editableFields,
  ])

  return <Component {...props} source={source} disabled={isDisabled} />
}

export default OrderInputField
