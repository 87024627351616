import PropTypes from 'prop-types'
import React from 'react'
import { useTranslate } from 'react-admin'

const WorkflowField = ({ source, record }) => {
  const t = useTranslate()
  const workflowState = record[source]

  return (
    <span>
      {workflowState
        ? t(`orders.workflows.${record[source]}`)
        : t(`orders.workflows.loading`)}
    </span>
  )
}

WorkflowField.propTypes = {
  record: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
}

export default WorkflowField
