import { CardActions } from '@material-ui/core'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import {
  BooleanInput,
  CreateButton,
  Datagrid,
  Edit,
  EditButton,
  List,
  NumberField,
  RefreshButton,
  SimpleForm,
  TextField,
  WithPermissions,
} from 'react-admin'
import EditActions from 'Components/Common/EditActions'
import EditToolbar from 'Components/Common/EditToolbar'
import CurrencyField from 'Components/Common/ViewFields/CurrencyField'
import BooleanField from 'Components/Common/ViewFields/BooleanField'
import PercentageField from 'Components/Common/ViewFields/PercentageField'
import NumberWithUnitInput from 'Components/Common/InputFields/NumberWithUnitInput'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'
import { DownloadActionsControl } from './resellable_protection_plans'

const ProtectionPlanActions = ({ basePath }) => (
  <CardActions>
    <WithPermissions>
      {({ permissions }) =>
        permissions === 'ROLE_ADMIN' ? (
          <CreateButton basePath={basePath} />
        ) : null
      }
    </WithPermissions>
    <RefreshButton />
  </CardActions>
)

ProtectionPlanActions.propTypes = {
  basePath: PropTypes.string.isRequired,
}

export const ProtectionPlanList = props => (
  <List
    {...props}
    pagination={<CustomPagination />}
    actions={<ProtectionPlanActions />}
  >
    <Datagrid>
      <TextField source="protectionPlan.sku" />
      <NumberField source="protectionPlan.duration" />
      <BooleanField source="protectionPlan.theftProtection" />
      <CurrencyField source="protectionPlan.amount" />
      <CurrencyField source="price" />
      <PercentageField source="margin" />
      <EditButton />
    </Datagrid>
  </List>
)

const Title = ({ record }) => get(record, 'protectionPlan.sku')

export const ProtectionPlanEdit = props => (
  <Edit title={<Title />} actions={<EditActions />} {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextField source="protectionPlan.sku" />
      <TextField source="protectionPlan.duration" />
      <BooleanInput
        source="protectionPlan.theftProtection"
        disabled
        options={{ labelStyle: { color: '#000000' } }}
      />
      <CurrencyField source="protectionPlan.amount" />
      <CurrencyField source="protectionPlan.netPrice" />
      <NumberWithUnitInput source="margin" unit="%" />
      <CurrencyField source="netPrice" />
      <DownloadActionsControl style={{ marginTop: '20px' }} />
    </SimpleForm>
  </Edit>
)
