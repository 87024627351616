import Fuse from 'fuse.js'

export default function getSuggestions({ promoBundles, inputValue }) {
  const searchValue = (inputValue || ''.trim()).toLowerCase()

  const fuse = new Fuse(promoBundles, {
    keys: ['skus', 'name'],
  })

  const results = fuse.search(searchValue)

  const suggestions = results.map(({ item }) => {
    const suggestion = {
      ...item,
      value: item.id,
      label: `${item.skus.join(', ')} - ${item.name}`,
    }
    return suggestion
  })

  return suggestions
}
