import DateFnsUtils from '@date-io/date-fns'
import { format } from 'date-fns'
import deLocale from 'date-fns/locale/de'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'
import PropTypes from 'prop-types'
import React from 'react'
import { composeValidators, useTranslate } from 'react-admin'
import { useField } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Box } from '@material-ui/core'

const useStyles = makeStyles({
  input: {
    padding: '23px 12px 6px 12px',
  },
  inputLabel: {
    transform: 'translate(12px, 7px) scale(0.75)',
    padding: 0,
  },
})

const DateInput = ({
  source,
  options,
  label,
  resource,
  variant,
  validate,
  helperText,
}) => {
  const sanitizedValidate = Array.isArray(validate)
    ? composeValidators(validate)
    : validate

  const {
    input,
    meta: { error },
  } = useField(source, {
    validate: sanitizedValidate,
  })

  const handleChange = value => {
    input.onChange(value ? format(value, 'yyyy-MM-dd') : null)
  }
  const translate = useTranslate()

  const translatedLabel = translate(
    label || `resources.${resource}.fields.${source}`,
  )

  const classes = useStyles({ variant })

  return (
    <Box mb={4}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
        <DatePicker
          value={input.value || null}
          onChange={handleChange}
          format="dd.MM.yyyy"
          clearable
          error={error}
          helperText={translate(error || helperText)}
          label={translatedLabel}
          variant={variant}
          InputProps={{
            classes: { input: clsx({ [classes.input]: variant === 'filled' }) },
          }}
          InputLabelProps={{
            classes: {
              shrink: clsx({ [classes.inputLabel]: variant === 'filled' }),
            },
          }}
          {...options}
        />
      </MuiPickersUtilsProvider>
    </Box>
  )
}

DateInput.propTypes = {
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['standard', 'filled']),
  validate: PropTypes.func,
}

DateInput.defaultProps = {
  label: null,
  variant: 'standard',
  validate: () => {},
}

export default DateInput // decorate with react-final-form's <Field>
