import axios from 'axios'
import { HttpError, useUpdateLoading } from 'react-admin'
import React from 'react'
import { url as baseUrl } from 'httpClient'

/**
 * @param {object} options
 * @return {object}
 */
const apiClient = async (options = {}) => {
  const token = localStorage.getItem('token')

  const contentLanguage = localStorage.getItem('contentLanguage') || 'de'

  const { endpoint, headers, method, body, ...restOptions } = options

  const url = options.url || `${baseUrl}${endpoint}`

  const requestHeaders = {
    'Content-Type': 'application/ld+json',
    Accept: 'application/ld+json',
    Authorization: `Bearer ${token}`,
    'X-Admin': true,
    'X-Locale': contentLanguage,
    ...headers,
  }

  const requestConfig = {
    method,
    url,
    data: body,
    headers: requestHeaders,
    ...restOptions,
  }

  if (options.type === 'blob') {
    requestConfig.responseType = 'blob'
  }

  try {
    if (options.type === 'blob' || options.type === 'binary') {
      return axios.request(requestConfig)
    }

    const res = await axios.request(requestConfig)

    if (res.status < 200 || res.status >= 300) {
      return Promise.reject(new HttpError(res.statusText, res.status))
    }

    return res
  } catch (e) {
    if (Object.prototype.hasOwnProperty.call(e, 'body')) {
      return Promise.reject(e)
    }

    throw e
  }
}

export default apiClient

export const useApiClient = () => {
  const { startLoading, stopLoading } = useUpdateLoading()

  const callApiClient = React.useCallback(
    async (...args) => {
      try {
        startLoading()
        const res = await apiClient(...args)
        return res
      } finally {
        stopLoading()
      }
    },
    [startLoading, stopLoading, apiClient],
  )

  return callApiClient
}
