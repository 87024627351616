import { makeStyles } from '@material-ui/core/styles'
import { Input } from '@material-ui/core'
import numbro from 'numbro'
import de from 'numbro/languages/de-DE'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useField } from 'react-final-form'

const useStyles = makeStyles({
  root: {
    fontSize: 'inherit',
  },
})

numbro.registerLanguage(de)
numbro.setLanguage('de-DE')

const transformValue = value =>
  numbro(value).format({
    thousandSeparated: true,
    mantissa: 2,
  })

const InlineNumberWithUnitInput = ({ source, unit, inputClasses, onBlur }) => {
  const { input } = useField(source)

  const [value, setValue] = useState(0)
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    const inputValue = input.value || 0
    setValue(transformValue(inputValue))
  }, [input.value])

  const handleChange = ({ target: { value } }) => {
    setValue(value)
  }

  const handleBlur = () => {
    const unformattedValue = numbro(value).value() || 0
    setValue(transformValue(unformattedValue))
    setEditing(false)
    input.onChange(unformattedValue)
    onBlur()
  }

  const handleFocus = () => {
    setEditing(true)
    setValue(transformValue(value))
  }

  const handleKeyPress = e => {
    if (/,/.test(e.key) && /,/.test(value)) {
      e.preventDefault()
    }
    if (!/[\d,]/.test(e.key)) {
      e.preventDefault()
    }
  }

  const classes = useStyles()
  const displayValue = editing ? value : `${value} ${unit}`

  return (
    <Input
      type="tel"
      value={displayValue}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      classes={inputClasses || classes}
      variant="filled"
    />
  )
}

InlineNumberWithUnitInput.propTypes = {
  unit: PropTypes.string,
  source: PropTypes.string.isRequired,
  inputClasses: PropTypes.object,
  onBlur: PropTypes.func,
}

InlineNumberWithUnitInput.defaultProps = {
  unit: '€',
  inputClasses: null,
  onBlur: () => {},
}

export default InlineNumberWithUnitInput
