import GenericRestClientButton from 'Components/Common/ActionButtons/GenericRestClientButton'
import PropTypes from 'prop-types'
import React from 'react'

const CopyAccessorySKUButton = ({ record }) => (
  <GenericRestClientButton
    type="copyAccessoryToVariants"
    action="copyAccessoryToVariants"
    resource="devices"
    disabled={!record}
    payload={{ id: record?.id }}
  />
)

CopyAccessorySKUButton.propTypes = {
  record: PropTypes.object.isRequired,
}

export default CopyAccessorySKUButton
