import isPlainObject from 'lodash.isplainobject'
import { DELETE, GET_LIST, GET_MANY_REFERENCE } from 'react-admin'
import {
  ASSIGN_CUSTOMER_TO_ORDER,
  DOWNLOAD_ALL_ATTACHMENTS,
  DOWNLOAD_ATTACHMENT,
  PROCESS_ORDER,
  SAVE_TRANSLATIONS,
} from 'Rest/actions'
import { iriRegex } from 'Rest/convertReactAdminRequestToHydraRequest'

export const reactAdminDocumentsCache = new Map()

export class ReactAdminDocument {
  constructor(obj) {
    Object.assign(this, obj, {
      originId: obj['@id'],
    })

    reactAdminDocumentsCache[this.id] = this.originId
  }

  /**
   * @return {string}
   */
  toString() {
    return `[object ${this.originId}]`
  }
}

export const replaceGraphQLReferences = document =>
  Object.entries(document).forEach(([key, value]) => {
    // if the value is a plain object and has an id
    if (isPlainObject(value) && value.id) {
      document[key] = new ReactAdminDocument(value)
      document[`${key}_reference_id`] = document[key].id

      replaceGraphQLReferences(document[key]) // THIS IS IMPORTANT, IF WE USE VALUES IT BREAKS EVENTUALLY. FML

      return
    }

    if (typeof value === 'string') {
      // if it's an IRI, add to cache and add _reference_id field for references
      const matches = iriRegex.exec(value)
      if (matches) {
        const [iri, id] = matches
        document[`${key}_reference_id`] = id
        document.id = id
        reactAdminDocumentsCache[id] = iri
        return
      }
    }

    // to-many
    if (
      Array.isArray(value) &&
      value.length &&
      isPlainObject(value[0]) &&
      value[0].id
    ) {
      document[`${key}_reference_ids`] = value.map(obj => obj.id)
      document[key] = value.map(obj => replaceGraphQLReferences(obj))
    }
  })

export const replaceReferencesInGraphQLResponse = data => {
  Object.entries(data).forEach(([key, value]) => {
    if (data[key].edges) {
      data[key].edges.forEach(({ node }) => replaceGraphQLReferences(node))
      // replaceReferences()
    } else {
      replaceGraphQLReferences(data[key])
    }
  })
}

export const replaceReferences = document =>
  Object.entries(document).forEach(([key, value]) => {
    // to-one
    if (isPlainObject(value) && value['@id']) {
      document[key] = new ReactAdminDocument(value)
      document[`${key}_reference_id`] = document[key].id

      replaceReferences(document[key]) // THIS IS IMPORTANT, IF WE USE VALUES IT BREAKS EVENTUALLY. FML

      return
    }

    if (typeof value === 'string') {
      // if it's an IRI, add to cache and add _reference_id field for references
      const matches = iriRegex.exec(value)
      if (matches) {
        const [iri, id] = matches
        document[`${key}_reference_id`] = id
        reactAdminDocumentsCache[id] = iri
        return
      }
    }

    // empty array -- we can't know what it is, so lets' create a reference_id array just in case
    if (Array.isArray(value) && value.length === 0) {
      document[`${key}_reference_ids`] = []
    }

    // to-many
    if (
      Array.isArray(value) &&
      value.length &&
      isPlainObject(value[0]) &&
      value[0]['@id']
    ) {
      document[`${key}_reference_ids`] = value.map(obj => obj.id)
      document[key] = value.map(obj => new ReactAdminDocument(obj))
    }

    // to many iris
    if (
      Array.isArray(value) &&
      value.length &&
      typeof value[0] === 'string' &&
      iriRegex.test(value[0])
    ) {
      document[`${key}_reference_ids`] = []
      value.forEach(iri => {
        const [_, uuid] = iriRegex.exec(iri)
        reactAdminDocumentsCache[uuid] = iri
        document[`${key}_reference_ids`].push(uuid)
      })
    }
  })

/**
 * Transforms a JSON-LD document to a react-admin compatible document.
 *
 * @param {Object} document
 * @param {bool} clone
 *
 * @return {ReactAdminDocument}
 */
export const transformJsonLdDocumentToReactAdminDocument = (
  document,
  clone = true,
) => {
  if (clone) {
    // deep clone documents
    document = JSON.parse(JSON.stringify(document))
  }

  replaceReferences(document)

  // The main document is a JSON-LD document, convert it // and store it in the cache
  if (document['@id']) {
    document = new ReactAdminDocument(document)
  }

  // We might replace embedded objects by their IRIs, and store the object itself in the cache to reuse without issuing new HTTP requests.
  // Check here: https://github.com/api-platform/admin

  return document
}

/**
 * @param {Object} response
 * @param {string} resource
 * @param {string} type
 *
 * @returns {Promise}
 */
const convertHydraResponseToReactAdminResponse = (type, resource, response) => {
  // 204 ==> No Content
  if (response.status === 204) {
    return Promise.resolve({ data: { id: null } })
  }

  switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE:
      // TODO: support other prefixes than "hydra:"
      return Promise.resolve(
        response.data['hydra:member'].map(
          transformJsonLdDocumentToReactAdminDocument,
        ),
      ).then(data => ({
        data,
        total: response.data['hydra:totalItems'],
        context: response.data['@context'],
      }))

    case DELETE:
      return Promise.resolve({ data: { id: null } })
    case SAVE_TRANSLATIONS:
    case PROCESS_ORDER:
    case ASSIGN_CUSTOMER_TO_ORDER:
      return Promise.resolve({ data: { id: null } })
    case DOWNLOAD_ATTACHMENT:
    case DOWNLOAD_ALL_ATTACHMENTS:
      return response
    default:
      return Promise.resolve(
        transformJsonLdDocumentToReactAdminDocument(response.data),
      ).then(data => ({ data, context: response.data['@context'] }))
  }
}

export default convertHydraResponseToReactAdminResponse
