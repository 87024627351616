import {
  AutocompleteInput,
  Datagrid,
  EditButton,
  FormTab,
  ReferenceManyField,
  SaveButton,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  required,
  useEditController,
  useLoading,
  useMutation,
  useNotify,
  usePermissions,
  useTranslate,
} from 'react-admin'
import { validateIban, validateRequiredZipCode } from './CustomerCreate'

import Button from '@material-ui/core/Button'
import ContextEdit from 'Components/Common/ContextEdit'
import { NullTitle } from 'Components/Common/TranslatedTitle'
import NullToolbar from 'Components/Common/NullToolbar'
import PropTypes from 'prop-types'
import PureBooleanField from 'Components/Common/ViewFields/BooleanField'
import PureCurrencyField from 'Components/Common/ViewFields/CurrencyField'
import PureDateField from 'Components/Common/ViewFields/DateField'
import React from 'react'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import StatusField from 'Components/Common/Orders/Fields/StatusField'
import { get } from 'lodash'
import legalForms from 'Lib/legalForms'
import styled from 'styled-components/macro'
import { validateRequired } from 'Resources/Aluxo/devices'

const CustomerTitle = ({ record }) => {
  const translate = useTranslate()
  return `${translate('resources.customers.name', { smart_count: 1 })} - ${get(
    record,
    'email',
    '',
  )}`
}

const TranslatedText = ({ text }) => {
  const translate = useTranslate()
  return translate(text, { smart_count: 2 })
}

const FormHeading = ({ text }) => (
  <span
    style={{ fontSize: '1.5rem', margin: '15px 0 5px 0', display: 'block' }}
  >
    <TranslatedText text={text} />
  </span>
)

FormHeading.propTypes = {
  text: PropTypes.string.isRequired,
}

const AddressTab = ({ ...props }) => {
  if (!get(props, 'record.addresses')) {
    return null
  }
  const idxBilling = props.record.addresses.findIndex(a => a.type === 'billing')
  const idxShipping = props.record.addresses.findIndex(
    a => a.type === 'shipping',
  )
  return (
    <FormTab label="Addressen" {...props}>
      <FormHeading text="resources.customers.fields.billingAddress.name" />
      <SelectInput
        source={`addresses.${idxBilling}.salutation`}
        choices={[
          { id: 'Frau', name: 'Frau' },
          { id: 'Herr', name: 'Herr' },
        ]}
        validate={validateRequired}
        label="resources.customers.fields.addresses.salutation"
      />
      <TextInput
        source={`addresses.${idxBilling}.firstName`}
        validate={validateRequired}
        label="resources.customers.fields.addresses.firstName"
      />
      <TextInput
        source={`addresses.${idxBilling}.lastName`}
        validate={validateRequired}
        label="resources.customers.fields.addresses.lastName"
      />
      <AutocompleteInput
        source={`addresses.${idxBilling}.legalForm`}
        choices={legalForms.map(f => ({ id: f, name: f }))}
        validate={validateRequired}
        label="resources.customers.fields.addresses.legalForm"
      />
      <TextInput
        source={`addresses.${idxBilling}.company`}
        validate={validateRequired}
        label="resources.customers.fields.addresses.company"
      />
      <TextInput
        source={`addresses.${idxBilling}.street`}
        validate={validateRequired}
        label="resources.customers.fields.addresses.street"
      />
      <TextInput
        source={`addresses.${idxBilling}.postcode`}
        validate={validateRequiredZipCode}
        label="resources.customers.fields.addresses.postcode"
      />
      <TextInput
        source={`addresses.${idxBilling}.city`}
        validate={validateRequired}
        label="resources.customers.fields.addresses.city"
      />
      <TextInput
        source={`addresses.${idxBilling}.phone`}
        validate={validateRequired}
        label="resources.customers.fields.addresses.phone"
      />
      <TextInput
        source={`addresses.${idxBilling}.email`}
        label="resources.customers.fields.addresses.email"
      />
      <FormHeading text="resources.customers.fields.shippingAddress.name" />
      <TextInput
        source={`addresses.${idxShipping}.firstName`}
        validate={validateRequired}
        label="resources.customers.fields.addresses.firstName"
      />
      <TextInput
        source={`addresses.${idxShipping}.lastName`}
        validate={validateRequired}
        label="resources.customers.fields.addresses.lastName"
      />
      <TextInput
        source={`addresses.${idxShipping}.company`}
        validate={validateRequired}
        label="resources.customers.fields.addresses.company"
      />
      <TextInput
        source={`addresses.${idxShipping}.street`}
        validate={validateRequired}
        label="resources.customers.fields.addresses.street"
      />
      <TextInput
        source={`addresses.${idxShipping}.postcode`}
        validate={validateRequiredZipCode}
        label="resources.customers.fields.addresses.postcode"
      />
      <TextInput
        source={`addresses.${idxShipping}.city`}
        validate={validateRequired}
        label="resources.customers.fields.addresses.city"
      />
    </FormTab>
  )
}

AddressTab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
}

const CustomerEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

const TabbedEdit = ({ ...props }) => (
  <TabbedForm {...props} toolbar={<CustomerEditToolbar />}>
    <AddressTab {...props} />
    <FormTab label="resources.customers.fields.bankDetails.name">
      <TextInput
        source="bankDetails.bank"
        label="resources.customers.fields.bankDetails.bank"
      />
      <TextInput
        source="bankDetails.IBAN"
        label="resources.customers.fields.bankDetails.iban"
        validate={validateIban}
      />
      <TextInput
        source="bankDetails.BIC"
        label="resources.customers.fields.bankDetails.bic"
      />
    </FormTab>
  </TabbedForm>
)

const CenteredDiv = styled.div`
  display: flex;
  min-height: 80px;
  align-items: center;
  justify-content: center;
`

const CustomDatagrid = props => {
  const translate = useTranslate()

  if (props.data && Object.keys(props.data).length > 0) {
    return <Datagrid {...props} />
  }

  return (
    <CenteredDiv>
      {translate(
        props.loaded
          ? 'resources.customers.noOrders'
          : 'resources.customers.loadOrders',
      )}
    </CenteredDiv>
  )
}

CustomDatagrid.propTypes = {
  data: PropTypes.object.isRequired,
  loaded: PropTypes.bool.isRequired,
}

const Orders = props => {
  const { permissions = [] } = usePermissions()

  return (
    <ReferenceManyField
      addLabel={false}
      reference="also_orders"
      target="customer"
      {...props}
    >
      <CustomDatagrid style={{ width: '100%' }}>
        <TextField source="orderNumber" />
        <PureBooleanField source="priority" hideFalseValues />
        <StatusField source="marking" sortable={false} />
        <PureDateField source="created" format="DD.MM.YYYY" />
        <TextField source="billingAddress.company" />
        {permissions && permissions.includes('ROLE_STOREVIEW') && (
          <TextField source="storeView.name" />
        )}
        <PureCurrencyField
          label="resources.orders.fields.total"
          source="totalVolume"
        />
        <EditButton />
      </CustomDatagrid>
    </ReferenceManyField>
  )
}

const CustomerActions = ({ data }) => {
  const translate = useTranslate()
  const showNotification = useNotify()
  const loading = useLoading()
  const [resetPassword, { loading: mutationLoading }] = useMutation(
    {
      type: 'resetCustomerPassword',
      resource: 'customers',
      payload: { id: data.id },
    },
    {
      undoable: true,
      onSuccess: () => {
        showNotification(
          'notifications.resetCustomerPassword.success',
          'info',
          {},
          true,
        )
      },
      onFailure: () => {
        showNotification('notifications.resetCustomerPassword.fail')
      },
    },
  )

  return (
    <TopToolbar>
      <Button
        color="primary"
        onClick={resetPassword}
        disabled={mutationLoading || loading}
      >
        <RotateLeftIcon style={{ marginRight: '10px' }} />
        {translate('ra.auth.reset')}
      </Button>
    </TopToolbar>
  )
}

CustomerActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
}

const CustomerEdit = props => {
  const controllerProps = useEditController(props)
  const { permissions = [] } = usePermissions()
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: '1em 0',
      }}
    >
      <div style={{ gridColumn: '1/3' }}>
        <ContextEdit
          {...controllerProps}
          title={<CustomerTitle />}
          action={<CustomerActions />}
        >
          <SimpleForm redirect={false} toolbar={<CustomerEditToolbar />}>
            <TextInput source="email" validate={[required()]} />
            <TextField source="customerNumber" />
            {permissions && permissions.includes('ROLE_STOREVIEW') && (
              <TextField source="storeView.name" />
            )}
          </SimpleForm>
        </ContextEdit>
      </div>
      <div style={{ gridColumn: '1/3' }}>
        <ContextEdit
          title={<TranslatedText text="resources.orders.name" />}
          {...controllerProps}
        >
          <SimpleForm {...props} toolbar={<NullToolbar />}>
            <Orders />
          </SimpleForm>
        </ContextEdit>
      </div>

      <div>
        <ContextEdit {...controllerProps} title={<NullTitle />} actions={null}>
          <TabbedEdit redirect={false} />
        </ContextEdit>
      </div>
    </div>
  )
}

CustomerEdit.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  permissions: PropTypes.array.isRequired,
}

export default CustomerEdit
