import Add from '@material-ui/icons/Add'
import Button from 'Components/Common/Buttons/Button'
import React from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { sanitizeResellerProductBeforeAddingToLines } from 'Components/Also/EDI/ResellableProductList'
import { useToggle } from 'react-use'
import { useForm } from 'react-final-form'
import { useResellerContext } from 'ResellerContext'
import { useFieldArray } from 'react-final-form-arrays'
import AlsoOfferLineAddDialog from 'Components/Also/Offers/OfferLines/AlsoOfferLineAddDialog'

const AlsoOfferAddLineButton = () => {
  const [open, toggle] = useToggle(false)
  const t = useTranslate()
  const notify = useNotify()
  const { batch } = useForm()
  const { customizingValues } = useResellerContext()
  const {
    fields: { push },
  } = useFieldArray('offerLines')

  const handleClickOpen = () => {
    toggle(true)
  }

  const handleClose = () => {
    toggle(false)
  }

  const handleAdd = records => {
    const recordArray = [].concat(records || [])
    batch(() => {
      recordArray.forEach(record =>
        push({
          ...sanitizeResellerProductBeforeAddingToLines(record),
          margin: customizingValues.alsoOfferSettings.defaultMargin,
        }),
      )
    })
    notify('notifications.addOfferLine.success')
    handleClose()
  }

  return (
    <>
      <Button
        label={t(`actions.add_line`)}
        color="primary"
        variant="contained"
        onClick={handleClickOpen}
        disableOnGlobalLoad={false}
      >
        <Add />
      </Button>

      <AlsoOfferLineAddDialog
        handleAdd={handleAdd}
        open={open}
        handleClose={handleClose}
      />
    </>
  )
}

export default AlsoOfferAddLineButton
