import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import TranslatedTitle from 'Components/Common/TranslatedTitle'
import { useOrder } from 'OrderContext'
import PropTypes from 'prop-types'
import React from 'react'
import { SimpleShowLayout, useTranslate } from 'react-admin'

const TrackingLink = ({ trackingUrl }) => (
  <a href={trackingUrl} target="_blank" rel="noopener noreferrer">
    {trackingUrl}
  </a>
)

TrackingLink.propTypes = {
  trackingUrl: PropTypes.string.isRequired,
}

const OrderShipments = () => {
  const { resource, basePath, record } = useOrder()

  const shipments = record.shipments || []
  const translate = useTranslate()
  return record ? (
    <SimpleShowLayout resource={resource} basePath={basePath} record={record}>
      <TranslatedTitle translateKey="resources.orders.tabs.shipments" />
      <Table>
        <TableHead>
          <TableCell>
            {translate('resources.order_shipments.fields.trackingNumber')}
          </TableCell>
          <TableCell>
            {translate('resources.order_shipments.fields.shippingCourier')}
          </TableCell>
          <TableCell>
            {translate('resources.order_shipments.fields.trackingLink')}
          </TableCell>
        </TableHead>
        <TableBody>
          {shipments.map(s => (
            <TableRow>
              <TableCell>{s.trackingNumber}</TableCell>
              <TableCell>
                {s.shippingCourier
                  ? s.shippingCourier.name
                  : translate(
                      'resources.order_shipments.messages.notAvailable',
                    )}
              </TableCell>
              <TableCell>
                {s.shippingCourier ? (
                  <TrackingLink trackingUrl={s.trackingURL} />
                ) : (
                  translate('resources.order_shipments.messages.notAvailable')
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </SimpleShowLayout>
  ) : null
}

export default OrderShipments
