import produce from 'immer'
import { max, round, sortBy } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useDataProvider } from 'react-admin'
import { useApiClient } from 'Rest/fetchHydra'
import { useDebounce } from 'use-debounce'
import { useQuery, useQueryClient } from 'react-query'
import useCanceableDataProvider, {
  useGetCancelToken,
} from 'Hooks/useCanceableQuery'

const getMaxStock = prices => max(prices.map(p => p.stock))

const FetchData = ({ children, includeItScope, searchValue }) => {
  const apiClient = useApiClient()
  const dataProvider = useDataProvider()
  const canceableDataProvider = useCanceableDataProvider()
  const getCancelToken = useGetCancelToken()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!includeItScope) {
      queryClient.cancelQueries(['it_scope_products'])
    }
  }, [includeItScope])

  const [searchQuery] = useDebounce(searchValue, 400, {
    leading: false,
  })

  const queriesActive = searchQuery?.length > 2

  const manufacturerQuery = useQuery(['manufacturers'], () =>
    dataProvider
      .getList('manufacturers', {
        pagination: false,
        sort: false,
        filter: false,
      })
      .then(res => res.data),
  )
  const productVariantQuery = useQuery(
    ['product_variants', searchQuery],
    () => {
      const [promise, cancel] = canceableDataProvider(
        'getList',
        'product_variants',
        {
          pagination: false,
          sort: false,
          filter: {
            search: searchQuery,
          },
        },
      )

      const resPromise = promise.then(res => res.data)

      resPromise.cancel = cancel
      return resPromise
    },

    {
      enabled: queriesActive,
      keepPreviousData: true,
      initialData: [],
    },
  )

  const itScopeProductQuery = useQuery(
    ['it_scope_products', searchQuery],
    () => {
      const [cancelToken, cancel] = getCancelToken()
      const promise = apiClient({
        endpoint: `/it_scope_products`,
        params: {
          keywords: searchQuery,
        },
        cancelToken,
      }).then(res => res.data)

      promise.cancel = cancel
      return promise
    },
    {
      enabled: Boolean(includeItScope) && queriesActive,
      keepPreviousData: true,
      initialData: [],
    },
  )

  const itScopeProducts = useMemo(() => {
    if (!includeItScope) {
      return []
    }
    const filtered = itScopeProductQuery.data.filter(
      ({ prices }) => prices.length > 0,
    )
    const sorted = sortBy(filtered, 'manufacturerSKU')

    return sorted
  }, [includeItScope, itScopeProductQuery.data])

  const loading =
    manufacturerQuery.isFetching ||
    productVariantQuery.isFetching ||
    itScopeProductQuery.isFetching

  const productVariants = productVariantQuery.data.map(v =>
    produce(v, draft => {
      draft.product.manufacturer = manufacturerQuery.data?.find(
        m => m.id === draft.product.manufacturer_reference_id,
      )
    }),
  )

  const itScopeData = itScopeProducts.map(p => ({
    ...p,
    price: round(p.averagePrice, 2),
    stock: getMaxStock(p.prices),
  }))

  return children({ productVariants, itScopeData, loading })
}

export default FetchData
