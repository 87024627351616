import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
} from '@material-ui/core'
import {
  NonSortableLabel,
  TableWrapper,
} from 'Components/Aluxo/OrderLines/OrderLinesTable'
import { useOrder } from 'OrderContext'
import React from 'react'
import { useGetManyReference, useTranslate } from 'react-admin'
import currencyText from 'Lib/currencyText'
import TranslatedTitle from 'Components/Common/TranslatedTitle'

const OrderLines = () => {
  const { record } = useOrder()
  const t = useTranslate()

  const {
    data: serviceObjects,
    loading: servicesLoading,
  } = useGetManyReference(
    'also_workplace_services',
    'orderLine.order',
    record.id,
    false,
    false,
    false,
    'also_workplace_services',
  )

  const {
    data: customServices,
    loading: customServicesLoading,
  } = useGetManyReference(
    'also_workplace_reseller_custom_services',
    'orderLine.order',
    record.id,
    false,
    false,
    false,
    'also_workplace_reseller_custom_services',
  )

  const loading = servicesLoading || customServicesLoading

  if (loading) {
    return <LinearProgress style={{ marginTop: '1em' }} />
  }

  const services = {}

  Object.values(serviceObjects).forEach(service => {
    if (services[service.service_reference_id]) {
      services[service.service_reference_id].push(service)
    } else {
      services[service.service_reference_id] = [service]
    }
  })

  Object.values(customServices).forEach(service => {
    if (services[service.service_reference_id]) {
      services[service.service_reference_id].push(service)
    } else {
      services[service.service_reference_id] = [service]
    }
  })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TranslatedTitle translateKey="alsoWorkplaceServices.namePerWorkplace" />
        <TableWrapper>
          <Table
            size="small"
            stripedRows
            fixedHeader={false}
            fixedFooter={false}
          >
            <TableHead displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableCell style={{ width: '40px' }}>
                  <NonSortableLabel>
                    {t('alsoWorkplaceServices.fields.position')}
                  </NonSortableLabel>
                </TableCell>
                <TableCell style={{ width: '100px' }}>
                  <NonSortableLabel>
                    {t('alsoWorkplaceServices.fields.category')}
                  </NonSortableLabel>
                </TableCell>
                <TableCell>
                  <NonSortableLabel>
                    {t('alsoWorkplaceServices.fields.name')}
                  </NonSortableLabel>
                </TableCell>

                <TableCell style={{ width: '40px', textAlign: 'right' }}>
                  <NonSortableLabel>
                    {t('alsoWorkplaceServices.fields.quantity')}
                  </NonSortableLabel>
                </TableCell>
                <TableCell style={{ width: '60px', textAlign: 'right' }}>
                  <NonSortableLabel>
                    {t('alsoWorkplaceServices.fields.monthlyRate')}
                  </NonSortableLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody displayRowCheckbox={false}>
              {Object.entries(services).map(([id, array], index) => {
                const { name, category, workplaceDependentPrice } = array[0]

                const quantity = array.reduce(
                  (acc, item) => acc + item.quantity,
                  0,
                )

                return (
                  <TableRow key={id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{category}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                      {quantity}
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                      {currencyText(workplaceDependentPrice)}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableWrapper>
      </Grid>
      <Grid item xs={12}>
        <TranslatedTitle translateKey="alsoWorkplaceServices.nameOnce" />
        <TableWrapper>
          <Table
            size="small"
            stripedRows
            fixedHeader={false}
            fixedFooter={false}
          >
            <TableHead displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                <TableCell style={{ width: '40px' }}>
                  <NonSortableLabel>
                    {t('alsoWorkplaceServices.fields.position')}
                  </NonSortableLabel>
                </TableCell>
                <TableCell style={{ width: '100px' }}>
                  <NonSortableLabel>
                    {t('alsoWorkplaceServices.fields.category')}
                  </NonSortableLabel>
                </TableCell>
                <TableCell>
                  <NonSortableLabel>
                    {t('alsoWorkplaceServices.fields.name')}
                  </NonSortableLabel>
                </TableCell>

                <TableCell style={{ width: '40px', textAlign: 'right' }}>
                  <NonSortableLabel>
                    {t('alsoWorkplaceServices.fields.quantity')}
                  </NonSortableLabel>
                </TableCell>
                <TableCell style={{ width: '60px', textAlign: 'right' }}>
                  <NonSortableLabel>
                    {t('alsoWorkplaceServices.fields.monthlyRate')}
                  </NonSortableLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody displayRowCheckbox={false}>
              {Object.entries(services)
                .filter(([, array]) => array[0].orderDependentPrice > 0)
                .map(([id, array], index) => {
                  const { name, category, orderDependentPrice } = array[0]

                  return (
                    <TableRow key={id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{category}</TableCell>
                      <TableCell>{name}</TableCell>
                      <TableCell style={{ textAlign: 'right' }}>1</TableCell>
                      <TableCell style={{ textAlign: 'right' }}>
                        {currencyText(orderDependentPrice)}
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableWrapper>
      </Grid>
    </Grid>
  )
}

export default OrderLines
