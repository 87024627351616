import Fuse from 'fuse.js'

export default function getSuggestions({
  serviceExtensions,
  itScopeData,
  inputValue,
}) {
  const searchValue = inputValue || ''.trim().toLowerCase()

  const fuse = new Fuse(serviceExtensions, {
    keys: ['sku', 'name'],
  })

  const results = fuse.search(searchValue)

  const groups = []
  results.forEach(({ item }) => {
    const suggestion = {
      ...item,
      value: item.id,
      label: `${item.sku} - ${item.term} Monate ${item.name}`,
      type: 'aluxo365',
    }
    const group = groups.find(group => group.id === item.term)
    if (group) {
      group.suggestions.push(suggestion)
    } else {
      groups.push({
        id: item.term,
        title: `${item.term} Monate`,
        suggestions: [suggestion],
      })
    }
  })

  if (itScopeData.length > 0) {
    groups.push({
      id: 'itscope',
      title: 'ITScope',
      suggestions: itScopeData.map(item => ({
        ...item,
        id: item.puid,
        sku: item.manufacturerSKU,
        label: `${item.manufacturerSKU} - ${item.productName}`,
        value: item.puid,
        type: 'itscope',
      })),
    })
  }

  return groups
}
