import React from 'react'
import PropTypes from 'prop-types'
import {
  CanceledLabel,
  ReleasedLabel,
  UnreleasedLabel,
  UploadedLabel,
} from '../Aluxo/ExternalProtectionPlans/labels'

const StatusLabel = ({ record }) => {
  let recordData = record
  const recordType = record['@type']

  if (recordType === 'ExternalProtectionPlanDevice') {
    recordData = record.externalProtectionPlan
  }

  // Drafted
  if (recordData.drafted) {
    return <UnreleasedLabel />
  }

  // Released waiting for Upload
  if (
    recordData &&
    !recordData.drafted &&
    !recordData.uploaded &&
    (recordType === 'ExternalProtectionPlan' || record.status === 'ADD')
  ) {
    return <ReleasedLabel />
  }

  // Uploaded
  if (
    !recordData.drafted &&
    recordData.uploaded &&
    (recordType === 'ExternalProtectionPlan' || record.status === 'ADD')
  ) {
    return <UploadedLabel />
  }

  // Canceled
  if (
    recordData &&
    !recordData.drafted &&
    recordData.uploaded &&
    (recordType === 'ExternalProtectionPlan' || record.status === 'REMOVE')
  ) {
    return <CanceledLabel />
  }

  return null
}

StatusLabel.propTypes = {
  record: PropTypes.object.isRequired, //eslint-disable-line
}

export default StatusLabel
