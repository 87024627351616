import queryString from 'query-string'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import debounce from 'lodash/debounce'
import de from './messages/de'
import apiClient from 'Rest/fetchHydra'

const parsed = queryString.parse(window.location.search)

const { showTranslationKeys } = parsed

const sendMissingKey = debounce(key => {
  const request = {
    type: 'admin',
    platformType: 'react-admin',
    key,
    content: key,
  }

  apiClient({
    endpoint: '/string_translations',
    method: 'POST',
    body: request,
  })
}, 3000)

const onMissingKey = key => {
  const roles = JSON.parse(localStorage.getItem('roles'))
  if (Array.isArray(roles) && roles.includes('ROLE_ALSO_RESELLER')) {
    sendMissingKey(key)
  }
  return key
}

const i18nProvider = polyglotI18nProvider(
  locale => {
    if (showTranslationKeys) {
      return null
    }
    if (locale === 'de') {
      return de
    }

    return de
  },
  'de',
  {
    allowMissing: true,
    onMissingKey,
  },
)

export default i18nProvider
