import React, { useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { useApiClient } from 'Rest/fetchHydra'

const types = {
  offer: ['create_offer'],
  rent_order: [
    'opened',
    'created',
    'canceled',
    'order_confirmed',
    'shipped',
    'confirmed',
    'delivered',
    'invoice_to_albis',
  ],
  buy_order: [
    'opened',
    'order_confirmed',
    'shipped',
    'invoice_to_customer',
    'canceled',
  ],
  also_order: ['opened', 'transmitted', 'also_online_service_notification'],
}

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  iframe: {
    margin: theme.spacing(1),
    width: '600px',
    height: '800px',
    resize: 'both',
    overflow: 'auto',
  },
}))

const CommonEmailTemplatesRender = () => {
  const classes = useStyles()
  const t = useTranslate()
  const apiClient = useApiClient()

  const ref = useRef(null)

  const [selectedRenderer, setSelectedRenderer] = useState(null)

  const handleRendererSelection = event => {
    setSelectedRenderer(event.target.value)
  }

  const [selectedTemplate, setSelectedTemplate] = useState(null)

  const handleTemplateSelection = event => {
    setSelectedTemplate(event.target.value)
  }

  const [body, setBody] = useState(null)

  useEffect(() => {
    const fetchEmailBody = async () => {
      if (!selectedTemplate) {
        return
      }

      const res = await apiClient({
        endpoint: '/common_render_email_requests',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          renderer: selectedRenderer,
          type: selectedTemplate,
          // order: '5aa669ca-bb60-4af6-ac22-ec3ba002aa24',
        },
      })

      setBody(res.data)
    }
    fetchEmailBody()
  }, [selectedTemplate])

  useEffect(() => {
    if (ref.current && body) {
      const doc = ref.current.contentDocument

      doc.open()
      doc.write(body)
      doc.close()
    }
  }, [body])

  return (
    <Card style={{ marginTop: '1em' }}>
      <CardHeader title={t('resources.email_templates.name')} />
      <CardContent>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">
              {t('resources.email_templates.renderer')}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedRenderer}
              onChange={handleRendererSelection}
            >
              {Object.keys(types).map(type => (
                <MenuItem value={type}>
                  {t(`resources.email_templates.renderers.${type}.name`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">
              {t('resources.email_templates.template')}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedTemplate}
              onChange={handleTemplateSelection}
            >
              {(types[selectedRenderer] || []).map(type => (
                <MenuItem value={type}>
                  {t(
                    `resources.email_templates.renderers.${selectedRenderer}.templates.${type}`,
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <iframe title="email-preview" ref={ref} className={classes.iframe}>
            Wird geladen
          </iframe>
        </div>
      </CardContent>
    </Card>
  )
}

export default CommonEmailTemplatesRender
