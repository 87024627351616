import React from 'react'
import {
  BooleanField,
  Datagrid,
  EditButton,
  List,
  TextField,
  NumberField,
} from 'react-admin'
import CustomPagination from 'Components/Common/Pagination/CustomPagination'
import DateField from 'Components/Common/ViewFields/DateField'

const AlsoResellerCustomServiceList = props => (
  <List {...props} pagination={<CustomPagination />}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="slug" />
      <TextField source="manufacturer" />
      <TextField source="category" />
      <NumberField source="position" />
      <BooleanField source="active" />
      <DateField source="createdAt" sortByOrder="DESC" />
      <DateField source="updatedAt" />
      <EditButton basePath="/also_reseller_custom_services" />
    </Datagrid>
  </List>
)

export default AlsoResellerCustomServiceList
