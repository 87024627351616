import React from 'react'
import { useFormState } from 'react-final-form'
import { SelectInput, useTranslate } from 'react-admin'
import { Inputs } from 'Components/Common/Orders/OrderEdit'
import { useOrder } from 'OrderContext'
import ToggleEditable, {
  MaybeUndefinedStringWithTranslation,
} from 'Components/Also/Offers/ToggleEditable'
import { validateRequired } from 'Resources/Aluxo/devices'

const EditableGeneralData = () => {
  const { values } = useFormState()
  const t = useTranslate()

  const { resource, basePath, record } = useOrder()

  return (
    <ToggleEditable
      displayChildren={
        <>
          <div>
            {t('resources.also_offers.fields.offerNumber')}:
            <MaybeUndefinedStringWithTranslation
              value={values.offerNumber}
              ml={1}
            />
          </div>
          <div>
            {t('resources.also_offers.fields.period')}:
            <MaybeUndefinedStringWithTranslation value={values.period} ml={1} />
          </div>
        </>
      }
      inputChildren={
        <Inputs resource={resource} basePath={basePath} record={record}>
          <div>{`${t('resources.also_offers.fields.offerNumber')}: ${
            values.offerNumber
          }`}</div>
          <SelectInput
            source="period"
            choices={[
              { id: 12, name: '12 Monate' },
              { id: 24, name: '24 Monate' },
              { id: 36, name: '36 Monate' },
            ]}
            validate={validateRequired}
          />
        </Inputs>
      }
    />
  )
}

export default EditableGeneralData
