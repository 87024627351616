export const SET_CONTENT_LANGUAGE = 'WAAS::SET_CONTENT_LANGUAGE'
export const SET_USER_LANGUAGE = 'WAAS::SET_USER_LANGUAGE'

export const setContentLanguage = contentLanguage => ({
  type: SET_CONTENT_LANGUAGE,
  payload: { contentLanguage },
})
export const setUserLanguage = userLanguage => ({
  type: SET_USER_LANGUAGE,
  payload: { userLanguage },
})

const initialState = {
  contentLanguage: localStorage.getItem('contentLanguage') || 'de',
  userLanguage: 'de',
}

const reducer = (state = initialState, { type, payload }) => {
  if (type === SET_CONTENT_LANGUAGE) {
    localStorage.setItem('contentLanguage', payload.contentLanguage)
    return {
      ...state,
      contentLanguage: payload.contentLanguage,
    }
  }
  if (type === SET_USER_LANGUAGE) {
    return {
      ...state,
      userLanguage: payload.userLanguage,
    }
  }
  return state
}

export default reducer
