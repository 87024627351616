import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import {
  useDataProvider,
  useLoading,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'
import { UPDATE_ALSO_API_DATA } from 'Rest/actions'

const UpdateAlsoApiDataButton = ({ label }) => {
  const showNotification = useNotify()
  const refreshView = useRefresh()
  const translate = useTranslate()
  const disabled = useLoading()

  const dataProvider = useDataProvider()

  const handleClick = async () => {
    try {
      await dataProvider.fetchApi(UPDATE_ALSO_API_DATA)
      showNotification('notifications.updateAlsoApiData.success')
      refreshView()
    } catch (error) {
      showNotification('notifications.updateAlsoApiData.fail', 'warning')
    }
  }

  return (
    <Button color="primary" onClick={handleClick} disabled={disabled}>
      {translate(label)}
    </Button>
  )
}

UpdateAlsoApiDataButton.propTypes = {
  label: PropTypes.string,
}

UpdateAlsoApiDataButton.defaultProps = {
  label: 'actions.updateAlsoApiData',
}

export default UpdateAlsoApiDataButton
