import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment/moment'
import { translate } from 'react-admin'

const OverdueField = props => {
  const { record = {}, elStyle } = props
  const m = moment(record.deadline)
  const now = moment()
  const diff = now.diff(m, 'days')
  return <span style={elStyle}>{diff > 0 && `${diff} Tagen`} </span>
}

OverdueField.propTypes = {
  addLabel: PropTypes.bool,
  elStyle: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

OverdueField.defaultProps = {
  addLabel: true,
}

export default translate(OverdueField)
