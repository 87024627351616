import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Loading } from 'react-admin'

export const changeStates = {
  draft: ['*'],
  opened: ['*'],
  changed: ['*'],
  review: ['shippingAddress.*', 'contactAddress.email.*'],
  approved: ['shippingAddress.*', 'contactAddress.email.*'],
  created: ['shippingAddress.*', 'contactAddress.email.*'],
  rejected: ['contactAddress.email.*'],
  customer_signed: ['shippingAddress.*', 'contactAddress.email.*'],
  reseller_signed: ['shippingAddress.*', 'contactAddress.email.*'],
  ordered: ['contactAddress.email.*'],
  order_confirmed: ['contactAddress.email.*'],
  shipped: ['contactAddress.email.*'],
  delivered: ['contactAddress.email.*'],
  payment_pending: ['contactAddress.email.*'],
  confirmed: ['contactAddress.email.*'],
  refused: ['contactAddress.email.*'],
  completed: ['contactAddress.email.*'],
  invoiced: ['contactAddress.email.*'],
  offer_draft: ['*'],
  buy_offer_created: ['*'],
  rent_offer_created: ['*'],
}

export const editableLineFieldsForState = {
  draft: ['*'],
  opened: ['*'],
  changed: ['*'],
  offer_draft: ['*'],
  shipped: ['serialNumbers'],
  delivered: ['serialNumbers'],
  payment_pending: ['serialNumbers'],
  ordered: ['serialNumbers'],
  order_confirmed: ['serialNumbers'],
  refused: ['serialNumbers'],
  completed: ['serialNumbers'],
  cancelled: ['serialNumbers'],
  invoiced: ['serialNumbers'],
  paid: ['serialNumbers'],
}

export const OrderContext = React.createContext()

const OrderProvider = ({ children, record, resource, basePath }) => (
  <OrderContext.Provider
    value={{
      record,
      resource,
      basePath,
    }}
  >
    {record ? children : <Loading />}
  </OrderContext.Provider>
)

OrderProvider.propTypes = {
  record: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default OrderProvider

export const useOrder = () => {
  const context = useContext(OrderContext)
  if (context === undefined) {
    throw new Error('useOrder must be used within a OrderProvider')
  }

  const canChange = Object.keys(changeStates).includes(context.record.marking)

  const editableLineFields = editableLineFieldsForState[context.record.marking]
  const editableFields = changeStates[context.record.marking] || []

  return { ...context, canChange, editableLineFields, editableFields }
}
