import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Add from '@material-ui/icons/Add'
import Button from 'Components/Common/Buttons/Button'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useOrder } from 'OrderContext'
import AddPromoBundleDialogContent from 'Components/Aluxo/OrderLines/Lines/PromoBundleLine/AddPromoBundleDialogContent'

export default function AddDialog({
  reference,
  initialValues,
  dialogProps,
  label,
}) {
  const { canChange } = useOrder()

  const [open, setOpen] = useState(false)
  const t = useTranslate()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        label={t(label || `actions.add_${reference}`)}
        color="primary"
        onClick={handleClickOpen}
        disabled={!canChange}
      >
        <Add />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">Hinzufügen</DialogTitle>
        <AddPromoBundleDialogContent
          handleClose={handleClose}
          initialValues={initialValues}
          dialogProps={dialogProps}
        />
      </Dialog>
    </>
  )
}

AddDialog.propTypes = {
  reference: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  label: PropTypes.string,
}

AddDialog.defaultProps = {
  initialValues: {},
  label: null,
}
