import React, { useContext } from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Labeled, useTranslate } from 'react-admin'
import { get } from 'lodash'
import { useField } from 'react-final-form'
import { ResellerContext } from 'ResellerContext'
import { useOrder } from 'OrderContext'

const SELECTOR = 'LEASING.ORDER_FINANCE_PARTNER'
const ALBIS_RATES = 'LEASING.ALBIS'
const DLL_RATES = 'LEASING.DLL'

export default function FinancePartnerSelect() {
  const orderProps = useOrder()

  const { flags } = useContext(ResellerContext)
  const t = useTranslate()
  const {
    input: { value: fieldsValue, onChange },
  } = useField('fields')

  if (!flags.multipleFinancePartners) {
    return null
  }

  if (orderProps.record.workflow === 'buy_order') {
    return null
  }

  const value = get(fieldsValue, SELECTOR) || ALBIS_RATES

  const handleChange = event => {
    onChange({ ...fieldsValue, [SELECTOR]: event.target.value })
  }

  return (
    <Labeled label={t('resources.orders.fields.finance_partner')}>
      <RadioGroup
        name="financePartner"
        value={value}
        onChange={handleChange}
        row
      >
        {[
          { id: ALBIS_RATES, name: 'Albis' },
          { id: DLL_RATES, name: 'DLL' },
        ].map(choice => (
          <FormControlLabel
            disabled={!orderProps.canChange}
            value={choice.id}
            control={<Radio />}
            label={choice.name}
          />
        ))}
      </RadioGroup>
    </Labeled>
  )
}
