import React from 'react'
import { useField } from 'react-final-form'
import { SelectInput, TextInput } from 'react-admin'
import { validateRequired } from 'Resources/Aluxo/devices'
import legalForms from 'Lib/legalForms'
import { validateRequiredEmail } from 'Resources/Common/reseller_smtp_configurations'
import { Inputs } from 'Components/Common/Orders/OrderEdit'
import { useOrder } from 'OrderContext'
import ToggleEditable, {
  MaybeUndefinedStringWithTranslation,
} from 'Components/Also/Offers/ToggleEditable'

const EditableAddress = ({ source }) => {
  const {
    input: { value },
  } = useField(source)
  const { resource, basePath, record } = useOrder()

  return (
    <ToggleEditable
      displayChildren={
        <>
          <div>
            <MaybeUndefinedStringWithTranslation
              value={value.salutation}
              i18nkey="resources.also_offers.fields.offerAddress.salutation"
              mr={1}
            />
            <MaybeUndefinedStringWithTranslation
              value={value.firstName}
              i18nkey="resources.also_offers.fields.offerAddress.firstName"
              mr={1}
            />
            <MaybeUndefinedStringWithTranslation
              value={value.lastName}
              i18nkey="resources.also_offers.fields.offerAddress.lastName"
            />
          </div>
          <div>
            <MaybeUndefinedStringWithTranslation
              value={value.company}
              i18nkey="resources.also_offers.fields.offerAddress.company"
              mr={1}
            />
            <MaybeUndefinedStringWithTranslation
              value={value.legalForm}
              i18nkey="resources.also_offers.fields.offerAddress.legalForm"
            />
          </div>
          <div>
            <MaybeUndefinedStringWithTranslation
              value={value.street}
              i18nkey="resources.also_offers.fields.offerAddress.street"
            />
          </div>
          <div>
            <MaybeUndefinedStringWithTranslation
              value={value.postcode}
              i18nkey="resources.also_offers.fields.offerAddress.postcode"
              mr={1}
            />
            <MaybeUndefinedStringWithTranslation
              value={value.city}
              i18nkey="resources.also_offers.fields.offerAddress.city"
            />
          </div>
          <div>
            <MaybeUndefinedStringWithTranslation
              value={value.phone}
              i18nkey="resources.also_offers.fields.offerAddress.phone"
            />
          </div>
          <div>
            <MaybeUndefinedStringWithTranslation
              value={value.email}
              i18nkey="resources.also_offers.fields.offerAddress.email"
            />
          </div>
        </>
      }
      inputChildren={
        <Inputs resource={resource} basePath={basePath} record={record}>
          <SelectInput
            source={`${source}.salutation`}
            choices={[
              { id: 'Frau', name: 'Frau' },
              { id: 'Herr', name: 'Herr' },
            ]}
            validate={validateRequired}
          />
          <TextInput
            source={`${source}.firstName`}
            validate={validateRequired}
          />
          <TextInput
            source={`${source}.lastName`}
            validate={validateRequired}
          />
          <SelectInput
            source={`${source}.legalForm`}
            choices={legalForms.map(f => ({ id: f, name: f }))}
            validate={validateRequired}
          />
          <TextInput source={`${source}.company`} validate={validateRequired} />
          <TextInput source={`${source}.street`} validate={validateRequired} />
          <TextInput
            source={`${source}.postcode`}
            validate={validateRequired}
          />
          <TextInput source={`${source}.city`} validate={validateRequired} />
          <TextInput source={`${source}.phone`} validate={validateRequired} />
          <TextInput
            source={`${source}.email`}
            validate={validateRequiredEmail}
          />
        </Inputs>
      }
    />
  )
}

export default EditableAddress
