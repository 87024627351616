import { useOrder } from 'OrderContext'
import React from 'react'
import GenericRestClientButton from '../../Common/ActionButtons/GenericRestClientButton'

const CheckITScopeStockButton = () => {
  const { record } = useOrder()
  return (
    <GenericRestClientButton
      type="itScopeCheckStock"
      action="updateOrderLinesStock"
      resource="orders"
      payload={{ id: record.id }}
    />
  )
}

export default CheckITScopeStockButton
